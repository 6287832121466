import reducer from './reducers'

import * as extractionOperations from './operations'
import * as extractionEndpoints from './paths'

export {
  extractionOperations,
  extractionEndpoints
}

export default reducer
