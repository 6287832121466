import React from 'react'
import { SortableElement as SortableItem } from 'react-sortable-hoc'
import './styles.scss'

export const SortableColumnRow = SortableItem(
  ({
    position,
    column,
    onPositionChange,
    onDeleteColumn,
    onOpenNormalizations
  }) => (
        <tr key={position} className="draggable-item">
            <td><b>{position + 1}</b></td>
            <td>{column.id}</td>
            <td>
                <textarea
                    className="form-control"
                    id={'column_name' + column.position}
                    type="text"
                    value={column.column_name}
                    name="column_name"
                    placeholder="Name"
                    onChange={(e) => onPositionChange(position, column.column_name, e)}
                    style={{
                      maxWidth: '140px',
                      textAlign: 'center',
                      padding: 0,
                      margin: '0 auto'
                    }}
                />
            </td>
            <td>
                <select
                    className="form-control"
                    value={column.type}
                    name="type"
                    onChange={(e) => onPositionChange(position, column.type, e)}
                >
                    <option value="">---</option>
                    <option value="string">string</option>
                    <option value="numeric">numeric</option>
                    <option value="datetime">datetime</option>
                    <option value="date">date</option>
                    <option value="boolean">boolean</option>
                    <option value="text">text</option>
                </select>
            </td>
            <td>
                <input
                    className="form-control"
                    type="text"
                    value={column.rule}
                    name="rule"
                    placeholder="REGEX"
                    onChange={(e) => onPositionChange(position, column.rule, e)}
                />
            </td>
            <td>
                <input
                    className="form-control"
                    type="text"
                    value={column.length || ''}
                    name="length"
                    onChange={(e) => onPositionChange(position, column.length, e)}
                />
            </td>
            <td>
                <label className="css-input switch switch-success" style={{
                  width: '100%',
                  height: '100%',
                  zIndex: '4'
                }}>
                    <input
                        type="checkbox"
                        name="index"
                        checked={column.index}
                        onChange={(e) => onPositionChange(position, column.index, e)}
                        style={{
                          width: '100%',
                          height: '100%',
                          zIndex: '4'
                        }}
                    />
                    <span></span>
                </label>
            </td>
            <td>
                <div className="col-sm-6 m-0 p-0">
                    <button
                        className="btn btn-primary btn-sm"
                        type="button"
                        onClick={(e) => {
                          onOpenNormalizations(position)
                        }}
                    >
                        N
                    </button>
                </div>
                <div className="col-sm-6 m-0 p-0">
                    <button
                        className="btn btn-danger btn-sm"
                        type="button"
                        onClick={(e) => {
                          if (window.confirm('Are you sure?')) {
                            onDeleteColumn(position)
                          }
                        }}
                    >
                        -
                    </button>
                </div>
            </td>
            <td></td>
        </tr>
  ))
