import { combineReducers } from 'redux'
import * as types from './types'

const fetchGroupsInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { results: [], pagination_data: {} }
}

const getGroupInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { permissions: [], users: [] }
}

const saveGroupInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

const updateGroupInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

export function fetchGroupsReducer (state = fetchGroupsInitialState, action) {
  switch (action.type) {
    case types.FETCH_GROUPS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.FETCH_GROUPS_REQUEST_FAILURE:
      return {
        ...fetchGroupsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.FETCH_GROUPS_REQUEST_SUCCESS:
      return {
        ...fetchGroupsInitialState,
        data: action.payload.data
      }
    case types.FETCH_GROUPS_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function getGroupReducer (state = getGroupInitialState, action) {
  switch (action.type) {
    case types.GET_GROUP_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.GET_GROUP_REQUEST_FAILURE:
      return {
        ...getGroupInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.GET_GROUP_REQUEST_SUCCESS:
      return {
        ...getGroupInitialState,
        data: action.payload.data
      }
    case types.GET_GROUP_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function saveGroupReducer (state = saveGroupInitialState, action) {
  switch (action.type) {
    case types.SAVE_GROUP_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.SAVE_GROUP_REQUEST_FAILURE:
      return {
        ...fetchGroupsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.SAVE_GROUP_REQUEST_SUCCESS:
      return {
        ...fetchGroupsInitialState,
        data: action.payload.data
      }
    case types.SAVE_GROUP_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function updateGroupReducer (state = updateGroupInitialState, action) {
  switch (action.type) {
    case types.UPDATE_GROUP_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.UPDATE_GROUP_REQUEST_FAILURE:
      return {
        ...updateGroupInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.UPDATE_GROUP_REQUEST_SUCCESS:
      return {
        ...updateGroupInitialState,
        data: action.payload.data
      }
    case types.UPDATE_GROUP_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export default combineReducers({
  fetchGroupsReducer,
  saveGroupReducer,
  getGroupReducer,
  updateGroupReducer
})
