import React, { Component } from 'react'
import { PageContainerLayout, PageHeaderLayout } from '../../../layouts'
import { SourceWizardForm } from '../../containers/source-wizard-form'
import { NavLink } from 'react-router-dom'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { SourceShape } from '../../../../prop-types'
import * as PropTypes from 'prop-types'
import { sourceOperations } from '../../../../state/ducks/sources'
import { connect } from 'react-redux'
import { Session } from '../../../../state/utils/session'

class SourceEditorPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }

    this.renderHeaderPaths = this.renderHeaderPaths.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.props.getSource(this.props.match.params.source_id)
  }

  renderHeaderPaths () {
    return (
            <>
                <li>
                    <NavLink className="link-effect"
                             to={ROUTING_PATHS.CLIENT_DASHBOARD_SOURCES_PATH.replace(':client_id', this.state.session.getClientSession())}
                    >
                        Sources
                    </NavLink>
                </li>
                <li>Edit Source</li>
            </>
    )
  }

  render () {
    return (
            <>
                <PageContainerLayout clientSidebar={true} showNavbarClientOptions={true}>
                    <PageHeaderLayout
                        title={this.props.source.name}
                        subTitle={'Edit Source'}
                        paths={this.renderHeaderPaths}
                    />
                    <SourceWizardForm
                        source={this.props.source}
                        isEditor={true}
                    />
                </PageContainerLayout>
            </>
    )
  }
}

// Set propTypes
SourceEditorPage.propTypes = {
  source: SourceShape,
  getSourceErrorResponse: PropTypes.object
}

// Set defaultProps
SourceEditorPage.defaultProps = {
  source: {},
  getSourceErrorResponse: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  source: state.sources.getSourceReducer.data,
  isLoading: state.sources.getSourceReducer.loading,
  getSourceErrorResponse: state.sources.getSourceReducer.error_data
})

// mapStateToProps
const mapDispatchToProps = {
  getSource: sourceOperations.get
}

// Export Class
const _SourceEditorPage = connect(mapStateToProps, mapDispatchToProps)(SourceEditorPage)
export { _SourceEditorPage as SourceEditorPage }
