import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Session } from '../../../../state/utils/session'
import { LogoutForm } from '../../containers'
import { AuthenticationPageLayout } from '../../../layouts'
import { authenticationEndpoints, authenticationOperations } from '../../../../state/ducks/authentication'

class LogoutPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      session: new Session()
    }
    this.logout = this.logout.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.logout()
  }

  logout () {
    this.props.logout(
      authenticationEndpoints.LOGOUT_REQUEST
    )
  }

  render () {
    return (
                <>
                    <AuthenticationPageLayout>
                        <LogoutForm />
                    </AuthenticationPageLayout>
                </>
    )
  };
}

const mapDispatchToProps = {
  logout: authenticationOperations.logout
}

// Export Class
const _LogoutPage = withRouter(connect(null, mapDispatchToProps)(LogoutPage))
export { _LogoutPage as LogoutPage }
