import React from 'react'
import * as PropTypes from 'prop-types'
import { executionOperations } from '../../../../state/ducks/executions'
import { connect } from 'react-redux'
import config from '../../../../config'
import { jobPoolsOperations } from '../../../../state/ducks/jobs-pools'
import { JobsPoolsShape } from '../../../../prop-types'
import SelectSearchLayout from '../../../layouts/select-search'

const DEFAULT_JOB_POOL = 'on_demand'

class ExecutionsModal extends React.Component {
  constructor (props) {
    super(props)

    this.default_job_pool_id = this.props.jobsPools.find(({ name }) => name === DEFAULT_JOB_POOL)?.id
    this.state = {
      hasError: false,
      featureMode: ['MELI_TEST'].includes(config.featureMode),
      standalone: false,
      job_pool_id: this.default_job_pool_id
    }

    this.returnJobsPoolOptions = this.returnJobsPoolOptions.bind(this)
    this.onJobsPoolSearchKeywordChange = this.onJobsPoolSearchKeywordChange.bind(this)
    this.onSelectJobPool = this.onSelectJobPool.bind(this)
    this.executeETL = this.executeETL.bind(this)
    this.dismiss = this.dismiss.bind(this)

    this.selectSearchLayout = React.createRef()
  }

  componentDidMount () {
    if ((this.props.object_type === 'ALARM' || !this.state.featureMode)) return

    if (!this.default_job_pool_id) this.props.fetchJobsPools({ search: DEFAULT_JOB_POOL })
  }

  componentDidUpdate (previousProps) {
    if (
      this.props.object_type === 'ALARM' ||
            !this.state.featureMode ||
            previousProps.jobsPools === this.props.jobsPools
    ) { return }

    if (!this.default_job_pool_id) {
      this.default_job_pool_id = this.props.jobsPools.find(({ name }) => name === DEFAULT_JOB_POOL)?.id
      this.setState({ job_pool_id: this.default_job_pool_id })
      this.props.fetchJobsPools({})
    }
  }

  dismiss () {
    if ((this.props.object_type === 'ALARM' || !this.state.featureMode)) return

    if (!this.state.standalone) this.selectSearchLayout.current.clearValue()

    this.setState({ job_pool_id: this.default_job_pool_id })
  }

  returnJobsPoolOptions () {
    const options = []
    this.props.jobsPools.forEach(element => {
      options.push({
        value: element.id + '|' + element.name,
        label: element.name
      })
    })
    return options
  }

  onJobsPoolSearchKeywordChange (keyword) {
    this.returnJobsPoolOptions()
    this.props.fetchJobsPools({ search: keyword })
  }

  onSelectJobPool (item) {
    if (item) {
      const split_ = item.value.split('|')
      this.setState({ job_pool_id: split_[0], hasError: false })
    } else {
      this.setState({ job_pool_id: this.default_job_pool_id })
    }
  }

  executeETL () {
    const data = {
      execution_type: this.props.object_type,
      ids: [this.props.object.id]
    }

    let hasError = false

    if (this.props.object_type !== 'ALARM' && this.state.featureMode) {
      data.standalone = this.state.standalone
      data.job_pool_id = this.state.job_pool_id
      hasError = !this.state.job_pool_id
    }
    this.setState({ hasError })

    if (!hasError) {
      if (!this.state.standalone && this.state.job_pool_id && this.selectSearchLayout?.current?.clearValue) this.selectSearchLayout.current.clearValue()

      this.setState({ standalone: false, job_pool_id: this.default_job_pool_id })

      this.props.saveExecution(data)
      window.jQuery('#executions-modal').modal('hide')
    }
  }

  render () {
    return (
            <>
                {/* MODAL */}
                <div className="modal" id="executions-modal" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className={`modal-dialog ${(this.props.object_type !== 'ALARM' && this.state.featureMode) ? '' : 'modal-sm'}`}>
                        <div className="modal-content">
                            <div className=" block block-themed block-opt-refresh-icon7 block-transparent remove-margin-b">
                                <div className="block-header bg-primary-dark">
                                    <ul className="block-options">
                                        <li>
                                            <button data-dismiss="modal" type="button" onClick={this.dismiss}><i className="si si-close"></i>
                                            </button>
                                        </li>
                                    </ul>
                                    <h3 className="block-title">
                                        Execute?
                                    </h3>
                                </div>
                                <div className="block-content">
                                    {(this.props.object_type !== 'ALARM' && this.state.featureMode) &&
                                    <>
                                        <span>Si desea enviar está {this.props.object_type === 'SOURCE' ? 'fuente' : 'conciliación'} como proceso independiente habilite la casilla standalone?, esto implica que no se va encolar</span>

                                        <div className="jobs">
                                            <div>
                                                <div className="filter">
                                                    <h2 className="filter-label">Standalone</h2>
                                                    <label className="css-input switch switch-success" style={{
                                                      height: '100%',
                                                      zIndex: '4'
                                                    }}>
                                                        <input
                                                            type="checkbox"
                                                            value={this.state.standalone}
                                                            checked={this.state.standalone}
                                                            onChange={(standalone) => {
                                                              this.setState({ standalone: standalone.target.checked, job_pool_id: this.default_job_pool_id })
                                                            }}
                                                        />
                                                        <span></span>
                                                    </label>
                                                </div>

                                                {!this.state.standalone &&
                                                    <div className="filter filter-select">
                                                        <h2 className="filter-label">Job Pool</h2>
                                                        <SelectSearchLayout
                                                            ref={this.selectSearchLayout}
                                                            isInModal
                                                            isClearable
                                                            placeholder={this.default_job_pool_id ? DEFAULT_JOB_POOL : 'Select an option...'}
                                                            className="form-material select-filter"
                                                            getOptions={this.returnJobsPoolOptions}
                                                            onSearchChange={this.onJobsPoolSearchKeywordChange}
                                                            onSelectItem={this.onSelectJobPool}
                                                        />
                                                        {this.state.hasError && <label className='has-error-select'>Select a job pool</label>}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </>
                                    }
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-sm btn-default" type="button" data-dismiss="modal" onClick={this.dismiss}>
                                    Close
                                </button>
                                <button
                                    className="btn btn-sm btn-primary"
                                    type="button"
                                    onClick={this.executeETL}
                                >
                                    <i className="fa fa-check"></i> Ok
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    )
  }
}

// Set propTypes
const { arrayOf, func, string, object } = PropTypes
ExecutionsModal.propTypes = {
  object_type: string,
  object,
  fetchJobsPools: func.isRequired,
  jobsPools: arrayOf(JobsPoolsShape).isRequired
}

// Set defaultProps
ExecutionsModal.defaultProps = {
  object_type: '',
  object: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  isLoading: state.executions.saveExecutionReducer.loading,
  saveExecutionErrorResponse: state.executions.saveExecutionReducer.error_data,
  jobsPools: state.jobsPools.fetchJobPoolsReducer.data.results
})

// mapStateToProps
const mapDispatchToProps = {
  saveExecution: executionOperations.save,
  fetchJobsPools: jobPoolsOperations.list
}

// Export Class
const _ExecutionsModal = connect(mapStateToProps, mapDispatchToProps)(ExecutionsModal)
export { _ExecutionsModal as ExecutionsModal }
