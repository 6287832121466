import React, { Component } from 'react'
import { InputSearchLayout } from '../input-search'
import { PaginationLayout } from '../pagination'
import { TableTheadLayout } from '../table-thead'
import { TableTbodyLayout } from '../table-tbody'
import { objectHeaders } from '../../../utils'
import { ContextMenuLayout } from '../table-context-menu'

class TableContainerLayout extends Component {
  constructor (props) {
    super(props)

    // State
    this.state = {
      fullPage: false,
      allTransactionsSelected: false,
      queryParams: {}
    }

    // Create the ref
    this.tableRef = React.createRef()

    this.fullPage = this.fullPage.bind(this)
    this.onPaginationChange = this.onPaginationChange.bind(this)
    this.onSearchChange = this.onSearchChange.bind(this)
    this.updateQueryParams = this.updateQueryParams.bind(this)
    this.onAllTransactionsSelected = this.onAllTransactionsSelected.bind(this)
    this.onCopy = this.onCopy.bind(this)
    this.onAddTags = this.onAddTags.bind(this)
    this.onAddComments = this.onAddComments.bind(this)
    this.onExportAsCSV = this.onExportAsCSV.bind(this)
    this.onExportAsXLS = this.onExportAsXLS.bind(this)
  }

  fullPage () {
    this.setState({ fullPage: !this.state.fullPage })
  }

  updateQueryParams (key, value) {
    const { queryParams } = this.state
    queryParams[key] = value
    if (key === 'search') {
      queryParams.page = 1
    }
    this.setState({ queryParams }, () => this.props.onFetch(this.state.queryParams))
  }

  onPaginationChange (page) {
    this.updateQueryParams('page', page)
  }

  onSearchChange (keywork) {
    this.updateQueryParams('search', keywork)
  }

  onAllTransactionsSelected (boolean) {
    this.setState({
      allTransactionsSelected: boolean
    })
  }

  onCopy () {
    alert('Copy')
  }

  onAddTags () {
    alert('Add Tags')
  }

  onAddComments () {
    alert('Add Comments')
  }

  onExportAsCSV () {
    alert('Export as CSV')
  }

  onExportAsXLS () {
    alert('Export as XLS')
  }

  contextMenuOptions () {
    return [
      {
        label: 'Copy',
        function: this.onCopy

      },
      {
        label: 'Add Tags',
        function: this.onAddTags

      },
      {
        label: 'Add Comments',
        function: this.onAddComments

      },
      {
        label: 'Export as .CSV',
        function: this.onExportAsCSV

      },
      {
        label: 'Export as .XLS',
        function: this.onExportAsXLS
      }
    ]
  }

  render () {
    return (
            <>
                {/* Container */}
                <div className={
                    ' block block-themed block-opt-refresh-icon7 ' +
                    (this.props.isLoading ? ' block-opt-refresh ' : '') +
                    (this.state.fullPage ? ' block-opt-fullscreen ' : '')
                }>
                    <div className="block-header bg-primary-dark">
                        <ul className="block-options">
                            <li>
                                <button type="button" onClick={this.fullPage}>
                                    <i className="si si-size-fullscreen"/>
                                </button>
                            </li>
                            <li>
                                <button type="button" onClick={this.props.fetch}>
                                    <i className="si si-refresh"/>
                                </button>
                            </li>
                        </ul>
                        <h3 className="block-title">{this.props.title}</h3>
                    </div>
                    <div className="block-content">

                        {/* Search */}
                        <InputSearchLayout
                            onSearchChange={this.onSearchChange}
                        />
                        {/* End Search */}

                        {/* Table */}
                        <div className="table-responsive" ref={this.tableRef}>
                            <table
                                className="table table-striped table-bordered table-condensed table-hover text-center"
                            >
                                {/* THeads */}
                                <TableTheadLayout
                                    headers={objectHeaders(this.props.rows[0])}
                                    allTransactionsSelected={this.state.allTransactionsSelected}
                                    onAllTransactionsSelected={this.onAllTransactionsSelected}

                                />
                                {/* End THeads */}

                                {/* TBody */}
                                <TableTbodyLayout
                                    rows={this.props.rows}
                                    allTransactionsSelected={this.state.allTransactionsSelected}
                                    onAllTransactionsSelected={this.onAllTransactionsSelected}
                                />
                                {/* End TBody */}
                            </table>
                        </div>
                        {/* End Table */}

                        {/* Pagination */}
                        <PaginationLayout
                            pagination={this.props.paginationData}
                            onPaginationChange={this.onPaginationChange}
                        />
                        {/* End Pagination */}

                        {/* Context Menu */}
                        <ContextMenuLayout
                            tableRef={this.tableRef}
                            options={this.contextMenuOptions()}
                        />
                        {/* End Context Menu */}
                    </div>
                </div>
                {/* End Container */}
            </>
    )
  }
}

// Set defaultProps
TableContainerLayout.defaultProps = {
  title: '',
  onFetch: () => {
  },
  isLoading: false,
  paginationData: {},
  rows: []
}

export { TableContainerLayout }
