import React, { Component } from 'react'
import { EmptyTableLayout } from '../../../layouts'
import { history } from '../../../../routing/History'
import * as PATHS from '../../../../routing/Paths'
import { Session } from '../../../../state/utils/session'
import { returnSortIcon, updateThSortBy } from '../../../../utils/tables'

const thList = [
  {
    title: 'ID',
    att: 'id',
    sortedBy: null
  },
  {
    title: 'Created At',
    att: 'created_at',
    sortedBy: null
  },
  {
    title: 'Name',
    att: 'name',
    sortedBy: null
  },
  {
    title: 'Status',
    att: 'status',
    sortedBy: null
  }
]

class AccountingSeatsTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session(),
      thList
    }

    this.goToAccountingSeatEditor = this.goToAccountingSeatEditor.bind(this)
    this.selectAccountingSeat = this.selectAccountingSeat.bind(this)
    this.applySort = this.applySort.bind(this)
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    if (!history.location.search.includes('ordering')) {
      this.setState({
        thList
      })
    }
  }

  goToAccountingSeatEditor (accountingSeat) {
    history.push(
      PATHS.CLIENT_DASHBOARD_ACCOUNTING_SEAT_EDITOR_PATH
        .replace(':accountingSeat_id', accountingSeat.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  selectAccountingSeat (accountingSeat) {
    history.push(
      PATHS.CLIENT_DASHBOARD_ACCOUNTING_SEAT_DETAILS_PATH
        .replace(':accountingSeat_id', accountingSeat.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  applySort (index) {
    const { thList } = this.state
    thList[index] = updateThSortBy(thList[index])
    this.setState({ thList }, () => {
      this.props.onSortBy(thList[index].att, thList[index].sortedBy)
    })
  }

  render () {
    return (
            <>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered table-condensed text-center">
                        <thead>
                        <tr>
                            {
                                this.state.thList.map((th, index) => {
                                  return (
                                        <th
                                            key={index}
                                            className={'text-center cursor-pointer'}
                                            onClick={this.applySort.bind(this, index)}
                                        >
                                            {th.title}
                                            &nbsp;
                                            {returnSortIcon(th.sortedBy)}
                                        </th>
                                  )
                                })
                            }
                            {/* <th className="text-center">Options</th> */}
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.accountingSeats.map((accountingSeat) => {
                              return (
                                    <tr key={accountingSeat.id}>
                                        <td className="text-center">
                                            {accountingSeat.id}
                                        </td>
                                        <td className="text-center">
                                            {new Date(accountingSeat.created_at).toLocaleDateString()}
                                        </td>
                                        <td className="text-center">
                                            {accountingSeat.name}
                                        </td>
                                        <td className="text-center">
                                            {accountingSeat.status}
                                        </td>
                                        {/* <td className="text-center"> */}
                                        {/*    <div className="btn-group"> */}
                                        {/*        <button className="btn btn-xs btn-default" */}
                                        {/*                type="button" */}
                                        {/*                data-toggle="tooltip" */}
                                        {/*                title="Edit AccountingSeat" */}
                                        {/*                onClick={this.goToAccountingSeatEditor.bind(this, accountingSeat)} */}
                                        {/*        > */}
                                        {/*            <i className="fa fa-refresh"/> */}
                                        {/*        </button> */}
                                        {/*        <button className="btn btn-xs btn-default" */}
                                        {/*                type="button" */}
                                        {/*                data-toggle="tooltip" */}
                                        {/*                title="Select AccountingSeat" */}
                                        {/*                onClick={this.selectAccountingSeat.bind(this, accountingSeat)} */}
                                        {/*        > */}
                                        {/*            <i className="fa fa-eye"/> */}
                                        {/*        </button> */}
                                        {/*    </div> */}
                                        {/* </td> */}
                                    </tr>
                              )
                            })
                        }
                        <EmptyTableLayout results={this.props.accountingSeats} columns={5}/>
                        </tbody>
                    </table>
                    {this.props.children}
                </div>
            </>
    )
  }
}

// Set defaultProps
AccountingSeatsTable.defaultProps = {
  accountingSeats: [],
  onSortBy: () => {}
}

// Export Class
export { AccountingSeatsTable }
