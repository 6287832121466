import React, { Component, useCallback, useState } from 'react'
import debounce from 'lodash.debounce'
import { Session } from '../../../../../state/utils/session'
import { history } from '../../../../../routing/History'
import {
  GroupOptionsControllerLayout,
  PageContainerLayout
} from '../../../../layouts'
import { releaseOperations } from '../../../../../state/ducks/deploy-tool'
import { connect } from 'react-redux'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import Checkbox from '@material-ui/core/Checkbox'

// Utils
const DatabaseTableLabelsMapping = {
  clients: { label: 'Clientes', batchOrder: 1 },
  connections: { label: 'Conexiones', batchOrder: 2 },
  alarms: { label: 'Alarmas', batchOrder: 3 },
  automations: { label: 'Automatizaciones', batchOrder: 3 },
  conciliations: { label: 'Conciliaciones', batchOrder: 3 },
  extractions: { label: 'Extracciones', batchOrder: 3 },
  sources: { label: 'Fuentes', batchOrder: 3 },
  file_deleting_tablenamematchvalue: {
    label: 'Eliminación de archivos: TableNameMatchValue',
    batchOrder: 3
  },
  file_deleting_filedeletingconfig: {
    label: 'Eliminación de archivos: FileDeletingConfig',
    batchOrder: 3
  },
  file_deleting_accountingannulmentactionconfig: {
    label: 'Eliminación de archivos: AccountingAnnulmentActionConfig',
    batchOrder: 3
  },
  worker_workercall: {
    label: 'Worker Calls',
    batchOrder: 3
  },
  automations_alarms: { label: 'Automatizaciones: Alarmas', batchOrder: 4 },
  automations_conciliations: {
    label: 'Automatizaciones: Conciliaciones',
    batchOrder: 4
  },
  automations_extractions: {
    label: 'Automatizaciones: Extracciones',
    batchOrder: 4
  },
  automations_sources: { label: 'Automatizaciones: Fuentes', batchOrder: 4 },
  columns: { label: 'Fuentes: Columnas', batchOrder: 4 },
  uniqueness: { label: 'Fuentes: Uniqueness', batchOrder: 4 },
  automations_worker_calls: {
    label: 'Automatizaciones: Worker Calls',
    batchOrder: 4
  },
  accounting_seat_settings: { label: 'Asientos Contables', batchOrder: 5 },
  normalizations: { label: 'Fuentes: Normalizaciones', batchOrder: 5 },
  s3_manager_api_s3managergroup: { label: 'S3 Manager: Grupos', batchOrder: 5 },
  s3_manager_api_s3managersettings: {
    label: 'S3 Manager: Configuraciones',
    batchOrder: 5
  },
  s3_manager_api_globalconcimanualconfig: {
    label: 'Global Concil Manual Config',
    batchOrder: 5
  },
  s3_manager_api_globalconcimanualconfigcbk: {
    label: 'Global Concil Manual Config Adj CBK',
    batchOrder: 5
  },
  uniqueness_order_key: {
    label: 'Fuentes: Uniqueness Order Key',
    batchOrder: 5
  },
  uniqueness_partition_key: {
    label: 'Fuentes: Uniqueness Partition Key',
    batchOrder: 5
  },
  transactional_management_api_source: {
    label: 'Gestión Transaccional: Fuentes',
    batchOrder: 6
  },
  transactional_management_api_tag: {
    label: 'Gestión Transaccional: Tag',
    batchOrder: 6
  },
  transactional_management_api_sourcetag: {
    label: 'Gestión Transaccional: Source Tag',
    batchOrder: 7
  },
  transactional_management_api_customcolumn: {
    label: 'Gestión Transaccional: Custom Column',
    batchOrder: 7
  },
  transactional_management_api_customcolumnvalue: {
    label: 'Gestión Transaccional: Custom Column Value',
    batchOrder: 8
  },
  transactional_management_api_tablefortagsource: {
    label: 'Gestión Transaccional: Table For Tag Source',
    batchOrder: 8
  },
  transactional_management_api_tagoption: {
    label: 'Gestión Transaccional: Tag Option',
    batchOrder: 8
  },
  core_accountingannulmentreportconfig: {
    label: 'Desconciliación: Accounting Annulment Report Config',
    batchOrder: 4
  },
  manual_conciliation_api_adjustmentreason: {
    label: 'Conciliación Manual: Razon de ajuste',
    batchOrder: 8
  },
  manual_conciliation_api_report: {
    label: 'Conciliación Manual: Reporte',
    batchOrder: 8
  },
  manual_conciliation_api_metadata: {
    label: 'Conciliación Manual: Metadato',
    batchOrder: 9
  },
  manual_conciliation_api_missing: {
    label: 'Conciliación Manual: Missing',
    batchOrder: 9
  },
  manual_conciliation_api_reportmetadata: {
    label: 'Conciliación Manual: Reporte-Metadato',
    batchOrder: 9
  }
}
const getSortedDatabaseMappings = (changes) => {
  const arr = []
  for (const prop in changes) {
    if (changes.hasOwnProperty(prop)) {
      arr.push({
        key: prop,
        value: changes[prop]
      })
    }
  }
  arr.sort(function (a, b) {
    return a.value.batchOrder - b.value.batchOrder
  })
  return arr // returns array
}
const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false)
  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div>{title}</div>
        <div>{isActive ? '-' : '+'}</div>
      </div>
      {isActive && <div className="accordion-content">{content}</div>}
    </div>
  )
}

// Wizards
const ReleaseDatabaseChange = (props) => {
  const [checked, setChecked] = useState(props.change.selected || false)
  const changeObject = props.change
  const changeType = changeObject.action
  const objectId = changeObject.id
  const config = changeObject.config
  const changeList = changeObject.changes || config
  const objectName = config.name
  const ChangeType = (_type) => {
    if (_type === 'add') {
      return <div>Agregar</div>
    } else if (_type === 'update') {
      return <div>Modificar</div>
    } else if (_type === 'copy') {
      return <div>Copiar</div>
    } else if (_type === 'delete') {
      return <div>Eliminar</div>
    }
  }
  const RenderChangeList = () => {
    const changes = Object.entries(changeList).map((item, index) => (
      <div className={'change-badge'} key={`changeList-${index}`}>
        <div>{item[0]}: </div>
        <div className="value">
          {JSON.stringify(item[1]).valueOf()}
        </div>
      </div>
    ))
    return (
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        {changes}
      </div>
    )
  }
  const container = (
    <div className={'database-change'}>
      <Checkbox
        checked={checked}
        disabled
        onChange={(event) => {
          setChecked(event.target.checked)
          props.onSelectCallback(event.target.checked)
        }}
      />
      <div className={'change-content'}>
        <div>
          Id: {objectId}: - Nombre: {objectName} <div>{RenderChangeList()}</div>
        </div>
        <div>{ChangeType(changeType)}</div>
      </div>
    </div>
  )
  return container
}
const ReleaseS3Change = (props) => {
  const [checked, setChecked] = useState(props.change.selected || false)
  const changeObject = props.change
  const changeType = changeObject.action
  const objectName = changeObject.key
  const ChangeType = (_type) => {
    if (_type === 'add') {
      return <div>Agregar</div>
    } else if (_type === 'update') {
      return <div>Modificar</div>
    } else if (_type === 'copy') {
      return <div>Copiar</div>
    } else if (_type === 'delete') {
      return <div>Eliminar</div>
    }
  }
  const container = (
    <div className={'database-change'}>
      <Checkbox
        checked={checked}
        disabled
        onChange={(event) => {
          setChecked(event.target.checked)
          props.onSelectCallback(event.target.checked)
        }}
      />
      <div className={'change-content'}>
        <div>Nombre: {objectName} </div>
        <div>{ChangeType(changeType)}</div>
      </div>
    </div>
  )
  return container
}
const DeployDetailsWizard = (props) => {
  const databaseTables = getSortedDatabaseMappings(DatabaseTableLabelsMapping)
  const session = new Session()
  const { release } = props
  const renderDatabaseModel = (model) => {
    if (props.changes.database.hasOwnProperty(model.key)) {
      const modelName = model.key
      const changes = Object.entries(props.changes.database).find(
        (el) => el[0] === modelName
      )[1]
      const changeList = changes.map((item, index) => (
        <ReleaseDatabaseChange key={item.id} change={item} />
      ))
      const selectedItems = changes.filter(
        (item) => item.selected === true
      ).length
      const modelTitle = (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <p style={{ margin: 0 }}>{model.value.label}</p>
          {selectedItems > 0 && (
            <div className="counter-badge">{selectedItems}</div>
          )}
        </div>
      )
      return <Accordion title={modelTitle} content={changeList} />
    }
  }
  const renderS3Changes = () => {
    const changes = []
    for (const [configIndex, element] of props.changes.s3.entries()) {
      console.log(configIndex)
      const changeList = element.changes.map((item, index) => (
        <ReleaseS3Change key={item.id} change={item} />
      ))
      const selectedItems = element.changes.filter(
        (item) => item.selected === true
      ).length
      const modelTitle = (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <p style={{ margin: 0 }}>Repositorio</p>
          {selectedItems > 0 && (
            <div className="counter-badge">{selectedItems}</div>
          )}
        </div>
      )
      changes.push(<Accordion title={modelTitle} content={changeList} />)
    }
    return changes
  }
  const handleDeployIntent = (event) => {
    props.deploy(session.getClientSession(), props.release.id)
  }
  const renderReleaseStatus = () => {
    const status = props.release.status
    if (status === 'created') {
      return <div className="release-status">Pendiente de aprobación</div>
    } else if (status === 'approved') {
      return <div className="release-status alternate">Versión aprobada</div>
    } else if (status === 'rejected') {
      return <div className="release-status error">Versión rechazada</div>
    } else if (status === 'running') {
      return (
        <div className="release-status alternate">Desplegando cambios</div>
      )
    } else if (status === 'failed') {
      return <div className="release-status error">Despliegue con errores</div>
    } else if (status === 'deployed') {
      return (
        <div className="release-status success">Desplegado exitosamente</div>
      )
    }
  }
  // if a user clicks the button many times this will prevent multiple deploy intents
  const debouncedDeployIntent = useCallback(
    debounce(handleDeployIntent, 500),
    []
  )

  return (
    <div className="deploy-tool-wizard">
      <form>
        {renderReleaseStatus()}
        <div className="release-inputs">
          <label>
            Código de la versión:
            <input
              disabled
              className={'release-code-input'}
              type="text"
              placeholder="v0.0.x"
              value={props.release.version_code}
            />
          </label>
          <label>
            Descripción de la versión:
            <textarea
              disabled
              value={props.release.changelist}
              rows={3}
            ></textarea>
          </label>
        </div>
        {release.errors && (
          <div className="release-inputs">
            <label>
              Detalles del error:
              <textarea
                disabled
                rows={4}
                style={{ maxWidth: '50rem' }}
                value={release.errors.error}
              />
            </label>
          </div>
        )}
        <div className="release-error-message">
          <p>{''}</p>
        </div>
        <Tabs>
          <TabList>
            <Tab>1. Base de datos</Tab>
            <Tab>2. Archivos S3</Tab>
          </TabList>
          <TabPanel>
            <div className="deploy-tool-wizard database-changes">
              {Object.entries(databaseTables).map((item) =>
                renderDatabaseModel(item[1])
              )}
            </div>
          </TabPanel>
          <TabPanel>{renderS3Changes()}</TabPanel>
        </Tabs>
        <GroupOptionsControllerLayout
          validFor={['DEPLOY_TOOL_AUTHORIZER']}
        >
          {props.release.status === 'created' && !props.loading && (
            <div
              className={`deploy-creator-submit ${
                props.isDeploying ? 'disabled' : ''
              }`}
              onClick={() => handleDeployIntent()}
            >
              Aprobar & Desplegar
            </div>
          )}
          {props.release.status === 'approved' && !props.loading && (
            <div
              className={`deploy-creator-submit ${
                props.isDeploying ? 'disabled' : ''
              }`}
              onClick={debouncedDeployIntent}
            >
              Reintentar Despliegue
            </div>
          )}
        </GroupOptionsControllerLayout>
      </form>
    </div>
  )
}

// Pages
class DeployDetailsPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      session: new Session()
    }
  }

  UNSAFE_componentWillMount () {
    const routeParams = history.location.pathname.trim().split('/')
    console.log(routeParams)
    const releaseId = routeParams[4]
    this.props.getRelease(releaseId)
  }

  databaseChanges () {
    return this.props.changes
  }

  isRunningPlaceholder () {
    return (
      <div
        style={{
          background: 'white',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '80vh'
        }}
      >
        <div className={'deploy-tool-loader'}></div>
        <div
          style={{
            marginTop: '1.8rem',
            color: 'black',
            fontWeight: 'bold',
            fontSize: '2.5rem',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          Estamos cargando los detalles, por favor espera.
        </div>
        <div style={{ marginTop: '3rem' }} onClick={() => {}}>
          <a href="#">Regresar al menú principal</a>
        </div>
      </div>
    )
  }

  render () {
    return (
      <>
        <PageContainerLayout>
          {this.props.isRunning && this.isRunningPlaceholder()}
          {!this.props.isRunning && (
            <div className="deploy-tool-creator">
              <div className="title">
                Detalle de la versión: {this.props.release.version_code}
              </div>
              {
                <DeployDetailsWizard
                  release={this.props.release}
                  changes={this.props.changes}
                  deploy={this.props.deployRelease}
                  loading={this.props.loading}
                />
              }
            </div>
          )}
        </PageContainerLayout>
      </>
    )
  }
}

// Set defaultProps
DeployDetailsPage.defaultProps = {
  version_code: 'v0.0.1',
  changes: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  isLoading: state.releases.fetchReleaseReducer.loading,
  // changes: state.releases.fetchDiffcheckReducer.data.changes.data,
  isRunning: state.releases.fetchReleaseReducer.loading,
  changes: state.releases.fetchReleaseReducer.data.configuration,
  release: state.releases.fetchReleaseReducer.data,
  isDeploying: state.releases.deployReleaseReducer.loading,
  isDeploySucesss: state.releases.deployReleaseReducer.completed,
  loading: state.releases.deployReleaseReducer.loadingButton
})

// mapStateToProps
const mapDispatchToProps = {
  getRelease: releaseOperations.get,
  deployRelease: releaseOperations.deployRelease
}

// Export Class
const _DeployDetailsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeployDetailsPage)

export { _DeployDetailsPage as DeployViewerPage }
