import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import SelectSearchLayout from '../../../layouts/select-search'
import { groupOperations } from '../../../../state/ducks/groups'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import { ClientShape } from '../../../../prop-types'
import { Session } from '../../../../state/utils/session'

class PermissionGroupsForm extends Component {
  constructor (props) {
    super(props)

    // set state
    this.state = {
      session: new Session()
    }

    // handlers
    this.returnOptions = this.returnOptions.bind(this)
    this.onGroupearchKeywordChange = this.onGroupearchKeywordChange.bind(this)
    this.onSelectGroup = this.onSelectGroup.bind(this)
    this.removeGroup = this.removeGroup.bind(this)
  }

  returnOptions () {
    const options = []
    this.props.groups_list.forEach(element => {
      const groupAdded = this.props.added_groups.find(p => p.toString() === element.id.toString())
      if (!groupAdded) {
        options.push({
          value: element.id + '|' + element.name,
          label: element.name
        })
      }
    })
    return options
  }

  onGroupearchKeywordChange (keyword) {
    this.returnOptions()
    this.props.fetchGroups({ search: keyword })
  }

  onSelectGroup (item) {
    const addedGroups = this.props.added_groups
    const split_ = item.value.split('|')
    addedGroups.push({
      id: split_[0],
      name: split_[1]
    })
    this.props.onChange(addedGroups)
  }

  removeGroup (index) {
    const addedGroups = this.props.added_groups
    addedGroups.splice(index, 1)
    this.props.onChange(addedGroups)
  }

  renderGroups () {
    return (
            <>
                <table className="table">
                    <tbody>
                    {
                        this.props.added_groups.map((group, index) => {
                          return (
                                <tr key={index} className="border">
                                    <td style={{ width: '20px' }}>
                                        <i className="fa fa-object-group fa-2x"></i>
                                    </td>
                                    <td>
                                        <small className="font-w400">{group.name}</small>
                                        <div className="font-w400 text-muted">
                                            <small>---</small>
                                        </div>
                                    </td>
                                    <td style={{ width: '20px' }}>
                                        <Button
                                            variant="danger"
                                            className="float-right"
                                            onClick={this.removeGroup.bind(this, index)}
                                        >
                                            <i className="fa fa-trash"/>
                                        </Button>
                                    </td>
                                </tr>
                          )
                        })
                    }
                    </tbody>
                </table>
                {/* <pre> */}
                {/*    <code className="json"> */}
                {/*        {JSON.stringify(this.props.added_groups)} */}
                {/*    </code> */}
                {/* </pre> */}
            </>
    )
  }

  render () {
    return (
            <>
                <div className="form-group">
                    <div className="col-sm-6 col-sm-offset-3">
                        <div className="form-material">
                            <SelectSearchLayout
                                getOptions={this.returnOptions}
                                onSearchChange={this.onGroupearchKeywordChange}
                                onSelectItem={this.onSelectGroup}
                            />
                            <label htmlFor="input-groups">Search groups</label>
                        </div>
                        {this.renderGroups()}
                    </div>
                </div>
            </>
    )
  }
}

// Set propTypes
PermissionGroupsForm.propTypes = {
  groups_list: PropTypes.arrayOf(ClientShape).isRequired,
  fetchGroups: PropTypes.func.isRequired
}

// Set defaultProps
PermissionGroupsForm.defaultProps = {
  groups_list: [],
  added_groups: [],
  onChange: () => {
  }
}

// mapStateToProps
const mapStateToProps = (state) => ({
  groups_list: state.groups.fetchGroupsReducer.data.results
})

// mapStateToProps
const mapDispatchToProps = {
  fetchGroups: groupOperations.list
}

// Export Class
const _PermissionGroupsForm = connect(mapStateToProps,
  mapDispatchToProps)(PermissionGroupsForm)
export { _PermissionGroupsForm as PermissionGroupsForm }
