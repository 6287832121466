import React, { Component } from 'react'
import { PageContainerLayout, PageHeaderLayout } from '../../../layouts'
import { UserWizardForm } from '../../../shared/containers'
import { NavLink } from 'react-router-dom'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { UserShape } from '../../../../prop-types'
import * as PropTypes from 'prop-types'
import { userOperations } from '../../../../state/ducks/users'
import { connect } from 'react-redux'

class UserEditorPage extends Component {
  constructor (props) {
    super(props)
  }

  UNSAFE_componentWillMount () {
    this.props.getUser(this.props.match.params.user_id)
  }

  renderHeaderPaths () {
    return (
            <>
                <li>
                    <NavLink className="link-effect" to={ROUTING_PATHS.USERS_PATH}>
                        Users
                    </NavLink>
                </li>
                <li>Edit User</li>
            </>
    )
  }

  render () {
    return (
            <>

                <PageContainerLayout clientSidebar={true} showNavbarClientOptions={true}>
                    <PageHeaderLayout
                        title={this.props.user.full_name}
                        subTitle={'Edit User'}
                        paths={this.renderHeaderPaths}
                    />
                    <UserWizardForm
                        user={this.props.user}
                        isEditor={true}
                    />
                </PageContainerLayout>

            </>
    )
  }
}

// Set propTypes
UserEditorPage.propTypes = {
  user: UserShape,
  getUserErrorResponse: PropTypes.object
}

// Set defaultProps
UserEditorPage.defaultProps = {
  user: {},
  getUserErrorResponse: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  user: state.users.getUserReducer.data,
  isLoading: state.users.getUserReducer.loading,
  getUserErrorResponse: state.users.getUserReducer.error_data
})

// mapStateToProps
const mapDispatchToProps = {
  getUser: userOperations.get
}

// Export Class
const _UserEditorPage = connect(mapStateToProps, mapDispatchToProps)(UserEditorPage)
export { _UserEditorPage as UserEditorPage }
