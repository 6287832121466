import React, { Component } from 'react'
import { Session } from '../../../../state/utils/session'

class NotAuthorizedPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      session: new Session()
    }
  }

  render () {
    return (
            <>
                {/* Error Content */}
                <div className="content text-center pulldown overflow-hidden">
                    <div className="row">
                        <div className="col-sm-6 col-sm-offset-3">
                            {/* Error Titles */}
                            <h1 className="font-s128 font-w300 text-amethyst animated fadeInDown">401</h1>
                            <h2 className="h3 font-w300 push-50 animated fadeInUp">No tienes los permisos necesarios para acceder a este sitio.</h2>
                            {/* END Error Titles */}
                            <div className="btn btn-primary" onClick={() => this.state.session.removeSession()}>Salir de aqui</div>
                        </div>
                    </div>
                </div>
                {/* END Error Content */}

                {/* Error Footer */}
                {/* END Error Footer */}
            </>
    )
  };
}

export { NotAuthorizedPage }
