import React, { Component } from 'react'
import { history } from '../../../../routing/History'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { Session } from '../../../../state/utils/session'
import SourceColumnNormalizationModal from '../source-column-normalization-modal'

class SourceColumnsTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      column_to_show_normalizations: { normalizations: [] },
      session: new Session()
    }
    this.goToSourceEditor = this.goToSourceEditor.bind(this)
    this.setColumnToShowNormalizations = this.setColumnToShowNormalizations.bind(this)
  }

  goToSourceEditor () {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_SOURCE_EDITOR_PATH
        .replace(':source_id', this.props.source.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  setColumnToShowNormalizations (column) {
    this.setState({
      column_to_show_normalizations: column
    }, () => {
      window.jQuery('#normalizations-modal-details').modal('show')
    })
  }

  render () {
    return (
            <>
                <div className="block block-themed">
                    <div className="block-header bg-primary-dark">
                        <ul className="block-options">
                            <li>
                                <button type="button" onClick={this.goToSourceEditor}>
                                    <i className="fa fa-edit"></i>
                                </button>
                            </li>
                        </ul>
                        <h3 className="block-title">
                            <i className="si si-grid"></i> Columns
                        </h3>
                    </div>
                    <div className="block-content" style={{ height: '300px', overflowY: 'scroll' }}>
                        <table className="table table-striped table-bordered table-condensed text-center">
                            <thead>
                            <tr>
                                <td>Pos.</td>
                                <td>ID</td>
                                <td>Name</td>
                                <td>Type</td>
                                <td>Rule</td>
                                <td>Length</td>
                                <td>Index</td>
                                <td>Norm.</td>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                this.props.source.columns.map((column, index) => {
                                  return (
                                        <tr key={index}>
                                            <td><b>{index + 1}</b></td>
                                            <td>
                                                {column.id}
                                            </td>
                                            <td>
                                                {column.column_name}
                                            </td>
                                            <td>
                                                {column.type}
                                            </td>
                                            <td>
                                                {column.rule}
                                            </td>
                                            <td>
                                                {column.length}
                                            </td>
                                            <td>
                                                {JSON.stringify(column.index)}
                                            </td>
                                            <td>
                                                <button className="btn btn-default"
                                                        onClick={this.setColumnToShowNormalizations.bind(this, column)}
                                                >
                                                    {column.normalizations.length}
                                                </button>
                                            </td>
                                        </tr>
                                  )
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
                <SourceColumnNormalizationModal column={this.state.column_to_show_normalizations}/>
            </>
    )
  }
}

// Set defaultProps
SourceColumnsTable.defaultProps = {
  source: { columns: [] }
}

// Export Class
export { SourceColumnsTable }
