import { DeployListPage } from './pages/list'
import { DeployCreatorPage } from './pages/creator'
import { DeployEditorPage } from './pages/editor'
import { DeployViewerPage } from './pages/detail'
import React, { Component } from 'react'
import { PageContainerLayout } from '../../../layouts'
import { clientOperations } from '../../../../state/ducks/clients'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { ClientShape } from '../../../../prop-types'
import { Session } from '../../../../state/utils/session'
import { history } from '../../../../routing/History'
import * as ROUTING_PATHS from '../../../../routing/Paths'

class DeployHomePage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      session: new Session(),
      params: {}
    }
    this.fetchClients = this.fetchClients.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.fetchClients()
  }

  fetchClients () {
    this.props.fetchClients(this.state.params)
  }

  selectClient (client) {
    const session = new Session()
    session.getSession()
    this.state.session.setClientSession(client)
    history.push(
      ROUTING_PATHS.DEPLOY_TOOL_BASE_PATH.replace(':client_id', client.id)
    )
  }

  render () {
    return (
      <>
        <PageContainerLayout>
          {/* Page Content */}
          <div className="content content-boxed">
            <div
              className={
                ' block block-themed block-opt-refresh-icon7 ' +
                (this.props.isLoading ? ' block-opt-refresh ' : '')
              }
            >
              <div className="deploy-tool block-content">
                <div className="deploy-tool client-selector">
                  <h3 className="deploy-tool title">
                    Para empezar, selecciona un cliente
                  </h3>
                  <ul className="deploy-tool client-list">
                    {this.props.clients.map((item, index) => {
                      return (
                        <div
                          onClick={() => this.selectClient(item)}
                          className="deploy-tool client-card"
                          key={`clients-${index}`}
                        >
                          {item.name}
                        </div>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* END Page Content */}
        </PageContainerLayout>
      </>
    )
  }
}

// Set propTypes
const { arrayOf, func } = PropTypes
DeployHomePage.propTypes = {
  clients: arrayOf(ClientShape).isRequired,
  fetchClients: func.isRequired
}

// Set defaultProps
DeployHomePage.defaultProps = {
  clients: []
}

// mapStateToProps
const mapStateToProps = (state) => ({
  isLoading: state.clients.fetchClientsReducer.loading,
  clients: state.clients.fetchClientsReducer.data.results
})

// mapStateToProps
const mapDispatchToProps = {
  fetchClients: clientOperations.list
}

// Export Class
const _DeployHomePage = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeployHomePage)

export {
  _DeployHomePage as DeployHomePage,
  DeployListPage,
  DeployCreatorPage,
  DeployViewerPage,
  DeployEditorPage
}
