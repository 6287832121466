import React, { Component } from 'react'
import { EmptyTableLayout } from '../../../layouts'
import { history } from '../../../../routing/History'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { Session } from '../../../../state/utils/session'
import Swal from 'sweetalert2'
import { automationOperations } from '../../../../state/ducks/automations'
import { connect } from 'react-redux'

class AutomationsTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }

    this.goToAutomationEditor = this.goToAutomationEditor.bind(this)
    this.selectAutomation = this.selectAutomation.bind(this)
    this.deleteAutomation = this.deleteAutomation.bind(this)
  }

  goToAutomationEditor (automation) {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_AUTOMATION_EDITOR_PATH
        .replace(':automation_id', automation.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  selectAutomation (automation) {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_AUTOMATION_DETAILS_PATH
        .replace(':automation_id', automation.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  deleteAutomation (automation) {
    Swal.fire({
      title: '',
      html: `<b>Por favor confirme la eliminación de la automatización ${automation.name}?</b>`,
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.dismiss !== 'backdrop') {
        if (result.value) {
          this.props.removeAutomation(this.props.params, automation.id)
        }
      }
    })
  }

  returnBooleanLabel (status) {
    if (status) {
      return (
                <>
                    <span className="label label-success">
                        <i className="fa fa-check"></i>
                    </span>
                </>
      )
    } else {
      return (
                <>
                    <span className="label label-danger">
                        <i className="fa fa-close"></i>
                    </span>
                </>
      )
    }
  }

  render () {
    return (
            <>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered table-condensed text-center">
                        <thead>
                        <tr>
                            <th className="text-center">ID</th>
                            <th className="text-center">Name</th>
                            <th className="text-center">Suffix</th>
                            <th className="text-center">Cron</th>
                            <th className="text-center">All Sources</th>
                            <th className="text-center">All Conciliations</th>
                            <th className="text-center">All Alarms</th>
                            <th className="text-center">Active</th>
                            <th className="text-center">Options</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.automations.map((automation) => {
                              return (
                                    <tr key={automation.id}>
                                        <td className="text-center">{automation.id}</td>
                                        <td className="text-center">{automation.name}</td>
                                        <td className="text-center">{automation.suffix}</td>
                                        <td className="text-center">{automation.cron_expression}</td>
                                        <td className="text-center">
                                            {this.returnBooleanLabel(automation.for_all_sources)}
                                        </td>
                                        <td className="text-center">
                                            {this.returnBooleanLabel(automation.for_all_conciliations)}
                                        </td>
                                        <td className="text-center">
                                            {this.returnBooleanLabel(automation.for_all_alarms)}
                                        </td>
                                        <td className="text-center">
                                            {this.returnBooleanLabel(automation.active)}
                                        </td>
                                        <td className="text-center">
                                            <div className="btn-group">
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Edit Automation"
                                                        onClick={this.goToAutomationEditor.bind(this, automation)}
                                                >
                                                    <i className="fa fa-pen"></i>
                                                </button>
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Select Automation"
                                                        onClick={this.selectAutomation.bind(this, automation)}
                                                >
                                                    <i className="fa fa-eye"></i>
                                                </button>
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Remove Automation"
                                                        onClick={this.deleteAutomation.bind(this, automation)}
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                              )
                            })
                        }
                        <EmptyTableLayout results={this.props.automations} columns={4}/>
                        </tbody>
                    </table>
                    {this.props.children}
                </div>
            </>
    )
  }
}

// Set defaultProps
AutomationsTable.defaultProps = {
  automations: []
}

const mapStateToProps = (state) => ({
  isLoading: state.automations.removeAutomationReducer.loading
})

const mapDispatchToProps = {
  removeAutomation: automationOperations.remove
}

// Export Class
const _AutomationsTable = connect(mapStateToProps, mapDispatchToProps)(AutomationsTable)
export { _AutomationsTable as AutomationsTable }
