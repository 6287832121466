import React, { Component } from 'react'
import { history } from '../../../../routing/History'

class UserDetailsCard extends Component {
  constructor (props) {
    super(props)

    this.state = {}
    this.goToUserEditor = this.goToUserEditor.bind(this)
  }

  goToUserEditor () {
    history.push(this.props.editorPath)
  }

  render () {
    return (
            <>
                <div className="block">
                    <div className="block-header bg-gray-lighter">
                        <ul className="block-options">
                            <li>
                                <button type="button" onClick={this.goToUserEditor}>
                                    <i className="fa fa-edit"></i>
                                </button>
                            </li>
                        </ul>
                        <h3 className="block-title">
                            <i className="fa fa-info-circle"></i> Details
                        </h3>
                    </div>
                    <div className="block-content">
                        <ul className="list list-activity push">
                            <li>
                                <div className="font-w600">Username:</div>
                                <div>{this.props.user.username}</div>
                            </li>
                            <li>
                                <div className="font-w600">Full Name:</div>
                                <div>{this.props.user.full_name}</div>
                            </li>
                            <li>
                                <div className="font-w600">Avatar:</div>
                                <div>{this.props.user.avatar}</div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="block">
                    <div className="block-header bg-gray-lighter">
                        <ul className="block-options">
                            <li>
                                <button type="button" onClick={this.goToUserEditor}>
                                    <i className="fa fa-edit"></i>
                                </button>
                            </li>
                        </ul>
                        <h3 className="block-title">
                            <i className="fa fa-lock"></i> Groups
                        </h3>
                    </div>
                    <div className="block-content">
                        <ul className="list list-activity push">
                            {
                                this.props.user.groups.map((g, index) => {
                                  return (
                                        <li key={index}>
                                            <div className="font-w600">Group name:</div>
                                            <div>{g.name}</div>
                                        </li>
                                  )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </>
    )
  }
}

// Set defaultProps
UserDetailsCard.defaultProps = {
  user: { groups: [] },
  editorPath: ''
}

// Export Class
export { UserDetailsCard }
