import { combineReducers } from 'redux'
import * as types from './types'

const fetchDashboardsInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { results: [], pagination_data: {} }
}

const getDashboardInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { groups: [] }
}

const saveDashboardInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

const updateDashboardInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

export function fetchDashboardsReducer (state = fetchDashboardsInitialState, action) {
  switch (action.type) {
    case types.FETCH_DASHBOARDS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.FETCH_DASHBOARDS_REQUEST_FAILURE:
      return {
        ...fetchDashboardsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.FETCH_DASHBOARDS_REQUEST_SUCCESS:
      return {
        ...fetchDashboardsInitialState,
        data: action.payload.data
      }
    case types.FETCH_DASHBOARDS_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function getDashboardReducer (state = getDashboardInitialState, action) {
  switch (action.type) {
    case types.GET_DASHBOARD_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.GET_DASHBOARD_REQUEST_FAILURE:
      return {
        ...getDashboardInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.GET_DASHBOARD_REQUEST_SUCCESS:
      return {
        ...getDashboardInitialState,
        data: action.payload.data,
        completed: true
      }
    case types.GET_DASHBOARD_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function saveDashboardReducer (state = saveDashboardInitialState, action) {
  switch (action.type) {
    case types.SAVE_DASHBOARD_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.SAVE_DASHBOARD_REQUEST_FAILURE:
      return {
        ...fetchDashboardsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.SAVE_DASHBOARD_REQUEST_SUCCESS:
      return {
        ...fetchDashboardsInitialState,
        data: action.payload.data
      }
    case types.SAVE_DASHBOARD_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function updateDashboardReducer (state = updateDashboardInitialState, action) {
  switch (action.type) {
    case types.UPDATE_DASHBOARD_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.UPDATE_DASHBOARD_REQUEST_FAILURE:
      return {
        ...updateDashboardInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.UPDATE_DASHBOARD_REQUEST_SUCCESS:
      return {
        ...updateDashboardInitialState,
        data: action.payload.data
      }
    case types.UPDATE_DASHBOARD_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export default combineReducers({
  fetchDashboardsReducer,
  saveDashboardReducer,
  getDashboardReducer,
  updateDashboardReducer
})
