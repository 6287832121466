import React, { Component } from 'react'
import { PageContainerLayout, PageHeaderLayout } from '../../../layouts'
import { userOperations } from '../../../../state/ducks/users'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { UserShape } from '../../../../prop-types'
import { Session } from '../../../../state/utils/session'
import { NavLink } from 'react-router-dom'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { UserWizardForm } from '../../containers/user-wizard-form'

class UserProfileEditorPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }
    this.fetchUser = this.fetchUser.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.fetchUser()
  }

  fetchUser () {
    this.props.fetchUser(this.state.session.getSession().user_id)
  }

  renderHeaderPaths () {
    return (
            <>
                <li>
                    <NavLink className="link-effect" to={ROUTING_PATHS.SHARED_USER_PROFILE_PATH}>
                        Profile
                    </NavLink>
                </li>
                <li>Editor</li>
            </>
    )
  }

  render () {
    return (
            <>
                <PageContainerLayout
                    clientSidebar={!!this.state.session.getClientSession()}
                    showNavbarClientOptions={!!this.state.session.getClientSession()}>

                    <PageHeaderLayout
                        title={this.props.user.full_name}
                        subTitle={'Edit Profile'}
                        paths={this.renderHeaderPaths}
                    />
                    <UserWizardForm
                        user={this.props.user}
                        isEditor={true}
                        afterUpdateRedirectTo={ROUTING_PATHS.SHARED_USER_PROFILE_PATH}
                    />
                </PageContainerLayout>
            </>
    )
  };
}

// Set propTypes
const { arrayOf, func } = PropTypes
UserProfileEditorPage.propTypes = {
  user: arrayOf(UserShape).isRequired,
  fetchUser: func.isRequired
}

// Set defaultProps
UserProfileEditorPage.defaultProps = {
  user: {},
  paginationData: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  isLoading: state.users.getUserReducer.loading,
  user: state.users.getUserReducer.data
})

// mapStateToProps
const mapDispatchToProps = {
  fetchUser: userOperations.get
}

// Export Class
const _UserProfileEditorPage = connect(mapStateToProps, mapDispatchToProps)(UserProfileEditorPage)
export { _UserProfileEditorPage as UserProfileEditorPage }
