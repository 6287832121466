import React, { Component } from 'react'
import { connect } from 'react-redux'
import config from '../../../../config'
import { authenticationEndpoints, authenticationOperations } from '../../../../state/ducks/authentication'
import { history } from '../../../../routing/History'
import * as PATHS from '../../../../routing/Paths'

// Class
class LoginForm extends Component {
  constructor (props) {
    super(props)

    // state
    this.state = {
      username: '',
      password: ''
    }

    // handlers
    this.handleChange = this.handleChange.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.goToResetPassword = this.goToResetPassword.bind(this)
    this.goToGoogleLogin = this.goToGoogleLogin.bind(this)
  }

  componentDidMount () {
    console.log('process.env.REACT_MERCADOLIBRE_APP:_', process.env.REACT_MERCADOLIBRE_APP)
  }

  handleChange (event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.handleSubmit()
    }
  }

  handleSubmit = () => {
    const { username, password } = this.state
    if (username && password) {
      this.props.login(
        authenticationEndpoints.LOGIN_REQUEST,
        { username, password }
      )
    }
  }

  goToResetPassword () {
    history.push(PATHS.RESET_PASSWORD_PATH)
  }

  goToGoogleLogin (e) {
    e.preventDefault()
    window.location.href = config.apiEndPoint + 'auth/v1/login/google/'
  }

  goToSSOLogin (e) {
    e.preventDefault()
    window.location.href = config.apiEndPoint + 'sso/v1/?sso2'
  }

  render () {
    if (config.appEnviroment === 'meli') {
      return (
                <>
                    <div className=" block block-themed block-opt-refresh-icon7 animated fadeIn">
                        <div className="block-content block-content-full block-content-narrow">
                            {/* Title */}
                            <h1 className="h2 font-w600 push-30-t push-5">Simetrik</h1>
                            <p>Welcome, please login.</p>
                            {/* END Title */}

                            <form className="js-validation-login form-horizontal push-30-t push-50"
                                  action="javascript:void(0)" method="post">
                                <div>
                                    <button onClick={this.goToSSOLogin} name="button-sso"
                                            className="btn btn-block btn-warning">
                                        Acceder via Directorio Activo - MercadoLibre
                                        <i className="ml-1 si si-login pull-right"></i>
                                    </button>
                                </div>
                            </form>
                            {/* END Form */}
                        </div>
                    </div>
                </>
      )
    }
    return (
            <>
                <div className=" block block-themed block-opt-refresh-icon7 animated fadeIn">
                    <div className="block-content block-content-full block-content-narrow">
                        {/* Title */}
                        <h1 className="h2 font-w600 push-30-t push-5">Simetrik</h1>
                        <p>Welcome, please login.</p>
                        {/* END Title */}
                        <form className="js-validation-login form-horizontal push-30-t push-50"
                              action="javascript:void(0)" method="post">
                                        <div><div className="form-group">
                                        <div className="col-xs-12">
                                            <div className="form-material form-material-primary floating">
                                                <input
                                                    className="form-control"
                                                    id="login-username"
                                                    type="email"
                                                    name="username"
                                                    value={this.state.username}
                                                    onKeyPress={this.handleKeyPress}
                                                    onChange={this.handleChange}
                                                />
                                                <label htmlFor="login-username">Username</label>
                                            </div>
                                        </div>
                                    </div>
                                            <div className="form-group">
                                                <div className="col-xs-12">
                                                    <div className="form-material form-material-primary floating">
                                                        <input
                                                            className="form-control"
                                                            id="login-password"
                                                            type="password"
                                                            name="password"
                                                            value={this.state.password}
                                                            onKeyPress={this.handleKeyPress}
                                                            onChange={this.handleChange}
                                                        />
                                                        <label htmlFor="login-password">Password</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="col-xs-12">
                                                    <label className="css-input switch switch-sm switch-primary">
                                                        <input type="checkbox" id="login-remember-me"
                                                               name="login-remember-me"
                                                        />
                                                        <span></span>
                                                        Remember Me?
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="col-xs-12 col-sm-6 col-md-4">
                                                    <button
                                                        className="btn btn-block btn-primary"
                                                        type="button"
                                                        disabled={this.props.authenticationReducers.loginReducer.loading}
                                                        onClick={this.handleSubmit}
                                                    >
                                                        {
                                                            this.props.authenticationReducers.loginReducer.loading
                                                              ? <i className="fa fa-spinner fa-spin"></i>
                                                              : <>
                                                                    <i className="ml-1 si si-login pull-right"></i>
                                                                    <span>Login</span>
                                                                </>
                                                        }
                                                    </button>
                                                    {
                                                        config.appEnviroment === 'simetrik'
                                                          ? <button
                                                                type="button"
                                                                name="button"
                                                                className="btn btn-default"
                                                                onClick={this.goToGoogleLogin}
                                                                style={{ width: '200px', marginTop: '10px' }}
                                                            >
                                                                Continue with Google
                                                                <i className="ml-1 si si-login pull-right"></i>
                                                            </button>
                                                          : null
                                                    }
                                                </div>
                                                <div className="col-xs-12 col-sm-6 col-md-8">
                                                    {
                                                        this.props.authenticationReducers.loginReducer.error_data.error
                                                          ? <small
                                                                className="mt-2">{this.props.authenticationReducers.loginReducer.error_data.error}</small>
                                                          : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                            {
                                config.appEnviroment === 'meli2'
                                  ? <div>
                                        <hr/>
                                        <button onClick={this.goToSSOLogin} name="button-sso"
                                                className="btn btn-block btn-warning">
                                            Acceder via Directorio Activo - MercadoLibre
                                            <i className="ml-1 si si-login pull-right"></i>
                                        </button>
                                    </div>
                                  : null
                            }
                        </form>
                        {/* END Form */}
                    </div>
                </div>
            </>
    )
  };
}

// Set propTypes
LoginForm.propTypes = {}

// Set defaultProps
LoginForm.defaultProps = {}

// Set prefetch
LoginForm.prefetch = null

// mapStateToProps
const mapStateToProps = (state) => ({
  authenticationReducers: state.authentication
})

// mapStateToProps
const mapDispatchToProps = {
  login: authenticationOperations.login
}

// Export Class
const _LoginForm = connect(mapStateToProps, mapDispatchToProps)(LoginForm)
export { _LoginForm as LoginForm }
