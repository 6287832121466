import React, { Component } from 'react'
import { history } from '../../../../routing/History'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { Session } from '../../../../state/utils/session'
import { Link } from 'react-router-dom'

class AccountingSeatSettingDetailsCard extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }
    this.goToAccountingSeatSettingEditor = this.goToAccountingSeatSettingEditor.bind(this)
  }

  goToAccountingSeatSettingEditor () {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_ACCOUNTING_SEAT_SETTING_EDITOR_PATH
        .replace(':accounting_seat_setting_id', this.props.accountingSeatSetting.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  render () {
    return (
            <>
                <div className="block block-themed">
                    <div className="block-header bg-primary-dark">
                        <ul className="block-options">
                            <li>
                                <button type="button" onClick={this.goToAccountingSeatSettingEditor}>
                                    <i className="fa fa-edit"></i>
                                </button>
                            </li>
                        </ul>
                        <h3 className="block-title">
                            <i className="fa fa-info-circle"></i> Details
                        </h3>
                    </div>
                    <div className="block-content" style={{ height: '300px', overflowY: 'scroll' }}>
                        <ul className="list list-activity push">
                            <li>
                                <div className="font-w600">ID:</div>
                                <div>{this.props.accountingSeatSetting.id}</div>
                            </li>
                            <li>
                                <div className="font-w600">Name:</div>
                                <div>{this.props.accountingSeatSetting.name}</div>
                            </li>
                            <li>
                                <div className="font-w600">Table Name:</div>
                                <div>{this.props.accountingSeatSetting.table_name}</div>
                            </li>
                            <li>
                                <div className="font-w600">Connection:</div>
                                <div>
                                    <Link to={
                                        ROUTING_PATHS.CLIENT_DASHBOARD_CONNECTION_DETAILS_PATH
                                          .replace(':client_id', this.state.session.getClientSession())
                                          .replace(':connection_id', this.props.accountingSeatSetting.connection)
                                    }>
                                        {this.props.accountingSeatSetting.connection_name}
                                    </Link>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </>
    )
  }
}

// Set defaultProps
AccountingSeatSettingDetailsCard.defaultProps = {
  accountingSeatSetting: {}
}

// Export Class
export { AccountingSeatSettingDetailsCard }
