import React, { Component } from 'react'
import { PageContainerLayout, PageHeaderLayout } from '../../../layouts'
import { NavLink } from 'react-router-dom'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { ConciliationShape } from '../../../../prop-types'
import * as PropTypes from 'prop-types'
import { conciliationOperations } from '../../../../state/ducks/conciliations'
import { connect } from 'react-redux'
import { Session } from '../../../../state/utils/session'
import {
  ConciliationAllocationsATableGenerated,
  ConciliationAllocationsBTableGenerated,
  ConciliationConciliationTableGenerated,
  ConciliationDetailsCard,
  ConciliationExecutionLogs,
  ConciliationMissingsATableGenerated,
  ConciliationMissingsBTableGenerated,
  ConciliationTimelineCard
} from '../../containers'

class ConciliationDetailsPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }
  }

  UNSAFE_componentWillMount () {
    this.props.getConciliation(this.props.match.params.conciliation_id)
  }

  render () {
    const renderHeaderPaths = () => {
      return (
                <>
                    <li>
                        <NavLink className="link-effect"
                                 to={ROUTING_PATHS.CLIENT_DASHBOARD_CONCILIATIONS_PATH
                                   .replace(':client_id', this.state.session.getClientSession())
                                 }
                        >
                            Conciliations
                        </NavLink>
                    </li>
                    <li>Conciliation Details</li>
                </>
      )
    }

    return (
            <>
                <PageContainerLayout
                    clientSidebar={true}
                    showNavbarClientOptions={true}
                >
                    {/* First Section */}
                    <PageHeaderLayout
                        title={this.props.conciliation.name}
                        subTitle={'Conciliation Details'}
                        paths={renderHeaderPaths}
                    />
                    <div className="content content-boxed">
                        <div className="row">
                            <div className="col-sm-12 col-lg-4">
                                {/* Details */}
                                <ConciliationDetailsCard conciliation={this.props.conciliation}/>
                                {/* END Details */}
                            </div>
                            <div className="col-sm-12 col-lg-4">
                                {/* Timeline */}
                                <ConciliationTimelineCard conciliation={this.props.conciliation}/>
                                {/* END Timeline */}
                            </div>
                            <div className="col-sm-12 col-lg-4">
                                {/* Execution Logs */}
                                <ConciliationExecutionLogs conciliation={this.props.conciliation}/>
                                {/* END Execution Logs */}
                            </div>
                        </div>
                    </div>
                    {/* End First Section */}

                    {
                        this.props.conciliation.conciliation_type === 2
                          ? <>
                                {/* Conciliation Result */}
                                <PageHeaderLayout
                                    title={'Conciliation Result'}
                                    subTitle={'Table generated after running the Simetrik Conciliator'}
                                    paths={() => {
                                    }}
                                />
                                <div className="content content-boxed">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            {/* Success Table Generated Table */}
                                            <ConciliationConciliationTableGenerated
                                                conciliation={this.props.conciliation}/>
                                            {/* END Success Table Generated Table */}
                                        </div>
                                    </div>
                                </div>
                                {/* End Conciliation Result */}

                                {/* Allocations */}
                                <PageHeaderLayout
                                    title={'Allocations'}
                                    subTitle={'...Description...'}
                                    paths={() => {
                                    }}
                                />
                                <div className="content content-boxed">
                                    <div className="row">
                                        {
                                            this.props.conciliation
                                              ? <>
                                                    {
                                                        this.props.conciliation.parameters.allocation_a
                                                          ? <div className="col-lg-6">
                                                                {/* Allocations A */}
                                                                <ConciliationAllocationsATableGenerated
                                                                    conciliation={this.props.conciliation}/>
                                                                {/* END Allocations A */}
                                                            </div>
                                                          : null
                                                    }
                                                </>
                                              : null
                                        }
                                        {
                                            this.props.conciliation
                                              ? <>
                                                    {
                                                        this.props.conciliation.parameters.allocation_b
                                                          ? <div className="col-lg-6">
                                                                {/* Allocations B */}
                                                                <ConciliationAllocationsBTableGenerated
                                                                    conciliation={this.props.conciliation}/>
                                                                {/* END Allocations B */}
                                                            </div>
                                                          : null
                                                    }
                                                </>
                                              : null
                                        }
                                    </div>
                                </div>
                                {/* End Allocations */}

                                {/* Missings */}
                                <PageHeaderLayout
                                    title={'Missings'}
                                    subTitle={'...Description...'}
                                    paths={() => {
                                    }}
                                />
                                <div className="content content-boxed">
                                    <div className="row">
                                        {
                                            this.props.conciliation
                                              ? <>
                                                    {
                                                        this.props.conciliation.parameters.missing_a
                                                          ? <div className="col-lg-6">
                                                                {/* Missings B */}
                                                                <ConciliationMissingsATableGenerated
                                                                    conciliation={this.props.conciliation}/>
                                                                {/* END Missings B */}
                                                            </div>
                                                          : null
                                                    }
                                                </>
                                              : null
                                        }
                                        {
                                            this.props.conciliation
                                              ? <>
                                                    {
                                                        this.props.conciliation.parameters.missing_b
                                                          ? <div className="col-lg-6">
                                                                {/* Missings B */}
                                                                <ConciliationMissingsBTableGenerated
                                                                    conciliation={this.props.conciliation}/>
                                                                {/* END Missings B */}
                                                            </div>
                                                          : null
                                                    }
                                                </>
                                              : null
                                        }
                                    </div>
                                </div>
                                {/* End Missings */}
                            </>
                          : null
                    }
                </PageContainerLayout>
            </>
    )
  }
}

// Set propTypes
ConciliationDetailsPage.propTypes = {
  conciliation: ConciliationShape,
  isLoading: PropTypes.bool
}

// Set defaultProps
ConciliationDetailsPage.defaultProps = {
  // Conciliation
  conciliation: { columns: [] },
  isLoading: true
}

// mapStateToProps
const mapStateToProps = (state) => ({
  conciliation: state.conciliations.getConciliationReducer.data,
  isLoading: state.conciliations.getConciliationReducer.loading
})

// mapStateToProps
const mapDispatchToProps = {
  getConciliation: conciliationOperations.get
}

// Export Class
const _ConciliationDetailsPage = connect(mapStateToProps, mapDispatchToProps)(ConciliationDetailsPage)
export { _ConciliationDetailsPage as ConciliationDetailsPage }
