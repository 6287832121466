import React, { Component } from 'react'
import { EmptyTableLayout } from '../../../layouts'
import { history } from '../../../../routing/History'
import * as ROUTING_PATHS from '../../../../routing/Paths'

class UsersTable extends Component {
  constructor (props) {
    super(props)

    this.state = {}

    this.goToUserEditor = this.goToUserEditor.bind(this)
    this.selectUser = this.selectUser.bind(this)
    this.deleteUser = this.deleteUser.bind(this)
  }

  goToUserEditor (user) {
    history.push(ROUTING_PATHS.USER_EDITOR_PATH.replace(':user_id', user.id))
  }

  selectUser (user) {
    history.push(ROUTING_PATHS.USER_DETAILS_PATH.replace(':user_id', user.id))
  }

  deleteUser (user) {

  }

  render () {
    return (
            <>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered table-condensed text-center">
                        <thead>
                        <tr>
                            <th className="text-center">ID</th>
                            <th className="text-center">Username</th>
                            <th className="text-center">Name</th>
                            <th className="text-center">Options</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.users.map((user) => {
                              return (
                                    <tr key={user.id}>
                                        <td className="text-center">{user.id}</td>
                                        <td className="text-center">{user.username}</td>
                                        <td className="text-center">{user.name}</td>
                                        <td className="text-center">
                                            <div className="btn-group">
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Edit User"
                                                        onClick={this.goToUserEditor.bind(this, user)}
                                                >
                                                    <i className="fa fa-pen"></i>
                                                </button>
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Select User"
                                                        onClick={this.selectUser.bind(this, user)}
                                                >
                                                    <i className="fa fa-eye"></i>
                                                </button>
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Remove User"
                                                        onClick={this.deleteUser.bind(this, user)}
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                              )
                            })
                        }
                        <EmptyTableLayout results={this.props.users} columns={4}/>
                        </tbody>
                    </table>
                    {this.props.children}
                </div>
            </>
    )
  }
}

// Set defaultProps
UsersTable.defaultProps = {
  users: []
}

// Export Class
export { UsersTable }
