import React from 'react'
import { Session } from '../../../../state/utils/session'
import { history } from '../../../../routing/History'
import * as ROUTING_PATHS from '../../../../routing/Paths'

export function WorkerCallDetailsCard (props) {
  const session = new Session()

  const goToWorkerCallEditor = () => {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_WORKER_CALLS_EDITOR_PATH.replace(
        ':worker_call_id',
        props.workerCall.id
      ).replace(':client_id', session.getClientSession())
    )
  }

  const goToAutomationDetails = (automation) => {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_AUTOMATION_DETAILS_PATH.replace(
        ':automation_id',
        automation.id
      ).replace(':client_id', session.getClientSession())
    )
  }

  return (
    <>
      <div className="row">
        <div className="col-sm-12 col-lg-5">
          <div className="block">
            <div className="block-header bg-gray-lighter">
              <ul className="block-options">
                <li>
                  <button
                    type="button"
                    onClick={goToWorkerCallEditor}
                    data-toggle="tooltip"
                    title="Edit worker call"
                  >
                    <i className="fa fa-edit"></i>
                  </button>
                </li>
              </ul>
              <h3 className="block-title">
                <i className="fa fa-info-circle"></i> Details
              </h3>
            </div>
            <div className="block-content">
              <ul className="list list-activity push">
                <li>
                  <div className="font-w600">ID:</div>
                  <div>{props.workerCall.id}</div>
                </li>
                <li>
                  <div className="font-w600">Worker call name:</div>
                  <div>{props.workerCall.name}</div>
                </li>
                <li>
                  <div className="font-w600">Path:</div>
                  <div>{props.workerCall.path}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {props.workerCall?.automations?.map((automation) => (
          <div key={automation.id} className="col-sm-12 col-lg-5">
            <div className="block">
              <div className="block-header bg-gray-lighter">
                <ul className="block-options">
                  <li>
                    <button
                      type="button"
                      onClick={() => goToAutomationDetails(automation)}
                      data-toggle="tooltip"
                      title="Go to automation details"
                    >
                      <i className="fa fa-magic"></i>
                    </button>
                  </li>
                </ul>
                <h3 className="block-title">
                  <i className="fa fa-info-circle"></i> Automation
                </h3>
              </div>
              <div className="block-content">
                <ul className="list list-activity push">
                  <li>
                    <div className="font-w600">ID:</div>
                    <div>{automation.id}</div>
                  </li>
                  <li>
                    <div className="font-w600">Automation name:</div>
                    <div>{automation.name}</div>
                  </li>
                  <li>
                    <div className="font-w600">Active:</div>
                    <div>{JSON.stringify(automation.active)}</div>
                  </li>
                  <li>
                    <div className="font-w600">Cron expression:</div>
                    <div>{automation.cron_expression}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

WorkerCallDetailsCard.defaultProps = {
  workerCall: {}
}
