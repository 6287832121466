import React, { Component } from 'react'
import { EmptyTableLayout, GroupOptionsControllerLayout } from '../../../layouts'
import { history } from '../../../../routing/History'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { Session } from '../../../../state/utils/session'

class DashboardsTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }

    this.goToDashboardEditor = this.goToDashboardEditor.bind(this)
    this.selectDashboard = this.selectDashboard.bind(this)
    this.deleteDashboard = this.deleteDashboard.bind(this)
  }

  goToDashboardEditor (dashboard) {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_DASHBOARD_EDITOR_PATH
        .replace(':dashboard_id', dashboard.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  selectDashboard (dashboard) {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_DASHBOARD_DETAILS_PATH
        .replace(':dashboard_id', dashboard.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  deleteDashboard (dashboards) {

  }

  render () {
    return (
            <>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered table-condensed text-center">
                        <thead>
                        <tr>
                            <th className="text-center">ID</th>
                            <th className="text-center">Name</th>
                            <th className="text-center">Vertical</th>
                            <th className="text-center">Quicksight ID</th>
                            <th className="text-center">Options</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.dashboards.map((dashboard) => {
                              return (
                                    <tr key={dashboard.id}>
                                        <td className="text-center">{dashboard.id}</td>
                                        <td className="text-center">{dashboard.name}</td>
                                        <td className="text-center">{dashboard.vertical}</td>
                                        <td className="text-center">{dashboard.quicksight_id}</td>
                                        <td className="text-center">
                                            <div className="btn-group">
                                                <GroupOptionsControllerLayout validFor={['ADMIN', 'DEVELOPER']}>
                                                    <button className="btn btn-xs btn-default"
                                                            type="button"
                                                            data-toggle="tooltip"
                                                            title="Edit Dashboard"
                                                            onClick={this.goToDashboardEditor.bind(this, dashboard)}
                                                    >
                                                        <i className="fa fa-pen"></i>
                                                    </button>
                                                </GroupOptionsControllerLayout>
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Select Dashboard"
                                                        onClick={this.selectDashboard.bind(this, dashboard)}
                                                >
                                                    <i className="fa fa-eye"></i>
                                                </button>
                                                <GroupOptionsControllerLayout validFor={['ADMIN', 'DEVELOPER']}>
                                                    <button className="btn btn-xs btn-default"
                                                            type="button"
                                                            data-toggle="tooltip"
                                                            title="Remove Dashboard"
                                                            onClick={this.deleteDashboard.bind(this, dashboard)}
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </button>
                                                </GroupOptionsControllerLayout>
                                            </div>
                                        </td>
                                    </tr>
                              )
                            })
                        }
                        <EmptyTableLayout results={this.props.dashboards} columns={5}/>
                        </tbody>
                    </table>
                    {this.props.children}
                </div>
            </>
    )
  }
}

// Set defaultProps
DashboardsTable.defaultProps = {
  dashboards: []
}

// Export Class
export { DashboardsTable }
