import React, { Component } from 'react'
import { EmptyTableLayout } from '../../../layouts'
import { history } from '../../../../routing/History'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { Session } from '../../../../state/utils/session'
import { Link } from 'react-router-dom'
import { ExecutionsModal } from '../executions-modal'
import Swal from 'sweetalert2'
import { sourceOperations } from '../../../../state/ducks/sources'
import { connect } from 'react-redux'

class SourcesTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session(),
      source_to_execute: {}
    }

    this.goToSourceEditor = this.goToSourceEditor.bind(this)
    this.selectSource = this.selectSource.bind(this)
    this.deleteSource = this.deleteSource.bind(this)
    this.executeETL = this.executeETL.bind(this)
  }

  executeETL (source) {
    this.setState({
      source_to_execute: source
    }, () => {
      window.jQuery('#executions-modal').modal('show')
    })
  }

  goToSourceEditor (source) {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_SOURCE_EDITOR_PATH
        .replace(':source_id', source.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  selectSource (source) {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_SOURCE_DETAILS_PATH
        .replace(':source_id', source.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  deleteSource (source) {
    Swal.fire({
      title: '',
      html: `<b>Por favor confirme la eliminación de la fuente ${source.name}?</b>`,
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.dismiss !== 'backdrop') {
        if (result.value) {
          this.props.removeSource(this.props.params, source.id)
        }
      }
    })
  }

  render () {
    return (
            <>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered table-condensed text-center">
                        <thead>
                        <tr>
                            <th className="text-center">ID</th>
                            <th className="text-center">Name</th>
                            <th className="text-center">Origin Connection</th>
                            <th className="text-center">Destination Connection</th>
                            <th className="text-center">Options</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.sources.map((source) => {
                              return (
                                    <tr key={source.id}>
                                        <td className="text-center">{source.id}</td>
                                        <td className="text-center">{source.name}</td>
                                        <td className="text-center">
                                            <Link to={
                                                ROUTING_PATHS.CLIENT_DASHBOARD_CONNECTION_DETAILS_PATH
                                                  .replace(':client_id', this.state.session.getClientSession())
                                                  .replace(':connection_id', source.origin_connection)
                                            }>
                                                {source.origin_connection_name}
                                            </Link>
                                        </td>
                                        <td className="text-center">
                                            <Link to={
                                                ROUTING_PATHS.CLIENT_DASHBOARD_CONNECTION_DETAILS_PATH
                                                  .replace(':client_id', this.state.session.getClientSession())
                                                  .replace(':connection_id', source.destination_connection)
                                            }>
                                                {source.destination_connection_name}
                                            </Link>
                                        </td>
                                        <td className="text-center">
                                            <div className="btn-group">
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Run ETL"
                                                        onClick={this.executeETL.bind(this, source)}
                                                >
                                                    ETL
                                                </button>
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Edit Source"
                                                        onClick={this.goToSourceEditor.bind(this, source)}
                                                >
                                                    <i className="fa fa-pen"></i>
                                                </button>
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Select Source"
                                                        onClick={this.selectSource.bind(this, source)}
                                                >
                                                    <i className="fa fa-eye"></i>
                                                </button>
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Remove Source"
                                                        onClick={this.deleteSource.bind(this, source)}
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                              )
                            })
                        }
                        <EmptyTableLayout results={this.props.sources} columns={5}/>
                        </tbody>
                    </table>
                    {this.props.children}
                </div>
                <ExecutionsModal object_type={'SOURCE'} object={this.state.source_to_execute}/>
            </>
    )
  }
}

// Set defaultProps
SourcesTable.defaultProps = {
  sources: []
}

const mapStateToProps = (state) => ({
  isLoading: state.sources.removeSourceReducer.loading
})

// mapStateToProps
const mapDispatchToProps = {
  removeSource: sourceOperations.remove
}

// Export Class
const _SourceTable = connect(mapStateToProps, mapDispatchToProps)(SourcesTable)
export { _SourceTable as SourcesTable }
