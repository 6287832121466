import reducer from './reducers'

import * as jobsOperations from './operations'
import * as jobsEndpoints from './paths'

export {
  jobsOperations,
  jobsEndpoints
}

export default reducer
