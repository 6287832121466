import React, { Component } from 'react'

class GroupTimelineCard extends Component {
  constructor (props) {
    super(props)

    this.state = {}
  }

  render () {
    return (
            <>
                <div className="block">
                    <div className="block-header bg-gray-lighter">
                        <ul className="block-options">
                            <li>
                                <button type="button" data-toggle="block-option"
                                        data-action="fullscreen_toggle">
                                    <i className="si si-size-fullscreen"/>
                                </button>
                            </li>
                            <li>
                                <button type="button" data-toggle="block-option"
                                        data-action="refresh_toggle" data-action-mode="demo">
                                    <i className="si si-refresh"/>
                                </button>
                            </li>
                        </ul>
                        <h3 className="block-title">
                            <i className="fa fa-newspaper-o"/> Updates
                        </h3>
                    </div>
                    <div className="block-content">
                        <ul className="list list-timeline pull-t">
                            {/* Facebook Notification */}
                            <li>
                                <div className="list-timeline-time">2 hrs ago</div>
                                <i className="fa fa-pen list-timeline-icon bg-default"/>
                                <div className="list-timeline-content">
                                    <p className="font-w600">New Name</p>
                                    <p className="font-s13">By: Maria</p>
                                </div>
                            </li>
                            {/* END Facebook Notification */}
                            {/* Facebook Notification */}
                            <li>
                                <div className="list-timeline-time">3 hrs ago</div>
                                <i className="fa fa-plus list-timeline-icon bg-success"/>
                                <div className="list-timeline-content">
                                    <p className="font-w600">Created</p>
                                    <p className="font-s13">By: Pepito</p>
                                </div>
                            </li>
                            {/* END Facebook Notification */}
                        </ul>
                    </div>
                </div>
            </>
    )
  }
}

// Set defaultProps
GroupTimelineCard.defaultProps = {
  group: { permissions: [], users: [] }
}

// Export Class
export { GroupTimelineCard }
