import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { InputSearchLayout, PageContainerLayout, PageHeaderLayout, PaginationLayout } from '../../../layouts'
import { JobHistoricalHeaderTiles, JobHistoricalTable } from '../../containers'
import { jobsOperations } from '../../../../state/ducks/jobs'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Session } from '../../../../state/utils/session'
import { JobHistoricalShape, PaginationShape } from '../../../../prop-types'

class JobsDetailsPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session(),
      params: {}
    }
    this.renderHeaderPaths = this.renderHeaderPaths.bind(this)
    this.fetchJobHistorical = this.fetchJobHistorical.bind(this)
    this.onPaginationChange = this.onPaginationChange.bind(this)
    this.onSearchChange = this.onSearchChange.bind(this)
    this.updateParams = this.updateParams.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.fetchJobHistorical()
  }

  fetchJobHistorical () {
    const { params } = this.state
    params.job_id = this.props.match.params.job_id
    this.props.fetchJobHistorical(params)
  }

  onPaginationChange (page) {
    this.updateParams('page', page)
  }

  onSearchChange (keywork) {
    this.updateParams('search', keywork)
  }

  updateParams (key, value) {
    const { params } = this.state
    params[key] = value
    if (key === 'search') {
      params.page = 1
    }
    this.setState({
      params
    }, () => this.fetchJobHistorical())
  }

  renderHeaderPaths () {
    return (
            <>
                <li>
                    <NavLink className="link-effect"
                             to={ROUTING_PATHS.CLIENT_DASHBOARD_JOB_PATH.replace(':client_id', this.state.session.getClientSession())}
                    >
                        Jobs
                    </NavLink>
                </li>
                <li>Job Historical Details</li>
            </>
    )
  }

  render () {
    return (
            <>
                <PageContainerLayout clientSidebar={true} showNavbarClientOptions={true}>
                    <PageHeaderLayout title={'Job Historical'} subTitle={'All Job Historical'}
                                      paths={this.renderHeaderPaths}/>
                    {/* Page Content */}
                    <div className="content content-boxed">
                        {/* Header Tiles */}
                        <JobHistoricalHeaderTiles all_job_historical={this.props.paginationData.totalItems}/>
                        {/* END Header Tiles */}
                        <div className={' block block-themed block-opt-refresh-icon7 ' + (this.props.isLoading ? ' block-opt-refresh ' : '')}>
                            <div className="block-header bg-primary-dark">
                                <ul className="block-options">
                                    <li>
                                        <button type="button" data-toggle="block-option"
                                                data-action="fullscreen_toggle">
                                            <i className="si si-size-fullscreen"/>
                                        </button>
                                    </li>
                                    <li>
                                        <button type="button"
                                                data-toggle="block-option"
                                                data-action="refresh_toggle"
                                                data-action-mode="demo"
                                                onClick={this.fetchJobHistorical}
                                        >
                                            <i className="si si-refresh"></i>
                                        </button>
                                    </li>
                                </ul>
                                <h3 className="block-title">All Job Historical</h3>
                            </div>
                            <div className="block-content">
                                {/* Search */}
                                <InputSearchLayout onSearchChange={this.onSearchChange}/>
                                {/* End Search */}
                                {/* Table */}
                                <JobHistoricalTable jobHistorical={this.props.jobHistorical}>
                                    {/* Pagination */}
                                    <PaginationLayout pagination={this.props.paginationData}
                                                      onPaginationChange={this.onPaginationChange}/>
                                    {/* End Pagination */}
                                </JobHistoricalTable>
                                {/* End Table */}
                            </div>
                        </div>
                    </div>
                    {/* END Page Content */}
                </PageContainerLayout>
            </>
    )
  };
}

// Set propTypes
const { arrayOf, func } = PropTypes
JobsDetailsPage.propTypes = {
  jobHistorical: arrayOf(JobHistoricalShape).isRequired,
  fetchJobHistorical: func.isRequired,
  paginationData: PaginationShape
}

// Set defaultProps
JobsDetailsPage.defaultProps = {
  jobHistorical: [],
  paginationData: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  isLoading: state.jobs.fetchJobHistoricalReducer.loading,
  jobHistorical: state.jobs.fetchJobHistoricalReducer.data.results,
  paginationData: state.jobs.fetchJobHistoricalReducer.data.pagination_data
})

// mapStateToProps
const mapDispatchToProps = {
  fetchJobHistorical: jobsOperations.listHistorical
}

// Export Class
const _JobsDetailsPage = connect(mapStateToProps, mapDispatchToProps)(JobsDetailsPage)
export { _JobsDetailsPage as JobsDetailsPage }
