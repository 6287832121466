import React, { Component } from 'react'
import { SortableColumnTable } from '../sortable-column-table'
import { ColumnsConverterTextarea } from '../columns-converter-textarea'
import arrayMove from 'array-move'
import SourceColumnNormalizationModalForm from '../source-column-normalization-modal-form'

class SourceColumnsForm extends Component {
  constructor (props) {
    super(props)

    // state
    this.state = {
      column_to_normalizate: {
        normalizations: []
      }
    }

    this.onSortEnd = this.onSortEnd.bind(this)
    this.onAddColumn = this.onAddColumn.bind(this)
    this.onDeleteColumn = this.onDeleteColumn.bind(this)
    this.onNormalizationsChange = this.onNormalizationsChange.bind(this)
    this.onColumnsChange = this.onColumnsChange.bind(this)
    this.onPositionChange = this.onPositionChange.bind(this)
    this.onOpenNormalizations = this.onOpenNormalizations.bind(this)
  }

  onSortEnd ({ oldIndex, newIndex }) {
    const columns = JSON.parse(JSON.stringify(this.props.source.columns))
    const list = []
    const sorted = arrayMove(columns, oldIndex, newIndex)
    sorted.forEach((column, index) => {
      list.push(column.column_name)
      column.position = index + 1
    })
    this.props.onChange('columns', sorted)
  }

  onPositionChange (index, oldValue, e) {
    const columns = JSON.parse(JSON.stringify(this.props.source.columns))
    columns.forEach((column, i) => {
      if (i === index) {
        if (e.target.name === 'index') {
          column[e.target.name] = !column[e.target.name]
        } else {
          column[e.target.name] = e.target.value
        }
      }
    })
    this.props.onChange('columns', columns)
  }

  onAddColumn () {
    const columns = JSON.parse(JSON.stringify(this.props.source.columns))
    const newCol = {
      id: '',
      column_name: '',
      position: columns.length + 1,
      index: '',
      rule: '',
      type: 'string',
      length: '',
      normalizations: []
    }
    columns.push(newCol)
    this.props.onChange('columns', columns)
  }

  onDeleteColumn (index) {
    const columns = JSON.parse(JSON.stringify(this.props.source.columns))
    columns.splice(index, 1)
    columns.forEach((column, index) => {
      column.position = index + 1
    })
    this.props.onChange('columns', columns)
  }

  onNormalizationsChange (newColumn) {
    const columns = JSON.parse(JSON.stringify(this.props.source.columns))
    columns.forEach((column, index) => {
      if (column.column_name === newColumn.column_name) {
        column.normalizations = newColumn.normalizations
      }
    })
    this.props.onChange('columns', columns)
  }

  onColumnsChange (newColumns) {
    let hasNormalization = false
    this.props.source.columns.forEach((col) => {
      if (col.normalizations.length) {
        hasNormalization = true
      }
    })
    if (hasNormalization) {
      if (window.confirm('All the normalizations will be deleted.')) {
        if (window.confirm('Are you sure?')) {
          this.props.onChange('columns', newColumns)
        }
      }
    } else {
      this.props.onChange('columns', newColumns)
    }
  }

  onOpenNormalizations (columnIndex) {
    const columns = JSON.parse(JSON.stringify(this.props.source.columns))
    this.setState({ column_to_normalizate: columns[columnIndex] }, () => {
      window.jQuery('#normalizations-modal').modal('show')
    })
  }

  render () {
    return (
            <>
                <div className="form-group">
                    <div className="col-sm-10 col-sm-offset-1" style={{ minHeight: '70px' }}>
                        <ColumnsConverterTextarea
                            onColumnsChange={this.onColumnsChange}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <div className="col-sm-10 col-sm-offset-1" style={{ minHeight: '70px' }}>
                        <SortableColumnTable
                            columns={this.props.source.columns ?? []}
                            onSortEnd={this.onSortEnd}
                            onPositionChange={this.onPositionChange}
                            onAddColumn={this.onAddColumn}
                            onDeleteColumn={this.onDeleteColumn}
                            onOpenNormalizations={this.onOpenNormalizations}
                        />
                    </div>
                </div>
                {/* Normalizations Modal */}
                <SourceColumnNormalizationModalForm
                    column={this.state.column_to_normalizate}
                    onChange={this.onNormalizationsChange}
                />
            </>
    )
  }
}

// Set defaultProps
SourceColumnsForm.defaultProps = {
  source: { columns: [] },
  onChange: () => {
  }
}

export { SourceColumnsForm }
