import React, { Component } from 'react'
import { history } from '../../../../routing/History'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { Session } from '../../../../state/utils/session'
import { Link } from 'react-router-dom'
import { jsonPrettify } from '../../../../utils/jsonPrettify'

class AlarmDetailsCard extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }
    this.goToAlarmEditor = this.goToAlarmEditor.bind(this)
  }

  goToAlarmEditor () {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_ALARM_EDITOR_PATH
        .replace(':alarm_id', this.props.alarm.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  render () {
    return (
            <>
                <div className="block">
                    <div className="block-header bg-gray-lighter">
                        <ul className="block-options">
                            <li>
                                <button type="button" onClick={this.goToAlarmEditor}>
                                    <i className="fa fa-edit"></i>
                                </button>
                            </li>
                        </ul>
                        <h3 className="block-title">
                            <i className="fa fa-info-circle"></i> Details
                        </h3>
                    </div>
                    <div className="block-content">
                        <ul className="list list-activity push">
                            <li>
                                <div className="font-w600">ID:</div>
                                <div>{this.props.alarm.id}</div>
                            </li>
                            <li>
                                <div className="font-w600">Alarm name:</div>
                                <div>{this.props.alarm.name}</div>
                            </li>
                            <li>
                                <div className="font-w600">Description:</div>
                                <div>{this.props.alarm.description}</div>
                            </li>
                            <li>
                                <div className="font-w600">type:</div>
                                <div>{this.props.alarm.alarm_type}</div>
                            </li>
                            <li>
                                <div className="font-w600">Connection:</div>
                                <div>
                                    <Link to={
                                        ROUTING_PATHS.CLIENT_DASHBOARD_CONNECTION_DETAILS_PATH
                                          .replace(':client_id', this.state.session.getClientSession())
                                          .replace(':connection_id', this.props.alarm.connection)
                                    }>
                                        {this.props.alarm.connection_name}
                                    </Link>
                                </div>
                            </li>
                            <li>
                                <div className="font-w600">Active:</div>
                                <div>{JSON.stringify(this.props.alarm.active)}</div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="block">
                    <div className="block-header bg-gray-lighter">
                        <ul className="block-options">
                            <li>
                                <button type="button" onClick={this.goToAlarmEditor}>
                                    <i className="fa fa-edit"></i>
                                </button>
                            </li>
                        </ul>
                        <h3 className="block-title">
                            <i className="fa fa-info-circle"></i> Email Settings
                        </h3>
                    </div>
                    <div className="block-content">
                        <ul className="list list-activity push">
                            <li>
                                <div className="font-w600">Emails:</div>
                                <div>
                                    <pre>
                                        <code>
                                            {jsonPrettify(this.props.alarm.emails)}
                                        </code>
                                    </pre>
                                </div>
                            </li>
                            <li>
                                <div className="font-w600">Subject:</div>
                                <div>{this.props.alarm.subject}</div>
                            </li>
                            <li>
                                <div className="font-w600">Template:</div>
                                <div>{this.props.alarm.template}</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </>
    )
  }
}

// Set defaultProps
AlarmDetailsCard.defaultProps = {
  alarm: {}
}

// Export Class
export { AlarmDetailsCard }
