import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { UserShape } from '../../../../prop-types'
import { userOperations } from '../../../../state/ducks/users'
import * as PATHS from '../../../../routing/Paths'
import { connect } from 'react-redux'
import { BaseFormWizard } from '../../../../assets/js/base_form_wizard'
import { AppUI } from '../../../../assets/js/app'
import { UserDataForm } from '../user-data-form'
import { UserGroupsForm } from '../user-groups-form'
import { GroupOptionsControllerLayout } from '../../../layouts/group-options-controller'

class UserWizardForm extends Component {
  constructor (props) {
    super(props)

    // state
    this.state = {
      user: {
        groups: []
      }
    }

    // handlers
    this.onUserDataChange = this.onUserDataChange.bind(this)
    this.onUserGroupsChange = this.onUserGroupsChange.bind(this)
    this.sendData = this.sendData.bind(this)
    AppUI.initHelpers(['highlightjs'])
  }

  componentDidMount () {
    BaseFormWizard.init()
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.user && 'id' in nextProps.user) {
      this.setState({ user: nextProps.user })
    }
  }

  onUserDataChange (name, value) {
    const user = this.state.user
    user[name] = value
    this.setState({
      user
    })
  }

  onUserGroupsChange (groups) {
    const user = this.state.user
    user.groups = JSON.parse(JSON.stringify(groups))
    this.setState({
      user: JSON.parse(JSON.stringify(user))
    })
  }

  sendData () {
    if (!this.props.isEditor) {
      this.props.saveUser(this.state.user)
    } else {
      this.props.updateUser(this.state.user.id, this.state.user)
    }
  }

  render () {
    return (
            <>
                <div className="content content-boxed">
                    <div className={' block block-themed block-opt-refresh-icon7 ' + (this.props.isLoading ? ' block-opt-refresh ' : '')}>
                        <div className="block-content">
                            {/* Simple Progress Wizard (.js-wizard-simple class is initialized in js/pages/base_forms_wizard.js) */}
                            {/* For more examples you can check out http://vadimg.com/twitter-bootstrap-wizard-example/ */}
                            <div className="js-wizard-simple block">
                                {/* Steps Progress */}
                                <div className="block-content block-content-mini block-content-full border-b">
                                    <div className="wizard-progress progress active remove-margin-b">
                                        <div className="progress-bar progress-bar-primary progress-bar-striped"
                                             role="progressbar" aria-valuenow="100" aria-valuemin="0"
                                             aria-valuemax="100"
                                             style={{ width: '0' }}>
                                        </div>
                                    </div>
                                </div>
                                {/* END Steps Progress */}

                                {/* Step Tabs */}
                                <ul className="nav nav-tabs nav-tabs-alt nav-justified">
                                    <li className="active">
                                        <a href="#simple-progress-step1" data-toggle="tab">1. User Data</a>
                                    </li>
                                    <GroupOptionsControllerLayout validFor={['ADMIN']}>
                                        <li>
                                            <a href="#simple-progress-step2" data-toggle="tab">2. Groups</a>
                                        </li>
                                    </GroupOptionsControllerLayout>
                                </ul>
                                {/* END Step Tabs */}

                                {/* Form */}
                                <form className="form-horizontal" action="javascript:void(0)">
                                    {/* Steps Content */}
                                    <div className="block-content tab-content" style={{ minHeight: '300px' }}>
                                        {/* Step 1 */}
                                        <div className="tab-pane fade fade-right in push-30-t push-50 active"
                                             id="simple-progress-step1">
                                            <UserDataForm
                                                user={this.state.user}
                                                onChange={this.onUserDataChange}
                                            />
                                        </div>
                                        {/* END Step 1 */}

                                        {/* Step 2 */}
                                        <GroupOptionsControllerLayout validFor={['ADMIN']}>
                                            <div className="tab-pane fade fade-right in push-30-t push-50"
                                                 id="simple-progress-step2">
                                                <UserGroupsForm
                                                    added_groups={this.state.user.groups}
                                                    onChange={this.onUserGroupsChange}
                                                />
                                            </div>
                                        </GroupOptionsControllerLayout>
                                        {/* END Step 2 */}
                                    </div>
                                    {/* END Steps Content */}

                                    {/* Steps Navigation */}
                                    <div className="block-content block-content-mini block-content-full border-t">
                                        <div className="row">
                                            {
                                                this.props.saveUserErrorResponse.error
                                                  ? <div className="col-xs-12 text-danger text-center">
                                                        {JSON.stringify(this.props.saveUserErrorResponse.error)}
                                                    </div>
                                                  : null
                                            }
                                            {
                                                this.props.updateUserErrorResponse.error
                                                  ? <div className="col-xs-12 text-danger text-center">
                                                        {JSON.stringify(this.props.updateUserErrorResponse.error)}
                                                    </div>
                                                  : null
                                            }
                                            <div className="col-xs-6">
                                                <button
                                                    className="wizard-prev btn btn-warning"
                                                    type="button"
                                                >
                                                    <i className="fa fa-arrow-circle-o-left"></i> Previous
                                                </button>
                                            </div>
                                            <div className="col-xs-6 text-right">
                                                <button
                                                    className="wizard-next btn btn-success"
                                                    type="button"
                                                >
                                                    Next <i className="fa fa-arrow-circle-o-right"></i>
                                                </button>
                                                <button
                                                    className="wizard-finish btn btn-primary"
                                                    type="button"
                                                    disabled={this.props.isLoading}
                                                    onClick={this.sendData}
                                                >
                                                    {
                                                        this.props.isLoading
                                                          ? <>
                                                                <i className="ml-1 fa fa-spinner fa-spin pull-right"></i>
                                                                <span>Submit</span>
                                                            </>
                                                          : <>
                                                                <i className="ml-1 si si-login pull-right"></i>
                                                                <span>Submit</span>
                                                            </>
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* END Steps Navigation */}
                                </form>
                                {/* END Form */}
                            </div>
                            {/* END Simple Progress Wizard */}
                            {/* <pre className="pre-sh push"> */}
                            {/*    <code className="json"> */}
                            {/*    {JSON.stringify(this.state.user)} */}
                            {/*    </code> */}
                            {/* </pre> */}
                        </div>
                    </div>
                </div>
            </>
    )
  }
}

// Set propTypes
UserWizardForm.propTypes = {
  user: UserShape,
  saveUserErrorResponse: PropTypes.object,
  updateUserErrorResponse: PropTypes.object,
  isEditor: PropTypes.bool
}

// Set defaultProps
UserWizardForm.defaultProps = {
  user: {},
  saveUserErrorResponse: {},
  updateUserErrorResponse: {},
  isEditor: false,
  afterUpdateRedirectTo: PATHS.USER_DETAILS_PATH
}

// mapStateToProps
const mapStateToProps = (state) => ({
  isLoading: state.users.saveUserReducer.loading || state.users.updateUserReducer.loading,
  saveUserErrorResponse: state.users.saveUserReducer.error_data,
  updateUserErrorResponse: state.users.updateUserReducer.error_data
})

// mapStateToProps
const mapDispatchToProps = {
  saveUser: userOperations.save,
  updateUser: userOperations.update
}

// Export Class
const _UserWizardForm = connect(mapStateToProps, mapDispatchToProps)(UserWizardForm)
export { _UserWizardForm as UserWizardForm }
