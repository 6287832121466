export default {
  // env: process.env.NODE_ENV || 'development',
  apiEndPoint: process.env.REACT_APP_ENDPOINT || 'https://pruebas-meliin-bk.simetrik.com/',
  nameCookieAuth: process.env.REACT_APP_COOKIE_AUTH || 'meliin_test_auth',
  nameCookieGroup: process.env.REACT_APP_COOKIE_GROUP || 'meliin_test_group',
  nameCookieLastSession: process.env.REACT_APP_COOKIE_LAST_SESSION || 'meliin_test_last_session',
  nameCookieClientSelected: process.env.REACT_APP_COOKIE_CLIENT_SELECT || 'meliin_test_client_selected',
  domainCookieProfile: process.env.REACT_APP_COOKIE_DOMAIN || 'localhost',
  frontendUrlV2: process.env.REACT_APP_FRONTEND_V2 || 'https://pruebas-meliin2.simetrik.com',
  featureMode: process.env.REACT_APP_FEATURE_MODE || null,
  // sentryEnvironment: process.env.SENTRY_ENVIRONMENT || undefined,
  // sentryDSN: process.env.SENTRY_DSN || 'https://6d5e71d625154d2993389ba506383380@sentry.io/3471749',
  zendeskKey: process.env.ZENDESK_KEY || 'bea70950-b9fb-42be-a890-6c6ac56aaea2',
  // gaTrackingID: process.env.GA_TRACKING_ID || undefined
  appEnviroment: process.env.REACT_APP_ENVIRONMENT || null
}
