import React, { Component } from 'react'
import { EmptyTableLayout } from '../empty-table'

class TableTbodyLayout extends Component {
  constructor (props) {
    super(props)

    // State
    this.state = {
      rows: []
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.rows.length || nextProps.allTransactionsSelected !== this.props.allTransactionsSelected) {
      this.updateStateRows(nextProps.rows, nextProps.allTransactionsSelected)
    }
  }

  updateStateRows (newRows, allTransactionsSelected) {
    const currentRows = JSON.parse(JSON.stringify(this.state.rows))
    newRows.forEach(nr => {
      if (!currentRows.find(cr => cr.id === nr.id)) {
        nr.checked = allTransactionsSelected
        currentRows.push(nr)
      }
    })
    this.setState({ rows: currentRows })
  }

  isChecked (rowId) {
    if (this.props.allTransactionsSelected) {
      return true
    } else {
      return !!this.state.rows.find(cr => cr.id === rowId && cr.checked)
    }
  }

  handleRowCheck (row) {
    const currentRows = this.state.rows
    let allTransactionsSelected = this.props.allTransactionsSelected
    currentRows.forEach(cr => {
      if (cr.id === row.id) {
        cr.checked = !cr.checked
      }
      if (!cr.checked) {
        allTransactionsSelected = false
      }
    })
    this.setState({
      rows: currentRows
    }, () => {
      this.props.onAllTransactionsSelected(allTransactionsSelected)
    })
  }

  render () {
    return (
            <>
                <tbody>
                {
                    this.props.rows.map((object, index) => {
                      return (
                            <tr key={index}
                                className={(this.isChecked(object.id) ? 'row-active' : '')}
                                onClick={this.handleRowCheck.bind(this, object, false)}
                                onContextMenu={this.handleRowCheck.bind(this, object)}
                            >
                                <td className="text-center checkbox-row">
                                    <label className="css-input css-checkbox css-checkbox-default">
                                        <input type="checkbox"
                                               name="check-item"
                                               onChange={this.handleRowCheck.bind(this, object)}
                                               checked={this.isChecked(object.id)}
                                        />
                                        <span/>
                                    </label>
                                </td>
                                {
                                    Object.keys(object).forEach((key, index) => {
                                      if (key !== 'checked') {
                                        return (
                                                <td className="text-center"
                                                    key={index}
                                                >
                                                    {JSON.stringify(object[key])}
                                                </td>
                                        )
                                      }
                                    })
                                }
                            </tr>
                      )
                    })
                }

                {/* Empty Table */}
                <EmptyTableLayout
                    results={this.props.rows}
                    columns={Object.keys(this.props.rows).length}
                />
                {/* End Empty Table */}

                </tbody>
            </>
    )
  }
}

// Set defaultProps
TableTbodyLayout.defaultProps = {
  rows: [],
  tableRef: null,
  allTransactionsSelected: false,
  onAllTransactionsSelected: () => {
  }
}

export { TableTbodyLayout }
