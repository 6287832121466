import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { ConciliationShape } from '../../../../prop-types'
import { conciliationOperations } from '../../../../state/ducks/conciliations'
import { connect } from 'react-redux'
import { BaseFormWizard } from '../../../../assets/js/base_form_wizard'
import { ConciliationDataForm } from '../conciliation-data-form'
import { AppUI } from '../../../../assets/js/app'
import { Session } from '../../../../state/utils/session'

class ConciliationWizardForm extends Component {
  constructor (props) {
    super(props)

    // state
    this.state = {
      session: new Session(),
      conciliation: { parameters: {} }
    }

    // handlers
    this.onConciliationDataChange = this.onConciliationDataChange.bind(this)
    this.sendData = this.sendData.bind(this)
    AppUI.initHelpers(['highlightjs'])
  }

  componentDidMount () {
    BaseFormWizard.init()
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.conciliation && 'id' in nextProps.conciliation) {
      this.setState({ conciliation: nextProps.conciliation })
    }
  }

  onConciliationDataChange (name, value) {
    const conciliation = JSON.parse(JSON.stringify(this.state.conciliation))
    conciliation[name] = value
    this.setState({
      conciliation
    })
  }

  sendData () {
    if (!this.props.isEditor) {
      const conciliation = this.state.conciliation
      conciliation.client = this.state.session.getClientSession()
      this.props.saveConciliation(this.state.conciliation)
    } else {
      this.props.updateConciliation(this.state.conciliation.id, this.state.conciliation)
    }
  }

  render () {
    return (
            <>
                <div className="content content-boxed">
                    <div className={' block block-themed block-opt-refresh-icon7 ' + (this.props.isLoading ? ' block-opt-refresh ' : '')}>
                        <div className="block-content">
                            {/* Simple Progress Wizard (.js-wizard-simple class is initialized in js/pages/base_forms_wizard.js) */}
                            {/* For more examples you can check out http://vadimg.com/twitter-bootstrap-wizard-example/ */}
                            <div className="js-wizard-simple block">
                                {/* Steps Progress */}
                                <div className="block-content block-content-mini block-content-full border-b">
                                    <div className="wizard-progress progress active remove-margin-b">
                                        <div className="progress-bar progress-bar-primary progress-bar-striped"
                                             role="progressbar" aria-valuenow="100" aria-valuemin="0"
                                             aria-valuemax="100"
                                             style={{ width: '0' }}>
                                        </div>
                                    </div>
                                </div>
                                {/* END Steps Progress */}

                                {/* Step Tabs */}
                                <ul className="nav nav-tabs nav-tabs-alt nav-justified">
                                    <li className="active">
                                        <a href="#simple-progress-step1" data-toggle="tab">1. Conciliation Data</a>
                                    </li>
                                </ul>
                                {/* END Step Tabs */}

                                {/* Form */}
                                <form className="form-horizontal" action="javascript:void(0)">
                                    {/* Steps Content */}
                                    <div className="block-content tab-content" style={{ minHeight: '300px' }}>
                                        {/* Step 1 */}
                                        <div className="tab-pane fade fade-right in push-30-t push-50 active"
                                             id="simple-progress-step1">
                                            <ConciliationDataForm
                                                conciliation={this.state.conciliation}
                                                onChange={this.onConciliationDataChange}
                                            />
                                        </div>
                                        {/* END Step 1 */}

                                    </div>
                                    {/* END Steps Content */}

                                    {/* Steps Navigation */}
                                    <div className="block-content block-content-mini block-content-full border-t">
                                        <div className="row">
                                            {
                                                this.props.saveConciliationErrorResponse.error
                                                  ? <div className="col-xs-12 text-danger text-center">
                                                        {JSON.stringify(this.props.saveConciliationErrorResponse.error)}
                                                    </div>
                                                  : null
                                            }
                                            {
                                                this.props.updateConciliationErrorResponse.error
                                                  ? <div className="col-xs-12 text-danger text-center">
                                                        {JSON.stringify(this.props.updateConciliationErrorResponse.error)}
                                                    </div>
                                                  : null
                                            }
                                            <div className="col-xs-6">
                                                <button
                                                    className="wizard-prev btn btn-warning"
                                                    type="button"
                                                >
                                                    <i className="fa fa-arrow-circle-o-left"></i> Previous
                                                </button>
                                            </div>
                                            <div className="col-xs-6 text-right">
                                                <button
                                                    className="wizard-next btn btn-success"
                                                    type="button"
                                                >
                                                    Next <i className="fa fa-arrow-circle-o-right"></i>
                                                </button>
                                                <button
                                                    className="wizard-finish btn btn-primary"
                                                    type="button"
                                                    disabled={this.props.isLoading}
                                                    onClick={this.sendData}
                                                >
                                                    {
                                                        this.props.isLoading
                                                          ? <>
                                                                <i className="ml-1 fa fa-spinner fa-spin pull-right"></i>
                                                                <span>Submit</span>
                                                            </>
                                                          : <>
                                                                <i className="ml-1 si si-login pull-right"></i>
                                                                <span>Submit</span>
                                                            </>
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* END Steps Navigation */}
                                </form>
                                {/* END Form */}
                            </div>
                            {/* END Simple Progress Wizard */}
                            {/* <pre className="pre-sh push"> */}
                            {/*    <code className="json"> */}
                            {/*    {JSON.stringify(this.state.conciliation)} */}
                            {/*    </code> */}
                            {/* </pre> */}
                        </div>
                    </div>
                </div>
            </>
    )
  }
}

// Set propTypes
ConciliationWizardForm.propTypes = {
  conciliation: ConciliationShape,
  saveConciliationErrorResponse: PropTypes.object,
  updateConciliationErrorResponse: PropTypes.object,
  isEditor: PropTypes.bool
}

// Set defaultProps
ConciliationWizardForm.defaultProps = {
  conciliation: {},
  saveConciliationErrorResponse: {},
  updateConciliationErrorResponse: {},
  isEditor: false
}

// mapStateToProps
const mapStateToProps = (state) => ({
  isLoading: state.conciliations.saveConciliationReducer.loading || state.conciliations.updateConciliationReducer.loading,
  saveConciliationErrorResponse: state.conciliations.saveConciliationReducer.error_data,
  updateConciliationErrorResponse: state.conciliations.updateConciliationReducer.error_data
})

// mapStateToProps
const mapDispatchToProps = {
  saveConciliation: conciliationOperations.save,
  updateConciliation: conciliationOperations.update
}

// Export Class
const _ConciliationWizardForm = connect(mapStateToProps, mapDispatchToProps)(ConciliationWizardForm)
export { _ConciliationWizardForm as ConciliationWizardForm }
