import React from 'react'
import { NavLink } from 'react-router-dom'
import * as ROUTING_PATHS from '../../../../routing/Paths'

const Logout = () => (
    <>
        <div className=" block block-themed block-opt-refresh-icon7 animated fadeIn">
            <div className="block-content block-content-full block-content-narrow">
                {/* Title */}
                <h1 className="h2 font-w600 push-30-t push-5">Simetrik</h1>
                <p>Sesion Cerrada.</p>
                {/* END Title */}

                <NavLink name="button-sso"
                        className="btn btn-block btn-warning"
                   to={ROUTING_PATHS.LOGIN_PATH}
                >
                    Ir a Login
                    <i className="ml-1 si si-login pull-right"></i>
                </NavLink>
                {/* END Form */}
            </div>
        </div>
    </>
)

export { Logout as LogoutForm }
