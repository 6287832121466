import React, { Component } from 'react'
import { PageContainerLayout, PageHeaderLayout } from '../../../layouts'
import { ExtractionWizardForm } from '../../containers/extraction-wizard-form'
import { NavLink } from 'react-router-dom'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { ExtractionShape } from '../../../../prop-types'
import * as PropTypes from 'prop-types'
import { extractionOperations } from '../../../../state/ducks/extractions'
import { connect } from 'react-redux'
import { Session } from '../../../../state/utils/session'

class ExtractionEditorPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }

    this.renderHeaderPaths = this.renderHeaderPaths.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.props.getExtraction(this.props.match.params.extraction_id)
  }

  renderHeaderPaths () {
    return (
            <>
                <li>
                    <NavLink className="link-effect"
                             to={ROUTING_PATHS.CLIENT_DASHBOARD_EXTRACTIONS_PATH.replace(':client_id', this.state.session.getClientSession())}
                    >
                        Extractions
                    </NavLink>
                </li>
                <li>Edit Extraction</li>
            </>
    )
  }

  render () {
    return (
            <>
                <PageContainerLayout clientSidebar={true} showNavbarClientOptions={true}>
                    <PageHeaderLayout
                        title={this.props.extraction.name}
                        subTitle={'Edit Extraction'}
                        paths={this.renderHeaderPaths}
                    />
                    <ExtractionWizardForm
                        extraction={this.props.extraction}
                        isEditor={true}
                    />
                </PageContainerLayout>
            </>
    )
  }
}

// Set propTypes
ExtractionEditorPage.propTypes = {
  extraction: ExtractionShape,
  getExtractionErrorResponse: PropTypes.object
}

// Set defaultProps
ExtractionEditorPage.defaultProps = {
  extraction: {},
  getExtractionErrorResponse: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  extraction: state.extractions.getExtractionReducer.data,
  isLoading: state.extractions.getExtractionReducer.loading,
  getExtractionErrorResponse: state.extractions.getExtractionReducer.error_data
})

// mapStateToProps
const mapDispatchToProps = {
  getExtraction: extractionOperations.get
}

// Export Class
const _ExtractionEditorPage = connect(mapStateToProps, mapDispatchToProps)(ExtractionEditorPage)
export { _ExtractionEditorPage as ExtractionEditorPage }
