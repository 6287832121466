import React, { useEffect, useState } from 'react'
import { validationsAutomationsOperations } from '../../../../state/ducks/validations-automations'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import PropTypes from 'prop-types'

function AutomationValidations ({ fetchValidationsAutomationsConciliations, automationsConciliationsList, automation, fetchValidationsAutomationsSources, automationsSourcesList, fetchValidationsAutomationsAlarms, automationsAlarmList, resetValidationsAutomations, fetchValidationsAutomationsWorkerCalls, automationsWorkerCallList }) {
  const [showConciliation, setShowConciliation] = useState(false)
  const [showSources, setShowSources] = useState(false)
  const [showAlarms, setShowAlarms] = useState(false)
  const [showWorkCalls, setShowWorkCalls] = useState(false)
  useEffect(() => {
    if (automation) {
      resetValidationsAutomations()
      if (automation.conciliations.length > 0) {
        const conciliationsIds = automation.conciliations.map((conciliation) => conciliation.id).join(',')
        fetchValidationsAutomationsConciliations(automation.id, conciliationsIds)
      }
      if (automation.sources.length > 0) {
        const sourcesIds = automation.sources.map((source) => source.id).join(',')
        fetchValidationsAutomationsSources(automation.id, sourcesIds)
      }

      if (automation.alarms.length > 0) {
        const alarmsIds = automation.alarms.map((alarm) => alarm.id).join(',')
        fetchValidationsAutomationsAlarms(automation.id, alarmsIds)
      }

      if (automation.worker_calls.length > 0) {
        const workerCallsIds = automation.worker_calls.map((workerCall) => workerCall.id).join(',')
        fetchValidationsAutomationsWorkerCalls(automation.id, workerCallsIds)
      }
    }
  }, [automation])

  const handleShowConciliation = () => {
    setShowConciliation(!showConciliation)
  }

  const handleShowSources = () => {
    setShowSources(!showSources)
  }

  const handleShowAlarms = () => {
    setShowAlarms(!showAlarms)
  }

  const handleShowWorkCalls = () => {
    setShowWorkCalls(!showWorkCalls)
  }

  return (
    <div className="form-group">
      <div className="col-sm-6 col-sm-offset-3">
        <div className="form-material">

          <div className=" block block-themed block-opt-refresh-icon7">
            <div className="block-header bg-primary-dark">
              <ul className="block-options">
                <li>
                  <button type="button" data-toggle="block-option" data-action="refresh_toggle"
                    data-action-mode="demo" onClick={
                      () => {
                        handleShowConciliation()
                      }
                    }> <FontAwesomeIcon
                      icon={!showConciliation ? faChevronDown : faChevronUp}
                    /></button>
                </li>
              </ul>
              <h3 className="block-title">Conciliations <span className="label label-danger">{automationsConciliationsList.length}</span> </h3> </div>
            <div className="block-content">
              {showConciliation && (
                <table
                  className="table table-striped table-bordered table-condensed table-hover text-center ">
                  <thead>
                    <tr>
                      <th>Auto</th>
                      <th>Duplicated</th>
                    </tr>
                  </thead>
                  <tbody>
                    {automationsConciliationsList.map((automation, index) => (
                      <tr key={index}>
                        <td>{automation.auto}</td>
                        <td>
                          {automation.duplicated_conciliations.map((duplicated, index) => (
                            <p key={index}>{duplicated}</p>
                          ))}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>)}
            </div>
          </div>

          <div className=" block block-themed block-opt-refresh-icon7">
            <div className="block-header bg-primary-dark">
              <ul className="block-options">
                <li>
                  <button type="button" data-toggle="block-option" data-action="refresh_toggle"
                    data-action-mode="demo" onClick={
                      () => {
                        handleShowSources()
                      }
                    }> <FontAwesomeIcon
                      icon={!showSources ? faChevronDown : faChevronUp}
                    /></button>
                </li>
              </ul>
              <h3 className="block-title">Sources <span className="label label-danger">{automationsSourcesList.length}</span> </h3> </div>
            <div className="block-content">
              {showSources && (
                <table
                  className="table table-striped table-bordered table-condensed table-hover text-center ">
                  <thead>
                    <tr>
                      <th>Auto</th>
                      <th>Duplicated</th>
                    </tr>
                  </thead>
                  <tbody>
                    {automationsSourcesList.map((sources, index) => (
                      <tr key={index}>
                        <td>{sources.auto}</td>
                        <td>
                          {sources.duplicated_sources.map((duplicated, index) => (
                            <p key={index}>{duplicated}</p>
                          ))}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>)}
            </div>
          </div>

          <div className=" block block-themed block-opt-refresh-icon7">
            <div className="block-header bg-primary-dark">
              <ul className="block-options">
                <li>
                  <button type="button" data-toggle="block-option" data-action="refresh_toggle"
                    data-action-mode="demo" onClick={
                      () => {
                        handleShowAlarms()
                      }
                    }> <FontAwesomeIcon
                      icon={!showAlarms ? faChevronDown : faChevronUp}
                    /></button>
                </li>
              </ul>
              <h3 className="block-title">Alarms <span className="label label-danger">{automationsAlarmList.length}</span> </h3> </div>
            <div className="block-content">
              {showAlarms && (
                <table
                  className="table table-striped table-bordered table-condensed table-hover text-center ">
                  <thead>
                    <tr>
                      <th>Auto</th>
                      <th>Duplicated</th>
                    </tr>
                  </thead>
                  <tbody>
                    {automationsAlarmList.map((alarms, index) => (
                      <tr key={index}>
                        <td>{alarms.auto}</td>
                        <td>
                          {alarms.duplicated_alarms.map((duplicated, index) => (
                            <p key={index}>{duplicated}</p>
                          ))}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>)}
            </div>
          </div>

          <div className=" block block-themed block-opt-refresh-icon7">
            <div className="block-header bg-primary-dark">
              <ul className="block-options">
                <li>
                  <button type="button" data-toggle="block-option" data-action="refresh_toggle"
                    data-action-mode="demo" onClick={
                      () => {
                        handleShowWorkCalls()
                      }
                    }> <FontAwesomeIcon
                      icon={!showWorkCalls ? faChevronDown : faChevronUp}
                    /></button>
                </li>
              </ul>
              <h3 className="block-title">Worker Calls <span className="label label-danger">{automationsWorkerCallList.length}</span> </h3> </div>
            <div className="block-content">
              {showWorkCalls && (
                <table
                  className="table table-striped table-bordered table-condensed table-hover text-center ">
                  <thead>
                    <tr>
                      <th>Auto</th>
                      <th>Duplicated</th>
                    </tr>
                  </thead>
                  <tbody>
                    {automationsWorkerCallList.map((wc, index) => (
                      <tr key={index}>
                        <td>{wc.auto}</td>
                        <td>
                          {wc.duplicated_worker_calls.map((duplicated, index) => (
                            <p key={index}>{duplicated}</p>
                          ))}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>)}
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

AutomationValidations.defaultProps = {
  automationsConciliationsList: [],
  automationsSourcesList: [],
  automationsAlarmList: [],
  automationsWorkerCallList: []
}

AutomationValidations.propTypes = {
  fetchValidationsAutomations: PropTypes.func,
  resetValidationsAutomations: PropTypes.func,
  automationsConciliationsList: PropTypes.array,
  automationsSourcesList: PropTypes.array,
  automationsAlarmList: PropTypes.array,
  automationsWorkerCallList: PropTypes.array,
  automationsLoading: PropTypes.bool,
  automationsCompleted: PropTypes.bool,
  fetchValidationsAutomationsConciliations: PropTypes.func,
  fetchValidationsAutomationsSources: PropTypes.func,
  fetchValidationsAutomationsAlarms: PropTypes.func,
  fetchValidationsAutomationsWorkerCalls: PropTypes.func
}

const mapStateToProps = (state) => ({
  automationsConciliationsList: state.validationsAutomations.fetchValidationsAutomationsReducer.conciliations,
  automationsSourcesList: state.validationsAutomations.fetchValidationsAutomationsReducer.sources,
  automationsAlarmList: state.validationsAutomations.fetchValidationsAutomationsReducer.alarms,
  automationsWorkerCallList: state.validationsAutomations.fetchValidationsAutomationsReducer.workerCalls,
  automationsLoading: state.automations.getAutomationReducer.loading,
  automationsCompleted: state.automations.getAutomationReducer.completed
})

const mapDispatchToProps = {
  fetchValidationsAutomationsConciliations: validationsAutomationsOperations.listConciliations,
  fetchValidationsAutomationsSources: validationsAutomationsOperations.listSources,
  fetchValidationsAutomationsAlarms: validationsAutomationsOperations.listAlarms,
  fetchValidationsAutomationsWorkerCalls: validationsAutomationsOperations.listWorkerCalls,
  resetValidationsAutomations: validationsAutomationsOperations.resetValidationsAutomations
}

const _AutomationValidations = connect(
  mapStateToProps,
  mapDispatchToProps
)(AutomationValidations)

export { _AutomationValidations as AutomationValidations }
