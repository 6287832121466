import React, { Component } from 'react'
import AsyncSelect from 'react-select/async'

class SelectSearchLayout extends Component {
  selectRef = null

  state = {
    keyword: '',
    options: []
  }

  promiseOptions = inputValue =>
    new Promise(resolve => {
      setTimeout(() => {
        resolve(this.props.getOptions())
      }, 1000)
    })

  handleInputChange (value) {
    this.setState({
      keyword: value
    })
    if (value && value.length > 1) {
      if (value.length % 2 === 0) {
        if (this.props.onSearchChange) {
          this.props.onSearchChange(value)
        }
      }
    }
  }

  handleKeyDown (e) {
    if (e.key === 'Enter') {
      this.props.onSearchChange(this.state.keyword)
    }
  }

  handleChange (item) {
    this.props.onSelectItem(item)
  }

  clearValue = () => {
    this.selectRef.select.select.clearValue()
  }

  render () {
    return (
      <AsyncSelect
        ref={(ref) => {
          this.selectRef = ref
        }}
        placeholder={
          this.props.placeholder ? this.props.placeholder : 'Search for item'
        }
        cacheOptions={false}
        defaultOptions
        defaultValue={null}
        isClearable={this.props.isClearable}
        loadOptions={this.promiseOptions.bind(this)}
        menuPortalTarget={this.props.isInModal ? '' : document.querySelector('body')}
        onInputChange={this.handleInputChange.bind(this)}
        onKeyDown={this.handleKeyDown.bind(this)}
        onChange={this.handleChange.bind(this)}
      />
    )
  }
}

SelectSearchLayout.defaultProps = {
  placeholder: 'Type to search an item...',
  options: [],
  isClearable: false,
  onSearchChange: function () {

  },
  onSelectItem: function () {

  }
}

export default SelectSearchLayout
