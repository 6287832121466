// AUTOMATIONS
export const FETCH_AUTOMATIONS_REQUEST = 'session/FETCH_AUTOMATIONS_REQUEST'
export const FETCH_AUTOMATIONS_REQUEST_SUCCESS = 'session/FETCH_AUTOMATIONS_REQUEST_SUCCESS'
export const FETCH_AUTOMATIONS_REQUEST_FAILURE = 'session/FETCH_AUTOMATIONS_REQUEST_FAILURE'
export const FETCH_AUTOMATIONS_REQUEST_COMPLETED = 'session/FETCH_AUTOMATIONS_REQUEST_COMPLETED'

export const GET_AUTOMATION_REQUEST = 'session/GET_AUTOMATION_REQUEST'
export const GET_AUTOMATION_REQUEST_SUCCESS = 'session/GET_AUTOMATION_REQUEST_SUCCESS'
export const GET_AUTOMATION_REQUEST_FAILURE = 'session/GET_AUTOMATION_REQUEST_FAILURE'
export const GET_AUTOMATION_REQUEST_COMPLETED = 'session/GET_AUTOMATION_REQUEST_COMPLETED'

export const SAVE_AUTOMATION_REQUEST = 'session/SAVE_AUTOMATION_REQUEST'
export const SAVE_AUTOMATION_REQUEST_SUCCESS = 'session/SAVE_AUTOMATION_REQUEST_SUCCESS'
export const SAVE_AUTOMATION_REQUEST_FAILURE = 'session/SAVE_AUTOMATION_REQUEST_FAILURE'
export const SAVE_AUTOMATION_REQUEST_COMPLETED = 'session/SAVE_AUTOMATION_REQUEST_COMPLETED'

export const UPDATE_AUTOMATION_REQUEST = 'session/UPDATE_AUTOMATION_REQUEST'
export const UPDATE_AUTOMATION_REQUEST_SUCCESS = 'session/UPDATE_AUTOMATION_REQUEST_SUCCESS'
export const UPDATE_AUTOMATION_REQUEST_FAILURE = 'session/UPDATE_AUTOMATION_REQUEST_FAILURE'
export const UPDATE_AUTOMATION_REQUEST_COMPLETED = 'session/UPDATE_AUTOMATION_REQUEST_COMPLETED'

export const DELETE_AUTOMATION_REQUEST = 'session/DELETE_AUTOMATION_REQUEST'
export const DELETE_AUTOMATION_REQUEST_SUCCESS = 'session/DELETE_AUTOMATION_REQUEST_SUCCESS'
export const DELETE_AUTOMATION_REQUEST_FAILURE = 'session/DELETE_AUTOMATION_REQUEST_FAILURE'
export const DELETE_AUTOMATION_REQUEST_COMPLETED = 'session/DELETE_AUTOMATION_REQUEST_COMPLETED'
