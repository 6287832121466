import React from 'react'
import './loadingPages.styles.scss'

export const LoadingPage = () => {
  return (
    <div className="loading">
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <h3>Consultando...</h3>
    </div>
  )
}
