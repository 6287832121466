import React, { Component } from 'react'

class AlarmQueryForm extends Component {
  constructor (props) {
    super(props)

    // props
    this.props = {}

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange (event) {
    this.props.onChange(event.target.name, event.target.value)
  }

  render () {
    return (
            <>
                <div className="form-group">
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <div className="form-material">
                            <input className="form-control"
                                   type="number"
                                   id="input-delay"
                                   placeholder="Please enter the delay"
                                   name="delay"
                                   value={this.props.alarm.delay}
                                   onChange={this.handleChange}
                            />
                            <label htmlFor="input-delay">Delay</label>
                        </div>
                    </div>
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <div className="form-material">
                            <textarea className="form-control"
                                      rows={4}
                                      id="input-query"
                                      placeholder="Please enter the query"
                                      name="query"
                                      value={this.props.alarm.query}
                                      onChange={this.handleChange}
                            />
                            <label htmlFor="input-query">Query</label>
                        </div>
                    </div>
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <br/>
                        <div className="form-material">
                            <textarea className="form-control"
                                      rows={4}
                                      id="input-query_message"
                                      placeholder="Please enter the query message"
                                      name="query_message"
                                      value={this.props.alarm.query_message}
                                      onChange={this.handleChange}
                            />
                            <label htmlFor="input-query">Query Message</label>
                        </div>
                    </div>
                </div>
            </>
    )
  }
}

// Set default props
AlarmQueryForm.defaultProps = {
  alarm: {},
  onChange: () => {
  }
}

export {
  AlarmQueryForm
}
