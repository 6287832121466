import React, { Component } from 'react'
import { history } from '../../../../routing/History'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { Session } from '../../../../state/utils/session'

class DashboardDetailsCard extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }
    this.goToDashboardEditor = this.goToDashboardEditor.bind(this)
  }

  goToDashboardEditor () {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_DASHBOARD_EDITOR_PATH
        .replace(':dashboard_id', this.props.dashboard.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  render () {
    return (
            <>
                <div className="block">
                    <div className="block-header bg-gray-lighter">
                        <ul className="block-options">
                            <li>
                                <button type="button" onClick={this.goToDashboardEditor}>
                                    <i className="fa fa-edit"></i>
                                </button>
                            </li>
                        </ul>
                        <h3 className="block-title">
                            <i className="fa fa-info-circle"></i> Details
                        </h3>
                    </div>
                    <div className="block-content">
                        <ul className="list list-activity push">
                            <li>
                                <div className="font-w600">ID:</div>
                                <div>{this.props.dashboard.id}</div>
                            </li>
                            <li>
                                <div className="font-w600">Dashboard name:</div>
                                <div>{this.props.dashboard.name}</div>
                            </li>
                            <li>
                                <div className="font-w600">Vertical:</div>
                                <div>{this.props.dashboard.vertical}</div>
                            </li>
                            <li>
                                <div className="font-w600">Quicksight ID:</div>
                                <div>{this.props.dashboard.quicksight_id}</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </>
    )
  }
}

// Set defaultProps
DashboardDetailsCard.defaultProps = {
  dashboard: {}
}

// Export Class
export { DashboardDetailsCard }
