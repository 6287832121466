import { AccountLockedPage } from './account-locked'
import { ActionRequiredPage } from './action-required'
import { LoginPage } from './login'
import { LogoutPage } from './logout'
import { NotAuthorizedPage } from './not-authorized'
import { NotFoundPage } from './not-found'
import { ResetPasswordPage } from './reset-password'
import { TokenValidatorPage } from './token-validator'

const AuthenticationPages = {
  AccountLockedPage,
  ActionRequiredPage,
  LoginPage,
  LogoutPage,
  NotAuthorizedPage,
  NotFoundPage,
  ResetPasswordPage,
  TokenValidatorPage
}

export { AuthenticationPages as AUTHENTICATION_PAGES }
