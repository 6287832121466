import React, { Component } from 'react'
import { PageContainerLayout, PageHeaderLayout } from '../../../layouts'
import { NavLink } from 'react-router-dom'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { PermissionWizardForm } from '../../../shared/containers'

class PermissionCreatorPage extends Component {
  constructor (props) {
    super(props)
  }

  renderHeaderPaths () {
    return (
            <>
                <li>
                    <NavLink className="link-effect" to={ROUTING_PATHS.PERMISSIONS_PATH}>
                        Permissions
                    </NavLink>
                </li>
                <li>New Permission</li>
            </>
    )
  }

  render () {
    return (
            <>

                <PageContainerLayout clientSidebar={true} showNavbarClientOptions={true}>
                    <PageHeaderLayout
                        title={'New Permission'}
                        subTitle={'Create a new permission'}
                        paths={this.renderHeaderPaths}
                    />
                    <PermissionWizardForm/>
                </PageContainerLayout>
            </>
    )
  }
}

export { PermissionCreatorPage }
