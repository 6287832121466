import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as zxcvbn from 'zxcvbn'
import { authenticationEndpoints, authenticationOperations } from '../../../../state/ducks/authentication'
import { Session } from '../../../../state/utils/session'

// Class
class ChangePasswordForm extends Component {
  constructor (props) {
    super(props)

    // state
    this.state = {
      new_password: '',
      new_password_confirmation: '',
      session: new Session()
    }

    // handlers
    this.handleChange = this.handleChange.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.logout = this.logout.bind(this)
  }

  handleChange (event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.handleSubmit()
    }
  }

  handleSubmit = () => {
    this.props.changePassword(
      authenticationEndpoints.CHANGE_PASSWORD,
      { new_password: this.state.new_password }
    )
  }

  createPasswordLabel = (result) => {
    switch (result.score) {
      case 0:
        return 'Weak'
      case 1:
        return 'Weak'
      case 2:
        return 'Fair'
      case 3:
        return 'Good'
      case 4:
        return 'Strong'
      default:
        return 'Weak'
    }
  }

  logout () {
    this.state.session.removeSession()
  }

  render () {
    const testedResult = zxcvbn(this.state.new_password ? this.state.new_password : '')

    return (
            <>

                <div className=" block block-themed block-opt-refresh-icon7 animated fadeIn">
                    <div className="block-header bg-primary">
                        <ul className="block-options">
                            <li>
                                <span className="cursor-pointer" onClick={this.logout}>
                                    Logout
                                </span>
                            </li>
                        </ul>
                        <h3 className="block-title">Security Action Required</h3>
                    </div>
                    <div className="block-content block-content-full block-content-narrow">
                        {/* Title */}
                        <h1 className="h2 font-w600 push-30-t push-5">Simetrik</h1>
                        <p>Your current password needs to be updated.</p>
                        {/* END Title */}

                        <form className="js-validation-login form-horizontal push-30-t push-50"
                              action="javascript:void(0)" method="post">
                            <div className="form-group">
                                <div className="col-xs-12">
                                    <div className="form-material form-material-primary floating">
                                        <input
                                            className="form-control"
                                            id="login-password"
                                            type="password"
                                            name="new_password"
                                            value={this.state.new_password}
                                            onKeyPress={this.handleKeyPress}
                                            onChange={this.handleChange}
                                        />
                                        <label htmlFor="login-password">Password</label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-xs-12">
                                    <div className="form-material form-material-primary floating">
                                        <input
                                            id="login-password-confirmation"
                                            type="password"
                                            className={
                                                'form-control fade-in fade-in-third ' +
                                                ((this.state.new_password_confirmation) &&
                                                    this.state.new_password_confirmation !== this.state.new_password
                                                  ? 'text-danger' : ''
                                                )
                                            }
                                            name="new_password_confirmation"
                                            value={this.state.new_password_confirmation}
                                            onKeyPress={this.handleKeyPress}
                                            onChange={this.handleChange}
                                        />
                                        <label htmlFor="login-password-confirmation">Password Confirmation</label>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.new_password && testedResult.score < 4
                                  ? <div className="form-group">
                                        <div className="col-xs-12">
                                            <div className="password-strength-meter w-100 text-center">
                                                <progress
                                                    className={`password-strength-meter-progress strength-${this.createPasswordLabel(testedResult)}`}
                                                    value={testedResult.score}
                                                    max="4"
                                                />
                                                <br/>
                                                <label
                                                    className="password-strength-meter-label"
                                                >
                                                    <small>Password strength:</small>
                                                    {this.createPasswordLabel(testedResult)}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                  : null
                            }
                            <div className="form-group">
                                <div className="col-xs-12 col-sm-6 col-md-7">
                                    <button
                                        className="btn btn-block btn-primary"
                                        type="button"
                                        disabled={
                                            (this.state.new_password && testedResult.score <= 2) ||
                                            this.props.authenticationReducers.changePasswordReducer.loading
                                        }
                                        onClick={this.handleSubmit}
                                    >
                                        {
                                            this.props.authenticationReducers.changePasswordReducer.loading
                                              ? <i className="fa fa-spinner fa-spin"></i>
                                              : <>
                                                    <i className="ml-1 si si-login pull-right"></i>
                                                    <span>Change Password</span>
                                                </>
                                        }
                                    </button>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12">
                                    {
                                        this.props.authenticationReducers.changePasswordReducer.error_data.error
                                          ? <small
                                                className="mt-2">{this.props.authenticationReducers.changePasswordReducer.error_data.error}</small>
                                          : null
                                    }
                                </div>
                            </div>
                        </form>
                        {/* END Form */}
                    </div>
                </div>
            </>
    )
  };
}

// Set propTypes
ChangePasswordForm.propTypes = {}

// Set defaultProps
ChangePasswordForm.defaultProps = {}

// Set prefetch
ChangePasswordForm.prefetch = null

// mapStateToProps
const mapStateToProps = (state) => ({
  authenticationReducers: state.authentication
})

// mapStateToProps
const mapDispatchToProps = {
  changePassword: authenticationOperations.changePassword
}

// Export Class
const _ChangePasswordForm = connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm)
export { _ChangePasswordForm as ChangePasswordForm }
