import * as types from './types'
import apiService from '../../utils/apiService'
import { handleApiErrors, handleApiResponseFailure, handleApiResponseSuccess } from '../../utils'
import * as API_PATHS from './paths'

export const get = (objectId) => {
  return dispatch => {
    const TYPE = types.GET_COLUMN_REQUEST
    const FINAL_PATH = API_PATHS.BASE_PATH + objectId + '/' // object_id
    dispatch({ type: TYPE, payload: {} })
    apiService({
      method: 'GET',
      action: TYPE,
      path: FINAL_PATH,
      async: true,
      params: null,
      body: null
    })
      .then(res => {
        if ('status' in res.data && res.data.status === 'ERROR') {
          handleApiResponseFailure(dispatch, TYPE, res)
        } else {
          handleApiResponseSuccess(dispatch, TYPE, res)
          // Other actions

          dispatch({ type: `${TYPE}_COMPLETED`, payload: res })
        }
      })
      .catch(err => {
        handleApiErrors(dispatch, TYPE, { data: { api_error: err, error: 'Server 500' } })
      })
  }
}

export const list = (params, sourceId = null) => {
  return dispatch => {
    const TYPE = types.FETCH_COLUMNS_REQUEST
    let FINAL_PATH = API_PATHS.BASE_PATH

    if (sourceId) {
      FINAL_PATH = API_PATHS.BASE_SOURCE_COLUMNS_PATH.replace(':source_id', sourceId)
    }

    dispatch({ type: TYPE, payload: {} })
    apiService({
      method: 'GET',
      action: TYPE,
      path: FINAL_PATH,
      async: true,
      params,
      body: null
    })
      .then(res => {
        if (res.data.status === 'OK') {
          handleApiResponseSuccess(dispatch, TYPE, res)
          // Other actions

          dispatch({ type: `${TYPE}_COMPLETED`, payload: res })
        } else {
          handleApiResponseFailure(dispatch, TYPE, res)
        }
      })
      .catch(err => {
        handleApiErrors(dispatch, TYPE, { data: { api_error: err, error: 'Server 500' } })
      })
  }
}

export const save = (body) => {
  return dispatch => {
    const TYPE = types.SAVE_COLUMN_REQUEST
    const FINAL_PATH = API_PATHS.BASE_PATH
    dispatch({ type: TYPE, payload: {} })
    apiService({
      method: 'POST',
      action: TYPE,
      path: FINAL_PATH,
      async: true,
      params: null,
      body
    })
      .then(res => {
        if ('status' in res.data && res.data.status === 'ERROR') {
          handleApiResponseFailure(dispatch, TYPE, res)
        } else {
          handleApiResponseSuccess(dispatch, TYPE, res)
          // Other actions
        }
      })
      .catch(err => {
        handleApiErrors(dispatch, TYPE, { data: { api_error: err, error: 'Server 500' } })
      })
  }
}

export const update = (objectId, body, redirectPath = null) => {
  console.log('updating...')
  return dispatch => {
    const TYPE = types.UPDATE_COLUMN_REQUEST
    const FINAL_PATH = API_PATHS.BASE_PATH + objectId + '/' // object_id
    dispatch({ type: TYPE, payload: {} })
    apiService({
      method: 'PUT',
      action: TYPE,
      path: FINAL_PATH,
      async: true,
      params: null,
      body
    })
      .then(res => {
        if ('status' in res.data && res.data.status === 'ERROR') {
          handleApiResponseFailure(dispatch, TYPE, res)
        } else {
          handleApiResponseSuccess(dispatch, TYPE, res)
          dispatch({ type: `${TYPE}_COMPLETED`, payload: res })
        }
      })
      .catch(err => {
        handleApiErrors(dispatch, TYPE, { data: { api_error: err, error: 'Server 500' } })
      })
  }
}
