import { combineReducers } from 'redux'
import * as types from './types'

const fetchSourcesInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { results: [], pagination_data: {} }
}

const getSourceInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { columns: [] }
}

const saveSourceInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

const updateSourceInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

const removeSourceInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

const successTableGeneratedInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { results: [], pagination_data: {} }
}

const errorTableGeneratedInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { results: [], pagination_data: {} }
}

export function fetchSourcesReducer (state = fetchSourcesInitialState, action) {
  switch (action.type) {
    case types.FETCH_SOURCES_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.FETCH_SOURCES_REQUEST_FAILURE:
      return {
        ...fetchSourcesInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.FETCH_SOURCES_REQUEST_SUCCESS:
      return {
        ...fetchSourcesInitialState,
        data: action.payload.data
      }
    case types.FETCH_SOURCES_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function getSourceReducer (state = getSourceInitialState, action) {
  switch (action.type) {
    case types.GET_SOURCE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.GET_SOURCE_REQUEST_FAILURE:
      return {
        ...getSourceInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.GET_SOURCE_REQUEST_SUCCESS:
      return {
        ...getSourceInitialState,
        data: action.payload.data
      }
    case types.GET_SOURCE_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function saveSourceReducer (state = saveSourceInitialState, action) {
  switch (action.type) {
    case types.SAVE_SOURCE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.SAVE_SOURCE_REQUEST_FAILURE:
      return {
        ...fetchSourcesInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.SAVE_SOURCE_REQUEST_SUCCESS:
      return {
        ...fetchSourcesInitialState,
        data: action.payload.data
      }
    case types.SAVE_SOURCE_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function updateSourceReducer (state = updateSourceInitialState, action) {
  switch (action.type) {
    case types.UPDATE_SOURCE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.UPDATE_SOURCE_REQUEST_FAILURE:
      return {
        ...updateSourceInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.UPDATE_SOURCE_REQUEST_SUCCESS:
      return {
        ...updateSourceInitialState,
        data: action.payload.data
      }
    case types.UPDATE_SOURCE_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function removeSourceReducer (
  state = removeSourceInitialState,
  action
) {
  switch (action.type) {
    case types.DELETE_SOURCE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.DELETE_SOURCE_REQUEST_FAILURE:
      return {
        ...removeSourceInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.DELETE_SOURCE_REQUEST_SUCCESS:
      return {
        ...removeSourceInitialState,
        data: action.payload.data
      }
    case types.DELETE_SOURCE_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function successTableGeneratedReducer (state = successTableGeneratedInitialState, action) {
  switch (action.type) {
    case types.SUCCESS_TABLE_GENERATED_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.SUCCESS_TABLE_GENERATED_REQUEST_FAILURE:
      return {
        ...successTableGeneratedInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.SUCCESS_TABLE_GENERATED_REQUEST_SUCCESS:
      return {
        ...successTableGeneratedInitialState,
        data: action.payload.data
      }
    case types.SUCCESS_TABLE_GENERATED_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function errorTableGeneratedReducer (state = errorTableGeneratedInitialState, action) {
  switch (action.type) {
    case types.ERROR_TABLE_GENERATED_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.ERROR_TABLE_GENERATED_REQUEST_FAILURE:
      return {
        ...errorTableGeneratedInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.ERROR_TABLE_GENERATED_REQUEST_SUCCESS:
      return {
        ...errorTableGeneratedInitialState,
        data: action.payload.data
      }
    case types.ERROR_TABLE_GENERATED_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export default combineReducers({
  fetchSourcesReducer,
  saveSourceReducer,
  getSourceReducer,
  updateSourceReducer,
  removeSourceReducer,
  successTableGeneratedReducer,
  errorTableGeneratedReducer
})
