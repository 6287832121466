import reducer from './reducers'

import * as authenticationOperations from './operations'
import * as authenticationEndpoints from './paths'

export {
  authenticationOperations,
  authenticationEndpoints
}

export default reducer
