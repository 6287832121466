import React, { Component } from 'react'
import { history } from '../../../../routing/History'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { Session } from '../../../../state/utils/session'
import { Link } from 'react-router-dom'
import { jsonPrettify } from '../../../../utils/jsonPrettify'
import { ExecutionsModal } from '../executions-modal'

class SourceDetailsCard extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session(),
      source_to_execute: {}
    }
    this.goToSourceEditor = this.goToSourceEditor.bind(this)
    this.executeETL = this.executeETL.bind(this)
  }

  goToSourceEditor () {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_SOURCE_EDITOR_PATH
        .replace(':source_id', this.props.source.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  executeETL (source) {
    this.setState({
      source_to_execute: source
    }, () => {
      window.jQuery('#executions-modal').modal('show')
    })
  }

  render () {
    return (
            <>
                <div className="block block-themed">
                    <div className="block-header bg-primary-dark">
                        <ul className="block-options">
                            <li>
                                <button type="button" onClick={this.goToSourceEditor}>
                                    <i className="fa fa-edit"></i>
                                </button>
                            </li>
                            <li>
                                <button className="btn btn-xs btn-default"
                                        type="button"
                                        data-toggle="tooltip"
                                        title="Run ETL"
                                        onClick={this.executeETL.bind(this, this.props.source)}
                                >
                                    ETL
                                </button>
                            </li>
                        </ul>
                        <h3 className="block-title">
                            <i className="fa fa-info-circle"></i> Details
                        </h3>
                    </div>
                    <div className="block-content" style={{ height: '300px', overflowY: 'scroll' }}>
                        <ul className="list list-activity push">
                            <li style={{ paddingLeft: '0' }}>
                                <div className="font-w600">ID:</div>
                                <div>{this.props.source.id}</div>
                            </li>
                            <li style={{ paddingLeft: '0' }}>
                                <div className="font-w600">Source name:</div>
                                <div>{this.props.source.name}</div>
                            </li>
                            <li style={{ paddingLeft: '0' }}>
                                <div className="font-w600">Origin Connection:</div>
                                <div>
                                    <Link to={
                                        ROUTING_PATHS.CLIENT_DASHBOARD_CONNECTION_DETAILS_PATH
                                          .replace(':client_id', this.state.session.getClientSession())
                                          .replace(':connection_id', this.props.source.origin_connection)
                                    }>
                                        {this.props.source.origin_connection_name}
                                    </Link>
                                </div>
                            </li>
                            <li style={{ paddingLeft: '0' }}>
                                <div className="font-w600">Destination Connection:</div>
                                <div>
                                    <Link to={
                                        ROUTING_PATHS.CLIENT_DASHBOARD_CONNECTION_DETAILS_PATH
                                          .replace(':client_id', this.state.session.getClientSession())
                                          .replace(':connection_id', this.props.source.destination_connection)
                                    }>
                                        {this.props.source.destination_connection_name}
                                    </Link>
                                </div>
                            </li>
                            <li style={{ paddingLeft: '0' }}>
                                <div className="font-w600">Batch Order:</div>
                                <div>{this.props.source.batch_order}</div>
                            </li>
                        </ul>
                        <pre>
                            <code>
                                {jsonPrettify(this.props.source.parameters)}
                            </code>
                        </pre>
                    </div>
                </div>
                <ExecutionsModal object_type={'SOURCE'} object={this.state.source_to_execute}/>
            </>
    )
  }
}

// Set defaultProps
SourceDetailsCard.defaultProps = {
  source: {}
}

// Export Class
export { SourceDetailsCard }
