import React, { Component } from 'react'
import { EmptyTableLayout } from '../../../layouts'
import { history } from '../../../../routing/History'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { Session } from '../../../../state/utils/session'

class JobsTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }

    this.selectJob = this.selectJob.bind(this)
  }

  selectJob (job) {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_JOB_DETAILS_PATH
        .replace(':job_id', job.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  render () {
    return (
            <>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered table-condensed text-center">
                        <thead>
                        <tr>
                            <th className="text-center">ID</th>
                            <th className="text-center">Name</th>
                            <th className="text-center">Type</th>
                            <th className="text-center">State</th>
                            <th className="text-center">Created</th>
                            <th className="text-center">Updated</th>
                            <th className="text-center">Options</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.jobs.map((job) => {
                              return (
                                    <tr key={job.id}>
                                        <td className="text-center">{job.id}</td>
                                        <td className="text-left">{job.name}</td>
                                        <td className="text-left">{job.type}</td>
                                        <td className="text-center">{job.state}</td>
                                        <td className="text-center">{job.created}</td>
                                        <td className="text-center">{job.updated}</td>
                                        <td className="text-center">
                                            <div className="btn-group">
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Select Job"
                                                        onClick={this.selectJob.bind(this, job)}
                                                >
                                                    <i className="fa fa-eye"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                              )
                            })
                        }
                        <EmptyTableLayout results={this.props.jobs} columns={3}/>
                        </tbody>
                    </table>
                    {this.props.children}
                </div>
            </>
    )
  }
}

// Set defaultProps
JobsTable.defaultProps = {
  jobs: []
}

// Export Class
export { JobsTable }
