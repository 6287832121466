import { combineReducers } from 'redux'
import * as types from './types'

const fetchExecutionsInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { results: [], pagination_data: {} }
}

const getExecutionInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { groups: [] }
}

const saveExecutionInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

const updateExecutionInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

export function fetchExecutionsReducer (state = fetchExecutionsInitialState, action) {
  switch (action.type) {
    case types.FETCH_EXECUTIONS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.FETCH_EXECUTIONS_REQUEST_FAILURE:
      return {
        ...fetchExecutionsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.FETCH_EXECUTIONS_REQUEST_SUCCESS:
      return {
        ...fetchExecutionsInitialState,
        data: action.payload.data
      }
    case types.FETCH_EXECUTIONS_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function getExecutionReducer (state = getExecutionInitialState, action) {
  switch (action.type) {
    case types.GET_EXECUTION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.GET_EXECUTION_REQUEST_FAILURE:
      return {
        ...getExecutionInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.GET_EXECUTION_REQUEST_SUCCESS:
      return {
        ...getExecutionInitialState,
        data: action.payload.data
      }
    case types.GET_EXECUTION_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function saveExecutionReducer (state = saveExecutionInitialState, action) {
  switch (action.type) {
    case types.SAVE_EXECUTION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.SAVE_EXECUTION_REQUEST_FAILURE:
      return {
        ...saveExecutionInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.SAVE_EXECUTION_REQUEST_SUCCESS:
      return {
        ...saveExecutionInitialState,
        data: action.payload.data
      }
    case types.SAVE_EXECUTION_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function updateExecutionReducer (state = updateExecutionInitialState, action) {
  switch (action.type) {
    case types.UPDATE_EXECUTION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.UPDATE_EXECUTION_REQUEST_FAILURE:
      return {
        ...updateExecutionInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.UPDATE_EXECUTION_REQUEST_SUCCESS:
      return {
        ...updateExecutionInitialState,
        data: action.payload.data
      }
    case types.UPDATE_EXECUTION_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export default combineReducers({
  fetchExecutionsReducer,
  saveExecutionReducer,
  getExecutionReducer,
  updateExecutionReducer
})
