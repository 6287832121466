import { combineReducers } from 'redux'
import * as types from './types'

const fetchUsersInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { results: [], pagination_data: {} }
}

const getUserInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { groups: [], permissions: [], clients: [], user_permissions: [] }
}

const saveUserInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

const updateUserInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

export function fetchUsersReducer (state = fetchUsersInitialState, action) {
  switch (action.type) {
    case types.FETCH_USERS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.FETCH_USERS_REQUEST_FAILURE:
      return {
        ...fetchUsersInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.FETCH_USERS_REQUEST_SUCCESS:
      return {
        ...fetchUsersInitialState,
        data: action.payload.data
      }
    case types.FETCH_USERS_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function getUserReducer (state = getUserInitialState, action) {
  switch (action.type) {
    case types.GET_USER_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.GET_USER_REQUEST_FAILURE:
      return {
        ...getUserInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.GET_USER_REQUEST_SUCCESS:
      return {
        ...getUserInitialState,
        data: action.payload.data
      }
    case types.GET_USER_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function saveUserReducer (state = saveUserInitialState, action) {
  switch (action.type) {
    case types.SAVE_USER_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.SAVE_USER_REQUEST_FAILURE:
      return {
        ...fetchUsersInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.SAVE_USER_REQUEST_SUCCESS:
      return {
        ...fetchUsersInitialState,
        data: action.payload.data
      }
    case types.SAVE_USER_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function updateUserReducer (state = updateUserInitialState, action) {
  switch (action.type) {
    case types.UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.UPDATE_USER_REQUEST_FAILURE:
      return {
        ...updateUserInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.UPDATE_USER_REQUEST_SUCCESS:
      return {
        ...updateUserInitialState,
        data: action.payload.data
      }
    case types.UPDATE_USER_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export default combineReducers({
  fetchUsersReducer,
  saveUserReducer,
  getUserReducer,
  updateUserReducer
})
