import React, { Component } from 'react'
import { Button, Card, Form } from 'react-bootstrap'
import { stringListToArrayList } from '../../../../utils/parameters'

const initialState = {
  column_name: null,
  position: null,
  index: '',
  rule: '',
  type: 'string',
  length: '',
  normalizations: []
}

class ColumnsConverterTextarea extends Component {
  state = {
    string: '',
    columns: []
  }

  converter () {
    this.setState({ columns: [] })
    const columns = []
    stringListToArrayList(this.state.string).forEach((column, index) => {
      if (column.trim()) {
        const includesType = !!column.includes(':')
        if (includesType) {
          columns.push({
            ...initialState,
            position: index + 1,
            column_name: column.trim().split(':')[0].toLowerCase(),
            type: this.getColumntype(column.trim().split(':')[1]),
            index: false
          })
        } else {
          columns.push({
            ...initialState,
            position: index + 1,
            column_name: column.trim().toLowerCase(),
            index: false
          })
        }
      }
    })
    this.setState({ columns })
    this.props.onColumnsChange(columns)
  }

  getColumntype (pandasType) {
    switch (pandasType) {
      case 'object':
      case 'timedelta':
      case 'category':
        return 'string'
      case 'int64':
      case 'float64':
      case 'int':
      case 'float':
        return 'numeric'
      case 'bool':
        return 'boolean'
      case 'datetime64':
      case 'datetime':
        return 'datetime'
      case 'date':
        return 'date'
      default:
        return 'string'
    }
  }

  render () {
    return (
            <>
                <Card.Body className="mb-0 pb-0">
                    <div className="mx-auto col-lg-12">
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <label
                                data-toggle="tooltip"
                                title="Add a list of columns separated by comma or spaces and click on convert button to map the columns"
                            >
                                Columns List <i className="fa fa-question-circle"></i>
                            </label>
                            <Form.Control
                                as="textarea"
                                rows="3"
                                value={this.state.string}
                                onChange={(e) => this.setState({ string: e.target.value })}
                            />
                        </Form.Group>
                    </div>
                </Card.Body>
                <Button
                    variant="success"
                    type="button"
                    size="sm"
                    onClick={this.converter.bind(this)}
                >
                    Convert
                </Button>
            </>
    )
  }
}

ColumnsConverterTextarea.defaultProps = {
  onColumnsChange: () => {
  }
}

export { ColumnsConverterTextarea }
