import { combineReducers } from 'redux'
import * as types from './types'

const fetchValidationsAutomationsInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  conciliations: [],
  sources: [],
  alarms: [],
  workerCalls: []
}

export function fetchValidationsAutomationsReducer (
  state = fetchValidationsAutomationsInitialState,
  action
) {
  switch (action.type) {
    case types.RESET_VALIDATIONS_AUTOMATIONS:
      return {
        ...fetchValidationsAutomationsInitialState
      }
    case types.FETCH_VALIDATIONS_AUTOMATIONS_CONCILIATIONS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.FETCH_VALIDATIONS_AUTOMATIONS_CONCILIATIONS_REQUEST_FAILURE:
      return {
        ...state,
        error_data: action.payload.data,
        failed: true
      }
    case types.FETCH_VALIDATIONS_AUTOMATIONS_CONCILIATIONS_REQUEST_SUCCESS:
      return {
        ...fetchValidationsAutomationsInitialState,
        sources: state.sources,
        alarms: state.alarms,
        workerCalls: state.workerCalls,
        conciliations: action.payload.data
      }
    case types.FETCH_VALIDATIONS_AUTOMATIONS_CONCILIATIONS_REQUEST_COMPLETED:
      return {
        ...state,
        conciliations: action.payload.data,
        completed: true
      }
    case types.FETCH_VALIDATIONS_AUTOMATIONS_SOURCES_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.FETCH_VALIDATIONS_AUTOMATIONS_SOURCES_REQUEST_FAILURE:
      return {
        ...state,
        error_data: action.payload.data,
        failed: true
      }
    case types.FETCH_VALIDATIONS_AUTOMATIONS_SOURCES_REQUEST_SUCCESS:
      return {
        ...fetchValidationsAutomationsInitialState,
        conciliations: state.conciliations,
        sources: action.payload.data,
        alarms: state.alarms,
        workerCalls: state.workerCalls
      }
    case types.FETCH_VALIDATIONS_AUTOMATIONS_SOURCES_REQUEST_COMPLETED:
      return {
        ...state,
        sources: action.payload.data,
        completed: true
      }
    case types.FETCH_VALIDATIONS_AUTOMATIONS_ALARMS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.FETCH_VALIDATIONS_AUTOMATIONS_ALARMS_REQUEST_FAILURE:
      return {
        ...state,
        error_data: action.payload.data,
        failed: true
      }
    case types.FETCH_VALIDATIONS_AUTOMATIONS_ALARMS_REQUEST_SUCCESS:
      return {
        ...fetchValidationsAutomationsInitialState,
        conciliations: state.conciliations,
        sources: state.sources,
        workerCalls: state.workerCalls,
        alarms: action.payload.data
      }
    case types.FETCH_VALIDATIONS_AUTOMATIONS_ALARMS_REQUEST_COMPLETED:
      return {
        ...state,
        alarms: action.payload.data,
        completed: true
      }
    case types.FETCH_VALIDATIONS_AUTOMATIONS_WORKERCALLS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.FETCH_VALIDATIONS_AUTOMATIONS_WORKERCALLS_REQUEST_FAILURE:
      return {
        ...state,
        error_data: action.payload.data,
        failed: true
      }
    case types.FETCH_VALIDATIONS_AUTOMATIONS_WORKERCALLS_REQUEST_SUCCESS:
      return {
        ...fetchValidationsAutomationsInitialState,
        conciliations: state.conciliations,
        sources: state.sources,
        alarms: state.alarms,
        workerCalls: action.payload.data
      }
    case types.FETCH_VALIDATIONS_AUTOMATIONS_WORKERCALLS_REQUEST_COMPLETED:
      return {
        ...state,
        workerCalls: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export default combineReducers({
  fetchValidationsAutomationsReducer
})
