import * as types from './types'
import apiService from '../../utils/apiService'
import {
  handleApiErrors,
  handleApiResponseFailure,
  handleApiResponseSuccess
} from '../../utils'
import * as API_PATHS from './paths'
import { toast } from 'react-toastify'

export const getDynamicParametries = (params) => {
  return (dispatch) => {
    const TYPE = types.FETCH_DYNAMIC_PARAMETRIES_REQUEST
    const FINAL_PATH = `${API_PATHS.BASE_PATH}/schemas/?all=0`
    dispatch({ type: TYPE, payload: {} })

    apiService({
      method: 'GET',
      action: TYPE,
      path: FINAL_PATH,
      async: true,
      params,
      body: null
    })
      .then((res) => {
        const handler =
          res.data.status === 'OK'
            ? handleApiResponseSuccess
            : handleApiResponseFailure
        handler(dispatch, TYPE, res)
      })
      .catch((err) => {
        handleApiErrors(dispatch, TYPE, {
          data: { api_error: err, error: 'Server 500' }
        })
      })
  }
}

function handleInconsistencyResponse ({ response, modelName }) {
  const { headers, data } = response
  const contentType = headers['content-type']

  switch (contentType) {
    case 'text/csv': {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${modelName} - inconsistencies.csv`)
      document.body.appendChild(link)
      link.click()
      break
    }

    // if any database is empty we'll receive a JSON from the backend
    case 'application/json': {
      const { rds_empty: rdsEmpty, snowflake_empty: snowflakeEmpty } = data
      let message = ''
      if (rdsEmpty) message += 'La RDS se encuentra vacía.\n'
      if (snowflakeEmpty) message += 'Snowflake se encuentra vacío.'

      toast.warning(message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
      break
    }

    default:
  }
}

export const getInconsistencies = (modelName) => {
  return (dispatch) => {
    const modelNameFormatted = modelName.toLowerCase().replace(/(\s+)/g, '_')
    const TYPE = types.FETCH_INCONSISTENCIES_REQUEST
    const FINAL_PATH = `${API_PATHS.BASE_PATH}/models/${modelNameFormatted}/inconsistencies/`
    dispatch({ type: TYPE, payload: {} })

    apiService({
      method: 'GET',
      action: TYPE,
      path: FINAL_PATH,
      async: true,
      body: null
    })
      .then((response) => {
        if (response.status !== 200) { return handleApiResponseFailure(dispatch, TYPE, response) }

        handleInconsistencyResponse({ response, modelName })
        handleApiResponseSuccess(dispatch, TYPE, response)
      })
      .catch((err) => {
        handleApiErrors(dispatch, TYPE, {
          data: { api_error: err, error: 'Server 500' }
        })
      })
  }
}
