import React, { Component, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import Checkbox from '@material-ui/core/Checkbox'

import { Session } from '../../../../../state/utils/session'
import { PageContainerLayout } from '../../../../layouts'
import { releaseOperations } from '../../../../../state/ducks/deploy-tool'
import {
  DatabaseTableLabelsMapping,
  getSortedDatabaseMappings,
  warnings
} from '../helpers'
import { Modal } from '../components/modal.s3.component'

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false)

  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div>{title}</div>
        <div>{isActive ? '-' : '+'}</div>
      </div>
      {isActive && <div className="accordion-content">{content}</div>}
    </div>
  )
}

// Wizards
const ReleaseDatabaseChange = (props) => {
  const [checked, setChecked] = useState(props.change.selected || false)
  const changeObject = props.change
  const changeType = changeObject.action
  const objectId = changeObject.id
  const config = changeObject.config
  const changeList = changeObject.changes || config
  const objectName = config.name
  const ChangeType = (_type) => {
    if (_type === 'add') {
      return <div>Agregar</div>
    } else if (_type === 'update') {
      return <div>Modificar</div>
    } else if (_type === 'copy') {
      return <div>Copiar</div>
    } else if (_type === 'delete') {
      return <div>Eliminar</div>
    }
  }
  const RenderChangeList = () => {
    const changes = Object.entries(changeList).map((item, index) => (
      <div className={'change-badge'} key={`changeList-${index}`}>
        <div>{item[0]}: </div>
        <div className="value">
          {JSON.stringify(item[1]).valueOf()}
        </div>
      </div>
    ))
    return (
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        {changes}
      </div>
    )
  }
  const container = (
    <div className={'database-change'}>
      <Checkbox
        checked={checked}
        onChange={(event) => {
          setChecked(event.target.checked)
          props.onSelectCallback(event.target.checked)
        }}
      />
      <div className={'change-content'}>
        <div>
          Id: {objectId}: - Nombre: {objectName} <div>{RenderChangeList()}</div>
        </div>
        <div>{ChangeType(changeType)}</div>
      </div>
    </div>
  )
  return container
}
const ReleaseS3Change = (props) => {
  const [checked, setChecked] = useState(props.change.selected || false)
  const changeObject = props.change
  const changeType = changeObject.action
  const objectName = changeObject.key
  const ChangeType = (_type) => {
    if (_type === 'add') {
      return <div>Agregar</div>
    } else if (_type === 'update') {
      return <div>Modificar</div>
    } else if (_type === 'copy') {
      return <div><a className="preview-query" onClick={() => {
        props.modal(true)
      }}>{props.change.loading ? 'Loading...' : 'Preview Query'}</a><span>Copiar</span></div>
    } else if (_type === 'delete') {
      return <div>Delete</div>
    }
  }
  const container = (
    <div className={'database-change'}>
      <Checkbox
        checked={checked}
        onChange={(event) => {
          setChecked(event.target.checked)
          props.onSelectCallback(event.target.checked)
        }}
      />
      <div className={'change-content'}>
        <div>Nombre: {objectName} </div>
        <div>{ChangeType(changeType)}</div>
      </div>
    </div>
  )
  return container
}
const DeployReleaseWizard = (props) => {
  useEffect(() => {
    if (!props.loadingS3) {
      updateStateEntryS3(itemLoading.configIndex, itemLoading.index, false)
    }
  }, [props.loadingS3])

  const { loading } = props
  const [databaseChanges, setDatabaseChanges] = useState(
    props.changes.database
  )
  const [s3Changes, sets3Changes] = useState(props.changes.s3)
  const [releaseName, setReleaseName] = useState('')
  const [releaseChangelog, setReleaseChangelog] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [disabledButton, setDisabledButton] = useState(false)
  const [itemLoading, setItemloading] = useState({ configIndex: 0, index: 0 })
  const databaseTables = getSortedDatabaseMappings(DatabaseTableLabelsMapping)
  const session = new Session()
  const updateEntry = (model, elementIndex, value) => {
    const obj = { ...databaseChanges }
    obj[model][elementIndex].selected = value
    setDatabaseChanges(obj)
  }
  const updateEntryS3 = (configIndex, elementIndex, value) => {
    const obj = [...s3Changes]
    obj[configIndex].changes[elementIndex].selected = value
    sets3Changes(obj)
  }
  const updateStateEntryS3 = (configIndex, elementIndex, value) => {
    const obj = [...s3Changes]
    if (obj[configIndex].changes.length > 0) {
      obj[configIndex].changes[elementIndex].loading = value
      sets3Changes(obj)
    }
  }
  const renderDatabaseModel = (model) => {
    const modelName = model.key
    const changes = Object.entries(databaseChanges).find(
      (el) => el[0] === modelName
    )[1]
    const changeList = changes.map((item, index) => (
      <ReleaseDatabaseChange
        onSelectCallback={(selected) => {
          updateEntry(modelName, index, selected)
          console.log(selected)
        }}
        key={item.id}
        change={item}
      />
    ))
    const selectedItems = changes.filter(
      (item) => item.selected === true
    ).length
    const warning = warnings[model.value.label]
    const modelTitle = (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <p style={{ margin: 0 }}>{model.value.label}</p>
        {selectedItems > 0 && (
          <div className="counter-badge">{selectedItems}</div>
        )}
        {warning && (
          <button
            className="btn btn-xs icon-warning"
            type="button"
            data-toggle="tooltip"
            title={warning}
          >
            <i className="fa fa-exclamation-triangle"/>
          </button>
        )}
      </div>
    )
    return <Accordion title={modelTitle} content={changeList} />
  }
  const renderS3Changes = () => {
    const changes = []
    for (const [configIndex, element] of s3Changes.entries()) {
      const changeList = element.changes.map((item, index) => (
        <ReleaseS3Change
          onSelectCallback={(selected) => {
            updateEntryS3(configIndex, index, selected)
          }}
          key={item.id}
          change={item}
          modal={(loading) => {
            setItemloading({ configIndex, index })
            updateStateEntryS3(configIndex, index, loading)
            props.modal(session.getClientSession(), item.key)
          }}
        />
      ))
      const selectedItems = element.changes.filter(
        (item) => item.selected === true
      ).length
      const modelTitle = (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <p style={{ margin: 0 }}>Repositorio</p>
          {selectedItems > 0 && (
            <div className="counter-badge">{selectedItems}</div>
          )}
        </div>
      )
      changes.push(<Accordion title={modelTitle} content={changeList} />)
    }
    return changes
  }
  const handleReleaseNameChange = (event) => {
    setReleaseName(event.target.value)
  }
  const handleReleaseChangelog = (event) => {
    setReleaseChangelog(event.target.value)
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    if (releaseName.trim().length > 0) {
      setErrorMessage('')
      // Gather selected data for submit form.
      // Database
      const databaseObject = {}
      for (const [key, value] of Object.entries(databaseChanges)) {
        const selectedChanges = value.filter((item) => item.selected === true)
        if (selectedChanges.length > 0) databaseObject[key] = selectedChanges
      }
      const s3ChangesArray = []
      // S3
      for (const [configIndex, element] of s3Changes.entries()) {
        console.log(configIndex)
        const object = { ...element }
        const selectedChanges = element.changes.filter((item) => item.selected)
        object.changes = selectedChanges
        if (selectedChanges.length > 0) s3ChangesArray.push(object)
      }
      const formData = {
        configuration: {
          database: databaseObject,
          s3: s3ChangesArray
        },
        client: parseInt(session.getClientSession()),
        version_code: releaseName,
        changelist: releaseChangelog
      }
      props.save(formData)
      setDisabledButton(true)
    } else {
      setErrorMessage('* El campo código de la versión es obligatorio.')
    }
  }
  return (
    <div className="deploy-tool-wizard">
      <form onSubmit={handleSubmit}>
        <div className="release-inputs">
          <label>
            Código de la versión (*):
            <input
              className={'release-code-input'}
              type="text"
              placeholder="v0.0.x"
              value={releaseName}
              onChange={handleReleaseNameChange}
            />
          </label>
          <label>
            Descripción de la versión:
            <textarea
              value={releaseChangelog}
              onChange={handleReleaseChangelog}
              rows={3}
            ></textarea>
          </label>
        </div>
        <div className="release-error-message">
          <p>{errorMessage}</p>
        </div>
        <Tabs>
          <TabList>
            <Tab>1. Base de datos</Tab>
            <Tab>2. Archivos S3</Tab>
          </TabList>

          <TabPanel>
            <div className="deploy-tool-wizard database-changes">
              {Object.entries(databaseTables).map((item) =>
                renderDatabaseModel(item[1])
              )}
            </div>
          </TabPanel>
          <TabPanel>{renderS3Changes()}</TabPanel>
        </Tabs>
        {!loading && (
          <input
            disabled={disabledButton}
            type="submit"
            value="Enviar versión"
            className="deploy-creator-submit"
          />
        )}
      </form>
    </div>
  )
}

// Pages
class DeployCreatorPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      session: new Session(),
      loading: true
    }
    this.fetchChanges = this.fetchChanges.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.fetchChanges()
  }

  fetchChanges () {
    this.props.fetchDiffcheck(this.state.session.getClientSession())
  }

  databaseChanges () {
    return this.props.changes
  }

  showHideModal = (client, path) => {
    if (client && path) {
      this.props.s3FilePreview(client, path)
    } else {
      this.props.s3FilePreviewClear()
    }
  }

  avoidEmptyProperties (changes) {
    for (const table in DatabaseTableLabelsMapping) {
      if (table in changes.database === false) {
        changes.database[table] = []
      }
    }
    return changes
  }

  isRunningPlaceholder () {
    return (
      <div
        style={{
          background: 'white',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '80vh'
        }}
      >
        <div className={'deploy-tool-loader'}></div>
        <div
          style={{
            marginTop: '1.8rem',
            color: 'black',
            fontWeight: 'bold',
            fontSize: '2.5rem',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          Estamos buscando cambios pendientes...
        </div>
        <div style={{ marginTop: '3rem' }} onClick={() => {}}>
          <a href="#">Regresar al menú principal</a>
        </div>
      </div>
    )
  }

  render () {
    const { loading } = this.state
    return (
      <>
        <PageContainerLayout>
          {this.props.isRunning && this.isRunningPlaceholder()}
          {!this.props.isRunning && (
            <div className="deploy-tool-creator">
              <div className="title">
                Crear una versión
                <p>
                  En la siguiente sección encontrarás los tipos de cambios que
                  existen, selecciona los cambios que desees incluir en la
                  versión
                </p>
              </div>
              <DeployReleaseWizard
                changes={this.avoidEmptyProperties(this.props.changes)}
                save={this.props.createRelease}
                loading={this.props.loading}
                loadingS3={this.props.loadingS3}
                modal={(client, path) => this.showHideModal(client, path)}
              />
            </div>
          )}
          <Modal
            show={this.props.s3file !== ''}
            loading={loading}
            s3file={this.props.s3file}
            handleClose={() => this.showHideModal(null, null)}
          />
        </PageContainerLayout>
      </>
    )
  }
}

// Set defaultProps
DeployCreatorPage.defaultProps = {
  changes: {},
  s3file: ''
}

// mapStateToProps
const mapStateToProps = (state) => ({
  isLoading: state.releases.fetchDiffcheckReducer.loading,
  changes: state.releases.fetchDiffcheckReducer.data.changes.data,
  s3file: state.releases.s3filesReducer.data.body,
  isCached:
    state.releases.fetchDiffcheckReducer.data.changes.status === 'cached',
  isRunning:
    state.releases.fetchDiffcheckReducer.data.changes.status === 'running',
  loading: state.releases.saveReleaseReducer.loadingButton,
  loadingS3: state.releases.s3filesReducer.loading
})

// mapStateToProps
const mapDispatchToProps = {
  fetchDiffcheck: releaseOperations.fetchDiffcheck,
  createRelease: releaseOperations.save,
  s3FilePreview: releaseOperations.s3FilePreview,
  s3FilePreviewClear: releaseOperations.s3FilePreviewClear
}

// Export Class
const _DeployCreatorPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeployCreatorPage)

export { _DeployCreatorPage as DeployCreatorPage }
