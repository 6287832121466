// USERS
export const FETCH_USERS_REQUEST = 'session/FETCH_USERS_REQUEST'
export const FETCH_USERS_REQUEST_SUCCESS = 'session/FETCH_USERS_REQUEST_SUCCESS'
export const FETCH_USERS_REQUEST_FAILURE = 'session/FETCH_USERS_REQUEST_FAILURE'
export const FETCH_USERS_REQUEST_COMPLETED = 'session/FETCH_USERS_REQUEST_COMPLETED'

export const GET_USER_REQUEST = 'session/GET_USER_REQUEST'
export const GET_USER_REQUEST_SUCCESS = 'session/GET_USER_REQUEST_SUCCESS'
export const GET_USER_REQUEST_FAILURE = 'session/GET_USER_REQUEST_FAILURE'
export const GET_USER_REQUEST_COMPLETED = 'session/GET_USER_REQUEST_COMPLETED'

export const SAVE_USER_REQUEST = 'session/SAVE_USER_REQUEST'
export const SAVE_USER_REQUEST_SUCCESS = 'session/SAVE_USER_REQUEST_SUCCESS'
export const SAVE_USER_REQUEST_FAILURE = 'session/SAVE_USER_REQUEST_FAILURE'
export const SAVE_USER_REQUEST_COMPLETED = 'session/SAVE_USER_REQUEST_COMPLETED'

export const UPDATE_USER_REQUEST = 'session/UPDATE_USER_REQUEST'
export const UPDATE_USER_REQUEST_SUCCESS = 'session/UPDATE_USER_REQUEST_SUCCESS'
export const UPDATE_USER_REQUEST_FAILURE = 'session/UPDATE_USER_REQUEST_FAILURE'
export const UPDATE_USER_REQUEST_COMPLETED = 'session/UPDATE_USER_REQUEST_COMPLETED'
