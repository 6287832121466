import React, { Component } from 'react'
import { PageContainerLayout, PageHeaderLayout } from '../../../layouts'
import { GroupWizardForm } from '../../../shared/containers'
import { NavLink } from 'react-router-dom'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { GroupShape } from '../../../../prop-types'
import * as PropTypes from 'prop-types'
import { groupOperations } from '../../../../state/ducks/groups'
import { connect } from 'react-redux'

class GroupEditorPage extends Component {
  constructor (props) {
    super(props)
  }

  UNSAFE_componentWillMount () {
    this.props.getGroup(this.props.match.params.group_id)
  }

  renderHeaderPaths () {
    return (
            <>
                <li>
                    <NavLink className="link-effect" to={ROUTING_PATHS.GROUPS_PATH}>
                        Groups
                    </NavLink>
                </li>
                <li>Edit Group</li>
            </>
    )
  }

  render () {
    return (
            <>

                <PageContainerLayout>
                    <PageHeaderLayout
                        title={this.props.group.name}
                        subTitle={'Edit Group'}
                        paths={this.renderHeaderPaths}
                    />
                    <GroupWizardForm
                        group={this.props.group}
                        isEditor={true}
                    />
                </PageContainerLayout>

            </>
    )
  }
}

// Set propTypes
GroupEditorPage.propTypes = {
  group: GroupShape,
  getGroupErrorResponse: PropTypes.object
}

// Set defaultProps
GroupEditorPage.defaultProps = {
  group: {},
  getGroupErrorResponse: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  group: state.groups.getGroupReducer.data,
  isLoading: state.groups.getGroupReducer.loading,
  getGroupErrorResponse: state.groups.getGroupReducer.error_data
})

// mapStateToProps
const mapDispatchToProps = {
  getGroup: groupOperations.get
}

// Export Class
const _GroupEditorPage = connect(mapStateToProps, mapDispatchToProps)(GroupEditorPage)
export { _GroupEditorPage as GroupEditorPage }
