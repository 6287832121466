import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { LastExecutionLogsListLayout } from '../../../layouts/last-execution-logs-list'
import { conciliationExecutionLogOperations } from '../../../../state/ducks/conciliation-execution-logs'

class ConciliationExecutionLogs extends Component {
  constructor (props) {
    super(props)

    // state
    this.state = {}

    this.fetchExecutionLogs = this.fetchExecutionLogs.bind(this)
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.conciliation.id !== this.props.conciliation.id) {
      this.props.fetchExecutionLogs({})
    }
  }

  fetchExecutionLogs () {
    this.props.fetchExecutionLogs({})
  }

  render () {
    return (
            <>
                <LastExecutionLogsListLayout
                    title={''}
                    model={'conciliation'}
                    fetch={this.fetchExecutionLogs}
                    execution_logs={this.props.executionLogs}
                    isLoadingConciliation={this.props.isLoading}
                />
            </>
    )
  }
}

// Set propTypes
ConciliationExecutionLogs.propTypes = {
  conciliation: PropTypes.object.isRequired,
  fetchExecutionLogs: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
}

// Set defaultProps
ConciliationExecutionLogs.defaultProps = {
  conciliation: {},
  isLoading: true
}

// mapStateToProps
const mapStateToProps = (state) => ({
  executionLogs: state.conciliationExecutionLogs.fetchConciliationExecutionLogsReducer.data.results,
  isLoading: state.conciliationExecutionLogs.fetchConciliationExecutionLogsReducer.loading
})

// mapStateToProps
const mapDispatchToProps = {
  fetchExecutionLogs: conciliationExecutionLogOperations.list
}

// Export Class
const _ConciliationExecutionLogs = connect(mapStateToProps,
  mapDispatchToProps)(ConciliationExecutionLogs)
export { _ConciliationExecutionLogs as ConciliationExecutionLogs }
