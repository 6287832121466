import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { ExtractionShape } from '../../../../prop-types'
import { extractionOperations } from '../../../../state/ducks/extractions'
import { connect } from 'react-redux'
import { BaseFormWizard } from '../../../../assets/js/base_form_wizard'
import { ExtractionDataForm } from '../extraction-data-form'
import { AppUI } from '../../../../assets/js/app'
import { Session } from '../../../../state/utils/session'

class ExtractionWizardForm extends Component {
  constructor (props) {
    super(props)

    // state
    this.state = {
      session: new Session(),
      extraction: { parameters: {} }
    }

    // handlers
    this.onExtractionDataChange = this.onExtractionDataChange.bind(this)
    this.sendData = this.sendData.bind(this)
    AppUI.initHelpers(['highlightjs'])
  }

  componentDidMount () {
    BaseFormWizard.init()
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.extraction && 'id' in nextProps.extraction) {
      this.setState({ extraction: nextProps.extraction })
    }
  }

  onExtractionDataChange (name, value) {
    const extraction = JSON.parse(JSON.stringify(this.state.extraction))
    extraction[name] = value
    this.setState({
      extraction
    })
  }

  sendData () {
    if (!this.props.isEditor) {
      const extraction = this.state.extraction
      extraction.client = this.state.session.getClientSession()
      this.props.saveExtraction(this.state.extraction)
    } else {
      this.props.updateExtraction(this.state.extraction.id, this.state.extraction)
    }
  }

  render () {
    return (
            <>
                <div className="content content-boxed">
                    <div className={' block block-themed block-opt-refresh-icon7 ' + (this.props.isLoading ? ' block-opt-refresh ' : '')}>
                        <div className="block-content">
                            {/* Simple Progress Wizard (.js-wizard-simple class is initialized in js/pages/base_forms_wizard.js) */}
                            {/* For more examples you can check out http://vadimg.com/twitter-bootstrap-wizard-example/ */}
                            <div className="js-wizard-simple block">
                                {/* Steps Progress */}
                                <div className="block-content block-content-mini block-content-full border-b">
                                    <div className="wizard-progress progress active remove-margin-b">
                                        <div className="progress-bar progress-bar-primary progress-bar-striped"
                                             role="progressbar" aria-valuenow="100" aria-valuemin="0"
                                             aria-valuemax="100"
                                             style={{ width: '0' }}>
                                        </div>
                                    </div>
                                </div>
                                {/* END Steps Progress */}

                                {/* Step Tabs */}
                                <ul className="nav nav-tabs nav-tabs-alt nav-justified">
                                    <li className="active">
                                        <a href="#simple-progress-step1" data-toggle="tab">1. Extraction Data</a>
                                    </li>
                                    <li>
                                        <a href="#simple-progress-step2" data-toggle="tab">2. Test Extraction</a>
                                    </li>
                                </ul>
                                {/* END Step Tabs */}

                                {/* Form */}
                                <form className="form-horizontal" action="javascript:void(0)">
                                    {/* Steps Content */}
                                    <div className="block-content tab-content" style={{ minHeight: '300px' }}>
                                        {/* Step 1 */}
                                        <div className="tab-pane fade fade-right in push-30-t push-50 active"
                                             id="simple-progress-step1">
                                            <ExtractionDataForm
                                                extraction={this.state.extraction}
                                                onChange={this.onExtractionDataChange}
                                            />
                                        </div>
                                        {/* END Step 1 */}

                                        {/* Step 2 */}
                                        <div className="tab-pane fade fade-right in push-30-t push-50"
                                             id="simple-progress-step2">
                                            <div className="text-center">
                                                <span>We are working in this functionality, please continue to the next step.</span>
                                            </div>
                                        </div>
                                        {/* END Step 2 */}
                                    </div>
                                    {/* END Steps Content */}

                                    {/* Steps Navigation */}
                                    <div className="block-content block-content-mini block-content-full border-t">
                                        <div className="row">
                                            {
                                                this.props.saveExtractionErrorResponse.error
                                                  ? <div className="col-xs-12 text-danger text-center">
                                                        {typeof this.props.saveExtractionErrorResponse === 'string' ? this.props.saveExtractionErrorResponse.error : JSON.stringify(this.props.saveExtractionErrorResponse.error)}
                                                    </div>
                                                  : null
                                            }
                                            {
                                                this.props.updateExtractionErrorResponse.error
                                                  ? <div className="col-xs-12 text-danger text-center">
                                                        {typeof this.props.saveExtractionErrorResponse === 'string' ? this.props.saveExtractionErrorResponse.error : JSON.stringify(this.props.updateExtractionErrorResponse.error)}
                                                    </div>
                                                  : null
                                            }
                                            <div className="col-xs-6">
                                                <button
                                                    className="wizard-prev btn btn-warning"
                                                    type="button"
                                                >
                                                    <i className="fa fa-arrow-circle-o-left"></i> Previous
                                                </button>
                                            </div>
                                            <div className="col-xs-6 text-right">
                                                <button
                                                    className="wizard-next btn btn-success"
                                                    type="button"
                                                >
                                                    Next <i className="fa fa-arrow-circle-o-right"></i>
                                                </button>
                                                <button
                                                    className="wizard-finish btn btn-primary"
                                                    type="button"
                                                    disabled={this.props.isLoading}
                                                    onClick={this.sendData}
                                                >
                                                    {
                                                        this.props.isLoading
                                                          ? <>
                                                                <i className="ml-1 fa fa-spinner fa-spin pull-right"></i>
                                                                <span>Submit</span>
                                                            </>
                                                          : <>
                                                                <i className="ml-1 si si-login pull-right"></i>
                                                                <span>Submit</span>
                                                            </>
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* END Steps Navigation */}
                                </form>
                                {/* END Form */}
                            </div>
                            {/* END Simple Progress Wizard */}
                            {/* <pre className="pre-sh push"> */}
                            {/*    <code className="json"> */}
                            {/*    {JSON.stringify(this.state.extraction)} */}
                            {/*    </code> */}
                            {/* </pre> */}
                        </div>
                    </div>
                </div>
            </>
    )
  }
}

// Set propTypes
ExtractionWizardForm.propTypes = {
  extraction: ExtractionShape,
  saveExtractionErrorResponse: PropTypes.object,
  updateExtractionErrorResponse: PropTypes.object,
  isEditor: PropTypes.bool
}

// Set defaultProps
ExtractionWizardForm.defaultProps = {
  extraction: {},
  saveExtractionErrorResponse: {},
  updateExtractionErrorResponse: {},
  isEditor: false
}

// mapStateToProps
const mapStateToProps = (state) => ({
  isLoading: state.extractions.saveExtractionReducer.loading || state.extractions.updateExtractionReducer.loading,
  saveExtractionErrorResponse: state.extractions.saveExtractionReducer.error_data,
  updateExtractionErrorResponse: state.extractions.updateExtractionReducer.error_data
})

// mapStateToProps
const mapDispatchToProps = {
  saveExtraction: extractionOperations.save,
  updateExtraction: extractionOperations.update
}

// Export Class
const _ExtractionWizardForm = connect(mapStateToProps, mapDispatchToProps)(ExtractionWizardForm)
export { _ExtractionWizardForm as ExtractionWizardForm }
