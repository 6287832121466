import React, { Component } from 'react'
import { PageContainerLayout } from '../../../layouts'
import { connect } from 'react-redux'
import * as userOperations from '../../../../state/ducks/users/actions'
import { AuditUsersPage } from './users'

// const AuditPlaceholder = () => {
//   return (
//         <div className="empty-placeholder">
//             <img src="/img/placeholders/audit_placeholder.svg" alt=""/>
//         </div>
//   )
// }

class AuditLogsPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      params: {}
    }
  }

  fetchUsers () {
    this.props.fetchUsers(this.state.params)
  }

  componentDidMount () {
    this.fetchUsers()
  }

  render () {
    return (
            <>
                <PageContainerLayout
                    clientSidebar={true}
                    showNavbarClientOptions={true}
                >
                    <div className="content content-boxed">
                        <AuditUsersPage users={this.props.users} paginationData={this.props.paginationData}/>
                    </div>
                </PageContainerLayout>
            </>
    )
  };
}

// Set propTypes
AuditLogsPage.propTypes = {}

// Set defaultProps
AuditLogsPage.defaultProps = {}

const mapStateToProps = (state) => ({
  isLoading: state.users.fetchUsersReducer.loading,
  users: state.users.fetchUsersReducer.data.results,
  paginationData: state.users.fetchUsersReducer.data.pagination_data
})

// mapStateToProps
const mapDispatchToProps = {
  fetchUsers: userOperations.list
}

// Export Class
const AuditLogsPage_ = connect(mapStateToProps, mapDispatchToProps)(AuditLogsPage)
export { AuditLogsPage_ as AuditLogsPage }
