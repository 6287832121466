import React, { Component } from 'react'
import { InputSearchLayout, PageContainerLayout, PageHeaderLayout, PaginationLayout } from '../../../layouts'
import { SourcesHeaderTiles, SourcesTable } from '../../containers'
import { sourceOperations } from '../../../../state/ducks/sources'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { PaginationShape, SourceShape } from '../../../../prop-types'

class SourcesPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      params: {}
    }
    this.fetchSources = this.fetchSources.bind(this)
    this.onPaginationChange = this.onPaginationChange.bind(this)
    this.onSearchChange = this.onSearchChange.bind(this)
    this.updateParams = this.updateParams.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.fetchSources()
  }

  fetchSources () {
    this.props.fetchSources(this.state.params)
  }

  onPaginationChange (page) {
    this.updateParams('page', page)
  }

  onSearchChange (keywork) {
    this.updateParams('search', keywork)
  }

  updateParams (key, value) {
    const { params } = this.state
    params[key] = value
    if (key === 'search') {
      params.page = 1
    }
    this.setState({
      params
    }, () => this.fetchSources())
  }

  renderHeaderPaths () {
    return (
            <>
                <li>Sources</li>
            </>
    )
  }

  render () {
    return (
            <>
                <PageContainerLayout clientSidebar={true} showNavbarClientOptions={true}>
                    <PageHeaderLayout title={'Sources'} subTitle={'All sources'}
                                      paths={this.renderHeaderPaths}/>
                    {/* Page Content */}
                    <div className="content content-boxed">
                        {/* Header Tiles */}
                        <SourcesHeaderTiles all_sources={this.props.paginationData.totalItems}/>
                        {/* END Header Tiles */}
                        <div className={' block block-themed block-opt-refresh-icon7 ' + (this.props.isLoading ? ' block-opt-refresh ' : '')}>
                            <div className="block-header bg-primary-dark">
                                <ul className="block-options">
                                    <li>
                                        <button type="button" data-toggle="block-option"
                                                data-action="fullscreen_toggle">
                                            <i className="si si-size-fullscreen"/>
                                        </button>
                                    </li>
                                    <li>
                                        <button type="button"
                                                data-toggle="block-option"
                                                data-action="refresh_toggle"
                                                data-action-mode="demo"
                                                onClick={this.fetchSources}
                                        >
                                            <i className="si si-refresh"></i>
                                        </button>
                                    </li>
                                </ul>
                                <h3 className="block-title">All sources</h3>
                            </div>
                            <div className="block-content">
                                {/* Search */}
                                <InputSearchLayout onSearchChange={this.onSearchChange}/>
                                {/* End Search */}
                                {/* Table */}
                                <SourcesTable sources={this.props.sources} params={this.state.params}>
                                    {/* Pagination */}
                                    <PaginationLayout pagination={this.props.paginationData}
                                                      onPaginationChange={this.onPaginationChange}/>
                                    {/* End Pagination */}
                                </SourcesTable>
                                {/* End Table */}
                            </div>
                        </div>
                    </div>
                    {/* END Page Content */}
                </PageContainerLayout>
            </>
    )
  };
}

// Set propTypes
const { arrayOf, func } = PropTypes
SourcesPage.propTypes = {
  sources: arrayOf(SourceShape).isRequired,
  fetchSources: func.isRequired,
  paginationData: PaginationShape
}

// Set defaultProps
SourcesPage.defaultProps = {
  sources: [],
  paginationData: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  isLoading: state.sources.fetchSourcesReducer.loading,
  sources: state.sources.fetchSourcesReducer.data.results,
  paginationData: state.sources.fetchSourcesReducer.data.pagination_data
})

// mapStateToProps
const mapDispatchToProps = {
  fetchSources: sourceOperations.list
}

// Export Class
const _SourcesPage = connect(mapStateToProps, mapDispatchToProps)(SourcesPage)
export { _SourcesPage as SourcesPage }
