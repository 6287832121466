import { combineReducers } from 'redux'
import * as types from './types'

const fetchPermissionsInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { results: [], pagination_data: {} }
}

const getPermissionInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { groups: [] }
}

const savePermissionInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

const updatePermissionInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

export function fetchPermissionsReducer (state = fetchPermissionsInitialState, action) {
  switch (action.type) {
    case types.FETCH_PERMISSIONS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.FETCH_PERMISSIONS_REQUEST_FAILURE:
      return {
        ...fetchPermissionsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.FETCH_PERMISSIONS_REQUEST_SUCCESS:
      return {
        ...fetchPermissionsInitialState,
        data: action.payload.data
      }
    case types.FETCH_PERMISSIONS_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function getPermissionReducer (state = getPermissionInitialState, action) {
  switch (action.type) {
    case types.GET_PERMISSION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.GET_PERMISSION_REQUEST_FAILURE:
      return {
        ...getPermissionInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.GET_PERMISSION_REQUEST_SUCCESS:
      return {
        ...getPermissionInitialState,
        data: action.payload.data
      }
    case types.GET_PERMISSION_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function savePermissionReducer (state = savePermissionInitialState, action) {
  switch (action.type) {
    case types.SAVE_PERMISSION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.SAVE_PERMISSION_REQUEST_FAILURE:
      return {
        ...fetchPermissionsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.SAVE_PERMISSION_REQUEST_SUCCESS:
      return {
        ...fetchPermissionsInitialState,
        data: action.payload.data
      }
    case types.SAVE_PERMISSION_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function updatePermissionReducer (state = updatePermissionInitialState, action) {
  switch (action.type) {
    case types.UPDATE_PERMISSION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.UPDATE_PERMISSION_REQUEST_FAILURE:
      return {
        ...updatePermissionInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.UPDATE_PERMISSION_REQUEST_SUCCESS:
      return {
        ...updatePermissionInitialState,
        data: action.payload.data
      }
    case types.UPDATE_PERMISSION_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export default combineReducers({
  fetchPermissionsReducer,
  savePermissionReducer,
  getPermissionReducer,
  updatePermissionReducer
})
