import React, { Component } from 'react'
import { InputSearchLayout, PageContainerLayout, PageHeaderLayout, PaginationLayout } from '../../../layouts'
import { AccountingSeatSettingsHeaderTiles, AccountingSeatSettingsTable } from '../../containers'
import { accountingSeatSettingsOperations } from '../../../../state/ducks/accounting-seat-settings'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import { PaginationShape } from '../../../../prop-types'
import { jsonToQueryString, queryStringToJSON } from '../../../../state/utils/apiService'
import { history } from '../../../../routing/History'

class AccountingSeatSettingsPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      params: {}
    }
    this.fetchAccountingSeatSettings = this.fetchAccountingSeatSettings.bind(this)
    this.onPaginationChange = this.onPaginationChange.bind(this)
    this.onSearchChange = this.onSearchChange.bind(this)
    this.addQueryParam = this.addQueryParam.bind(this)
    this.updateQueryParams = this.updateQueryParams.bind(this)
    this.onSortBy = this.onSortBy.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.fetchAccountingSeatSettings(queryStringToJSON(this.props.location.search))
  }

  componentDidMount () {
    window.scrollTo(0, 0)
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.location.search !== this.props.location.search) {
      this.fetchAccountingSeatSettings(queryStringToJSON(nextProps.location.search))
    }
  }

  fetchAccountingSeatSettings (params) {
    this.setState({ params }, () => this.props.fetchAccountingSeatSettings(this.state.params))
  }

  onPaginationChange (page) {
    this.addQueryParam('page', page)
  }

  onSearchChange (keywork) {
    this.addQueryParam('search', keywork)
  }

  onSortBy (att, sortBy) {
    this.addQueryParam('ordering', sortBy === -1 ? '-' + att : att)
  }

  addQueryParam (key, value) {
    const { params } = this.state
    params[key] = value
    if (key === 'search') {
      params.page = 1
    }
    this.updateQueryParams(params)
  }

  updateQueryParams (params = null) {
    history.push({
      pathname: this.props.location.pathname,
      search: params ? jsonToQueryString(params) : ''
    })
  }

  render () {
    const renderHeaderPaths = () => {
      return (
                <>
                    <li>Accounting Seat</li>
                </>
      )
    }

    return (
            <>
                <PageContainerLayout
                    clientSidebar={true}
                    showNavbarClientOptions={true}
                >
                    {/* Page Header */}
                    <PageHeaderLayout
                        title={'Accounting Seat'}
                        subTitle={'Settings'}
                        paths={renderHeaderPaths}
                    />
                    {/* END Page Header */}
                    {/* Page Content */}
                    <div className="content content-boxed">
                        {/* Header Tiles */}
                        <AccountingSeatSettingsHeaderTiles
                            statusGroups={this.props.statusGroups}
                            onQueryParamsChange={this.updateQueryParams}
                        />
                        {/* END Header Tiles */}
                        <div className={
                            ' block block-themed block-opt-refresh-icon7 ' +
                            (this.props.isLoading ? ' block-opt-refresh ' : '')
                        }>
                            <div className="block-header bg-primary-dark">
                                <ul className="block-options">
                                    <li>
                                        <button type="button" data-toggle="block-option"
                                                data-action="fullscreen_toggle">
                                            <i className="si si-size-fullscreen"/>
                                        </button>
                                    </li>
                                    <li>
                                        <button type="button"
                                                data-toggle="block-option"
                                                data-action="refresh_toggle"
                                                data-action-mode="demo"
                                                onClick={this.fetchAccountingSeatSettings}
                                        >
                                            <i className="si si-refresh"/>
                                        </button>
                                    </li>
                                </ul>
                                <h3 className="block-title">All Accounting Seat Settings</h3>
                            </div>
                            <div className="block-content">
                                {/* Search */}
                                <InputSearchLayout
                                    onSearchChange={this.onSearchChange}
                                />
                                {/* End Search */}
                                {/* Table */}
                                <AccountingSeatSettingsTable
                                    accountingSeatSettings={this.props.accountingSeatSettings}
                                    onSortBy={this.onSortBy}
                                >
                                    {/* Pagination */}
                                    <PaginationLayout
                                        pagination={this.props.paginationData}
                                        onPaginationChange={this.onPaginationChange}
                                    />
                                    {/* End Pagination */}
                                </AccountingSeatSettingsTable>
                                {/* End Table */}
                            </div>
                        </div>
                    </div>
                    {/* END Page Content */}
                </PageContainerLayout>
            </>
    )
  };
}

// Set propTypes
AccountingSeatSettingsPage.propTypes = {
  accountingSeatSettings: PropTypes.array.isRequired,
  fetchAccountingSeatSettings: PropTypes.func.isRequired,
  paginationData: PaginationShape
}

// Set defaultProps
AccountingSeatSettingsPage.defaultProps = {
  accountingSeatSettings: [],
  paginationData: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  isLoading: state.accountingSeatSettings.fetchAccountingSeatSettingsReducer.loading,
  accountingSeatSettings: state.accountingSeatSettings.fetchAccountingSeatSettingsReducer.data.results,
  paginationData: state.accountingSeatSettings.fetchAccountingSeatSettingsReducer.data.pagination_data
})

// mapStateToProps
const mapDispatchToProps = {
  fetchAccountingSeatSettings: accountingSeatSettingsOperations.list
}

// Export Class
const _AccountingSeatSettingsPage = connect(mapStateToProps, mapDispatchToProps)(AccountingSeatSettingsPage)
export { _AccountingSeatSettingsPage as AccountingSeatSettingsPage }
