import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Session } from '../../../../state/utils/session'
import { LOGIN_PATH } from '../../../../routing/Paths'
import { history } from '../../../../routing/History'

// Class
class TokenValidatorMessage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      error_message: '',
      session: new Session()
    }

    this.goToLogin = this.goToLogin.bind(this)
  }

  componentDidMount () {
    setTimeout(() => {
      if (this.props.match.path.includes('failure')) {
        this.setState({ error_message: this.props.match.params.message })
      }
      if (this.props.match.path.includes('success')) {
        this.state.session.setSession(this.props.match.params.token)
      }
    }, 1300)
  }

  goToLogin () {
    history.push(LOGIN_PATH)
  }

  render () {
    console.log('permisos')
    return (
            <>
                <div className=" block block-themed block-opt-refresh-icon7 animated fadeIn">
                    <div className="block-header bg-primary">
                        <ul className="block-options"></ul>
                        {
                            !this.state.error_message
                              ? <h3 className="block-title">Authenticating...</h3>
                              : <h3 className="block-title">Authentication Error</h3>
                        }
                    </div>
                    <div className="block-content block-content-full block-content-narrow text-center">
                        <br/>
                        <br/>
                        {
                            !this.state.error_message
                              ? <>
                                    <i className="fa fa-4x fa-cog fa-spin text-primary"></i>
                                </>
                              : <>
                                    <br/>
                                    <p>{this.state.error_message}</p>
                                    <br/>
                                    <h3 className="block-title cursor-pointer" onClick={this.goToLogin}>
                                        Back to Login
                                    </h3>
                                    <br/>
                                    <i className="fa fa-4x fa-home text-primary cursor-pointer"
                                       onClick={this.goToLogin}></i>
                                </>
                        }
                    </div>
                </div>
            </>
    )
  };
}

// Set propTypes
TokenValidatorMessage.propTypes = {}

// Set defaultProps
TokenValidatorMessage.defaultProps = {
  match: {}
}

// Set prefetch
TokenValidatorMessage.prefetch = null

// mapStateToProps
const mapStateToProps = (state) => ({})

// mapStateToProps
const mapDispatchToProps = {}

// Export Class
const _TokenValidatorMessage = connect(mapStateToProps, mapDispatchToProps)(TokenValidatorMessage)
export { _TokenValidatorMessage as TokenValidatorMessage }
