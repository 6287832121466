import React, { Component } from 'react'
import { history } from '../../../../routing/History'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { Session } from '../../../../state/utils/session'

class AutomationDetailsCard extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }
    this.goToAutomationEditor = this.goToAutomationEditor.bind(this)
  }

  goToAutomationEditor () {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_AUTOMATION_EDITOR_PATH
        .replace(':automation_id', this.props.automation.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  render () {
    return (
            <>
                <div className="block">
                    <div className="block-header bg-gray-lighter">
                        <ul className="block-options">
                            <li>
                                <button type="button" onClick={this.goToAutomationEditor}>
                                    <i className="fa fa-edit"></i>
                                </button>
                            </li>
                        </ul>
                        <h3 className="block-title">
                            <i className="fa fa-info-circle"></i> Details
                        </h3>
                    </div>
                    <div className="block-content">
                        <ul className="list list-activity push">
                            <li>
                                <div className="font-w600">ID:</div>
                                <div>{this.props.automation.id}</div>
                            </li>
                            <li>
                                <div className="font-w600">Automation name:</div>
                                <div>{this.props.automation.name}</div>
                            </li>
                            <li>
                                <div className="font-w600">Description:</div>
                                <div>{this.props.automation.description}</div>
                            </li>
                            <li>
                                <div className="font-w600">Suffix:</div>
                                <div>{this.props.automation.suffix}</div>
                            </li>
                            <li>
                                <div className="font-w600">For all sources:</div>
                                <div>{JSON.stringify(this.props.automation.for_all_sources)}</div>
                            </li>
                            <li>
                                <div className="font-w600">For all conciliations:</div>
                                <div>{JSON.stringify(this.props.automation.for_all_conciliations)}</div>
                            </li>
                            <li>
                                <div className="font-w600">For all alarms:</div>
                                <div>{JSON.stringify(this.props.automation.for_all_alarms)}</div>
                            </li>
                            <li>
                                <div className="font-w600">Active:</div>
                                <div>{JSON.stringify(this.props.automation.active)}</div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="block">
                    <div className="block-header bg-gray-lighter">
                        <ul className="block-options">
                            <li>
                                <button type="button" onClick={this.goToAutomationEditor}>
                                    <i className="fa fa-edit"></i>
                                </button>
                            </li>
                        </ul>
                        <h3 className="block-title">
                            <i className="fa fa-info-circle"></i> Executions
                        </h3>
                    </div>
                    <div className="block-content">
                        <div className="p-4">
                            <h5 className="mr-2 font-weight-bold">
                                Next Executions
                            </h5>
                            <hr/>
                            <ul className="list-group">
                                {
                                    this.props.automation.next_three_executions
                                      ? this.props.automation.next_three_executions.map((e, i) => {
                                        return (
                                                <li className="list-group-item"
                                                key={`next_three_executions-${i}`}
                                                >
                                                    <i className="fa fa-clock mr-2"></i>
                                                    {e} GTM
                                                </li>
                                        )
                                      })
                                      : null
                                }
                            </ul>
                        </div>
                        <div className="p-4">
                            <h5 className="mr-2 font-weight-bold">
                                Previous Executions
                            </h5>
                            <hr/>
                            <ul className="list-group">
                                {
                                    this.props.automation.automation_logs
                                      // eslint-disable-next-line array-callback-return
                                      ? this.props.automation.automation_logs.map((log, index) => {
                                        if (index < 3) {
                                          return (
                                                    <li className="list-group-item"
                                                    key={`automation_logs-${index}`}
                                                    >
                                                        <i className="fa fa-clock mr-2"></i>
                                                        {log.created_at} GMT
                                                    </li>
                                          )
                                        }
                                      })
                                      : null
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </>
    )
  }
}

// Set defaultProps
AutomationDetailsCard.defaultProps = {
  automation: {}
}

// Export Class
export { AutomationDetailsCard }
