import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import SelectSearchLayout from '../../../layouts/select-search'
import { conciliationOperations } from '../../../../state/ducks/conciliations'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import { Session } from '../../../../state/utils/session'

class AutomationConciliationsForm extends Component {
  constructor (props) {
    super(props)

    // set state
    this.state = {
      session: new Session()
    }

    // handlers
    this.handleCheckbox = this.handleCheckbox.bind(this)
    this.returnOptions = this.returnOptions.bind(this)
    this.onConciliationSearchKeywordChange = this.onConciliationSearchKeywordChange.bind(this)
    this.onSelectConciliation = this.onSelectConciliation.bind(this)
    this.removeConciliation = this.removeConciliation.bind(this)
  }

  handleCheckbox (event) {
    if (!this.props.automation.for_all_conciliations === true) {
      this.props.onChange('conciliations', [])
    }
    setTimeout(() => {
      this.props.onChange('for_all_conciliations', !this.props.automation.for_all_conciliations)
    }, 100)
  }

  returnOptions () {
    const options = []
    this.props.conciliations_list.forEach(element => {
      const groupAdded = this.props.automation.conciliations.find(
        p => p.toString() === element.id.toString()
      )
      if (!groupAdded) {
        options.push({
          value: `${element.id}|${element.name}`,
          label: element.name + '(' + element.id + ')'
        })
      }
    })
    return options
  }

  onConciliationSearchKeywordChange (keyword) {
    this.returnOptions()
    this.props.fetchConciliations({ search: keyword })
  }

  onSelectConciliation (item) {
    const addedConciliations = this.props.automation.conciliations
    const split_ = item.value.split('|')
    addedConciliations.push({
      id: split_[0],
      name: split_[1]
    })
    this.props.onChange('conciliations', addedConciliations)
    console.log('addedConciliations', addedConciliations)
  }

  removeConciliation (index) {
    const addedConciliations = this.props.automation.conciliations
    addedConciliations.splice(index, 1)
    this.props.onChange('conciliations', addedConciliations)
  }

  renderConciliations () {
    return (
            <>
                <table className="table">
                    <tbody>
                    {
                        this.props.automation.conciliations.map((conciliation, index) => {
                          return (
                                <tr key={index} className="border">
                                    <td style={{ width: '20px' }}>
                                        <i className="fa fa-th fa-2x"></i>
                                    </td>
                                    <td>
                                        <small className="font-w400">{conciliation.name}  ({conciliation.id})</small>
                                        <div className="font-w400 text-muted">
                                            <small>---</small>
                                        </div>
                                    </td>
                                    <td style={{ width: '20px' }}>
                                        <Button
                                            variant="danger"
                                            className="float-right"
                                            onClick={this.removeConciliation.bind(this, index)}
                                        >
                                            <i className="fa fa-trash"/>
                                        </Button>
                                    </td>
                                </tr>
                          )
                        })
                    }
                    </tbody>
                </table>
                {/* <pre> */}
                {/*    <code className="json"> */}
                {/*        {JSON.stringify(this.props.automation.conciliations)} */}
                {/*    </code> */}
                {/* </pre> */}
            </>
    )
  }

  render () {
    return (
            <>
                <div className="form-group">
                    <div className="col-sm-8 col-sm-offset-2 text-center" style={{ minHeight: '70px' }}>
                        <div className="">
                            <label className="css-input switch switch-success">
                                <input
                                    type="checkbox"
                                    value={this.props.automation.for_all_conciliations}
                                    checked={this.props.automation.for_all_conciliations}
                                    onChange={this.handleCheckbox}
                                />
                                <span></span> Add all conciliations
                            </label>
                        </div>
                    </div>
                    {
                        !this.props.automation.for_all_conciliations
                          ? <>
                                <div className="form-group">
                                    <div className="col-sm-6 col-sm-offset-3">
                                        <div className="form-material">
                                            <SelectSearchLayout
                                                getOptions={this.returnOptions}
                                                onSearchChange={this.onConciliationSearchKeywordChange}
                                                onSelectItem={this.onSelectConciliation}
                                            />
                                            <label htmlFor="input-groups">Search conciliations</label>
                                        </div>
                                        {this.renderConciliations()}
                                    </div>
                                </div>
                            </>
                          : null
                    }
                </div>
            </>
    )
  }
}

// Set propTypes
AutomationConciliationsForm.propTypes = {
  conciliations_list: PropTypes.array.isRequired,
  fetchConciliations: PropTypes.func.isRequired
}

// Set defaultProps
AutomationConciliationsForm.defaultProps = {
  conciliations_list: [],
  automation: [],
  onChange: () => {
  }
}

// mapStateToProps
const mapStateToProps = (state) => ({
  conciliations_list: state.conciliations.fetchConciliationsReducer.data.results
})

// mapStateToProps
const mapDispatchToProps = {
  fetchConciliations: conciliationOperations.list
}

// Export Class
const _AutomationConciliationsForm = connect(mapStateToProps,
  mapDispatchToProps)(AutomationConciliationsForm)
export { _AutomationConciliationsForm as AutomationConciliationsForm }
