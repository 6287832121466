import React from 'react'
import { useDispatch } from 'react-redux'
import { EmptyTableLayout } from '../../../layouts'
import { getInconsistencies } from '../../../../state/ducks/dynamic-parametries'

export function DynamicParametriesTable ({ children, data }) {
  const dispatch = useDispatch()
  return (
    <>
      <div className="table-responsive">
        <table className="table table-striped table-bordered table-condensed text-center">
          <thead style={{ backgroundColor: '#1f2b5c', color: '#fff' }}>
            <tr>
              <th className="text-center">ID</th>
              <th className="text-center">Name</th>
              <th className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {data.map(({ id, label }) => {
              return (
                <tr key={id}>
                  <td className="text-center">{id}</td>
                  <td className="text-center">{label}</td>
                  <td className="text-center">
                    <button
                      className="btn btn-sm btn-default"
                      type="button"
                      onClick={() => dispatch(getInconsistencies(label))}
                    >
                      Download
                    </button>
                  </td>
                </tr>
              )
            })}
            <EmptyTableLayout results={data} columns={4} />
          </tbody>
        </table>
        {children}
      </div>
    </>
  )
}
