import reducer from './reducers'

import * as alarmExecutionLogOperations from './operations'
import * as alarmExecutionLogEndpoints from './paths'

export {
  alarmExecutionLogOperations,
  alarmExecutionLogEndpoints
}

export default reducer
