import React from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated/dist/react-select.esm'

export function JobFilter (props) {
  const animatedComponents = makeAnimated()
  const { onChange, options, selected } = props
  return (
    <Select
      placeholder="Select an option..."
      className="select-filter"
      isMulti={false}
      isSearchable
      value={selected}
      components={animatedComponents}
      closeMenuOnSelect={false}
      onChange={selectedOption => onChange(selectedOption)}
      options={options}
    />
  )
}
