import reducer from './reducers'

import * as connectionOperations from './operations'
import * as connectionEndpoints from './paths'

export {
  connectionOperations,
  connectionEndpoints
}

export default reducer
