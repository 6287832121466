import * as PropTypes from 'prop-types'

const { number, shape } = PropTypes

const ClientShape = shape({
  id: number,
  name: PropTypes.string, // .isRequired
  logo: PropTypes.string, // .isRequired
  region: PropTypes.string, // .isRequired
  emr_suffix: PropTypes.string, // .isRequired
  users: PropTypes.array
})

export { ClientShape }
