import React, { Component } from 'react'
import { PageContainerLayout } from '../../../layouts'
import { connect } from 'react-redux'

class SourcePickerWizard extends Component {
  constructor (props) {
    super(props)
  }

  render () {
    return (
            <>
                <div className="wizard-content">
                    <div className="flex-row">
                        <div className="wizard-title">1. Elegí una fuente</div>
                    </div>
                    <div className="wizard-body">
                        <div className="flex-row">
                            <div className="unrec-source-card">
                                <span className="flag-icon flag-icon-ar"/>
                                <span className="source-name">Site MLA</span>
                            </div>
                            <div className="unrec-source-card">
                                <span className="flag-icon flag-icon-br"/>
                                <span className="source-name">Site MLB</span>
                            </div>
                            <div className="unrec-source-card">
                                <span className="flag-icon flag-icon-mx"/>
                                <span className="source-name">Site MLM</span>
                            </div>
                        </div>
                    </div>
                    <div className="wizard-navigation">
                        <div className="btn btn-rounded">
                            Next
                        </div>
                    </div>
                </div>
            </>
    )
  }
}

class NonReconciledItemsPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    return (
            <>
                <PageContainerLayout
                    clientSidebar={true}
                    showNavbarClientOptions={true}
                >

                    {/* Page Content */}
                    <div className="content content-boxed">
                        <SourcePickerWizard/>
                    </div>
                    {/* END Page Content */}
                </PageContainerLayout>
            </>
    )
  };
}

// Set propTypes
NonReconciledItemsPage.propTypes = {
  // permissions: PropTypes.arrayOf(PermissionShape).isRequired,
  // fetchPermissions: PropTypes.func.isRequired,
  // paginationData: PaginationShape
}

// Set defaultProps
NonReconciledItemsPage.defaultProps = {
  sources: [],
  paginationData: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  /* isLoading: state.permissions.fetchPermissionsReducer.loading,
    isCompleted: state.permissions.fetchPermissionsReducer.completed,
    permissions: state.permissions.fetchPermissionsReducer.data.results,
    paginationData: state.permissions.fetchPermissionsReducer.data.pagination_data */
})

// mapStateToProps
const mapDispatchToProps = {
  // fetchPermissions: permissionOperations.list
}

// Export Class
const _NonReconciledItemsPage = connect(mapStateToProps, mapDispatchToProps)(NonReconciledItemsPage)
export { _NonReconciledItemsPage as NonReconciledItemsPage }
