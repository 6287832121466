import React, { Component } from 'react'
import { EmptyTableLayout } from '../../../../layouts'
import { history } from '../../../../../routing/History'
import * as ROUTING_PATHS from '../../../../../routing/Paths'
import { Session } from '../../../../../state/utils/session'

class ReleasesTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }
    this.selectRelease = this.selectRelease.bind(this)
  }

  renderReleaseStatus = (release) => {
    const status = release.status
    if (status === 'created') {
      return <div className='release-status-container'><div className='release-status'>Pendiente de aprobación</div></div>
    } else if (status === 'approved') {
      return <div className='release-status-container'><div className='release-status alternate'>Versión aprobada</div></div>
    } else if (status === 'rejected') {
      return <div className='release-status-container'><div className='release-status error'>Versión rechazada</div></div>
    } else if (status === 'running') {
      return <div className='release-status-container'><div className='release-status alternate'>Desplegando cambios</div></div>
    } else if (status === 'failed') {
      return <div className='release-status-container'><div className='release-status error'>Despliegue con errores</div></div>
    } else if (status === 'deployed') {
      return <div className='release-status-container'><div className='release-status success'>Desplegado exitosamente</div></div>
    }
  }

  selectRelease (release) {
    history.push(
      ROUTING_PATHS.DEPLOY_TOOL_DETAILS_PATH
        .replace(':release_id', release.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  render () {
    return (
            <>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered table-condensed text-center">
                        <thead>
                        <tr>
                            <th className="text-center">ID</th>
                            <th className="text-center">Código de la versión</th>
                            <th className="text-center">Estado</th>
                            <th className="text-center">Creada el</th>
                            <th className="text-center">Acciones</th>

                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.releases.map((release) => {
                              return (
                                    <tr key={release.id}>
                                        <td className="text-center">{release.id}</td>
                                        <td className="text-center">
                                            <a>{release.version_code}</a>
                                        </td>
                                        <td className="text-center">{this.renderReleaseStatus(release)}</td>
                                        <td className="text-center">{release.applied_at || release.created_at}</td>
                                        <td className="text-center">
                                            <div className="btn-group">
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Ver detalles"
                                                        onClick={this.selectRelease.bind(this, release)}
                                                >
                                                    <i className="fa fa-eye"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                              )
                            })
                        }
                        <EmptyTableLayout results={this.props.releases} columns={5}/>
                        </tbody>
                    </table>
                    {this.props.children}
                </div>
            </>
    )
  }
}

// Set defaultProps
ReleasesTable.defaultProps = {
  releases: []
}

// Export Class
export { ReleasesTable }
