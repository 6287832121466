// CLIENTS
export const FETCH_CLIENTS_REQUEST = 'session/FETCH_CLIENTS_REQUEST'
export const FETCH_CLIENTS_REQUEST_SUCCESS = 'session/FETCH_CLIENTS_REQUEST_SUCCESS'
export const FETCH_CLIENTS_REQUEST_FAILURE = 'session/FETCH_CLIENTS_REQUEST_FAILURE'
export const FETCH_CLIENTS_REQUEST_COMPLETED = 'session/FETCH_CLIENTS_REQUEST_COMPLETED'

export const GET_CLIENT_REQUEST = 'session/GET_CLIENT_REQUEST'
export const GET_CLIENT_REQUEST_SUCCESS = 'session/GET_CLIENT_REQUEST_SUCCESS'
export const GET_CLIENT_REQUEST_FAILURE = 'session/GET_CLIENT_REQUEST_FAILURE'
export const GET_CLIENT_REQUEST_COMPLETED = 'session/GET_CLIENT_REQUEST_COMPLETED'

export const SAVE_CLIENT_REQUEST = 'session/SAVE_CLIENT_REQUEST'
export const SAVE_CLIENT_REQUEST_SUCCESS = 'session/SAVE_CLIENT_REQUEST_SUCCESS'
export const SAVE_CLIENT_REQUEST_FAILURE = 'session/SAVE_CLIENT_REQUEST_FAILURE'
export const SAVE_CLIENT_REQUEST_COMPLETED = 'session/SAVE_CLIENT_REQUEST_COMPLETED'

export const UPDATE_CLIENT_REQUEST = 'session/UPDATE_CLIENT_REQUEST'
export const UPDATE_CLIENT_REQUEST_SUCCESS = 'session/UPDATE_CLIENT_REQUEST_SUCCESS'
export const UPDATE_CLIENT_REQUEST_FAILURE = 'session/UPDATE_CLIENT_REQUEST_FAILURE'
export const UPDATE_CLIENT_REQUEST_COMPLETED = 'session/UPDATE_CLIENT_REQUEST_COMPLETED'

export const PERMISSIONS_CLIENT_REQUEST = 'session/PERMISSIONS_CLIENT_REQUEST'
export const PERMISSIONS_CLIENT_REQUEST_SUCCESS = 'session/PERMISSIONS_CLIENT_REQUEST_SUCCESS'
export const PERMISSIONS_CLIENT_REQUEST_FAILURE = 'session/PERMISSIONS_CLIENT_REQUEST_FAILURE'
export const PERMISSIONS_CLIENT_REQUEST_COMPLETED = 'session/PERMISSIONS_CLIENT_REQUEST_COMPLETED'
