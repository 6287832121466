import React, { Component } from 'react'
import * as sancronos from 'sancronos-validator'
import config from '../../../../config'

class AutomationDataForm extends Component {
  constructor (props) {
    super(props)

    // state
    this.state = {
      featureMode: ['MELI_TEST', 'MELI_PROD'].includes(config.featureMode)
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleCronExpression = this.handleCronExpression.bind(this)
    this.validateCronMinutes = this.validateCronMinutes.bind(this)
    this.validateCron = this.validateCron.bind(this)
    this.validateCronMinutes = this.validateCronMinutes.bind(this)
    this.handleCheckbox = this.handleCheckbox.bind(this)
    this.handleStopIfFails = this.handleStopIfFails.bind(this)
  }

  handleChange (event) {
    this.props.onChange(event.target.name, event.target.value)
  }

  handleCronExpression (event) {
    this.props.onChange('cron_expression', event.target.value)
  }

  handleCheckbox (event) {
    this.props.onChange('active', !this.props.automation.active)
  }

  handleStopIfFails () {
    this.props.onChange('stop_if_failed', !this.props.automation.stop_if_failed)
  }

  async validateCron () {
    await sancronos.isValid(this.props.automation.cron_expression)
      .then(() => {
        return true
      })
      .catch(e => {
        return false
      })
  }

  validateCronMinutes () {
    const cronExpression = this.props.automation.cron_expression
    if (cronExpression) {
      const minutes = cronExpression.split(' ')
      let isAValidCronExpressionMinute = true
      if (minutes[0].toString() !== '*/30' && minutes[0].toString() !== '0' && minutes[0].toString() !== '30') {
        isAValidCronExpressionMinute = false
      }
      return isAValidCronExpressionMinute
    }
    return false
  }

  render () {
    return (
            <>
                <div className="form-group">
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <div className="form-material">
                            <input className="form-control"
                                   type="text"
                                   id="input-name"
                                   placeholder="Please enter a name"
                                   name="name"
                                   value={this.props.automation.name}
                                   onChange={this.handleChange}
                            />
                            <label htmlFor="input-name">Name</label>
                        </div>
                    </div>
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <div className="form-material">
                            <input
                                className="form-control"
                                type="text"
                                id="input-description"
                                placeholder="Please enter a description"
                                name="description"
                                value={this.props.automation.description}
                                onChange={this.handleChange}
                            />
                            <label htmlFor="input-description">Description</label>
                        </div>
                    </div>
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <div className="form-material">
                            <input
                                className="form-control"
                                type="text"
                                id="input-suffix"
                                placeholder="Please enter a suffix"
                                name="suffix"
                                value={this.props.automation.suffix}
                                onChange={this.handleChange}
                            />
                            <label htmlFor="input-suffix">Suffix</label>
                        </div>
                    </div>
                    <div
                        className={((!this.validateCron() || !this.validateCronMinutes()) && this.props.automation.cron_expression.length ? 'col-sm-8 col-sm-offset-2 has-error' : 'col-sm-8 col-sm-offset-2')}
                        style={{ minHeight: '70px' }}>
                        <div className="form-material">
                            <input className="form-control"
                                   type="text"
                                   id="input-cron_expression"
                                   placeholder="Please enter a cron expression"
                                   name="cron_expression"
                                   value={this.props.automation.cron_expression}
                                   onChange={this.handleCronExpression}
                            />
                            <label htmlFor="input-name">Cron Expression <b>(GMT)</b></label>
                            {
                                !this.validateCronMinutes()
                                  ? <small className="text-muted ml-2 mt-2">Allowed minutes (*/30 , 30,
                                        0)</small> : null
                            }
                            <br/>
                            <small className="text-muted"><a href="https://crontab.guru/#0_8_*_*_*" target="_blank" rel="noreferrer">See
                                Crons Creator for more details</a></small>
                        </div>
                    </div>
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <br/>
                        {this.state.featureMode ? (
                            <div
                                style={{ display: 'flex', justifyContent: 'space-around' }}
                            >
                                <label
                                className="css-input switch switch-success"
                                style={{ fontWeight: 'bold' }}
                                >
                                <input
                                    type="checkbox"
                                    value={this.props.automation.active}
                                    checked={this.props.automation.active}
                                    onChange={this.handleCheckbox}
                                    defaultChecked={true}
                                />
                                <span /> Active?
                                </label>
                                <label
                                className="css-input switch switch-success"
                                style={{ fontWeight: 'bold' }}
                                >
                                <input
                                    type="checkbox"
                                    value={this.props.automation.stop_if_failed}
                                    checked={this.props.automation.stop_if_failed}
                                    onChange={this.handleStopIfFails}
                                />
                                <span /> Stop If Fails
                                </label>
                            </div>
                        ) : (
                            <div className="form-material">
                                <label className="css-input switch switch-success">
                                <input
                                    type="checkbox"
                                    value={this.props.automation.active}
                                    checked={this.props.automation.active}
                                    onChange={this.handleCheckbox}
                                    defaultChecked={true}
                                />
                                <span></span> Active?
                                </label>
                            </div>
                        )}
                    </div>
                </div>
            </>
    )
  }
}

// Set defaultProps
AutomationDataForm.defaultProps = {
  automation: {},
  onChange: () => {
  }
}

export { AutomationDataForm }
