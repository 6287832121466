import React, { Component } from 'react'
import { history } from '../../../../routing/History'
import * as ROUTING_PATHS from '../../../../routing/Paths'

class PermissionDetailsCard extends Component {
  constructor (props) {
    super(props)

    this.state = {}
    this.goToPermissionEditor = this.goToPermissionEditor.bind(this)
  }

  goToPermissionEditor () {
    history.push(ROUTING_PATHS.PERMISSION_EDITOR_PATH.replace(':permission_id', this.props.permission.id))
  }

  render () {
    return (
            <>
                <div className="block">
                    <div className="block-header bg-gray-lighter">
                        <ul className="block-options">
                            <li>
                                <button type="button" onClick={this.goToPermissionEditor}>

                                    <i className="fa fa-edit"></i>
                                </button>
                            </li>
                        </ul>
                        <h3 className="block-title">
                            <i className="fa fa-info-circle"></i> Details
                        </h3>
                    </div>
                    <div className="block-content">
                        <ul className="list list-activity push">
                            <li>
                                <div className="font-w600">Codename:</div>
                                <div>{this.props.permission.codename}</div>
                            </li>
                            <li>
                                <div className="font-w600">Name:</div>
                                <div>{this.props.permission.name}</div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="block">
                    <div className="block-header bg-gray-lighter">
                        <ul className="block-options">
                            <li>
                                <button type="button" onClick={this.goToPermissionEditor}>
                                    <i className="fa fa-edit"></i>
                                </button>
                            </li>
                        </ul>
                        <h3 className="block-title">
                            <i className="fa fa-lock"></i> Groups
                        </h3>
                    </div>
                    <div className="block-content">
                        <ul className="list list-activity push">
                            {
                                this.props.permission.groups.map((g, index) => {
                                  return (
                                        <li key={index}>
                                            <div className="font-w600">Group name:</div>
                                            <div>{g.name}</div>
                                        </li>
                                  )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </>
    )
  }
}

// Set defaultProps
PermissionDetailsCard.defaultProps = {
  permission: { groups: [] }
}

// Export Class
export { PermissionDetailsCard }
