import React, { Component } from 'react'
import { PageContainerLayout, PageHeaderLayout } from '../../../layouts'
import { ClientWizardForm } from '../../containers/client-wizard-form'
import { NavLink } from 'react-router-dom'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { ClientShape } from '../../../../prop-types'
import * as PropTypes from 'prop-types'
import { clientOperations } from '../../../../state/ducks/clients'
import { connect } from 'react-redux'

class ClientEditorPage extends Component {
  constructor (props) {
    super(props)
  }

  UNSAFE_componentWillMount () {
    this.props.getClient(this.props.match.params.client_id)
  }

  renderHeaderPaths () {
    return (
            <>
                <li>
                    <NavLink className="link-effect" to={ROUTING_PATHS.CLIENTS_PATH}>
                        Clients
                    </NavLink>
                </li>
                <li>Edit Client</li>
            </>
    )
  }

  render () {
    return (
            <>

                <PageContainerLayout>
                    <PageHeaderLayout
                        title={this.props.client.name}
                        subTitle={'Edit Client'}
                        paths={this.renderHeaderPaths}
                    />
                    <ClientWizardForm
                        client={this.props.client}
                        isEditor={true}
                    />
                </PageContainerLayout>

            </>
    )
  }
}

// Set propTypes
ClientEditorPage.propTypes = {
  client: ClientShape,
  getClientErrorResponse: PropTypes.object
}

// Set defaultProps
ClientEditorPage.defaultProps = {
  client: {},
  getClientErrorResponse: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  client: state.clients.getClientReducer.data,
  isLoading: state.clients.getClientReducer.loading,
  getClientErrorResponse: state.clients.getClientReducer.error_data
})

// mapStateToProps
const mapDispatchToProps = {
  getClient: clientOperations.get
}

// Export Class
const _ClientEditorPage = connect(mapStateToProps, mapDispatchToProps)(ClientEditorPage)
export { _ClientEditorPage as ClientEditorPage }
