import React, { Component } from 'react'
import { PageContainerLayout, PageHeaderLayout } from '../../../layouts'
import { NavLink } from 'react-router-dom'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { GroupWizardForm } from '../../../shared/containers'

class GroupCreatorPage extends Component {
  constructor (props) {
    super(props)
  }

  renderHeaderPaths () {
    return (
            <>
                <li>
                    <NavLink className="link-effect" to={ROUTING_PATHS.GROUPS_PATH}>
                        Groups
                    </NavLink>
                </li>
                <li>New Group</li>
            </>
    )
  }

  render () {
    return (
            <>

                <PageContainerLayout clientSidebar={true} showNavbarClientOptions={true}>
                    <PageHeaderLayout
                        title={'New Group'}
                        subTitle={'Create a new group'}
                        paths={this.renderHeaderPaths}
                    />
                    <GroupWizardForm/>
                </PageContainerLayout>
            </>
    )
  }
}

export { GroupCreatorPage }
