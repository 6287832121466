import React, { Component } from 'react'

class DashboardDataForm extends Component {
  constructor (props) {
    super(props)

    // state
    this.state = {}

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange (event) {
    this.props.onChange(event.target.name, event.target.value)
  }

  render () {
    return (
            <>
                <div className="form-group">
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <div className="form-material">
                            <input className="form-control"
                                   type="text"
                                   id="input-name"
                                   placeholder="Please enter a name"
                                   name="name"
                                   value={this.props.dashboard.name}
                                   onChange={this.handleChange}
                            />
                            <label htmlFor="input-name">Name</label>
                        </div>
                    </div>
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <div className="form-material">
                            <input className="form-control"
                                   type="text"
                                   id="input-vertical"
                                   placeholder="Please enter a vertical"
                                   name="vertical"
                                   value={this.props.dashboard.vertical}
                                   onChange={this.handleChange}
                            />
                            <label htmlFor="input-name">Vertical</label>
                        </div>
                    </div>
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <div className="form-material">
                            <input
                                className="form-control"
                                type="text"
                                id="input-quicksight_id"
                                name="quicksight_id"
                                value={this.props.dashboard.quicksight_id}
                                onChange={this.handleChange}
                            />
                            <label htmlFor="input-quicksight_id">Quicksight ID</label>
                        </div>
                    </div>
                </div>
            </>
    )
  }
}

// Set defaultProps
DashboardDataForm.defaultProps = {
  dashboard: {},
  onChange: () => {
  }
}

export { DashboardDataForm }
