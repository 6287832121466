import { combineReducers } from 'redux'
import * as types from './types'

const fetchSourceExecutionLogsInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { results: [], pagination_data: {} }
}

const getSourceExecutionLogInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { columns: [] }
}

const saveSourceExecutionLogInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

const updateSourceExecutionLogInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

export function fetchSourceExecutionLogsReducer (state = fetchSourceExecutionLogsInitialState, action) {
  switch (action.type) {
    case types.FETCH_SOURCE_EXECUTION_LOGS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.FETCH_SOURCE_EXECUTION_LOGS_REQUEST_FAILURE:
      return {
        ...fetchSourceExecutionLogsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.FETCH_SOURCE_EXECUTION_LOGS_REQUEST_SUCCESS:
      return {
        ...fetchSourceExecutionLogsInitialState,
        data: action.payload.data
      }
    case types.FETCH_SOURCE_EXECUTION_LOGS_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function getSourceExecutionLogReducer (state = getSourceExecutionLogInitialState, action) {
  switch (action.type) {
    case types.GET_SOURCE_EXECUTION_LOG_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.GET_SOURCE_EXECUTION_LOG_REQUEST_FAILURE:
      return {
        ...getSourceExecutionLogInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.GET_SOURCE_EXECUTION_LOG_REQUEST_SUCCESS:
      return {
        ...getSourceExecutionLogInitialState,
        data: action.payload.data
      }
    case types.GET_SOURCE_EXECUTION_LOG_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function saveSourceExecutionLogReducer (state = saveSourceExecutionLogInitialState, action) {
  switch (action.type) {
    case types.SAVE_SOURCE_EXECUTION_LOG_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.SAVE_SOURCE_EXECUTION_LOG_REQUEST_FAILURE:
      return {
        ...fetchSourceExecutionLogsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.SAVE_SOURCE_EXECUTION_LOG_REQUEST_SUCCESS:
      return {
        ...fetchSourceExecutionLogsInitialState,
        data: action.payload.data
      }
    case types.SAVE_SOURCE_EXECUTION_LOG_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function updateSourceExecutionLogReducer (state = updateSourceExecutionLogInitialState, action) {
  switch (action.type) {
    case types.UPDATE_SOURCE_EXECUTION_LOG_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.UPDATE_SOURCE_EXECUTION_LOG_REQUEST_FAILURE:
      return {
        ...updateSourceExecutionLogInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.UPDATE_SOURCE_EXECUTION_LOG_REQUEST_SUCCESS:
      return {
        ...updateSourceExecutionLogInitialState,
        data: action.payload.data
      }
    case types.UPDATE_SOURCE_EXECUTION_LOG_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export default combineReducers({
  fetchSourceExecutionLogsReducer,
  saveSourceExecutionLogReducer,
  getSourceExecutionLogReducer,
  updateSourceExecutionLogReducer
})
