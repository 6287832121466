// ACCOUNTING_SEATS_SETTINGS
export const FETCH_ACCOUNTING_ENTRIES_REQUEST = 'session/FETCH_ACCOUNTING_ENTRIES_REQUEST'
export const FETCH_ACCOUNTING_ENTRIES_REQUEST_SUCCESS = 'session/FETCH_ACCOUNTING_ENTRIES_REQUEST_SUCCESS'
export const FETCH_ACCOUNTING_ENTRIES_REQUEST_FAILURE = 'session/FETCH_ACCOUNTING_ENTRIES_REQUEST_FAILURE'
export const FETCH_ACCOUNTING_ENTRIES_REQUEST_COMPLETED = 'session/FETCH_ACCOUNTING_ENTRIES_REQUEST_COMPLETED'

export const ACCOUNTING_ENTRY_ANNULMENT_REQUEST = 'session/ACCOUNTING_ENTRY_ANNULMENT_REQUEST'
export const ACCOUNTING_ENTRY_ANNULMENT_REQUEST_SUCCESS = 'session/ACCOUNTING_ENTRY_ANNULMENT_REQUEST_SUCCESS'
export const ACCOUNTING_ENTRY_ANNULMENT_REQUEST_FAILURE = 'session/ACCOUNTING_ENTRY_ANNULMENT_REQUEST_FAILURE'
export const ACCOUNTING_ENTRY_ANNULMENT_REQUEST_COMPLETED = 'session/ACCOUNTING_ENTRY_ANNULMENT_REQUEST_COMPLETED'

export const GET_ACCOUNTING_SEAT_SETTING_REQUEST = 'session/GET_ACCOUNTING_SEAT_SETTING_REQUEST'
export const GET_ACCOUNTING_SEAT_SETTING_REQUEST_SUCCESS = 'session/GET_ACCOUNTING_SEAT_SETTING_REQUEST_SUCCESS'
export const GET_ACCOUNTING_SEAT_SETTING_REQUEST_FAILURE = 'session/GET_ACCOUNTING_SEAT_SETTING_REQUEST_FAILURE'
export const GET_ACCOUNTING_SEAT_SETTING_REQUEST_COMPLETED = 'session/GET_ACCOUNTING_SEAT_SETTING_REQUEST_COMPLETED'

export const SAVE_ACCOUNTING_SEAT_SETTING_REQUEST = 'session/SAVE_ACCOUNTING_SEAT_SETTING_REQUEST'
export const SAVE_ACCOUNTING_SEAT_SETTING_REQUEST_SUCCESS = 'session/SAVE_ACCOUNTING_SEAT_SETTING_REQUEST_SUCCESS'
export const SAVE_ACCOUNTING_SEAT_SETTING_REQUEST_FAILURE = 'session/SAVE_ACCOUNTING_SEAT_SETTING_REQUEST_FAILURE'
export const SAVE_ACCOUNTING_SEAT_SETTING_REQUEST_COMPLETED = 'session/SAVE_ACCOUNTING_SEAT_SETTING_REQUEST_COMPLETED'

export const UPDATE_ACCOUNTING_SEAT_SETTING_REQUEST = 'session/UPDATE_ACCOUNTING_SEAT_SETTING_REQUEST'
export const UPDATE_ACCOUNTING_SEAT_SETTING_REQUEST_SUCCESS = 'session/UPDATE_ACCOUNTING_SEAT_SETTING_REQUEST_SUCCESS'
export const UPDATE_ACCOUNTING_SEAT_SETTING_REQUEST_FAILURE = 'session/UPDATE_ACCOUNTING_SEAT_SETTING_REQUEST_FAILURE'
export const UPDATE_ACCOUNTING_SEAT_SETTING_REQUEST_COMPLETED = 'session/UPDATE_ACCOUNTING_SEAT_SETTING_REQUEST_COMPLETED'
