import React, { Component } from 'react'
import { connect } from 'react-redux'
import { authenticationEndpoints, authenticationOperations } from '../../../../state/ducks/authentication'
import { history } from '../../../../routing/History'
import * as PATHS from '../../../../routing/Paths'

// Class
class ResetPasswordForm extends Component {
  constructor (props) {
    super(props)

    // state
    this.state = {
      username: ''
    }

    // handlers
    this.handleChange = this.handleChange.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.goToLogin = this.goToLogin.bind(this)
  }

  handleChange (event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.handleSubmit()
    }
  }

  handleSubmit = () => {
    this.props.resetPassword(
      authenticationEndpoints.RESET_PASSWORD,
      { username: this.state.username }
    )
  }

  goToLogin () {
    history.push(PATHS.LOGIN_PATH)
  }

  renderFormContent () {
    return (
            <>
                <p>Don’t worry, we’ll send a reset link to you.</p>
                <form className="js-validation-login form-horizontal push-30-t push-50"
                      action="javascript:void(0)" method="post">
                    <div className="form-group">
                        <div className="col-xs-12">
                            <div className="form-material form-material-primary floating">
                                <input
                                    className="form-control"
                                    id="login-username"
                                    type="email"
                                    name="username"
                                    value={this.state.username}
                                    onKeyPress={this.handleKeyPress}
                                    onChange={this.handleChange}
                                />
                                <label htmlFor="login-username">Username</label>
                            </div>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="col-xs-12 col-sm-6 col-md-6">
                            <button
                                className="btn btn-block btn-primary"
                                type="button"
                                disabled={this.props.authenticationReducers.resetPasswordReducer.loading}
                                onClick={this.handleSubmit}
                            >
                                {
                                    this.props.authenticationReducers.resetPasswordReducer.loading
                                      ? <i className="fa fa-spinner fa-spin"></i>
                                      : <>
                                            <i className="ml-1 fa fa-send pull-right"></i>
                                            <span>Send Email</span>
                                        </>
                                }
                            </button>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6">
                            {
                                this.props.authenticationReducers.resetPasswordReducer.error_data.error
                                  ? <small
                                        className="mt-2">{this.props.authenticationReducers.resetPasswordReducer.error_data.error}</small>
                                  : null
                            }
                        </div>
                    </div>
                </form>
                {/* END Form */}
            </>
    )
  }

  renderCompletedRequestContent () {
    return (
            <>
                <p>
                    We have sent to your email a security link, follow that link to reset your password.
                </p>
                <div className="mb-3 w-100 text-center">
                    <i className="fa fa-check fa-3x text-success"></i>
                    <br />
                </div>
            </>
    )
  }

  render () {
    return (
            <>
                <div className=" block block-themed block-opt-refresh-icon7 animated fadeIn">
                    <div className="block-header bg-primary">
                        <ul className="block-options">
                            <li>
                                <span className="cursor-pointer" onClick={this.goToLogin}>
                                    Back to login
                                </span>
                            </li>
                        </ul>
                        <h3 className="block-title">Reset Password</h3>
                    </div>
                    <div className="block-content block-content-full block-content-narrow">
                        {/* Title */}
                        <h1 className="h2 font-w600 push-30-t push-5">Simetrik</h1>
                        {/* END Title */}
                        {
                            !this.props.authenticationReducers.resetPasswordReducer.completed
                              ? this.renderFormContent()
                              : this.renderCompletedRequestContent()
                        }
                    </div>
                </div>
            </>
    )
  };
}

// Set propTypes
ResetPasswordForm.propTypes = {}

// Set defaultProps
ResetPasswordForm.defaultProps = {}

// Set prefetch
ResetPasswordForm.prefetch = null

// mapStateToProps
const mapStateToProps = (state) => ({
  authenticationReducers: state.authentication
})

// mapStateToProps
const mapDispatchToProps = {
  resetPassword: authenticationOperations.resetPassword
}

// Export Class
const _ResetPasswordForm = connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm)
export { _ResetPasswordForm as ResetPasswordForm }
