// CONNECTIONS
export const FETCH_CONNECTIONS_REQUEST = 'session/FETCH_CONNECTIONS_REQUEST'
export const FETCH_CONNECTIONS_REQUEST_SUCCESS = 'session/FETCH_CONNECTIONS_REQUEST_SUCCESS'
export const FETCH_CONNECTIONS_REQUEST_FAILURE = 'session/FETCH_CONNECTIONS_REQUEST_FAILURE'
export const FETCH_CONNECTIONS_REQUEST_COMPLETED = 'session/FETCH_CONNECTIONS_REQUEST_COMPLETED'

export const GET_CONNECTION_REQUEST = 'session/GET_CONNECTION_REQUEST'
export const GET_CONNECTION_REQUEST_SUCCESS = 'session/GET_CONNECTION_REQUEST_SUCCESS'
export const GET_CONNECTION_REQUEST_FAILURE = 'session/GET_CONNECTION_REQUEST_FAILURE'
export const GET_CONNECTION_REQUEST_COMPLETED = 'session/GET_CONNECTION_REQUEST_COMPLETED'

export const SAVE_CONNECTION_REQUEST = 'session/SAVE_CONNECTION_REQUEST'
export const SAVE_CONNECTION_REQUEST_SUCCESS = 'session/SAVE_CONNECTION_REQUEST_SUCCESS'
export const SAVE_CONNECTION_REQUEST_FAILURE = 'session/SAVE_CONNECTION_REQUEST_FAILURE'
export const SAVE_CONNECTION_REQUEST_COMPLETED = 'session/SAVE_CONNECTION_REQUEST_COMPLETED'

export const UPDATE_CONNECTION_REQUEST = 'session/UPDATE_CONNECTION_REQUEST'
export const UPDATE_CONNECTION_REQUEST_SUCCESS = 'session/UPDATE_CONNECTION_REQUEST_SUCCESS'
export const UPDATE_CONNECTION_REQUEST_FAILURE = 'session/UPDATE_CONNECTION_REQUEST_FAILURE'
export const UPDATE_CONNECTION_REQUEST_COMPLETED = 'session/UPDATE_CONNECTION_REQUEST_COMPLETED'
