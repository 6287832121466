import React, { Component } from 'react'
import { InputSearchLayout, PaginationLayout } from '../../../layouts'
import { ClientsTable } from '../../containers'
import { clientOperations } from '../../../../state/ducks/clients'
import { connect } from 'react-redux'
import { Session } from '../../../../state/utils/session'

class ClientsLogin extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session(),
      params: {},
      idUser: ''
    }
    this.fetchClients = this.fetchClients.bind(this)
    this.onPaginationChange = this.onPaginationChange.bind(this)
    this.onSearchChange = this.onSearchChange.bind(this)
    this.updateParams = this.updateParams.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.fetchClients()
  }

  fetchClients () {
    this.props.fetchClients(this.state.params)
  }

  onPaginationChange (page) {
    this.updateParams('page', page)
  }

  onSearchChange (keywork) {
    this.updateParams('search', keywork)
  }

  updateParams (key, value) {
    const { params } = this.state
    params[key] = value
    if (key === 'search') {
      params.page = 1
    }
    this.setState({
      params
    }, () => this.fetchClients())
  }

  renderHeaderPaths () {
    return (
            <>
                <li>Clients</li>
            </>
    )
  }

  render () {
    return (
            <div className="block-content">
                {/* Search */}
                <InputSearchLayout onSearchChange={this.onSearchChange} />
                {/* End Search */}
                {/* Table */}
                <ClientsTable clients={this.props.clients} login={false} idUser={this.state.idUser}>
                    {/* Pagination */}
                    <PaginationLayout pagination={this.props.paginationData}
                        onPaginationChange={this.onPaginationChange} />
                    {/* End Pagination */}
                </ClientsTable>
                {/* End Table */}
            </div>
    )
  };
}

// mapStateToProps
const mapStateToProps = (state) => ({
  isLoading: state.clients.fetchClientsReducer.loading,
  clients: state.clients.fetchClientsReducer.data.results,
  paginationData: state.clients.fetchClientsReducer.data.pagination_data

})

// mapStateToProps
const mapDispatchToProps = {
  fetchClients: clientOperations.list
}

// Export Class
const _ClientsLogin = connect(mapStateToProps, mapDispatchToProps)(ClientsLogin)
export { _ClientsLogin as ClientsLogin }
