import React, { Component } from 'react'
import { PageContainerLayout, PageHeaderLayout } from '../../../layouts'
import { NavLink } from 'react-router-dom'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { JobsPoolsShape } from '../../../../prop-types'
import * as PropTypes from 'prop-types'
import { jobPoolsOperations } from '../../../../state/ducks/jobs-pools'
import { connect } from 'react-redux'
import { Session } from '../../../../state/utils/session'
import { JobsPoolDetailsCard, JobsPoolsTimelineCard } from '../../containers'

class JobsPoolsDetailsPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }

    this.renderHeaderPaths = this.renderHeaderPaths.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.props.getJobsPool(this.props.match.params.job_pool_id)
  }

  renderHeaderPaths () {
    return (
            <>
                <li>
                    <NavLink className="link-effect"
                             to={ROUTING_PATHS.CLIENT_DASHBOARD_JOB_POOLS_PATH.replace(':client_id', this.state.session.getClientSession())}
                    >
                        Jobs Pools
                    </NavLink>
                </li>
                <li>Job Pool Details</li>
            </>
    )
  }

  render () {
    return (
            <>
                <PageContainerLayout clientSidebar={true} showNavbarClientOptions={true}>
                    <PageHeaderLayout
                        title={this.props.jobPool.name}
                        subTitle={'Job Pool Details'}
                        paths={this.renderHeaderPaths}
                    />
                    <div className="content content-boxed">
                        <div className="row">
                            <div className="col-sm-12 col-lg-5">
                                {/* Details */}
                                <JobsPoolDetailsCard jobPool={this.props.jobPool}/>
                                {/* END Details */}
                            </div>
                            <div className="col-sm-12 col-lg-7">
                                {/* Timeline */}
                                <JobsPoolsTimelineCard jobPool={this.props.jobPool}/>
                                {/* END Timeline */}
                            </div>
                        </div>
                    </div>
                </PageContainerLayout>
            </>
    )
  }
}

// Set propTypes
JobsPoolsDetailsPage.propTypes = {
  jobPool: JobsPoolsShape,
  getJobsPoolErrorResponse: PropTypes.object
}

// Set defaultProps
JobsPoolsDetailsPage.defaultProps = {
  jobPool: {},
  getJobsPoolErrorResponse: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  jobPool: state.jobsPools.getJobPoolReducer.data,
  isLoading: state.jobsPools.getJobPoolReducer.loading,
  getJobsPoolErrorResponse: state.jobsPools.getJobPoolReducer.error_data
})

// mapStateToProps
const mapDispatchToProps = {
  getJobsPool: jobPoolsOperations.get
}

// Export Class
const _JobsPoolsDetailsPage = connect(mapStateToProps, mapDispatchToProps)(JobsPoolsDetailsPage)
export { _JobsPoolsDetailsPage as JobsPoolsDetailsPage }
