import React, { Component } from 'react'
import { history } from '../../../../routing/History'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { Session } from '../../../../state/utils/session'

class AlarmQueryCard extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }
    this.goToAlarmEditor = this.goToAlarmEditor.bind(this)
  }

  goToAlarmEditor () {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_ALARM_EDITOR_PATH
        .replace(':alarm_id', this.props.alarm.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  render () {
    return (
            <>
                <div className="block">
                    <div className="block-header bg-gray-lighter">
                        <ul className="block-options">
                            <li>
                                <button type="button" onClick={this.goToAlarmEditor}>
                                    <i className="fa fa-edit"></i>
                                </button>
                            </li>
                        </ul>
                        <h3 className="block-title">
                            <i className="fa fa-info-circle"></i> Query Data
                        </h3>
                    </div>
                    <div className="block-content">
                        <ul className="list list-activity push">
                            <li>
                                <div className="font-w600">Delay:</div>
                                <div>{this.props.alarm.delay}</div>
                            </li>
                            <li>
                                <div className="font-w600">Query:</div>
                                <div>{this.props.alarm.query}</div>
                            </li>
                            <li>
                                <div className="font-w600">Query Message:</div>
                                <div>{this.props.alarm.query_message}</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </>
    )
  }
}

// Set defaultProps
AlarmQueryCard.defaultProps = {
  alarm: {}
}

// Export Class
export { AlarmQueryCard }
