// LOGIN
export const LOGIN_REQUEST = 'session/LOGIN_REQUEST'
export const LOGIN_REQUEST_SUCCESS = 'session/LOGIN_REQUEST_SUCCESS'
export const LOGIN_REQUEST_FAILURE = 'session/LOGIN_REQUEST_FAILURE'
export const LOGIN_ACTION_REQUIRED = 'session/LOGIN_ACTION_REQUIRED'
export const LOGIN_REQUEST_COMPLETED = 'session/LOGIN_REQUEST_COMPLETED'

// LOGOUT
export const LOGOUT_REQUEST = 'session/LOGOUT_REQUEST'
export const LOGOUT_REQUEST_SUCCESS = 'session/LOGOUT_REQUEST_SUCCESS'
export const LOGOUT_REQUEST_FAILURE = 'session/LOGOUT_REQUEST_FAILURE'
export const LOGOUT_ACTION_REQUIRED = 'session/LOGOUT_ACTION_REQUIRED'
export const LOGOUT_REQUEST_COMPLETED = 'session/LOGOUT_REQUEST_COMPLETED'

// CHANGE_PASSWORD
export const CHANGE_PASSWORD_REQUEST = 'session/CHANGE_PASSWORD_REQUEST'
export const CHANGE_PASSWORD_REQUEST_SUCCESS = 'session/CHANGE_PASSWORD_REQUEST_SUCCESS'
export const CHANGE_PASSWORD_REQUEST_FAILURE = 'session/CHANGE_PASSWORD_REQUEST_FAILURE'
export const CHANGE_PASSWORD_REQUEST_COMPLETED = 'session/CHANGE_PASSWORD_REQUEST_COMPLETED'

// VERIFY_SECURITY_CODE
export const VERIFY_SECURITY_CODE_REQUEST = 'session/VERIFY_SECURITY_CODE_REQUEST'
export const VERIFY_SECURITY_CODE_REQUEST_SUCCESS = 'session/VERIFY_SECURITY_CODE_REQUEST_SUCCESS'
export const VERIFY_SECURITY_CODE_REQUEST_FAILURE = 'session/VERIFY_SECURITY_CODE_REQUEST_FAILURE'
export const VERIFY_SECURITY_CODE_REQUEST_COMPLETED = 'session/VERIFY_SECURITY_CODE_REQUEST_COMPLETED'

// RESEND_SECURITY_CODE
export const RESEND_SECURITY_CODE_REQUEST = 'session/RESEND_SECURITY_CODE_REQUEST'
export const RESEND_SECURITY_CODE_REQUEST_SUCCESS = 'session/RESEND_SECURITY_CODE_REQUEST_SUCCESS'
export const RESEND_SECURITY_CODE_REQUEST_FAILURE = 'session/RESEND_SECURITY_CODE_REQUEST_FAILURE'
export const RESEND_SECURITY_CODE_REQUEST_COMPLETED = 'session/RESEND_SECURITY_CODE_REQUEST_COMPLETED'

// RESET_PASSWORD
export const RESET_PASSWORD_REQUEST = 'session/RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_REQUEST_SUCCESS = 'session/RESET_PASSWORD_REQUEST_SUCCESS'
export const RESET_PASSWORD_REQUEST_FAILURE = 'session/RESET_PASSWORD_REQUEST_FAILURE'
export const RESET_PASSWORD_REQUEST_COMPLETED = 'session/RESET_PASSWORD_REQUEST_COMPLETED'
