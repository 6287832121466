import React, { Component } from 'react'
import { history } from '../../../../routing/History'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { Session } from '../../../../state/utils/session'

class DashboardsHeaderTiles extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }
    this.goToDashboardCreator = this.goToDashboardCreator.bind(this)
  }

  goToDashboardCreator () {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_DASHBOARD_CREATOR_PATH
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  render () {
    return (
            <>
                <div className="row">
                    <div className="col-sm-6 col-md-3">
                        <a className="block block-link-hover3 text-center"
                           href="javascript:void(0)"
                           onClick={this.goToDashboardCreator}
                        >
                            <div className="block-content block-content-full">
                                <div className="h1 font-w700 text-success"><i className="fa fa-plus"></i></div>
                            </div>
                            <div className="block-content block-content-full block-content-mini bg-gray-lighter text-success font-w600">
                                Add New Dashboard
                            </div>
                        </a>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <a className="block block-link-hover3 text-center" href="javascript:void(0)">
                            <div className="block-content block-content-full">
                                <div className="h1 font-w700">{this.props.all_dashboards}</div>
                            </div>
                            <div className="block-content block-content-full block-content-mini bg-gray-lighter text-muted font-w600">
                                All dashboards
                            </div>
                        </a>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <a className="block block-link-hover3 text-center" href="javascript:void(0)">
                            <div className="block-content block-content-full">
                                <div className="h1 font-w700">&nbsp;</div>
                            </div>
                            <div className="block-content block-content-full block-content-mini bg-gray-lighter text-muted font-w600">
                                &nbsp;
                            </div>
                        </a>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <a className="block block-link-hover3 text-center" href="javascript:void(0)">
                            <div className="block-content block-content-full">
                                <div className="h1 font-w700">&nbsp;</div>
                            </div>
                            <div className="block-content block-content-full block-content-mini bg-gray-lighter text-muted font-w600">
                                &nbsp;
                            </div>
                        </a>
                    </div>
                </div>
            </>
    )
  }
}

// Set defaultProps
DashboardsHeaderTiles.defaultProps = {
  all_dashboards: 0
}

// Export Class
export { DashboardsHeaderTiles }
