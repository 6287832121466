import reducer from './reducers'

import * as releaseOperations from './operations'
import * as releaseEndpoints from './paths'

export {
  releaseOperations,
  releaseEndpoints
}

export default reducer
