import { ClientCreatorPage } from './client-creator'
import { ClientEditorPage } from './client-editor'
import { ClientsPage } from './clients'
import { GroupCreatorPage } from './group-creator'
import { GroupDetailsPage } from './group-details'
import { GroupEditorPage } from './group-editor'
import { GroupsPage } from './groups'
import { HomePage } from './home'
import { PermissionCreatorPage } from './permission-creator'
import { PermissionDetailsPage } from './permission-details'
import { PermissionEditorPage } from './permission-editor'
import { PermissionsPage } from './permissions'
import { UserCreatorPage } from './user-creator'
import { UserDetailsPage } from './user-details'
import { UserEditorPage } from './user-editor'
import { UsersPage } from './users'
import { AccountingPage } from './accounting'
import { UserManagerPage } from './security/users'
import { ClientsLogin } from './client-login'

const AdminDashboardPages = {
  ClientCreatorPage,
  ClientEditorPage,
  ClientsPage,
  GroupCreatorPage,
  GroupDetailsPage,
  GroupEditorPage,
  GroupsPage,
  HomePage,
  PermissionCreatorPage,
  PermissionDetailsPage,
  PermissionEditorPage,
  PermissionsPage,
  UserCreatorPage,
  UserDetailsPage,
  UserEditorPage,
  UsersPage,
  AccountingPage,
  UserManagerPage,
  ClientsLogin
}

export { AdminDashboardPages as ADMIN_DASHBOARD_PAGES }
