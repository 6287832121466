// JOB_POOLS
export const FETCH_JOB_POOLS_REQUEST = 'session/FETCH_JOB_POOLS_REQUEST'
export const FETCH_JOB_POOLS_REQUEST_SUCCESS = 'session/FETCH_JOB_POOLS_REQUEST_SUCCESS'
export const FETCH_JOB_POOLS_REQUEST_FAILURE = 'session/FETCH_JOB_POOLS_REQUEST_FAILURE'
export const FETCH_JOB_POOLS_REQUEST_COMPLETED = 'session/FETCH_JOB_POOLS_REQUEST_COMPLETED'

export const GET_JOB_POOL_REQUEST = 'session/GET_JOB_POOL_REQUEST'
export const GET_JOB_POOL_REQUEST_SUCCESS = 'session/GET_JOB_POOL_REQUEST_SUCCESS'
export const GET_JOB_POOL_REQUEST_FAILURE = 'session/GET_JOB_POOL_REQUEST_FAILURE'
export const GET_JOB_POOL_REQUEST_COMPLETED = 'session/GET_JOB_POOL_REQUEST_COMPLETED'

export const SAVE_JOB_POOL_REQUEST = 'session/SAVE_JOB_POOL_REQUEST'
export const SAVE_JOB_POOL_REQUEST_SUCCESS = 'session/SAVE_JOB_POOL_REQUEST_SUCCESS'
export const SAVE_JOB_POOL_REQUEST_FAILURE = 'session/SAVE_JOB_POOL_REQUEST_FAILURE'
export const SAVE_JOB_POOL_REQUEST_COMPLETED = 'session/SAVE_JOB_POOL_REQUEST_COMPLETED'

export const DELETE_JOB_POOL_REQUEST = 'session/DELETE_JOB_POOL_REQUEST'
export const DELETE_JOB_POOL_REQUEST_SUCCESS = 'session/DELETE_JOB_POOL_REQUEST_SUCCESS'
export const DELETE_JOB_POOL_REQUEST_FAILURE = 'session/DELETE_JOB_POOL_REQUEST_FAILURE'
export const DELETE_JOB_POOL_REQUEST_COMPLETED = 'session/DELETE_JOB_POOL_REQUEST_COMPLETED'
