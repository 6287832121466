export const FETCH_DYNAMIC_PARAMETRIES_REQUEST =
  'session/FETCH_DYNAMIC_PARAMETRIES_REQUEST'
export const FETCH_DYNAMIC_PARAMETRIES_REQUEST_SUCCESS =
  'session/FETCH_DYNAMIC_PARAMETRIES_REQUEST_SUCCESS'
export const FETCH_DYNAMIC_PARAMETRIES_REQUEST_FAILURE =
  'session/FETCH_DYNAMIC_PARAMETRIES_REQUEST_FAILURE'

export const FETCH_INCONSISTENCIES_REQUEST =
  'session/FETCH_INCONSISTENCIES_REQUEST'
export const FETCH_INCONSISTENCIES_REQUEST_SUCCESS =
  'session/FETCH_INCONSISTENCIES_REQUEST_SUCCESS'
export const FETCH_INCONSISTENCIES_REQUEST_FAILURE =
  'session/FETCH_INCONSISTENCIES_REQUEST_FAILURE'
