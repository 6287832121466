import reducer from './reducers'

import * as dashboardOperations from './operations'
import * as dashboardEndpoints from './paths'

export {
  dashboardOperations,
  dashboardEndpoints
}

export default reducer
