import React, { Component } from 'react'
import { EmptyTableLayout, GroupOptionsControllerLayout } from '../../../layouts'
import { history } from '../../../../routing/History'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { Session } from '../../../../state/utils/session'
import { clientOperations } from '../../../../state/ducks/clients'
import { connect } from 'react-redux'

class ClientsTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session(),
      idUser: ''
    }

    this.goToClientEditor = this.goToClientEditor.bind(this)
    this.selectClient = this.selectClient.bind(this)
    this.deleteClient = this.deleteClient.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.setState({ idUser: this.state.session.session.user_id })
  }

  goToClientEditor (client) {
    history.push(ROUTING_PATHS.CLIENT_EDITOR_PATH.replace(':client_id', client.id))
  }

  selectClient (client) {
    this.state.session.setClientSession(client)
    this.permissionOfUsers(client.id, this.state.idUser)
    history.push(ROUTING_PATHS.CLIENT_DASHBOARD_PATH.replace(':client_id', client.id))
  }

  permissionOfUsers (idclient, idUser) {
    return this.props.permissionUsers(idclient, idUser)
  }

  deleteClient (client) {

  }

  render () {
    return (
            <>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered table-condensed text-center">
                        <thead>
                            <tr>
                                <th className="text-center">ID</th>
                                <th className="text-center">Name</th>
                                <th className="text-center">Region</th>
                                <th className="text-center">Emr Suffix</th>
                                <th className="text-center">Options</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props.clients.map((client) => {
                                  return (
                                        <tr key={client.id}>
                                            <td className="text-center">{client.id}</td>
                                            <td className="text-center">{client.name}</td>
                                            <td className="text-center">
                                                <span className="label label-info">{client.emr_region}</span>
                                            </td>
                                            <td className="text-center">{client.emr_suffix}</td>
                                            <td className="text-center">
                                                <div className="btn-group">
                                                    <GroupOptionsControllerLayout validFor={['ADMIN', 'DEVELOPER']}>
                                                        {this.props.login && (
                                                            <button className="btn btn-xs btn-default"
                                                                type="button"
                                                                data-toggle="tooltip"
                                                                title="Edit Client"
                                                                onClick={this.goToClientEditor.bind(this, client)}
                                                            >
                                                                <i className="fa fa-pen"></i>
                                                            </button>
                                                        )}
                                                    </GroupOptionsControllerLayout>
                                                    <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Select Client"
                                                        onClick={this.selectClient.bind(this, client)}
                                                    >
                                                        <i className="fa fa-eye"></i>
                                                    </button>
                                                    <GroupOptionsControllerLayout validFor={['ADMIN']}>
                                                        {this.props.login && (
                                                            <button className="btn btn-xs btn-default"
                                                                type="button"
                                                                data-toggle="tooltip"
                                                                title="Remove Client"
                                                                onClick={this.deleteClient.bind(this, client)}
                                                            >
                                                                <i className="fa fa-trash"></i>
                                                            </button>
                                                        )}
                                                    </GroupOptionsControllerLayout>
                                                </div>
                                            </td>
                                        </tr>
                                  )
                                })
                            }
                            <EmptyTableLayout results={this.props.clients} columns={5} />
                        </tbody>
                    </table>
                    {this.props.children}
                </div>
            </>
    )
  }
}

// mapStateToProps
const mapStateToProps = (state) => ({
  permission: state.clients
})

// mapStateToProps
const mapDispatchToProps = {
  permissionUsers: clientOperations.getPermissions
}

// Set defaultProps
ClientsTable.defaultProps = {
  clients: [],
  login: true
}

// Export Class
const _ClientsTable = connect(mapStateToProps, mapDispatchToProps)(ClientsTable)
export { _ClientsTable as ClientsTable }
