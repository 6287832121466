import React, { Component } from 'react'
import { PageContainerLayout, PageHeaderLayout } from '../../../layouts'
import { NavLink } from 'react-router-dom'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { AccountingSeatSettingShape } from '../../../../prop-types'
import * as PropTypes from 'prop-types'
import { accountingSeatSettingsOperations } from '../../../../state/ducks/accounting-seat-settings'
import { connect } from 'react-redux'
import { Session } from '../../../../state/utils/session'
import { AccountingSeatSettingDetailsCard, AccountingSeatSettingTimelineCard } from '../../containers'

class AccountingSeatSettingDetailsPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }

    this.renderHeaderPaths = this.renderHeaderPaths.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.props.getAccountingSeatSetting(this.props.match.params.accounting_seat_setting_id)
  }

  renderHeaderPaths () {
    return (
            <>
                <li>
                    <NavLink className="link-effect"
                             to={ROUTING_PATHS.CLIENT_DASHBOARD_ACCOUNTING_SEAT_SETTINGS_PATH.replace(':client_id', this.state.session.getClientSession())}
                    >
                        Accounting Seat Settings
                    </NavLink>
                </li>
                <li>Setting Details</li>
            </>
    )
  }

  render () {
    return (
            <>
                <PageContainerLayout clientSidebar={true} showNavbarClientOptions={true}>
                    <PageHeaderLayout
                        title={this.props.accountingSeatSetting.name}
                        subTitle={'Accounting Seat Setting Details'}
                        paths={this.renderHeaderPaths}
                    />
                    <div className="content content-boxed">
                        <div className="row">
                            <div className="col-sm-12 col-lg-5">
                                {/* Details */}
                                <AccountingSeatSettingDetailsCard accountingSeatSetting={this.props.accountingSeatSetting}/>
                                {/* END Details */}
                            </div>
                            <div className="col-sm-12 col-lg-7">
                                {/* Timeline */}
                                <AccountingSeatSettingTimelineCard accountingSeatSetting={this.props.accountingSeatSetting}/>
                                {/* END Timeline */}
                            </div>
                        </div>
                    </div>
                </PageContainerLayout>
            </>
    )
  }
}

// Set propTypes
AccountingSeatSettingDetailsPage.propTypes = {
  accountingSeatSetting: AccountingSeatSettingShape,
  getAccountingSeatSettingErrorResponse: PropTypes.object
}

// Set defaultProps
AccountingSeatSettingDetailsPage.defaultProps = {
  accountingSeatSetting: {},
  getAccountingSeatSettingErrorResponse: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  accountingSeatSetting: state.accountingSeatSettings.getAccountingSeatSettingReducer.data,
  isLoading: state.accountingSeatSettings.getAccountingSeatSettingReducer.loading,
  getAccountingSeatSettingErrorResponse: state.accountingSeatSettings.getAccountingSeatSettingReducer.error_data
})

// mapStateToProps
const mapDispatchToProps = {
  getAccountingSeatSetting: accountingSeatSettingsOperations.get
}

// Export Class
const _AccountingSeatSettingDetailsPage = connect(mapStateToProps, mapDispatchToProps)(AccountingSeatSettingDetailsPage)
export { _AccountingSeatSettingDetailsPage as AccountingSeatSettingDetailsPage }
