import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { AuthenticationPageLayout } from '../../../layouts'
import { TokenValidatorMessage } from '../../containers'

class TokenValidatorPage extends Component {
  componentDidMount () {
    // const session = new Session();
    // session.checkSession();
  }

  render () {
    return (
            <>
                <AuthenticationPageLayout>
                    <TokenValidatorMessage match={this.props.match} />
                </AuthenticationPageLayout>
            </>
    )
  };
}

const _TokenValidatorPage = withRouter(TokenValidatorPage)

export { _TokenValidatorPage as TokenValidatorPage }
