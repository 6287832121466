import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { conciliationOperations } from '../../../../state/ducks/conciliations'
import { PaginationSizeLayout, TableGeneratedLayout } from '../../../layouts'
import { PaginationLayout } from '../../../layouts/pagination'

class ConciliationMissingsATableGenerated extends Component {
  constructor (props) {
    super(props)

    // state
    this.state = {
      fullPage: false,
      queryParams: {}
    }

    this.getMissingsATableGenerated = this.getMissingsATableGenerated.bind(this)
    this.fullPage = this.fullPage.bind(this)
    this.getMissingsATableGenerated = this.getMissingsATableGenerated.bind(this)
    this.onPaginationChange = this.onPaginationChange.bind(this)
    this.onPaginationSizeChange = this.onPaginationSizeChange.bind(this)
    this.onSortBy = this.onSortBy.bind(this)
  }

  componentDidMount () {
    if (!this.state.queryParams.connection_id) {
      this.setState({
        queryParams: {
          connection_id: this.props.conciliation.destination_connection,
          table_name: this.props.conciliation.parameters.missing_a === true
            ? 'missings__' + this.props.conciliation.name + '__' + this.props.conciliation.parameters.a_prefix
            : this.props.conciliation.parameters.missing_a
        }
      }, () => {
        if (!this.props.isLoading) {
          this.props.getMissingsATableGenerated(this.state.queryParams)
        }
      })
    }
  }

  getMissingsATableGenerated () {
    this.props.getMissingsATableGenerated(this.state.queryParams)
  }

  fullPage (fullPage) {
    const currentValue = this.state.fullPage
    if (currentValue) {
      fullPage = null
    }
    this.setState({ fullPage })
  }

  updateQueryParams (key, value) {
    const { queryParams } = this.state
    queryParams[key] = value
    if (key === 'search') {
      queryParams.page = 1
    }
    this.setState({ queryParams }, () => this.getMissingsATableGenerated())
  }

  onPaginationSizeChange (size) {
    this.updateQueryParams('pagination_size', size)
  }

  onSortBy (att, sortBy) {
    this.updateQueryParams('ordering', sortBy === -1 ? '-' + att : att)
  }

  onPaginationChange (page) {
    this.updateQueryParams('page', page)
  }

  render () {
    return (
            <>
                <div className={
                    ' block block-themed block-opt-refresh-icon7 ' +
                    (this.props.isLoading ? ' block-opt-refresh ' : '') +
                    (this.state.fullPage ? ' block-opt-fullscreen ' : '')
                }>
                    <div className="block-header bg-primary-dark">
                        <ul className="block-options">
                            <li>
                                <button type="button"
                                        onClick={this.fullPage.bind('loaded-table')}
                                >
                                    <i className="si si-size-fullscreen"/>
                                </button>
                            </li>
                            <li>
                                <button type="button">
                                    <i className="fa fa-download"/>
                                </button>
                            </li>
                            <li>
                                <button type="button"
                                        data-toggle="block-option"
                                        onClick={this.getMissingsATableGenerated}
                                >
                                    <i className="si si-refresh"></i>
                                </button>
                            </li>
                        </ul>
                        <h3 className="block-title">{this.state.table_name}</h3>
                    </div>
                    <div className="block-content">
                        {/* Pagination Size */}
                        <PaginationSizeLayout
                            onPaginationSizeChange={this.onPaginationSizeChange}/>
                        {/* End Pagination Size */}
                        <div className="table-scrolling">
                            {/* Table */}
                            <TableGeneratedLayout
                                rows={this.props.missingsATableGenerated}
                                onSortBy={this.onSortBy}
                            />
                            {/* End Table */}
                        </div>
                        {/* Pagination */}
                        <PaginationLayout
                            pagination={this.props.paginationData}
                            onPaginationChange={this.onPaginationChange}
                        />
                        {/* End Pagination */}
                    </div>
                </div>
            </>
    )
  }
}

// Set propTypes
ConciliationMissingsATableGenerated.propTypes = {
  conciliation: PropTypes.object.isRequired,
  missingsATableGenerated: PropTypes.array,
  isLoading: PropTypes.bool,
  paginationData: PropTypes.object
}

// Set defaultProps
ConciliationMissingsATableGenerated.defaultProps = {
  conciliation: {},
  missingsATableGenerated: [],
  isLoading: true,
  paginationData: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  missingsATableGenerated: state.conciliations.missingsATableGeneratedReducer.data.results,
  isLoading: state.conciliations.missingsATableGeneratedReducer.loading,
  paginationData: state.conciliations.missingsATableGeneratedReducer.data.pagination_data
})

// mapStateToProps
const mapDispatchToProps = {
  getMissingsATableGenerated: conciliationOperations.getMissingsATableGenerated
}

// Export Class
const _ConciliationMissingsATableGenerated = connect(mapStateToProps,
  mapDispatchToProps)(ConciliationMissingsATableGenerated)
export { _ConciliationMissingsATableGenerated as ConciliationMissingsATableGenerated }
