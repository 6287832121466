// EXECUTIONS
export const FETCH_EXECUTIONS_REQUEST = 'session/FETCH_EXECUTIONS_REQUEST'
export const FETCH_EXECUTIONS_REQUEST_SUCCESS = 'session/FETCH_EXECUTIONS_REQUEST_SUCCESS'
export const FETCH_EXECUTIONS_REQUEST_FAILURE = 'session/FETCH_EXECUTIONS_REQUEST_FAILURE'
export const FETCH_EXECUTIONS_REQUEST_COMPLETED = 'session/FETCH_EXECUTIONS_REQUEST_COMPLETED'

export const GET_EXECUTION_REQUEST = 'session/GET_EXECUTION_REQUEST'
export const GET_EXECUTION_REQUEST_SUCCESS = 'session/GET_EXECUTION_REQUEST_SUCCESS'
export const GET_EXECUTION_REQUEST_FAILURE = 'session/GET_EXECUTION_REQUEST_FAILURE'
export const GET_EXECUTION_REQUEST_COMPLETED = 'session/GET_EXECUTION_REQUEST_COMPLETED'

export const SAVE_EXECUTION_REQUEST = 'session/SAVE_EXECUTION_REQUEST'
export const SAVE_EXECUTION_REQUEST_SUCCESS = 'session/SAVE_EXECUTION_REQUEST_SUCCESS'
export const SAVE_EXECUTION_REQUEST_FAILURE = 'session/SAVE_EXECUTION_REQUEST_FAILURE'
export const SAVE_EXECUTION_REQUEST_COMPLETED = 'session/SAVE_EXECUTION_REQUEST_COMPLETED'

export const UPDATE_EXECUTION_REQUEST = 'session/UPDATE_EXECUTION_REQUEST'
export const UPDATE_EXECUTION_REQUEST_SUCCESS = 'session/UPDATE_EXECUTION_REQUEST_SUCCESS'
export const UPDATE_EXECUTION_REQUEST_FAILURE = 'session/UPDATE_EXECUTION_REQUEST_FAILURE'
export const UPDATE_EXECUTION_REQUEST_COMPLETED = 'session/UPDATE_EXECUTION_REQUEST_COMPLETED'
