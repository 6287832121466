import React, { Component } from 'react'
import { EmptyTableLayout } from '../../../layouts'
import { history } from '../../../../routing/History'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { Session } from '../../../../state/utils/session'

class ConnectionsTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }

    this.goToConnectionEditor = this.goToConnectionEditor.bind(this)
    this.selectConnection = this.selectConnection.bind(this)
    this.deleteConnection = this.deleteConnection.bind(this)
  }

  goToConnectionEditor (connection) {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_CONNECTION_EDITOR_PATH
        .replace(':connection_id', connection.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  selectConnection (connection) {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_CONNECTION_DETAILS_PATH
        .replace(':connection_id', connection.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  deleteConnection (connection) {

  }

  returnConnectionTypeLabel (connection) {
    switch (connection.origin) {
      case 1:
        return (
                    <>
                        <span className="label label-primary">{connection.origin_type}</span>
                    </>
        )
      case 2:
        return (
                    <>
                        <span className="label label-success">{connection.origin_type}</span>
                    </>
        )
      case 3:
        return (
                    <>
                        <span className="label label-info">{connection.origin_type}</span>
                    </>
        )
      case 4:
        return (
                    <>
                        <span className="label label-warning">{connection.origin_type}</span>
                    </>
        )
      default:
        break
    }
  }

  render () {
    return (
            <>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered table-condensed text-center">
                        <thead>
                        <tr>
                            <th className="text-center">ID</th>
                            <th className="text-center">Name</th>
                            <th className="text-center">Origin</th>
                            <th className="text-center">Options</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.connections.map((connection) => {
                              return (
                                    <tr key={connection.id}>
                                        <td className="text-center">{connection.id}</td>
                                        <td className="text-center">{connection.name}</td>
                                        <td className="text-center">
                                            {this.returnConnectionTypeLabel(connection)}
                                        </td>
                                        <td className="text-center">
                                            <div className="btn-group">
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Edit Connection"
                                                        onClick={this.goToConnectionEditor.bind(this, connection)}
                                                >
                                                    <i className="fa fa-pen"></i>
                                                </button>
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Select Connection"
                                                        onClick={this.selectConnection.bind(this, connection)}
                                                >
                                                    <i className="fa fa-eye"></i>
                                                </button>
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Remove Connection"
                                                        onClick={this.deleteConnection.bind(this, connection)}
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                              )
                            })
                        }
                        <EmptyTableLayout results={this.props.connections} columns={4}/>
                        </tbody>
                    </table>
                    {this.props.children}
                </div>
            </>
    )
  }
}

// Set defaultProps
ConnectionsTable.defaultProps = {
  connections: []
}

// Export Class
export { ConnectionsTable }
