import React, { Component } from 'react'
import { PageContainerLayout, PageHeaderLayout } from '../../../layouts'
import { ConnectionWizardForm } from '../../containers/connection-wizard-form'
import { NavLink } from 'react-router-dom'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { ConnectionShape } from '../../../../prop-types'
import * as PropTypes from 'prop-types'
import { connectionOperations } from '../../../../state/ducks/connections'
import { connect } from 'react-redux'
import { Session } from '../../../../state/utils/session'

class ConnectionEditorPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }

    this.renderHeaderPaths = this.renderHeaderPaths.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.props.getConnection(this.props.match.params.connection_id)
  }

  renderHeaderPaths () {
    return (
            <>
                <li>
                    <NavLink className="link-effect"
                             to={ROUTING_PATHS.CLIENT_DASHBOARD_CONNECTIONS_PATH.replace(':client_id', this.state.session.getClientSession())}
                    >
                        Connections
                    </NavLink>
                </li>
                <li>Edit Connection</li>
            </>
    )
  }

  render () {
    return (
            <>
                <PageContainerLayout clientSidebar={true} showNavbarClientOptions={true}>
                    <PageHeaderLayout
                        title={this.props.connection.name}
                        subTitle={'Edit Connection'}
                        paths={this.renderHeaderPaths}
                    />
                    <ConnectionWizardForm
                        connection={this.props.connection}
                        isEditor={true}
                    />
                </PageContainerLayout>
            </>
    )
  }
}

// Set propTypes
ConnectionEditorPage.propTypes = {
  connection: ConnectionShape,
  getConnectionErrorResponse: PropTypes.object
}

// Set defaultProps
ConnectionEditorPage.defaultProps = {
  connection: {},
  getConnectionErrorResponse: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  connection: state.connections.getConnectionReducer.data,
  isLoading: state.connections.getConnectionReducer.loading,
  getConnectionErrorResponse: state.connections.getConnectionReducer.error_data
})

// mapStateToProps
const mapDispatchToProps = {
  getConnection: connectionOperations.get
}

// Export Class
const _ConnectionEditorPage = connect(mapStateToProps, mapDispatchToProps)(ConnectionEditorPage)
export { _ConnectionEditorPage as ConnectionEditorPage }
