import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { ClientShape } from '../../../../prop-types'
import { clientOperations } from '../../../../state/ducks/clients'
import { connect } from 'react-redux'
import { BaseFormWizard } from '../../../../assets/js/base_form_wizard'
import { ClientDataForm } from '../client-data-form'
import { ClientUsersForm } from '../client-users-form'
import { AppUI } from '../../../../assets/js/app'
import * as ROUTING_PATHS from '../../../../routing/Paths'

class ClientWizardForm extends Component {
  constructor (props) {
    super(props)

    // state
    this.state = {
      client: {
        users: []
      }
    }

    // handlers
    this.onClientDataChange = this.onClientDataChange.bind(this)
    this.onClientUsersChange = this.onClientUsersChange.bind(this)
    this.sendData = this.sendData.bind(this)
    AppUI.initHelpers(['highlightjs'])
  }

  componentDidMount () {
    BaseFormWizard.init()
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.client && 'id' in nextProps.client) {
      this.setState({ client: nextProps.client })
    }
  }

  onClientDataChange (name, value) {
    const client = this.state.client
    client[name] = value
    this.setState({
      client
    })
  }

  onClientUsersChange (users) {
    const client = this.state.client
    client.users = JSON.parse(JSON.stringify(users))
    this.setState({
      client: JSON.parse(JSON.stringify(client))
    })
  }

  sendData () {
    if (!this.props.isEditor) {
      this.props.saveClient(this.state.client)
    } else {
      this.props.updateClient(this.state.client.id, this.state.client, ROUTING_PATHS.CLIENTS_PATH)
    }
  }

  render () {
    return (
            <>
                <div className="content content-boxed">
                    <div className={' block block-themed block-opt-refresh-icon7 ' + (this.props.isLoading ? ' block-opt-refresh ' : '')}>
                        <div className="block-content">
                            {/* Simple Progress Wizard (.js-wizard-simple class is initialized in js/pages/base_forms_wizard.js) */}
                            {/* For more examples you can check out http://vadimg.com/twitter-bootstrap-wizard-example/ */}
                            <div className="js-wizard-simple block">
                                {/* Steps Progress */}
                                <div className="block-content block-content-mini block-content-full border-b">
                                    <div className="wizard-progress progress active remove-margin-b">
                                        <div className="progress-bar progress-bar-primary progress-bar-striped"
                                             role="progressbar" aria-valuenow="100" aria-valuemin="0"
                                             aria-valuemax="100"
                                             style={{ width: '0' }}>
                                        </div>
                                    </div>
                                </div>
                                {/* END Steps Progress */}

                                {/* Step Tabs */}
                                <ul className="nav nav-tabs nav-tabs-alt nav-justified">
                                    <li className="active">
                                        <a href="#simple-progress-step1" data-toggle="tab">1. Client Data</a>
                                    </li>
                                    <li>
                                        <a href="#simple-progress-step2" data-toggle="tab">2. Users</a>
                                    </li>
                                </ul>
                                {/* END Step Tabs */}

                                {/* Form */}
                                <form className="form-horizontal" action="javascript:void(0)">
                                    {/* Steps Content */}
                                    <div className="block-content tab-content" style={{ minHeight: '300px' }}>
                                        {/* Step 1 */}
                                        <div className="tab-pane fade fade-right in push-30-t push-50 active"
                                             id="simple-progress-step1">
                                            <ClientDataForm
                                                client={this.state.client}
                                                onChange={this.onClientDataChange}
                                            />
                                        </div>
                                        {/* END Step 1 */}

                                        {/* Step 2 */}
                                        <div className="tab-pane fade fade-right in push-30-t push-50"
                                             id="simple-progress-step2">
                                            <ClientUsersForm
                                                added_users={this.state.client.users}
                                                onChange={this.onClientUsersChange}
                                            />
                                            {/* END Step 2 */}
                                        </div>
                                    </div>
                                    {/* END Steps Content */}

                                    {/* Steps Navigation */}
                                    <div className="block-content block-content-mini block-content-full border-t">
                                        <div className="row">
                                            {
                                                this.props.saveClientErrorResponse.error
                                                  ? <div className="col-xs-12 text-danger text-center">
                                                        {JSON.stringify(this.props.saveClientErrorResponse.error)}
                                                    </div>
                                                  : null
                                            }
                                            {
                                                this.props.updateClientErrorResponse.error
                                                  ? <div className="col-xs-12 text-danger text-center">
                                                        {JSON.stringify(this.props.updateClientErrorResponse.error)}
                                                    </div>
                                                  : null
                                            }
                                            <div className="col-xs-6">
                                                <button
                                                    className="wizard-prev btn btn-warning"
                                                    type="button"
                                                >
                                                    <i className="fa fa-arrow-circle-o-left"></i> Previous
                                                </button>
                                            </div>
                                            <div className="col-xs-6 text-right">
                                                <button
                                                    className="wizard-next btn btn-success"
                                                    type="button"
                                                >
                                                    Next <i className="fa fa-arrow-circle-o-right"></i>
                                                </button>
                                                <button
                                                    className="wizard-finish btn btn-primary"
                                                    type="button"
                                                    disabled={this.props.isLoading}
                                                    onClick={this.sendData}
                                                >
                                                    {
                                                        this.props.isLoading
                                                          ? <>
                                                                <i className="ml-1 fa fa-spinner fa-spin pull-right"></i>
                                                                <span>Submit</span>
                                                            </>
                                                          : <>
                                                                <i className="ml-1 si si-login pull-right"></i>
                                                                <span>Submit</span>
                                                            </>
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* END Steps Navigation */}
                                </form>
                                {/* END Form */}
                            </div>
                            {/* END Simple Progress Wizard */}
                            {/* <pre className="pre-sh push"> */}
                            {/*    <code className="json"> */}
                            {/*    {jsonPrettify(this.state.client)} */}
                            {/*    </code> */}
                            {/* </pre> */}
                        </div>
                    </div>
                </div>
            </>
    )
  }
}

// Set propTypes
ClientWizardForm.propTypes = {
  client: ClientShape,
  saveClientErrorResponse: PropTypes.object,
  updateClientErrorResponse: PropTypes.object,
  isEditor: PropTypes.bool
}

// Set defaultProps
ClientWizardForm.defaultProps = {
  client: {},
  saveClientErrorResponse: {},
  updateClientErrorResponse: {},
  isEditor: false
}

// mapStateToProps
const mapStateToProps = (state) => ({
  isLoading: state.clients.saveClientReducer.loading || state.clients.updateClientReducer.loading,
  saveClientErrorResponse: state.clients.saveClientReducer.error_data,
  updateClientErrorResponse: state.clients.updateClientReducer.error_data
})

// mapStateToProps
const mapDispatchToProps = {
  saveClient: clientOperations.save,
  updateClient: clientOperations.update
}

// Export Class
const _ClientWizardForm = connect(mapStateToProps, mapDispatchToProps)(ClientWizardForm)
export { _ClientWizardForm as ClientWizardForm }
