import React, { Component } from 'react'
import { PageContainerLayout, PageHeaderLayout } from '../../../layouts'
import { ClientWizardForm } from '../../containers/client-wizard-form'
import { NavLink } from 'react-router-dom'
import * as ROUTING_PATHS from '../../../../routing/Paths'

class ClientCreatorPage extends Component {
  constructor (props) {
    super(props)
  }

  renderHeaderPaths () {
    return (
            <>
                <li>
                    <NavLink className="link-effect" to={ROUTING_PATHS.CLIENTS_PATH}>
                        Clients
                    </NavLink>
                </li>
                <li>New Client</li>
            </>
    )
  }

  render () {
    return (
            <>

                <PageContainerLayout>
                    <PageHeaderLayout
                        title={'New client'}
                        subTitle={'Create a new client with users and roles'}
                        paths={this.renderHeaderPaths}
                    />
                    <ClientWizardForm/>
                </PageContainerLayout>
            </>
    )
  }
}

export { ClientCreatorPage }
