import React, { Component } from 'react'
import './styles.scss'
import { Link, NavLink } from 'react-router-dom'
import * as PATHS from '../../../routing/Paths'
import { Session } from '../../../state/utils/session'
import { GroupOptionsControllerLayout } from '../group-options-controller'

class SidebarLayout extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }
  }

  renderAdminSidebarOptions () {
    return (
            <div className="side-content">
                <ul className="nav-main">
                    <li>
                        <NavLink activeClassName='active' to={PATHS.HOME_PATH}>
                            <i className="si si-speedometer text-center"/>
                            <span className="sidebar-mini-hide">Dashboard</span>
                        </NavLink>
                    </li>
                    <li className="nav-main-heading">
                        <span className="sidebar-mini-hide">Administration</span>
                    </li>
                    <li>
                        <NavLink activeClassName='active' to={PATHS.CLIENTS_PATH}>
                            <i className="fa fa-building text-center"/>
                            <span className="sidebar-mini-hide">Clients</span>

                        </NavLink>
                    </li>
                    <GroupOptionsControllerLayout validFor={['ADMIN']}>
                        <li>
                            <NavLink activeClassName='active' to={PATHS.PERMISSIONS_PATH}>
                                <i className="fa fa-lock text-center"/>
                                <span className="sidebar-mini-hide">Permwissions</span>
                            </NavLink>
                        </li>
                    </GroupOptionsControllerLayout>
                    <GroupOptionsControllerLayout validFor={['ADMIN']}>
                        <li>
                            <NavLink activeClassName='active' to={PATHS.GROUPS_PATH}>
                                <i className="fa fa-object-group text-center"/>
                                <span className="sidebar-mini-hide">Groups</span>
                            </NavLink>
                        </li>
                    </GroupOptionsControllerLayout>
                    <GroupOptionsControllerLayout validFor={['ADMIN']}>
                        <li>
                            <NavLink activeClassName='active' to={PATHS.USERS_PATH}>
                                <i className="fa fa-users text-center"/>
                                <span className="sidebar-mini-hide">Users</span>
                            </NavLink>
                        </li>
                    </GroupOptionsControllerLayout>
                </ul>
            </div>
    )
  }

  renderClientSidebarOptions () {
    const clientId = this.state.session.getClientSession()

    return (
            <div className="side-content">
                <ul className="nav-main">
                    <li>
                        <NavLink activeClassName='active'
                                 to={PATHS.CLIENT_DASHBOARD_HOME_PATH.replace(':client_id', clientId)}
                        >
                            <i className="si si-speedometer text-center"/>
                            <span className="sidebar-mini-hide">Dashboard</span>
                        </NavLink>
                    </li>
                    <GroupOptionsControllerLayout validFor={['ADMIN', 'DEVELOPER']}>
                        <li className="nav-main-heading">
                            <span className="sidebar-mini-hide">ETL</span>
                        </li>
                    </GroupOptionsControllerLayout>
                    <GroupOptionsControllerLayout validFor={['ADMIN', 'DEVELOPER']}>
                        <li>
                            <NavLink activeClassName='active'
                                     to={PATHS.CLIENT_DASHBOARD_CONNECTIONS_PATH.replace(':client_id', clientId)}
                            >
                                <i className="fa fa-plug text-center"/>
                                <span className="sidebar-mini-hide">Connections</span>
                            </NavLink>
                        </li>
                    </GroupOptionsControllerLayout>
                    <GroupOptionsControllerLayout validFor={['ADMIN', 'DEVELOPER']}>
                        <li>
                            <NavLink activeClassName='active'
                                     to={PATHS.CLIENT_DASHBOARD_EXTRACTIONS_PATH.replace(':client_id', clientId)}
                            >
                                <i className="fas fa-parachute-box text-center"/>
                                <span className="sidebar-mini-hide">Extractions</span>
                            </NavLink>
                        </li>
                    </GroupOptionsControllerLayout>
                    <GroupOptionsControllerLayout validFor={['ADMIN', 'DEVELOPER']}>
                        <li>
                            <NavLink activeClassName='active'
                                     to={PATHS.CLIENT_DASHBOARD_SOURCES_PATH.replace(':client_id', clientId)}
                            >
                                <i className="fas fa-boxes text-center"/>
                                <span className="sidebar-mini-hide">Sources</span>
                            </NavLink>
                        </li>
                    </GroupOptionsControllerLayout>
                    <GroupOptionsControllerLayout validFor={['ADMIN', 'DEVELOPER', 'VISUALIZER']}>
                        <li className="nav-main-heading">
                            <span className="sidebar-mini-hide">Conciliations</span>
                        </li>
                    </GroupOptionsControllerLayout>
                    <GroupOptionsControllerLayout validFor={['ADMIN', 'DEVELOPER']}>
                        <li>
                            <NavLink activeClassName='active'
                                     to={PATHS.CLIENT_DASHBOARD_CONCILIATIONS_PATH.replace(':client_id', clientId)}
                            >
                                <i className="fa fa-project-diagram text-center"/>
                                <span className="sidebar-mini-hide">Conciliations</span>
                            </NavLink>
                        </li>
                    </GroupOptionsControllerLayout>
                    <GroupOptionsControllerLayout validFor={['ADMIN', 'DEVELOPER', 'VISUALIZER']}>
                        <li>
                            <NavLink activeClassName='active'
                                     to={PATHS.CLIENT_DASHBOARD_DASHBOARDS_PATH.replace(':client_id', clientId)}
                            >
                                <i className="fa fa-chart-line text-center"/>
                                <span className="sidebar-mini-hide">Dashboards</span>
                            </NavLink>
                        </li>
                    </GroupOptionsControllerLayout>
                    <GroupOptionsControllerLayout validFor={['ADMIN', 'DEVELOPER']}>
                        <li className="nav-main-heading">
                            <span className="sidebar-mini-hide">Accounting</span>
                        </li>
                    </GroupOptionsControllerLayout>
                    <GroupOptionsControllerLayout validFor={['ADMIN', 'DEVELOPER']}>
                        <li className={(window.location.href.includes('non-reconciled') ? 'open' : '')}>
                            <a className="nav-submenu" data-toggle="nav-submenu" href="#">
                                <i className="si si-badge"/>
                                <span className="sidebar-mini-hide">Non-Reconciled Items</span>
                            </a>
                            <ul>
                                <li>
                                    <NavLink
                                        activeClassName='active'
                                        to={{
                                          pathname: PATHS.CLIENT_DASHBOARD_NON_RECONCILED_ITEMS_PATH.replace(':client_id', clientId)
                                        }}>
                                        Dashboard
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        activeClassName='active'
                                        to={{
                                          pathname: PATHS.CLIENT_DASHBOARD_ACCOUNTING_SEAT_SETTINGS_PATH.replace(':client_id', clientId)
                                        }}>
                                        Settings
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                    </GroupOptionsControllerLayout>
                    <GroupOptionsControllerLayout validFor={['ADMIN', 'DEVELOPER']}>
                        <li className={(window.location.href.includes('accounting') ? 'open' : '')}>
                            <a className="nav-submenu" data-toggle="nav-submenu" href="#">
                                <i className="si si-badge"/>
                                <span className="sidebar-mini-hide">Accounting Seats</span>
                            </a>
                            <ul>
                                <li>
                                    <NavLink
                                        activeClassName='active'
                                        to={{
                                          pathname: PATHS.CLIENT_DASHBOARD_ACCOUNTING_SEATS_DASHBOARD_PATH.replace(':client_id', clientId),
                                          search: '?status=1'
                                        }}>
                                        Dashboard
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink
                                        activeClassName='active'
                                        to={{
                                          pathname: PATHS.CLIENT_DASHBOARD_ACCOUNTING_SEAT_SETTINGS_PATH.replace(':client_id', clientId)
                                        }}>
                                        Settings
                                    </NavLink>
                                </li>
                            </ul>
                        </li>
                    </GroupOptionsControllerLayout>
                    <GroupOptionsControllerLayout validFor={['ADMIN', 'DEVELOPER']}>
                        <li className="nav-main-heading">
                            <span className="sidebar-mini-hide">Automations</span>
                        </li>
                    </GroupOptionsControllerLayout>
                    <GroupOptionsControllerLayout validFor={['ADMIN', 'DEVELOPER']}>
                        <li>
                            <NavLink activeClassName='active'
                                     to={PATHS.CLIENT_DASHBOARD_ALARMS_PATH.replace(':client_id', clientId)}
                            >
                                <i className="fa fa-bell text-center"/>
                                <span className="sidebar-mini-hide">Alarms</span>
                            </NavLink>
                        </li>
                    </GroupOptionsControllerLayout>
                    <GroupOptionsControllerLayout validFor={['ADMIN', 'DEVELOPER']}>
                        <li>
                            <NavLink activeClassName='active'
                                     to={PATHS.CLIENT_DASHBOARD_AUTOMATIONS_PATH.replace(':client_id', clientId)}
                            >
                                <i className="fa fa-magic text-center"/>
                                <span className="sidebar-mini-hide">Automations</span>
                            </NavLink>
                        </li>
                    </GroupOptionsControllerLayout>
                    <GroupOptionsControllerLayout validFor={['ADMIN', 'DEVELOPER']}>
                        <li className="nav-main-heading">
                            <span className="sidebar-mini-hide"/>
                        </li>
                    </GroupOptionsControllerLayout>
                </ul>
            </div>
    )
  }

  render () {
    return (
            <>
                <nav id="sidebar">
                    {/* Sidebar Scroll Container */}
                    <div id="sidebar-scroll">
                        {/* Sidebar Content */}
                        {/* Adding .sidebar-mini-hide to an element will hide it when the sidebar is in mini mode */}
                        <div className="sidebar-content">
                            {/* Side Header */}
                            <div className="side-header side-content bg-white-op">
                                {/* Layout API, functionality initialized in App() -> uiLayoutApi() */}
                                <button className="btn btn-link text-gray pull-right hidden-md hidden-lg" type="button"
                                        data-toggle="layout" data-action="sidebar_close">
                                    <i className="fa fa-times"/>
                                </button>
                                {/* Themes functionality initialized in App() -> uiHandleTheme() */}
                                <div className="btn-group pull-right">
                                    <button id="btn-select-theme" className="btn btn-link text-gray dropdown-toggle"
                                            data-toggle="dropdown"
                                            type="button">
                                        <i className="si si-drop"/>
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-right font-s13 sidebar-mini-hide">
                                        <li>
                                            <a data-toggle="theme" data-theme="default" tabIndex="-1"
                                               href="javascript:void(0)">
                                                <i className="fa fa-circle text-default pull-right"/> <span
                                                className="font-w600">Default</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <Link className="h5 text-white" to={PATHS.HOME_PATH}>
                                    <img className="sidebar-logo" src={'/img/logos/simetrik-white-logo.png'}
                                         alt="Simetrik"/>
                                    <img className="sidebar-complete-logo"
                                         src={'/img/logos/simetrik-white-complete-logo.png'} alt="Simetrik"/>
                                </Link>
                            </div>
                            {/* END Side Header */}

                            {/* Side Content */}
                            {!this.props.clientSidebar ? this.renderAdminSidebarOptions() : this.renderClientSidebarOptions()}
                            {/* END Side Content */}
                        </div>
                        {/* Sidebar Content */}
                    </div>
                    {/* END Sidebar Scroll Container */}
                </nav>
            </>
    )
  }
}

SidebarLayout.defaultProps = {
  clientSidebar: false
}

export { SidebarLayout }
