import React from 'react'

export function WorkerCallDataForm (props) {
  const handleChange = (event) => {
    props.onChange(event.target.name, event.target.value)
  }

  return (
    <>
      <div className="form-group">
        <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
          <div className="form-material">
            <input
              className="form-control"
              type="text"
              id="input-name"
              placeholder="Please enter a name"
              name="name"
              value={props.workerCall.name}
              onChange={handleChange}
            />
            <label htmlFor="input-name">Name</label>
          </div>
        </div>
        <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
          <div className="form-material">
            <input
              className="form-control"
              type="text"
              id="input-path"
              placeholder="Please enter a path"
              name="path"
              value={props.workerCall.path}
              onChange={handleChange}
            />
            <label htmlFor="input-path">Path</label>
          </div>
        </div>
      </div>
    </>
  )
}

WorkerCallDataForm.defaultProps = {
  workerCall: {},
  onChange: () => {}
}
