import reducer from './reducers'

import * as userOperations from './operations'
import * as userEndpoints from './paths'

export {
  userOperations,
  userEndpoints
}

export default reducer
