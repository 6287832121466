import React, { Component } from 'react'
import { PageContainerLayout, PageHeaderLayout } from '../../../layouts'
import { NavLink } from 'react-router-dom'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import * as PropTypes from 'prop-types'
// import {accountingSeatOperations} from "../../../../state/ducks/accounting-seat-settings";
import { connect } from 'react-redux'
import { Session } from '../../../../state/utils/session'

class AccountingSeatEditorPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }

    this.renderHeaderPaths = this.renderHeaderPaths.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.props.getAccountingSeat(this.props.match.params.accounting_seat_id)
  }

  renderHeaderPaths () {
    return (
            <>
                <li>
                    <NavLink className="link-effect"
                             to={ROUTING_PATHS.CLIENT_DASHBOARD_ACCOUNTING_SEATS_DASHBOARD_PATH.replace(':client_id', this.state.session.getClientSession())}
                    >
                        Accounting Seats
                    </NavLink>
                </li>
                <li>Edit AccountingSeat</li>
            </>
    )
  }

  render () {
    return (
            <>
                <PageContainerLayout clientSidebar={true} showNavbarClientOptions={true}>
                    <PageHeaderLayout
                        title={this.props.accountingSeat.name}
                        subTitle={'Edit AccountingSeat'}
                        paths={this.renderHeaderPaths}
                    />
                </PageContainerLayout>
            </>
    )
  }
}

// Set propTypes
AccountingSeatEditorPage.propTypes = {
  accountingSeat: PropTypes.object,
  getAccountingSeatErrorResponse: PropTypes.object
}

// Set defaultProps
AccountingSeatEditorPage.defaultProps = {
  accountingSeat: {},
  getAccountingSeatErrorResponse: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  accountingSeat: state.accountingSeats.getAccountingSeatReducer.data,
  isLoading: state.accountingSeats.getAccountingSeatReducer.loading,
  getAccountingSeatErrorResponse: state.accountingSeats.getAccountingSeatReducer.error_data
})

// mapStateToProps
const mapDispatchToProps = {
  // getAccountingSeat: accountingSeatOperations.get
}

// Export Class
const _AccountingSeatEditorPage = connect(mapStateToProps, mapDispatchToProps)(AccountingSeatEditorPage)
export { _AccountingSeatEditorPage as AccountingSeatEditorPage }
