import React from 'react'
import { SortableContainer as SortableList } from 'react-sortable-hoc'
import { SortableColumnRow } from '../sortable-column-row'

export const SortableColumnTable = SortableList((
  { columns, onPositionChange, onAddColumn, onDeleteColumn, onOpenNormalizations }
) => {
  return (
        <>
            <table className="table table-striped table-borderless text-center">
                <thead>
                <tr>
                    <td>Position</td>
                    <td>ID</td>
                    <td>Name</td>
                    <td>Type</td>
                    <td>Rule</td>
                    <td>Length</td>
                    <td>Index</td>
                    <td>Options</td>
                </tr>
                </thead>
                <tbody>
                {
                    columns.map((column, i) => {
                      return (
                            <SortableColumnRow
                            key={`sortable-column-row-${i}`}
                                position={i}
                                column={column}
                                onPositionChange={onPositionChange}
                                onDeleteColumn={onDeleteColumn}
                                onOpenNormalizations={onOpenNormalizations}
                            />
                      )
                    })
                }
                </tbody>
            </table>
            <h6 className="text-primary hover mt-2" onClick={(e) => {
              e.preventDefault()
              onAddColumn()
            }}>+ Add Column</h6>
        </>
  )
})
