import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { history } from '../../../../routing/History'

class AccountLockedPage extends Component {
  constructor (props) {
    super(props)

    this.goToHome = this.goToHome.bind(this)
  }

  UNSAFE_componentWillMount () {
    window.$('body').addClass('bg-image')
    window.$('body').css('background-image', "url('/img/photos/photo17@2x.jpg')")
  }

  componentWillUnmount () {
    window.$('body').removeClass('bg-image')
    window.$('body').css('background-image', 'initial')
  }

  goToHome () {
    history.goBack()
  }

  render () {
    return (
            <>
                {/* Lock Screen Content */}
                <div className="content overflow-hidden">
                    <div className="row">
                        <div className="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 col-lg-4 col-lg-offset-4">
                            {/* Lock Screen Block */}
                            <div className="block block-themed animated bounceIn">
                                <div className="block-header bg-primary-dark">
                                    <h3 className="block-title">Account Locked</h3>
                                </div>
                                <div className="block-content block-content-full block-content-narrow">
                                    {/* Lock Screen Avatar */}
                                    <div className="text-center push-15-t push-15">
                                        <img className="img-avatar img-avatar96" src="/img/avatars/avatar10.jpg"
                                             alt=""/>
                                    </div>
                                    {/* END Lock Screen Avatar */}

                                    {/* Lock Screen Form */}
                                    {/* jQuery Validation (.js-validation-lock class is initialized in js/pages/base_pages_lock.js) */}
                                    {/* For more examples you can check out https://github.com/jzaefferer/jquery-validation */}
                                    <div className="js-validation-lock form-horizontal push-30-t push-30">
                                        <div className="form-group">
                                            <div className="col-xs-10 col-xs-offset-1 col-sm-6 col-sm-offset-3">
                                                <button className="btn btn-block btn-default"
                                                        type="submit"
                                                        onClick={this.goToHome}
                                                >
                                                    <i className="si si-lock-open pull-right"/> Unlock
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* END Lock Screen Form */}
                                </div>
                            </div>
                            {/* END Lock Screen Block */}
                        </div>
                    </div>
                </div>
                {/* END Lock Screen Content */}

                {/* Lock Screen Footer */}
                <div className="push-10-t text-center animated fadeInUp">
                    <small className="text-black-op font-w600"><span className="js-year-copy"/>
                        Copyright © 2019 Simetrik Inc. All rights reserved.
                    </small>
                </div>
                {/* END Lock Screen Footer */}
            </>
    )
  };
}

const _AccountLockedPage = withRouter(AccountLockedPage)

export { _AccountLockedPage as AccountLockedPage }
