import React, { Component } from 'react'

const QuickSightEmbedding = require('amazon-quicksight-embedding-sdk')

class DashboardEmbedCard extends Component {
  constructor (props) {
    super(props)

    this.state = {
      url: ''
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.url.includes('http') && this.state.url === '') {
      this.setState({
        url: nextProps.url
      }, () => {
        const containerDiv = document.getElementById('quicksight')
        const options = {
          url: nextProps.url,
          container: containerDiv,
          parameters: {},
          height: '100%',
          width: '100%'
        }
        // const dashboard = QuickSightEmbedding.embedDashboard(options)
        QuickSightEmbedding.embedDashboard(options)
        // dashboard.on("error", (payload) => {
        //     this.setState({showErrorDiv: true})
        // });
      })
    }
  }

  render () {
    return (
            <>
                <div className="block block-themed">
                    <div className="block-header bg-primary-dark">
                        <ul className="block-options">
                            <li>
                                <button type="button"
                                        data-toggle="block-option"
                                        data-action="fullscreen_toggle"
                                >
                                    <i className="si si-size-fullscreen"/>
                                </button>
                            </li>
                        </ul>
                        <h3 className="block-title">Quicksight Dashboard</h3>
                    </div>
                    <div className="block-content">
                        <div id="quicksight" style={{ height: '89vh' }}>

                        </div>
                    </div>
                </div>
            </>
    )
  }
}

// Set defaultProps
DashboardEmbedCard.defaultProps = {
  url: ''
}

// Export Class
export { DashboardEmbedCard }
