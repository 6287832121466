import React, { Component } from 'react'
import { AppUI } from '../../../assets/js/app'

class AuthenticationPageLayout extends Component {
  constructor (props) {
    super(props)
    this.initialize = this.initialize.bind(this)
    this.initialize()
  }

  componentDidMount () {
    this.initialize()
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    this.initialize()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    this.initialize()
  }

  initialize () {
    window.jQuery(function () {
      // Init page helpers (BS Notify Plugin)
      AppUI.init()
    })
  }

  render () {
    return (
            <>
                {/* Content */}
                <div className="content overflow-hidden auth-page">
                    <div className="row" style={{ display: 'flex', flex: 1, minHeight: '80vh', alignItems: 'center' }}>
                        <div className="col-sm-8 col-sm-offset-2 col-md-6 col-md-offset-3 col-lg-4 col-lg-offset-4">
                            {/* Children */}
                            {this.props.children}
                            {/* END Children */}
                        </div>
                    </div>
                </div>
                {/* END Content */}

                {/* Footer */}
                <div className="push-10-t text-center animated fadeInUp">
                    <small className="text-muted font-w600">
                        Copyright © 2019 Simetrik Inc. All rights reserved.
                    </small>
                </div>
                {/* END Footer */}
            </>
    )
  };
}

export { AuthenticationPageLayout }
