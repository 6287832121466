import React, { Component } from 'react'
import './styles.scss'
import { DashboardHeaderLayout, PageContainerLayout, StatsLayout } from '../../../layouts'
import { Session } from '../../../../state/utils/session'
import { permissionOperations } from '../../../../state/ducks/permissions'
import { userOperations } from '../../../../state/ducks/users'
import { groupOperations } from '../../../../state/ducks/groups'
import { connectionOperations } from '../../../../state/ducks/connections'
import { extractionOperations } from '../../../../state/ducks/extractions'
import { sourceOperations } from '../../../../state/ducks/sources'
import { conciliationOperations } from '../../../../state/ducks/conciliations'
import { dashboardOperations } from '../../../../state/ducks/dashboards'
import { alarmOperations } from '../../../../state/ducks/alarms'
import { automationOperations } from '../../../../state/ducks/automations'
import { jobPoolsOperations } from '../../../../state/ducks/jobs-pools'
import { jobsOperations } from '../../../../state/ducks/jobs'
import { connect } from 'react-redux'
import * as PATHS from '../../../../routing/Paths'
import config from '../../../../config'
import { workerCallsOperations } from '../../../../state/ducks/worker-calls'

class HomePage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      featureMode: ['MELI_TEST'].includes(config.featureMode),
      session: new Session(),
      stats_items: [
        {
          title_icon: 'fa fa-plug mr-2',
          title_text: 'Connections',
          count: 0,
          link: '',
          validFor: []
        },
        {
          title_icon: 'fas fa-parachute-box mr-2',
          title_text: 'Extractions',
          count: 0,
          link: '',
          validFor: []
        },
        {
          title_icon: 'fas fa-boxes mr-2',
          title_text: 'Sources',
          count: 0,
          link: '',
          validFor: []
        },
        {
          title_icon: 'fa fa-project-diagram mr-2',
          title_text: 'Conciliations',
          count: 0,
          link: '',
          validFor: []
        },
        {
          title_icon: 'fa fa-chart-line mr-2',
          title_text: 'Dashboards',
          count: 0,
          link: '',
          validFor: []
        },
        {
          title_icon: 'fa fa-bell mr-2',
          title_text: 'Alarms',
          count: 0,
          link: '',
          validFor: []
        },
        {
          title_icon: 'fa fa-magic mr-2',
          title_text: 'Automations',
          count: 0,
          link: '',
          validFor: []
        },
        {
          title_icon: 'fa fa-briefcase mr-2',
          title_text: 'Worker Calls',
          count: 0,
          link: '',
          validFor: []
        },
        {
          title_icon: 'fa fa-object-group mr-2',
          title_text: 'Groups',
          count: 0,
          link: '',
          validFor: []
        },
        {
          title_icon: 'fa fa-lock mr-2',
          title_text: 'Permissions',
          count: 0,
          link: '',
          validFor: []
        },
        {
          title_icon: 'fa fa-users mr-2',
          title_text: 'Users',
          count: 0,
          link: ''
        }
      ]
    }
  }

  UNSAFE_componentWillMount () {
    this.props.fetchPermissions({})
    this.props.fetchUsers({})
    this.props.fetchGroups({})
    this.props.fetchConnections({})
    this.props.fetchExtractions({})
    this.props.fetchSources({})
    this.props.fetchConciliations({})
    this.props.fetchDashboards({})
    this.props.fetchAlarms({})
    this.props.fetchAutomations({})
    this.props.fetchWorkerCalls({})
    if (this.state.featureMode) {
      this.props.fetchJobPools({})
      this.props.fetchJobs({})
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    const statsItems = [
      {
        title_icon: 'fa fa-plug mr-2',
        title_text: 'Connections',
        count: nextProps.connections,
        link: PATHS.CLIENT_DASHBOARD_CONNECTIONS_PATH.replace(':client_id', this.state.session.getClientSession()),
        validFor: ['ADMIN', 'DEVELOPER']
      },
      {
        title_icon: 'fas fa-parachute-box mr-2',
        title_text: 'Extractions',
        count: nextProps.extractions,
        link: PATHS.CLIENT_DASHBOARD_EXTRACTIONS_PATH.replace(':client_id', this.state.session.getClientSession()),
        validFor: ['ADMIN', 'DEVELOPER']
      },
      {
        title_icon: 'fas fa-boxes mr-2',
        title_text: 'Sources',
        count: nextProps.sources,
        link: PATHS.CLIENT_DASHBOARD_SOURCES_PATH.replace(':client_id', this.state.session.getClientSession()),
        validFor: ['ADMIN', 'DEVELOPER']
      },
      {
        title_icon: 'fa fa-project-diagram mr-2',
        title_text: 'Conciliations',
        count: nextProps.conciliations,
        link: PATHS.CLIENT_DASHBOARD_CONCILIATIONS_PATH.replace(':client_id', this.state.session.getClientSession()),
        validFor: ['ADMIN', 'DEVELOPER']
      },
      {
        title_icon: 'fa fa-chart-line mr-2',
        title_text: 'Dashboards',
        count: nextProps.dashboards,
        link: PATHS.CLIENT_DASHBOARD_DASHBOARDS_PATH.replace(':client_id', this.state.session.getClientSession()),
        validFor: ['ADMIN', 'DEVELOPER', 'VISUALIZER']
      },
      {
        title_icon: 'fa fa-bell mr-2',
        title_text: 'Alarms',
        count: nextProps.alarms,
        link: PATHS.CLIENT_DASHBOARD_ALARMS_PATH.replace(':client_id', this.state.session.getClientSession()),
        validFor: ['ADMIN', 'DEVELOPER']
      },
      {
        title_icon: 'fa fa-magic mr-2',
        title_text: 'Automations',
        count: nextProps.automations,
        link: PATHS.CLIENT_DASHBOARD_AUTOMATIONS_PATH.replace(':client_id', this.state.session.getClientSession()),
        validFor: ['ADMIN', 'DEVELOPER']
      },
      {
        title_icon: 'fa fa-briefcase mr-2',
        title_text: 'Worker Calls',
        count: nextProps.workerCalls,
        link: PATHS.CLIENT_DASHBOARD_WORKER_CALLS_PATH.replace(':client_id', this.state.session.getClientSession()),
        validFor: ['ADMIN', 'DEVELOPER']
      },
      {
        title_icon: 'fa fa-object-group mr-2',
        title_text: 'Groups',
        count: nextProps.groups,
        link: PATHS.CLIENT_DASHBOARD_GROUPS_PATH.replace(':client_id', this.state.session.getClientSession()),
        validFor: ['ADMIN']
      },
      {
        title_icon: 'fa fa-lock mr-2',
        title_text: 'Permissions',
        count: nextProps.permissions,
        link: PATHS.CLIENT_DASHBOARD_PERMISSIONS_PATH.replace(':client_id', this.state.session.getClientSession()),
        validFor: ['ADMIN']
      },
      {
        title_icon: 'fa fa-users mr-2',
        title_text: 'Users',
        count: nextProps.users,
        link: PATHS.CLIENT_DASHBOARD_USERS_PATH.replace(':client_id', this.state.session.getClientSession()),
        validFor: ['ADMIN']
      },
      {
        title_icon: 'fa fa-search mr-2',
        title_text: 'Inconsistencies',
        count: '‎',
        link: PATHS.CLIENT_DASHBOARD_INCONSISTENCIES_PATH.replace(':client_id', this.state.session.getClientSession()),
        validFor: ['ADMIN', 'DEVELOPER']
      }
    ]

    if (this.state.featureMode) {
      statsItems.push({
        title_icon: 'fa fa-building mr-2',
        title_text: 'Jobs Pools',
        count: nextProps.jobPools,
        link: PATHS.CLIENT_DASHBOARD_JOB_POOLS_PATH.replace(':client_id', this.state.session.getClientSession()),
        validFor: ['ADMIN', 'DEVELOPER']
      },
      {
        title_icon: 'fa fa-building mr-2',
        title_text: 'Jobs',
        count: nextProps.jobs,
        link: PATHS.CLIENT_DASHBOARD_JOB_PATH.replace(':client_id', this.state.session.getClientSession()),
        validFor: ['ADMIN', 'DEVELOPER']
      })
    }

    this.setState({
      stats_items: statsItems
    })
  }

  render () {
    return (
            <>
                <PageContainerLayout clientSidebar={true} showNavbarClientOptions={true}>
                    {/* Page Header */}
                    <DashboardHeaderLayout
                        title={'Dashboard'}
                        subTitle={'Client Name'}
                        showImage={true}
                        imageSrc={'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6oN5Lv9xeG3wJVLC1FcvoijGd0pMBdUH2tXn8QCP0FYU1XMAAlg'}
                    />
                    {/* END Page Header */}

                    {/* Stats */}
                    <StatsLayout items={this.state.stats_items} col_size={'col-xs-6 col-sm-2'}/>
                    {/* END Stats */}

                </PageContainerLayout>
            </>
    )
  };
}

// Set defaultProps
HomePage.defaultProps = {
  permissions: 0,
  users: 0,
  groups: 0,
  connections: 0,
  extractions: 0,
  sources: 0,
  conciliations: 0,
  dashboards: 0,
  alarms: 0,
  automations: 0,
  workerCalls: 0,
  jobPools: 0,
  jobs: 0
}

// mapStateToProps
const mapStateToProps = (state) => ({
  permissions: state.permissions.fetchPermissionsReducer.data.pagination_data.totalItems,
  users: state.users.fetchUsersReducer.data.pagination_data.totalItems,
  groups: state.groups.fetchGroupsReducer.data.pagination_data.totalItems,
  connections: state.connections.fetchConnectionsReducer.data.pagination_data.totalItems,
  extractions: state.extractions.fetchExtractionsReducer.data.pagination_data.totalItems,
  sources: state.sources.fetchSourcesReducer.data.pagination_data.totalItems,
  conciliations: state.conciliations.fetchConciliationsReducer.data.pagination_data.totalItems,
  dashboards: state.dashboards.fetchDashboardsReducer.data.pagination_data.totalItems,
  alarms: state.alarms.fetchAlarmsReducer.data.pagination_data.totalItems,
  automations: state.automations.fetchAutomationsReducer.data.pagination_data.totalItems,
  workerCalls: state.workerCalls.fetchWorkerCallsReducer.data.pagination_data.totalItems,
  jobPools: state.jobsPools.fetchJobPoolsReducer.data.pagination_data.totalItems,
  jobs: state.jobs.fetchJobsReducer.data.pagination_data.totalItems
})

// mapStateToProps
const mapDispatchToProps = {
  fetchConnections: connectionOperations.list,
  fetchExtractions: extractionOperations.list,
  fetchSources: sourceOperations.list,
  fetchConciliations: conciliationOperations.list,
  fetchDashboards: dashboardOperations.list,
  fetchAlarms: alarmOperations.list,
  fetchAutomations: automationOperations.list,
  fetchWorkerCalls: workerCallsOperations.list,
  fetchPermissions: permissionOperations.list,
  fetchUsers: userOperations.list,
  fetchGroups: groupOperations.list,
  fetchJobPools: jobPoolsOperations.list,
  fetchJobs: jobsOperations.list
}

// Export Class
const _HomePage = connect(mapStateToProps, mapDispatchToProps)(HomePage)
export { _HomePage as HomePage }
