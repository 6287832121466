import React, { Component } from 'react'
import { history } from '../../../../routing/History'
import { PERMISSION_CREATOR_PATH } from '../../../../routing/Paths'

class PermissionsHeaderTiles extends Component {
  constructor (props) {
    super(props)

    this.state = {}
    this.goToGroupCreator = this.goToGroupCreator.bind(this)
  }

  goToGroupCreator () {
    history.push(PERMISSION_CREATOR_PATH)
  }

  render () {
    return (
            <>
                <div className="row">
                    <div className="col-sm-6 col-md-3">
                        <a className="block block-link-hover3 text-center"
                           href="javascript:void(0)"
                           onClick={this.goToGroupCreator}
                        >
                            <div className="block-content block-content-full">
                                <div className="h1 font-w700 text-success"><i className="fa fa-plus"></i></div>
                            </div>
                            <div className="block-content block-content-full block-content-mini bg-gray-lighter text-success font-w600">
                                Add New Permission
                            </div>
                        </a>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <a className="block block-link-hover3 text-center" href="javascript:void(0)">
                            <div className="block-content block-content-full">
                                <div className="h1 font-w700">{this.props.all_permissions}</div>
                            </div>
                            <div className="block-content block-content-full block-content-mini bg-gray-lighter text-muted font-w600">
                                All permissions
                            </div>
                        </a>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <a className="block block-link-hover3 text-center" href="javascript:void(0)">
                            <div className="block-content block-content-full">
                                <div className="h1 font-w700">&nbsp;</div>
                            </div>
                            <div className="block-content block-content-full block-content-mini bg-gray-lighter text-muted font-w600">
                                &nbsp;
                            </div>
                        </a>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <a className="block block-link-hover3 text-center" href="javascript:void(0)">
                            <div className="block-content block-content-full">
                                <div className="h1 font-w700">&nbsp;</div>
                            </div>
                            <div className="block-content block-content-full block-content-mini bg-gray-lighter text-muted font-w600">
                                &nbsp;
                            </div>
                        </a>
                    </div>
                </div>
            </>
    )
  }
}

// Set defaultProps
PermissionsHeaderTiles.defaultProps = {
  all_permissions: 0
}

// Export Class
export { PermissionsHeaderTiles }
