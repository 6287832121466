import React, { Component } from 'react'
import { PageContainerLayout, PageHeaderLayout, SourceHeaderLayout } from '../../../layouts'
import { NavLink } from 'react-router-dom'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { SourceShape } from '../../../../prop-types'
import * as PropTypes from 'prop-types'
import { sourceOperations } from '../../../../state/ducks/sources'
import { connect } from 'react-redux'
import { Session } from '../../../../state/utils/session'
import {
  SourceColumnsTable,
  SourceDetailsCard,
  SourceExecutionLogs,
  SourceSuccessTableGenerated,
  SourceErrorTableGenerated,
  SourceTimelineCard
} from '../../containers'

class SourceDetailsPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }
  }

  UNSAFE_componentWillMount () {
    this.props.getSource(this.props.match.params.source_id)
  }

  render () {
    const renderHeaderPaths = () => {
      return (
                <>
                    <li>
                        <NavLink className="link-effect"
                                 to={ROUTING_PATHS.CLIENT_DASHBOARD_SOURCES_PATH
                                   .replace(':client_id', this.state.session.getClientSession())
                                 }
                        >
                            Sources
                        </NavLink>
                    </li>
                    <li>Source Details</li>
                </>
      )
    }

    return (
            <>
                <PageContainerLayout
                    clientSidebar={true}
                    showNavbarClientOptions={true}
                >
                    {/* First Section */}
                    <SourceHeaderLayout
                        title={this.props.source.name}
                        subTitle={'Source Details'}
                        paths={renderHeaderPaths}
                    />

                    <div className="content content-boxed">
                        <div className="row">
                            <div className="col-sm-12 col-lg-4">
                                {/* Details */}
                                <SourceDetailsCard source={this.props.source}/>
                                {/* END Details */}
                            </div>
                            <div className="col-sm-12 col-lg-4">
                                {/* Timeline */}
                                <SourceTimelineCard source={this.props.source}/>
                                {/* END Timeline */}
                            </div>
                            <div className="col-sm-12 col-lg-4">
                                {/* Execution Logs */}
                                <SourceExecutionLogs source={this.props.source}/>
                                {/* END Execution Logs */}
                            </div>
                        </div>
                    </div>
                    {/* End First Section */}

                    {/* Source Schema */}
                    <PageHeaderLayout
                        title={'Source Schema'}
                        subTitle={'...Description...'}
                        paths={() => {
                        }}
                    />
                    <div className="content content-boxed">
                        <div className="row">
                            <div className="col-lg-12">
                                {/* Columns */}
                                <SourceColumnsTable source={this.props.source}/>
                                {/* END Columns */}
                            </div>
                        </div>
                    </div>
                    {/* End Source Schema */}

                    {/* ETL Results */}
                    <PageHeaderLayout
                        title={'ETL Results'}
                        subTitle={'Tables generated after running the ETL'}
                        paths={() => {
                        }}
                    />
                    <div className="content content-boxed">
                        <div className="row">
                            <div className="col-lg-12">
                                {/* Success Table Generated Table */}
                                <SourceSuccessTableGenerated source={this.props.source}/>
                                {/* END Success Table Generated Table */}
                            </div>
                            <div className="col-lg-12">
                                {/* Error Table Generated Table */}
                                <SourceErrorTableGenerated source={this.props.source}/>
                                {/* END Error Table Generated Table */}
                            </div>
                        </div>
                    </div>
                    {/* End ETL Results */}
                </PageContainerLayout>
            </>
    )
  }
}

// Set propTypes
SourceDetailsPage.propTypes = {
  source: SourceShape,
  isLoading: PropTypes.bool
}

// Set defaultProps
SourceDetailsPage.defaultProps = {
  // Source
  source: { columns: [] },
  isLoading: true
}

// mapStateToProps
const mapStateToProps = (state) => ({
  source: state.sources.getSourceReducer.data,
  isLoading: state.sources.getSourceReducer.loading
})

// mapStateToProps
const mapDispatchToProps = {
  getSource: sourceOperations.get
}

// Export Class
const _SourceDetailsPage = connect(mapStateToProps, mapDispatchToProps)(SourceDetailsPage)
export { _SourceDetailsPage as SourceDetailsPage }
