import React, { Component } from 'react'
import { Session } from '../../../../state/utils/session'

class JobsHeaderTiles extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }
  }

  render () {
    return (
            <>
                <div className="row">
                    <div className="col-sm-6 col-md-3">
                        <a className="block block-link-hover3 text-center" href="javascript:void(0)">
                            <div className="block-content block-content-full">
                                <div className="h1 font-w700">{this.props.all_jobs}</div>
                            </div>
                            <div className="block-content block-content-full block-content-mini bg-gray-lighter text-muted font-w600">
                                All Jobs
                            </div>
                        </a>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <a className="block block-link-hover3 text-center" href="javascript:void(0)">
                            <div className="block-content block-content-full">
                                <div className="h1 font-w700">&nbsp;</div>
                            </div>
                            <div className="block-content block-content-full block-content-mini bg-gray-lighter text-muted font-w600">
                                &nbsp;
                            </div>
                        </a>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <a className="block block-link-hover3 text-center" href="javascript:void(0)">
                            <div className="block-content block-content-full">
                                <div className="h1 font-w700">&nbsp;</div>
                            </div>
                            <div className="block-content block-content-full block-content-mini bg-gray-lighter text-muted font-w600">
                                &nbsp;
                            </div>
                        </a>
                    </div>
                </div>
            </>
    )
  }
}

// Set defaultProps
JobsHeaderTiles.defaultProps = {
  all_jobs: 0
}

// Export Class
export { JobsHeaderTiles }
