import PropTypes from 'prop-types'

// const { number, shape, string, arrayOf } = PropTypes
const { shape } = PropTypes

const UserShape = shape({
  // Data
})

export { UserShape }
