import { combineReducers } from 'redux'
import * as types from './types'

const fetchAlarmExecutionLogsInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { results: [], pagination_data: {} }
}

const getAlarmExecutionLogInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { columns: [] }
}

const saveAlarmExecutionLogInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

const updateAlarmExecutionLogInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

export function fetchAlarmExecutionLogsReducer (state = fetchAlarmExecutionLogsInitialState, action) {
  switch (action.type) {
    case types.FETCH_ALARM_EXECUTION_LOGS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.FETCH_ALARM_EXECUTION_LOGS_REQUEST_FAILURE:
      return {
        ...fetchAlarmExecutionLogsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.FETCH_ALARM_EXECUTION_LOGS_REQUEST_SUCCESS:
      return {
        ...fetchAlarmExecutionLogsInitialState,
        data: action.payload.data
      }
    case types.FETCH_ALARM_EXECUTION_LOGS_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function getAlarmExecutionLogReducer (state = getAlarmExecutionLogInitialState, action) {
  switch (action.type) {
    case types.GET_ALARM_EXECUTION_LOG_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.GET_ALARM_EXECUTION_LOG_REQUEST_FAILURE:
      return {
        ...getAlarmExecutionLogInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.GET_ALARM_EXECUTION_LOG_REQUEST_SUCCESS:
      return {
        ...getAlarmExecutionLogInitialState,
        data: action.payload.data
      }
    case types.GET_ALARM_EXECUTION_LOG_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function saveAlarmExecutionLogReducer (state = saveAlarmExecutionLogInitialState, action) {
  switch (action.type) {
    case types.SAVE_ALARM_EXECUTION_LOG_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.SAVE_ALARM_EXECUTION_LOG_REQUEST_FAILURE:
      return {
        ...fetchAlarmExecutionLogsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.SAVE_ALARM_EXECUTION_LOG_REQUEST_SUCCESS:
      return {
        ...fetchAlarmExecutionLogsInitialState,
        data: action.payload.data
      }
    case types.SAVE_ALARM_EXECUTION_LOG_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function updateAlarmExecutionLogReducer (state = updateAlarmExecutionLogInitialState, action) {
  switch (action.type) {
    case types.UPDATE_ALARM_EXECUTION_LOG_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.UPDATE_ALARM_EXECUTION_LOG_REQUEST_FAILURE:
      return {
        ...updateAlarmExecutionLogInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.UPDATE_ALARM_EXECUTION_LOG_REQUEST_SUCCESS:
      return {
        ...updateAlarmExecutionLogInitialState,
        data: action.payload.data
      }
    case types.UPDATE_ALARM_EXECUTION_LOG_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export default combineReducers({
  fetchAlarmExecutionLogsReducer,
  saveAlarmExecutionLogReducer,
  getAlarmExecutionLogReducer,
  updateAlarmExecutionLogReducer
})
