import React, { Component } from 'react'
import './styles.scss'
import { HeaderNavbarLayout } from '../header-navbar'
import { FooterLayout } from '../footer'
import { AppUI } from '../../../assets/js/app'

class PageContainerLayout extends Component {
  constructor (props) {
    super(props)

    this.state = {
      hola: ''
    }

    this.initialize = this.initialize.bind(this)
    this.initialize()
  }

  componentDidMount () {
    this.initialize()
    this.eventListener()
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    this.initialize()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    this.initialize()
  }

  eventListener () {
    // const userIsActive = false
    // setInterval(() => {
    //     console.log("setInterval...", new Date());
    //     setTimeout(() => {
    //         console.log("setTimeout...", new Date());
    //         window.$('body').on('click', function() {
    //             userIsActive = true
    //         });
    //         window.$('body').on('scroll', function() {
    //             userIsActive = true
    //         });
    //         window.$(document).keypress(function () {
    //             userIsActive = true;
    //             console.log("keypress....")
    //         });
    //         if(!userIsActive) {
    //             history.push(PATHS.ACCOUNT_LOCKED_PATH);
    //         }
    //         userIsActive = false;
    //     }, 59000); // => 599000
    // }, 60000); // => 600000
  }

  initialize () {
    window.jQuery(function () {
      // Init page helpers (BS Notify Plugin)
      AppUI.init()
      AppUI.initHelpers(['appear', 'appear-countTo', 'notify'])
    })
  }

  render () {
    return (
            <>
                <div id="page-container" className="header-navbar-fixed">
                    {/* <SideOverlayLayout/> */}
                    {/* <SidebarLayout clientSidebar={this.props.clientSidebar}/> */}
                    <HeaderNavbarLayout showNavbarClientOptions={this.props.showNavbarClientOptions}/>
                    {/* Main Container */}
                    <main id="main-container">
                        {/* Page Content */}
                        {this.props.children}
                        {/* END Page Content */}
                    </main>
                    {/* END Main Container */}
                    <FooterLayout/>
                </div>
            </>
    )
  }
}

PageContainerLayout.defaultProps = {
  clientSidebar: false
}

export { PageContainerLayout }
