import React, { Component } from 'react'
import { history } from '../../../../routing/History'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { Session } from '../../../../state/utils/session'

class AutomationSouConAlaCard extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }
    this.goToAutomationEditor = this.goToAutomationEditor.bind(this)
  }

  goToAutomationEditor () {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_AUTOMATION_EDITOR_PATH
        .replace(':automation_id', this.props.automation.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  render () {
    return (
            <>
                {
                    this.props.automation.sources
                      ? <div className="block">
                            <div className="block-header bg-gray-lighter">
                                <ul className="block-options">
                                    <li>
                                        <button type="button" onClick={this.goToAutomationEditor}>
                                            <i className="fa fa-edit"></i>
                                        </button>
                                    </li>
                                </ul>
                                <h3 className="block-title">
                                    <i className="fa fa-info-circle"></i> Sources
                                </h3>
                            </div>
                            <div className="block-content">
                                <div className="p-4">
                                    <ul className="list-group">
                                        {
                                            this.props.automation.sources.map((e, i) => {
                                              return (
                                                    <li className="list-group-item" key={`sources-${i}`}>
                                                        {e.name}
                                                    </li>
                                              )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                      : null
                }
                {
                    this.props.automation.conciliations
                      ? <div className="block">
                            <div className="block-header bg-gray-lighter">
                                <ul className="block-options">
                                    <li>
                                        <button type="button" onClick={this.goToAutomationEditor}>
                                            <i className="fa fa-edit"></i>
                                        </button>
                                    </li>
                                </ul>
                                <h3 className="block-title">
                                    <i className="fa fa-info-circle"></i> Conciliations
                                </h3>
                            </div>
                            <div className="block-content">
                                <div className="p-4">
                                    <ul className="list-group">
                                        {
                                            this.props.automation.conciliations.map((e, i) => {
                                              return (
                                                    <li className="list-group-item" key={`conciliations-${i}`}>
                                                        {e.name}
                                                    </li>
                                              )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                      : null
                }
                {
                    this.props.automation.alarms
                      ? <div className="block">
                            <div className="block-header bg-gray-lighter">
                                <ul className="block-options">
                                    <li>
                                        <button type="button" onClick={this.goToAutomationEditor}>
                                            <i className="fa fa-edit"></i>
                                        </button>
                                    </li>
                                </ul>
                                <h3 className="block-title">
                                    <i className="fa fa-info-circle"></i> Alarms
                                </h3>
                            </div>
                            <div className="block-content">
                                <div className="p-4">
                                    <ul className="list-group">
                                        {
                                            this.props.automation.alarms.map((e, i) => {
                                              return (
                                                    <li className="list-group-item" key={`alarms-${i}`}>
                                                        {e.name}
                                                    </li>
                                              )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                      : null
                }
                {
                    this.props.automation.worker_calls
                      ? <div className="block">
                            <div className="block-header bg-gray-lighter">
                                <ul className="block-options">
                                    <li>
                                        <button type="button" onClick={this.goToAutomationEditor}>
                                            <i className="fa fa-edit"></i>
                                        </button>
                                    </li>
                                </ul>
                                <h3 className="block-title">
                                    <i className="fa fa-info-circle"></i> Worker Calls
                                </h3>
                            </div>
                            <div className="block-content">
                                <div className="p-4">
                                    <ul className="list-group">
                                        {
                                            this.props.automation.worker_calls.map((e, i) => {
                                              return (
                                                    <li className="list-group-item" key={`worker_calls-${i}`}>
                                                        {e.name}
                                                    </li>
                                              )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                      : null
                }
            </>
    )
  }
}

// Set defaultProps
AutomationSouConAlaCard.defaultProps = {
  automation: {}
}

// Export Class
export { AutomationSouConAlaCard }
