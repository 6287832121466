import React from 'react'
import { history } from '../../../../routing/History'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { Session } from '../../../../state/utils/session'

export function WorkerCallsHeaderTiles (props) {
  const session = new Session()

  const goToWorkerCallsCreator = () => {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_WORKER_CALLS_CREATOR_PATH.replace(
        ':client_id',
        session.getClientSession()
      )
    )
  }

  return (
    <>
      <div className="row">
        <div className="col-sm-6 col-md-3">
          <a
            className="block block-link-hover3 text-center"
            href="javascript:void(0)"
            onClick={goToWorkerCallsCreator}
          >
            <div className="block-content block-content-full">
              <div className="h1 font-w700 text-success">
                <i className="fa fa-plus"></i>
              </div>
            </div>
            <div className="block-content block-content-full block-content-mini bg-gray-lighter text-success font-w600">
              Add New Worker Call
            </div>
          </a>
        </div>
        <div className="col-sm-6 col-md-3">
          <a
            className="block block-link-hover3 text-center"
            href="javascript:void(0)"
          >
            <div className="block-content block-content-full">
              <div className="h1 font-w700">{props.all_worker_calls}</div>
            </div>
            <div className="block-content block-content-full block-content-mini bg-gray-lighter text-muted font-w600">
              All worker calls
            </div>
          </a>
        </div>
        <div className="col-sm-6 col-md-3">
          <a
            className="block block-link-hover3 text-center"
            href="javascript:void(0)"
          >
            <div className="block-content block-content-full">
              <div className="h1 font-w700">
              &nbsp;
              </div>
            </div>
            <div className="block-content block-content-full block-content-mini bg-gray-lighter text-muted font-w600">
            &nbsp;
            </div>
          </a>
        </div>
        <div className="col-sm-6 col-md-3">
          <a
            className="block block-link-hover3 text-center"
            href="javascript:void(0)"
          >
            <div className="block-content block-content-full">
              <div className="h1 font-w700">&nbsp;</div>
            </div>
            <div className="block-content block-content-full block-content-mini bg-gray-lighter text-muted font-w600">
              &nbsp;
            </div>
          </a>
        </div>
      </div>
    </>
  )
}

WorkerCallsHeaderTiles.defaultProps = {
  all_worker_calls: 0
}
