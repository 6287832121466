import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { PageContainerLayout, PageHeaderLayout } from '../../../layouts'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { Session } from '../../../../state/utils/session'
import { WorkerCallWizardForm } from '../../containers/worker-call-wizard-form'
import { WorkerCallShape } from '../../../../prop-types/worker-calls'
import { workerCallsOperations } from '../../../../state/ducks/worker-calls'
import { connect } from 'react-redux'

function WorkerCallEditorPage (props) {
  const session = new Session()

  useEffect(() => {
    props.getWorkerCall(props.match.params.worker_call_id)
  }, [])

  const renderHeaderPaths = () => (
    <>
      <li>
        <NavLink
          className="link-effect"
          to={ROUTING_PATHS.CLIENT_DASHBOARD_WORKER_CALLS_PATH.replace(
            ':client_id',
            session.getClientSession()
          )}
        >
          Worker Calls
        </NavLink>
      </li>
    </>
  )

  return (
    <>
      <PageContainerLayout clientSidebar={true} showNavbarClientOptions={true}>
        <PageHeaderLayout
          title={props.workerCall.name}
          subTitle={'Edit Worker Call'}
          paths={renderHeaderPaths}
        />
        <WorkerCallWizardForm workerCall={props.workerCall} isEditor={true} />
      </PageContainerLayout>
    </>
  )
}

WorkerCallEditorPage.propTypes = {
  workerCall: WorkerCallShape
}

WorkerCallEditorPage.defaultProps = {
  workerCall: {}
}

const mapStateToProps = (state) => ({
  workerCall: state.workerCalls.getWorkerCallReducer.data,
  isLoading: state.workerCalls.getWorkerCallReducer.loading,
  getWorkerCallErrorResponse: state.workerCalls.getWorkerCallReducer.error_data
})

const mapDispatchToProps = {
  getWorkerCall: workerCallsOperations.get
}

const _WorkerCallEditorPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkerCallEditorPage)

export { _WorkerCallEditorPage as WorkerCallEditorPage }
