import reducer from './reducers'

import * as accountingEntriesOperations from './operations'
import * as accountingEntriesEndpoints from './paths'

export {
  accountingEntriesOperations,
  accountingEntriesEndpoints
}

export default reducer
