import { combineReducers } from 'redux'
import * as types from './types'

const fetchClientsInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { results: [], pagination_data: {} }
}

const getClientInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

const saveClientInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

const updateClientInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

const permissionsClientInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

export function fetchClientsReducer (state = fetchClientsInitialState, action) {
  switch (action.type) {
    case types.FETCH_CLIENTS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.FETCH_CLIENTS_REQUEST_FAILURE:
      return {
        ...fetchClientsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.FETCH_CLIENTS_REQUEST_SUCCESS:
      return {
        ...fetchClientsInitialState,
        data: action.payload.data
      }
    case types.FETCH_CLIENTS_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function getClientReducer (state = getClientInitialState, action) {
  switch (action.type) {
    case types.GET_CLIENT_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.GET_CLIENT_REQUEST_FAILURE:
      return {
        ...getClientInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.GET_CLIENT_REQUEST_SUCCESS:
      return {
        ...getClientInitialState,
        data: action.payload.data
      }
    case types.GET_CLIENT_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function saveClientReducer (state = saveClientInitialState, action) {
  switch (action.type) {
    case types.SAVE_CLIENT_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.SAVE_CLIENT_REQUEST_FAILURE:
      return {
        ...fetchClientsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.SAVE_CLIENT_REQUEST_SUCCESS:
      return {
        ...fetchClientsInitialState,
        data: action.payload.data
      }
    case types.SAVE_CLIENT_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function updateClientReducer (state = updateClientInitialState, action) {
  switch (action.type) {
    case types.UPDATE_CLIENT_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.UPDATE_CLIENT_REQUEST_FAILURE:
      return {
        ...updateClientInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.UPDATE_CLIENT_REQUEST_SUCCESS:
      return {
        ...updateClientInitialState,
        data: action.payload.data
      }
    case types.UPDATE_CLIENT_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function permissionsClientReducer (state = permissionsClientInitialState, action) {
  switch (action.type) {
    case types.PERMISSIONS_CLIENT_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.PERMISSIONS_CLIENT_REQUEST_FAILURE :
      return {
        ...permissionsClientInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.PERMISSIONS_CLIENT_REQUEST_SUCCESS:
      return {
        ...permissionsClientInitialState,
        data: action.payload.data
      }
    case types.PERMISSIONS_CLIENT_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export default combineReducers({
  fetchClientsReducer,
  saveClientReducer,
  getClientReducer,
  updateClientReducer,
  permissionsClientReducer
})
