import React, { Component } from 'react'
import { groupOperations } from '../../../../state/ducks/groups'
import { connect } from 'react-redux'
import { BaseFormWizard } from '../../../../assets/js/base_form_wizard'
import { AppUI } from '../../../../assets/js/app'
import { GroupDataForm } from '../group-data-form'
import { GroupPermissionsForm } from '../group-permissions-form'
import { GroupUsersForm } from '../group-users-form'

class GroupWizardForm extends Component {
  constructor (props) {
    super(props)

    // state
    this.state = {
      group: {
        permissions: [],
        users: []
      }
    }

    // handlers
    this.onGroupDataChange = this.onGroupDataChange.bind(this)
    this.onGroupPermissionsChange = this.onGroupPermissionsChange.bind(this)
    this.onGroupUsersChange = this.onGroupUsersChange.bind(this)
    this.sendData = this.sendData.bind(this)
    AppUI.initHelpers(['highlightjs'])
  }

  componentDidMount () {
    BaseFormWizard.init()
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.group && 'id' in nextProps.group) {
      this.setState({ group: nextProps.group })
    }
  }

  onGroupDataChange (name, value) {
    const group = this.state.group
    group[name] = value
    this.setState({
      group
    })
  }

  onGroupPermissionsChange (permissions) {
    const group = this.state.group
    group.permissions = JSON.parse(JSON.stringify(permissions))
    this.setState({
      group: JSON.parse(JSON.stringify(group))
    })
  }

  onGroupUsersChange (users) {
    const group = this.state.group
    group.users = JSON.parse(JSON.stringify(users))
    this.setState({
      group: JSON.parse(JSON.stringify(group))
    })
  }

  sendData () {
    if (!this.props.isEditor) {
      this.props.saveGroup(this.state.group)
    } else {
      this.props.updateGroup(this.state.group.id, this.state.group)
    }
  }

  render () {
    return (
            <>
                <div className="content content-boxed">
                    <div className={' block block-themed block-opt-refresh-icon7 ' + (this.props.isLoading ? ' block-opt-refresh ' : '')}>
                        <div className="block-content">
                            {/* Simple Progress Wizard (.js-wizard-simple class is initialized in js/pages/base_forms_wizard.js) */}
                            {/* For more examples you can check out http://vadimg.com/twitter-bootstrap-wizard-example/ */}
                            <div className="js-wizard-simple block">
                                {/* Steps Progress */}
                                <div className="block-content block-content-mini block-content-full border-b">
                                    <div className="wizard-progress progress active remove-margin-b">
                                        <div className="progress-bar progress-bar-primary progress-bar-striped"
                                             role="progressbar" aria-valuenow="100" aria-valuemin="0"
                                             aria-valuemax="100"
                                             style={{ width: '0' }}>
                                        </div>
                                    </div>
                                </div>
                                {/* END Steps Progress */}

                                {/* Step Tabs */}
                                <ul className="nav nav-tabs nav-tabs-alt nav-justified">
                                    <li className="active">
                                        <a href="#simple-progress-step1" data-toggle="tab">1. Group Data</a>
                                    </li>
                                    <li>
                                        <a href="#simple-progress-step2" data-toggle="tab">2. Permissions</a>
                                    </li>
                                    <li>
                                        <a href="#simple-progress-step3" data-toggle="tab">3. Users</a>
                                    </li>
                                </ul>
                                {/* END Step Tabs */}

                                {/* Form */}
                                <form className="form-horizontal" action="javascript:void(0)">
                                    {/* Steps Content */}
                                    <div className="block-content tab-content" style={{ minHeight: '300px' }}>
                                        {/* Step 1 */}
                                        <div className="tab-pane fade fade-right in push-30-t push-50 active"
                                             id="simple-progress-step1">
                                            <GroupDataForm
                                                group={this.state.group}
                                                onChange={this.onGroupDataChange}
                                            />
                                        </div>
                                        {/* END Step 1 */}

                                        {/* Step 2 */}
                                        <div className="tab-pane fade fade-right in push-30-t push-50"
                                             id="simple-progress-step2">
                                            <GroupPermissionsForm
                                                added_permissions={this.state.group.permissions}
                                                onChange={this.onGroupPermissionsChange}
                                            />
                                        </div>
                                        {/* END Step 2 */}

                                        {/* Step 3 */}
                                        <div className="tab-pane fade fade-right in push-30-t push-50"
                                             id="simple-progress-step3">
                                            <GroupUsersForm
                                                added_users={this.state.group.users}
                                                onChange={this.onGroupUsersChange}
                                            />
                                        </div>
                                        {/* END Step 3 */}

                                    </div>
                                    {/* END Steps Content */}

                                    {/* Steps Navigation */}
                                    <div className="block-content block-content-mini block-content-full border-t">
                                        <div className="row">
                                            {
                                                this.props.saveGroupErrorResponse.error
                                                  ? <div className="col-xs-12 text-danger text-center">
                                                        {JSON.stringify(this.props.saveGroupErrorResponse.error)}
                                                    </div>
                                                  : null
                                            }
                                            {
                                                this.props.updateGroupErrorResponse.error
                                                  ? <div className="col-xs-12 text-danger text-center">
                                                        {JSON.stringify(this.props.updateGroupErrorResponse.error)}
                                                    </div>
                                                  : null
                                            }
                                            <div className="col-xs-6">
                                                <button
                                                    className="wizard-prev btn btn-warning"
                                                    type="button"
                                                >
                                                    <i className="fa fa-arrow-circle-o-left"></i> Previous
                                                </button>
                                            </div>
                                            <div className="col-xs-6 text-right">
                                                <button
                                                    className="wizard-next btn btn-success"
                                                    type="button"
                                                >
                                                    Next <i className="fa fa-arrow-circle-o-right"></i>
                                                </button>
                                                <button
                                                    className="wizard-finish btn btn-primary"
                                                    type="button"
                                                    disabled={this.props.isLoading}
                                                    onClick={this.sendData}
                                                >
                                                    {
                                                        this.props.isLoading
                                                          ? <>
                                                                <i className="ml-1 fa fa-spinner fa-spin pull-right"></i>
                                                                <span>Submit</span>
                                                            </>
                                                          : <>
                                                                <i className="ml-1 si si-login pull-right"></i>
                                                                <span>Submit</span>
                                                            </>
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* END Steps Navigation */}
                                </form>
                                {/* END Form */}
                            </div>
                            {/* END Simple Progress Wizard */}
                            {/* <pre className="pre-sh push"> */}
                            {/*    <code className="json"> */}
                            {/*    {JSON.stringify(this.state.group)} */}
                            {/*    </code> */}
                            {/* </pre> */}
                        </div>
                    </div>
                </div>
            </>
    )
  }
}

// Set propTypes
GroupWizardForm.propTypes = {
  group: {},
  saveGroupErrorResponse: {},
  updateGroupErrorResponse: {},
  isEditor: false
}

// mapStateToProps
const mapStateToProps = (state) => ({
  isLoading: state.groups.saveGroupReducer.loading || state.groups.updateGroupReducer.loading,
  saveGroupErrorResponse: state.groups.saveGroupReducer.error_data,
  updateGroupErrorResponse: state.groups.updateGroupReducer.error_data
})

// mapStateToProps
const mapDispatchToProps = {
  saveGroup: groupOperations.save,
  updateGroup: groupOperations.update
}

// Export Class
const _GroupWizardForm = connect(mapStateToProps, mapDispatchToProps)(GroupWizardForm)
export { _GroupWizardForm as GroupWizardForm }
