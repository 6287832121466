import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDynamicParametries } from '../../../../state/ducks/dynamic-parametries'
import { PageContainerLayout, PageHeaderLayout } from '../../../layouts'
import { DynamicParametriesTable } from '../../containers/dynamic-parametries-table'

export function InconsistenciesPage () {
  const dispatch = useDispatch()
  const { dynamicParametries = {} } = useSelector((state) => state)
  const { parametries = {} } = dynamicParametries
  const {
    data: { results }
  } = parametries

  useEffect(() => {
    dispatch(getDynamicParametries())
  }, [dispatch])

  return (
    <>
      <PageContainerLayout clientSidebar={true} showNavbarClientOptions={true}>
        <PageHeaderLayout
          title="Inconsistencies"
          subTitle="All inconsistencies"
          paths={() => (
            <>
              <li>Inconsistencies</li>
            </>
          )}
        />
        <div className="content content-boxed">
          <div className="block-content">
            <DynamicParametriesTable data={results} />
          </div>
        </div>
      </PageContainerLayout>
    </>
  )
}
