// Handle Fetch Errors
import { toast } from 'react-toastify'
import { Session } from './session'

const listMessages = [
  {
    error: 'Authentication credentials were not provided.',
    text: 'La sesión ha finalizado, inicia sesión nuevamente'
  },
  {
    error: 'Signature has expired.',
    text: 'La sesión ha finalizado, inicia sesión nuevamente'
  }
]

export function handleApiErrors (dispatch, type, data) {
  // console.log(data?.data?.api_error?.response.data.error)
  if (data?.data?.api_error?.response?.status === 401) {
    const message = listMessages.find(item => item.error === data?.data?.api_error?.response.data.error) || { text: data?.data?.api_error?.response.data.error }
    toast.warn(message.text, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: message.text
    })
    const session = new Session()
    session.removeSession()
  }
  return dispatch({ type: `${type}_FAILURE`, payload: data })
}

// Handle Fetch Response
export function handleApiResponseSuccess (dispatch, type, data) {
  return dispatch({ type: `${type}_SUCCESS`, payload: data })
}

// Handle Fetch Response
export function handleApiResponseFailure (dispatch, type, data) {
  if (data.data.error === 'Signature has expired.') {
    const message = 'La sesión ha finalizado, inicia sesión nuevamente.'
    toast.warn(message, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      toastId: message
    })
    const session = new Session()
    session.removeSession()
  }
  return dispatch({ type: `${type}_FAILURE`, payload: data })
}
