import reducer from './reducers'

import * as automationOperations from './operations'
import * as automationEndpoints from './paths'

export {
  automationOperations,
  automationEndpoints
}

export default reducer
