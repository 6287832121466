import reducer from './reducers'

import * as sourceExecutionLogOperations from './operations'
import * as sourceExecutionLogEndpoints from './paths'

export {
  sourceExecutionLogOperations,
  sourceExecutionLogEndpoints
}

export default reducer
