// SOURCES
export const FETCH_SOURCES_REQUEST = 'session/FETCH_SOURCES_REQUEST'
export const FETCH_SOURCES_REQUEST_SUCCESS = 'session/FETCH_SOURCES_REQUEST_SUCCESS'
export const FETCH_SOURCES_REQUEST_FAILURE = 'session/FETCH_SOURCES_REQUEST_FAILURE'
export const FETCH_SOURCES_REQUEST_COMPLETED = 'session/FETCH_SOURCES_REQUEST_COMPLETED'

export const GET_SOURCE_REQUEST = 'session/GET_SOURCE_REQUEST'
export const GET_SOURCE_REQUEST_SUCCESS = 'session/GET_SOURCE_REQUEST_SUCCESS'
export const GET_SOURCE_REQUEST_FAILURE = 'session/GET_SOURCE_REQUEST_FAILURE'
export const GET_SOURCE_REQUEST_COMPLETED = 'session/GET_SOURCE_REQUEST_COMPLETED'

export const SAVE_SOURCE_REQUEST = 'session/SAVE_SOURCE_REQUEST'
export const SAVE_SOURCE_REQUEST_SUCCESS = 'session/SAVE_SOURCE_REQUEST_SUCCESS'
export const SAVE_SOURCE_REQUEST_FAILURE = 'session/SAVE_SOURCE_REQUEST_FAILURE'
export const SAVE_SOURCE_REQUEST_COMPLETED = 'session/SAVE_SOURCE_REQUEST_COMPLETED'

export const UPDATE_SOURCE_REQUEST = 'session/UPDATE_SOURCE_REQUEST'
export const UPDATE_SOURCE_REQUEST_SUCCESS = 'session/UPDATE_SOURCE_REQUEST_SUCCESS'
export const UPDATE_SOURCE_REQUEST_FAILURE = 'session/UPDATE_SOURCE_REQUEST_FAILURE'
export const UPDATE_SOURCE_REQUEST_COMPLETED = 'session/UPDATE_SOURCE_REQUEST_COMPLETED'

export const DELETE_SOURCE_REQUEST = 'session/DELETE_SOURCE_REQUEST'
export const DELETE_SOURCE_REQUEST_SUCCESS = 'session/DELETE_SOURCE_REQUEST_SUCCESS'
export const DELETE_SOURCE_REQUEST_FAILURE = 'session/DELETE_SOURCE_REQUEST_FAILURE'
export const DELETE_SOURCE_REQUEST_COMPLETED = 'session/DELETE_SOURCE_REQUEST_COMPLETED'

export const SUCCESS_TABLE_GENERATED_REQUEST = 'session/SUCCESS_TABLE_GENERATED_REQUEST'
export const SUCCESS_TABLE_GENERATED_REQUEST_SUCCESS = 'session/SUCCESS_TABLE_GENERATED_REQUEST_SUCCESS'
export const SUCCESS_TABLE_GENERATED_REQUEST_FAILURE = 'session/SUCCESS_TABLE_GENERATED_REQUEST_FAILURE'
export const SUCCESS_TABLE_GENERATED_REQUEST_COMPLETED = 'session/SUCCESS_TABLE_GENERATED_REQUEST_COMPLETED'

export const ERROR_TABLE_GENERATED_REQUEST = 'session/ERROR_TABLE_GENERATED_REQUEST'
export const ERROR_TABLE_GENERATED_REQUEST_SUCCESS = 'session/ERROR_TABLE_GENERATED_REQUEST_SUCCESS'
export const ERROR_TABLE_GENERATED_REQUEST_FAILURE = 'session/ERROR_TABLE_GENERATED_REQUEST_FAILURE'
export const ERROR_TABLE_GENERATED_REQUEST_COMPLETED = 'session/ERROR_TABLE_GENERATED_REQUEST_COMPLETED'
