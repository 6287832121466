import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { SourceShape } from '../../../../prop-types'
import { sourceOperations } from '../../../../state/ducks/sources'
import { connect } from 'react-redux'
import { BaseFormWizard } from '../../../../assets/js/base_form_wizard'
import { SourceDataForm } from '../source-data-form'
import { SourceColumnsForm } from '../source-columns-form'
import { SourceUniquenessTable } from '../source-uniqueness-table'
import { AppUI } from '../../../../assets/js/app'
import { Session } from '../../../../state/utils/session'

class SourceWizardForm extends Component {
  constructor (props) {
    super(props)

    // state
    this.state = {
      session: new Session(),
      source: { batch_order: 1, parameters: {}, columns: [] }
    }

    // handlers
    this.onSourceDataChange = this.onSourceDataChange.bind(this)
    this.sendData = this.sendData.bind(this)
    AppUI.initHelpers(['highlightjs'])
  }

  componentDidMount () {
    BaseFormWizard.init()
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.source && 'id' in nextProps.source) {
      this.setState({ source: nextProps.source })
    }
  }

  onSourceDataChange (name, value) {
    const source = JSON.parse(JSON.stringify(this.state.source))
    source[name] = value
    this.setState({
      source
    })
  }

  sendData () {
    console.log('sendData:', this.state.source)
    if (!this.props.isEditor) {
      this.props.saveSource(this.dataCleaner(this.state.source))
    } else {
      this.props.updateSource(this.state.source.id, this.dataCleaner(this.state.source))
    }
  }

  dataCleaner (data) {
    const newObj = JSON.parse(JSON.stringify(data))
    newObj.columns.forEach((column) => {
      console.log('column:', column.index)
      // Length >= 1 or Null
      if (column.length === '') {
        column.length = null
      }
      // Length Boolean
      if (column.index === '' || column.index === 'false') {
        column.index = false
      }
      if (column.index === 'true') {
        column.index = true
      }
      // OverWrite Boolean
      column.normalizations.forEach((n) => {
        if (n.overwrite === '' || n.overwrite === 'false') {
          n.overwrite = false
        }
        if (n.overwrite === 'true') {
          n.overwrite = true
        }
      })
    })
    return newObj
  }

  render () {
    return (
            <>
                <div className="content content-boxed">
                    <div className={' block block-themed block-opt-refresh-icon7 ' + (this.props.isLoading ? ' block-opt-refresh ' : '')}>
                        <div className="block-content">
                            {/* Simple Progress Wizard (.js-wizard-simple class is initialized in js/pages/base_forms_wizard.js) */}
                            {/* For more examples you can check out http://vadimg.com/twitter-bootstrap-wizard-example/ */}
                            <div className="js-wizard-simple block">
                                {/* Steps Progress */}
                                <div className="block-content block-content-mini block-content-full border-b">
                                    <div className="wizard-progress progress active remove-margin-b">
                                        <div className="progress-bar progress-bar-primary progress-bar-striped"
                                             role="progressbar" aria-valuenow="100" aria-valuemin="0"
                                             aria-valuemax="100"
                                             style={{ width: '0' }}>
                                        </div>
                                    </div>
                                </div>
                                {/* END Steps Progress */}

                                {/* Step Tabs */}
                                <ul className="nav nav-tabs nav-tabs-alt nav-justified">
                                    <li className="active">
                                        <a href="#simple-progress-step1" data-toggle="tab">1. Source Data</a>
                                    </li>
                                    <li>
                                        <a href="#simple-progress-step2" data-toggle="tab">2. Columns and
                                            Normalizations</a>
                                    </li>
                                    <li>
                                        <a href="#simple-progress-step3" data-toggle="tab">3. Uniqueness</a>
                                    </li>
                                </ul>
                                {/* END Step Tabs */}

                                {/* Form */}
                                <form className="form-horizontal" action="javascript:void(0)">
                                    {/* Steps Content */}
                                    <div className="block-content tab-content" style={{ minHeight: '300px' }}>
                                        {/* Step 1 */}
                                        <div className="tab-pane fade fade-right in push-30-t push-50 active"
                                             id="simple-progress-step1">
                                            <SourceDataForm
                                                source={this.state.source}
                                                onChange={this.onSourceDataChange}
                                            />
                                        </div>
                                        {/* END Step 1 */}

                                        {/* Step 2 */}
                                        <div className="tab-pane fade fade-right in push-30-t push-50"
                                             id="simple-progress-step2">
                                            <SourceColumnsForm
                                                source={this.state.source}
                                                onChange={this.onSourceDataChange}
                                            />
                                        </div>
                                        {/* END Step 2 */}

                                        {/* Step 3 */}
                                        <div className="tab-pane fade fade-right in push-30-t push-50"
                                             id="simple-progress-step3">
                                            {
                                                this.state.source.id
                                                  ? <SourceUniquenessTable
                                                        source={this.state.source}
                                                        onChange={this.onSourceDataChange}
                                                    />
                                                  : <div className="text-center">
                                                        <span>You must create the source first.</span>
                                                    </div>
                                            }
                                        </div>
                                        {/* END Step 3 */}

                                    </div>
                                    {/* END Steps Content */}

                                    {/* Steps Navigation */}
                                    <div className="block-content block-content-mini block-content-full border-t">
                                        <div className="row">
                                            {
                                                this.props.saveSourceErrorResponse.error
                                                  ? <div className="col-xs-12 text-danger text-center">
                                                        {JSON.stringify(this.props.saveSourceErrorResponse.error)}
                                                    </div>
                                                  : null
                                            }
                                            {
                                                this.props.updateSourceErrorResponse.error
                                                  ? <div className="col-xs-12 text-danger text-center">
                                                        {JSON.stringify(this.props.updateSourceErrorResponse.error)}
                                                    </div>
                                                  : null
                                            }
                                            <div className="col-xs-6">
                                                <button
                                                    className="wizard-prev btn btn-warning"
                                                    type="button"
                                                >
                                                    <i className="fa fa-arrow-circle-o-left"></i> Previous
                                                </button>
                                            </div>
                                            <div className="col-xs-6 text-right">
                                                <button
                                                    className="wizard-next btn btn-success"
                                                    type="button"
                                                >
                                                    Next <i className="fa fa-arrow-circle-o-right"></i>
                                                </button>
                                                <button
                                                    className="wizard-finish btn btn-primary"
                                                    type="button"
                                                    disabled={this.props.isLoading}
                                                    onClick={this.sendData}
                                                >
                                                    {
                                                        this.props.isLoading
                                                          ? <>
                                                                <i className="ml-1 fa fa-spinner fa-spin pull-right"></i>
                                                                <span>Submit</span>
                                                            </>
                                                          : <>
                                                                <i className="ml-1 si si-login pull-right"></i>
                                                                <span>Submit</span>
                                                            </>
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* END Steps Navigation */}
                                </form>
                                {/* END Form */}
                            </div>
                            {/* END Simple Progress Wizard */}
                            {/* <pre className="pre-sh push"> */}
                            {/*    <code className="json"> */}
                            {/*    {jsonPrettify(this.state.source.uniqueness)} */}
                            {/*    </code> */}
                            {/* </pre> */}
                        </div>
                    </div>
                </div>

            </>
    )
  }
}

// Set propTypes
SourceWizardForm.propTypes = {
  source: SourceShape,
  saveSourceErrorResponse: PropTypes.object,
  updateSourceErrorResponse: PropTypes.object,
  isEditor: PropTypes.bool
}

// Set defaultProps
SourceWizardForm.defaultProps = {
  source: { parameters: {}, columns: [] },
  saveSourceErrorResponse: {},
  updateSourceErrorResponse: {},
  isEditor: false
}

// mapStateToProps
const mapStateToProps = (state) => ({
  isLoading: state.sources.saveSourceReducer.loading || state.sources.updateSourceReducer.loading,
  saveSourceErrorResponse: state.sources.saveSourceReducer.error_data,
  updateSourceErrorResponse: state.sources.updateSourceReducer.error_data
})

// mapStateToProps
const mapDispatchToProps = {
  saveSource: sourceOperations.save,
  updateSource: sourceOperations.update
}

// Export Class
const _SourceWizardForm = connect(mapStateToProps, mapDispatchToProps)(SourceWizardForm)
export { _SourceWizardForm as SourceWizardForm }
