export const siteOptions = [
  { value: 'MLA', label: 'Argentina' },
  { value: 'MLB', label: 'Brasil' },
  { value: 'MCO', label: 'Colombia' },
  { value: 'MLC', label: 'Chile' },
  { value: 'MLM', label: 'México' },
  { value: 'MPE', label: 'Perú' },
  { value: 'MLU', label: 'Uruguay' }
]
export const types = [
  { value: 'accounting-translator', label: 'Accounting Translator' },
  { value: 'conciliator', label: 'Conciliator' },
  { value: 'etl', label: 'Etl' },
  { value: 'file-deleting', label: 'File Deleting' }
]
export const statuses = [
  { value: 'created', label: 'Created' },
  { value: 'running', label: 'Running' },
  { value: 'succeeded', label: 'Succeeded' }
]
