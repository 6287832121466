import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { LastExecutionLogsListLayout } from '../../../layouts/last-execution-logs-list'
import { sourceExecutionLogOperations } from '../../../../state/ducks/source-execution-logs'

class SourceExecutionLogs extends Component {
  constructor (props) {
    super(props)

    // state
    this.state = {}

    this.fetchExecutionLogs = this.fetchExecutionLogs.bind(this)
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.source.id !== this.props.source.id) {
      this.props.fetchExecutionLogs({})
    }
  }

  fetchExecutionLogs () {
    this.props.fetchExecutionLogs({})
  }

  render () {
    return (
            <>
                <LastExecutionLogsListLayout
                    title={''}
                    model={'source'}
                    fetch={this.fetchExecutionLogs}
                    execution_logs={this.props.executionLogs}
                    isLoadingSource={this.props.isLoading}
                />
            </>
    )
  }
}

// Set propTypes
SourceExecutionLogs.propTypes = {
  source: PropTypes.object.isRequired,
  fetchExecutionLogs: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
}

// Set defaultProps
SourceExecutionLogs.defaultProps = {
  source: {},
  isLoading: true
}

// mapStateToProps
const mapStateToProps = (state) => ({
  executionLogs: state.sourceExecutionLogs.fetchSourceExecutionLogsReducer.data.results,
  isLoading: state.sourceExecutionLogs.fetchSourceExecutionLogsReducer.loading
})

// mapStateToProps
const mapDispatchToProps = {
  fetchExecutionLogs: sourceExecutionLogOperations.list
}

// Export Class
const _SourceExecutionLogs = connect(mapStateToProps,
  mapDispatchToProps)(SourceExecutionLogs)
export { _SourceExecutionLogs as SourceExecutionLogs }
