import React from 'react'
import ReactDOM from 'react-dom'
import config from './config'
// import * as Sentry from '@sentry/browser';
import * as serviceWorker from './serviceWorker'
import { Routes } from './routing/Routes'
import { Provider } from 'react-redux'
import Zendesk, { ZendeskAPI } from 'react-zendesk'
import './assets/css/style.scss'

import configureStore from './state'
import { ButtonDocumentation } from './components/layouts'

const reduxStore = configureStore(window.REDUX_INITIAL_DATA)

// Sentry.init({dsn: config.sentryDSN, environment: config.sentryEnvironment});

// const zendeskConfig = {
//   webWidget: {
//     contactForm: {
//       fields: [
//         { id: 360031564114, prefill: { '*': 'simetrik' } }
//       ]
//     }
//   }
// }

ReactDOM.render(
    <Provider store={reduxStore}>
        <Routes/>
        {config.appEnviroment === 'meli2' && <ButtonDocumentation /> }
        <Zendesk
          zendeskKey={config.zendeskKey}
          onLoaded={() => {
            if (config.appEnviroment !== 'meli' && config.appEnviroment !== 'meli2') {
              ZendeskAPI('webWidget', 'show')
            } else {
              ZendeskAPI('webWidget', 'hide')
            }
          }}/>
    </Provider>,
    document.getElementById('root')
)

serviceWorker.unregister()
