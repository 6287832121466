import React, { Component } from 'react'

class NotFoundPage extends Component {
  render () {
    return (
            <>
                {/* Error Content */}
                <div className="content bg-white text-center pulldown overflow-hidden">
                    <div className="row">
                        <div className="col-sm-6 col-sm-offset-3">
                            {/* Error Titles */}
                            <h1 className="font-s128 font-w300 text-city animated flipInX">404</h1>
                            <h2 className="h3 font-w300 push-50 animated fadeInUp">We are sorry but the page you are looking
                                for was not found..</h2>
                            {/* END Error Titles */}
                        </div>
                    </div>
                </div>
                {/* END Error Content */}

                {/* Error Footer */}
                <div className="content pulldown text-muted text-center">
                    Would you like to let us know about it?<br/>
                    <a className="link-effect" href="javascript:void(0)">Report it</a> or <a className="link-effect"
                                                                                         href="javascript:void(0)" onClick={(e) => {
                                                                                           e.preventDefault()
                                                                                           window.history.back()
                                                                                         }}>Go
                    Back to Dashboard</a>
                </div>
                {/* END Error Footer */}
            </>
    )
  };
}

export { NotFoundPage }
