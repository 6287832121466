import React from 'react'
import './modal.styles.scss'

export const Modal = ({ handleClose, show, handleSubmit, loading }) => {
  const showHideClassName = show ? 'modal display-block' : 'modal display-none'
  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <p className="m-top">¿Seguro que deseas desbloquear el usuario?</p>
        <p className="m-bottom">
          Verifica que no exista un cluster levantado en ECS para confirmar.
        </p>
        {!loading && (
          <div className="btn-container">
            <button className="btn cancel" onClick={handleClose}>
              Cancelar
            </button>
            <button className="btn success" onClick={handleSubmit}>
              Confirmar
            </button>
          </div>
        )}
      </section>
    </div>
  )
}
