import React from 'react'
import { SourceUniquenessModalForm } from '../source-uniqueness-modal-form'

class SourceUniquenessModal extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      uniqueness: {}
    }

    this.onUniquenessChange = this.onUniquenessChange.bind(this)
    this.onChange = this.onChange.bind(this)
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    this.setState({
      uniqueness: nextProps.uniqueness
    })
  }

  onUniquenessChange (uniqueness) {
    this.setState({
      uniqueness
    })
  }

  onChange () {
    this.props.onChange(this.props.action, this.state.uniqueness)
  }

  render () {
    return (
      <>
        {/* MODAL */}
        <div
          className="modal"
          id="uniqueness-modal-form"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-md">
            <div className="modal-content">
              <div className=" block block-themed block-opt-refresh-icon7 block-transparent remove-margin-b">
                <div className="block-header bg-primary-dark">
                  <ul className="block-options">
                    <li>
                      <button data-dismiss="modal" type="button">
                        <i className="si si-close"></i>
                      </button>
                    </li>
                  </ul>
                  <h3 className="block-title">
                    {this.props.uniqueness.id || this.props.uniqueness.name ? (
                      <span>Edit Uniqueness: {this.props.uniqueness.name}</span>
                    ) : (
                      <span>New Uniqueness</span>
                    )}
                  </h3>
                </div>
                <div className="block-content">
                  <SourceUniquenessModalForm
                    source_id={this.props.source_id}
                    uniqueness={this.state.uniqueness}
                    onChange={this.onUniquenessChange}
                  />
                  {/* <pre className="pre-sh push"> */}
                  {/*    MODAL */}
                  {/*    <code className="json"> */}
                  {/*        state */}
                  {/*        {jsonPrettify(this.state.uniqueness)} */}
                  {/*        <hr/> */}
                  {/*        props */}
                  {/*        {jsonPrettify(this.props)} */}
                  {/*    </code> */}
                  {/* </pre> */}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-sm btn-default"
                  type="button"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  className="btn btn-sm btn-primary"
                  type="button"
                  data-dismiss="modal"
                  onClick={this.onChange}
                >
                  <i className="fa fa-check"></i> Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

SourceUniquenessModal.defaultProps = {
  action: 'add',
  source_id: null,
  uniqueness: {},
  onChange: () => {}
}

export default SourceUniquenessModal
