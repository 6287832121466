import { combineReducers } from 'redux'
import * as types from './types'

const clientSettingsInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: []
}

export function fetchClientSettingsReducer (state = clientSettingsInitialState, action) {
  switch (action.type) {
    case types.GET_CLIENT_SETTING_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.GET_CLIENT_SETTING_REQUEST_FAILURE:
      return {
        ...clientSettingsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.GET_CLIENT_SETTING_REQUEST_SUCCESS:
      console.log(action)
      return {
        ...clientSettingsInitialState,
        data: action.payload.data.results[0]
      }
    case types.GET_CLIENT_SETTING_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data.results[0],
        completed: true
      }
    case types.UPDATE_CLIENT_SETTING_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.UPDATE_CLIENT_SETTING_REQUEST_COMPLETED:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}

export default combineReducers({
  fetchClientSettingsReducer
})
