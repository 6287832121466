import React from 'react'

class ContextMenuLayout extends React.Component {
  state = {
    visible: false
  }

  componentDidMount () {
    if (this.props.tableRef.current) {
      this.props.tableRef.current.addEventListener('contextmenu', this._handleContextMenu)
      this.props.tableRef.current.addEventListener('click', this._handleClick)
      this.props.tableRef.current.addEventListener('scroll', this._handleScroll)
    }
  };

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    if (this.props.tableRef.current) {
      this.props.tableRef.current.addEventListener('contextmenu', this._handleContextMenu)
      this.props.tableRef.current.addEventListener('click', this._handleClick)
      this.props.tableRef.current.addEventListener('scroll', this._handleScroll)
    }
  }

  componentWillUnmount () {
    // this.props.tableRef.current.removeEventListener('contextmenu', this._handleContextMenu);
    // this.props.tableRef.current.removeEventListener('click', this._handleClick);
    // this.props.tableRef.current.removeEventListener('scroll', this._handleScroll);
  }

  _handleContextMenu = (event) => {
    event.preventDefault()

    this.setState({ visible: true })

    if (this.root) {
      const clickX = event.clientX
      const clickY = event.clientY
      const screenW = window.innerWidth
      const screenH = window.innerHeight
      const rootW = this.root.offsetWidth
      const rootH = this.root.offsetHeight

      const right = (screenW - clickX) > rootW
      const left = !right
      const top = (screenH - clickY) > rootH
      const bottom = !top

      if (right) {
        this.root.style.left = `${clickX + 5}px`
      }

      if (left) {
        this.root.style.left = `${clickX - rootW - 5}px`
      }

      if (top) {
        this.root.style.top = `${clickY + 5}px`
      }

      if (bottom) {
        this.root.style.top = `${clickY - rootH - 5}px`
      }
    }
  }

  _handleClick = (event) => {
    const { visible } = this.state
    const wasOutside = !(event.target.contains === this.root)

    if (wasOutside && visible) this.setState({ visible: false })
  }

  _handleScroll = () => {
    const { visible } = this.state

    if (visible) this.setState({ visible: false })
  }

  render () {
    const { visible } = this.state
    return (visible || null) &&
            <div ref={ref => {
              this.root = ref
            }} className="contextMenu">
                {
                    this.props.options.map((o, index) => {
                      return (
                            <div className="contextMenu--option" key={index} onClick={o.function}>
                                {o.label}
                            </div>
                      )
                    })
                }
            </div>
  };
}

// Default Props
ContextMenuLayout.defaultProps = {
  options: [],
  tableRef: null
}

export { ContextMenuLayout }
