import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Session } from '../../../../state/utils/session'
import { LoginForm } from '../../containers'
import { AuthenticationPageLayout } from '../../../layouts'

class LoginPage extends Component {
  UNSAFE_componentWillMount () {
    const session = new Session()
    session.checkSession()
  }

  render () {
    return (
                <>
                    <AuthenticationPageLayout>
                        <LoginForm />
                    </AuthenticationPageLayout>
                </>
    )
  };
}

const _LoginPage = withRouter(LoginPage)

export { _LoginPage as LoginPage }
