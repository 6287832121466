import reducer from './reducers'

import * as jobPoolsOperations from './operations'
import * as jobPoolsEndpoints from './paths'

export {
  jobPoolsOperations,
  jobPoolsEndpoints
}

export default reducer
