import React, { Component } from 'react'
import {
  GroupOptionsControllerLayout,
  InputSearchLayout,
  PageContainerLayout,
  PageHeaderLayout,
  PaginationLayout
} from '../../../layouts'
import { DashboardsHeaderTiles, DashboardsTable } from '../../containers'
import { dashboardOperations } from '../../../../state/ducks/dashboards'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { DashboardShape, PaginationShape } from '../../../../prop-types'

class DashboardsPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      params: {}
    }
    this.fetchDashboards = this.fetchDashboards.bind(this)
    this.onPaginationChange = this.onPaginationChange.bind(this)
    this.onSearchChange = this.onSearchChange.bind(this)
    this.updateParams = this.updateParams.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.fetchDashboards()
  }

  fetchDashboards () {
    this.props.fetchDashboards(this.state.params)
  }

  onPaginationChange (page) {
    this.updateParams('page', page)
  }

  onSearchChange (keywork) {
    this.updateParams('search', keywork)
  }

  updateParams (key, value) {
    const { params } = this.state
    params[key] = value
    if (key === 'search') {
      params.page = 1
    }
    this.setState({
      params
    }, () => this.fetchDashboards())
  }

  renderHeaderPaths () {
    return (
            <>
                <li>Dashboards</li>
            </>
    )
  }

  render () {
    return (
            <>
                <PageContainerLayout clientSidebar={true} showNavbarClientOptions={true}>
                    <PageHeaderLayout title={'Dashboards'} subTitle={'All dashboards'}
                                      paths={this.renderHeaderPaths}/>
                    {/* Page Content */}
                    <div className="content content-boxed">
                        <GroupOptionsControllerLayout validFor={['ADMIN', 'DEVELOPER']}>
                            {/* Header Tiles */}
                            <DashboardsHeaderTiles all_dashboards={this.props.paginationData.totalItems}/>
                        </GroupOptionsControllerLayout>
                        {/* END Header Tiles */}
                        <div className={' block block-themed block-opt-refresh-icon7 ' + (this.props.isLoading ? ' block-opt-refresh ' : '')}>
                            <div className="block-header bg-primary-dark">
                                <ul className="block-options">
                                    <li>
                                        <button type="button" data-toggle="block-option"
                                                data-action="fullscreen_toggle">
                                            <i className="si si-size-fullscreen"/>
                                        </button>
                                    </li>
                                    <li>
                                        <button type="button"
                                                data-toggle="block-option"
                                                data-action="refresh_toggle"
                                                data-action-mode="demo"
                                                onClick={this.fetchDashboards}
                                        >
                                            <i className="si si-refresh"></i>
                                        </button>
                                    </li>
                                </ul>
                                <h3 className="block-title">All dashboards</h3>
                            </div>
                            <div className="block-content">
                                {/* Search */}
                                <InputSearchLayout onSearchChange={this.onSearchChange}/>
                                {/* End Search */}
                                {/* Table */}
                                <DashboardsTable dashboards={this.props.dashboards}>
                                    {/* Pagination */}
                                    <PaginationLayout pagination={this.props.paginationData}
                                                      onPaginationChange={this.onPaginationChange}/>
                                    {/* End Pagination */}
                                </DashboardsTable>
                                {/* End Table */}
                            </div>
                        </div>
                    </div>
                    {/* END Page Content */}
                </PageContainerLayout>
            </>
    )
  };
}

// Set propTypes
const { arrayOf, func } = PropTypes
DashboardsPage.propTypes = {
  dashboards: arrayOf(DashboardShape).isRequired,
  fetchDashboards: func.isRequired,
  paginationData: PaginationShape
}

// Set defaultProps
DashboardsPage.defaultProps = {
  dashboards: [],
  paginationData: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  isLoading: state.dashboards.fetchDashboardsReducer.loading,
  dashboards: state.dashboards.fetchDashboardsReducer.data.results,
  paginationData: state.dashboards.fetchDashboardsReducer.data.pagination_data
})

// mapStateToProps
const mapDispatchToProps = {
  fetchDashboards: dashboardOperations.list
}

// Export Class
const _DashboardsPage = connect(mapStateToProps, mapDispatchToProps)(DashboardsPage)
export { _DashboardsPage as DashboardsPage }
