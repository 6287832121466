import reducer from './reducers'

import * as groupOperations from './operations'
import * as groupEndpoints from './paths'

export {
  groupOperations,
  groupEndpoints
}

export default reducer
