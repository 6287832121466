import React, { Component } from 'react'
import * as zxcvbn from 'zxcvbn'

class UserDataForm extends Component {
  constructor (props) {
    super(props)

    // state
    this.state = { user: {} }

    this.handleChange = this.handleChange.bind(this)
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    this.setState({
      user: nextProps.user
    })
  }

  handleChange (event) {
    this.props.onChange(event.target.name, event.target.value)
  }

  createPasswordLabel = (result) => {
    switch (result.score) {
      case 0:
        return 'Weak'
      case 1:
        return 'Weak'
      case 2:
        return 'Fair'
      case 3:
        return 'Good'
      case 4:
        return 'Strong'
      default:
        return 'Weak'
    }
  }

  render () {
    const testedResult = zxcvbn(this.state.user.password ? this.state.user.password : '')

    return (
            <>

                <div className="form-group">
                    <div className="col-sm-8 col-sm-offset-2">
                        <div className="form-material">
                            <input className="form-control"
                                   type="text"
                                   id="input-username"
                                   placeholder="Please enter a username/email"
                                   name="username"
                                   value={this.state.user.username}
                                   onChange={this.handleChange}
                            />
                            <label htmlFor="input-username">Username</label>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="col-sm-8 col-sm-offset-2">
                        <div className="form-material">
                            <input className="form-control"
                                   type="text"
                                   id="input-full_name"
                                   placeholder="Please enter a full name"
                                   name="full_name"
                                   value={this.state.user.full_name}
                                   onChange={this.handleChange}
                            />
                            <label htmlFor="input-full_name">Full Name</label>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="col-sm-8 col-sm-offset-2">
                        <div className="form-material">
                            <input className="form-control"
                                   type="text"
                                   id="input-avatar"
                                   placeholder="Please enter a avatar url"
                                   name="avatar"
                                   value={this.state.user.avatar}
                                   onChange={this.handleChange}
                            />
                            <label htmlFor="input-avatar">Avatar</label>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="col-sm-8 col-sm-offset-2">
                        <div className="form-material">
                            <input className="form-control"
                                   type="password"
                                   id="input-password"
                                   placeholder="Please enter a password"
                                   name="password"
                                   value={this.state.user.password}
                                   onChange={this.handleChange}
                            />
                            <label htmlFor="input-password">Password</label>
                        </div>
                    </div>
                </div>
                {
                    this.state.user.password && testedResult.score < 4
                      ? <div className="form-group">
                            <div className="col-sm-8 col-sm-offset-2">
                                <div className="password-strength-meter w-100 text-center">
                                    <progress
                                        className={`password-strength-meter-progress strength-${this.createPasswordLabel(testedResult)}`}
                                        value={testedResult.score}
                                        max="4"
                                    />
                                    <br/>
                                    <label
                                        className="password-strength-meter-label"
                                    >
                                        <small>Password strength:</small>
                                        {this.createPasswordLabel(testedResult)}
                                    </label>
                                </div>
                            </div>
                        </div>
                      : null
                }
            </>
    )
  }
}

// Set default props
UserDataForm.defaultProps = {
  user: {},
  onChange: () => {
  }
}

export {
  UserDataForm
}
