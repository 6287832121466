import React, { Component } from 'react'
import { EmptyTableLayout } from '../../../layouts'
import { history } from '../../../../routing/History'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { Session } from '../../../../state/utils/session'
import { Link } from 'react-router-dom'
import { ExecutionsModal } from '../executions-modal'

class ExecutionsTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session(),
      execution_to_execute: {}
    }

    this.goToExecutionEditor = this.goToExecutionEditor.bind(this)
    this.selectExecution = this.selectExecution.bind(this)
    this.deleteExecution = this.deleteExecution.bind(this)
    this.executeETL = this.executeETL.bind(this)
  }

  executeETL (execution) {
    this.setState({
      execution_to_execute: execution
    }, () => {
      window.jQuery('#executions-modal').modal('show')
    })
  }

  goToExecutionEditor (execution) {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_SOURCE_EDITOR_PATH
        .replace(':execution_id', execution.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  selectExecution (execution) {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_SOURCE_DETAILS_PATH
        .replace(':execution_id', execution.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  deleteExecution (execution) {

  }

  render () {
    return (
            <>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered table-condensed text-center">
                        <thead>
                        <tr>
                            <th className="text-center">ID</th>
                            <th className="text-center">Name</th>
                            <th className="text-center">Origin Connection</th>
                            <th className="text-center">Destination Connection</th>
                            <th className="text-center">Options</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.executions.map((execution) => {
                              return (
                                    <tr key={execution.id}>
                                        <td className="text-center">{execution.id}</td>
                                        <td className="text-center">{execution.name}</td>
                                        <td className="text-center">
                                            <Link to={
                                                ROUTING_PATHS.CLIENT_DASHBOARD_CONNECTION_DETAILS_PATH
                                                  .replace(':client_id', this.state.session.getClientSession())
                                                  .replace(':connection_id', execution.origin_connection)
                                            }>
                                                {execution.origin_connection_name}
                                            </Link>
                                        </td>
                                        <td className="text-center">
                                            <Link to={
                                                ROUTING_PATHS.CLIENT_DASHBOARD_CONNECTION_DETAILS_PATH
                                                  .replace(':client_id', this.state.session.getClientSession())
                                                  .replace(':connection_id', execution.destination_connection)
                                            }>
                                                {execution.destination_connection_name}
                                            </Link>
                                        </td>
                                        <td className="text-center">
                                            <div className="btn-group">
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Run ETL"
                                                        onClick={this.executeETL.bind(this, execution)}
                                                >
                                                    ETL
                                                </button>
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Edit Execution"
                                                        onClick={this.goToExecutionEditor.bind(this, execution)}
                                                >
                                                    <i className="fa fa-pen"></i>
                                                </button>
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Select Execution"
                                                        onClick={this.selectExecution.bind(this, execution)}
                                                >
                                                    <i className="fa fa-eye"></i>
                                                </button>
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Remove Execution"
                                                        onClick={this.deleteExecution.bind(this, execution)}
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                              )
                            })
                        }
                        <EmptyTableLayout results={this.props.executions} columns={5}/>
                        </tbody>
                    </table>
                    {this.props.children}
                </div>
                <ExecutionsModal object_type={'SOURCE'} object={this.state.execution_to_execute}/>
            </>
    )
  }
}

// Set defaultProps
ExecutionsTable.defaultProps = {
  executions: []
}

// Export Class
export { ExecutionsTable }
