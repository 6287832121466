// ALARMS
export const FETCH_ALARMS_REQUEST = 'session/FETCH_ALARMS_REQUEST'
export const FETCH_ALARMS_REQUEST_SUCCESS = 'session/FETCH_ALARMS_REQUEST_SUCCESS'
export const FETCH_ALARMS_REQUEST_FAILURE = 'session/FETCH_ALARMS_REQUEST_FAILURE'
export const FETCH_ALARMS_REQUEST_COMPLETED = 'session/FETCH_ALARMS_REQUEST_COMPLETED'

export const GET_ALARM_REQUEST = 'session/GET_ALARM_REQUEST'
export const GET_ALARM_REQUEST_SUCCESS = 'session/GET_ALARM_REQUEST_SUCCESS'
export const GET_ALARM_REQUEST_FAILURE = 'session/GET_ALARM_REQUEST_FAILURE'
export const GET_ALARM_REQUEST_COMPLETED = 'session/GET_ALARM_REQUEST_COMPLETED'

export const SAVE_ALARM_REQUEST = 'session/SAVE_ALARM_REQUEST'
export const SAVE_ALARM_REQUEST_SUCCESS = 'session/SAVE_ALARM_REQUEST_SUCCESS'
export const SAVE_ALARM_REQUEST_FAILURE = 'session/SAVE_ALARM_REQUEST_FAILURE'
export const SAVE_ALARM_REQUEST_COMPLETED = 'session/SAVE_ALARM_REQUEST_COMPLETED'

export const UPDATE_ALARM_REQUEST = 'session/UPDATE_ALARM_REQUEST'
export const UPDATE_ALARM_REQUEST_SUCCESS = 'session/UPDATE_ALARM_REQUEST_SUCCESS'
export const UPDATE_ALARM_REQUEST_FAILURE = 'session/UPDATE_ALARM_REQUEST_FAILURE'
export const UPDATE_ALARM_REQUEST_COMPLETED = 'session/UPDATE_ALARM_REQUEST_COMPLETED'

export const DELETE_ALARM_REQUEST = 'session/DELETE_ALARM_REQUEST'
export const DELETE_ALARM_REQUEST_SUCCESS = 'session/DELETE_ALARM_REQUEST_SUCCESS'
export const DELETE_ALARM_REQUEST_FAILURE = 'session/DELETE_ALARM_REQUEST_FAILURE'
export const DELETE_ALARM_REQUEST_COMPLETED = 'session/DELETE_ALARM_REQUEST_COMPLETED'
