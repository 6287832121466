import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import {
  HashRouter,
  Route,
  Switch
} from 'react-router-dom'
import { history } from './History'
import { PrivateRoute } from './PrivateRoute'
import * as PATHS from './Paths'
// Pages
import { AUTHENTICATION_PAGES } from '../components/authentication'
import { ADMIN_DASHBOARD_PAGES } from '../components/admin-dashboard'
import * as CLIENT_DASHBOARD_PAGES from '../components/client-dashboard'
import * as SHARED_PAGES from '../components/shared'
import 'react-toastify/dist/ReactToastify.css'

class Routes extends Component {
  render () {
    return (
      <>
        <HashRouter history={history}>
          <Switch>
            {/* ############### */}
            {/* GENERAL PATHS */}
            {/* ############### */}
            <PrivateRoute
              exact
              path={PATHS.ACCOUNTING_HOME_PATH}
              component={ADMIN_DASHBOARD_PAGES.AccountingPage}
            />
            <PrivateRoute
              exact
              path={PATHS.ROOT_PATH}
              component={ADMIN_DASHBOARD_PAGES.HomePage}
            />
            <Route
              exact
              path={PATHS.NOT_AUTHORIZED_PATH}
              component={AUTHENTICATION_PAGES.NotAuthorizedPage}
            />
            {/* ############### */}
            {/* END GENERAL PATHS */}
            {/* ############### */}
            {/* ############### */}
            {/* AUTHENTICATION */}
            {/* ############### */}
            <Route
              exact
              path={PATHS.LOGIN_PATH}
              component={AUTHENTICATION_PAGES.LoginPage}
            />
            <Route
              exact
              path={PATHS.LOGOUT_PATH}
              component={AUTHENTICATION_PAGES.LogoutPage}
            />
            {/* <Route
                            exact
                            path={PATHS.RESET_PASSWORD_PATH}
                            component={AUTHENTICATION_PAGES.ResetPasswordPage}
                        /> */}
            <PrivateRoute
              exact
              path={PATHS.LOGIN_ACTION_REQUIRED_PATH}
              component={AUTHENTICATION_PAGES.ActionRequiredPage}
            />
            <Route
              exact
              path={PATHS.LOGIN_SUCCESS_PATH}
              component={AUTHENTICATION_PAGES.TokenValidatorPage}
            />
            <Route
              exact
              path={PATHS.LOGIN_FAILURE_PATH}
              component={AUTHENTICATION_PAGES.TokenValidatorPage}
            />
            <Route
              exact
              path={PATHS.ACCOUNT_LOCKED_PATH}
              component={AUTHENTICATION_PAGES.AccountLockedPage}
            />
            {/* ############### */}
            {/* END AUTHENTICATION */}
            {/* ############### */}
            {/* ############### */}
            {/* SHARED */}
            {/* ############### */}
            <PrivateRoute
              exact
              path={PATHS.SHARED_USER_PROFILE_PATH}
              component={SHARED_PAGES.UserProfilePage}
            />
            {/* <PrivateRoute
                            exact
                            path={PATHS.SHARED_USER_PROFILE_EDITOR_PATH}
                            component={SHARED_PAGES.UserProfileEditorPage}
                        /> */}
            {/* ############### */}
            {/* END SHARED */}
            {/* ############### */}
            {/* ############### */}
            {/* ADMIN */}
            {/* ############### */}
            {/* CLIENTS_PATH */}
            <PrivateRoute
              exact
              path={PATHS.HOME_PATH}
              groups={[
                'ADMIN',
                'DEVELOPER',
                'VISUALIZER',
                'DEPLOY_TOOL_VISUALIZER',
                'DEPLOY_TOOL_MANAGER',
                'DEPLOY_TOOL_AUTHORIZER'
              ]}
              component={ADMIN_DASHBOARD_PAGES.HomePage}
            />
            <PrivateRoute
                exact
                path={PATHS.CLIENT_LOGIN_PATH}
                groups={['ADMIN', 'DEVELOPER', 'VISUALIZER']}
                component={ADMIN_DASHBOARD_PAGES.ClientsLogin}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENTS_PATH}
              groups={['ADMIN', 'DEVELOPER', 'VISUALIZER']}
              component={ADMIN_DASHBOARD_PAGES.ClientsPage}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_CREATOR_PATH}
              groups={['ADMIN', 'DEVELOPER']}
              component={ADMIN_DASHBOARD_PAGES.ClientCreatorPage}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_EDITOR_PATH}
              groups={['ADMIN', 'DEVELOPER']}
              component={ADMIN_DASHBOARD_PAGES.ClientEditorPage}
            />
            {/* PERMISSIONS_PATH */}
            <PrivateRoute
              exact
              path={PATHS.PERMISSIONS_PATH}
              component={ADMIN_DASHBOARD_PAGES.PermissionsPage}
              groups={['ADMIN']}
            />
            <PrivateRoute
              exact
              path={PATHS.PERMISSION_CREATOR_PATH}
              component={ADMIN_DASHBOARD_PAGES.PermissionCreatorPage}
              groups={['ADMIN']}
            />
            <PrivateRoute
              exact
              path={PATHS.PERMISSION_EDITOR_PATH}
              component={ADMIN_DASHBOARD_PAGES.PermissionEditorPage}
              groups={['ADMIN']}
            />
            <PrivateRoute
              exact
              path={PATHS.PERMISSION_DETAILS_PATH}
              component={ADMIN_DASHBOARD_PAGES.PermissionDetailsPage}
              groups={['ADMIN']}
            />
            {/* GROUPS_PATH */}
            <PrivateRoute
              exact
              path={PATHS.GROUPS_PATH}
              component={ADMIN_DASHBOARD_PAGES.GroupsPage}
              groups={['ADMIN']}
            />
            <PrivateRoute
              exact
              path={PATHS.GROUP_CREATOR_PATH}
              component={ADMIN_DASHBOARD_PAGES.GroupCreatorPage}
              groups={['ADMIN']}
            />
            <PrivateRoute
              exact
              path={PATHS.GROUP_EDITOR_PATH}
              component={ADMIN_DASHBOARD_PAGES.GroupEditorPage}
              groups={['ADMIN']}
            />
            <PrivateRoute
              exact
              path={PATHS.GROUP_DETAILS_PATH}
              component={ADMIN_DASHBOARD_PAGES.GroupDetailsPage}
              groups={['ADMIN']}
            />
            {/* USERS_PATH */}
            <PrivateRoute
              exact
              path={PATHS.USERS_PATH}
              component={ADMIN_DASHBOARD_PAGES.UsersPage}
              groups={['ADMIN']}
            />
            <PrivateRoute
              exact
              path={PATHS.USER_CREATOR_PATH}
              component={ADMIN_DASHBOARD_PAGES.UserCreatorPage}
              groups={['ADMIN']}
            />
            <PrivateRoute
              exact
              path={PATHS.USER_EDITOR_PATH}
              component={ADMIN_DASHBOARD_PAGES.UserEditorPage}
              groups={['ADMIN']}
            />
            <PrivateRoute
              exact
              path={PATHS.USER_DETAILS_PATH}
              component={ADMIN_DASHBOARD_PAGES.UserDetailsPage}
              groups={['ADMIN']}
            />
            {/* ############### */}
            {/* END ADMIN */}
            {/* ############### */}
            {/* ############### */}
            {/* CLIENT DASHBOARD */}
            {/* ############### */}
            {/* CLIENT_DASHBOARD_HOME_PATH */}
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_HOME_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.HomePage}
              groups={['ADMIN', 'DEVELOPER', 'VISUALIZER']}
            />
            {/* PERMISSIONS_PATH */}
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_PERMISSIONS_PATH}
              component={CLIENT_DASHBOARD_PAGES.PermissionsPage}
              groups={['ADMIN']}
              requireClientID
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_PERMISSION_CREATOR_PATH}
              component={CLIENT_DASHBOARD_PAGES.PermissionCreatorPage}
              groups={['ADMIN']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_PERMISSION_EDITOR_PATH}
              component={CLIENT_DASHBOARD_PAGES.PermissionEditorPage}
              groups={['ADMIN']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_PERMISSION_DETAILS_PATH}
              component={CLIENT_DASHBOARD_PAGES.PermissionDetailsPage}
              groups={['ADMIN']}
            />
            {/* GROUPS_PATH */}
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_GROUPS_PATH}
              component={CLIENT_DASHBOARD_PAGES.GroupsPage}
              groups={['ADMIN']}
              requireClientID
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_GROUP_CREATOR_PATH}
              component={CLIENT_DASHBOARD_PAGES.GroupCreatorPage}
              groups={['ADMIN']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_GROUP_EDITOR_PATH}
              component={CLIENT_DASHBOARD_PAGES.GroupEditorPage}
              groups={['ADMIN']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_GROUP_DETAILS_PATH}
              component={CLIENT_DASHBOARD_PAGES.GroupDetailsPage}
              groups={['ADMIN']}
            />
            {/* USERS_PATH */}
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_USERS_PATH}
              component={CLIENT_DASHBOARD_PAGES.UsersPage}
              groups={['ADMIN']}
              requireClientID
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_USER_CREATOR_PATH}
              component={CLIENT_DASHBOARD_PAGES.UserCreatorPage}
              groups={['ADMIN']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_USER_EDITOR_PATH}
              component={CLIENT_DASHBOARD_PAGES.UserEditorPage}
              groups={['ADMIN']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_USER_DETAILS_PATH}
              component={CLIENT_DASHBOARD_PAGES.UserDetailsPage}
              groups={['ADMIN']}
            />
            {/* CLIENT_DASHBOARD_CONNECTIONS_PATH */}
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_CONNECTIONS_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.ConnectionsPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_CONNECTION_CREATOR_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.ConnectionCreatorPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_CONNECTION_EDITOR_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.ConnectionEditorPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_CONNECTION_DETAILS_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.ConnectionDetailsPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            {/* CLIENT_DASHBOARD_EXTRACTIONS_PATH */}
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_EXTRACTIONS_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.ExtractionsPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_EXTRACTION_CREATOR_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.ExtractionCreatorPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_EXTRACTION_EDITOR_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.ExtractionEditorPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_EXTRACTION_DETAILS_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.ExtractionDetailsPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            {/* CLIENT_DASHBOARD_SOURCES_PATH */}
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_SOURCES_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.SourcesPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_SOURCE_CREATOR_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.SourceCreatorPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_SOURCE_EDITOR_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.SourceEditorPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_SOURCE_DETAILS_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.SourceDetailsPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            {/* CLIENT_DASHBOARD_CONCILIATIONS_PATH */}
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_CONCILIATIONS_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.ConciliationsPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_CONCILIATION_CREATOR_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.ConciliationCreatorPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_CONCILIATION_EDITOR_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.ConciliationEditorPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_CONCILIATION_DETAILS_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.ConciliationDetailsPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            {/* CLIENT_DASHBOARD_DASHBOARDS_PATH */}
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_DASHBOARDS_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.DashboardsPage}
              groups={['ADMIN', 'DEVELOPER', 'VISUALIZER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_DASHBOARD_CREATOR_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.DashboardCreatorPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_DASHBOARD_EDITOR_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.DashboardEditorPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_DASHBOARD_DETAILS_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.DashboardDetailsPage}
              groups={['ADMIN', 'DEVELOPER', 'VISUALIZER']}
            />
            {/* CLIENT_DASHBOARD_ALARMS_PATH */}
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_ALARMS_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.AlarmsPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_ALARM_CREATOR_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.AlarmCreatorPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_ALARM_EDITOR_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.AlarmEditorPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_ALARM_DETAILS_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.AlarmDetailsPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            {/* CLIENT_DASHBOARD_EXECUTIONS_PATH */}
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_EXECUTIONS_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.ExecutionsPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_EXECUTION_CREATOR_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.AlarmCreatorPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_EXECUTION_EDITOR_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.AlarmEditorPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_EXECUTION_DETAILS_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.AlarmDetailsPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            {/* CLIENT_DASHBOARD_AUTOMATIONS_PATH */}
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_AUTOMATIONS_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.AutomationsPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_AUTOMATION_CREATOR_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.AutomationCreatorPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_AUTOMATION_EDITOR_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.AutomationEditorPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_AUTOMATION_DETAILS_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.AutomationDetailsPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            {/* CLIENT_DASHBOARD_WORKER_CALLS_PATH */}
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_WORKER_CALLS_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.WorkerCallsPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_WORKER_CALLS_CREATOR_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.WorkerCallCreatorPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_WORKER_CALLS_EDITOR_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.WorkerCallEditorPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_WORKER_CALLS_DETAILS_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.WorkerCallDetailsPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            {/* CLIENT_DASHBOARD_ACCOUNTING_SEATS_PATH */}
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_ACCOUNTING_SEATS_DASHBOARD_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.AccountingSeatsPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_ACCOUNTING_SEAT_EDITOR_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.AccountingSeatEditorPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_ACCOUNTING_SEAT_DETAILS_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.AccountingSeatDetailsPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_ACCOUNTING_SEAT_SETTINGS_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.AccountingSeatSettingsPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_ACCOUNTING_SEAT_SETTING_CREATOR_PATH}
              requireClientID={true}
              component={
                CLIENT_DASHBOARD_PAGES.AccountingSeatSettingCreatorPage
              }
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_ACCOUNTING_SEAT_SETTING_EDITOR_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.AccountingSeatSettingEditorPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_ACCOUNTING_SEAT_SETTING_DETAILS_PATH}
              requireClientID={true}
              component={
                CLIENT_DASHBOARD_PAGES.AccountingSeatSettingDetailsPage
              }
              groups={['ADMIN', 'DEVELOPER']}
            />
            {/* CLIENT_DASHBOARD_JOB_POOLS_PATH */}
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_JOB_POOLS_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.JobsPoolsPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_JOB_POOLS_CREATOR_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.JobsPoolsCreatorPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_JOB_POOLS_DETAILS_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.JobsPoolsDetailsPage}
              groups={['ADMIN', 'DEVELOPER']}
            />

            {/* CLIENT_DASHBOARD_JOB_PATH */}
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_JOB_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.JobsPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_JOB_DETAILS_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.JobsDetailsPage}
              groups={['ADMIN', 'DEVELOPER']}
            />

            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_INCONSISTENCIES_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.InconsistenciesPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            {/* NON_RECONCILED_ITEMS */}
            <PrivateRoute
              exact
              path={PATHS.CLIENT_DASHBOARD_NON_RECONCILED_ITEMS_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.NonReconciledItemsPage}
              groups={['ADMIN', 'DEVELOPER']}
            />
            <PrivateRoute
              exact={true}
              path={PATHS.AUDIT_USERS_PATH}
              requireClientID={false}
              component={CLIENT_DASHBOARD_PAGES.AuditLogsPage}
              groups={['AUDIT']}
            />
            <PrivateRoute
              exact={true}
              path={PATHS.ACCESS_CONTROL_PATH}
              requireClientID={false}
              component={ADMIN_DASHBOARD_PAGES.UserManagerPage}
              groups={['SECURITY_ADMIN', 'ADMIN']}
            />
            <PrivateRoute
              exact={false}
              path={PATHS.CLIENT_CUSTOM_SETTINGS_PATH}
              requireClientID={false}
              component={CLIENT_DASHBOARD_PAGES.ClientCustomSettingsPage}
              groups={['BANK_ADMIN', 'ADMIN']}
            />
            {/* ------ DEPLOY TOOL */}
            <PrivateRoute
              exact={true}
              path={PATHS.DEPLOY_TOOL_HOME_INIT_PATH}
              requireClientID={false}
              component={CLIENT_DASHBOARD_PAGES.DeployHomePage}
              groups={[
                'DEPLOY_TOOL_VISUALIZER',
                'DEPLOY_TOOL_MANAGER',
                'DEPLOY_TOOL_AUTHORIZER'
              ]}
            />
            <PrivateRoute
              exact
              path={PATHS.DEPLOY_TOOL_BASE_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.DeployListPage}
              groups={[
                'DEPLOY_TOOL_VISUALIZER',
                'DEPLOY_TOOL_MANAGER',
                'DEPLOY_TOOL_AUTHORIZER'
              ]}
            />
            <PrivateRoute
              exact
              path={PATHS.DEPLOY_TOOL_CREATOR_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.DeployCreatorPage}
              groups={['DEPLOY_TOOL_MANAGER']}
            />
            <PrivateRoute
              exact
              path={PATHS.DEPLOY_TOOL_EDITOR_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.DeployEditorPage}
              groups={['DEPLOY_TOOL_AUTHORIZER']}
            />
            <PrivateRoute
              exact
              path={PATHS.DEPLOY_TOOL_DETAILS_PATH}
              requireClientID={true}
              component={CLIENT_DASHBOARD_PAGES.DeployViewerPage}
              groups={[
                'DEPLOY_TOOL_VISUALIZER',
                'DEPLOY_TOOL_MANAGER',
                'DEPLOY_TOOL_AUTHORIZER'
              ]}
            />
            {/* END DEPLOY */}
            {/* ------- */}
            <Route path="*" component={AUTHENTICATION_PAGES.NotFoundPage} />
          </Switch>
        </HashRouter>
        <ToastContainer />
      </>
    )
  }
}

const mapStateToProps = (store) => {
  return { store }
}

const _Routes = connect(mapStateToProps)(Routes)

export { _Routes as Routes }
