import * as types from './types'
import * as API_PATHS from './paths'
import { Session } from '../../utils/session'
import apiService from '../../utils/apiService'
import {
  handleApiErrors,
  handleApiResponseFailure,
  handleApiResponseSuccess
} from '../../utils'
import { history } from '../../../routing/History'
import * as ROUTING_PATHS from '../../../routing/Paths'

const session = new Session()

export const get = (objectId) => {
  return (dispatch) => {
    const TYPE = types.GET_WORKER_CALLS_REQUEST
    const FINAL_PATH = API_PATHS.BASE_PATH + objectId + '/'
    dispatch({ type: TYPE, payload: {} })
    apiService({
      method: 'GET',
      action: TYPE,
      path: FINAL_PATH,
      async: true,
      params: null,
      body: null
    })
      .then((res) => {
        if ('status' in res.data && res.data.status === 'ERROR') {
          handleApiResponseFailure(dispatch, TYPE, res)
        } else {
          handleApiResponseSuccess(dispatch, TYPE, res)
          dispatch({ type: `${TYPE}_COMPLETED`, payload: res })
        }
      })
      .catch((err) => {
        handleApiErrors(dispatch, TYPE, {
          data: { api_error: err, error: 'Server 500' }
        })
      })
  }
}

export const list = (params) => {
  return (dispatch) => {
    const TYPE = types.FETCH_WORKER_CALLS_REQUEST
    const FINAL_PATH = API_PATHS.BASE_PATH
    dispatch({ type: TYPE, payload: {} })
    apiService({
      method: 'GET',
      action: TYPE,
      path: FINAL_PATH,
      async: true,
      params,
      body: null
    })
      .then((res) => {
        if (res?.data.status === 'OK') {
          handleApiResponseSuccess(dispatch, TYPE, res)
          dispatch({ type: `${TYPE}_COMPLETED`, payload: res })
        } else {
          handleApiResponseFailure(dispatch, TYPE, res)
        }
      })
      .catch((err) => {
        handleApiErrors(dispatch, TYPE, {
          data: { api_error: err, error: 'Server 500' }
        })
      })
  }
}

export const save = (body) => {
  return (dispatch) => {
    const TYPE = types.SAVE_WORKER_CALL_REQUEST
    const FINAL_PATH = API_PATHS.BASE_PATH
    dispatch({ type: TYPE, payload: {} })
    apiService({
      method: 'POST',
      action: TYPE,
      path: FINAL_PATH,
      async: true,
      params: null,
      body
    })
      .then((res) => {
        if ('status' in res.data && res.data.status === 'ERROR') {
          handleApiResponseFailure(dispatch, TYPE, res)
        } else {
          handleApiResponseSuccess(dispatch, TYPE, res)
          dispatch({ type: `${TYPE}_COMPLETED`, payload: res })
          history.push(
            ROUTING_PATHS.CLIENT_DASHBOARD_WORKER_CALLS_DETAILS_PATH.replace(
              ':client_id',
              session.getClientSession()
            ).replace(':worker_call_id', res.data.id)
          )
        }
      })
      .catch((err) => {
        handleApiErrors(dispatch, TYPE, {
          data: { api_error: err, error: 'Server 500' }
        })
      })
  }
}

export const update = (objectId, body, redirectPath = null) => {
  return (dispatch) => {
    const TYPE = types.UPDATE_WORKER_CALL_REQUEST
    const FINAL_PATH = API_PATHS.BASE_PATH + objectId + '/'
    dispatch({ type: TYPE, payload: {} })
    apiService({
      method: 'PATCH',
      action: TYPE,
      path: FINAL_PATH,
      async: true,
      params: null,
      body
    })
      .then((res) => {
        if ('status' in res.data && res.data.status === 'ERROR') {
          handleApiResponseFailure(dispatch, TYPE, res)
        } else {
          handleApiResponseSuccess(dispatch, TYPE, res)
          dispatch({ type: `${TYPE}_COMPLETED`, payload: res })
          if (redirectPath) {
            history.push(redirectPath)
          } else {
            history.push(
              ROUTING_PATHS.CLIENT_DASHBOARD_WORKER_CALLS_DETAILS_PATH.replace(
                ':client_id',
                session.getClientSession()
              ).replace(':worker_call_id', res.data.id)
            )
          }
        }
      })
      .catch((err) => {
        handleApiErrors(dispatch, TYPE, {
          data: { api_error: err, error: 'Server 500' }
        })
      })
  }
}

export const remove = (params, objectId) => {
  return (dispatch) => {
    const TYPE = types.DELETE_WORKER_CALL_REQUEST
    const FINAL_PATH = API_PATHS.BASE_PATH + objectId + '/'
    dispatch({ type: TYPE, payload: {} })
    apiService({
      method: 'DELETE',
      action: TYPE,
      path: FINAL_PATH,
      async: true,
      params: null,
      body: null
    })
      .then((res) => {
        if ('status' in res && res.status === 'ERROR') {
          handleApiResponseFailure(dispatch, TYPE, res)
        } else {
          handleApiResponseSuccess(dispatch, TYPE, res)
          dispatch({ type: `${TYPE}_COMPLETED`, payload: res })
          dispatch(list(params))
        }
      })
      .catch((err) => {
        handleApiErrors(dispatch, TYPE, {
          data: { api_error: err, error: 'Server 500' }
        })
      })
  }
}
