import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { conciliationOperations } from '../../../../state/ducks/conciliations'
import { PaginationSizeLayout, TableGeneratedLayout } from '../../../layouts'
import { PaginationLayout } from '../../../layouts/pagination'

class ConciliationAllocationsATableGenerated extends Component {
  constructor (props) {
    super(props)

    // state
    this.state = {
      fullPage: false,
      queryParams: {}
    }

    this.getAllocationsATableGenerated = this.getAllocationsATableGenerated.bind(this)
    this.fullPage = this.fullPage.bind(this)
    this.getAllocationsATableGenerated = this.getAllocationsATableGenerated.bind(this)
    this.onPaginationChange = this.onPaginationChange.bind(this)
    this.onPaginationSizeChange = this.onPaginationSizeChange.bind(this)
    this.onSortBy = this.onSortBy.bind(this)
  }

  componentDidMount () {
    if (!this.state.queryParams.connection_id) {
      this.setState({
        queryParams: {
          connection_id: this.props.conciliation.destination_connection,
          table_name: this.props.conciliation.parameters.allocation_a.name
        }
      }, () => {
        if (!this.props.isLoading) {
          this.props.getAllocationsATableGenerated(this.state.queryParams)
        }
      })
    }
  }

  getAllocationsATableGenerated () {
    this.props.getAllocationsATableGenerated(this.state.queryParams)
  }

  fullPage (fullPage) {
    const currentValue = this.state.fullPage
    if (currentValue) {
      fullPage = null
    }
    this.setState({ fullPage })
  }

  updateQueryParams (key, value) {
    const { queryParams } = this.state
    queryParams[key] = value
    if (key === 'search') {
      queryParams.page = 1
    }
    this.setState({ queryParams }, () => this.getAllocationsATableGenerated())
  }

  onPaginationSizeChange (size) {
    this.updateQueryParams('pagination_size', size)
  }

  onSortBy (att, sortBy) {
    this.updateQueryParams('ordering', sortBy === -1 ? '-' + att : att)
  }

  onPaginationChange (page) {
    this.updateQueryParams('page', page)
  }

  render () {
    return (
            <>
                <div className={
                    ' block block-themed block-opt-refresh-icon7 ' +
                    (this.props.isLoading ? ' block-opt-refresh ' : '') +
                    (this.state.fullPage ? ' block-opt-fullscreen ' : '')
                }>
                    <div className="block-header bg-primary-dark">
                        <ul className="block-options">
                            <li>
                                <button type="button"
                                        onClick={this.fullPage.bind('loaded-table')}
                                >
                                    <i className="si si-size-fullscreen"/>
                                </button>
                            </li>
                            <li>
                                <button type="button">
                                    <i className="fa fa-download"/>
                                </button>
                            </li>
                            <li>
                                <button type="button"
                                        data-toggle="block-option"
                                        onClick={this.getAllocationsATableGenerated}
                                >
                                    <i className="si si-refresh"></i>
                                </button>
                            </li>
                        </ul>
                        <h3 className="block-title">{this.props.conciliation.parameters.allocation_a.name}</h3>
                    </div>
                    <div className="block-content">
                        {/* Pagination Size */}
                        <PaginationSizeLayout
                            onPaginationSizeChange={this.onPaginationSizeChange}/>
                        {/* End Pagination Size */}
                        <div className="table-scrolling">
                            {/* Table */}
                            <TableGeneratedLayout
                                rows={this.props.allocationsATableGenerated}
                                onSortBy={this.onSortBy}
                            />
                            {/* End Table */}
                        </div>
                        {/* Pagination */}
                        <PaginationLayout
                            pagination={this.props.paginationData}
                            onPaginationChange={this.onPaginationChange}
                        />
                        {/* End Pagination */}
                    </div>
                </div>
            </>
    )
  }
}

// Set propTypes
ConciliationAllocationsATableGenerated.propTypes = {
  conciliation: PropTypes.object.isRequired,
  allocationsATableGenerated: PropTypes.array,
  isLoading: PropTypes.bool,
  paginationData: PropTypes.object
}

// Set defaultProps
ConciliationAllocationsATableGenerated.defaultProps = {
  conciliation: {},
  allocationsATableGenerated: [],
  isLoading: true,
  paginationData: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  allocationsATableGenerated: state.conciliations.allocationsATableGeneratedReducer.data.results,
  isLoading: state.conciliations.allocationsATableGeneratedReducer.loading,
  paginationData: state.conciliations.allocationsATableGeneratedReducer.data.pagination_data
})

// mapStateToProps
const mapDispatchToProps = {
  getAllocationsATableGenerated: conciliationOperations.getAllocationsATableGenerated
}

// Export Class
const _ConciliationAllocationsATableGenerated = connect(mapStateToProps,
  mapDispatchToProps)(ConciliationAllocationsATableGenerated)
export { _ConciliationAllocationsATableGenerated as ConciliationAllocationsATableGenerated }
