// VALIDATIONS AUTOMATIONS TYPES

export const RESET_VALIDATIONS_AUTOMATIONS = 'session/RESET_VALIDATIONS_AUTOMATIONS'

// FETCH_VALIDATIONS_AUTOMATIONS_CONCILIATIONS
export const FETCH_VALIDATIONS_AUTOMATIONS_CONCILIATIONS_REQUEST = 'session/FETCH_VALIDATIONS_AUTOMATIONS_CONCILIATIONS_REQUEST'
export const FETCH_VALIDATIONS_AUTOMATIONS_CONCILIATIONS_REQUEST_SUCCESS =
  'session/FETCH_VALIDATIONS_AUTOMATIONS_CONCILIATIONS_REQUEST_SUCCESS'
export const FETCH_VALIDATIONS_AUTOMATIONS_CONCILIATIONS_REQUEST_FAILURE =
  'session/FETCH_VALIDATIONS_AUTOMATIONS_CONCILIATIONS_REQUEST_FAILURE'
export const FETCH_VALIDATIONS_AUTOMATIONS_CONCILIATIONS_REQUEST_COMPLETED =
  'session/FETCH_VALIDATIONS_AUTOMATIONS_CONCILIATIONS_REQUEST_COMPLETED'

// FETCH_VALIDATIONS_AUTOMATIONS_SOURCES
export const FETCH_VALIDATIONS_AUTOMATIONS_SOURCES_REQUEST = 'session/FETCH_VALIDATIONS_AUTOMATIONS_SOURCES_REQUEST'
export const FETCH_VALIDATIONS_AUTOMATIONS_SOURCES_REQUEST_SUCCESS = 'session/FETCH_VALIDATIONS_AUTOMATIONS_SOURCES_REQUEST_SUCCESS'
export const FETCH_VALIDATIONS_AUTOMATIONS_SOURCES_REQUEST_FAILURE = 'session/FETCH_VALIDATIONS_AUTOMATIONS_SOURCES_REQUEST_FAILURE'
export const FETCH_VALIDATIONS_AUTOMATIONS_SOURCES_REQUEST_COMPLETED = 'session/FETCH_VALIDATIONS_AUTOMATIONS_SOURCES_REQUEST_COMPLETED'

// FETCH_VALIDATIONS_AUTOMATIONS_ALARMS
export const FETCH_VALIDATIONS_AUTOMATIONS_ALARMS_REQUEST = 'session/FETCH_VALIDATIONS_AUTOMATIONS_ALARMS_REQUEST'
export const FETCH_VALIDATIONS_AUTOMATIONS_ALARMS_REQUEST_SUCCESS = 'session/FETCH_VALIDATIONS_AUTOMATIONS_ALARMS_REQUEST_SUCCESS'
export const FETCH_VALIDATIONS_AUTOMATIONS_ALARMS_REQUEST_FAILURE = 'session/FETCH_VALIDATIONS_AUTOMATIONS_ALARMS_REQUEST_FAILURE'
export const FETCH_VALIDATIONS_AUTOMATIONS_ALARMS_REQUEST_COMPLETED = 'session/FETCH_VALIDATIONS_AUTOMATIONS_ALARMS_REQUEST_COMPLETED'

// FETCH_VALIDATIONS_AUTOMATIONS_WORKERCALLS
export const FETCH_VALIDATIONS_AUTOMATIONS_WORKERCALLS_REQUEST = 'session/FETCH_VALIDATIONS_AUTOMATIONS_WORKERCALLS_REQUEST'
export const FETCH_VALIDATIONS_AUTOMATIONS_WORKERCALLS_REQUEST_SUCCESS = 'session/FETCH_VALIDATIONS_AUTOMATIONS_WORKERCALLS_REQUEST_SUCCESS'
export const FETCH_VALIDATIONS_AUTOMATIONS_WORKERCALLS_REQUEST_FAILURE = 'session/FETCH_VALIDATIONS_AUTOMATIONS_WORKERCALLS_REQUEST_FAILURE'
export const FETCH_VALIDATIONS_AUTOMATIONS_WORKERCALLS_REQUEST_COMPLETED = 'session/FETCH_VALIDATIONS_AUTOMATIONS_WORKERCALLS_REQUEST_COMPLETED'
