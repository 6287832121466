import { toast } from 'react-toastify'
import config from '../../config'
import { history } from '../../routing/History'
import * as PATHS from '../../routing/Paths'
import jwtDecode from 'jwt-decode'
import Cookies from 'js-cookie'

export class Session {
  constructor () {
    this.selectedAuth = this.getAuth()
    this.session = this.getSession()
    this.clientSession = this.getClientSession()
    this.selectedGroup = this.getSelectedGroup()
  }

  setSession = (token) => {
    const date = new Date()
    const dateNow = date.getTime()
    this.selectedAuth = this.getAuth()
    document.cookie = `${config.nameCookieLastSession}=${dateNow}; path=/; domain=${config.domainCookieProfile}`
    document.cookie = `${config.nameCookieAuth}=${token}; path=/; domain=${config.domainCookieProfile}`
    this.setSelectedGroup(this.getSession().groups[0])
    this.applyRedirects()
  }

  applyRedirects () {
    this.session = this.getSession()
    if (this.session.required_action && this.session.required_action !== 'change-password') {
      history.push(
        PATHS.LOGIN_ACTION_REQUIRED_PATH.replace(':action', this.session.required_action)
      )
    } else if (!this.session.required_admin_dashboard && this.session.default_client) {
      this.setClientSession(this.session.default_client)
      history.push(
        PATHS.CLIENT_DASHBOARD_HOME_PATH.replace(':client_id', this.session.default_client)
      )
    } else {
      if (['SK_PRD'].includes(config.featureMode)) {
        history.push(PATHS.CLIENT_LOGIN_PATH)
      } else {
        history.push(PATHS.HOME_PATH)
      }
    }
  }

  setClientSession = (client) => {
    const cli = JSON.stringify({ id: client.id, name: client?.name, logo: client?.logo })
    document.cookie = `${config.nameCookieClientSelected}=${cli}; path=/; domain=${config.domainCookieProfile}`
    this.clientSession = client.id
  }

  getSession = () => {
    const validatePermission = (rolRequire, PERMISSIONS) => {
      if (!rolRequire) return true
      const rolFilter = PERMISSIONS.find((rol) => {
        if (typeof rolRequire === 'string') return rol === rolRequire
        return rolRequire.indexOf(rol) >= 0
      })
      return rolFilter !== undefined
    }

    const token = this.getAuth()
    if (token) {
      const session = this.jwtDecode(token)
      if (this.tokenExpired(session)) return null
      const groups = session.groups
      if (!validatePermission(['ADMIN', 'DEVELOPER', 'AUDIT', 'DEPLOY_TOOL_VISUALIZER', 'DEPLOY_TOOL_MANAGER', 'DEPLOY_TOOL_AUTHORIZER'], groups)) {
        window.location.href = config.frontendUrlV2
        return null
      }
    }
    return token ? this.jwtDecode(token) : null
  }

  getAuth = () => {
    return Cookies.get(config.nameCookieAuth) || null
  }

  jwtDecode (token) {
    try {
      if (token) {
        return jwtDecode(token)
      }
    } catch (e) {
      console.log('jwtDecode Exception:', e)
    }
  }

  getClientSession = () => {
    return Cookies.get(config.nameCookieClientSelected) ? JSON.parse(Cookies.get(config.nameCookieClientSelected))?.id : null
  }

  setSelectedGroup = (groupCode) => {
    document.cookie = `${config.nameCookieGroup}=${groupCode}; path=/; domain=${config.domainCookieProfile}`
    this.clientSession = this.getSelectedGroup()
  }

  getSelectedGroup = () => {
    const selectedGroupName = Cookies.get('group') || null
    if (selectedGroupName && this.getSession()) {
      if (this.getSession().groups) {
        if (this.getSession().groups.includes(selectedGroupName)) {
          return selectedGroupName
        } else {
          this.removeSelectedGroup()
          return this.getSession().groups[0]
        }
      } else {
        this.removeSelectedGroup()
        return ''
      }
    }
    if (this.getSession()) {
      return this.getSession().groups[0]
    } else {
      return ''
    }
  }

  removeSelectedGroup () {
    document.cookie = `${config.nameCookieGroup}=; path=/; domain=${config.domainCookieProfile}`
  }

  removeSession = () => {
    document.cookie = `${config.nameCookieAuth}=; path=/; domain=${config.domainCookieProfile}`
    document.cookie = `${config.nameCookieLastSession}=; path=/; domain=${config.domainCookieProfile}`
    this.removeSelectedGroup()
    this.removeClientSession()
    history.push(PATHS.LOGIN_PATH)
  }

  removeClientSession = () => {
    document.cookie = `${config.nameCookieClientSelected}=; path=/; domain=${config.domainCookieProfile}`
    if (['SK_PRD'].includes(config.featureMode)) {
      history.push(PATHS.CLIENT_LOGIN_PATH)
    } else {
      history.push(PATHS.CLIENTS_PATH)
    }
  }

  tokenExpired (optionalSession) {
    const session = optionalSession || this.session
    if (this.utcSecondsToDatetime(session.exp) <= new Date()) {
      toast.warn('La sesión ha finalizado, inicia sesión nuevamente.', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
      this.removeSession()
      return true
    }
    return false
  }

  allowedActionFor = (groups = []) => {
    let isAuthorized = false
    groups.forEach(g => {
      if (this.getSession().groups.includes(g)) {
        isAuthorized = true
      }
    })
    return isAuthorized
  }

  checkSession = () => {
    const session = this.getSession()
    if (session) {
      if (this.utcSecondsToDatetime(session.exp) >= new Date()) {
        history.push('/home')
      } else {
        this.removeSession()
      }
    }
  }

  utcSecondsToDatetime = (utcSeconds) => {
    if (utcSeconds) {
      const date = new Date(0) // The 0 there is the key, which sets the date to the epoch
      date.setUTCSeconds(utcSeconds)
      return date
    } else {
      return null
    }
  }
}
