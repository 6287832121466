import React, { Component } from 'react'

class PaginationSizeLayout extends Component {
  constructor (props) {
    super(props)

    this.state = {
      paginationSize: 10
    }

    this.onPaginationSizeChange = this.onPaginationSizeChange.bind(this)
  }

  onPaginationSizeChange (event) {
    this.setState({
      paginationSize: event.target.value
    }, () => {
      this.props.onPaginationSizeChange(this.state.paginationSize)
    }
    )
  }

  render () {
    return (
            <>
                <select
                    onChange={this.onPaginationSizeChange}
                    value={this.state.paginationSize}
                    className="form-control form-control-sm"
                    style={{ width: '70px' }}
                >
                    <option value={10}>10</option>
                    <option value={50}> 50 </option>
                    <option value={100}>100</option>
                </select>
            </>
    )
  }
}

// default props
PaginationSizeLayout.defaultProps = {
  onPaginationSizeChange: () => {

  }
}

export { PaginationSizeLayout }
