import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { AlarmShape } from '../../../../prop-types'
import { alarmOperations } from '../../../../state/ducks/alarms'
import { connect } from 'react-redux'
import { BaseFormWizard } from '../../../../assets/js/base_form_wizard'
import { AlarmDataForm } from '../alarm-data-form'
import { AppUI } from '../../../../assets/js/app'
import { Session } from '../../../../state/utils/session'
import { AlarmEmailForm } from '../alarm-email-form'
import { AlarmQueryForm } from '../alarm-query-form'

class AlarmWizardForm extends Component {
  constructor (props) {
    super(props)

    // state
    this.state = {
      session: new Session(),
      alarm: { parameters: {} }
    }

    // handlers
    this.onAlarmDataChange = this.onAlarmDataChange.bind(this)
    this.sendData = this.sendData.bind(this)
    AppUI.initHelpers(['highlightjs'])
  }

  componentDidMount () {
    BaseFormWizard.init()
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.alarm && 'id' in nextProps.alarm) {
      this.setState({ alarm: nextProps.alarm })
    }
  }

  onAlarmDataChange (name, value) {
    const alarm = JSON.parse(JSON.stringify(this.state.alarm))
    alarm[name] = value
    this.setState({
      alarm
    })
  }

  sendData () {
    if (!this.props.isEditor) {
      const alarm = this.state.alarm
      alarm.client = this.state.session.getClientSession()
      this.props.saveAlarm(this.state.alarm)
    } else {
      this.props.updateAlarm(this.state.alarm.id, this.state.alarm)
    }
  }

  render () {
    return (
            <>
                <div className="content content-boxed">
                    <div className={' block block-themed block-opt-refresh-icon7 ' + (this.props.isLoading ? ' block-opt-refresh ' : '')}>
                        <div className="block-content">
                            {/* Simple Progress Wizard (.js-wizard-simple class is initialized in js/pages/base_forms_wizard.js) */}
                            {/* For more examples you can check out http://vadimg.com/twitter-bootstrap-wizard-example/ */}
                            <div className="js-wizard-simple block">
                                {/* Steps Progress */}
                                <div className="block-content block-content-mini block-content-full border-b">
                                    <div className="wizard-progress progress active remove-margin-b">
                                        <div className="progress-bar progress-bar-primary progress-bar-striped"
                                             role="progressbar" aria-valuenow="100" aria-valuemin="0"
                                             aria-valuemax="100"
                                             style={{ width: '0' }}>
                                        </div>
                                    </div>
                                </div>
                                {/* END Steps Progress */}

                                {/* Step Tabs */}
                                <ul className="nav nav-tabs nav-tabs-alt nav-justified">
                                    <li className="active">
                                        <a href="#simple-progress-step1" data-toggle="tab">1. Alarm Data</a>
                                    </li>
                                    <li>
                                        <a href="#simple-progress-step2" data-toggle="tab">2. Query</a>
                                    </li>
                                    <li>
                                        <a href="#simple-progress-step3" data-toggle="tab">3. Email Settings</a>
                                    </li>
                                    <li>
                                        <a href="#simple-progress-step5" data-toggle="tab">4. Test Alarm</a>
                                    </li>
                                </ul>
                                {/* END Step Tabs */}

                                {/* Form */}
                                <form className="form-horizontal" action="javascript:void(0)">
                                    {/* Steps Content */}
                                    <div className="block-content tab-content" style={{ minHeight: '300px' }}>
                                        {/* Step 1 */}
                                        <div className="tab-pane fade fade-right in push-30-t push-50 active"
                                             id="simple-progress-step1">
                                            <AlarmDataForm
                                                alarm={this.state.alarm}
                                                onChange={this.onAlarmDataChange}
                                            />
                                        </div>
                                        {/* END Step 1 */}

                                        {/* Step 2 */}
                                        <div className="tab-pane fade fade-right in push-30-t push-50"
                                             id="simple-progress-step2">
                                            <AlarmQueryForm
                                                alarm={this.state.alarm}
                                                onChange={this.onAlarmDataChange}
                                            />
                                        </div>
                                        {/* END Step 2 */}

                                        {/* Step 3 */}
                                        <div className="tab-pane fade fade-right in push-30-t push-50"
                                             id="simple-progress-step3">
                                            <AlarmEmailForm
                                                alarm={this.state.alarm}
                                                onChange={this.onAlarmDataChange}
                                            />
                                        </div>
                                        {/* END Step 3 */}

                                        {/* Step 4 */}
                                        <div className="tab-pane fade fade-right in push-30-t push-50"
                                             id="simple-progress-step5">
                                            <div className="text-center">
                                                <span>We are working in this functionality, please continue to the next step.</span>
                                            </div>
                                        </div>
                                        {/* END Step 4 */}
                                    </div>
                                    {/* END Steps Content */}

                                    {/* Steps Navigation */}
                                    <div className="block-content block-content-mini block-content-full border-t">
                                        <div className="row">
                                            {
                                                this.props.saveAlarmErrorResponse.error
                                                  ? <div className="col-xs-12 text-danger text-center">
                                                        {JSON.stringify(this.props.saveAlarmErrorResponse.error)}
                                                    </div>
                                                  : null
                                            }
                                            {
                                                this.props.updateAlarmErrorResponse.error
                                                  ? <div className="col-xs-12 text-danger text-center">
                                                        {JSON.stringify(this.props.updateAlarmErrorResponse.error)}
                                                    </div>
                                                  : null
                                            }
                                            <div className="col-xs-6">
                                                <button
                                                    className="wizard-prev btn btn-warning"
                                                    type="button"
                                                >
                                                    <i className="fa fa-arrow-circle-o-left"></i> Previous
                                                </button>
                                            </div>
                                            <div className="col-xs-6 text-right">
                                                <button
                                                    className="wizard-next btn btn-success"
                                                    type="button"
                                                >
                                                    Next <i className="fa fa-arrow-circle-o-right"></i>
                                                </button>
                                                <button
                                                    className="wizard-finish btn btn-primary"
                                                    type="button"
                                                    disabled={this.props.isLoading}
                                                    onClick={this.sendData}
                                                >
                                                    {
                                                        this.props.isLoading
                                                          ? <>
                                                                <i className="ml-1 fa fa-spinner fa-spin pull-right"></i>
                                                                <span>Submit</span>
                                                            </>
                                                          : <>
                                                                <i className="ml-1 si si-login pull-right"></i>
                                                                <span>Submit</span>
                                                            </>
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* END Steps Navigation */}
                                </form>
                                {/* END Form */}
                            </div>
                            {/* END Simple Progress Wizard */}
                            {/* <pre className="pre-sh push"> */}
                            {/*    <code className="json"> */}
                            {/*    {JSON.stringify(this.state.alarm)} */}
                            {/*    </code> */}
                            {/* </pre> */}
                        </div>
                    </div>
                </div>
            </>
    )
  }
}

// Set propTypes
AlarmWizardForm.propTypes = {
  alarm: AlarmShape,
  saveAlarmErrorResponse: PropTypes.object,
  updateAlarmErrorResponse: PropTypes.object,
  isEditor: PropTypes.bool
}

// Set defaultProps
AlarmWizardForm.defaultProps = {
  alarm: {},
  saveAlarmErrorResponse: {},
  updateAlarmErrorResponse: {},
  isEditor: false
}

// mapStateToProps
const mapStateToProps = (state) => ({
  isLoading: state.alarms.saveAlarmReducer.loading || state.alarms.updateAlarmReducer.loading,
  saveAlarmErrorResponse: state.alarms.saveAlarmReducer.error_data,
  updateAlarmErrorResponse: state.alarms.updateAlarmReducer.error_data
})

// mapStateToProps
const mapDispatchToProps = {
  saveAlarm: alarmOperations.save,
  updateAlarm: alarmOperations.update
}

// Export Class
const _AlarmWizardForm = connect(mapStateToProps, mapDispatchToProps)(AlarmWizardForm)
export { _AlarmWizardForm as AlarmWizardForm }
