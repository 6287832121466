import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { EmptyTableLayout } from '../../../layouts'
import { history } from '../../../../routing/History'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { Session } from '../../../../state/utils/session'
import { jobPoolsOperations } from '../../../../state/ducks/jobs-pools'
import Swal from 'sweetalert2'

class JobsPoolsTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }

    this.selectJobsPool = this.selectJobsPool.bind(this)
    this.deleteJobsPool = this.deleteJobsPool.bind(this)
  }

  selectJobsPool (jobPool) {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_JOB_POOLS_DETAILS_PATH
        .replace(':job_pool_id', jobPool.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  deleteJobsPool (jobPool) {
    Swal.fire({
      title: '',
      html: `<b>Por favor confirme la eliminación del job pool ${jobPool.name}?</b>`,
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.dismiss !== 'backdrop') {
        if (result.value) {
          this.props.removeJobPool(this.props.params, jobPool.id)
        }
      }
    })
  }

  render () {
    return (
            <>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered table-condensed text-center">
                        <thead>
                        <tr>
                            <th className="text-center">ID</th>
                            <th className="text-center">Name</th>
                            <th className="text-center">Options</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.jobsPools.map((jobPool) => {
                              return (
                                    <tr key={jobPool.id}>
                                        <td className="text-center">{jobPool.id}</td>
                                        <td className="text-center">{jobPool.name}</td>
                                        <td className="text-center">
                                            <div className="btn-group">
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Select JobsPool"
                                                        onClick={this.selectJobsPool.bind(this, jobPool)}
                                                >
                                                    <i className="fa fa-eye"></i>
                                                </button>
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Remove JobsPool"
                                                        onClick={this.deleteJobsPool.bind(this, jobPool)}
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                              )
                            })
                        }
                        <EmptyTableLayout results={this.props.jobsPools} columns={3}/>
                        </tbody>
                    </table>
                    {this.props.children}
                    {
                        this.props.removeJobPoolErrorResponse.error
                          ? <div className="col-xs-12 text-danger text-center">
                                {JSON.stringify(this.props.removeJobPoolErrorResponse.error)}
                            </div>
                          : null
                    }
                </div>
            </>
    )
  }
}

// Set propTypes
JobsPoolsTable.propTypes = {
  removeJobPoolErrorResponse: PropTypes.object
}

// Set defaultProps
JobsPoolsTable.defaultProps = {
  params: null,
  jobsPools: [],
  removeJobPoolErrorResponse: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  isLoading: state.jobsPools.removeJobPoolReducer.loading,
  removeJobPoolErrorResponse: state.jobsPools.removeJobPoolReducer.error_data
})

// mapStateToProps
const mapDispatchToProps = {
  removeJobPool: jobPoolsOperations.remove
}

// Export Class
const _JobsPoolsTable = connect(mapStateToProps, mapDispatchToProps)(JobsPoolsTable)
export { _JobsPoolsTable as JobsPoolsTable }
