import React, { Component } from 'react'
import { EmptyTableLayout } from '../../../layouts'
import { Session } from '../../../../state/utils/session'

class JobHistoricalTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }
  }

  render () {
    return (
            <>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered table-condensed text-center">
                        <thead>
                        <tr>
                            <th className="text-center">ID</th>
                            <th className="text-center">Name</th>
                            <th className="text-center">Type</th>
                            <th className="text-center">State</th>
                            <th className="text-center">Created</th>
                            <th className="text-center">Log At</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.jobHistorical.map((execution) => {
                              return (
                                    <tr key={execution.id}>
                                        <td className="text-center">{execution.id}</td>
                                        <td className="text-left">{execution.name}</td>
                                        <td className="text-left">{execution.type}</td>
                                        <td className="text-center">{execution.state}</td>
                                        <td className="text-center">{execution.created}</td>
                                        <td className="text-center">{execution.log_at}</td>
                                    </tr>
                              )
                            })
                        }
                        <EmptyTableLayout results={this.props.jobHistorical} columns={3}/>
                        </tbody>
                    </table>
                    {this.props.children}
                </div>
            </>
    )
  }
}

// Set defaultProps
JobHistoricalTable.defaultProps = {
  jobHistorical: []
}

// Export Class
export { JobHistoricalTable }
