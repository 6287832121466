// COLUMNS
export const FETCH_COLUMNS_REQUEST = 'session/FETCH_COLUMNS_REQUEST'
export const FETCH_COLUMNS_REQUEST_SUCCESS = 'session/FETCH_COLUMNS_REQUEST_SUCCESS'
export const FETCH_COLUMNS_REQUEST_FAILURE = 'session/FETCH_COLUMNS_REQUEST_FAILURE'
export const FETCH_COLUMNS_REQUEST_COMPLETED = 'session/FETCH_COLUMNS_REQUEST_COMPLETED'

export const GET_COLUMN_REQUEST = 'session/GET_COLUMN_REQUEST'
export const GET_COLUMN_REQUEST_SUCCESS = 'session/GET_COLUMN_REQUEST_SUCCESS'
export const GET_COLUMN_REQUEST_FAILURE = 'session/GET_COLUMN_REQUEST_FAILURE'
export const GET_COLUMN_REQUEST_COMPLETED = 'session/GET_COLUMN_REQUEST_COMPLETED'

export const SAVE_COLUMN_REQUEST = 'session/SAVE_COLUMN_REQUEST'
export const SAVE_COLUMN_REQUEST_SUCCESS = 'session/SAVE_COLUMN_REQUEST_SUCCESS'
export const SAVE_COLUMN_REQUEST_FAILURE = 'session/SAVE_COLUMN_REQUEST_FAILURE'
export const SAVE_COLUMN_REQUEST_COMPLETED = 'session/SAVE_COLUMN_REQUEST_COMPLETED'

export const UPDATE_COLUMN_REQUEST = 'session/UPDATE_COLUMN_REQUEST'
export const UPDATE_COLUMN_REQUEST_SUCCESS = 'session/UPDATE_COLUMN_REQUEST_SUCCESS'
export const UPDATE_COLUMN_REQUEST_FAILURE = 'session/UPDATE_COLUMN_REQUEST_FAILURE'
export const UPDATE_COLUMN_REQUEST_COMPLETED = 'session/UPDATE_COLUMN_REQUEST_COMPLETED'
