import reducer from './reducers'

import * as clientOperations from './operations'
import * as clientEndpoints from './paths'

export {
  clientOperations,
  clientEndpoints
}

export default reducer
