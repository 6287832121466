// WORKER CALLS
export const FETCH_WORKER_CALLS_REQUEST = 'session/FETCH_WORKER_CALLS_REQUEST'
export const FETCH_WORKER_CALLS_REQUEST_SUCCESS =
  'session/FETCH_WORKER_CALLS_REQUEST_SUCCESS'
export const FETCH_WORKER_CALLS_REQUEST_FAILURE =
  'session/FETCH_WORKER_CALLS_REQUEST_FAILURE'
export const FETCH_WORKER_CALLS_REQUEST_COMPLETED =
  'session/FETCH_WORKER_CALLS_REQUEST_COMPLETED'

export const GET_WORKER_CALLS_REQUEST = 'session/GET_WORKER_CALLS_REQUEST'
export const GET_WORKER_CALLS_REQUEST_SUCCESS =
  'session/GET_WORKER_CALLS_REQUEST_SUCCESS'
export const GET_WORKER_CALLS_REQUEST_FAILURE =
  'session/GET_WORKER_CALLS_REQUEST_FAILURE'
export const GET_WORKER_CALLS_REQUEST_COMPLETED =
  'session/GET_WORKER_CALLS_REQUEST_COMPLETED'

export const SAVE_WORKER_CALL_REQUEST = 'session/SAVE_WORKER_CALL_REQUEST'
export const SAVE_WORKER_CALL_REQUEST_SUCCESS =
  'session/SAVE_WORKER_CALL_REQUEST_SUCCESS'
export const SAVE_WORKER_CALL_REQUEST_FAILURE =
  'session/SAVE_WORKER_CALL_REQUEST_FAILURE'
export const SAVE_WORKER_CALL_REQUEST_COMPLETED =
  'session/SAVE_WORKER_CALL_REQUEST_COMPLETED'

export const UPDATE_WORKER_CALL_REQUEST = 'session/UPDATE_WORKER_CALL_REQUEST'
export const UPDATE_WORKER_CALL_REQUEST_SUCCESS =
  'session/UPDATE_WORKER_CALL_REQUEST_SUCCESS'
export const UPDATE_WORKER_CALL_REQUEST_FAILURE =
  'session/UPDATE_WORKER_CALL_REQUEST_FAILURE'
export const UPDATE_WORKER_CALL_REQUEST_COMPLETED =
  'session/UPDATE_WORKER_CALL_REQUEST_COMPLETED'

export const DELETE_WORKER_CALL_REQUEST = 'session/DELETE_WORKER_CALL_REQUEST'
export const DELETE_WORKER_CALL_REQUEST_SUCCESS =
  'session/DELETE_WORKER_CALL_REQUEST_SUCCESS'
export const DELETE_WORKER_CALL_REQUEST_FAILED =
  'session/DELETE_WORKER_CALL_REQUEST_FAILED'
export const DELETE_WORKER_CALL_REQUEST_COMPLETED =
  'session/DELETE_WORKER_CALL_REQUEST_COMPLETED'
