import React, { Component } from 'react'
import { InputSearchLayout, PageContainerLayout, PageHeaderLayout, PaginationLayout } from '../../../layouts'
import { AlarmsHeaderTiles, AlarmsTable } from '../../containers'
import { alarmOperations } from '../../../../state/ducks/alarms'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { AlarmShape, PaginationShape } from '../../../../prop-types'

class AlarmsPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      params: {}
    }
    this.fetchAlarms = this.fetchAlarms.bind(this)
    this.onPaginationChange = this.onPaginationChange.bind(this)
    this.onSearchChange = this.onSearchChange.bind(this)
    this.updateParams = this.updateParams.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.fetchAlarms()
  }

  fetchAlarms () {
    this.props.fetchAlarms(this.state.params)
  }

  onPaginationChange (page) {
    this.updateParams('page', page)
  }

  onSearchChange (keywork) {
    this.updateParams('search', keywork)
  }

  updateParams (key, value) {
    const { params } = this.state
    params[key] = value
    if (key === 'search') {
      params.page = 1
    }
    this.setState({
      params
    }, () => this.fetchAlarms())
  }

  renderHeaderPaths () {
    return (
            <>
                <li>Alarms</li>
            </>
    )
  }

  render () {
    return (
            <>
                <PageContainerLayout clientSidebar={true} showNavbarClientOptions={true}>
                    <PageHeaderLayout title={'Alarms'} subTitle={'All alarms'}
                                      paths={this.renderHeaderPaths}/>
                    {/* Page Content */}
                    <div className="content content-boxed">
                        {/* Header Tiles */}
                        <AlarmsHeaderTiles all_alarms={this.props.paginationData.totalItems}/>
                        {/* END Header Tiles */}
                        <div className={' block block-themed block-opt-refresh-icon7 ' + (this.props.isLoading ? ' block-opt-refresh ' : '')}>
                            <div className="block-header bg-primary-dark">
                                <ul className="block-options">
                                    <li>
                                        <button type="button" data-toggle="block-option"
                                                data-action="fullscreen_toggle">
                                            <i className="si si-size-fullscreen"/>
                                        </button>
                                    </li>
                                    <li>
                                        <button type="button"
                                                data-toggle="block-option"
                                                data-action="refresh_toggle"
                                                data-action-mode="demo"
                                                onClick={this.fetchAlarms}
                                        >
                                            <i className="si si-refresh"></i>
                                        </button>
                                    </li>
                                </ul>
                                <h3 className="block-title">All alarms</h3>
                            </div>
                            <div className="block-content">
                                {/* Search */}
                                <InputSearchLayout onSearchChange={this.onSearchChange}/>
                                {/* End Search */}
                                {/* Table */}
                                <AlarmsTable alarms={this.props.alarms} params={this.state.params}>
                                    {/* Pagination */}
                                    <PaginationLayout pagination={this.props.paginationData}
                                                      onPaginationChange={this.onPaginationChange}/>
                                    {/* End Pagination */}
                                </AlarmsTable>
                                {/* End Table */}
                            </div>
                        </div>
                    </div>
                    {/* END Page Content */}
                </PageContainerLayout>
            </>
    )
  };
}

// Set propTypes
const { arrayOf, func } = PropTypes
AlarmsPage.propTypes = {
  alarms: arrayOf(AlarmShape).isRequired,
  fetchAlarms: func.isRequired,
  paginationData: PaginationShape
}

// Set defaultProps
AlarmsPage.defaultProps = {
  alarms: [],
  paginationData: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  isLoading: state.alarms.fetchAlarmsReducer.loading,
  alarms: state.alarms.fetchAlarmsReducer.data.results,
  paginationData: state.alarms.fetchAlarmsReducer.data.pagination_data
})

// mapStateToProps
const mapDispatchToProps = {
  fetchAlarms: alarmOperations.list
}

// Export Class
const _AlarmsPage = connect(mapStateToProps, mapDispatchToProps)(AlarmsPage)
export { _AlarmsPage as AlarmsPage }
