import React, { useState, useEffect } from 'react'

import { AppUI } from '../../../../assets/js/app'
import { BaseFormWizard } from '../../../../assets/js/base_form_wizard'
import { WorkerCallShape } from '../../../../prop-types/worker-calls'
import * as PropTypes from 'prop-types'
import { workerCallsOperations } from '../../../../state/ducks/worker-calls'
import { connect } from 'react-redux'
import { Session } from '../../../../state/utils/session'
import { WorkerCallDataForm } from '../worker-call-data-form'

function WorkerCallWizardForm (props) {
  const session = new Session()
  const [workerCall, setWorkerCall] = useState({ parameters: {} })

  const onWorkerCallDataChange = (name, value) => {
    const workerCallChange = JSON.parse(JSON.stringify(workerCall))
    workerCallChange[name] = value
    setWorkerCall(workerCallChange)
  }

  const sendData = () => {
    if (!props.isEditor) {
      const workerCallSend = workerCall
      workerCallSend.client = session.getClientSession()
      props.saveWorkerCall(workerCallSend)
    } else {
      props.updateWorkerCall(workerCall.id, workerCall)
    }
  }

  useEffect(() => {
    AppUI.initHelpers(['highlightjs'])
    BaseFormWizard.init()
  }, [])

  useEffect(() => {
    if (props.workerCall && 'id' in props.workerCall) {
      setWorkerCall(props.workerCall)
    }
  }, [props.workerCall])

  return (
    <>
      <div className="content content-boxed">
        <div
          className={
            'block block-themed block-opt-refresh-icon7 ' +
            (props.isLoading ? ' block-opt-refresh ' : '')
          }
        >
          <div className="block-content">
            <div className="js-wizard-simple block">
              {/* Steps Progress */}
              <div className="block-content block-content-mini block-content-full border-b">
                <div className="wizard-progress progress active remove-margin-b">
                  <div
                    className="progress-bar progress-bar-primary progress-bar-striped"
                    role="progressbar"
                    aria-valuenow="100"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    style={{ width: '0' }}
                  ></div>
                </div>
              </div>
              {/* END Steps Progress */}

              {/* Step Tabs */}
              <ul className="nav nav-tabs nav-tabs-alt nav-justified">
                <li className="active">
                  <a href="#simple-progress-step1" data-toggle="tab">
                    1. Worker Call Data
                  </a>
                </li>
              </ul>
              {/* END Step Tabs */}

              {/* Form */}
              <form className="form-horizontal" action="javascript:void(0)">
                {/* Steps Content */}
                <div
                  className="block-content tab-content"
                  style={{ minHeight: '300px' }}
                >
                  {/* Step 1 */}
                  <div
                    className="tab-pane fade fade-right in push-30-t push-50 active"
                    id="simple-progress-step1"
                  >
                    <WorkerCallDataForm
                      workerCall={workerCall}
                      onChange={onWorkerCallDataChange}
                    />
                  </div>
                  {/* END Step 1 */}
                </div>
                {/* END Steps Content */}

                {/* Steps Navigation */}
                <div className="block-content block-content-mini block-content-full border-t">
                  <div className="row">
                    {props.saveWorkerCallErrorResponse.error ? (
                      <div className="col-xs-12 text-danger text-center">
                        {JSON.stringify(
                          props.saveWorkerCallErrorResponse.error
                        )}
                      </div>
                    ) : null}
                    {props.updateWorkerCallErrorResponse.error ? (
                      <div className="col-xs-12 text-danger text-center">
                        {JSON.stringify(
                          props.updateWorkerCallErrorResponse.error
                        )}
                      </div>
                    ) : null}
                    <div className="col-xs-6">
                      <button
                        className="wizard-prev btn btn-warning"
                        type="button"
                      >
                        <i className="fa fa-arrow-circle-o-left"></i> Previous
                      </button>
                    </div>
                    <div className="col-xs-6 text-right">
                      <button
                        className="wizard-next btn btn-success"
                        type="button"
                      >
                        Next <i className="fa fa-arrow-circle-o-right"></i>
                      </button>
                      <button
                        className="wizard-finish btn btn-primary"
                        type="button"
                        disabled={props.isLoading}
                        onClick={sendData}
                      >
                        {props.isLoading ? (
                          <>
                            <i className="ml-1 fa fa-spinner fa-spin pull-right"></i>
                            <span>Submit</span>
                          </>
                        ) : (
                          <>
                            <i className="ml-1 si si-login pull-right"></i>
                            <span>Submit</span>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                {/* END Steps Navigation */}
              </form>
              {/* END Form */}
            </div>
            {/* END Simple Progress Wizard */}
          </div>
        </div>
      </div>
    </>
  )
}

WorkerCallWizardForm.propTypes = {
  workerCall: WorkerCallShape,
  saveWorkerCallErrorResponse: PropTypes.object,
  updateWorkerCallErrorResponse: PropTypes.object,
  isEditor: PropTypes.bool
}

WorkerCallWizardForm.defaultProps = {
  workerCall: {},
  saveWorkerCallErrorResponse: {},
  updateWorkerCallErrorResponse: {},
  isEditor: false
}

const mapStateToProps = (state) => ({
  isLoading:
    state.workerCalls.saveWorkerCallReducer.loading ||
    state.workerCalls.updateWorkerCallReducer.loading,
  saveWorkerCallErrorResponse:
    state.workerCalls.saveWorkerCallReducer.error_data,
  updateWorkerCallErrorResponse:
    state.workerCalls.updateWorkerCallReducer.error_data
})

const mapDispatchToProps = {
  saveWorkerCall: workerCallsOperations.save,
  updateWorkerCall: workerCallsOperations.update
}

const _WorkerCallWizardForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkerCallWizardForm)

export { _WorkerCallWizardForm as WorkerCallWizardForm }
