import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { accountingSeatSettingsOperations } from '../../../../state/ducks/accounting-seat-settings'
import { connect } from 'react-redux'
import { BaseFormWizard } from '../../../../assets/js/base_form_wizard'
import { AccountingSeatSettingDataForm } from '../accounting-seat-settings-data-form'
import { AppUI } from '../../../../assets/js/app'
import { Session } from '../../../../state/utils/session'

class AccountingSeatSettingsWizardForm extends Component {
  constructor (props) {
    super(props)

    // state
    this.state = {
      session: new Session(),
      accountingSeatSetting: { parameters: {} }
    }

    // handlers
    this.onAccountingSeatSettingsDataChange = this.onAccountingSeatSettingsDataChange.bind(this)
    this.sendData = this.sendData.bind(this)
    AppUI.initHelpers(['highlightjs'])
  }

  componentDidMount () {
    BaseFormWizard.init()
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.accountingSeatSetting && 'id' in nextProps.accountingSeatSetting) {
      this.setState({ accountingSeatSetting: nextProps.accountingSeatSetting })
    }
  }

  onAccountingSeatSettingsDataChange (name, value) {
    const accountingSeatSetting = JSON.parse(JSON.stringify(this.state.accountingSeatSetting))
    accountingSeatSetting[name] = value
    this.setState({
      accountingSeatSetting
    })
  }

  sendData () {
    if (!this.props.isEditor) {
      const accountingSeatSetting = this.state.accountingSeatSetting
      accountingSeatSetting.client = this.state.session.getClientSession()
      this.props.saveAccountingSeatSetting(this.state.accountingSeatSetting)
    } else {
      this.props.updateAccountingSeatSetting(this.state.accountingSeatSetting.id, this.state.accountingSeatSetting)
    }
  }

  render () {
    return (
            <>
                <div className="content content-boxed">
                    <div className={' block block-themed block-opt-refresh-icon7 ' + (this.props.isLoading ? ' block-opt-refresh ' : '')}>
                        <div className="block-content">
                            {/* Simple Progress Wizard (.js-wizard-simple class is initialized in js/pages/base_forms_wizard.js) */}
                            {/* For more examples you can check out http://vadimg.com/twitter-bootstrap-wizard-example/ */}
                            <div className="js-wizard-simple block">
                                {/* Steps Progress */}
                                <div className="block-content block-content-mini block-content-full border-b">
                                    <div className="wizard-progress progress active remove-margin-b">
                                        <div className="progress-bar progress-bar-primary progress-bar-striped"
                                             role="progressbar" aria-valuenow="100" aria-valuemin="0"
                                             aria-valuemax="100"
                                             style={{ width: '0' }}>
                                        </div>
                                    </div>
                                </div>
                                {/* END Steps Progress */}

                                {/* Step Tabs */}
                                <ul className="nav nav-tabs nav-tabs-alt nav-justified">
                                    <li className="active">
                                        <a href="#simple-progress-step1" data-toggle="tab">1. Accounting Seat Settings</a>
                                    </li>
                                </ul>
                                {/* END Step Tabs */}

                                {/* Form */}
                                <form className="form-horizontal" action="javascript:void(0)">
                                    {/* Steps Content */}
                                    <div className="block-content tab-content" style={{ minHeight: '300px' }}>
                                        {/* Step 1 */}
                                        <div className="tab-pane fade fade-right in push-30-t push-50 active"
                                             id="simple-progress-step1">
                                            <AccountingSeatSettingDataForm
                                                accountingSeatSetting={this.state.accountingSeatSetting}
                                                onChange={this.onAccountingSeatSettingsDataChange}
                                            />
                                        </div>
                                        {/* END Step 1 */}
                                    </div>
                                    {/* END Steps Content */}

                                    {/* Steps Navigation */}
                                    <div className="block-content block-content-mini block-content-full border-t">
                                        <div className="row">
                                            {
                                                this.props.saveAccountingSeatSettingErrorResponse.error
                                                  ? <div className="col-xs-12 text-danger text-center">
                                                        {JSON.stringify(this.props.saveAccountingSeatSettingErrorResponse.error)}
                                                    </div>
                                                  : null
                                            }
                                            {
                                                this.props.updateAccountingSeatSettingErrorResponse.error
                                                  ? <div className="col-xs-12 text-danger text-center">
                                                        {JSON.stringify(this.props.updateAccountingSeatSettingErrorResponse.error)}
                                                    </div>
                                                  : null
                                            }
                                            <div className="col-xs-6">
                                                <button
                                                    className="wizard-prev btn btn-warning"
                                                    type="button"
                                                >
                                                    <i className="fa fa-arrow-circle-o-left"></i> Previous
                                                </button>
                                            </div>
                                            <div className="col-xs-6 text-right">
                                                <button
                                                    className="wizard-next btn btn-success"
                                                    type="button"
                                                >
                                                    Next <i className="fa fa-arrow-circle-o-right"></i>
                                                </button>
                                                <button
                                                    className="wizard-finish btn btn-primary"
                                                    type="button"
                                                    disabled={this.props.isLoading}
                                                    onClick={this.sendData}
                                                >
                                                    {
                                                        this.props.isLoading
                                                          ? <>
                                                                <i className="ml-1 fa fa-spinner fa-spin pull-right"></i>
                                                                <span>Submit</span>
                                                            </>
                                                          : <>
                                                                <i className="ml-1 si si-login pull-right"></i>
                                                                <span>Submit</span>
                                                            </>
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* END Steps Navigation */}
                                </form>
                                {/* END Form */}
                            </div>
                            {/* END Simple Progress Wizard */}
                            {/* <pre className="pre-sh push"> */}
                            {/*    <code className="json"> */}
                            {/*    {JSON.stringify(this.state.accountingSeatSetting)} */}
                            {/*    </code> */}
                            {/* </pre> */}
                        </div>
                    </div>
                </div>
            </>
    )
  }
}

// Set propTypes
AccountingSeatSettingsWizardForm.propTypes = {
  accountingSeatSetting: PropTypes.object,
  saveAccountingSeatSettingErrorResponse: PropTypes.object,
  updateAccountingSeatSettingErrorResponse: PropTypes.object,
  isEditor: PropTypes.bool
}

// Set defaultProps
AccountingSeatSettingsWizardForm.defaultProps = {
  accountingSeatSetting: {},
  saveAccountingSeatSettingErrorResponse: {},
  updateAccountingSeatSettingErrorResponse: {},
  isEditor: false
}

// mapStateToProps
const mapStateToProps = (state) => ({
  isLoading: state.accountingSeatSettings.saveAccountingSeatSettingReducer.loading || state.accountingSeatSettings.updateAccountingSeatSettingReducer.loading,
  saveAccountingSeatSettingErrorResponse: state.accountingSeatSettings.saveAccountingSeatSettingReducer.error_data,
  updateAccountingSeatSettingErrorResponse: state.accountingSeatSettings.updateAccountingSeatSettingReducer.error_data
})

// mapStateToProps
const mapDispatchToProps = {
  saveAccountingSeatSetting: accountingSeatSettingsOperations.save,
  updateAccountingSeatSetting: accountingSeatSettingsOperations.update
}

// Export Class
const _AccountingSeatSettingsWizardForm = connect(mapStateToProps, mapDispatchToProps)(AccountingSeatSettingsWizardForm)
export { _AccountingSeatSettingsWizardForm as AccountingSeatSettingsWizardForm }
