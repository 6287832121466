import React, { Component } from 'react'
import { PageContainerLayout, PageHeaderLayout } from '../../../layouts'
import { NavLink } from 'react-router-dom'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { ExtractionShape } from '../../../../prop-types'
import * as PropTypes from 'prop-types'
import { extractionOperations } from '../../../../state/ducks/extractions'
import { connect } from 'react-redux'
import { Session } from '../../../../state/utils/session'
import { ExtractionDetailsCard, ExtractionTimelineCard } from '../../containers'
import { connectionOperations } from '../../../../state/ducks/connections'

class ExtractionDetailsPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }

    this.renderHeaderPaths = this.renderHeaderPaths.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.props.getExtraction(this.props.match.params.extraction_id)

    if (this.props.connections_list.length) return
    this.props.fetchConnections({})
  }

  renderHeaderPaths () {
    return (
            <>
                <li>
                    <NavLink className="link-effect"
                             to={ROUTING_PATHS.CLIENT_DASHBOARD_EXTRACTIONS_PATH.replace(':client_id', this.state.session.getClientSession())}
                    >
                        Extractions
                    </NavLink>
                </li>
                <li>Extraction Details</li>
            </>
    )
  }

  render () {
    return (
            <>
                <PageContainerLayout clientSidebar={true} showNavbarClientOptions={true}>
                    <PageHeaderLayout
                        title={this.props.extraction.name}
                        subTitle={'Extraction Details'}
                        paths={this.renderHeaderPaths}
                    />
                    <div className="content content-boxed">
                        <div className="row">
                            <div className="col-sm-12 col-lg-5">
                                {/* Details */}
                                <ExtractionDetailsCard extraction={this.props.extraction} connections={this.props.connections_list}/>
                                {/* END Details */}
                            </div>
                            <div className="col-sm-12 col-lg-7">
                                {/* Timeline */}
                                <ExtractionTimelineCard extraction={this.props.extraction}/>
                                {/* END Timeline */}
                            </div>
                        </div>
                    </div>
                </PageContainerLayout>
            </>
    )
  }
}

// Set propTypes
ExtractionDetailsPage.propTypes = {
  extraction: ExtractionShape,
  getExtractionErrorResponse: PropTypes.object
}

// Set defaultProps
ExtractionDetailsPage.defaultProps = {
  extraction: {},
  getExtractionErrorResponse: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  extraction: state.extractions.getExtractionReducer.data,
  isLoading: state.extractions.getExtractionReducer.loading,
  getExtractionErrorResponse: state.extractions.getExtractionReducer.error_data,
  connections_list: state.connections.fetchConnectionsReducer.data.results
})

// mapStateToProps
const mapDispatchToProps = {
  getExtraction: extractionOperations.get,
  fetchConnections: connectionOperations.list
}

// Export Class
const _ExtractionDetailsPage = connect(mapStateToProps, mapDispatchToProps)(ExtractionDetailsPage)
export { _ExtractionDetailsPage as ExtractionDetailsPage }
