import React, { Component } from 'react'
import { EmptyTableLayout } from '../../../layouts'
import { history } from '../../../../routing/History'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { Session } from '../../../../state/utils/session'
import { Link } from 'react-router-dom'
import { ExecutionsModal } from '../executions-modal'
import Swal from 'sweetalert2'
import { alarmOperations } from '../../../../state/ducks/alarms'
import { connect } from 'react-redux'

class AlarmsTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session(),
      alarm_to_execute: {}
    }

    this.goToAlarmEditor = this.goToAlarmEditor.bind(this)
    this.selectAlarm = this.selectAlarm.bind(this)
    this.deleteAlarm = this.deleteAlarm.bind(this)
    this.executeExecute = this.executeExecute.bind(this)
  }

  executeExecute (alarm) {
    this.setState({
      alarm_to_execute: alarm
    }, () => {
      window.jQuery('#executions-modal').modal('show')
    })
  }

  goToAlarmEditor (alarm) {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_ALARM_EDITOR_PATH
        .replace(':alarm_id', alarm.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  selectAlarm (alarm) {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_ALARM_DETAILS_PATH
        .replace(':alarm_id', alarm.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  deleteAlarm (alarm) {
    Swal.fire({
      title: '',
      html: `<b>Por favor confirme la eliminación de la alarma ${alarm.name}?</b>`,
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.dismiss !== 'backdrop') {
        if (result.value) {
          this.props.removeAlarm(this.props.params, alarm.id)
        }
      }
    })
  }

  returnActiveLabel (alarm) {
    if (alarm.active) {
      return (
                <>
                    <span className="label label-success">
                        <i className="fa fa-check"></i>
                    </span>
                </>
      )
    } else {
      return (
                <>
                    <span className="label label-danger">
                        <i className="fa fa-close"></i>
                    </span>
                </>
      )
    }
  }

  render () {
    return (
            <>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered table-condensed text-center">
                        <thead>
                        <tr>
                            <th className="text-center">ID</th>
                            <th className="text-center">Name</th>
                            <th className="text-center">Connection</th>
                            <th className="text-center">Active</th>
                            <th className="text-center">Options</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.alarms.map((alarm) => {
                              return (
                                    <tr key={alarm.id}>
                                        <td className="text-center">{alarm.id}</td>
                                        <td className="text-center">{alarm.name}</td>
                                        <td className="text-center">
                                            <Link to={
                                                ROUTING_PATHS.CLIENT_DASHBOARD_CONNECTION_DETAILS_PATH
                                                  .replace(':client_id', this.state.session.getClientSession())
                                                  .replace(':connection_id', alarm.connection)
                                            }>
                                                {alarm.connection_name}
                                            </Link>
                                        </td>
                                        <td className="text-center">{this.returnActiveLabel(alarm)}</td>
                                        <td className="text-center">
                                            <div className="btn-group">
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Run ETL"
                                                        onClick={this.executeExecute.bind(this, alarm)}
                                                >
                                                    Execute
                                                </button>
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Edit Alarm"
                                                        onClick={this.goToAlarmEditor.bind(this, alarm)}
                                                >
                                                    <i className="fa fa-pen"></i>
                                                </button>
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Select Alarm"
                                                        onClick={this.selectAlarm.bind(this, alarm)}
                                                >
                                                    <i className="fa fa-eye"></i>
                                                </button>
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Remove Alarm"
                                                        onClick={this.deleteAlarm.bind(this, alarm)}
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                              )
                            })
                        }
                        <EmptyTableLayout results={this.props.alarms} columns={5}/>
                        </tbody>
                    </table>
                    {this.props.children}
                </div>
                <ExecutionsModal object_type={'ALARM'} object={this.state.alarm_to_execute}/>
            </>
    )
  }
}

// Set defaultProps
AlarmsTable.defaultProps = {
  alarms: []
}

const mapStateToProps = (state) => ({
  isLoading: state.alarms.removeAlarmReducer.loading
})

const mapDispatchToProps = {
  removeAlarm: alarmOperations.remove
}

// Export Class
const _AlarmsTable = connect(mapStateToProps, mapDispatchToProps)(AlarmsTable)
export { _AlarmsTable as AlarmsTable }
