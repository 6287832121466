import reducer from './reducers'

import * as accountingSeatSettingsOperations from './operations'
import * as accountingSeatSettingsEndpoints from './paths'

export {
  accountingSeatSettingsOperations,
  accountingSeatSettingsEndpoints
}

export default reducer
