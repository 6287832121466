import React, { Component } from 'react'
import { history } from '../../../../routing/History'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { Session } from '../../../../state/utils/session'
import { Link } from 'react-router-dom'
import { jsonPrettify } from '../../../../utils/jsonPrettify'

class ConciliationDetailsCard extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }
    this.goToConciliationEditor = this.goToConciliationEditor.bind(this)
  }

  goToConciliationEditor () {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_CONCILIATION_EDITOR_PATH
        .replace(':conciliation_id', this.props.conciliation.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  render () {
    return (
            <>
                <div className="block">
                    <div className="block-header bg-gray-lighter">
                        <ul className="block-options">
                            <li>
                                <button type="button" data-toggle="block-option" data-action="content_toggle"></button>
                            </li>
                            <li>
                                <button type="button" data-toggle="block-option"
                                        data-action="fullscreen_toggle">
                                    <i className="si si-size-fullscreen"/>
                                </button>
                            </li>
                            <li>
                                <button type="button" onClick={this.goToConciliationEditor}>
                                    <i className="fa fa-edit"></i>
                                </button>
                            </li>
                        </ul>
                        <h3 className="block-title">
                            <i className="fa fa-info-circle"></i> Details
                        </h3>
                    </div>
                    <div className="block-content" style={{ height: '300px', overflowY: 'scroll' }}>
                        <div className="row">
                            <div className="col-sm-12 col-lg-12">
                                <ul className="list list-activity push">
                                    <li>
                                        <div className="font-w600">ID:</div>
                                        <div>{this.props.conciliation.id}</div>
                                    </li>
                                    <li>
                                        <div className="font-w600">Conciliation name:</div>
                                        <div>{this.props.conciliation.name}</div>
                                    </li>
                                    <li>
                                        <div className="font-w600">Description:</div>
                                        <div>{this.props.conciliation.description}</div>
                                    </li>
                                    <li>
                                        <div className="font-w600">Type:</div>
                                        <div>{this.props.conciliation.conciliation_type}</div>
                                    </li>
                                    <li>
                                        <div className="font-w600">Batch Order:</div>
                                        <div>{this.props.conciliation.batch_order}</div>
                                    </li>
                                    <li>
                                        <div className="font-w600">Origin Connection:</div>
                                        <div>
                                            <Link to={
                                                ROUTING_PATHS.CLIENT_DASHBOARD_CONNECTION_DETAILS_PATH
                                                  .replace(':client_id', this.state.session.getClientSession())
                                                  .replace(':connection_id', this.props.conciliation.origin_connection)
                                            }>
                                                {this.props.conciliation.origin_connection_name}
                                            </Link>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="font-w600">Destination Connection:</div>
                                        <div>
                                            <Link to={
                                                ROUTING_PATHS.CLIENT_DASHBOARD_CONNECTION_DETAILS_PATH
                                                  .replace(':client_id', this.state.session.getClientSession())
                                                  .replace(':connection_id', this.props.conciliation.destination_connection)
                                            }>
                                                {this.props.conciliation.destination_connection_name}
                                            </Link>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-sm-12 col-lg-12">
                                <ul className="list list-activity push">
                                    <li>
                                        <div className="font-w600">Parameters:</div>
                                        <div style={{ height: '350px' }}>
                                            <pre style={{ height: '100%' }}>
                                                <code>
                                                    {jsonPrettify(this.props.conciliation.parameters)}
                                                </code>
                                            </pre>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    )
  }
}

// Set defaultProps
ConciliationDetailsCard.defaultProps = {
  conciliation: {}
}

// Export Class
export { ConciliationDetailsCard }
