import React, { Component } from 'react'
import { history } from '../../../../routing/History'
import * as PATHS from '../../../../routing/Paths'
import { Session } from '../../../../state/utils/session'

class AccountingSeatSettingsHeaderTiles extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }
    this.goToAccountingSeatSettingCreator = this.goToAccountingSeatSettingCreator.bind(this)
  }

  goToAccountingSeatSettingCreator () {
    history.push(
      PATHS.CLIENT_DASHBOARD_ACCOUNTING_SEAT_SETTING_CREATOR_PATH.replace(':client_id', this.state.session.getClientSession())
    )
  }

  render () {
    return (
            <>
                <div className="row">
                    <div className="col-sm-6 col-md-3">
                        <a className="block block-link-hover3 text-center"
                           href="javascript:void(0)"
                           onClick={this.goToAccountingSeatSettingCreator}
                        >
                            <div className="block-content block-content-full">
                                <div className="h1 font-w700 text-success"><i className="fa fa-plus"></i></div>
                            </div>
                            <div className="block-content block-content-full block-content-mini bg-gray-lighter text-success font-w600">
                                Add New Setting
                            </div>
                        </a>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <a className="block block-link-hover3 text-center" href="javascript:void(0)">
                            <div className="block-content block-content-full">
                                <div className="h1 font-w700">&nbsp;</div>
                            </div>
                            <div className="block-content block-content-full block-content-mini bg-gray-lighter text-muted font-w600">
                                &nbsp;
                            </div>
                        </a>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <a className="block block-link-hover3 text-center" href="javascript:void(0)">
                            <div className="block-content block-content-full">
                                <div className="h1 font-w700">&nbsp;</div>
                            </div>
                            <div className="block-content block-content-full block-content-mini bg-gray-lighter text-muted font-w600">
                                &nbsp;
                            </div>
                        </a>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <a className="block block-link-hover3 text-center" href="javascript:void(0)">
                            <div className="block-content block-content-full">
                                <div className="h1 font-w700">&nbsp;</div>
                            </div>
                            <div className="block-content block-content-full block-content-mini bg-gray-lighter text-muted font-w600">
                                &nbsp;
                            </div>
                        </a>
                    </div>
                </div>
            </>
    )
  }
}

// Set defaultProps
AccountingSeatSettingsHeaderTiles.defaultProps = {
  statusGroups: [],
  onQueryParamsChange: () => {
  }
}

// Export Class
export { AccountingSeatSettingsHeaderTiles }
