import React, { Component } from 'react'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { sourceOperations } from '../../../../state/ducks/sources'
import { PaginationSizeLayout, TableGeneratedLayout } from '../../../layouts'
import { PaginationLayout } from '../../../layouts/pagination'

class SourceErrorTableGenerated extends Component {
  constructor (props) {
    super(props)

    // state
    this.state = {
      fullPage: false,
      queryParams: {}
    }

    this.getErrorTableGenerated = this.getErrorTableGenerated.bind(this)
    this.fullPage = this.fullPage.bind(this)
    this.getErrorTableGenerated = this.getErrorTableGenerated.bind(this)
    this.onPaginationChange = this.onPaginationChange.bind(this)
    this.onPaginationSizeChange = this.onPaginationSizeChange.bind(this)
    this.onSortBy = this.onSortBy.bind(this)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (!this.state.queryParams.connection_id) {
      this.setState({
        queryParams: {
          connection_id: this.props.source.destination_connection,
          table_name: 'ERROR__' + this.props.source.name
        }
      }, () => {
        if (!this.props.isLoading) {
          this.props.getErrorTableGenerated(this.state.queryParams)
        }
      })
    }
  }

  getErrorTableGenerated () {
    this.props.getErrorTableGenerated(this.state.queryParams)
  }

  fullPage (fullPage) {
    const currentValue = this.state.fullPage
    if (currentValue) {
      fullPage = null
    }
    this.setState({ fullPage })
  }

  updateQueryParams (key, value) {
    const { queryParams } = this.state
    queryParams[key] = value
    if (key === 'search') {
      queryParams.page = 1
    }
    this.setState({ queryParams }, () => this.getErrorTableGenerated())
  }

  onPaginationSizeChange (size) {
    this.updateQueryParams('pagination_size', size)
  }

  onSortBy (att, sortBy) {
    this.updateQueryParams('ordering', sortBy === -1 ? '-' + att : att)
  }

  onPaginationChange (page) {
    this.updateQueryParams('page', page)
  }

  render () {
    return (
            <>
                <div className={
                    ' block block-themed block-opt-refresh-icon7 ' +
                    (this.props.isLoading ? ' block-opt-refresh ' : '') +
                    (this.state.fullPage ? ' block-opt-fullscreen ' : '')
                }>
                    <div className="block-header bg-primary-dark">
                        <ul className="block-options">
                            <li>
                                <button type="button"
                                        onClick={this.fullPage.bind('loaded-table')}
                                >
                                    <i className="si si-size-fullscreen"/>
                                </button>
                            </li>
                            <li>
                                <button type="button">
                                    <i className="fa fa-download"/>
                                </button>
                            </li>
                            <li>
                                <button type="button"
                                        data-toggle="block-option"
                                        onClick={this.getErrorTableGenerated}
                                >
                                    <i className="si si-refresh"></i>
                                </button>
                            </li>
                        </ul>
                        <h3 className="block-title">{'ERROR__' + this.props.source.name}</h3>
                    </div>
                    <div className="block-content">
                        {/* Pagination Size */}
                        <PaginationSizeLayout
                            onPaginationSizeChange={this.onPaginationSizeChange}/>
                        {/* End Pagination Size */}
                        <div className="table-scrolling">
                            {/* Table */}
                            <TableGeneratedLayout
                                rows={this.props.errorTableGenerated}
                                onSortBy={this.onSortBy}
                            />
                            {/* End Table */}
                        </div>
                        {/* Pagination */}
                        <PaginationLayout
                            pagination={this.props.paginationData}
                            onPaginationChange={this.onPaginationChange}
                        />
                        {/* End Pagination */}
                    </div>
                </div>
            </>
    )
  }
}

// Set propTypes
SourceErrorTableGenerated.propTypes = {
  source: PropTypes.object.isRequired,
  errorTableGenerated: PropTypes.array,
  isLoading: PropTypes.bool,
  paginationData: PropTypes.object
}

// Set defaultProps
SourceErrorTableGenerated.defaultProps = {
  source: {},
  errorTableGenerated: [],
  isLoading: true,
  paginationData: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  errorTableGenerated: state.sources.errorTableGeneratedReducer.data.results,
  isLoading: state.sources.errorTableGeneratedReducer.loading,
  paginationData: state.sources.errorTableGeneratedReducer.data.pagination_data
})

// mapStateToProps
const mapDispatchToProps = {
  getErrorTableGenerated: sourceOperations.getErrorTableGenerated
}

// Export Class
const _SourceErrorTableGenerated = connect(mapStateToProps,
  mapDispatchToProps)(SourceErrorTableGenerated)
export { _SourceErrorTableGenerated as SourceErrorTableGenerated }
