import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import * as reducers from './ducks'
import { batchDispatchMiddleware } from 'redux-batched-actions'
import { checkNext } from './middlewares'
import { composeWithDevTools } from '@redux-devtools/extension'

export default function configureStore (initialState) {
  const rootReducer = combineReducers(reducers)

  return createStore(
    rootReducer,
    initialState,
    composeWithDevTools(
      applyMiddleware(
        batchDispatchMiddleware,
        checkNext,
        thunkMiddleware
      )
    )
  )
}
