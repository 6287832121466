import React, { Component } from 'react'
import { history } from '../../../../routing/History'
import * as PATHS from '../../../../routing/Paths'
import { Session } from '../../../../state/utils/session'

class AccountingSeatsHeaderTiles extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }
    this.goToGroupCreator = this.goToGroupCreator.bind(this)
    this.onQueryParamsChange = this.onQueryParamsChange.bind(this)
    this.returnGroupCount = this.returnGroupCount.bind(this)
    this.totalItems = this.totalItems.bind(this)
  }

  goToGroupCreator () {
    history.push(
      PATHS.CLIENT_DASHBOARD_ACCOUNTING_SEATS_DASHBOARD_PATH.replace(':client_id', this.state.session.getClientSession())
    )
  }

  onQueryParamsChange (queryParams) {
    this.props.onQueryParamsChange(queryParams)
  }

  returnGroupCount (group) {
    const res = this.props.statusGroups.find(s => s.group === group)
    return res ? res.count : 0
  }

  totalItems () {
    let total = 0
    this.props.statusGroups.forEach(s => { total += s.count })
    return total
  }

  render () {
    return (
            <>
                <div className="row">
                    <div className="col-sm-6 col-md-2">
                        <a className="block block-link-hover3 text-center cursor-pointer"
                           onClick={(e) => {
                             e.preventDefault()
                             this.onQueryParamsChange({ status: 1 })
                           }}
                        >
                            <div className="block-content block-content-full">
                                <div className="h1 font-w700">
                                    {this.returnGroupCount(1)}
                                </div>
                            </div>
                            <div
                                className="block-content block-content-full block-content-mini bg-gray-lighter text-muted font-w600">
                                Status 1
                            </div>
                        </a>
                    </div>
                    <div className="col-sm-6 col-md-2">
                        <a className="block block-link-hover3 text-center cursor-pointer"
                           onClick={(e) => {
                             e.preventDefault()
                             this.onQueryParamsChange({ status: 2 })
                           }}
                        >
                            <div className="block-content block-content-full">
                                <div className="h1 font-w700">
                                    {this.returnGroupCount(2)}
                                </div>
                            </div>
                            <div
                                className="block-content block-content-full block-content-mini bg-gray-lighter text-muted font-w600">
                                Status 2
                            </div>
                        </a>
                    </div>
                    <div className="col-sm-6 col-md-2">
                        <a className="block block-link-hover3 text-center cursor-pointer"
                           onClick={(e) => {
                             e.preventDefault()
                             this.onQueryParamsChange({ status: 3 })
                           }}
                        >
                            <div className="block-content block-content-full">
                                <div className="h1 font-w700">
                                    {this.returnGroupCount(3)}
                                </div>
                            </div>
                            <div
                                className="block-content block-content-full block-content-mini bg-gray-lighter text-muted font-w600">
                                Status 3
                            </div>
                        </a>
                    </div>
                    <div className="col-sm-6 col-md-2">
                        <a className="block block-link-hover3 text-center cursor-pointer"
                           onClick={(e) => {
                             e.preventDefault()
                             this.onQueryParamsChange({ status: 4 })
                           }}
                        >
                            <div className="block-content block-content-full">
                                <div className="h1 font-w700">
                                    {this.returnGroupCount(4)}
                                </div>
                            </div>
                            <div
                                className="block-content block-content-full block-content-mini bg-gray-lighter text-muted font-w600">
                                Status 4
                            </div>
                        </a>
                    </div>
                    <div className="col-sm-6 col-md-2">
                        <a className="block block-link-hover3 text-center cursor-pointer"
                           onClick={(e) => {
                             e.preventDefault()
                             this.onQueryParamsChange({ status: 5 })
                           }}
                        >
                            <div className="block-content block-content-full">
                                <div className="h1 font-w700">
                                    {this.returnGroupCount(5)}
                                </div>
                            </div>
                            <div
                                className="block-content block-content-full block-content-mini bg-gray-lighter text-muted font-w600">
                                Status 5
                            </div>
                        </a>
                    </div>
                    <div className="col-sm-6 col-md-2">
                        <a className="block block-link-hover3 text-center cursor-pointer"
                           onClick={(e) => { e.preventDefault(); this.onQueryParamsChange() }}
                        >
                            <div className="block-content block-content-full">
                                <div className="h1 font-w700">
                                    {this.totalItems()}
                                </div>
                            </div>
                            <div
                                className="block-content block-content-full block-content-mini bg-gray-lighter text-muted font-w600">
                                All
                            </div>
                        </a>
                    </div>
                </div>
            </>
    )
  }
}

// Set defaultProps
AccountingSeatsHeaderTiles.defaultProps = {
  statusGroups: [],
  onQueryParamsChange: () => {
  }
}

// Export Class
export { AccountingSeatsHeaderTiles }
