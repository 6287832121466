const DatabaseTableLabelsMapping = {
  clients: { label: 'Clientes', batchOrder: 1 },
  connections: { label: 'Conexiones', batchOrder: 2 },
  alarms: { label: 'Alarmas', batchOrder: 3 },
  automations: { label: 'Automatizaciones', batchOrder: 3 },
  conciliations: { label: 'Conciliaciones', batchOrder: 3 },
  extractions: { label: 'Extracciones', batchOrder: 3 },
  sources: { label: 'Fuentes', batchOrder: 3 },
  file_deleting_tablenamematchvalue: {
    label: 'Eliminación de archivos: TableNameMatchValue',
    batchOrder: 3
  },
  file_deleting_filedeletingconfig: {
    label: 'Eliminación de archivos: FileDeletingConfig',
    batchOrder: 3
  },
  file_deleting_accountingannulmentactionconfig: {
    label: 'Eliminación de archivos: AccountingAnnulmentActionConfig',
    batchOrder: 3
  },
  worker_workercall: {
    label: 'Worker Calls',
    batchOrder: 3
  },
  automations_alarms: { label: 'Automatizaciones: Alarmas', batchOrder: 4 },
  automations_conciliations: {
    label: 'Automatizaciones: Conciliaciones',
    batchOrder: 4
  },
  automations_extractions: {
    label: 'Automatizaciones: Extracciones',
    batchOrder: 4
  },
  automations_sources: { label: 'Automatizaciones: Fuentes', batchOrder: 4 },
  columns: { label: 'Fuentes: Columnas', batchOrder: 4 },
  uniqueness: { label: 'Fuentes: Uniqueness', batchOrder: 4 },
  automations_worker_calls: {
    label: 'Automatizaciones: Worker Calls',
    batchOrder: 4
  },
  accounting_seat_settings: { label: 'Asientos Contables', batchOrder: 5 },
  normalizations: { label: 'Fuentes: Normalizaciones', batchOrder: 5 },
  s3_manager_api_s3managergroup: { label: 'S3 Manager: Grupos', batchOrder: 5 },
  s3_manager_api_s3managersettings: {
    label: 'S3 Manager: Configuraciones',
    batchOrder: 5
  },
  s3_manager_api_globalconcimanualconfig: {
    label: 'Global Concil Manual Config',
    batchOrder: 5
  },
  s3_manager_api_globalconcimanualconfigcbk: {
    label: 'Global Concil Manual Config Adj CBK',
    batchOrder: 5
  },
  uniqueness_order_key: {
    label: 'Fuentes: Uniqueness Order Key',
    batchOrder: 5
  },
  uniqueness_partition_key: {
    label: 'Fuentes: Uniqueness Partition Key',
    batchOrder: 5
  },
  transactional_management_api_source: {
    label: 'Gestión Transaccional: Fuentes',
    batchOrder: 6
  },
  transactional_management_api_tag: {
    label: 'Gestión Transaccional: Tag',
    batchOrder: 6
  },
  transactional_management_api_sourcetag: {
    label: 'Gestión Transaccional: Source Tag',
    batchOrder: 7
  },
  transactional_management_api_customcolumn: {
    label: 'Gestión Transaccional: Custom Column',
    batchOrder: 7
  },
  transactional_management_api_customcolumnvalue: {
    label: 'Gestión Transaccional: Custom Column Value',
    batchOrder: 8
  },
  transactional_management_api_tablefortagsource: {
    label: 'Gestión Transaccional: Table For Tag Source',
    batchOrder: 8
  },
  transactional_management_api_tagoption: {
    label: 'Gestión Transaccional: Tag Option',
    batchOrder: 8
  },
  core_accountingannulmentreportconfig: {
    label: 'Desconciliación: Accounting Annulment Report Config',
    batchOrder: 4
  },
  manual_conciliation_api_adjustmentreason: {
    label: 'Conciliación Manual: Razon de ajuste',
    batchOrder: 8
  },
  manual_conciliation_api_report: {
    label: 'Conciliación Manual: Reporte',
    batchOrder: 8
  },
  manual_conciliation_api_metadata: {
    label: 'Conciliación Manual: Metadato',
    batchOrder: 9
  },
  manual_conciliation_api_missing: {
    label: 'Conciliación Manual: Missing',
    batchOrder: 9
  },
  manual_conciliation_api_reportmetadata: {
    label: 'Conciliación Manual: Reporte-Metadato',
    batchOrder: 9
  }
}

const getSortedDatabaseMappings = (changes) => {
  const arr = []
  for (const prop in changes) {
    if (changes.hasOwnProperty(prop)) {
      arr.push({
        key: prop,
        value: changes[prop]
      })
    }
  }
  arr.sort(function (a, b) {
    return a.value.batchOrder - b.value.batchOrder
  })
  return arr
}

const warnings = {
  Alarmas: 'El ESTADO de las ALARMAS debe ser ajustado manualmente accediendo al Admin',
  'Asientos Contables':
    'El campo CONFIG debe ser ajustado manualmente por POSTMAN',
  Automatizaciones: 'El ESTADO de las AUTOMATIZACIONES debe ser ajustado manualmente accediendo al Admin',
  Conexiones:
    'El campo PARAMETERS debe ser ajustado manualmente accediendo al Admin',
  'S3 Manager: Configuraciones':
    'El campo FOLDER debe ser ajustado manualmente por POSTMAN'
}

export { DatabaseTableLabelsMapping, getSortedDatabaseMappings, warnings }
