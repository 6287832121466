import React, { Component } from 'react'
import { EmptyTableLayout } from '../empty-table'
import { returnSortIcon, updateThSortBy } from '../../../utils/tables'

class TableGeneratedLayout extends Component {
  constructor (props) {
    super(props)

    this.state = {
      thList: []
    }
    this.applySort = this.applySort.bind(this)
    this.updateThList = this.updateThList.bind(this)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    this.updateThList()
  }

  updateThList () {
    const thList = []
    if (this.props.rows[0] && !this.state.thList.length) {
      Object.keys(this.props.rows[0]).forEach((key) => {
        thList.push({
          title: key,
          att: key,
          sortedBy: null
        })
      })
      this.setState({ thList })
    }
  }

  applySort (index) {
    const thList = JSON.parse(JSON.stringify(this.state.thList))
    thList[index] = updateThSortBy(thList[index])
    this.setState({ thList }, () => {
      this.props.onSortBy(thList[index].att, thList[index].sortedBy)
    })
  }

  render () {
    return (
            <>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered table-condensed text-center">
                        <thead>
                        <tr>
                            {
                                this.state.thList.map((th, index) => {
                                  return (
                                        <th
                                            key={index}
                                            className={'text-center cursor-pointer'}
                                            onClick={this.applySort.bind(this, index)}
                                        >
                                            {th.title}
                                            &nbsp;
                                            {returnSortIcon(th.sortedBy)}
                                        </th>
                                  )
                                })
                            }
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.rows[0]
                              ? this.props.rows.map((object, index) => {
                                return (
                                        <tr key={index}>
                                            {
                                                Object.keys(object).map((key, index) => {
                                                  return (
                                                        <td className="text-center" key={index}>{object[key]}</td>
                                                  )
                                                })
                                            }
                                        </tr>
                                )
                              })
                              : <></>

                        }
                        <EmptyTableLayout results={this.props.rows} columns={1}/>
                        </tbody>
                    </table>
                    {this.props.children}
                </div>
            </>
    )
  }
}

// Set defaultProps
TableGeneratedLayout.defaultProps = {
  rows: [],
  onSortBy: () => {
  }
}

// Export Class
export { TableGeneratedLayout }
