import React, { Component } from 'react'
import { ParametersCreatorForm } from '../../containers'
import SelectSearchLayout from '../../../layouts/select-search'
import * as PropTypes from 'prop-types'
import { connectionOperations } from '../../../../state/ducks/connections'
import { connect } from 'react-redux'

class SourceDataForm extends Component {
  constructor (props) {
    super(props)

    // state
    this.state = {
      driver: ''
    }

    this.handleChange = this.handleChange.bind(this)
    this.onParametersChange = this.onParametersChange.bind(this)
    this.returnConnectionOptions = this.returnConnectionOptions.bind(this)
    this.onOriginConnectionSearchKeywordChange = this.onOriginConnectionSearchKeywordChange.bind(this)
    this.onSelectOriginConnection = this.onSelectOriginConnection.bind(this)
    this.onSelectDestinationConnection = this.onSelectDestinationConnection.bind(this)
    this.handleBatchOrderChange = this.handleBatchOrderChange.bind(this)
  }

  componentDidMount () {
    if (this.props.connections_list.length) return

    this.props.fetchConnections({})
  }

  handleChange (event) {
    this.props.onChange(event.target.name, event.target.value)
  }

  handleBatchOrderChange (event) {
    this.props.onChange(event.target.name, parseFloat(event.target.value))
  }

  onParametersChange (parametersList) {
    this.props.onChange('parameters', parametersList)
  }

  returnConnectionOptions () {
    const options = []
    this.props.connections_list.forEach(element => {
      options.push({
        value: element.id + '|' + element.name,
        label: element.name
      })
    })
    return options
  }

  onOriginConnectionSearchKeywordChange (keyword) {
    this.returnConnectionOptions()
    this.props.fetchConnections({ search: keyword })
  }

  onSelectOriginConnection (item) {
    const split_ = item.value.split('|')
    // id: split_[0,
    // connection_name: split_[1]
    this.props.onChange('origin_connection', split_[0])
  }

  onSelectDestinationConnection (item) {
    const split_ = item.value.split('|')
    // id: split_[0,
    // connection_name: split_[1]
    this.props.onChange('destination_connection', split_[0])
  }

  render () {
    return (
            <>
                <div className="form-group">
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <div className="form-material">
                            <input className="form-control"
                                   type="text"
                                   id="input-name"
                                   placeholder="Please enter a name"
                                   name="name"
                                   value={this.props.source.name || ''}
                                   onChange={this.handleChange}
                            />
                            <label htmlFor="input-name">Name</label>
                        </div>
                    </div>
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <div className="form-material">
                            <label htmlFor="input-connections">Origin Connection</label>
                            <SelectSearchLayout
                                placeholder={this.props.source.origin_connection ? this.props.source.origin_connection_name : 'Type to search an item...'}
                                getOptions={this.returnConnectionOptions}
                                onSearchChange={this.onOriginConnectionSearchKeywordChange}
                                onSelectItem={this.onSelectOriginConnection}
                            />
                        </div>
                    </div>
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <div className="form-material">
                            <label htmlFor="input-connections">Destination Connection</label>
                            <SelectSearchLayout
                                placeholder={this.props.source.destination_connection ? this.props.source.destination_connection_name : 'Type to search an item...'}
                                getOptions={this.returnConnectionOptions}
                                onSearchChange={this.onOriginConnectionSearchKeywordChange}
                                onSelectItem={this.onSelectDestinationConnection}
                            />
                        </div>
                    </div>
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <div className="form-material">
                            <input
                                className="form-control"
                                type="number"
                                id="input-batch_order"
                                name="batch_order"
                                value={this.props.source.batch_order}
                                onChange={this.handleBatchOrderChange}
                            />
                            <label htmlFor="input-batch_order">Batch Order</label>
                        </div>
                    </div>
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <div className="form-material">
                            <label>Parameters</label>
                            <ParametersCreatorForm
                                parameters={this.props.source.parameters}
                                onParametersChange={this.onParametersChange}
                            />
                        </div>
                    </div>
                </div>
            </>
    )
  }
}

// Set propTypes
SourceDataForm.propTypes = {
  fetchConnections: PropTypes.func.isRequired
}

// Set defaultProps
SourceDataForm.defaultProps = {
  source: { parameters: {}, columns: [] },
  onChange: () => {
  }
}

// mapStateToProps
const mapStateToProps = (state) => ({
  connections_list: state.connections.fetchConnectionsReducer.data.results
})

// mapStateToProps
const mapDispatchToProps = {
  fetchConnections: connectionOperations.list
}

// Export Class
const _SourceDataForm = connect(mapStateToProps,
  mapDispatchToProps)(SourceDataForm)
export { _SourceDataForm as SourceDataForm }
