import React, { Component } from 'react'
import { history } from '../../../../routing/History'
import * as ROUTING_PATHS from '../../../../routing/Paths'

class GroupDetailsCard extends Component {
  constructor (props) {
    super(props)

    this.state = {}
    this.goToGroupEditor = this.goToGroupEditor.bind(this)
  }

  goToGroupEditor () {
    history.push(ROUTING_PATHS.GROUP_EDITOR_PATH.replace(':group_id', this.props.group.id))
  }

  render () {
    return (
            <>
                <div className="block">
                    <div className="block-header bg-gray-lighter">
                        <ul className="block-options">
                            <li>
                                <button type="button" onClick={this.goToGroupEditor}>
                                    <i className="fa fa-edit"></i>
                                </button>
                            </li>
                        </ul>
                        <h3 className="block-title">
                            <i className="fa fa-info-circle"></i> Details
                        </h3>
                    </div>
                    <div className="block-content">
                        <ul className="list list-activity push">
                            <li>
                                <div className="font-w600">Name:</div>
                                <div>{this.props.group.name}</div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="block">
                    <div className="block-header bg-gray-lighter">
                        <ul className="block-options">
                            <li>
                                <button type="button" onClick={this.goToGroupEditor}>
                                    <i className="fa fa-edit"></i>
                                </button>
                            </li>
                        </ul>
                        <h3 className="block-title">
                            <i className="fa fa-lock"></i> Permissions
                        </h3>
                    </div>
                    <div className="block-content">
                        <ul className="list list-activity push">
                            {
                                this.props.group.permissions.map((p, index) => {
                                  return (
                                        <li key={index}>
                                            <div className="font-w600">Permission Codename:</div>
                                            <div>{p.codename}</div>
                                            <small className="text-muted">{p.codename}</small>

                                        </li>
                                  )
                                })
                            }
                        </ul>
                    </div>
                </div>
                <div className="block">
                    <div className="block-header bg-gray-lighter">
                        <ul className="block-options">
                            <li>
                                <button type="button">
                                    <i className="fa fa-edit"></i>
                                </button>
                            </li>
                        </ul>
                        <h3 className="block-title">
                            <i className="fa fa-users"></i> Users
                        </h3>
                    </div>
                    <div className="block-content">
                        <ul className="list list-activity push">
                            {
                                this.props.group.users.map((u, index) => {
                                  return (
                                        <li key={index}>
                                            <div className="font-w600">Username:</div>
                                            <div>{u.username}</div>
                                            <small className="text-muted">{u.full_name}</small>
                                        </li>
                                  )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </>
    )
  }
}

// Set defaultProps
GroupDetailsCard.defaultProps = {
  group: { permissions: [], users: [] }
}

// Export Class
export { GroupDetailsCard }
