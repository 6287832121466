import React, { Component } from 'react'

class GroupDataForm extends Component {
  constructor (props) {
    super(props)

    // state
    this.state = { group: {} }

    this.handleChange = this.handleChange.bind(this)
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    this.setState({
      group: nextProps.group
    })
  }

  handleChange (event) {
    this.props.onChange(event.target.name, event.target.value)
  }

  render () {
    return (
            <>
                <div className="form-group">
                    <div className="col-sm-8 col-sm-offset-2">
                        <div className="form-material">
                            <input className="form-control"
                                   type="text"
                                   id="input-name"
                                   placeholder="Please enter a name"
                                   name="name"
                                   value={this.state.group.name}
                                   onChange={this.handleChange}
                            />
                            <label htmlFor="input-name">Group Name</label>
                        </div>
                    </div>
                </div>
            </>
    )
  }
}

// Set default props
GroupDataForm.defaultProps = {
  group: {},
  onChange: () => {
  }
}

export {
  GroupDataForm
}
