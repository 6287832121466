import React, { Component } from 'react'
import { Session } from '../../../state/utils/session'
import { history } from '../../../routing/History'
import { HOME_PATH } from '../../../routing/Paths'

class GroupsModalLayout extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }

    this.changeGroup = this.changeGroup.bind(this)
  }

  changeGroup (groupCode) {
    this.state.session.setSelectedGroup(groupCode)
    history.push(HOME_PATH)
    window.location.reload()
  }

  render () {
    return (
            <>
                {/* Apps Modal */}
                {/* Opens from the button in the header */}
                <div className="modal fade" id="apps-modal" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-sm modal-dialog modal-dialog-top">
                        <div className="modal-content">
                            {/* Apps Block */}
                            <div className=" block block-themed block-opt-refresh-icon7 block-transparent">
                                <div className="block-header bg-primary-dark">
                                    <ul className="block-options">
                                        <li>
                                            <button data-dismiss="modal" type="button"><i className="si si-close"/>
                                            </button>
                                        </li>
                                    </ul>
                                    <h3 className="block-title">Groups</h3>
                                </div>
                                <div className="block-content">
                                    <div className="row text-center">
                                        {
                                            this.state.session.allowedActionFor(['ADMIN'])
                                              ? <div className={'col-xs-6'}>
                                                    <a className="block block-rounded" href="javascript:void(0)"
                                                       onClick={this.changeGroup.bind(this, 'ADMIN')}>
                                                        <div className="block-content text-white bg-default">
                                                            <i className="si si-speedometer fa-2x"/>
                                                            <div className="font-w600 push-15-t push-15">Admin</div>
                                                        </div>
                                                    </a>
                                                </div>
                                              : null
                                        }
                                        {
                                            this.state.session.allowedActionFor(['DEVELOPER'])
                                              ? <div className={'col-xs-6'}>
                                                    <a className="block block-rounded" href="javascript:void(0)"
                                                       onClick={this.changeGroup.bind(this, 'DEVELOPER')}>
                                                        <div className="block-content text-white bg-modern">
                                                            <i className="si si-rocket fa-2x"/>
                                                            <div className="font-w600 push-15-t push-15">Developer</div>
                                                        </div>
                                                    </a>
                                                </div>
                                              : null
                                        }
                                        {
                                            this.state.session.allowedActionFor(['VISUALIZER'])
                                              ? <div className={'col-xs-6'}>
                                                    <a className="block block-rounded" href="javascript:void(0)"
                                                       onClick={this.changeGroup.bind(this, 'VISUALIZER')}>
                                                        <div className="block-content text-white bg-amethyst-light">
                                                            <i className="fa fa-eye fa-2x"/>
                                                            <div className="font-w600 push-15-t push-15">Visualizer</div>
                                                        </div>
                                                    </a>
                                                </div>
                                              : null
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* END Apps Block */}
                        </div>
                    </div>
                </div>
                {/* END Apps Modal */}
            </>
    )
  }
}

GroupsModalLayout.defaultProps = {
  onChange: () => {
  }
}

export { GroupsModalLayout }
