import React, { Component } from 'react'
import SelectSearchLayout from '../../../layouts/select-search'
import * as PropTypes from 'prop-types'
import { connectionOperations } from '../../../../state/ducks/connections'
import { connect } from 'react-redux'

class AlarmDataForm extends Component {
  constructor (props) {
    super(props)

    // state
    this.state = {
      driver: ''
    }

    this.handleChange = this.handleChange.bind(this)
    this.returnConnectionOptions = this.returnConnectionOptions.bind(this)
    this.onOriginConnectionSearchKeywordChange = this.onOriginConnectionSearchKeywordChange.bind(this)
    this.onSelectOriginConnection = this.onSelectOriginConnection.bind(this)
    this.handleBatchOrderChange = this.handleBatchOrderChange.bind(this)
    this.handleCheckbox = this.handleCheckbox.bind(this)
  }

  componentDidMount () {
    if (this.props.connections_list.length) return

    this.props.fetchConnections({})
  }

  handleChange (event) {
    this.props.onChange(event.target.name, event.target.value)
  }

  handleBatchOrderChange (event) {
    this.props.onChange(event.target.name, parseFloat(event.target.value))
  }

  handleCheckbox (event) {
    this.props.onChange('active', !this.props.alarm.active)
  }

  returnConnectionOptions () {
    const options = []
    this.props.connections_list.forEach(element => {
      options.push({
        value: element.id + '|' + element.name,
        label: element.name
      })
    })
    return options
  }

  onOriginConnectionSearchKeywordChange (keyword) {
    this.returnConnectionOptions()
    this.props.fetchConnections({ search: keyword })
  }

  onSelectOriginConnection (item) {
    const split_ = item.value.split('|')
    // id: split_[0,
    // connection_name: split_[1]
    this.props.onChange('connection', split_[0])
  }

  render () {
    return (
            <>
                <div className="form-group">
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <div className="form-material">
                            <input className="form-control"
                                   type="text"
                                   id="input-name"
                                   placeholder="Please enter a name"
                                   name="name"
                                   value={this.props.alarm.name}
                                   onChange={this.handleChange}
                            />
                            <label htmlFor="input-name">Name</label>
                        </div>
                    </div>
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <div className="form-material">
                            <input className="form-control"
                                   type="text"
                                   id="input-description"
                                   placeholder="Please enter a description"
                                   name="description"
                                   value={this.props.alarm.description}
                                   onChange={this.handleChange}
                            />
                            <label htmlFor="input-description">Description</label>
                        </div>
                    </div>
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <div className="form-material">
                            <label htmlFor="input-connections">Origin Connection</label>
                            <SelectSearchLayout
                                placeholder={this.props.alarm.connection ? this.props.alarm.connection_name : 'Type to search an item...'}
                                getOptions={this.returnConnectionOptions}
                                onSearchChange={this.onOriginConnectionSearchKeywordChange}
                                onSelectItem={this.onSelectOriginConnection}
                            />
                        </div>
                    </div>
                    <div className="col-sm-8 col-sm-offset-2">
                        <div className="form-material">
                            <select
                                className="form-control"
                                id="input-alarm_type"
                                name="alarm_type"
                                value={this.props.alarm.alarm_type}
                                onChange={this.handleChange}
                            >
                                <option>-</option>
                                <option value={1}>Always send</option>
                                <option value={2}>Send if contains rows</option>
                                <option value={3}>Send if doesn&apos;t contain rows</option>
                            </select>
                            <label htmlFor="input-alarm_type">Type</label>
                        </div>
                    </div>
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <br/>
                        <div className="form-material">
                            <label className="css-input switch switch-success">
                                <input
                                    type="checkbox"
                                    value={this.props.alarm.active}
                                    checked={this.props.alarm.active}
                                    onChange={this.handleCheckbox}
                                    defaultChecked={true}
                                />
                                <span></span> Active?
                            </label>
                        </div>
                    </div>
                </div>
            </>
    )
  }
}

// Set propTypes
AlarmDataForm.propTypes = {
  fetchConnections: PropTypes.func.isRequired
}

// Set defaultProps
AlarmDataForm.defaultProps = {
  alarm: { parameters: {}, columns: [] },
  onChange: () => {
  }
}

// mapStateToProps
const mapStateToProps = (state) => ({
  connections_list: state.connections.fetchConnectionsReducer.data.results
})

// mapStateToProps
const mapDispatchToProps = {
  fetchConnections: connectionOperations.list
}

// Export Class
const _AlarmDataForm = connect(mapStateToProps,
  mapDispatchToProps)(AlarmDataForm)
export { _AlarmDataForm as AlarmDataForm }
