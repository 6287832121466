import React, { useEffect } from 'react'
import { Session } from '../../../../state/utils/session'
import { PageContainerLayout, PageHeaderLayout } from '../../../layouts'
import { NavLink } from 'react-router-dom'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { WorkerCallDetailsCard } from '../../containers'
import { WorkerCallShape } from '../../../../prop-types/worker-calls'
import { workerCallsOperations } from '../../../../state/ducks/worker-calls'
import { connect } from 'react-redux'

function WorkerCallDetailsPage (props) {
  const session = new Session()

  useEffect(() => {
    props.getWorkerCall(props.match.params.worker_call_id)
  }, [])

  const renderHeaderPaths = () => (
    <>
      <li>
        <NavLink
          className="link-effect"
          to={ROUTING_PATHS.CLIENT_DASHBOARD_WORKER_CALLS_PATH.replace(
            ':client_id',
            session.getClientSession()
          )}
        >
          Worker Calls
        </NavLink>
      </li>
      <li>Worker Call Details</li>
    </>
  )

  return (
    <>
      <PageContainerLayout clientSidebar={true} showNavbarClientOptions={true}>
        <PageHeaderLayout
          title={props.workerCall.name}
          subTitle={'Worker Call Details'}
          paths={renderHeaderPaths}
        />
        <div className="content content-boxed">
              {/* Details */}
              <WorkerCallDetailsCard workerCall={props.workerCall} />
              {/* END Details */}
        </div>
      </PageContainerLayout>
    </>
  )
}

WorkerCallDetailsPage.propTypes = {
  workerCall: WorkerCallShape
}

WorkerCallDetailsPage.defaultProps = {
  workerCall: {}
}

const mapStateToProps = (state) => ({
  workerCall: state.workerCalls.getWorkerCallReducer.data,
  isLoading: state.workerCalls.getWorkerCallReducer.loading
})

const mapDispatchToProps = {
  getWorkerCall: workerCallsOperations.get
}

const _WorkerCallDetailsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkerCallDetailsPage)
export { _WorkerCallDetailsPage as WorkerCallDetailsPage }
