import axios from 'axios'
import React, { useState } from 'react'
import config from '../../../../config'
import { Session } from '../../../../state/utils/session'
import styles from './index.module.css'
import { CSVDownload } from 'react-csv'

const DownloadButton = ({ name }) => {
  const [data, setData] = useState(null)

  const session = new Session()

  const handleDownload = async () => {
    try {
      const { data } = await axios.get(`${config.apiEndPoint}api/v1/clients/${session.getClientSession()}/automations/report?f=csv&entity=${name}`, {
        headers: {
          Authorization: `JWT ${session.selectedAuth}`
        }
      })
      setData(data)
      setData(null)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <i className={`${styles.icon} fa fa-download`} onClick={handleDownload}></i>
      {data && <CSVDownload data={data} target="_blank" /> }
    </>
  )
}

export { DownloadButton }
