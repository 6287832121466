// RELEASES
export const FETCH_RELEASES_REQUEST = 'session/FETCH_RELEASES_REQUEST'
export const FETCH_RELEASES_REQUEST_SUCCESS =
  'session/FETCH_RELEASES_REQUEST_SUCCESS'
export const FETCH_RELEASES_REQUEST_FAILURE =
  'session/FETCH_RELEASES_REQUEST_FAILURE'
export const FETCH_RELEASES_REQUEST_COMPLETED =
  'session/FETCH_RELEASES_REQUEST_COMPLETED'

export const GET_RELEASE_REQUEST = 'session/GET_RELEASE_REQUEST'
export const GET_RELEASE_REQUEST_SUCCESS =
  'session/GET_RELEASE_REQUEST_SUCCESS'
export const GET_RELEASE_REQUEST_FAILURE =
  'session/GET_RELEASE_REQUEST_FAILURE'
export const GET_RELEASE_REQUEST_COMPLETED =
  'session/GET_RELEASE_REQUEST_COMPLETED'

export const SAVE_RELEASE_REQUEST = 'session/SAVE_RELEASE_REQUEST'
export const SAVE_RELEASE_REQUEST_BUTTON_LOADING =
  'session/SAVE_RELEASE_REQUEST_BUTTON_LOADING'
export const SAVE_RELEASE_REQUEST_BUTTON_STOP_LOADING =
  'session/SAVE_RELEASE_REQUEST_BUTTON_STOP_LOADING'
export const SAVE_RELEASE_REQUEST_SUCCESS =
  'session/SAVE_RELEASE_REQUEST_SUCCESS'
export const SAVE_RELEASE_REQUEST_FAILURE =
  'session/SAVE_RELEASE_REQUEST_FAILURE'
export const SAVE_RELEASE_REQUEST_COMPLETED =
  'session/SAVE_RELEASE_REQUEST_COMPLETED'

export const UPDATE_RELEASE_REQUEST = 'session/UPDATE_RELEASE_REQUEST'
export const UPDATE_RELEASE_REQUEST_SUCCESS =
  'session/UPDATE_RELEASE_REQUEST_SUCCESS'
export const UPDATE_RELEASE_REQUEST_FAILURE =
  'session/UPDATE_RELEASE_REQUEST_FAILURE'
export const UPDATE_RELEASE_REQUEST_COMPLETED =
  'session/UPDATE_RELEASE_REQUEST_COMPLETED'

export const FETCH_DIFFCHECK_REQUEST = 'session/FETCH_DIFFCHECK_REQUEST'
export const FETCH_DIFFCHECK_REQUEST_SUCCESS =
  'session/FETCH_DIFFCHECK_REQUEST_SUCCESS'
export const FETCH_DIFFCHECK_REQUEST_FAILURE =
  'session/FETCH_DIFFCHECK_REQUEST_FAILURE'
export const FETCH_DIFFCHECK_REQUEST_COMPLETED =
  'session/FETCH_DIFFCHECK_REQUEST_COMPLETED'

export const SEND_DEPLOY_RELEASE_REQUEST =
  'session/SEND_DEPLOYS_RELEASE_REQUEST'
export const SEND_DEPLOY_RELEASE_REQUEST_CANCEL_LOADING =
  'END_DEPLOY_RELEASE_REQUEST_CANCEL_LOADING'
export const SEND_DEPLOY_RELEASE_REQUEST_SUCCESS =
  'session/SEND_DEPLOY_RELEASE_REQUEST_SUCCESS'
export const SEND_DEPLOY_RELEASE_REQUEST_FAILURE =
  'session/SEND_DEPLOY_RELEASE_REQUEST_FAILURE'
export const SEND_DEPLOY_RELEASE_REQUEST_COMPLETED =
  'session/SEND_DEPLOY_RELEASE_REQUEST_COMPLETED'

export const S3_FILES_RELEASES_REQUEST = 'session/S3_FILES_RELEASES_REQUEST'
export const S3_FILES_RELEASES_REQUEST_SUCCESS =
  'session/S3_FILES_RELEASES_REQUEST_SUCCESS'
export const S3_FILES_RELEASES_REQUEST_FAILURE =
  'session/S3_FILES_RELEASES_REQUEST_FAILURE'
export const S3_FILES_RELEASES_REQUEST_COMPLETED =
  'session/S3_FILES_RELEASES_REQUEST_COMPLETED'
