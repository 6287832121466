import React, { Component } from 'react'
import { PageContainerLayout, PageHeaderLayout } from '../../../layouts'
import { NavLink } from 'react-router-dom'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { AutomationShape } from '../../../../prop-types'
import * as PropTypes from 'prop-types'
import { automationOperations } from '../../../../state/ducks/automations'
import { connect } from 'react-redux'
import { Session } from '../../../../state/utils/session'
import { AutomationDetailsCard, AutomationTimelineCard, AutomationSouConAlaCard } from '../../containers'

class AutomationDetailsPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }

    this.renderHeaderPaths = this.renderHeaderPaths.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.props.getAutomation(this.props.match.params.automation_id)
  }

  renderHeaderPaths () {
    return (
            <>
                <li>
                    <NavLink className="link-effect"
                             to={ROUTING_PATHS.CLIENT_DASHBOARD_AUTOMATIONS_PATH.replace(':client_id', this.state.session.getClientSession())}
                    >
                        Automations
                    </NavLink>
                </li>
                <li>Automation Details</li>
            </>
    )
  }

  render () {
    return (
            <>
                <PageContainerLayout clientSidebar={true} showNavbarClientOptions={true}>
                    <PageHeaderLayout
                        title={this.props.automation.name}
                        subTitle={'Automation Details'}
                        paths={this.renderHeaderPaths}
                    />
                    <div className="content content-boxed">
                        <div className="row">
                            <div className="col-sm-12 col-lg-5">
                                {/* Details */}
                                <AutomationDetailsCard automation={this.props.automation}/>
                                {/* END Details */}
                            </div>
                            <div className="col-sm-12 col-lg-7">
                                {/* Sources Conciliations Alarms */}
                                <AutomationSouConAlaCard automation={this.props.automation}/>
                                {/* END Sources Conciliations Alarms */}
                            </div>
                            <div className="col-sm-12 col-lg-7">
                                {/* Timeline */}
                                <AutomationTimelineCard automation={this.props.automation}/>
                                {/* END Timeline */}
                            </div>
                        </div>
                    </div>
                </PageContainerLayout>
            </>
    )
  }
}

// Set propTypes
AutomationDetailsPage.propTypes = {
  automation: AutomationShape,
  getAutomationErrorResponse: PropTypes.object
}

// Set defaultProps
AutomationDetailsPage.defaultProps = {
  automation: {},
  getAutomationErrorResponse: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  automation: state.automations.getAutomationReducer.data,
  isLoading: state.automations.getAutomationReducer.loading,
  getAutomationErrorResponse: state.automations.getAutomationReducer.error_data
})

// mapStateToProps
const mapDispatchToProps = {
  getAutomation: automationOperations.get
}

// Export Class
const _AutomationDetailsPage = connect(mapStateToProps, mapDispatchToProps)(AutomationDetailsPage)
export { _AutomationDetailsPage as AutomationDetailsPage }
