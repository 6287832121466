import { combineReducers } from 'redux'
import * as types from './types'

const fetchColumnsInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { results: [], pagination_data: {} }
}

const getColumnInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { columns: [] }
}

const saveColumnInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

const updateColumnInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

export function fetchColumnsReducer (state = fetchColumnsInitialState, action) {
  switch (action.type) {
    case types.FETCH_COLUMNS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.FETCH_COLUMNS_REQUEST_FAILURE:
      return {
        ...fetchColumnsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.FETCH_COLUMNS_REQUEST_SUCCESS:
      return {
        ...fetchColumnsInitialState,
        data: action.payload.data
      }
    case types.FETCH_COLUMNS_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function getColumnReducer (state = getColumnInitialState, action) {
  switch (action.type) {
    case types.GET_COLUMN_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.GET_COLUMN_REQUEST_FAILURE:
      return {
        ...getColumnInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.GET_COLUMN_REQUEST_SUCCESS:
      return {
        ...getColumnInitialState,
        data: action.payload.data
      }
    case types.GET_COLUMN_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function saveColumnReducer (state = saveColumnInitialState, action) {
  switch (action.type) {
    case types.SAVE_COLUMN_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.SAVE_COLUMN_REQUEST_FAILURE:
      return {
        ...fetchColumnsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.SAVE_COLUMN_REQUEST_SUCCESS:
      return {
        ...fetchColumnsInitialState,
        data: action.payload.data
      }
    case types.SAVE_COLUMN_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function updateColumnReducer (state = updateColumnInitialState, action) {
  switch (action.type) {
    case types.UPDATE_COLUMN_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.UPDATE_COLUMN_REQUEST_FAILURE:
      return {
        ...updateColumnInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.UPDATE_COLUMN_REQUEST_SUCCESS:
      return {
        ...updateColumnInitialState,
        data: action.payload.data
      }
    case types.UPDATE_COLUMN_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export default combineReducers({
  fetchColumnsReducer,
  saveColumnReducer,
  getColumnReducer,
  updateColumnReducer
})
