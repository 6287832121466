// GROUPS
export const FETCH_GROUPS_REQUEST = 'session/FETCH_GROUPS_REQUEST'
export const FETCH_GROUPS_REQUEST_SUCCESS = 'session/FETCH_GROUPS_REQUEST_SUCCESS'
export const FETCH_GROUPS_REQUEST_FAILURE = 'session/FETCH_GROUPS_REQUEST_FAILURE'
export const FETCH_GROUPS_REQUEST_COMPLETED = 'session/FETCH_GROUPS_REQUEST_COMPLETED'

export const GET_GROUP_REQUEST = 'session/GET_GROUP_REQUEST'
export const GET_GROUP_REQUEST_SUCCESS = 'session/GET_GROUP_REQUEST_SUCCESS'
export const GET_GROUP_REQUEST_FAILURE = 'session/GET_GROUP_REQUEST_FAILURE'
export const GET_GROUP_REQUEST_COMPLETED = 'session/GET_GROUP_REQUEST_COMPLETED'

export const SAVE_GROUP_REQUEST = 'session/SAVE_GROUP_REQUEST'
export const SAVE_GROUP_REQUEST_SUCCESS = 'session/SAVE_GROUP_REQUEST_SUCCESS'
export const SAVE_GROUP_REQUEST_FAILURE = 'session/SAVE_GROUP_REQUEST_FAILURE'
export const SAVE_GROUP_REQUEST_COMPLETED = 'session/SAVE_GROUP_REQUEST_COMPLETED'

export const UPDATE_GROUP_REQUEST = 'session/UPDATE_GROUP_REQUEST'
export const UPDATE_GROUP_REQUEST_SUCCESS = 'session/UPDATE_GROUP_REQUEST_SUCCESS'
export const UPDATE_GROUP_REQUEST_FAILURE = 'session/UPDATE_GROUP_REQUEST_FAILURE'
export const UPDATE_GROUP_REQUEST_COMPLETED = 'session/UPDATE_GROUP_REQUEST_COMPLETED'
