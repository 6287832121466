import React, { Component } from 'react'
import { history } from '../../../../routing/History'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { Session } from '../../../../state/utils/session'
import { Link } from 'react-router-dom'
import { jsonPrettify } from '../../../../utils/jsonPrettify'

class ExtractionDetailsCard extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }
    this.goToExtractionEditor = this.goToExtractionEditor.bind(this)
  }

  findConnectionName (id) {
    const { name: connectionName = '' } =
          this.props.connections.find((connection) => connection.id === id) ||
          {}

    return connectionName
  }

  goToExtractionEditor () {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_EXTRACTION_EDITOR_PATH
        .replace(':extraction_id', this.props.extraction.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  render () {
    return (
            <>
                <div className="block">
                    <div className="block-header bg-gray-lighter">
                        <ul className="block-options">
                            <li>
                                <button type="button" onClick={this.goToExtractionEditor}>
                                    <i className="fa fa-edit"></i>
                                </button>
                            </li>
                        </ul>
                        <h3 className="block-title">
                            <i className="fa fa-info-circle"></i> Details
                        </h3>
                    </div>
                    <div className="block-content">
                        <ul className="list list-activity push">
                            <li>
                                <div className="font-w600">ID:</div>
                                <div>{this.props.extraction.id}</div>
                            </li>
                            <li>
                                <div className="font-w600">Extraction name:</div>
                                <div>{this.props.extraction.name}</div>
                            </li>
                            <li>
                                <div className="font-w600">Connection:</div>
                                <div>
                                    <Link to={
                                        ROUTING_PATHS.CLIENT_DASHBOARD_CONNECTION_DETAILS_PATH
                                          .replace(':client_id', this.state.session.getClientSession())
                                          .replace(':connection_id', this.props.extraction.connection)
                                    }>
                                        ConnectionName: {this.findConnectionName(this.props.extraction.connection)}
                                    </Link>
                                </div>
                            </li>
                            <li>
                                <div className="font-w600">Table:</div>
                                <div>{this.props.extraction.table}</div>
                            </li>
                            <li>
                                <div className="font-w600">Parameters:</div>
                                <div>
                                    <pre>
                                        <code>
                                            {jsonPrettify(this.props.extraction.parameters)}
                                        </code>
                                    </pre>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </>
    )
  }
}

// Set defaultProps
ExtractionDetailsCard.defaultProps = {
  extraction: {}
}

// Export Class
export { ExtractionDetailsCard }
