import React, { Component } from 'react'
import { PageContainerLayout, PageHeaderLayout } from '../../../layouts'
import { NavLink } from 'react-router-dom'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { UserWizardForm } from '../../../shared/containers'

class UserCreatorPage extends Component {
  constructor (props) {
    super(props)
  }

  renderHeaderPaths () {
    return (
            <>
                <li>
                    <NavLink className="link-effect" to={ROUTING_PATHS.USERS_PATH}>
                        Users
                    </NavLink>
                </li>
                <li>New User</li>
            </>
    )
  }

  render () {
    return (
            <>

                <PageContainerLayout clientSidebar={true} showNavbarClientOptions={true}>
                    <PageHeaderLayout
                        title={'New User'}
                        subTitle={'Create a new user'}
                        paths={this.renderHeaderPaths}
                    />
                    <UserWizardForm/>
                </PageContainerLayout>
            </>
    )
  }
}

export { UserCreatorPage }
