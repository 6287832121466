import React, { Component } from 'react'
import { EmptyTableLayout } from '../../../layouts'
import { history } from '../../../../routing/History'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { Session } from '../../../../state/utils/session'
import { Link } from 'react-router-dom'

class ExtractionsTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }

    this.goToExtractionEditor = this.goToExtractionEditor.bind(this)
    this.selectExtraction = this.selectExtraction.bind(this)
  }

  goToExtractionEditor (extraction) {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_EXTRACTION_EDITOR_PATH
        .replace(':extraction_id', extraction.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  selectExtraction (extraction) {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_EXTRACTION_DETAILS_PATH
        .replace(':extraction_id', extraction.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  returnConnectionTypeLabel (extraction) {
    switch (extraction.origin) {
      case 1:
        return (
                    <>
                        <span className="label label-primary">{extraction.origin_type}</span>
                    </>
        )
      case 2:
        return (
                    <>
                        <span className="label label-success">{extraction.origin_type}</span>
                    </>
        )
      case 3:
        return (
                    <>
                        <span className="label label-info">{extraction.origin_type}</span>
                    </>
        )
      case 4:
        return (
                    <>
                        <span className="label label-warning">{extraction.origin_type}</span>
                    </>
        )
      default:
        break
    }
  }

  render () {
    return (
            <>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered table-condensed text-center">
                        <thead>
                        <tr>
                            <th className="text-center">ID</th>
                            <th className="text-center">Name</th>
                            <th className="text-center">Table Name</th>
                            <th className="text-center">Origin</th>
                            <th className="text-center">Connection</th>
                            <th className="text-center">Options</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.extractions.map((extraction) => {
                              return (
                                    <tr key={extraction.id}>
                                        <td className="text-center">{extraction.id}</td>
                                        <td className="text-center">{extraction.name}</td>
                                        <td className="text-center">{extraction.table}</td>
                                        <td className="text-center">
                                            {this.returnConnectionTypeLabel(extraction)}
                                        </td>
                                        <td className="text-center">
                                            <Link to={
                                                ROUTING_PATHS.CLIENT_DASHBOARD_CONNECTION_DETAILS_PATH
                                                  .replace(':client_id', this.state.session.getClientSession())
                                                  .replace(':connection_id', extraction.id)
                                            }>
                                                ConnectionName:{extraction.connection_name}
                                            </Link>
                                        </td>
                                        <td className="text-center">
                                            <div className="btn-group">
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Edit Extraction"
                                                        onClick={this.goToExtractionEditor.bind(this, extraction)}
                                                >
                                                    <i className="fa fa-pen"></i>
                                                </button>
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Select Extraction"
                                                        onClick={this.selectExtraction.bind(this, extraction)}
                                                >
                                                    <i className="fa fa-eye"></i>
                                                </button>
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Remove Extraction"
                                                        onClick={() => this.props.removeExtraction(extraction.id)}
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                              )
                            })
                        }
                        <EmptyTableLayout results={this.props.extractions} columns={6}/>
                        </tbody>
                    </table>
                    {this.props.children}
                </div>
            </>
    )
  }
}

// Set defaultProps
ExtractionsTable.defaultProps = {
  extractions: [],
  removeExtraction: () => {}
}

// Export Class
export { ExtractionsTable }
