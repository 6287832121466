import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { GroupOptionsControllerLayout } from '../group-options-controller'

class StatsLayout extends Component {
  render () {
    return (
            <>
                <div className="content bg-white border-b">
                    <div className="row items-push text-uppercase">
                        {
                            this.props.items.map((item, index) => {
                              return (
                                    <GroupOptionsControllerLayout validFor={item.validFor} key={index}>
                                        <div className={this.props.col_size}>
                                            {item.title_text === 'Dashboards' ? (
                                                <Link to={item.link} className="block block-link-hover2 text-center">
                                                    <div className="block-content block-content-full bg-primary-dark">
                                                        <i className={item.title_icon + ' fa-3x text-white animated fadeIn'}></i>
                                                        <div className="font-w600 text-white-op push-15-t animated flipInX">
                                                            <h3>{item.count}</h3>
                                                            <br/>
                                                            <small>{item.title_text}</small>
                                                        </div>
                                                    </div>
                                                </Link>
                                            ) : (
                                                <Link to={item.link} className="block block-link-hover2 text-center">
                                                    <div className="block-content block-content-full bg-primary-dark">
                                                        <i className={item.title_icon + ' fa-3x text-white animated fadeIn'}></i>
                                                        <div className="font-w600 text-white-op push-15-t animated flipInX">
                                                            <h3>{item.count}</h3>
                                                            <br/>
                                                            <small>{item.title_text}</small>
                                                        </div>
                                                    </div>
                                                </Link>
                                            )
                                        }

                                        </div>
                                    </GroupOptionsControllerLayout>
                              )
                            })
                        }
                    </div>
                </div>
            </>
    )
  }
}

// Set defaultProps
StatsLayout.defaultProps = {
  col_size: 'col-xs-6 col-sm-3',
  items: [
    {
      title_icon: '',
      title_text: null,
      count: null
    }
  ]
}

export { StatsLayout }
