import * as types from './types'
import * as API_PATHS from './paths'
import apiService from '../../utils/apiService'
import {
  handleApiErrors,
  handleApiResponseSuccess
} from '../../utils'

export const resetValidationsAutomations = () => {
  return (dispatch) => {
    dispatch({ type: types.RESET_VALIDATIONS_AUTOMATIONS, payload: {} })
  }
}

export const listConciliations = (id, conciliationsIds) => {
  return (dispatch) => {
    const TYPE = types.FETCH_VALIDATIONS_AUTOMATIONS_CONCILIATIONS_REQUEST
    if (!conciliationsIds) return dispatch({ type: `${TYPE}_COMPLETED`, payload: [] })
    let params = `conciliations_ids=${conciliationsIds}`
    if (id) params += `&auto_id_exclude=${id}`
    const FINAL_PATH = `${API_PATHS.BASE_PATH('')}?${params}`
    dispatch({ type: TYPE, payload: {} })
    apiService({
      method: 'GET',
      action: TYPE,
      path: FINAL_PATH,
      async: true,
      params: null,
      body: null
    })
      .then((res) => {
        handleApiResponseSuccess(dispatch, TYPE, res)
        dispatch({ type: `${TYPE}_COMPLETED`, payload: res })
      })
      .catch((err) => {
        handleApiErrors(dispatch, TYPE, {
          data: { api_error: err, error: 'Server 500' }
        })
      })
  }
}

export const listSources = (id, sourcesIds) => {
  return (dispatch) => {
    const TYPE = types.FETCH_VALIDATIONS_AUTOMATIONS_SOURCES_REQUEST
    if (!sourcesIds) return dispatch({ type: `${TYPE}_COMPLETED`, payload: [] })
    let params = `sources_ids=${sourcesIds}`
    if (id) params += `&auto_id_exclude=${id}`
    const FINAL_PATH = `${API_PATHS.BASE_PATH('sources')}?${params}`
    dispatch({ type: TYPE, payload: {} })
    apiService({
      method: 'GET',
      action: TYPE,
      path: FINAL_PATH,
      async: true,
      params: null,
      body: null
    })
      .then((res) => {
        handleApiResponseSuccess(dispatch, TYPE, res)
        dispatch({ type: `${TYPE}_COMPLETED`, payload: res })
      })
      .catch((err) => {
        handleApiErrors(dispatch, TYPE, {
          data: { api_error: err, error: 'Server 500' }
        })
      })
  }
}

export const listAlarms = (id, alarmsIds) => {
  return (dispatch) => {
    const TYPE = types.FETCH_VALIDATIONS_AUTOMATIONS_ALARMS_REQUEST
    if (!alarmsIds) return dispatch({ type: `${TYPE}_COMPLETED`, payload: [] })
    let params = `alarms_ids=${alarmsIds}`
    if (id) params += `&auto_id_exclude=${id}`
    const FINAL_PATH = `${API_PATHS.BASE_PATH('alarms')}?${params}`
    dispatch({ type: TYPE, payload: {} })
    apiService({
      method: 'GET',
      action: TYPE,
      path: FINAL_PATH,
      async: true,
      params: null,
      body: null
    })
      .then((res) => {
        handleApiResponseSuccess(dispatch, TYPE, res)
        dispatch({ type: `${TYPE}_COMPLETED`, payload: res })
      })
      .catch((err) => {
        handleApiErrors(dispatch, TYPE, {
          data: { api_error: err, error: 'Server 500' }
        })
      })
  }
}

export const listWorkerCalls = (id, workerCallsIds) => {
  return (dispatch) => {
    const TYPE = types.FETCH_VALIDATIONS_AUTOMATIONS_WORKERCALLS_REQUEST
    if (!workerCallsIds) return dispatch({ type: `${TYPE}_COMPLETED`, payload: [] })
    let params = `worker_calls_ids=${workerCallsIds}`
    if (id) params += `&auto_id_exclude=${id}`
    const FINAL_PATH = `${API_PATHS.BASE_PATH('worker-calls')}?${params}`
    dispatch({ type: TYPE, payload: {} })
    apiService({
      method: 'GET',
      action: TYPE,
      path: FINAL_PATH,
      async: true,
      params: null,
      body: null
    })
      .then((res) => {
        handleApiResponseSuccess(dispatch, TYPE, res)
        dispatch({ type: `${TYPE}_COMPLETED`, payload: res })
      })
      .catch((err) => {
        handleApiErrors(dispatch, TYPE, {
          data: { api_error: err, error: 'Server 500' }
        })
      })
  }
}
