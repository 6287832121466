import React, { Component } from 'react'
import { ContextMenuLayout, EmptyTableLayout } from '../../../layouts'

class NonReconciledItemsTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      rows: [],
      checkedAllRows: false
    }

    this.updateStateRows = this.updateStateRows.bind(this)
    this.handleCheckAll = this.handleCheckAll.bind(this)
    this.handleRowCheck = this.handleRowCheck.bind(this)
    this.isChecked = this.isChecked.bind(this)

    // Create the ref
    this.tableRef = React.createRef()
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.rows.length) {
      this.updateStateRows(nextProps.rows)
    }
  }

  updateStateRows (newRows) {
    const currentRows = JSON.parse(JSON.stringify(this.state.rows))
    newRows.forEach(nr => {
      if (!currentRows.find(cr => cr.id === nr.id)) {
        nr.checked = this.state.checkedAllRows
        currentRows.push(nr)
      }
    })
    this.setState({ rows: currentRows })
  }

  handleCheckAll (event) {
    const currentRows = this.state.rows
    currentRows.forEach(cr => {
      cr.checked = !this.state.checkedAllRows
    })
    this.setState({
      rows: currentRows,
      checkedAllRows: !this.state.checkedAllRows
    })
  }

  handleRowCheck (row, isContextMenu = false) {
    const currentRows = this.state.rows
    let checkedAllRows = this.state.checkedAllRows
    currentRows.forEach(cr => {
      if (cr.id === row.id && !isContextMenu) {
        cr.checked = !cr.checked
      }
      if (cr.id === row.id && isContextMenu) {
        cr.checked = true
      }
      if (!cr.checked) {
        checkedAllRows = false
      }
    })
    this.setState({
      rows: currentRows,
      checkedAllRows
    })
  }

  isChecked (rowId) {
    if (this.props.checkedAllRows) {
      return true
    } else {
      return !!this.state.rows.find(cr => cr.id === rowId && cr.checked)
    }
  }

  contextMenuOptions () {
    return [
      {
        label: 'Copy',
        function: () => {
          alert('Copy')
        }
      },
      {
        label: 'Add Tags',
        function: () => {
          alert('Export as Add Tags')
        }
      },
      {
        label: 'Add Comments',
        function: () => {
          alert('Export as Add Tags')
        }
      },
      {
        label: 'Export as .CSV',
        function: () => {
          alert('Export as .CSV')
        }
      },
      {
        label: 'Export as .XLS',
        function: () => {
          alert('Export as .XLS')
        }
      }
    ]
  }

  renderContextMenu () {
    return (
            <ContextMenuLayout
                options={this.contextMenuOptions()}
                tableRef={this.tableRef}
            />
    )
  }

  renderTHeads () {
    if (this.props.rows[0]) {
      return (
                <>
                    {
                        // eslint-disable-next-line array-callback-return
                        Object.keys(this.props.rows[0]).map((key, index) => {
                          if (key !== 'checked') {
                            return (
                                    <th className="text-center" key={index}>
                                        {key}
                                    </th>
                            )
                          }
                        })
                    }
                </>
      )
    }
  }

  renderTRows () {
    return (
            <>
                {
                    this.props.rows.map((object, index) => {
                      return (
                            <tr key={index}
                                className={(this.isChecked(object.id) ? 'row-active' : '')}
                                onClick={this.handleRowCheck.bind(this, object, false)}
                                onContextMenu={this.handleRowCheck.bind(this, object, true)}
                            >
                                <td className="text-center checkbox-row">
                                    <label className="css-input css-checkbox css-checkbox-default">
                                        <input type="checkbox"
                                               name="check-item"
                                               onChange={this.handleRowCheck.bind(this, object)}
                                               checked={this.isChecked(object.id)}
                                        />
                                        <span/>
                                    </label>
                                </td>
                                {
                                    // eslint-disable-next-line array-callback-return
                                    Object.keys(object).map((key, index) => {
                                      if (key !== 'checked') {
                                        return (
                                                <td className="text-center"
                                                    key={index}
                                                >
                                                    {JSON.stringify(object[key])}
                                                </td>
                                        )
                                      }
                                    })
                                }
                            </tr>
                      )
                    })
                }
            </>
    )
  }

  render () {
    return (
            <>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered table-condensed table-hover text-center">
                        <thead>
                        <tr>
                            <th className="text-center checkbox-row">
                                <label className="css-input css-checkbox css-checkbox-default">
                                    <input type="checkbox"
                                           id="check-all"
                                           name="check_all"
                                           onChange={this.handleCheckAll}
                                           checked={this.state.checkedAllRows}
                                    />
                                    <span/>
                                </label>
                            </th>
                            {/* THeads */}
                            {this.renderTHeads()}
                            {/* End THeads */}
                        </tr>
                        </thead>
                        <tbody ref={this.tableRef}>
                        {/* Rows */}
                        {this.renderTRows()}
                        {/* End Rows */}
                        {/* Empty Table */}
                        <EmptyTableLayout
                            results={this.props.rows}
                            columns={Object.keys(this.props.rows).length}
                        />
                        {/* End Empty Table */}
                        </tbody>
                    </table>
                    {/* Children */}
                    {this.props.children}
                    {/* End Children */}
                </div>
                {/* Context Menu */}
                {this.renderContextMenu()}
                {/* End Context Menu */}
            </>
    )
  }
}

// Set defaultProps
NonReconciledItemsTable.defaultProps = {
  rows: [],
  onCopy: () => {

  },
  onAddTags: () => {

  },
  onAddComments: () => {

  },
  onExportAsCSV: () => {

  },
  onExportAsXLS: () => {

  }
}

// Export Class
export { NonReconciledItemsTable }
