import React, { Component } from 'react'
import {
  PageContainerLayout, PaginationLayout
} from '../../../layouts'
import { Session } from '../../../../state/utils/session'
import { connect } from 'react-redux'
import { accountingEntriesOperations } from '../../../../state/ducks/accounting'

import moment from 'moment'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import 'react-dates/initialize'
import { DateRangePicker, SingleDatePicker } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import apiService from '../../../../state/utils/apiService'
import * as API_PATHS from '../../../../state/ducks/accounting/paths'
import { withRouter } from 'react-router'
import { OPEN_UP } from 'react-dates/lib/constants'

const siteOptions = [
  { value: 'MLA', label: 'Argentina' },
  { value: 'MLB', label: 'Brasil' },
  { value: 'MCO', label: 'Colombia' },
  { value: 'MCL', label: 'Chile' },
  { value: 'MLM', label: 'México' },
  { value: 'MLP', label: 'Perú' },
  { value: 'MLU', label: 'Uruguay' }

]
const docType = [
  { value: 10, label: 'Retiros' },
  { value: 20, label: 'Conciliación' },
  { value: 30, label: 'Retiros Posteriores' },
  { value: 70, label: 'Usuarios Especiales' }
]
const statuses = [
  { value: 2, label: 'Pendiente' },
  { value: 3, label: 'Fallido' },
  { value: 4, label: 'Contabilizado' },
  { value: 5, label: 'Cancelado' },
  { value: 6, label: 'Anulado' },
  { value: 7, label: 'Anulación por autorizar' },
  { value: 9, label: 'Anulación autorizada' }
]
const financialEntities = [
  { value: 'citibank', label: 'citibank' },
  { value: 'citi_onl', label: 'citi_onl' },
  { value: 'santander', label: 'santander' },
  { value: 'SPB', label: 'SPB' },
  { value: 'citi_banamex', label: 'citi_banamex' },
  { value: 'citibank_cb', label: 'citibank_cb' }
]

const AccountingEntryStatus = {
  CREATED: 1,
  PENDING: 2,
  FAILED: 3,
  SETTLED: 4,
  CANCELLED: 5,
  ANNULLED: 6,
  PENDING_ANNULMENT_AUTHORIZATION: 7,
  PENDING_UPDATE_AUTHORIZATION: 8,
  AUTHORIZED_ANULLMENT: 9,
  AUTHORIZED_UPDATE: 10
}

const AccountingEntryActions = {
  ANNUL: 'Anular Asiento',
  CANCEL: 'Cancelar Envío',
  REVIEW: 'Revisar Asiento'
}

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? 'modal display-block' : 'modal display-none'
  return (

        <div className={showHideClassName}>
            <section className="modal-main">
                <button className={'modal-close-button'} onClick={handleClose}>X</button>
                {children}
            </section>
        </div>
  )
}

class SimetrikActivityIndicator extends Component {
  render () {
    return (
            <div className="loader" style={{ height: this.props.viewPort }}>
                <svg className="spinner" width={this.props.size} height={this.props.size} viewBox="0 0 66 66"
                     xmlns="http://www.w3.org/2000/svg">
                    <circle className="path" fill="none" strokeWidth={6} strokeLinecap="round"
                            cx="33" cy="33" r="30"></circle>
                </svg>
            </div>
    )
  }
}

class AccountingEntryAnnullment extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false
    }
  }

  submitAnnullment () {
    this.setState(
      { loading: true }
    )
    apiService({
      method: 'POST',
      path: API_PATHS.BASE_PATH + this.props.entry.id + '/annulment/create',
      async: true,
      params: null,
      body: null
    })
      .then(res => {
        if ('status' in res.data && res.data.status === 'ERROR') {
          this.setState(
            {
              loading: false,
              success: false
            }
          )
          // handleApiResponseFailure(dispatch, TYPE, res);
        } else {
          this.setState(
            {
              loading: false,
              success: true
            }
          )
        }
      })
      .catch(err => {
        console.log(err)
        // handleApiErrors(dispatch, TYPE, {data: {api_error: err, error: "Server 500"}})
      })
  }

  render () {
    if (this.state.success) {
      return (
                <div className="modal-component">
                    <div className="success-message">
                        <h4 className={'modal-title'}>Enviado</h4>
                    </div>
                </div>

      )
    }
    return (
      this.state.loading
        ? (
                    <div className={'modal-component'}>
                        <div className="loading-message">
                            <h4 className={'modal-title text-center'}>Enviando para autorización...</h4>
                            <SimetrikActivityIndicator size={18}/>
                        </div>
                    </div>
          )
        : (
                    <div className={'modal-component'}>
                        <div className={'modal-title'}>Anular Asiento</div>
                        <div className="modal-description">Esta operación es irreversible, ¿está seguro de continuar?
                        </div>
                        <div className="modal-description">SAP ID: {this.props.entry.request.documents.sap_id}</div>
                        <div className="btn btn-primary modal-action"
                             onClick={this.submitAnnullment.bind(this)}>Confirmar
                        </div>
                    </div>
          )
    )
  }
}

class AccountingEntryAnnullmentAuthorize extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false
    }
  }

  submitAnnullmentAuthorization () {
    this.setState(
      { loading: true }
    )
    apiService({
      method: 'POST',
      path: API_PATHS.BASE_PATH + this.props.entry.id + '/annulment/authorize',
      async: true,
      params: null,
      body: null
    })
      .then(res => {
        if ('status' in res.data && res.data.status === 'ERROR') {
          this.setState(
            {
              loading: false,
              success: false
            }
          )
          // handleApiResponseFailure(dispatch, TYPE, res);
        } else {
          this.setState(
            {
              loading: false,
              success: true
            }
          )
        }
      })
      .catch(err => {
        console.log(err)
        // handleApiErrors(dispatch, TYPE, {data: {api_error: err, error: "Server 500"}})
      })
  }

  render () {
    if (this.state.success) {
      return (
                <div className={'modal-component'}>
                    <div className="success-message">
                        <img src="/images/done.png" alt=""/>
                        <h4 className={'modal-title'}>Autorizado</h4>
                    </div>
                </div>
      )
    }
    return (
      this.state.loading
        ? (
                    <div className={'modal-component'}>
                        <div className="loading-message">
                            <h4 className={'modal-title'}>Autorizando...</h4>
                            <SimetrikActivityIndicator size={18}/>
                        </div>
                    </div>
          )
        : (
                    <div className={'modal-component'}>
                        <div className={'modal-title'}>Autorizar Asiento</div>
                        <div className="modal-description">Esta operación es irreversible, ¿está seguro de continuar?
                        </div>
                        <div className="modal-description">SAP ID: {this.props.entry.request.documents.sap_id}</div>
                        <div className="btn btn-primary modal-action"
                             onClick={this.submitAnnullmentAuthorization.bind(this)}>Autorizar
                        </div>
                    </div>
          )
    )
  }
}

class AccountingEntryAnnullmentDeny extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false
    }
  }

  submitAnnullmentAuthorization () {
    this.setState(
      { loading: true }
    )
    apiService({
      method: 'POST',
      path: API_PATHS.BASE_PATH + this.props.entry.id + '/annulment/deny',
      async: true,
      params: null,
      body: null
    })
      .then(res => {
        if ('status' in res.data && res.data.status === 'ERROR') {
          this.setState(
            {
              loading: false,
              success: false
            }
          )
          // handleApiResponseFailure(dispatch, TYPE, res);
        } else {
          this.setState(
            {
              loading: false,
              success: true
            }
          )
        }
      })
      .catch(err => {
        console.log(err)
        // handleApiErrors(dispatch, TYPE, {data: {api_error: err, error: "Server 500"}})
      })
  }

  render () {
    if (this.state.success) {
      return (
                <div className={'modal-component'}>
                    <div className="success-message">
                        <img src="/images/done.png" alt=""/>
                        <h4 className={'modal-title'}>Rechazado</h4>
                    </div>
                </div>
      )
    }
    return (
      this.state.loading
        ? (
                    <div className={'modal-component'}>
                        <div className="loading-message">
                            <h4 className={'modal-title'}>Rechazando anulación...</h4>
                            <SimetrikActivityIndicator size={18}/>
                        </div>
                    </div>
          )
        : (
                    <div className={'modal-component'}>
                        <div className={'modal-title'}>Rechazar Anulación</div>
                        <div className="modal-description">Al realizar esta operación se regresará el asiento al estado
                            anterior, ¿está seguro de continuar?
                        </div>
                        <div className="modal-description">SAP ID: {this.props.entry.request.documents.sap_id}</div>
                        <div className="btn btn-primary modal-action"
                             onClick={this.submitAnnullmentAuthorization.bind(this)}>Continuar
                        </div>
                    </div>
          )
    )
  }
}

class AccountingEntryUpdateDeny extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false
    }
  }

  submitAnnullmentAuthorization () {
    this.setState(
      { loading: true }
    )
    apiService({
      method: 'POST',
      path: API_PATHS.BASE_PATH + this.props.entry.id + '/edit/deny',
      async: true,
      params: null,
      body: null
    })
      .then(res => {
        if ('status' in res.data && res.data.status === 'ERROR') {
          this.setState(
            {
              loading: false,
              success: false
            }
          )
          // handleApiResponseFailure(dispatch, TYPE, res);
        } else {
          this.setState(
            {
              loading: false,
              success: true
            }
          )
        }
      })
      .catch(err => {
        console.log(err)
        // handleApiErrors(dispatch, TYPE, {data: {api_error: err, error: "Server 500"}})
      })
  }

  render () {
    if (this.state.success) {
      return (
                <div className={'modal-component'}>
                    <div className="success-message">
                        <img src="/images/done.png" alt=""/>
                        <h4 className={'modal-title'}>Rechazado</h4>
                    </div>
                </div>
      )
    }
    return (
      this.state.loading
        ? (
                    <div className={'modal-component'}>
                        <div className="loading-message">
                            <h4 className={'modal-title'}>Rechazando modificación...</h4>
                            <SimetrikActivityIndicator size={18}/>
                        </div>
                    </div>
          )
        : (
                    <div className={'modal-component'}>
                        <div className={'modal-title'}>Rechazar Modificación</div>
                        <div className="modal-description">Al realizar esta operación se regresará el asiento al estado
                            anterior, ¿está seguro de continuar?
                        </div>
                        <div className="modal-description">SAP ID: {this.props.entry.request.documents.sap_id}</div>
                        <div className="btn btn-primary modal-action"
                             onClick={this.submitAnnullmentAuthorization.bind(this)}>Continuar
                        </div>
                    </div>
          )
    )
  }
}

class AccountingEntryUpdateAuthorize extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false
    }
  }

  submitUpdateAuthorization () {
    this.setState(
      { loading: true }
    )
    apiService({
      method: 'POST',
      path: API_PATHS.BASE_PATH + this.props.entry.id + '/edit/authorize',
      async: true,
      params: null,
      body: null
    })
      .then(res => {
        if ('status' in res.data && res.data.status === 'ERROR') {
          this.setState(
            {
              loading: false,
              success: false
            }
          )
          // handleApiResponseFailure(dispatch, TYPE, res);
        } else {
          this.setState(
            {
              loading: false,
              success: true
            }
          )
        }
      })
      .catch(err => {
        console.log(err)
        // handleApiErrors(dispatch, TYPE, {data: {api_error: err, error: "Server 500"}})
      })
  }

  render () {
    if (this.state.success) {
      return (
                <div className={'modal-component'}>
                    <div className="success-message">
                        <img src="/images/done.png" alt=""/>
                        <h4 className={'modal-title'}>Autorizado</h4>
                    </div>
                </div>
      )
    }
    return (
      this.state.loading
        ? (
                    <div className={'modal-component'}>
                        <div className="loading-message">
                            <h4 className={'modal-title'}>Autorizando...</h4>
                            <SimetrikActivityIndicator size={18}/>
                        </div>
                    </div>
          )
        : (
                    <div className={'modal-component'}>
                        <div className={'modal-title'}>Autorizar Modificación</div>
                        <hr/>
                        <div style={{ color: 'black' }}><b>SAP ID: </b></div>
                        <div>{this.props.entry.request.documents.sap_id}</div>
                        <div style={{ margin: '8px 0px' }}></div>
                        <div style={{ color: 'black' }}><b>Fecha contable (Año-Mes-Día) [UTC] </b></div>
                        <div>{moment(this.props.entry.request.documents.date_created).utcOffset(60).format('YYYY-MM-DD')}</div>
                        <hr/>
                        <div className="modal-description">Esta operación es irreversible, ¿está seguro de continuar?
                        </div>
                        <div className="btn btn-primary modal-action"
                             onClick={this.submitUpdateAuthorization.bind(this)}>Autorizar
                        </div>
                    </div>
          )
    )
  }
}

class AccountingEntryUpdate extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      createdAt: moment(),
      calendarFocused: false,
      isEditing: false
    }
  }

  submitUpdateRequest () {
    this.setState(
      { loading: true }
    )
    apiService({
      method: 'POST',
      path: API_PATHS.BASE_PATH + this.props.entry.id + '/edit/',
      async: true,
      params: null,
      body: {
        account_date:
                    this.state.isEditing ? this.state.createdAt.format('YYYY-MM-DD[T]HH:MM:ss[Z]') : this.props.entry.request.documents.date_created
      }
    })
      .then(res => {
        if ('status' in res.data && res.data.status === 'ERROR') {
          this.setState(
            {
              loading: false,
              success: false
            }
          )
          // handleApiResponseFailure(dispatch, TYPE, res);
        } else {
          this.setState(
            {
              loading: false,
              success: true
            }
          )
        }
      })
      .catch(err => {
        console.log(err)
        // handleApiErrors(dispatch, TYPE, {data: {api_error: err, error: "Server 500"}})
      })
  }

  onDateChange = (createdAt) => {
    this.setState(() => ({ createdAt }))
  }

  onFocusChange = ({ focused }) => {
    this.setState(() => ({ calendarFocused: focused }))
  }

  render () {
    if (this.state.success) {
      return (
                <div className="modal-component">
                    <div className="success-message">
                        <img src="/images/done.png" alt=""/>
                        <h4 className={'modal-title'}>Enviado</h4>
                    </div>
                </div>

      )
    }
    return (
      this.state.loading
        ? (
                    <div className={'modal-component'}>
                        <div className="loading-message">
                            <h4 className={'modal-title text-center'}>Enviando para autorización...</h4>
                            <SimetrikActivityIndicator size={18}/>
                        </div>
                    </div>
          )
        : (
                    <div className={'modal-component'}>
                        <div className={'modal-title'}>Asiento Fallido</div>
                        <div className="modal-description">Este asiento falló al contabilizar, esta es la respuesta de
                            SAP
                        </div>
                        <pre>SAP ID: {this.props.entry.request.documents.sap_id}</pre>
                        <pre>{this.props.entry.response.sap_message}</pre>

                        <div className="modal-description">¿Desea cambiar la fecha de contabilización?</div>
                        <div className="modal-description flex-row">
                            <label className="css-input switch switch-success">
                                <input
                                    type="checkbox"
                                    value={this.state.isEditing}
                                    checked={this.state.isEditing}
                                    onChange={(isEditing) => this.setState({ isEditing: isEditing.target.checked })}
                                    defaultChecked={false}
                                />
                                <span></span> Si?
                            </label>
                        </div>
                        {
                            this.state.isEditing ? <div className="modal-description flex-row">
                                <SingleDatePicker
                                    ariaLabel="Fecha de contabilización"
                                    date={this.state.createdAt}
                                    openDirection={OPEN_UP}
                                    onDateChange={this.onDateChange}
                                    focused={this.state.calendarFocused}
                                    onFocusChange={this.onFocusChange}
                                    block={true}
                                    isOutsideRange={() => false}
                                    displayFormat="YYYY-MM-DD"
                                />
                            </div> : null
                        }

                        <div className="btn btn-primary modal-action"
                             onClick={this.submitUpdateRequest.bind(this)}>Enviar para autorización
                        </div>
                    </div>
          )
    )
  }
}

// const MultiValueRemove = props => {
//   return (
//         <components.MultiValueRemove {...props}>
//         </components.MultiValueRemove>
//   )
// }

const animatedComponents = makeAnimated()

class AccountingFilter extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedOption: null
    }
  }

  render () {
    return (
            <Select
                placeholder="Selecciona una opción"
                className="select-filter"
                isMulti
                isSearchable
                value={this.props.selectedOption}
                components={animatedComponents}
                closeMenuOnSelect={false}
                onChange={this.props.onChange}
                options={this.props.options}/>
    )
  }
}

class AccountingPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session(),
      stats_items: [],
      isLoading: true,
      accountingEntries: [],
      show: false,
      activeDialog: null,
      activeEntry: {
        request: {
          documents: {
            sap_id: 0
          }
        }
      },
      filters: [
        { name: 'site', query: 'request:documents:site_id__in', values: null, selectedOption: null },
        { name: 'doc_type', query: 'request:documents:code__in', values: null, selectedOption: null },
        { name: 'status', query: 'status__in', values: null, selectedOption: null },
        {
          name: 'financial_entity',
          query: 'request:documents:line_item[0].financial_entity__in',
          values: null,
          selectedOption: null
        },
        { name: 'sap_id', query: 'request:documents:sap_id', values: '' },
        {
          name: 'doc_date_min',
          query: 'request:documents:doc_date::DATE__gte',
          values: null,
          selectedOption: null
        },
        {
          name: 'doc_date_max',
          query: 'request:documents:doc_date::DATE__lte',
          values: null,
          selectedOption: null
        },
        {
          name: 'acc_date_min',
          query: 'request:documents:date_created::DATE__gte',
          values: null,
          selectedOption: null
        },
        {
          name: 'acc_date_max',
          query: 'request:documents:date_created::DATE__lte',
          values: null,
          selectedOption: null
        }
      ],
      sap_id_input: '',
      startDate: null,
      endDate: null,
      accountDateMin: null,
      accountDateMax: null,
      minDate: moment('2019-11-05T00:00:00', 'YYYY-MM-DD'),
      maxDate: moment('2021-12-31T00:00:00', 'YYYY-MM-DD'),
      focusedInput: null,
      accountFocusedInput: null,
      params: {}
    }
    this.onPaginationChange = this.onPaginationChange.bind(this)
    this.updateParams = this.updateParams.bind(this)
  }

  showModal () {
    this.setState({ show: true })
  };

  UpdateQueryString (key, value, url) {
    if (!url) url = window.location.href
    const re = new RegExp('([?&])' + key + '=.*?(&|#|$)(.*)', 'gi')
    let hash

    if (re.test(url)) {
      if (typeof value !== 'undefined' && value !== null) {
        return url.replace(re, '$1' + key + '=' + value + '$2$3')
      } else {
        hash = url.split('#')
        url = hash[0].replace(re, '$1$3').replace(/(&|\?)$/, '')
        if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
          url += '#' + hash[1]
        }
        return url
      }
    } else {
      if (typeof value !== 'undefined' && value !== null) {
        const separator = url.indexOf('?') !== -1 ? '&' : '?'
        hash = url.split('#')
        url = hash[0] + separator + key + '=' + value
        if (typeof hash[1] !== 'undefined' && hash[1] !== null) {
          url += '#' + hash[1]
        }
        return url
      } else {
        return url
      }
    }
  }

  onPaginationChange (page) {
    this.updateParams('page', page)
    let searchParams = null
    if ('URLSearchParams' in window) {
      searchParams = new URLSearchParams(this.props.location.search)
      searchParams.delete('page')
      searchParams.set('page', page)
      const newRelativePathQuery = window.location.pathname + '#/accounting?' + searchParams.toString()
      window.history.replaceState(null, '', newRelativePathQuery)
    }
    this.props.fetchAccountingEntries('?' + searchParams.toString())
  }

  updateParams (key, value) {
    const { params } = this.state
    params[key] = value
    if (key === 'search') {
      params.page = 1
    }
  }

  hideModal () {
    this.setState({ show: false })
    window.location.reload()
  };

  UNSAFE_componentWillMount () {
    this.props.fetchAccountingEntries(this.props.location.search)
  }

  componentDidMount () {
    const filters = this.state.filters.map(
      (element) => {
        return (
          {
            key: element.query,
            value: this.getQueryVariable(element.query)
          }
        )
      })
    // const loadedFilters = filters.filter((item) => item.value !== undefined)
    filters.filter((item) => item.value !== undefined)
    // const newFiltersState = this.state.filters
  }

  componentDidUpdate (prevProps) {
    if (this.props.location.search !== prevProps.location.search) {
      this.props.fetchAccountingEntries(this.props.location.search)
    }
  }

  getQueryVariable (variable) {
    const query = this.props.location.search.substring(1)
    const vars = query.split('&')
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=')
      if (decodeURIComponent(pair[0]) === variable) {
        return decodeURIComponent(pair[1])
      }
    }
  }

  getStatusText (entry) {
    switch (entry.status) {
      case AccountingEntryStatus.CREATED:
        return 'Generado'
      case AccountingEntryStatus.PENDING:
        return 'Pendiente'
      case AccountingEntryStatus.FAILED:
        return 'Fallido'
      case AccountingEntryStatus.SETTLED:
        return 'Contabilizado'
      case AccountingEntryStatus.PENDING_ANNULMENT_AUTHORIZATION:
        return 'Anulación por autorizar'
      case AccountingEntryStatus.PENDING_UPDATE_AUTHORIZATION:
        return 'Modificación por autorizar'
      case AccountingEntryStatus.AUTHORIZED_ANULLMENT:
        return 'Anulación Autorizada'
      case AccountingEntryStatus.ANNULLED:
        return 'Anulado'
    }
  }

  getStatusStyle (statusCode) {
    switch (statusCode) {
      case 'PENDING':
        return 'field-value '
      case 'FAILED':
        return 'field-value failed-status'
      case 'COMPLETED':
        return 'field-value completed-status'
    }
  }

  renderActions (entry) {
    const isAccountingAuthorizer = this.state.session.allowedActionFor(['ACCOUNTING_AUTHORIZER'])
    const isAccountingManager = this.state.session.allowedActionFor(['ACCOUNTING_MANAGER'])
    const isAdmin = this.state.session.allowedActionFor(['ADMIN'])

    if (isAccountingAuthorizer) {
      switch (entry.status) {
        case AccountingEntryStatus.PENDING_ANNULMENT_AUTHORIZATION:
          return (
                        <div className={'flex-row'}>
                            <div className={'accounting-action-btn positive-btn'}
                                 onClick={() => this.authorizeAnnulment(entry)}>
                                Autorizar
                            </div>
                            <div className={'accounting-action-btn deny-btn'}
                                 onClick={() => this.denyAnnullment(entry)}>
                                Rechazar
                            </div>
                        </div>

          )
        case AccountingEntryStatus.PENDING_UPDATE_AUTHORIZATION:
          return (
                        <div className={'flex-row'}>
                            <div className={'accounting-action-btn positive-btn'}
                                 onClick={() => this.authorizeUpdate(entry)}>
                                Autorizar
                            </div>
                            <div className={'accounting-action-btn deny-btn'}
                                 onClick={() => this.denyUpdate(entry)}>
                                Rechazar
                            </div>
                        </div>

          )
        default:
          return (
                        <div className={'accounting-action-btn deny-btn deny-btn-light'}>
                            No hay acciones disponibles
                        </div>
          )
      }
    } else if (isAccountingManager || isAdmin) {
      switch (entry.status) {
        case AccountingEntryStatus.SETTLED:
          return (
                    <div className={'accounting-action-btn'}
                         onClick={() => this.showAnnullmentConfirmation(entry)}>
                        {AccountingEntryActions.ANNUL}
                    </div>
          )
        case AccountingEntryStatus.FAILED:
          return (
                    <div className={'accounting-action-btn'}
                         onClick={() => {
                           this.setState({
                             activeDialog: <AccountingEntryUpdate entry={entry}/>
                           })
                           this.showModal()
                         }}>
                        {AccountingEntryActions.REVIEW}
                    </div>
          )
        default:
          return (
                    <div/>
          )
      }
    } else {
      return null
    }
  }

  getModelName (modelCode) {
    switch (modelCode) {
      case 10:
        return 'Retiros'
      case 20:
        return 'Conciliación'
      case 30:
        return 'Ret. Post'
      case 70:
        return 'Usr. Esp'
    }
  }

  showAnnullmentConfirmation (entry) {
    this.setState({
      activeDialog: <AccountingEntryAnnullment entry={entry}/>
    })
    this.showModal()
  }

  authorizeAnnulment (entry) {
    this.setState({
      activeDialog: <AccountingEntryAnnullmentAuthorize entry={entry}/>
    })
    this.showModal()
  }

  authorizeUpdate (entry) {
    this.setState({
      activeDialog: <AccountingEntryUpdateAuthorize entry={entry}/>
    })
    this.showModal()
  }

  denyAnnullment (entry) {
    this.setState({
      activeDialog: <AccountingEntryAnnullmentDeny entry={entry}/>
    })
    this.showModal()
  }

  denyUpdate (entry) {
    this.setState({
      activeDialog: <AccountingEntryUpdateDeny entry={entry}/>
    })
    this.showModal()
  }

  renderEntry (entry) {
    return (
            <li key={entry.id} className="accounting-entry">
                <div className="flex-row">
                    <div className="accounting-entry-tile fixed-extent fixed-extent-80">
                        <div className="field-title">Modelo</div>
                        <div className="field-value">{this.getModelName(entry.request.documents.code)}</div>
                    </div>
                    <div className="accounting-entry-tile fixed-extent-40">
                        <div className="field-title">Site</div>
                        <div className="field-value">{entry.request.documents.site_id}</div>
                    </div>
                    <div className="accounting-entry-tile fixed-extent-80">
                        <div className="field-title">Identificador</div>
                        <div className="field-value">{entry.request.documents.sap_id}</div>
                    </div>
                    <div className="accounting-entry-tile fixed-extent">
                        <div className="field-title">Fecha Documento</div>
                        <div className="field-value">
                            {moment(entry.request.documents.doc_date).utcOffset(60).format('YYYY-MM-DD')}
                        </div>
                    </div>
                    <div className="accounting-entry-tile fixed-extent">
                        <div className="field-title">Fecha Contable</div>
                        <div className="field-value">
                            {moment(entry.request.documents.date_created).utcOffset(60).format('YYYY-MM-DD')}
                        </div>
                    </div>
                    <div className="accounting-entry-tile fixed-extent">
                        <div className="field-title">
                            Financial Entity
                        </div>
                        <div className="field-value">
                            {entry.request.documents.line_item[0].financial_entity}
                        </div>
                    </div>
                    <div className="accounting-entry-tile fixed-extent">
                        <div className="field-title">
                            Moneda
                        </div>
                        <div className="field-value">
                            {entry.request.documents.currency}
                        </div>
                    </div>
                    <div className="accounting-entry-tile fixed-extent-80">
                        <div className="field-title">Monto</div>
                        <div className="field-value">
                            {entry.request.documents.line_item[0].amount}
                        </div>
                    </div>
                    <div className="accounting-entry-tile fixed-extent">
                        <div className="field-title">Total Agrupación</div>
                        <div className="field-value">
                            {entry.request.total_grouped} registros
                        </div>
                    </div>
                    <div className="accounting-entry-tile entry-status">
                        <div className="field-title">Estado</div>
                        <div className="field-value">
                            {this.getStatusText(entry)}
                        </div>
                    </div>
                </div>
                <div className="flex-row" style={{ flex: 'none' }}>
                    <div className="accounting-entry-tile">
                        <div className="field-value">
                            {this.renderActions(entry)}
                        </div>
                    </div>
                </div>

            </li>
    )
  }

  applyFilters (e) {
    e.preventDefault()
    const toApply = []
    this.state.filters.forEach((filter) => {
      if (filter.values != null && filter.values !== '') {
        toApply.push(filter)
      }
    })
    const params = {}
    toApply.forEach((filter) => {
      const query = filter.query
      const values = filter.values
      params[query] = values
    })
    this.props.history.push({
      pathname: '/accounting',
      search: '?' + new URLSearchParams(params).toString()
    })
  }

  clearFilters () {
    this.setState(
      {
        filters: [
          { name: 'site', query: 'request:documents:site_id__in', values: null, selectedOption: null },
          { name: 'doc_type', query: 'request:documents:code__in', values: null, selectedOption: null },
          { name: 'status', query: 'status__in', values: null, selectedOption: null },
          {
            name: 'financial_entity',
            query: 'request:documents:line_item[0].financial_entity__in',
            values: null,
            selectedOption: null
          },
          { name: 'sap_id', query: 'request:documents:sap_id', values: '' },
          {
            name: 'doc_date_min',
            query: 'request:documents:doc_date::DATE__gte',
            values: null,
            selectedOption: null
          },
          {
            name: 'doc_date_max',
            query: 'request:documents:doc_date::DATE__lte',
            values: null,
            selectedOption: null
          },
          {
            name: 'acc_date_min',
            query: 'request:documents:created_date::DATE__gte',
            values: null,
            selectedOption: null
          },
          {
            name: 'acc_date_max',
            query: 'request:documents:created_date::DATE__lte',
            values: null,
            selectedOption: null
          }
        ],
        sap_id_input: '',
        startDate: null,
        endDate: null,
        accountDateMin: null,
        accountDateMax: null,
        minDate: moment('2019-11-01', 'YYYY-MM-DD'),
        accountDateMinDate: moment('2019-11-01', 'YYYY-MM-DD'),
        focusedInput: null,
        accountFocusedInput: null

      }
    )
    this.props.history.push({
      pathname: '/accounting'
    })
  }

  filterByPendingForAuth () {
    this.props.history.push({
      pathname: '/accounting',
      search: '?' + new URLSearchParams({ status__in: '7,8' }).toString()
    })
  }

  getAuthorizationPendingCount () {
    let annulmentPending = 0
    let updatePending = 0
    if (this.props.summary.hasOwnProperty('BY_STATUS') && this.props.summary.BY_STATUS.hasOwnProperty('7')) {
      annulmentPending = this.props.summary.BY_STATUS['7']
    }
    if (this.props.summary.hasOwnProperty('BY_STATUS') && this.props.summary.BY_STATUS.hasOwnProperty('8')) {
      updatePending = this.props.summary.BY_STATUS['8']
    }
    return annulmentPending + updatePending
  }

  render () {
    return (
            <>
                <PageContainerLayout>
                    <div className="accounting-container">
                        <div className="flex-row flex-container">
                        </div>
                        <div className="flex-row flex-container">
                            <div className="shortcut btn" onClick={this.filterByPendingForAuth.bind(this)}>
                                Por Autorizar
                                <div className="badge">
                                    {this.getAuthorizationPendingCount()}
                                </div>
                            </div>
                        </div>

                        <div className="flex-row flex-container">
                            <form method="" action="" onSubmit={this.applyFilters.bind(this)}>
                                <input type="submit" style={{ display: 'none' }}/>
                                <div className="accounting-filters">
                                    <div className="flex-row">
                                        <div className="filter">
                                            <h2 className="filter-label">Site</h2>
                                            <AccountingFilter
                                                className="select-filter"
                                                selectedOption={this.state.filters[0].selectedOption}
                                                options={siteOptions}
                                                onChange={(selectedOption) => {
                                                  if (selectedOption !== null) {
                                                    const filters = this.state.filters
                                                    filters[0].values = selectedOption.map(function (elem) {
                                                      return elem.value
                                                    }).join(',')
                                                    filters[0].selectedOption = selectedOption
                                                    this.setState({ filters })
                                                  }
                                                }}/>
                                        </div>
                                        <div className="filter">
                                            <h2 className="filter-label">Tipo de asiento</h2>
                                            <AccountingFilter className="select-filter"
                                                              selectedOption={this.state.filters[1].selectedOption}
                                                              options={docType} onChange={(selectedOption) => {
                                                                if (selectedOption !== null) {
                                                                  const filters = this.state.filters
                                                                  filters[1].values = selectedOption.map(function (elem) {
                                                                    return elem.value
                                                                  }).join(',')
                                                                  filters[1].selectedOption = selectedOption
                                                                  this.setState({ filters })
                                                                }
                                                              }}/>
                                        </div>
                                        <div className="filter">
                                            <h2 className="filter-label">Estado</h2>
                                            <AccountingFilter className="select-filter"
                                                              selectedOption={this.state.filters[2].selectedOption}
                                                              options={statuses} onChange={(selectedOption) => {
                                                                if (selectedOption !== null) {
                                                                  const filters = this.state.filters
                                                                  filters[2].values = selectedOption.map(function (elem) {
                                                                    return elem.value
                                                                  }).join(',')
                                                                  filters[2].selectedOption = selectedOption
                                                                  this.setState({ filters })
                                                                }
                                                              }}/>
                                        </div>
                                        <div className="filter">
                                            <h2 className="filter-label">Financial Entity</h2>
                                            <AccountingFilter className="select-filter" options={financialEntities}
                                                              selectedOption={this.state.filters[3].selectedOption}
                                                              onChange={(selectedOption) => {
                                                                if (selectedOption !== null) {
                                                                  const filters = this.state.filters
                                                                  filters[3].values = selectedOption.map(function (elem) {
                                                                    return elem.value
                                                                  }).join(',')
                                                                  filters[3].selectedOption = selectedOption
                                                                  this.setState({ filters })
                                                                }
                                                              }}/>
                                        </div>

                                    </div>
                                    <div className="flex-row">
                                        <div className="filter">
                                            <h2 className="filter-label">Fecha de creación</h2>
                                            <DateRangePicker
                                                startDatePlaceholderText="Desde"
                                                endDatePlaceholderText="Hasta"
                                                noBorder={true}
                                                isOutsideRange={() => false}
                                                startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                                                startDateId="your_unique_start_date_id_1" // PropTypes.string.isRequired,
                                                endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                                                endDateId="your_unique_end_date_id_2" // PropTypes.string.isRequired,
                                                minDate={this.state.minDate}
                                                maxDate={this.state.maxDate}
                                                onDatesChange={({ startDate, endDate }) => {
                                                  const filters = this.state.filters
                                                  filters[5].values = moment(startDate).format('YYYY-MM-DD')
                                                  filters[6].values = moment(endDate).format('YYYY-MM-DD')
                                                  this.setState({ startDate, endDate, filters })
                                                }} // PropTypes.func.isRequired,
                                                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                                onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                                            />
                                        </div>
                                        <div className="filter">
                                            <h2 className="filter-label">Fecha de contabilización</h2>
                                            <DateRangePicker
                                                startDatePlaceholderText="Desde"
                                                endDatePlaceholderText="Hasta"
                                                isOutsideRange={() => false}
                                                noBorder={true}
                                                startDate={this.state.accountDateMin} // momentPropTypes.momentObj or null,
                                                startDateId="your_unique_start_date_id_3" // PropTypes.string.isRequired,
                                                endDate={this.state.accountDateMax} // momentPropTypes.momentObj or null,
                                                endDateId="your_unique_end_date_id_4" // PropTypes.string.isRequired,
                                                minDate={this.state.minDate}
                                                maxDate={this.state.maxDate}
                                                onDatesChange={({ startDate, endDate }) => {
                                                  const filters = this.state.filters
                                                  filters[7].values = moment(startDate).format('YYYY-MM-DD')
                                                  filters[8].values = moment(endDate).format('YYYY-MM-DD')
                                                  this.setState({
                                                    accountDateMin: startDate,
                                                    accountDateMax: endDate,
                                                    filters
                                                  })
                                                }} // PropTypes.func.isRequired,
                                                focusedInput={this.state.accountFocusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                                onFocusChange={accountFocusedInput => this.setState({ accountFocusedInput })} // PropTypes.func.isRequired,
                                            />
                                        </div>
                                        <div className="filter">
                                            <h2 className="filter-label">SAP ID</h2>
                                            <input value={this.state.sap_id_input}
                                                   className="form-control no-border search-control simetrik_input"
                                                   type="text" name="sap_id"
                                                   placeholder={'Buscar por número de documento..'}
                                                   onChange={(event) => {
                                                     if (event.target.value !== null) {
                                                       const filters = this.state.filters
                                                       filters[4].values = event.target.value
                                                       this.setState({ filters, sap_id_input: event.target.value })
                                                     }
                                                   }}/>
                                        </div>
                                    </div>
                                    <div className="">
                                        {/* <div className="flex-row">
                                        <div style={{flex: 1, fontSize: "14px"}}>
                                            Desde
                                        </div>
                                        <div style={{flex: 1, fontSize: "14px"}}>Hasta
                                        </div>
                                    </div> */}
                                        {/* <DateRangePicker
                                        startDate={this.state.startDate} // momentPropTypes.momentObj or null,
                                        startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                                        endDate={this.state.endDate} // momentPropTypes.momentObj or null,
                                        endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                                        onDatesChange={({ startDate, endDate }) => this.setState({ startDate, endDate })} // PropTypes.func.isRequired,
                                        focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                        onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                                    /> */}
                                    </div>

                                </div>
                            </form>

                        </div>
                        <div className="flex-row flex-container">
                            <div className="shortcut btn" onClick={this.applyFilters.bind(this)}>
                                Aplicar filtros
                            </div>
                            <div className="shortcut btn" onClick={this.clearFilters.bind(this)}>
                                Limpiar
                            </div>
                        </div>
                        <div className="flex-row">
                            {
                                this.props.isLoading
                                  ? <SimetrikActivityIndicator size={32} viewPort={'60vh'}/>
                                  : (
                                        <div>
                                            <div className="accounting-table">
                                                <ul className="accounting-entry-list">
                                                    {this.props.accountingEntries.map((object, i) => this.renderEntry(object))}
                                                </ul>
                                            </div>
                                            <div className="flex-row">
                                                <PaginationLayout pagination={this.props.paginationData}
                                                                  onPaginationChange={this.onPaginationChange}/>
                                            </div>
                                        </div>
                                    )
                            }
                            <div className="accounting-details">
                            </div>
                        </div>

                    </div>
                    <Modal show={this.state.show}
                           handleClose={this.hideModal.bind(this)}>
                        {this.state.activeDialog}
                    </Modal>
                </PageContainerLayout>
            </>
    )
  };
}

// Set defaultProps
AccountingPage.defaultProps = {
  accountingEntries: [],
  summary: {}
}

// mapStateToProps
const mapStateToProps = function (state) {
  return ({
    isLoading: state.accounting.fetchAccountingEntriesReducer.loading,
    accountingEntries: state.accounting.fetchAccountingEntriesReducer.data,
    summary: state.accounting.fetchAccountingEntriesReducer.summary,
    paginationData: state.accounting.fetchAccountingEntriesReducer.pagination_data
    // statusGroups: state.accountingSeats.fetchAccountingSeatsReducer.data.status_groups
  })
}

// mapStateToProps
const mapDispatchToProps = {
  fetchAccountingEntries: accountingEntriesOperations.list
}

// Export Class
const _AccoutingPage = withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountingPage))
export { _AccoutingPage as AccountingPage }
