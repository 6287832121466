import React, { Component } from 'react'

import { PageContainerLayout } from '../../../layouts/page-container'
import { BankSettingsPage } from './components/BankSettings'
import { Route } from 'react-router'

class ClientCustomSettingsPage extends Component {
  constructor (props) {
    super(props)
  }

  render () {
    return (
            <PageContainerLayout>
                <Route exact={true} component={BankSettingsPage} path={'/settings/banks'}/>
            </PageContainerLayout>
    )
  }
}

export {
  ClientCustomSettingsPage
}
