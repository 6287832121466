import React, { Component } from 'react'
import { EmptyTableLayout } from '../../../layouts'
import { history } from '../../../../routing/History'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { Session } from '../../../../state/utils/session'
import { Link } from 'react-router-dom'
import { ExecutionsModal } from '../executions-modal'
import Swal from 'sweetalert2'
import { conciliationOperations } from '../../../../state/ducks/conciliations'
import { connect } from 'react-redux'

class ConciliationsTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session(),
      conciliation_to_execute: {}
    }

    this.goToConciliationEditor = this.goToConciliationEditor.bind(this)
    this.selectConciliation = this.selectConciliation.bind(this)
    this.executeEMR = this.executeEMR.bind(this)
  }

  executeEMR (conciliation) {
    this.setState({
      conciliation_to_execute: conciliation
    }, () => {
      window.jQuery('#executions-modal').modal('show')
    })
  }

  goToConciliationEditor (conciliation) {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_CONCILIATION_EDITOR_PATH
        .replace(':conciliation_id', conciliation.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  selectConciliation (conciliation) {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_CONCILIATION_DETAILS_PATH
        .replace(':conciliation_id', conciliation.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  deleteConciliation (conciliation) {
    Swal.fire({
      title: '',
      html: `<b>Por favor confirme la eliminación de la conciliación ${conciliation.name}?</b>`,
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.dismiss !== 'backdrop') {
        if (result.value) {
          this.props.removeConciliation(this.props.params, conciliation.id)
        }
      }
    })
  }

  render () {
    return (
            <>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered table-condensed text-center">
                        <thead>
                        <tr>
                            <th className="text-center">ID</th>
                            <th className="text-center">Name</th>
                            <th className="text-center">Batch Order</th>
                            <th className="text-center">Origin Connection</th>
                            <th className="text-center">Destination Connection</th>
                            <th className="text-center">Options</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.conciliations.map((conciliation) => {
                              return (
                                    <tr key={conciliation.id}>
                                        <td className="text-center">{conciliation.id}</td>
                                        <td className="text-center">{conciliation.name}</td>
                                        <td className="text-center">{conciliation.batch_order}</td>
                                        <td className="text-center">
                                            <Link to={
                                                ROUTING_PATHS.CLIENT_DASHBOARD_CONNECTION_DETAILS_PATH
                                                  .replace(':client_id', this.state.session.getClientSession())
                                                  .replace(':connection_id', conciliation.origin_connection)
                                            }>
                                                {conciliation.origin_connection_name}
                                            </Link>
                                        </td>
                                        <td className="text-center">
                                            <Link to={
                                                ROUTING_PATHS.CLIENT_DASHBOARD_CONNECTION_DETAILS_PATH
                                                  .replace(':client_id', this.state.session.getClientSession())
                                                  .replace(':connection_id', conciliation.destination_connection)
                                            }>
                                                {conciliation.destination_connection_name}
                                            </Link>
                                        </td>
                                        <td className="text-center">
                                            <div className="btn-group">
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Run ETL"
                                                        onClick={this.executeEMR.bind(this, conciliation)}
                                                >
                                                    EMR
                                                </button>
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Edit Conciliation"
                                                        onClick={this.goToConciliationEditor.bind(this, conciliation)}
                                                >
                                                    <i className="fa fa-pen"></i>
                                                </button>
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Conciliation Details"
                                                        onClick={this.selectConciliation.bind(this, conciliation)}
                                                >
                                                    <i className="fa fa-bar-chart"></i>
                                                </button>
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Remove Conciliation"
                                                        onClick={this.deleteConciliation.bind(this, conciliation)}
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                              )
                            })
                        }
                        <EmptyTableLayout results={this.props.conciliations} columns={6}/>
                        </tbody>
                    </table>
                    {this.props.children}
                </div>
                <ExecutionsModal object_type={'CONCILIATION'} object={this.state.conciliation_to_execute}/>
            </>
    )
  }
}

// Set defaultProps
ConciliationsTable.defaultProps = {
  conciliations: []
}

const mapStateToProps = (state) => ({
  isLoading: state.conciliations.removeConciliationReducer.loading
})

const mapDispatchToProps = {
  removeConciliation: conciliationOperations.remove
}

// Export Class
const _ConciliationsTable = connect(mapStateToProps, mapDispatchToProps)(ConciliationsTable)
export { _ConciliationsTable as ConciliationsTable }
