import React, { Component } from 'react'
import { EmptyTableLayout } from '../../../layouts'
import { history } from '../../../../routing/History'
import * as ROUTING_PATHS from '../../../../routing/Paths'

class GroupsTable extends Component {
  constructor (props) {
    super(props)

    this.state = {}

    this.goToGroupEditor = this.goToGroupEditor.bind(this)
    this.selectGroup = this.selectGroup.bind(this)
    this.deleteGroup = this.deleteGroup.bind(this)
  }

  goToGroupEditor (group) {
    history.push(ROUTING_PATHS.GROUP_EDITOR_PATH.replace(':group_id', group.id))
  }

  selectGroup (group) {
    history.push(ROUTING_PATHS.GROUP_DETAILS_PATH.replace(':group_id', group.id))
  }

  deleteGroup (group) {

  }

  render () {
    return (
            <>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered table-condensed text-center">
                        <thead>
                        <tr>
                            <th className="text-center">ID</th>
                            <th className="text-center">Name</th>
                            <th className="text-center">Users</th>
                            <th className="text-center">Permissions</th>
                            <th className="text-center">Options</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.groups.map((group) => {
                              return (
                                    <tr key={group.id}>
                                        <td className="text-center">{group.id}</td>
                                        <td className="text-center">{group.name}</td>
                                        <td className="text-center">{group.users.length}</td>
                                        <td className="text-center">{group.permissions.length}</td>
                                        <td className="text-center">
                                            <div className="btn-group">
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Edit Group"
                                                        onClick={this.goToGroupEditor.bind(this, group)}
                                                >
                                                    <i className="fa fa-pen"></i>
                                                </button>
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Select Group"
                                                        onClick={this.selectGroup.bind(this, group)}
                                                >
                                                    <i className="fa fa-eye"></i>
                                                </button>
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Remove Group"
                                                        onClick={this.deleteGroup.bind(this, group)}
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                              )
                            })
                        }
                        <EmptyTableLayout results={this.props.groups} columns={5}/>
                        </tbody>
                    </table>
                    {this.props.children}
                </div>
            </>
    )
  }
}

// Set defaultProps
GroupsTable.defaultProps = {
  groups: []
}

// Export Class
export { GroupsTable }
