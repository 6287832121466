// PERMISSIONS
export const FETCH_PERMISSIONS_REQUEST = 'session/FETCH_PERMISSIONS_REQUEST'
export const FETCH_PERMISSIONS_REQUEST_SUCCESS = 'session/FETCH_PERMISSIONS_REQUEST_SUCCESS'
export const FETCH_PERMISSIONS_REQUEST_FAILURE = 'session/FETCH_PERMISSIONS_REQUEST_FAILURE'
export const FETCH_PERMISSIONS_REQUEST_COMPLETED = 'session/FETCH_PERMISSIONS_REQUEST_COMPLETED'

export const GET_PERMISSION_REQUEST = 'session/GET_PERMISSION_REQUEST'
export const GET_PERMISSION_REQUEST_SUCCESS = 'session/GET_PERMISSION_REQUEST_SUCCESS'
export const GET_PERMISSION_REQUEST_FAILURE = 'session/GET_PERMISSION_REQUEST_FAILURE'
export const GET_PERMISSION_REQUEST_COMPLETED = 'session/GET_PERMISSION_REQUEST_COMPLETED'

export const SAVE_PERMISSION_REQUEST = 'session/SAVE_PERMISSION_REQUEST'
export const SAVE_PERMISSION_REQUEST_SUCCESS = 'session/SAVE_PERMISSION_REQUEST_SUCCESS'
export const SAVE_PERMISSION_REQUEST_FAILURE = 'session/SAVE_PERMISSION_REQUEST_FAILURE'
export const SAVE_PERMISSION_REQUEST_COMPLETED = 'session/SAVE_PERMISSION_REQUEST_COMPLETED'

export const UPDATE_PERMISSION_REQUEST = 'session/UPDATE_PERMISSION_REQUEST'
export const UPDATE_PERMISSION_REQUEST_SUCCESS = 'session/UPDATE_PERMISSION_REQUEST_SUCCESS'
export const UPDATE_PERMISSION_REQUEST_FAILURE = 'session/UPDATE_PERMISSION_REQUEST_FAILURE'
export const UPDATE_PERMISSION_REQUEST_COMPLETED = 'session/UPDATE_PERMISSION_REQUEST_COMPLETED'
