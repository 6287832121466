import { combineReducers } from 'redux'
import * as types from './types'

const fetchJobPoolsInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { results: [], pagination_data: {} }
}

const getJobPoolInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { groups: [] }
}

const saveJobPoolInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

const removeJobPoolInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

export function fetchJobPoolsReducer (state = fetchJobPoolsInitialState, action) {
  switch (action.type) {
    case types.FETCH_JOB_POOLS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.FETCH_JOB_POOLS_REQUEST_FAILURE:
      return {
        ...fetchJobPoolsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.FETCH_JOB_POOLS_REQUEST_SUCCESS:
      return {
        ...fetchJobPoolsInitialState,
        data: action.payload.data
      }
    case types.FETCH_JOB_POOLS_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function getJobPoolReducer (state = getJobPoolInitialState, action) {
  switch (action.type) {
    case types.GET_JOB_POOL_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.GET_JOB_POOL_REQUEST_FAILURE:
      return {
        ...getJobPoolInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.GET_JOB_POOL_REQUEST_SUCCESS:
      return {
        ...getJobPoolInitialState,
        data: action.payload.data
      }
    case types.GET_JOB_POOL_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function saveJobPoolReducer (state = saveJobPoolInitialState, action) {
  switch (action.type) {
    case types.SAVE_JOB_POOL_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.SAVE_JOB_POOL_REQUEST_FAILURE:
      return {
        ...fetchJobPoolsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.SAVE_JOB_POOL_REQUEST_SUCCESS:
      return {
        ...fetchJobPoolsInitialState,
        data: action.payload.data
      }
    case types.SAVE_JOB_POOL_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function removeJobPoolReducer (state = removeJobPoolInitialState, action) {
  switch (action.type) {
    case types.DELETE_JOB_POOL_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.DELETE_JOB_POOL_REQUEST_FAILURE:
      return {
        ...fetchJobPoolsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.DELETE_JOB_POOL_REQUEST_SUCCESS:
      return {
        ...fetchJobPoolsInitialState,
        data: action.payload.data
      }
    case types.DELETE_JOB_POOL_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export default combineReducers({
  fetchJobPoolsReducer,
  saveJobPoolReducer,
  removeJobPoolReducer,
  getJobPoolReducer
})
