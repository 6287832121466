import reducer from './reducers'

import * as alarmOperations from './operations'
import * as alarmEndpoints from './paths'

export {
  alarmOperations,
  alarmEndpoints
}

export default reducer
