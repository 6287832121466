import React, { useState } from 'react'

import {
  FloatingMenu,
  MainButton,
  ChildButton
} from 'react-floating-button-menu'

const ButtonDocumentation = () => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div style={{ position: 'fixed', left: '15px', bottom: '15px' }}>
    <FloatingMenu
      slideSpeed={500}
      direction="right"
      spacing={12}
      isOpen={isOpen}
    >
    <MainButton
      iconResting={<i className='fas fa-book' style={{ color: 'white' }} />}
      iconActive={<i className='fa fa-close' style={{ color: 'white' }} />}
      onClick={() => setIsOpen(prevState => !prevState)}
      size={56}
      style={{ backgroundColor: '#031776' }}
    />
     <ChildButton
      icon={<a href='https://gitbook.simetrik.com/v1-frontend/' target='_blank' rel='noopener noreferrer'><i className='fas fa-desktop' style={{ color: '031776' }} /></a>}
      background='white'
      size={40}
    />
    <ChildButton
      icon={<a href='https://gitbook.simetrik.com/v1-backend/guias-basicas/configuracion-de-postman' target='_blank' rel='noopener noreferrer'><i className='fas fa-database' style={{ color: '031776' }} /></a>}
      background='white'
      size={40}
    />
  </FloatingMenu>
  </div>
  )
}

export { ButtonDocumentation }
