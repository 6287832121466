import { toast } from 'react-toastify'
import * as types from './types'
import apiService from '../../utils/apiService'
import { handleApiErrors, handleApiResponseFailure, handleApiResponseSuccess } from '../../utils'
import * as API_PATHS from './paths'
import * as ROUTING_PATHS from '../../../routing/Paths'
import { history } from '../../../routing/History'
import { Session } from '../../utils/session'

const session = new Session()

export const get = (objectId) => {
  return dispatch => {
    const TYPE = types.GET_EXTRACTION_REQUEST
    const FINAL_PATH = API_PATHS.BASE_PATH + objectId + '/' // object_id
    dispatch({ type: TYPE, payload: {} })
    apiService({
      method: 'GET',
      action: TYPE,
      path: FINAL_PATH,
      async: true,
      params: null,
      body: null
    })
      .then(res => {
        if ('status' in res.data && res.data.status === 'ERROR') {
          console.log('res.data.status:', res.data.status)
          handleApiResponseFailure(dispatch, TYPE, res)
          if (res.data.error === 'No Client matches the given query.') {
            history.push(
              ROUTING_PATHS.CLIENT_DASHBOARD_EXTRACTIONS_PATH
            )
          }
        } else {
          handleApiResponseSuccess(dispatch, TYPE, res)
          // Other actions

          dispatch({ type: `${TYPE}_COMPLETED`, payload: res })
        }
      })
      .catch(err => {
        handleApiErrors(dispatch, TYPE, { data: { api_error: err, error: 'Server 500' } })
      })
  }
}

export const list = (params) => {
  return dispatch => {
    const TYPE = types.FETCH_EXTRACTIONS_REQUEST
    const FINAL_PATH = API_PATHS.BASE_PATH
    dispatch({ type: TYPE, payload: {} })
    apiService({
      method: 'GET',
      action: TYPE,
      path: FINAL_PATH,
      async: true,
      params,
      body: null
    })
      .then(res => {
        if (res.data.status === 'OK') {
          handleApiResponseSuccess(dispatch, TYPE, res)
          // Other actions

          dispatch({ type: `${TYPE}_COMPLETED`, payload: res })
        } else {
          handleApiResponseFailure(dispatch, TYPE, res)
        }
      })
      .catch(err => {
        handleApiErrors(dispatch, TYPE, { data: { api_error: err, error: 'Server 500' } })
      })
  }
}

export const save = (body) => {
  return dispatch => {
    const TYPE = types.SAVE_EXTRACTION_REQUEST
    const FINAL_PATH = API_PATHS.BASE_PATH
    dispatch({ type: TYPE, payload: {} })
    apiService({
      method: 'POST',
      action: TYPE,
      path: FINAL_PATH,
      async: true,
      params: null,
      body
    })
      .then(res => {
        if ('status' in res.data && res.data.status === 'ERROR') {
          handleApiResponseFailure(dispatch, TYPE, res)
        } else {
          handleApiResponseSuccess(dispatch, TYPE, res)
          // Other actions

          dispatch({ type: `${TYPE}_COMPLETED`, payload: res })
          history.push(
            ROUTING_PATHS.CLIENT_DASHBOARD_EXTRACTION_DETAILS_PATH
              .replace(':client_id', session.getClientSession())
              .replace(':extraction_id', res.data.id)
          )
        }
      })
      .catch(err => {
        handleApiErrors(dispatch, TYPE, { data: { api_error: err.response?.data, error: err.response?.data?.details || 'Server 500' } })
      })
  }
}

export const update = (objectId, body, redirectPath = null) => {
  return dispatch => {
    const TYPE = types.UPDATE_EXTRACTION_REQUEST
    const FINAL_PATH = API_PATHS.BASE_PATH + objectId + '/' // object_id
    dispatch({ type: TYPE, payload: {} })
    apiService({
      method: 'PUT',
      action: TYPE,
      path: FINAL_PATH,
      async: true,
      params: null,
      body
    })
      .then(res => {
        if ('status' in res.data && res.data.status === 'ERROR') {
          handleApiResponseFailure(dispatch, TYPE, res)
        } else {
          handleApiResponseSuccess(dispatch, TYPE, res)
          dispatch({ type: `${TYPE}_COMPLETED`, payload: res })
          if (redirectPath) {
            history.push(redirectPath)
          } else {
            history.push(
              ROUTING_PATHS.CLIENT_DASHBOARD_EXTRACTION_DETAILS_PATH
                .replace(':client_id', session.getClientSession())
                .replace(':extraction_id', res.data.id)
            )
          }
        }
      })
      .catch(err => {
        handleApiErrors(dispatch, TYPE, { data: { api_error: err.response?.data, error: err.response?.data?.details || 'Server 500' } })
      })
  }
}

export const remove = id => {
  return dispatch => {
    const TYPE = types.REMOVE_EXTRACTION_REQUEST
    dispatch({ type: TYPE, payload: {} })
    apiService({
      method: 'DELETE',
      action: TYPE,
      path: `${API_PATHS.BASE_PATH}${id}/`,
      async: true,
      params: null,
      body: null
    })
      .then(_ => {
        handleApiResponseSuccess(dispatch, TYPE, id)
      })
      .catch(_ => {
        toast.error('Error al intentar eliminar', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        })
      })
  }
}
