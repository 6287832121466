import { combineReducers } from 'redux'
import * as types from './types'

const fetchAccountingEntriesInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: [],
  summary: {
    BY_STATUS: {},
    TOTAL: 0
  },
  pagination_data: {}
}

export function fetchAccountingEntriesReducer (state = fetchAccountingEntriesInitialState, action) {
  switch (action.type) {
    case types.FETCH_ACCOUNTING_ENTRIES_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.FETCH_ACCOUNTING_ENTRIES_REQUEST_FAILURE:
      return {
        ...fetchAccountingEntriesInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.FETCH_ACCOUNTING_ENTRIES_REQUEST_SUCCESS:
      return {
        ...fetchAccountingEntriesInitialState,
        data: action.payload.data.entries,
        summary: action.payload.data.summary,
        pagination_data: action.payload.data.pagination_data
      }
    case types.FETCH_ACCOUNTING_ENTRIES_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data.entries,
        completed: true,
        pagination_data: action.payload.data.pagination_data
      }
    default:
      return state
  }
}

export default combineReducers({
  fetchAccountingEntriesReducer
})
