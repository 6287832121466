import React, { Component } from 'react'
import SelectSearchLayout from '../../../layouts/select-search'
import * as PropTypes from 'prop-types'
import { connectionOperations } from '../../../../state/ducks/connections'
import { connect } from 'react-redux'

class AccountingSeatSettingDataForm extends Component {
  constructor (props) {
    super(props)

    // state
    this.state = {
    }

    this.handleChange = this.handleChange.bind(this)
    this.returnConnectionOptions = this.returnConnectionOptions.bind(this)
    this.onConnectionSearchKeywordChange = this.onConnectionSearchKeywordChange.bind(this)
    this.onSelectConnection = this.onSelectConnection.bind(this)
    this.handleBatchOrderChange = this.handleBatchOrderChange.bind(this)
  }

  handleChange (event) {
    this.props.onChange(event.target.name, event.target.value)
  }

  handleBatchOrderChange (event) {
    this.props.onChange(event.target.name, parseFloat(event.target.value))
  }

  returnConnectionOptions () {
    const options = []
    this.props.connections_list.forEach(element => {
      options.push({
        value: element.id + '|' + element.name,
        label: element.name
      })
    })
    return options
  }

  onConnectionSearchKeywordChange (keyword) {
    this.returnConnectionOptions()
    this.props.fetchConnections({ search: keyword })
  }

  onSelectConnection (item) {
    const split_ = item.value.split('|')
    // id: split_[0,
    // connection_name: split_[1]
    this.props.onChange('connection', split_[0])
  }

  render () {
    return (
            <>
                <div className="form-group">
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <div className="form-material">
                            <input className="form-control"
                                   type="text"
                                   id="input-name"
                                   placeholder="Please enter a name"
                                   name="name"
                                   value={this.props.accountingSeatSetting.name || ''}
                                   onChange={this.handleChange}
                            />
                            <label htmlFor="input-name">Name</label>
                        </div>
                    </div>
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <div className="form-material">
                            <input className="form-control"
                                   type="text"
                                   id="input-table_name"
                                   placeholder="Please enter a table name"
                                   name="table_name"
                                   value={this.props.accountingSeatSetting.table_name || ''}
                                   onChange={this.handleChange}
                            />
                            <label htmlFor="input-name">Table Name</label>
                        </div>
                    </div>
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <div className="form-material">
                            <label htmlFor="input-connections">Connection</label>
                            <SelectSearchLayout
                                placeholder={this.props.accountingSeatSetting.connection ? this.props.accountingSeatSetting.connection_name : 'Type to search an item...'}
                                getOptions={this.returnConnectionOptions}
                                onSearchChange={this.onConnectionSearchKeywordChange}
                                onSelectItem={this.onSelectConnection}
                            />
                        </div>
                    </div>
                </div>
            </>
    )
  }
}

// Set propTypes
AccountingSeatSettingDataForm.propTypes = {
  fetchConnections: PropTypes.func.isRequired
}

// Set defaultProps
AccountingSeatSettingDataForm.defaultProps = {
  accountingSeatSetting: {},
  onChange: () => {
  }
}

// mapStateToProps
const mapStateToProps = (state) => ({
  connections_list: state.connections.fetchConnectionsReducer.data.results
})

// mapStateToProps
const mapDispatchToProps = {
  fetchConnections: connectionOperations.list
}

// Export Class
const _AccountingSeatSettingDataForm = connect(mapStateToProps,
  mapDispatchToProps)(AccountingSeatSettingDataForm)
export { _AccountingSeatSettingDataForm as AccountingSeatSettingDataForm }
