import React, { Component } from 'react'
import { EmptyTableLayout } from '../../../layouts'
import { history } from '../../../../routing/History'
import * as ROUTING_PATHS from '../../../../routing/Paths'

class PermissionsTable extends Component {
  constructor (props) {
    super(props)

    this.state = {}

    this.goToPermissionEditor = this.goToPermissionEditor.bind(this)
    this.selectPermission = this.selectPermission.bind(this)
    this.deletePermission = this.deletePermission.bind(this)
  }

  goToPermissionEditor (permission) {
    history.push(ROUTING_PATHS.PERMISSION_EDITOR_PATH.replace(':permission_id', permission.id))
  }

  selectPermission (permission) {
    history.push(ROUTING_PATHS.PERMISSION_DETAILS_PATH.replace(':permission_id', permission.id))
  }

  deletePermission (permission) {

  }

  render () {
    return (
            <>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered table-condensed text-center">
                        <thead>
                        <tr>
                            <th className="text-center">ID</th>
                            <th className="text-center">Name</th>
                            <th className="text-center">Codename</th>
                            <th className="text-center">Options</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.permissions.map((permission) => {
                              return (
                                    <tr key={permission.id}>
                                        <td className="text-center">{permission.id}</td>
                                        <td className="text-center">{permission.name}</td>
                                        <td className="text-center">{permission.codename}</td>
                                        <td className="text-center">
                                            <div className="btn-group">
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Edit Permission"
                                                        onClick={this.goToPermissionEditor.bind(this, permission)}
                                                >
                                                    <i className="fa fa-pen"></i>
                                                </button>
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Select Permission"
                                                        onClick={this.selectPermission.bind(this, permission)}
                                                >
                                                    <i className="fa fa-eye"></i>
                                                </button>
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Remove Permission"
                                                        onClick={this.deletePermission.bind(this, permission)}
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                              )
                            })
                        }
                        <EmptyTableLayout results={this.props.permissions} columns={4}/>
                        </tbody>
                    </table>
                    {this.props.children}
                </div>
            </>
    )
  }
}

// Set defaultProps
PermissionsTable.defaultProps = {
  permissions: []
}

// Export Class
export { PermissionsTable }
