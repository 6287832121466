import React, { Component } from 'react'
import { history } from '../../../../routing/History'
import { GROUP_CREATOR_PATH } from '../../../../routing/Paths'

class GroupsHeaderTiles extends Component {
  constructor (props) {
    super(props)

    this.state = {}

    this.goToGroupCreator = this.goToGroupCreator.bind(this)
  }

  goToGroupCreator () {
    history.push(GROUP_CREATOR_PATH)
  }

  render () {
    return (
            <>
                <div className="row">
                    <div className="col-sm-6 col-md-3">
                        <a className="block block-link-hover3 text-center"
                           href="javascript:void(0)"
                           onClick={this.goToGroupCreator}
                        >
                            <div className="block-content block-content-full">
                                <div className="h1 font-w700 text-success"><i className="fa fa-plus"></i></div>
                            </div>
                            <div className="block-content block-content-full block-content-mini bg-gray-lighter text-success font-w600">
                                Add New Group
                            </div>
                        </a>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <a className="block block-link-hover3 text-center" href="javascript:void(0)">
                            <div className="block-content block-content-full">
                                <div className="h1 font-w700">{this.props.all_groups}</div>
                            </div>
                            <div className="block-content block-content-full block-content-mini bg-gray-lighter text-muted font-w600">
                                All groups
                            </div>
                        </a>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <a className="block block-link-hover3 text-center" href="javascript:void(0)">
                            <div className="block-content block-content-full">
                                <div className="h1 font-w700">{this.props.admins}</div>
                            </div>
                            <div className="block-content block-content-full block-content-mini bg-gray-lighter text-muted font-w600">
                                Admins
                            </div>
                        </a>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <a className="block block-link-hover3 text-center" href="javascript:void(0)">
                            <div className="block-content block-content-full">
                                <div className="h1 font-w700">{this.props.developers}</div>
                            </div>
                            <div className="block-content block-content-full block-content-mini bg-gray-lighter text-muted font-w600">
                                Developers
                            </div>
                        </a>
                    </div>
                </div>
            </>
    )
  }
}

// Set defaultProps
GroupsHeaderTiles.defaultProps = {
  all_groups: 0,
  admins: 0,
  developers: 0
}

// Export Class
export { GroupsHeaderTiles }
