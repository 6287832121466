import axios from 'axios'
import config from '../../config'
import { Session } from './session'

const setHeaders = (params = {}) => {
  const session = new Session()
  const options = {}
  if (session.getSession()) {
    options.headers = {
      authorization: 'JWT ' + session.selectedAuth
    }
  }
  if (jsonToQueryString(params) !== '?') {
    options.params = params
    // history.push({
    //     pathname: history.location.pathname,
    //     search: jsonToQueryString(params)
    // })
  }
  return options
}

export const jsonToQueryString = (json) => {
  if (json) {
    return (
      '?' +
      Object.keys(json)
        .map(function (key) {
          return encodeURIComponent(key) + '=' + encodeURIComponent(json[key])
        })
        .join('&')
    )
  }
  return ''
}

export const queryStringToJSON = (queryString) => {
  const pairs = queryString.slice(1).split('&')
  const result = {}
  pairs.forEach(function (pair) {
    pair = pair.split('=')
    result[pair[0]] = decodeURIComponent(pair[1] || '')
  })
  return JSON.parse(JSON.stringify(result))
}

const apiService = (meta) => {
  // Path is Required
  if (!meta.path) {
    throw new Error(`'path' not specified for async action ${meta.action}`)
  }

  // Final URL
  let url = `${config.apiEndPoint}${meta.path}`

  // replace client_id
  const session = new Session()
  url = url.replace(':client_session_id', session.getClientSession())

  let request = axios.create()
  switch (meta.method) {
    case 'GET':
      request = axios.get(url, setHeaders(meta.params))
      break
    case 'POST':
      request = axios.post(url, meta.body, setHeaders(meta.params))
      break
    case 'PATCH':
      request = axios.patch(url, meta.body, setHeaders(meta.params))
      break
    case 'PUT':
      request = axios.put(url, meta.body, setHeaders(meta.params))
      break
    case 'DELETE':
      request = axios.delete(url, setHeaders(meta.params))
      break
  }
  return request
}

export default apiService
