import React from 'react'
import { jsonPrettify } from '../../../../utils/jsonPrettify'

class SourceColumnNormalizationModal extends React.Component {
  constructor (props) {
    super(props)

    this.state = {}
  }

  modalContent () {
    return (
            <>
                {
                    this.props.column.normalizations.map(n => {
                      return (
                            <>
                                <div className="row text-center">
                                    <div className="col-xs-6 text-left">
                                        <ul className="list-group">
                                            <li className="list-group-item">
                                                <b>ID: </b>
                                                {n.id}
                                            </li>
                                            <li className="list-group-item">
                                                <b>Operation: </b>
                                                {n.operation}
                                            </li>
                                            <li className="list-group-item">
                                                <b>Batch order: </b>
                                                {n.batch_order}
                                            </li>
                                            <li className="list-group-item">
                                                <b>Overwrite: </b>
                                                {JSON.stringify(n.overwrite)}
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-xs-6 text-left">
                                        <div>
                                            <pre>
                                                <code>
                                                    {jsonPrettify(n.parameters)}
                                                </code>
                                            </pre>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                            </>
                      )
                    })
                }
            </>
    )
  }

  render () {
    return (
            <>
                {/* MODAL */}
                <div className="modal" id="normalizations-modal-details" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className=" block block-themed block-opt-refresh-icon7 block-transparent remove-margin-b">
                                <div className="block-header bg-primary-dark">
                                    <ul className="block-options">
                                        <li>
                                            <button data-dismiss="modal" type="button"><i className="si si-close"></i>
                                            </button>
                                        </li>
                                    </ul>
                                    <h3 className="block-title">Normalizations for
                                        Column: {this.props.column.column_name}</h3>
                                </div>
                                <div className="block-content">
                                    {this.modalContent()}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-sm btn-default" type="button" data-dismiss="modal">
                                    Close
                                </button>
                                <button className="btn btn-sm btn-primary" type="button" data-dismiss="modal">
                                    <i className="fa fa-check"></i> Ok
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </>
    )
  }
}

SourceColumnNormalizationModal.defaultProps = {
  column: { normalizations: [] },
  onChange: () => {
  }
}

export default SourceColumnNormalizationModal
