import React from 'react'
import { Button } from 'react-bootstrap'
import { parametersListToObject, parametersObjectToList } from '../../../../utils/parameters'
import JSONInput from 'react-json-editor-ajrm'

class ParametersCreatorForm extends React.Component {
  state = {
    editorType: this.props.editorType,
    parameters_list: []
  }

  componentDidMount () {
    this.updateList(this.props.parameters)
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    this.updateList(nextProps.parameters)
  }

  updateList (parameters) {
    this.setState({ parameters_list: parametersObjectToList(parameters) })
  }

  keyPressHandler (e) {
    const re = /[0-9a-zA-Z]+/g
    if (!re.test(e.key)) {
      e.preventDefault()
    }
  }

  remove (index) {
    const { parameters_list: parametersList } = this.state
    parametersList.splice(index, 1)
    this.setState({ parameters_list: parametersList })
    this.onChange(parametersList)
  }

  add () {
    const { parameters_list: parametersList } = this.state
    parametersList.push({
      id: parametersList.length + 1,
      key: '',
      value: ''
    })
    this.setState({ parameters_list: parametersList })
    this.onChange(parametersList)
  }

  handleKey (event, index) {
    const { parameters_list: parametersList } = this.state
    parametersList[index][event.target.name] = event.target.value
    this.setState({ parameters_list: parametersList }, () => {
      let duplicates = false
      parametersList.forEach(p => {
        if (parametersList.filter(i => i.key === p.key).length > 1) {
          duplicates = true
        }
      })
      if (!duplicates) {
        this.onChange(parametersList)
      }
    })
  }

  handleValue (event, index) {
    const parametersList = JSON.parse(JSON.stringify(this.state.parameters_list))
    parametersList[index].value = event.target.value
    parametersList[index].format = 'string'
    if (event.target.value.length > 0 && !isNaN(event.target.value)) {
      parametersList[index].value = parseFloat(event.target.value)
      parametersList[index].format = 'number'
    } else if (event.target.value === 'true' || event.target.value === 'false') {
      parametersList[index].value = JSON.parse(event.target.value)
      parametersList[index].format = 'boolean'
    }
    this.setState({ parameters_list: parametersList }, () => this.onChange(parametersList))
  }

  handleFormat (event, index) {
    const parametersList = JSON.parse(JSON.stringify(this.state.parameters_list))
    parametersList[index].format = event.target.value
    if (parametersList[index].format === 'string') {
      parametersList[index].value = parametersList[index].value.toString()
    }
    if (parametersList[index].format === 'number' && !isNaN(parametersList[index].value)) {
      parametersList[index].value = parseFloat(parametersList[index].value)
    }
    if (parametersList[index].format === 'boolean' && (parametersList[index].value === 'true' || parametersList[index].value === 'false')) {
      parametersList[index].value = JSON.parse(parametersList[index].value)
    }
    this.setState({ parameters_list: parametersList }, () => this.onChange(parametersList))
  }

  onChange (parametersList) {
    const object = parametersListToObject(parametersList)
    this.props.onParametersChange(object)
  }

  onJsonEditorChange (e) {
    if (e.jsObject) {
      this.props.onParametersChange(e.jsObject)
    }
  }

  handleEditorType (type) {
    this.setState({
      editorType: type
    })
  }

  renderKeyValueCreator () {
    return (
            <>
                <table className="table table-bordered">
                    <tbody>
                    {
                        this.state.parameters_list.map((parameter, index) => {
                          return (
                                <tr key={parameter.id}>
                                    <td>
                                        <input className="form-control"
                                               type="text"
                                               placeholder="Key"
                                               name="key"
                                               value={parameter.key}
                                               onChange={(e) => this.handleKey(e, index)}
                                        />
                                    </td>
                                    <td>
                                        <input className="form-control"
                                               type="text"
                                               placeholder="Value"
                                               name="value"
                                               value={parameter.value}
                                               onChange={(e) => this.handleValue(e, index)}
                                        />
                                    </td>
                                    <td>
                                        <select className="form-control"
                                                name="format"
                                                value={parameter.format}
                                                onChange={(e) => this.handleFormat(e, index)}
                                        >
                                            <option value="string">String</option>
                                            <option value="number">Number</option>
                                            <option value="boolean">Boolean</option>
                                        </select>
                                    </td>
                                    <td className="text-center">
                                        <Button
                                            variant="danger"
                                            className="mr-1"
                                            onClick={(e) => {
                                              e.preventDefault()
                                              this.remove(index)
                                            }}
                                        >
                                            -
                                        </Button>
                                    </td>
                                </tr>
                          )
                        })
                    }
                    </tbody>
                </table>
                <h6 className="text-primary cursor-pointer" onClick={this.add.bind(this)}>+ Add Parameter</h6>
            </>
    )
  }

  renderJsonCreator () {
    return (
            <>
                <JSONInput
                    className="text-left"
                    id='a_unique_id'
                    placeholder={this.props.parameters}
                    height='300px'
                    width="100%"
                    onChange={this.onJsonEditorChange.bind(this)}
                />
            </>
    )
  };

  render () {
    return (
            <>
                <div className="block text-left">
                    <ul className="nav nav-tabs nav-tabs-alt nav-justified" data-toggle="tabs">
                        <li className={(this.state.editorType === 1 ? 'active' : '')} onClick={this.handleEditorType.bind(this, 1)}>
                            <a href="#btabs-alt-static-justified-home"><i className="fa fa-pen"></i> Key/Value</a>
                        </li>
                        <li className={(this.state.editorType === 2 ? 'active' : '')} onClick={this.handleEditorType.bind(this, 2)}>
                            <a href="#btabs-alt-static-justified-profile"><i className="fa fa-code"></i> Json</a>
                        </li>
                    </ul>
                    <div className="block-content tab-content">
                        <div className={'tab-pane' + (this.state.editorType === 1 ? 'active' : '')} id="btabs-alt-static-justified-home">
                            {this.renderKeyValueCreator()}
                        </div>
                        <div className={'tab-pane' + (this.state.editorType === 2 ? 'active' : '')} id="btabs-alt-static-justified-profile">
                            {this.renderJsonCreator()}
                        </div>
                    </div>
                </div>
            </>
    )
  }
}

// defaultProps
ParametersCreatorForm.defaultProps = {
  editorType: 1
}

export { ParametersCreatorForm }
