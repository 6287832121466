import React, { Component } from 'react'

class PaginationLayout extends Component {
  state = {
    currentPage: 1,
    translations: [
      { language: 'en', actions: { next: 'Next', previous: 'Previous', showing: 'Showing', to: 'to', of: 'of', entries: 'entries', page: 'Page' } },
      { language: 'es', actions: { next: 'Siguiente', previous: 'Anterior', showing: 'Mostrando', to: 'a', of: 'de', entries: 'registros', page: 'Pagina' } }
    ],
    enabledTranslation: { language: 'en', actions: { next: 'Next', previous: 'Previous', showing: 'Showing', to: 'to', of: 'of', entries: 'entries', page: 'Page' } }
  }

  getTranslation (language) {
    return this.state.translations.filter((item) => item.language === language)[0]
  }

  componentDidMount () {
    this.setState({ currentPage: this.props.pagination.currentPage, enabledTranslation: this.getTranslation('es') })
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.pagination.currentPage !== this.state.currentPage) {
      this.setState({ currentPage: nextProps.pagination.currentPage })
    }
  }

  previousPage () {
    if (this.props.pagination.currentPage >= 1) {
      const page = this.props.pagination.currentPage - 1
      this.changePagination(page)
    }
  }

  nextPage () {
    const page = this.props.pagination.currentPage + 1
    this.changePagination(page)
  }

  onPageChange (e) {
    this.setState({ currentPage: e.target.value })
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.changePagination(this.state.currentPage)
    }
  }

  changePagination (page) {
    if (page > 0 && page <= this.props.pagination.totalPages) {
      this.props.onPaginationChange(page)
    }
  }

  render () {
    const var1 = (this.props.pagination.totalItemsOnPage * (this.props.pagination.currentPage - 1)) + 1
    const var2 = (this.props.pagination.totalItemsOnPage * (this.props.pagination.currentPage - 1)) +
            this.props.pagination.totalItemsOnPage
    const var3 = var2 > this.props.pagination.totalItems ? this.props.pagination.totalItems : var2
    return (
            <>
                {
                    this.props.pagination.totalItems && this.props.pagination.totalPages >= 1
                      ? <nav>
                            <ul className="pager">
                                <li>
                                    <span>{this.state.enabledTranslation.actions.showing}&nbsp;
                                        {this.props.pagination.currentPage === 1 ? 1 : <>{var1}</>} {this.state.enabledTranslation.actions.to} {var3}
                                        &nbsp;{this.state.enabledTranslation.actions.of}&nbsp;{this.props.pagination.totalItems}&nbsp;{this.state.enabledTranslation.actions.entries}.
                                    </span>
                                </li>
                                <li className="previous">
                                    <a
                                        className="cursor-pointer"
                                        onClick={this.previousPage.bind(this)}
                                        disabled={!this.props.pagination.previousPage}
                                    >
                                        {this.state.enabledTranslation.actions.previous}
                                    </a>
                                </li>
                                <li className="center">
                                    <span>
                                        {this.state.enabledTranslation.actions.page}: {this.state.currentPage} / {this.props.pagination.totalPages}
                                    </span>
                                </li>
                                <li className="next">
                                    <a
                                        className="cursor-pointer"
                                        onClick={this.nextPage.bind(this)}
                                        disabled={!this.props.pagination.nextPage}
                                    >
                                        {this.state.enabledTranslation.actions.next}
                                    </a>
                                </li>
                            </ul>
                        </nav>
                      : null
                }
            </>
    )
  }
}

PaginationLayout.defaultProps = {
  pagination: { totalItems: 0 },
  onPaginationChange: function () {
  },
  totalPages: 0
}

export { PaginationLayout }
