import reducer from './reducers'

import * as sourceOperations from './operations'
import * as sourceEndpoints from './paths'

export {
  sourceOperations,
  sourceEndpoints
}

export default reducer
