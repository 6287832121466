import React, { Component } from 'react'
import { InputSearchLayout, PageContainerLayout, PageHeaderLayout, PaginationLayout } from '../../../layouts'
import { ConnectionsHeaderTiles, ConnectionsTable } from '../../containers'
import { connectionOperations } from '../../../../state/ducks/connections'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { ConnectionShape, PaginationShape } from '../../../../prop-types'

class ConnectionsPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      params: {}
    }
    this.fetchConnections = this.fetchConnections.bind(this)
    this.onPaginationChange = this.onPaginationChange.bind(this)
    this.onSearchChange = this.onSearchChange.bind(this)
    this.updateParams = this.updateParams.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.fetchConnections()
  }

  fetchConnections () {
    this.props.fetchConnections(this.state.params)
  }

  onPaginationChange (page) {
    this.updateParams('page', page)
  }

  onSearchChange (keywork) {
    this.updateParams('search', keywork)
  }

  updateParams (key, value) {
    const { params } = this.state
    params[key] = value
    if (key === 'search') {
      params.page = 1
    }
    this.setState({
      params
    }, () => this.fetchConnections())
  }

  renderHeaderPaths () {
    return (
            <>
                <li>Connections</li>
            </>
    )
  }

  render () {
    return (
            <>
                <PageContainerLayout clientSidebar={true} showNavbarClientOptions={true}>
                    <PageHeaderLayout title={'Connections'} subTitle={'All connections'}
                                      paths={this.renderHeaderPaths}/>
                    {/* Page Content */}
                    <div className="content content-boxed">
                        {/* Header Tiles */}
                        <ConnectionsHeaderTiles all_connections={this.props.paginationData.totalItems}/>
                        {/* END Header Tiles */}
                        <div className={' block block-themed block-opt-refresh-icon7 ' + (this.props.isLoading ? ' block-opt-refresh ' : '')}>
                            <div className="block-header bg-primary-dark">
                                <ul className="block-options">
                                    <li>
                                        <button type="button" data-toggle="block-option"
                                                data-action="fullscreen_toggle">
                                            <i className="si si-size-fullscreen"/>
                                        </button>
                                    </li>
                                    <li>
                                        <button type="button"
                                                data-toggle="block-option"
                                                data-action="refresh_toggle"
                                                data-action-mode="demo"
                                                onClick={this.fetchConnections}
                                        >
                                            <i className="si si-refresh"></i>
                                        </button>
                                    </li>
                                </ul>
                                <h3 className="block-title">All connections</h3>
                            </div>
                            <div className="block-content">
                                {/* Search */}
                                <InputSearchLayout onSearchChange={this.onSearchChange}/>
                                {/* End Search */}
                                {/* Table */}
                                <ConnectionsTable connections={this.props.connections}>
                                    {/* Pagination */}
                                    <PaginationLayout pagination={this.props.paginationData}
                                                      onPaginationChange={this.onPaginationChange}/>
                                    {/* End Pagination */}
                                </ConnectionsTable>
                                {/* End Table */}
                            </div>
                        </div>
                    </div>
                    {/* END Page Content */}
                </PageContainerLayout>
            </>
    )
  };
}

// Set propTypes
const { arrayOf, func } = PropTypes
ConnectionsPage.propTypes = {
  connections: arrayOf(ConnectionShape).isRequired,
  fetchConnections: func.isRequired,
  paginationData: PaginationShape
}

// Set defaultProps
ConnectionsPage.defaultProps = {
  connections: [],
  paginationData: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  isLoading: state.connections.fetchConnectionsReducer.loading,
  connections: state.connections.fetchConnectionsReducer.data.results,
  paginationData: state.connections.fetchConnectionsReducer.data.pagination_data
})

// mapStateToProps
const mapDispatchToProps = {
  fetchConnections: connectionOperations.list
}

// Export Class
const _ConnectionsPage = connect(mapStateToProps, mapDispatchToProps)(ConnectionsPage)
export { _ConnectionsPage as ConnectionsPage }
