import React, { Component } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

class SourceHeaderLayout extends Component {
  render () {
    return (
            <>
                <div className="content bg-gray-lighter">
                    <div className="row items-push">
                        <div className="col-sm-7">
                            <h1 className="page-heading">
                                {this.props.title} <small>{this.props.subTitle}</small>

                                <CopyToClipboard text={this.props.title}>
                                    <button className="btn btn-s btn-default"
                                            type="button"
                                            data-toggle="tooltip"
                                            title="Copy to Clipboard">
                                        <i className="far fa-copy"></i>
                                    </button>

                                </CopyToClipboard>
                            </h1>
                        </div>
                        <div className="col-sm-5 text-right hidden-xs">
                            <ol className="breadcrumb push-10-t">{this.props.paths()}</ol>
                        </div>
                    </div>
                </div>
            </>
    )
  }
}

// Set defaultProps
SourceHeaderLayout.defaultProps = {
  title: null,
  subTitle: null,
  paths: () => null
}

export { SourceHeaderLayout }
