import { combineReducers } from 'redux'
import * as types from './types'

const fetchWorkerCallsInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { results: [], pagination_data: {} }
}

const getWorkerCallInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

const saveWorkerCallInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

const updateWorkerCallInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

const removeWorkerCallInitialstate = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

export function fetchWorkerCallsReducer (
  state = fetchWorkerCallsInitialState,
  action
) {
  switch (action.type) {
    case types.FETCH_WORKER_CALLS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.FETCH_WORKER_CALLS_REQUEST_FAILURE:
      return {
        ...state,
        error_data: action.payload.data,
        failed: true
      }
    case types.FETCH_WORKER_CALLS_REQUEST_SUCCESS:
      return {
        ...fetchWorkerCallsInitialState,
        data: action.payload.data
      }
    case types.FETCH_WORKER_CALLS_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function getWorkerCallReducer (
  state = getWorkerCallInitialState,
  action
) {
  switch (action.type) {
    case types.GET_WORKER_CALLS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.GET_WORKER_CALLS_REQUEST_FAILURE:
      return {
        ...state,
        error_data: action.payload.data,
        failed: true
      }
    case types.GET_WORKER_CALLS_REQUEST_SUCCESS:
      return {
        ...getWorkerCallInitialState,
        data: action.payload.data
      }
    case types.GET_WORKER_CALLS_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function saveWorkerCallReducer (
  state = saveWorkerCallInitialState,
  action
) {
  switch (action.type) {
    case types.SAVE_WORKER_CALL_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.SAVE_WORKER_CALL_REQUEST_FAILURE:
      return {
        ...saveWorkerCallInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.SAVE_WORKER_CALL_REQUEST_SUCCESS:
      return {
        ...saveWorkerCallInitialState,
        data: action.payload.data
      }
    case types.SAVE_WORKER_CALL_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function updateWorkerCallReducer (
  state = updateWorkerCallInitialState,
  action
) {
  switch (action.type) {
    case types.UPDATE_WORKER_CALL_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.UPDATE_WORKER_CALL_REQUEST_FAILURE:
      return {
        ...updateWorkerCallInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.UPDATE_WORKER_CALL_REQUEST_SUCCESS:
      return {
        ...updateWorkerCallInitialState,
        data: action.payload.data
      }
    case types.UPDATE_WORKER_CALL_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function removeWorkerCallReducer (
  state = removeWorkerCallInitialstate,
  action
) {
  switch (action.type) {
    case types.DELETE_WORKER_CALL_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.DELETE_WORKER_CALL_REQUEST_FAILED:
      return {
        ...removeWorkerCallInitialstate,
        error_data: action.payload.data,
        failed: true
      }
    case types.DELETE_WORKER_CALL_REQUEST_SUCCESS:
      return {
        ...removeWorkerCallInitialstate,
        data: action.payload.data
      }
    case types.DELETE_WORKER_CALL_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export default combineReducers({
  fetchWorkerCallsReducer,
  getWorkerCallReducer,
  saveWorkerCallReducer,
  updateWorkerCallReducer,
  removeWorkerCallReducer
})
