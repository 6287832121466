import React from 'react'
import { Button } from 'react-bootstrap'
import { ParametersCreatorForm } from '../parameters-creator-form'
import { EmptyTableLayout } from '../../../layouts/empty-table'

const initialValue = {
  id: null,
  operation: '',
  parameters: {},
  parameters_list: {},
  batch_order: 1,
  overwrite: false
}

class SourceColumnNormalizationModalForm extends React.Component {
  state = {
    new_normalization: JSON.parse(JSON.stringify(initialValue)),
    normalizations: []
  }

  componentDidMount () {
    this.setState({ normalizations: this.props.column.normalizations })
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    this.setState({ normalizations: nextProps.column.normalizations })
  }

  saveNormalization = () => {
    const normalizations = this.state.normalizations
    const newNormalization = JSON.parse(JSON.stringify(this.state.new_normalization))
    normalizations.push(newNormalization)
    this.setState({
      normalizations,
      new_normalization: JSON.parse(JSON.stringify(initialValue))
    })
  }

  clean () {
    this.setState({
      new_normalization: JSON.parse(JSON.stringify(initialValue))
    })
  }

  edit (normalization, index = this.state.uniqueness.length + 1) {
    const norma = JSON.parse(JSON.stringify(normalization))
    norma.index = index
    norma.to_edit = true
    this.setState({
      new_normalization: norma
    })
  }

  remove (normalization, index) {
    if (window.confirm('Are you sure?')) {
      const { normalizations } = this.state
      normalizations.splice(index, 1)
      this.setState({ normalizations })
    }
  }

  handleNormalizationAttrChange (event) {
    const { new_normalization: newNormalization } = this.state
    newNormalization[event.target.name] = event.target.value
    this.setState({ new_normalization: newNormalization })
  }

  onParametersChange (parameters) {
    const newNormalization = JSON.parse(JSON.stringify(this.state.new_normalization))
    newNormalization.parameters = parameters
    this.setState({
      new_normalization: newNormalization
    })
  }

  onChangeHandler (e, index) {
    const { normalizations } = this.state
    normalizations[index][e.target.name] = e.target.value
    this.setState({ normalizations })
  }

  saveNormalizations = () => {
    const column = JSON.parse(JSON.stringify(this.props.column))
    column.normalizations = this.state.normalizations
    this.props.onChange(column)
  }

  updateNormalization () {
    const { normalizations } = this.state
    normalizations[this.state.new_normalization.index] = this.state.new_normalization
    this.setState({ normalizations, new_normalization: JSON.parse(JSON.stringify(initialValue)) })
  }

  returnButton () {
    if (!this.state.new_normalization.to_edit) {
      return (
                <Button type="button" variant="primary" onClick={(e) => {
                  e.preventDefault()
                  this.saveNormalization()
                }}>Add</Button>
      )
    } else {
      return (
                <Button type="button" variant="primary" onClick={(e) => {
                  e.preventDefault()
                  this.updateNormalization()
                }}>Update</Button>
      )
    }
  }

  modalContent () {
    return (
            <>
                <div className="row text-center">
                    <div className="col-xs-6">
                        <div className="block">
                            <div className="block-content border">
                                <div className="form-group">
                                    <div className="col-sm-12">
                                        <div className="form-material">
                                            <select className="form-control"
                                                    id="input-operation"
                                                    name="operation"
                                                    value={this.state.new_normalization.operation}
                                                    onChange={this.handleNormalizationAttrChange.bind(this)}
                                            >
                                                <option value=""></option>
                                                <option value="replace">replace</option>
                                                <option value="regex_extract">regex_extract
                                                </option>
                                                <option value="fill">fill</option>
                                                <option value="trim">trim</option>
                                                <option value="skip">skip</option>
                                                <option value="split">split</option>
                                                <option value="substring">substring</option>
                                                <option value="concat">concat</option>
                                                <option value="math">math</option>
                                                <option value="datetime">datetime</option>
                                                <option value="date">date</option>
                                                <option value="date_cast">date_cast</option>
                                                <option value="skt_id">skt_id</option>
                                                <option value="sql">sql</option>
                                            </select>
                                            <label htmlFor="input-operation">Operation</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="col-sm-12">
                                        <div className="form-material">
                                            <input className="form-control"
                                                   id="input-batch_order"
                                                   type="numeric"
                                                   name="batch_order"
                                                   value={this.state.new_normalization.batch_order}
                                                   onChange={this.handleNormalizationAttrChange.bind(this)}
                                            />
                                            <label htmlFor="input-batch_order">Batch Order</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="col-sm-12">
                                        <div className="form-material">
                                            <select className="form-control"
                                                    id="input-overwrite"
                                                    name="overwrite"
                                                    value={this.state.new_normalization.overwrite}
                                                    onChange={this.handleNormalizationAttrChange.bind(this)}
                                            >
                                                <option value={false}>False</option>
                                                <option value={true}>True</option>
                                            </select>
                                            <label htmlFor="input-overwrite">Overwrite</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="col-sm-12">
                                        <ParametersCreatorForm
                                            parameters={this.state.new_normalization.parameters}
                                            onParametersChange={this.onParametersChange.bind(this)}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <Button
                                        variant="default"
                                        className="mr-1"
                                        onClick={this.clean.bind(this)}
                                    >
                                        Clean Form
                                    </Button>
                                    {
                                        this.returnButton()
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-6">
                        <div className="block">
                            <div className="block-content border">
                                <table className="table table-striped table-borderless table-header-bg text-center text-center ">
                                    <thead>
                                    <tr>
                                        <th className="text-center">ID</th>
                                        <th className="text-center">Operation</th>
                                        <th className="text-center">Options</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        this.state.normalizations.map((normalization, index) => {
                                          return (
                                                <tr key={normalization.id}>
                                                    <td>{normalization.id}</td>
                                                    <td>{normalization.operation}</td>
                                                    <td>
                                                        <div className="btn-group">
                                                            <button className="btn btn-xs btn-default"
                                                                    type="button"
                                                                    data-toggle="tooltip"
                                                                    title="Edit Normalization"
                                                                    onClick={this.edit.bind(this, normalization, index)}
                                                            >
                                                                <i className="fa fa-pen"></i>
                                                            </button>
                                                            <button className="btn btn-xs btn-default"
                                                                    type="button"
                                                                    data-toggle="tooltip"
                                                                    title="Remove Normalization"
                                                                    onClick={this.remove.bind(this, normalization, index)}
                                                            >
                                                                <i className="fa fa-trash"></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                          )
                                        })
                                    }
                                    <EmptyTableLayout results={this.state.normalizations} columns={3}/>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </>
    )
  }

  render () {
    return (
            <>
                {/* MODAL */}
                <div className="modal" id="normalizations-modal" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className=" block block-themed block-opt-refresh-icon7 block-transparent remove-margin-b">
                                <div className="block-header bg-primary-dark">
                                    <ul className="block-options">
                                        <li>
                                            <button data-dismiss="modal" type="button"><i className="si si-close"></i>
                                            </button>
                                        </li>
                                    </ul>
                                    <h3 className="block-title">Normalizations for
                                        Column: {this.props.column.column_name}</h3>
                                </div>
                                <div className="block-content">
                                    {this.modalContent()}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-sm btn-default" type="button" data-dismiss="modal">
                                    Close
                                </button>
                                <button className="btn btn-sm btn-primary" type="button" data-dismiss="modal"
                                        onClick={(e) => {
                                          e.preventDefault()
                                          this.saveNormalizations()
                                        }}>
                                    <i className="fa fa-check"></i> Ok
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </>
    )
  }
}

SourceColumnNormalizationModalForm.defaultProps = {
  column: {},
  onChange: () => {}
}

export default SourceColumnNormalizationModalForm
