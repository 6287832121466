import { combineReducers } from 'redux'
import * as types from './types'

const fetchAccountingSeatSettingsInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { results: [], pagination_data: {}, status_groups: [] }
}

const getAccountingSeatSettingInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { groups: [] }
}

const saveAccountingSeatSettingInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

const updateAccountingSeatSettingInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

export function fetchAccountingSeatSettingsReducer (state = fetchAccountingSeatSettingsInitialState, action) {
  switch (action.type) {
    case types.FETCH_ACCOUNTING_SEAT_SETTINGS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.FETCH_ACCOUNTING_SEAT_SETTINGS_REQUEST_FAILURE:
      return {
        ...fetchAccountingSeatSettingsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.FETCH_ACCOUNTING_SEAT_SETTINGS_REQUEST_SUCCESS:
      return {
        ...fetchAccountingSeatSettingsInitialState,
        data: action.payload.data
      }
    case types.FETCH_ACCOUNTING_SEAT_SETTINGS_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function getAccountingSeatSettingReducer (state = getAccountingSeatSettingInitialState, action) {
  switch (action.type) {
    case types.GET_ACCOUNTING_SEAT_SETTING_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.GET_ACCOUNTING_SEAT_SETTING_REQUEST_FAILURE:
      return {
        ...getAccountingSeatSettingInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.GET_ACCOUNTING_SEAT_SETTING_REQUEST_SUCCESS:
      return {
        ...getAccountingSeatSettingInitialState,
        data: action.payload.data
      }
    case types.GET_ACCOUNTING_SEAT_SETTING_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function saveAccountingSeatSettingReducer (state = saveAccountingSeatSettingInitialState, action) {
  switch (action.type) {
    case types.SAVE_ACCOUNTING_SEAT_SETTING_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.SAVE_ACCOUNTING_SEAT_SETTING_REQUEST_FAILURE:
      return {
        ...fetchAccountingSeatSettingsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.SAVE_ACCOUNTING_SEAT_SETTING_REQUEST_SUCCESS:
      return {
        ...fetchAccountingSeatSettingsInitialState,
        data: action.payload.data
      }
    case types.SAVE_ACCOUNTING_SEAT_SETTING_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function updateAccountingSeatSettingReducer (state = updateAccountingSeatSettingInitialState, action) {
  switch (action.type) {
    case types.UPDATE_ACCOUNTING_SEAT_SETTING_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.UPDATE_ACCOUNTING_SEAT_SETTING_REQUEST_FAILURE:
      return {
        ...updateAccountingSeatSettingInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.UPDATE_ACCOUNTING_SEAT_SETTING_REQUEST_SUCCESS:
      return {
        ...updateAccountingSeatSettingInitialState,
        data: action.payload.data
      }
    case types.UPDATE_ACCOUNTING_SEAT_SETTING_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export default combineReducers({
  fetchAccountingSeatSettingsReducer,
  saveAccountingSeatSettingReducer,
  getAccountingSeatSettingReducer,
  updateAccountingSeatSettingReducer
})
