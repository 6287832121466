// SOURCE_EXECUTION_LOGS
export const FETCH_SOURCE_EXECUTION_LOGS_REQUEST = 'session/FETCH_SOURCE_EXECUTION_LOGS_REQUEST'
export const FETCH_SOURCE_EXECUTION_LOGS_REQUEST_SUCCESS = 'session/FETCH_SOURCE_EXECUTION_LOGS_REQUEST_SUCCESS'
export const FETCH_SOURCE_EXECUTION_LOGS_REQUEST_FAILURE = 'session/FETCH_SOURCE_EXECUTION_LOGS_REQUEST_FAILURE'
export const FETCH_SOURCE_EXECUTION_LOGS_REQUEST_COMPLETED = 'session/FETCH_SOURCE_EXECUTION_LOGS_REQUEST_COMPLETED'

export const GET_SOURCE_EXECUTION_LOG_REQUEST = 'session/GET_SOURCE_EXECUTION_LOG_REQUEST'
export const GET_SOURCE_EXECUTION_LOG_REQUEST_SUCCESS = 'session/GET_SOURCE_EXECUTION_LOG_REQUEST_SUCCESS'
export const GET_SOURCE_EXECUTION_LOG_REQUEST_FAILURE = 'session/GET_SOURCE_EXECUTION_LOG_REQUEST_FAILURE'
export const GET_SOURCE_EXECUTION_LOG_REQUEST_COMPLETED = 'session/GET_SOURCE_EXECUTION_LOG_REQUEST_COMPLETED'

export const SAVE_SOURCE_EXECUTION_LOG_REQUEST = 'session/SAVE_SOURCE_EXECUTION_LOG_REQUEST'
export const SAVE_SOURCE_EXECUTION_LOG_REQUEST_SUCCESS = 'session/SAVE_SOURCE_EXECUTION_LOG_REQUEST_SUCCESS'
export const SAVE_SOURCE_EXECUTION_LOG_REQUEST_FAILURE = 'session/SAVE_SOURCE_EXECUTION_LOG_REQUEST_FAILURE'
export const SAVE_SOURCE_EXECUTION_LOG_REQUEST_COMPLETED = 'session/SAVE_SOURCE_EXECUTION_LOG_REQUEST_COMPLETED'

export const UPDATE_SOURCE_EXECUTION_LOG_REQUEST = 'session/UPDATE_SOURCE_EXECUTION_LOG_REQUEST'
export const UPDATE_SOURCE_EXECUTION_LOG_REQUEST_SUCCESS = 'session/UPDATE_SOURCE_EXECUTION_LOG_REQUEST_SUCCESS'
export const UPDATE_SOURCE_EXECUTION_LOG_REQUEST_FAILURE = 'session/UPDATE_SOURCE_EXECUTION_LOG_REQUEST_FAILURE'
export const UPDATE_SOURCE_EXECUTION_LOG_REQUEST_COMPLETED = 'session/UPDATE_SOURCE_EXECUTION_LOG_REQUEST_COMPLETED'
