import React, { Component } from 'react'
import SourceUniquenessModal from '../source-uniqueness-modal'

class SourceUniquenessTable extends Component {
  constructor (props) {
    super(props)

    // state
    this.state = {
      action: 'add',
      uniqueness_to_edit: {}
    }

    this.renderUniqueness = this.renderUniqueness.bind(this)
    this.addUniqueness = this.addUniqueness.bind(this)
    this.editUniqueness = this.editUniqueness.bind(this)
    this.removeUniqueness = this.removeUniqueness.bind(this)
    this.onChangeUniqueness = this.onChangeUniqueness.bind(this)
  }

  addUniqueness () {
    this.setState(
      {
        action: 'add',
        uniqueness_to_edit: {}
      },
      () => {
        window.jQuery('#uniqueness-modal-form').modal('show')
      }
    )
  }

  editUniqueness (uniqueness) {
    this.setState(
      {
        action: 'edit',
        uniqueness_to_edit: uniqueness
      },
      () => {
        window.jQuery('#uniqueness-modal-form').modal('show')
      }
    )
  }

  removeUniqueness (index) {
    const source = JSON.parse(JSON.stringify(this.props.source))
    source.uniqueness.splice(index, 1)
    this.props.onChange('uniqueness', source.uniqueness)
  }

  onChangeUniqueness (action, uniqueness) {
    const source = JSON.parse(JSON.stringify(this.props.source))
    source.uniqueness = source.uniqueness ? source.uniqueness : []
    if (action === 'add') {
      source.uniqueness.push(uniqueness)
    } else {
      source.uniqueness.forEach((uni) => {
        if (uni.id === uniqueness.id) {
          uni = uniqueness
        }
      })
    }
    this.props.onChange('uniqueness', source.uniqueness)
  }

  renderUniqueness () {
    if (this.props.source.uniqueness) {
      return this.props.source.uniqueness.map((uniqueness, index) => {
        return (
          <tr key={index}>
            <td>{uniqueness.id}</td>
            <td>{uniqueness.name}</td>
            <td>
              {uniqueness.partition_keys ? uniqueness.partition_keys.length : 0}
            </td>
            <td>{uniqueness.order_keys ? uniqueness.order_keys.length : 0}</td>
            <td>
              <button
                className="btn btn-xs btn-default"
                type="button"
                data-toggle="tooltip"
                title="Edit Uniqueness"
                onClick={this.editUniqueness.bind(this, uniqueness)}
              >
                <i className="fa fa-pen"></i>
              </button>
              <button
                className="btn btn-xs btn-default"
                type="button"
                data-toggle="tooltip"
                title="Delete Uniqueness"
                onClick={this.removeUniqueness.bind(this, index)}
              >
                <i className="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        )
      })
    }
    return null
  }

  render () {
    return (
      <>
        <div className="col-sm-8 col-sm-offset-2">
          <table className="table table-striped table-borderless text-center">
            <thead>
              <tr>
                <th className="text-center">ID</th>
                <th className="text-center">Name</th>
                <th className="text-center">Partition Keys</th>
                <th className="text-center">Order Keys</th>
                <th className="text-center">Options</th>
              </tr>
            </thead>
            <tbody>{this.renderUniqueness()}</tbody>
          </table>
          <h6
            className="text-primary cursor-pointer mt-2"
            onClick={this.addUniqueness}
          >
            + Add Uniqueness
          </h6>
        </div>
        <SourceUniquenessModal
          action={this.state.action}
          source_id={this.props.source.id}
          uniqueness={this.state.uniqueness_to_edit}
          onChange={this.onChangeUniqueness}
        />
      </>
    )
  }
}

// Set defaultProps
SourceUniquenessTable.defaultProps = {
  source: { uniqueness: [] },
  onChange: () => {}
}

export { SourceUniquenessTable }
