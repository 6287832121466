import reducer from './reducers'

import * as permissionOperations from './operations'
import * as permissionEndpoints from './paths'

export {
  permissionOperations,
  permissionEndpoints
}

export default reducer
