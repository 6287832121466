// CONCILIATIONS
export const FETCH_CONCILIATIONS_REQUEST = 'session/FETCH_CONCILIATIONS_REQUEST'
export const FETCH_CONCILIATIONS_REQUEST_SUCCESS = 'session/FETCH_CONCILIATIONS_REQUEST_SUCCESS'
export const FETCH_CONCILIATIONS_REQUEST_FAILURE = 'session/FETCH_CONCILIATIONS_REQUEST_FAILURE'
export const FETCH_CONCILIATIONS_REQUEST_COMPLETED = 'session/FETCH_CONCILIATIONS_REQUEST_COMPLETED'

export const GET_CONCILIATION_REQUEST = 'session/GET_CONCILIATION_REQUEST'
export const GET_CONCILIATION_REQUEST_SUCCESS = 'session/GET_CONCILIATION_REQUEST_SUCCESS'
export const GET_CONCILIATION_REQUEST_FAILURE = 'session/GET_CONCILIATION_REQUEST_FAILURE'
export const GET_CONCILIATION_REQUEST_COMPLETED = 'session/GET_CONCILIATION_REQUEST_COMPLETED'

export const SAVE_CONCILIATION_REQUEST = 'session/SAVE_CONCILIATION_REQUEST'
export const SAVE_CONCILIATION_REQUEST_SUCCESS = 'session/SAVE_CONCILIATION_REQUEST_SUCCESS'
export const SAVE_CONCILIATION_REQUEST_FAILURE = 'session/SAVE_CONCILIATION_REQUEST_FAILURE'
export const SAVE_CONCILIATION_REQUEST_COMPLETED = 'session/SAVE_CONCILIATION_REQUEST_COMPLETED'

export const UPDATE_CONCILIATION_REQUEST = 'session/UPDATE_CONCILIATION_REQUEST'
export const UPDATE_CONCILIATION_REQUEST_SUCCESS = 'session/UPDATE_CONCILIATION_REQUEST_SUCCESS'
export const UPDATE_CONCILIATION_REQUEST_FAILURE = 'session/UPDATE_CONCILIATION_REQUEST_FAILURE'
export const UPDATE_CONCILIATION_REQUEST_COMPLETED = 'session/UPDATE_CONCILIATION_REQUEST_COMPLETED'

export const DELETE_CONCILIATION_REQUEST = 'session/DELETE_CONCILIATION_REQUEST'
export const DELETE_CONCILIATION_REQUEST_SUCCESS = 'session/DELETE_CONCILIATION_REQUEST_SUCCESS'
export const DELETE_CONCILIATION_REQUEST_FAILURE = 'session/DELETE_CONCILIATION_REQUEST_FAILURE'
export const DELETE_CONCILIATION_REQUEST_COMPLETED = 'session/DELETE_CONCILIATION_REQUEST_COMPLETED'

export const CONCILIATION_TABLE_GENERATED_REQUEST = 'session/CONCILIATION_TABLE_GENERATED_REQUEST'
export const CONCILIATION_TABLE_GENERATED_REQUEST_SUCCESS = 'session/CONCILIATION_TABLE_GENERATED_REQUEST_SUCCESS'
export const CONCILIATION_TABLE_GENERATED_REQUEST_FAILURE = 'session/CONCILIATION_TABLE_GENERATED_REQUEST_FAILURE'
export const CONCILIATION_TABLE_GENERATED_REQUEST_COMPLETED = 'session/CONCILIATION_TABLE_GENERATED_REQUEST_COMPLETED'

export const ALLOCATIONS_A_TABLE_GENERATED_REQUEST = 'session/ALLOCATIONS_A_TABLE_GENERATED_REQUEST'
export const ALLOCATIONS_A_TABLE_GENERATED_REQUEST_SUCCESS = 'session/ALLOCATIONS_A_TABLE_GENERATED_REQUEST_SUCCESS'
export const ALLOCATIONS_A_TABLE_GENERATED_REQUEST_FAILURE = 'session/ALLOCATIONS_A_TABLE_GENERATED_REQUEST_FAILURE'
export const ALLOCATIONS_A_TABLE_GENERATED_REQUEST_COMPLETED = 'session/ALLOCATIONS_A_TABLE_GENERATED_REQUEST_COMPLETED'

export const ALLOCATIONS_B_TABLE_GENERATED_REQUEST = 'session/ALLOCATIONS_B_TABLE_GENERATED_REQUEST'
export const ALLOCATIONS_B_TABLE_GENERATED_REQUEST_SUCCESS = 'session/ALLOCATIONS_B_TABLE_GENERATED_REQUEST_SUCCESS'
export const ALLOCATIONS_B_TABLE_GENERATED_REQUEST_FAILURE = 'session/ALLOCATIONS_B_TABLE_GENERATED_REQUEST_FAILURE'
export const ALLOCATIONS_B_TABLE_GENERATED_REQUEST_COMPLETED = 'session/ALLOCATIONS_B_TABLE_GENERATED_REQUEST_COMPLETED'

export const MISSINGS_A_TABLE_GENERATED_REQUEST = 'session/MISSINGS_A_TABLE_GENERATED_REQUEST'
export const MISSINGS_A_TABLE_GENERATED_REQUEST_SUCCESS = 'session/MISSINGS_A_TABLE_GENERATED_REQUEST_SUCCESS'
export const MISSINGS_A_TABLE_GENERATED_REQUEST_FAILURE = 'session/MISSINGS_A_TABLE_GENERATED_REQUEST_FAILURE'
export const MISSINGS_A_TABLE_GENERATED_REQUEST_COMPLETED = 'session/MISSINGS_A_TABLE_GENERATED_REQUEST_COMPLETED'

export const MISSINGS_B_TABLE_GENERATED_REQUEST = 'session/MISSINGS_B_TABLE_GENERATED_REQUEST'
export const MISSINGS_B_TABLE_GENERATED_REQUEST_SUCCESS = 'session/MISSINGS_B_TABLE_GENERATED_REQUEST_SUCCESS'
export const MISSINGS_B_TABLE_GENERATED_REQUEST_FAILURE = 'session/MISSINGS_B_TABLE_GENERATED_REQUEST_FAILURE'
export const MISSINGS_B_TABLE_GENERATED_REQUEST_COMPLETED = 'session/MISSINGS_B_TABLE_GENERATED_REQUEST_COMPLETED'
