import React, { Component } from 'react'
import { PageContainerLayout, PageHeaderLayout } from '../../../layouts'
import { NavLink } from 'react-router-dom'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { GroupShape } from '../../../../prop-types'
import * as groupOperations from '../../../../state/ducks/groups/actions'
import connect from 'react-redux/es/connect/connect'
import * as PropTypes from 'prop-types'
import { GroupDetailsCard, GroupTimelineCard } from '../../../shared/containers'

class GroupDetailsPage extends Component {
  constructor (props) {
    super(props)
  }

  UNSAFE_componentWillMount () {
    this.props.getGroup(this.props.match.params.group_id)
  }

  renderHeaderPaths () {
    return (
            <>
                <li>
                    <NavLink className="link-effect" to={ROUTING_PATHS.GROUPS_PATH}>
                        Groups
                    </NavLink>
                </li>
                <li>Group Details</li>
            </>
    )
  }

  render () {
    return (
            <>

                <PageContainerLayout clientSidebar={true} showNavbarClientOptions={true}>
                    <PageHeaderLayout
                        title={this.props.group.name}
                        subTitle={'Group Details'}
                        paths={this.renderHeaderPaths}
                    />
                    <div className="content content-boxed">
                        <div className="row">
                            <div className="col-sm-12 col-lg-5">
                                {/* Details */}
                                <GroupDetailsCard group={this.props.group}/>
                                {/* END Details */}
                            </div>
                            <div className="col-sm-12 col-lg-7">
                                {/* Timeline */}
                                <GroupTimelineCard group={this.props.group}/>
                                {/* END Timeline */}
                            </div>
                        </div>
                    </div>
                </PageContainerLayout>

            </>
    )
  }
}

// Set propTypes
GroupDetailsPage.propTypes = {
  group: GroupShape,
  getGroupErrorResponse: PropTypes.object
}

// Set defaultProps
GroupDetailsPage.defaultProps = {
  group: { permissions: [], users: [] },
  getGroupErrorResponse: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  group: state.groups.getGroupReducer.data,
  isLoading: state.groups.getGroupReducer.loading,
  getGroupErrorResponse: state.groups.getGroupReducer.error_data
})

// mapStateToProps
const mapDispatchToProps = {
  getGroup: groupOperations.get
}

// Export Class
const _GroupDetailsPage = connect(mapStateToProps, mapDispatchToProps)(GroupDetailsPage)
export { _GroupDetailsPage as GroupDetailsPage }
