import React, { Component } from 'react'
import config from '../../../../config'

class JobsPoolsDataForm extends Component {
  constructor (props) {
    super(props)

    // state
    this.state = {
      hasError: false,
      featureMode: ['MELI_TEST', 'MELI_PROD'].includes(config.featureMode)
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleStopIfFails = this.handleStopIfFails.bind(this)
  }

  handleChange (event) {
    this.props.onChange(event.target.name, event.target.value.replace(/[^a-zA-Z0-9_]/gi, ''))
  }

  handleStopIfFails () {
    this.props.onChange('stop_if_failed', !this.props.job_pool.stop_if_failed)
  }

  render () {
    return (
            <>
                <div className="form-group">
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <div className="form-material">
                            <input className="form-control"
                                   type="text"
                                   id="input-name"
                                   placeholder="Please enter a name"
                                   name="name"
                                   value={this.props.job_pool.name}
                                   onChange={this.handleChange}
                            />
                            {this.state.hasError && <span>Error</span>}
                            <label htmlFor="input-name">Name</label>
                        </div>
                    </div>
                    {this.state.featureMode && (
                        <div
                        className="col-sm-8 col-sm-offset-2"
                        style={{ minHeight: '70px' }}
                        >
                        <br />
                        <div className="form-material">
                            <label className="css-input switch switch-success">
                            <input
                                type="checkbox"
                                checked={this.props.job_pool.stop_if_failed}
                                onChange={this.handleStopIfFails}
                            />
                            <span /> Stop If Fails
                            </label>
                        </div>
                        </div>
                    )}
                </div>
            </>
    )
  }
}

// Set default props
JobsPoolsDataForm.defaultProps = {
  job_pool: {},
  onChange: () => {
  }
}

export {
  JobsPoolsDataForm
}
