import { combineReducers } from 'redux'
import * as types from './types'

const fetchAlarmsInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { results: [], pagination_data: {} }
}

const getAlarmInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { groups: [] }
}

const saveAlarmInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

const updateAlarmInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

const removeSourceInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

export function fetchAlarmsReducer (state = fetchAlarmsInitialState, action) {
  switch (action.type) {
    case types.FETCH_ALARMS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.FETCH_ALARMS_REQUEST_FAILURE:
      return {
        ...fetchAlarmsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.FETCH_ALARMS_REQUEST_SUCCESS:
      return {
        ...fetchAlarmsInitialState,
        data: action.payload.data
      }
    case types.FETCH_ALARMS_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function getAlarmReducer (state = getAlarmInitialState, action) {
  switch (action.type) {
    case types.GET_ALARM_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.GET_ALARM_REQUEST_FAILURE:
      return {
        ...getAlarmInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.GET_ALARM_REQUEST_SUCCESS:
      return {
        ...getAlarmInitialState,
        data: action.payload.data
      }
    case types.GET_ALARM_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function saveAlarmReducer (state = saveAlarmInitialState, action) {
  switch (action.type) {
    case types.SAVE_ALARM_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.SAVE_ALARM_REQUEST_FAILURE:
      return {
        ...fetchAlarmsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.SAVE_ALARM_REQUEST_SUCCESS:
      return {
        ...fetchAlarmsInitialState,
        data: action.payload.data
      }
    case types.SAVE_ALARM_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function updateAlarmReducer (state = updateAlarmInitialState, action) {
  switch (action.type) {
    case types.UPDATE_ALARM_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.UPDATE_ALARM_REQUEST_FAILURE:
      return {
        ...updateAlarmInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.UPDATE_ALARM_REQUEST_SUCCESS:
      return {
        ...updateAlarmInitialState,
        data: action.payload.data
      }
    case types.UPDATE_ALARM_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function removeAlarmReducer (state = removeSourceInitialState, action) {
  switch (action.type) {
    case types.DELETE_ALARM_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.DELETE_ALARM_REQUEST_FAILURE:
      return {
        ...removeSourceInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.DELETE_ALARM_REQUEST_SUCCESS:
      return {
        ...removeSourceInitialState,
        data: action.payload.data
      }
    case types.DELETE_ALARM_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export default combineReducers({
  fetchAlarmsReducer,
  saveAlarmReducer,
  getAlarmReducer,
  updateAlarmReducer,
  removeAlarmReducer
})
