import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import SelectSearchLayout from '../../../layouts/select-search'
import { sourceOperations } from '../../../../state/ducks/sources'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import { Session } from '../../../../state/utils/session'

class AutomationSourcesForm extends Component {
  constructor (props) {
    super(props)

    // set state
    this.state = {
      session: new Session()
    }

    // handlers
    this.handleCheckbox = this.handleCheckbox.bind(this)
    this.returnOptions = this.returnOptions.bind(this)
    this.onSourceSarchKeywordChange = this.onSourceSarchKeywordChange.bind(this)
    this.onSelectSource = this.onSelectSource.bind(this)
    this.removeSource = this.removeSource.bind(this)
  }

  handleCheckbox (event) {
    if (!this.props.automation.for_all_sources === true) {
      this.props.onChange('sources', [])
    }
    setTimeout(() => {
      this.props.onChange('for_all_sources', !this.props.automation.for_all_sources)
    }, 100)
  }

  returnOptions () {
    const options = []
    this.props.sources_list.forEach(element => {
      const groupAdded = this.props.automation.sources.find(p => p.toString() === element.id.toString())
      if (!groupAdded) {
        options.push({
          value: element.id + '|' + element.name,
          label: element.name + '(' + element.id + ')'
        })
      }
    })
    return options
  }

  onSourceSarchKeywordChange (keyword) {
    this.returnOptions()
    this.props.fetchSources({ search: keyword })
  }

  onSelectSource (item) {
    const addedSources = this.props.automation.sources
    const split_ = item.value.split('|')
    addedSources.push({
      id: split_[0],
      name: split_[1]
    })
    this.props.onChange('sources', addedSources)
  }

  removeSource (index) {
    const addedSources = this.props.automation.sources
    addedSources.splice(index, 1)
    this.props.onChange('sources', addedSources)
  }

  renderSources () {
    return (
            <>
                <table className="table">
                    <tbody>
                    {
                        this.props.automation.sources.map((source, index) => {
                          return (
                                <tr key={index} className="border">
                                    <td style={{ width: '20px' }}>
                                        <i className="fa fa-th fa-2x"></i>
                                    </td>
                                    <td>
                                        <small className="font-w400">{source.name} ({source.id})</small>
                                        <div className="font-w400 text-muted">
                                            <small>---</small>
                                        </div>
                                    </td>
                                    <td style={{ width: '20px' }}>
                                        <Button
                                            variant="danger"
                                            className="float-right"
                                            onClick={this.removeSource.bind(this, index)}
                                        >
                                            <i className="fa fa-trash"/>
                                        </Button>
                                    </td>
                                </tr>
                          )
                        })
                    }
                    </tbody>
                </table>
                {/* <pre> */}
                {/*    <code className="json"> */}
                {/*        {JSON.stringify(this.props.automation.sources)} */}
                {/*    </code> */}
                {/* </pre> */}
            </>
    )
  }

  render () {
    return (
            <>
                <div className="form-group">
                    <div className="col-sm-8 col-sm-offset-2 text-center" style={{ minHeight: '70px' }}>
                        <div className="">
                            <label className="css-input switch switch-success">
                                <input
                                    type="checkbox"
                                    value={this.props.automation.for_all_sources}
                                    checked={this.props.automation.for_all_sources}
                                    onChange={this.handleCheckbox}
                                />
                                <span></span> Add all sources
                            </label>
                        </div>
                    </div>
                    {
                        !this.props.automation.for_all_sources
                          ? <>
                                <div className="form-group">
                                    <div className="col-sm-6 col-sm-offset-3">
                                        <div className="form-material">
                                            <SelectSearchLayout
                                                getOptions={this.returnOptions}
                                                onSearchChange={this.onSourceSarchKeywordChange}
                                                onSelectItem={this.onSelectSource}
                                            />
                                            <label htmlFor="input-groups">Search sources</label>
                                        </div>
                                        {this.renderSources()}
                                    </div>
                                </div>
                            </>
                          : null
                    }
                </div>
            </>
    )
  }
}

// Set propTypes
AutomationSourcesForm.propTypes = {
  sources_list: PropTypes.array.isRequired,
  fetchSources: PropTypes.func.isRequired
}

// Set defaultProps
AutomationSourcesForm.defaultProps = {
  sources_list: [],
  automation: [],
  onChange: () => {
  }
}

// mapStateToProps
const mapStateToProps = (state) => ({
  sources_list: state.sources.fetchSourcesReducer.data.results
})

// mapStateToProps
const mapDispatchToProps = {
  fetchSources: sourceOperations.list
}

// Export Class
const _AutomationSourcesForm = connect(mapStateToProps,
  mapDispatchToProps)(AutomationSourcesForm)
export { _AutomationSourcesForm as AutomationSourcesForm }
