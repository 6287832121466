import { combineReducers } from 'redux'
import * as types from './types'

const fetchJobsInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { results: [], pagination_data: {} }
}

const getJobInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { groups: [] }
}

const fetchJobHistoricalInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { results: [], pagination_data: {} }
}

export function fetchJobsReducer (state = fetchJobsInitialState, action) {
  switch (action.type) {
    case types.FETCH_JOBS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.FETCH_JOBS_REQUEST_FAILURE:
      return {
        ...fetchJobsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.FETCH_JOBS_REQUEST_SUCCESS:
      return {
        ...fetchJobsInitialState,
        data: action.payload.data
      }
    case types.FETCH_JOBS_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function getJobReducer (state = getJobInitialState, action) {
  switch (action.type) {
    case types.GET_JOB_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.GET_JOB_REQUEST_FAILURE:
      return {
        ...getJobInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.GET_JOB_REQUEST_SUCCESS:
      return {
        ...getJobInitialState,
        data: action.payload.data
      }
    case types.GET_JOB_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function fetchJobHistoricalReducer (state = fetchJobHistoricalInitialState, action) {
  switch (action.type) {
    case types.FETCH_JOBS_HISTORICAL_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.FETCH_JOBS_HISTORICAL_REQUEST_FAILURE:
      return {
        ...fetchJobHistoricalInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.FETCH_JOBS_HISTORICAL_REQUEST_SUCCESS:
      return {
        ...fetchJobHistoricalInitialState,
        data: action.payload.data
      }
    case types.FETCH_JOBS_HISTORICAL_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export default combineReducers({
  fetchJobsReducer,
  getJobReducer,
  fetchJobHistoricalReducer
})
