import React, { Component } from 'react'
import { PageContainerLayout, PageHeaderLayout } from '../../../layouts'
import { NavLink } from 'react-router-dom'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { DashboardShape } from '../../../../prop-types'
import * as PropTypes from 'prop-types'
import { dashboardOperations } from '../../../../state/ducks/dashboards'
import { connect } from 'react-redux'
import { Session } from '../../../../state/utils/session'
import { DashboardDetailsCard, DashboardEmbedCard } from '../../containers'

class DashboardDetailsPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }

    this.renderHeaderPaths = this.renderHeaderPaths.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.props.getDashboard(this.props.match.params.dashboard_id)
  }

  renderHeaderPaths () {
    return (
            <>
                <li>
                    <NavLink className="link-effect"
                             to={ROUTING_PATHS.CLIENT_DASHBOARD_DASHBOARDS_PATH.replace(':client_id', this.state.session.getClientSession())}
                    >
                        Dashboards
                    </NavLink>
                </li>
                <li>Dashboard Details</li>
            </>
    )
  }

  render () {
    return (
            <>
                <PageContainerLayout clientSidebar={true} showNavbarClientOptions={true}>
                    <PageHeaderLayout
                        title={this.props.dashboard.name}
                        subTitle={'Dashboard Details'}
                        paths={this.renderHeaderPaths}
                    />
                    <div className="content content-boxed">
                        <div className="row">
                            <div className="col-sm-12 col-lg-5">
                                {/* Details */}
                                <DashboardDetailsCard dashboard={this.props.dashboard}/>
                                {/* END Details */}
                            </div>
                            <div className="col-sm-12 col-lg-7">
                                {/* Timeline */}
                                {/* <DashboardTimelineCard dashboard={this.props.dashboard}/> */}
                                {/* END Timeline */}
                            </div>
                            <div className="col-sm-12">
                                {/* Embed Card */}
                                {
                                    this.props.isCompleted
                                      ? <DashboardEmbedCard url={this.props.dashboard.url}/>
                                      : null
                                }
                                {/* END Embed Card */}
                            </div>
                        </div>
                    </div>
                </PageContainerLayout>
            </>
    )
  }
}

// Set propTypes
DashboardDetailsPage.propTypes = {
  dashboard: DashboardShape,
  getDashboardErrorResponse: PropTypes.object
}

// Set defaultProps
DashboardDetailsPage.defaultProps = {
  dashboard: {},
  getDashboardErrorResponse: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  dashboard: state.dashboards.getDashboardReducer.data,
  isLoading: state.dashboards.getDashboardReducer.loading,
  isCompleted: state.dashboards.getDashboardReducer.completed,
  getDashboardErrorResponse: state.dashboards.getDashboardReducer.error_data
})

// mapStateToProps
const mapDispatchToProps = {
  getDashboard: dashboardOperations.get
}

// Export Class
const _DashboardDetailsPage = connect(mapStateToProps, mapDispatchToProps)(DashboardDetailsPage)
export { _DashboardDetailsPage as DashboardDetailsPage }
