import * as types from './types'

const fetchDynamicParametriesInitialState = {
  loading: false,
  failed: false,
  error_data: { error: '' },
  data: { results: [] }
}

const inconsistenciesInitialState = {
  loading: false,
  failed: false,
  error_data: { error: '' },
  data: {}
}

function parametries (state = fetchDynamicParametriesInitialState, action) {
  switch (action.type) {
    case types.FETCH_DYNAMIC_PARAMETRIES_REQUEST:
      return {
        ...state,
        loading: true
      }

    case types.FETCH_DYNAMIC_PARAMETRIES_REQUEST_FAILURE:
      return {
        ...fetchDynamicParametriesInitialState,
        error_data: action.payload.data,
        failed: true
      }

    case types.FETCH_DYNAMIC_PARAMETRIES_REQUEST_SUCCESS:
      return {
        ...fetchDynamicParametriesInitialState,
        data: action.payload.data
      }

    default:
      return state
  }
}

function inconsistencies (state = inconsistenciesInitialState, action) {
  switch (action.type) {
    case types.FETCH_INCONSISTENCIES_REQUEST:
      return {
        ...state,
        loading: true
      }

    case types.FETCH_INCONSISTENCIES_REQUEST_FAILURE:
      return {
        ...fetchDynamicParametriesInitialState,
        error_data: action.payload.data,
        failed: true
      }

    case types.FETCH_INCONSISTENCIES_REQUEST_SUCCESS:
      return {
        ...fetchDynamicParametriesInitialState,
        data: action.payload.data
      }

    default:
      return state
  }
}

export { parametries, inconsistencies }
