import React, { Component } from 'react'
import { ResetPasswordForm } from '../../containers'
import { withRouter } from 'react-router-dom'
import { AuthenticationPageLayout } from '../../../layouts/authentication-page'
import { Session } from '../../../../state/utils/session'

class ResetPasswordPage extends Component {
  componentDidMount () {
    const session = new Session()
    session.checkSession()
  }

  render () {
    return (
            <>
                <AuthenticationPageLayout>
                    <ResetPasswordForm />
                </AuthenticationPageLayout>
            </>
    )
  };
}

const _ResetPasswordPage = withRouter(ResetPasswordPage)

export { _ResetPasswordPage as ResetPasswordPage }
