import React from 'react'
import { EmptyTableLayout } from '../../../layouts'
import { history } from '../../../../routing/History'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { Session } from '../../../../state/utils/session'
import Swal from 'sweetalert2'
import { workerCallsOperations } from '../../../../state/ducks/worker-calls'
import { connect } from 'react-redux'
import { executionOperations } from '../../../../state/ducks/executions'

function WorkerCallsTable (props) {
  const session = new Session()

  const returnBooleanLabel = (status) => {
    if (status) {
      return (
        <>
          <span className="label label-success">
            <i className="fa fa-check"></i>
          </span>
        </>
      )
    } else {
      return (
        <>
          <span className="label label-danger">
            <i className="fa fa-close"></i>
          </span>
        </>
      )
    }
  }

  const goToWorkerCallEditor = (workerCall) => {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_WORKER_CALLS_EDITOR_PATH.replace(
        ':worker_call_id',
        workerCall.id
      ).replace(':client_id', session.getClientSession())
    )
  }

  const selectWorkerCall = (workerCall) => {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_WORKER_CALLS_DETAILS_PATH.replace(
        ':worker_call_id',
        workerCall.id
      ).replace(':client_id', session.getClientSession())
    )
  }

  const executeWorkerCall = (workerCall) => {
    Swal.fire({
      title: '',
      html: `<b>Por favor confirme la ejecución del worker call ${workerCall.name}?</b>`,
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.dismiss !== 'backdrop') {
        if (result.value) {
          const data = {
            execution_type: 'WORKER_CALL',
            ids: [workerCall.id]
          }
          props.saveExecution(data)
        }
      }
    })
  }

  const deleteWorkerCall = (workerCall) => {
    Swal.fire({
      title: '',
      html: `<b>Por favor confirme la eliminación del worker call ${workerCall.name}?</b>`,
      showCancelButton: true,
      confirmButtonColor: '#d33',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.dismiss !== 'backdrop') {
        if (result.value) {
          props.removeWorkerCall(props.params, workerCall.id)
        }
      }
    })
  }

  return (
    <>
      <div className="table-responsive">
        <table className="table table-striped table-bordered table-condensed text-center">
          <thead>
            <tr>
              <th className="text-center">ID</th>
              <th className="text-center">Name</th>
              <th className="text-center">Path</th>
              <th className="text-center">Automations</th>
              <th className="text-center">Options</th>
            </tr>
          </thead>
          <tbody>
            {props.workerCalls.map((workerCall) => {
              return (
                <tr key={workerCall.id}>
                  <td className="text-center">{workerCall.id}</td>
                  <td className="text-center">{workerCall.name}</td>
                  <td className="text-center">{workerCall.path}</td>
                  <td className="text-center">
                    {returnBooleanLabel(workerCall?.automations?.length)}
                  </td>
                  <td className="text-center">
                    <div className="btn-group">
                      <button
                        className="btn btn-xs btn-default"
                        type="button"
                        data-toggle="tooltip"
                        title="Run worker call"
                        onClick={() => executeWorkerCall(workerCall)}
                      >
                        RUN
                      </button>
                      <button
                        className="btn btn-xs btn-default"
                        type="button"
                        data-toggle="tooltip"
                        title="Edit Worker Call"
                        onClick={() => goToWorkerCallEditor(workerCall)}
                      >
                        <i className="fa fa-pen"></i>
                      </button>
                      <button
                        className="btn btn-xs btn-default"
                        type="button"
                        data-toggle="tooltip"
                        title="Select worker call"
                        onClick={() => selectWorkerCall(workerCall)}
                      >
                        <i className="fa fa-eye"></i>
                      </button>
                      <button
                        className="btn btn-xs btn-default"
                        type="button"
                        data-toggle="tooltip"
                        title="Remove worker call"
                        onClick={() => deleteWorkerCall(workerCall)}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              )
            })}
            <EmptyTableLayout results={props.workerCalls} columns={5} />
          </tbody>
        </table>
        {props.children}
      </div>
    </>
  )
}

WorkerCallsTable.defaultProps = {
  workerCalls: []
}

const mapStateToProps = (state) => ({
  isLoading:
    state.workerCalls.removeWorkerCallReducer.loading ||
    state.executions.saveExecutionReducer.loading
})

const mapDispatchToProps = {
  removeWorkerCall: workerCallsOperations.remove,
  saveExecution: executionOperations.save
}

const _WorkerCallsTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkerCallsTable)
export { _WorkerCallsTable as WorkerCallsTable }
