import { combineReducers } from 'redux'
import * as types from './types'

const fetchExtractionsInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { results: [], pagination_data: {} }
}

const getExtractionInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { groups: [] }
}

const saveExtractionInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

const updateExtractionInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

export function fetchExtractionsReducer (state = fetchExtractionsInitialState, action) {
  switch (action.type) {
    case types.FETCH_EXTRACTIONS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.FETCH_EXTRACTIONS_REQUEST_FAILURE:
      return {
        ...fetchExtractionsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.FETCH_EXTRACTIONS_REQUEST_SUCCESS:
      return {
        ...fetchExtractionsInitialState,
        data: action.payload.data
      }
    case types.FETCH_EXTRACTIONS_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    case types.REMOVE_EXTRACTION_REQUEST_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          results: state.data.results.filter(({ id }) => action.payload !== id)
        }
      }
    default:
      return state
  }
}

export function getExtractionReducer (state = getExtractionInitialState, action) {
  switch (action.type) {
    case types.GET_EXTRACTION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.GET_EXTRACTION_REQUEST_FAILURE:
      return {
        ...getExtractionInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.GET_EXTRACTION_REQUEST_SUCCESS:
      return {
        ...getExtractionInitialState,
        data: action.payload.data
      }
    case types.GET_EXTRACTION_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function saveExtractionReducer (state = saveExtractionInitialState, action) {
  switch (action.type) {
    case types.SAVE_EXTRACTION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.SAVE_EXTRACTION_REQUEST_FAILURE:
      return {
        ...fetchExtractionsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.SAVE_EXTRACTION_REQUEST_SUCCESS:
      return {
        ...fetchExtractionsInitialState,
        data: action.payload.data
      }
    case types.SAVE_EXTRACTION_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function updateExtractionReducer (state = updateExtractionInitialState, action) {
  switch (action.type) {
    case types.UPDATE_EXTRACTION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.UPDATE_EXTRACTION_REQUEST_FAILURE:
      return {
        ...updateExtractionInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.UPDATE_EXTRACTION_REQUEST_SUCCESS:
      return {
        ...updateExtractionInitialState,
        data: action.payload.data
      }
    case types.UPDATE_EXTRACTION_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export default combineReducers({
  fetchExtractionsReducer,
  saveExtractionReducer,
  getExtractionReducer,
  updateExtractionReducer
})
