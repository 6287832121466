import { combineReducers } from 'redux'
import * as types from './types'

const fetchAutomationsInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { results: [], pagination_data: {} }
}

const getAutomationInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { groups: [] }
}

const saveAutomationInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

const updateAutomationInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

const removeSourceInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

export function fetchAutomationsReducer (state = fetchAutomationsInitialState, action) {
  switch (action.type) {
    case types.FETCH_AUTOMATIONS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.FETCH_AUTOMATIONS_REQUEST_FAILURE:
      return {
        ...fetchAutomationsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.FETCH_AUTOMATIONS_REQUEST_SUCCESS:
      return {
        ...fetchAutomationsInitialState,
        data: action.payload.data
      }
    case types.FETCH_AUTOMATIONS_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function getAutomationReducer (state = getAutomationInitialState, action) {
  switch (action.type) {
    case types.GET_AUTOMATION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.GET_AUTOMATION_REQUEST_FAILURE:
      return {
        ...getAutomationInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.GET_AUTOMATION_REQUEST_SUCCESS:
      return {
        ...getAutomationInitialState,
        data: action.payload.data
      }
    case types.GET_AUTOMATION_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function saveAutomationReducer (state = saveAutomationInitialState, action) {
  switch (action.type) {
    case types.SAVE_AUTOMATION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.SAVE_AUTOMATION_REQUEST_FAILURE:
      return {
        ...fetchAutomationsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.SAVE_AUTOMATION_REQUEST_SUCCESS:
      return {
        ...fetchAutomationsInitialState,
        data: action.payload.data
      }
    case types.SAVE_AUTOMATION_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function updateAutomationReducer (state = updateAutomationInitialState, action) {
  switch (action.type) {
    case types.UPDATE_AUTOMATION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.UPDATE_AUTOMATION_REQUEST_FAILURE:
      return {
        ...updateAutomationInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.UPDATE_AUTOMATION_REQUEST_SUCCESS:
      return {
        ...updateAutomationInitialState,
        data: action.payload.data
      }
    case types.UPDATE_AUTOMATION_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function removeAutomationReducer (state = removeSourceInitialState, action) {
  switch (action.type) {
    case types.DELETE_AUTOMATION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.DELETE_AUTOMATION_REQUEST_FAILURE:
      return {
        ...removeSourceInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.DELETE_AUTOMATION_REQUEST_SUCCESS:
      return {
        ...removeSourceInitialState,
        data: action.payload.data
      }
    case types.DELETE_AUTOMATION_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export default combineReducers({
  fetchAutomationsReducer,
  saveAutomationReducer,
  getAutomationReducer,
  updateAutomationReducer,
  removeAutomationReducer
})
