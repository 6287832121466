import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import SelectSearchLayout from '../../../layouts/select-search'
import { userOperations } from '../../../../state/ducks/users'
import { connect } from 'react-redux'
import * as PropTypes from 'prop-types'
import { ClientShape } from '../../../../prop-types'
import { Session } from '../../../../state/utils/session'

class ClientUsersForm extends Component {
  constructor (props) {
    super(props)

    // set state
    this.state = {
      session: new Session()
    }

    // handlers
    this.returnOptions = this.returnOptions.bind(this)
    this.onUserearchKeywordChange = this.onUserearchKeywordChange.bind(this)
    this.onSelectUser = this.onSelectUser.bind(this)
    this.removeUser = this.removeUser.bind(this)
  }

  componentDidMount () {
    const res = this.props.added_users.find(p => p.toString() === this.state.session.getSession().user_id.toString())
    if (!res) {
      this.props.onChange([{
        id: this.state.session.getSession().user_id.toString(),
        username: this.state.session.getSession().username,
        full_name: this.state.session.getSession().full_name
      }])
    }
  }

  returnOptions () {
    const options = []
    this.props.users_list.forEach(element => {
      const userAdded = this.props.added_users.find(p => p.toString() === element.id.toString())
      if (!userAdded) {
        options.push({
          value: element.id + '|' + element.username + '|' + element.full_name,
          label: element.username
        })
      }
    })
    return options
  }

  onUserearchKeywordChange (keyword) {
    this.returnOptions()
    this.props.fetchUsers({ search: keyword })
  }

  onSelectUser (item) {
    const addedUsers = this.props.added_users
    const split_ = item.value.split('|')
    addedUsers.push({
      id: split_[0],
      username: split_[1],
      full_name: split_[2]
    })
    this.props.onChange(addedUsers)
  }

  removeUser (index) {
    const addedUsers = this.props.added_users
    addedUsers.splice(index, 1)
    this.props.onChange(addedUsers)
  }

  renderUsers () {
    return (
            <>
                <table className="table">
                    <tbody>
                    {
                        this.props.added_users.map((user, index) => {
                          return (
                                <tr key={index} className="border">
                                    <td style={{ width: '20px' }}>
                                        <img className="img-avatar"
                                             style={{ width: '40px', height: '40px' }}
                                             src="img/avatars/avatar8.jpg"
                                             alt=""
                                        />
                                    </td>
                                    <td>
                                        <small className="font-w400">{user.full_name}</small>
                                        <div className="font-w400 text-muted">
                                            <small>{user.username}</small>
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <Button
                                            variant="danger"
                                            className="float-right"
                                            disabled={this.state.session.getSession().user_id.toString() === user.id.toString()}
                                            onClick={this.removeUser.bind(this, index)}
                                        >
                                            <i className="fa fa-trash"/>
                                        </Button>
                                    </td>
                                </tr>
                          )
                        })
                    }
                    </tbody>
                </table>
                {/* <pre> */}
                {/*    <code className="json"> */}
                {/*        {JSON.stringify(this.props.added_users)} */}
                {/*    </code> */}
                {/* </pre> */}
            </>
    )
  }

  render () {
    return (
            <>
                <div className="form-group">
                    <div className="col-sm-6 col-sm-offset-3">
                        <div className="form-material">
                            <SelectSearchLayout
                                getOptions={this.returnOptions}
                                onSearchChange={this.onUserearchKeywordChange}
                                onSelectItem={this.onSelectUser}
                            />
                            <label htmlFor="input-users">Search users</label>
                        </div>
                        {this.renderUsers()}
                    </div>
                </div>
            </>
    )
  }
}

// Set propTypes
ClientUsersForm.propTypes = {
  users_list: PropTypes.arrayOf(ClientShape).isRequired,
  fetchUsers: PropTypes.func.isRequired
}

// Set defaultProps
ClientUsersForm.defaultProps = {
  users_list: [],
  added_users: [],
  onChange: () => {
  }
}

// mapStateToProps
const mapStateToProps = (state) => ({
  users_list: state.users.fetchUsersReducer.data.results
})

// mapStateToProps
const mapDispatchToProps = {
  fetchUsers: userOperations.list
}

// Export Class
const _ClientUsersForm = connect(mapStateToProps,
  mapDispatchToProps)(ClientUsersForm)
export { _ClientUsersForm as ClientUsersForm }
