import { combineReducers } from 'redux'
import * as types from './types'

const fetchConciliationsInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { results: [], pagination_data: {} }
}

const getConciliationInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { groups: [], parameters: {} }
}

const saveConciliationInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

const updateConciliationInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

const removeConciliationInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

const conciliationTableGeneratedInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { results: [], pagination_data: {} }
}

const allocationsATableGeneratedInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { results: [], pagination_data: {} }
}

const allocationsBTableGeneratedInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { results: [], pagination_data: {} }
}

const missingsATableGeneratedInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { results: [], pagination_data: {} }
}

const missingsBTableGeneratedInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { results: [], pagination_data: {} }
}

export function fetchConciliationsReducer (state = fetchConciliationsInitialState, action) {
  switch (action.type) {
    case types.FETCH_CONCILIATIONS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.FETCH_CONCILIATIONS_REQUEST_FAILURE:
      return {
        ...fetchConciliationsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.FETCH_CONCILIATIONS_REQUEST_SUCCESS:
      return {
        ...fetchConciliationsInitialState,
        data: action.payload.data
      }
    case types.FETCH_CONCILIATIONS_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function getConciliationReducer (state = getConciliationInitialState, action) {
  switch (action.type) {
    case types.GET_CONCILIATION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.GET_CONCILIATION_REQUEST_FAILURE:
      return {
        ...getConciliationInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.GET_CONCILIATION_REQUEST_SUCCESS:
      return {
        ...getConciliationInitialState,
        data: action.payload.data
      }
    case types.GET_CONCILIATION_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function saveConciliationReducer (state = saveConciliationInitialState, action) {
  switch (action.type) {
    case types.SAVE_CONCILIATION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.SAVE_CONCILIATION_REQUEST_FAILURE:
      return {
        ...fetchConciliationsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.SAVE_CONCILIATION_REQUEST_SUCCESS:
      return {
        ...fetchConciliationsInitialState,
        data: action.payload.data
      }
    case types.SAVE_CONCILIATION_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function updateConciliationReducer (state = updateConciliationInitialState, action) {
  switch (action.type) {
    case types.UPDATE_CONCILIATION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.UPDATE_CONCILIATION_REQUEST_FAILURE:
      return {
        ...updateConciliationInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.UPDATE_CONCILIATION_REQUEST_SUCCESS:
      return {
        ...updateConciliationInitialState,
        data: action.payload.data
      }
    case types.UPDATE_CONCILIATION_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function removeConciliationReducer (state = removeConciliationInitialState, action) {
  switch (action.type) {
    case types.DELETE_CONCILIATION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.DELETE_CONCILIATION_REQUEST_FAILURE:
      return {
        ...removeConciliationInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.DELETE_CONCILIATION_REQUEST_SUCCESS:
      return {
        ...removeConciliationInitialState,
        data: action.payload.data
      }
    case types.DELETE_CONCILIATION_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function conciliationTableGeneratedReducer (state = conciliationTableGeneratedInitialState, action) {
  switch (action.type) {
    case types.CONCILIATION_TABLE_GENERATED_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.CONCILIATION_TABLE_GENERATED_REQUEST_FAILURE:
      return {
        ...conciliationTableGeneratedInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.CONCILIATION_TABLE_GENERATED_REQUEST_SUCCESS:
      return {
        ...conciliationTableGeneratedInitialState,
        data: action.payload.data
      }
    case types.CONCILIATION_TABLE_GENERATED_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function allocationsATableGeneratedReducer (state = allocationsATableGeneratedInitialState, action) {
  switch (action.type) {
    case types.ALLOCATIONS_A_TABLE_GENERATED_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.ALLOCATIONS_A_TABLE_GENERATED_REQUEST_FAILURE:
      return {
        ...conciliationTableGeneratedInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.ALLOCATIONS_A_TABLE_GENERATED_REQUEST_SUCCESS:
      return {
        ...conciliationTableGeneratedInitialState,
        data: action.payload.data
      }
    case types.ALLOCATIONS_A_TABLE_GENERATED_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function allocationsBTableGeneratedReducer (state = allocationsBTableGeneratedInitialState, action) {
  switch (action.type) {
    case types.ALLOCATIONS_B_TABLE_GENERATED_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.ALLOCATIONS_B_TABLE_GENERATED_REQUEST_FAILURE:
      return {
        ...conciliationTableGeneratedInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.ALLOCATIONS_B_TABLE_GENERATED_REQUEST_SUCCESS:
      return {
        ...conciliationTableGeneratedInitialState,
        data: action.payload.data
      }
    case types.ALLOCATIONS_B_TABLE_GENERATED_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function missingsATableGeneratedReducer (state = missingsATableGeneratedInitialState, action) {
  switch (action.type) {
    case types.MISSINGS_A_TABLE_GENERATED_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.MISSINGS_A_TABLE_GENERATED_REQUEST_FAILURE:
      return {
        ...conciliationTableGeneratedInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.MISSINGS_A_TABLE_GENERATED_REQUEST_SUCCESS:
      return {
        ...conciliationTableGeneratedInitialState,
        data: action.payload.data
      }
    case types.MISSINGS_A_TABLE_GENERATED_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function missingsBTableGeneratedReducer (state = missingsBTableGeneratedInitialState, action) {
  switch (action.type) {
    case types.MISSINGS_B_TABLE_GENERATED_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.MISSINGS_B_TABLE_GENERATED_REQUEST_FAILURE:
      return {
        ...conciliationTableGeneratedInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.MISSINGS_B_TABLE_GENERATED_REQUEST_SUCCESS:
      return {
        ...conciliationTableGeneratedInitialState,
        data: action.payload.data
      }
    case types.MISSINGS_B_TABLE_GENERATED_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export default combineReducers({
  fetchConciliationsReducer,
  saveConciliationReducer,
  getConciliationReducer,
  updateConciliationReducer,
  removeConciliationReducer,
  conciliationTableGeneratedReducer,
  allocationsATableGeneratedReducer,
  allocationsBTableGeneratedReducer,
  missingsATableGeneratedReducer,
  missingsBTableGeneratedReducer
})
