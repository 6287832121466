import React, { Component } from 'react'
import { PageContainerLayout, PageHeaderLayout } from '../../../layouts'
import { NavLink } from 'react-router-dom'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { UserShape } from '../../../../prop-types'
import * as userOperations from '../../../../state/ducks/users/actions'
import connect from 'react-redux/es/connect/connect'
import * as PropTypes from 'prop-types'
import { UserDetailsCard, UserTimelineCard } from '../../../shared/containers'

class UserDetailsPage extends Component {
  constructor (props) {
    super(props)
  }

  UNSAFE_componentWillMount () {
    this.props.getUser(this.props.match.params.user_id)
  }

  renderHeaderPaths () {
    return (
            <>
                <li>
                    <NavLink className="link-effect" to={ROUTING_PATHS.USERS_PATH}>
                        Users
                    </NavLink>
                </li>
                <li>User Details</li>
            </>
    )
  }

  render () {
    return (
            <>

                <PageContainerLayout>
                    <PageHeaderLayout
                        title={this.props.user.name}
                        subTitle={'User Details'}
                        paths={this.renderHeaderPaths}
                    />
                    <div className="content content-boxed">
                        <div className="row">
                            <div className="col-sm-12 col-lg-5">
                                {/* Details */}
                                <UserDetailsCard
                                    user={this.props.user}
                                    editorPath={ROUTING_PATHS.USER_EDITOR_PATH.replace(':user_id', this.props.user.id)}
                                />
                                {/* END Details */}
                            </div>
                            <div className="col-sm-12 col-lg-7">
                                {/* Timeline */}
                                <UserTimelineCard user={this.props.user}/>
                                {/* END Timeline */}
                            </div>
                        </div>
                    </div>
                </PageContainerLayout>

            </>
    )
  }
}

// Set propTypes
UserDetailsPage.propTypes = {
  user: UserShape,
  getUserErrorResponse: PropTypes.object
}

// Set defaultProps
UserDetailsPage.defaultProps = {
  user: { permissions: [], groups: [] },
  getUserErrorResponse: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  user: state.users.getUserReducer.data,
  isLoading: state.users.getUserReducer.loading,
  getUserErrorResponse: state.users.getUserReducer.error_data
})

// mapStateToProps
const mapDispatchToProps = {
  getUser: userOperations.get
}

// Export Class
const _UserDetailsPage = connect(mapStateToProps, mapDispatchToProps)(UserDetailsPage)
export { _UserDetailsPage as UserDetailsPage }
