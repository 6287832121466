import React, { Component } from 'react'
import { PageContainerLayout, PageHeaderLayout } from '../../../layouts'
import { NavLink } from 'react-router-dom'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { ExtractionWizardForm } from '../../containers'
import { Session } from '../../../../state/utils/session'

class ExtractionCreatorPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }

    this.renderHeaderPaths = this.renderHeaderPaths.bind(this)
  }

  renderHeaderPaths () {
    return (
            <>
                <li>
                    <NavLink className="link-effect"
                             to={ROUTING_PATHS.CLIENT_DASHBOARD_EXTRACTIONS_PATH.replace(':client_id', this.state.session.getClientSession())}>
                        Extractions
                    </NavLink>
                </li>
                <li>New Extraction</li>
            </>
    )
  }

  render () {
    return (
            <>

                <PageContainerLayout clientSidebar={true} showNavbarClientOptions={true}>
                    <PageHeaderLayout
                        title={'New Extraction'}
                        subTitle={'Create a new extraction'}
                        paths={this.renderHeaderPaths}
                    />
                    <ExtractionWizardForm/>
                </PageContainerLayout>
            </>
    )
  }
}

export { ExtractionCreatorPage }
