import * as types from './types'
import apiService from '../../utils/apiService'
import {
  handleApiErrors,
  handleApiResponseFailure,
  handleApiResponseSuccess
} from '../../utils'
import * as API_PATHS from './paths'
import * as ROUTING_PATHS from '../../../routing/Paths'
import { history } from '../../../routing/History'
import { Session } from '../../utils/session'

const session = new Session()

export const get = (objectId) => {
  return (dispatch) => {
    const TYPE = types.GET_RELEASE_REQUEST
    const FINAL_PATH = API_PATHS.BASE_PATH + objectId + '/' // object_id
    dispatch({ type: TYPE, payload: {} })
    apiService({
      method: 'GET',
      action: TYPE,
      path: FINAL_PATH,
      async: true,
      params: null,
      body: null
    })
      .then((res) => {
        if ('status' in res.data && res.data.status === 'ERROR') {
          handleApiResponseFailure(dispatch, TYPE, res)
          if (res.data.error === 'No Client matches the given query.') {
            history.push(ROUTING_PATHS.DEPLOY_TOOL_HOME_INIT_PATH)
          }
        } else {
          handleApiResponseSuccess(dispatch, TYPE, res)
          // Other actions
          dispatch({ type: `${TYPE}_COMPLETED`, payload: res })
        }
      })
      .catch((err) => {
        handleApiErrors(dispatch, TYPE, {
          data: { api_error: err, error: 'Server 500' }
        })
      })
  }
}

export const list = (params) => {
  return (dispatch) => {
    const TYPE = types.FETCH_RELEASES_REQUEST
    const FINAL_PATH = API_PATHS.BASE_PATH
    dispatch({ type: TYPE, payload: {} })
    apiService({
      method: 'GET',
      action: TYPE,
      path: FINAL_PATH,
      async: true,
      params,
      body: null
    })
      .then((res) => {
        if (res.data.status === 'OK') {
          handleApiResponseSuccess(dispatch, TYPE, res)
          // Other actions

          dispatch({ type: `${TYPE}_COMPLETED`, payload: res })
        } else {
          handleApiResponseFailure(dispatch, TYPE, res)
        }
      })
      .catch((err) => {
        handleApiErrors(dispatch, TYPE, {
          data: { api_error: err, error: 'Server 500' }
        })
      })
  }
}

export const save = (body) => {
  return (dispatch) => {
    const TYPE = types.SAVE_RELEASE_REQUEST
    const FINAL_PATH = API_PATHS.BASE_PATH
    dispatch({ type: TYPE, payload: {} })
    dispatch({ type: types.SAVE_RELEASE_REQUEST_BUTTON_LOADING })
    setTimeout(() => {
      dispatch({ type: types.SAVE_RELEASE_REQUEST_BUTTON_STOP_LOADING })
    }, 4000)
    apiService({
      method: 'POST',
      action: TYPE,
      path: FINAL_PATH,
      async: true,
      params: null,
      body
    })
      .then((res) => {
        if ('status' in res.data && res.data.status === 'ERROR') {
          handleApiResponseFailure(dispatch, TYPE, res)
        } else {
          handleApiResponseSuccess(dispatch, TYPE, res)
          // Other actions
          dispatch({ type: `${TYPE}_COMPLETED`, payload: res })
          history.push(
            ROUTING_PATHS.DEPLOY_TOOL_DETAILS_PATH.replace(
              ':client_id',
              session.getClientSession()
            ).replace(':release_id', res.data.id)
          )
        }
      })
      .catch((err) => {
        handleApiErrors(dispatch, TYPE, {
          data: { api_error: err, error: 'Server 500' }
        })
      })
  }
}

export const update = (objectId, body, redirectPath = null) => {
  return (dispatch) => {
    const TYPE = types.UPDATE_RELEASE_REQUEST
    const FINAL_PATH = API_PATHS.BASE_PATH + objectId + '/' // object_id
    dispatch({ type: TYPE, payload: {} })
    apiService({
      method: 'PUT',
      action: TYPE,
      path: FINAL_PATH,
      async: true,
      params: null,
      body
    })
      .then((res) => {
        if ('status' in res.data && res.data.status === 'ERROR') {
          handleApiResponseFailure(dispatch, TYPE, res)
        } else {
          handleApiResponseSuccess(dispatch, TYPE, res)
          dispatch({ type: `${TYPE}_COMPLETED`, payload: res })
          if (redirectPath) {
            history.push(redirectPath)
          } else {
            history.push(
              ROUTING_PATHS.DEPLOY_TOOL_DETAILS_PATH.replace(
                ':client_id',
                session.getClientSession()
              ).replace(':release_id', res.data.id)
            )
          }
        }
      })
      .catch((err) => {
        handleApiErrors(dispatch, TYPE, {
          data: { api_error: err, error: 'Server 500' }
        })
      })
  }
}

export const fetchDiffcheck = (client) => {
  return (dispatch) => {
    const TYPE = types.FETCH_DIFFCHECK_REQUEST
    const FINAL_PATH =
      API_PATHS.BASE_PATH.replace(':client_session_id', client) + 'scan'
    dispatch({ type: TYPE, payload: {} })
    apiService({
      method: 'GET',
      action: TYPE,
      path: FINAL_PATH,
      async: true,
      params: {},
      body: null
    })
      .then((res) => {
        if (res.data.changes.status !== undefined) {
          handleApiResponseSuccess(dispatch, TYPE, res)
          if (res.data.changes.status === 'running') {
            setTimeout(() => {
              dispatch(fetchDiffcheck(client))
            }, 20000)
          } else {
            // Other actions
            dispatch({ type: `${TYPE}_COMPLETED`, payload: res })
          }
        } else {
          handleApiResponseFailure(dispatch, TYPE, res)
        }
      })
      .catch((err) => {
        setTimeout(() => {
          dispatch(fetchDiffcheck(client))
        }, 20000)
        handleApiErrors(dispatch, TYPE, {
          data: { api_error: err, error: 'Server 500' }
        })
      })
  }
}

export const deployRelease = (client, objectId) => {
  return (dispatch) => {
    const TYPE = types.SEND_DEPLOY_RELEASE_REQUEST
    const FINAL_PATH =
      API_PATHS.BASE_PATH.replace(':client_session_id', client) +
      objectId +
      '/deploy/'
    dispatch({ type: TYPE, payload: {} })
    setTimeout(() => {
      dispatch({ type: types.SEND_DEPLOY_RELEASE_REQUEST_CANCEL_LOADING })
    }, 4000)
    apiService({
      method: 'POST',
      action: TYPE,
      path: FINAL_PATH,
      async: true,
      params: {},
      body: null
    })
      .then((res) => {
        if (res.data.success === true) {
          handleApiResponseSuccess(dispatch, TYPE, res)
          // Other actions
          dispatch({ type: `${TYPE}_COMPLETED`, payload: res })
          window.location.reload()
        } else {
          handleApiResponseFailure(dispatch, TYPE, res)
        }
      })
      .catch((err) => {
        handleApiErrors(dispatch, TYPE, {
          data: { api_error: err, error: 'Server 500' }
        })
      })
  }
}

export const s3FilePreview = (client, path) => {
  return (dispatch) => {
    const TYPE = types.S3_FILES_RELEASES_REQUEST
    const FINAL_PATH =
      API_PATHS.BASE_PATH.replace(':client_session_id', client) + 's3-file-preview/?path=' + path
    dispatch({ type: TYPE, payload: {} })
    apiService({
      method: 'GET',
      action: TYPE,
      path: FINAL_PATH,
      async: true,
      params: {},
      body: null
    })
      .then((res) => {
        if (res.statusText === 'OK') {
          handleApiResponseSuccess(dispatch, TYPE, res)
          // Other actions

          dispatch({ type: `${TYPE}_COMPLETED`, payload: res })
        } else {
          handleApiResponseFailure(dispatch, TYPE, res)
        }
      })
      .catch((err) => {
        handleApiErrors(dispatch, TYPE, {
          data: { api_error: err, error: 'Server 500' }
        })
      })
  }
}

export const s3FilePreviewClear = () => {
  return (dispatch) => {
    const TYPE = types.S3_FILES_RELEASES_REQUEST
    const res = { data: { body: '' } }
    handleApiResponseSuccess(dispatch, TYPE, res)
    dispatch({ type: `${TYPE}_COMPLETED`, payload: res })
  }
}
