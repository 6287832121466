import React, { Component } from 'react'
import { EmptyTableLayout, GroupOptionsControllerLayout } from '../../../layouts'
import { history } from '../../../../routing/History'
import { Session } from '../../../../state/utils/session'
import { returnSortIcon, updateThSortBy } from '../../../../utils/tables'
import { Link } from 'react-router-dom'
import * as ROUTING_PATHS from '../../../../routing/Paths'

const thList = [
  {
    title: 'ID',
    att: 'id',
    sortedBy: null
  },
  {
    title: 'Connection',
    att: 'connection__name',
    sortedBy: null
  },
  {
    title: 'Table Name',
    att: 'table_name',
    sortedBy: null
  }
]

class AccountingSeatSettingsTable extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session(),
      thList
    }

    this.goToAccountingSeatSettingEditor = this.goToAccountingSeatSettingEditor.bind(this)
    this.goToAccountingSeatSettingDetails = this.goToAccountingSeatSettingDetails.bind(this)
    this.applySort = this.applySort.bind(this)
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    if (!history.location.search.includes('ordering')) {
      this.setState({
        thList
      })
    }
  }

  goToAccountingSeatSettingEditor (accountingSeat) {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_ACCOUNTING_SEAT_SETTING_EDITOR_PATH
        .replace(':accounting_seat_setting_id', accountingSeat.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  goToAccountingSeatSettingDetails (accountingSeat) {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_ACCOUNTING_SEAT_SETTING_DETAILS_PATH
        .replace(':accounting_seat_setting_id', accountingSeat.id)
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  applySort (index) {
    const { thList } = this.state
    thList[index] = updateThSortBy(thList[index])
    this.setState({ thList }, () => {
      this.props.onSortBy(thList[index].att, thList[index].sortedBy)
    })
  }

  render () {
    return (
            <>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered table-condensed text-center">
                        <thead>
                        <tr>
                            {
                                this.state.thList.map((th, index) => {
                                  return (
                                        <th
                                            key={index}
                                            className={'text-center cursor-pointer'}
                                            onClick={this.applySort.bind(this, index)}
                                        >
                                            {th.title}
                                            &nbsp;
                                            {returnSortIcon(th.sortedBy)}
                                        </th>
                                  )
                                })
                            }
                            <th className="text-center">Options</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.accountingSeatSettings.map((accountingSeat) => {
                              return (
                                    <tr key={accountingSeat.id}>
                                        <td className="text-center">
                                            {accountingSeat.id}
                                        </td>
                                        <td className="text-center">
                                            <Link to={
                                                ROUTING_PATHS.CLIENT_DASHBOARD_CONNECTION_DETAILS_PATH
                                                  .replace(':client_id', this.state.session.getClientSession())
                                                  .replace(':connection_id', accountingSeat.connection)
                                            }>
                                                {accountingSeat.connection_name}
                                            </Link>
                                        </td>
                                        <td className="text-center">
                                            {accountingSeat.table_name}
                                        </td>
                                        <td>
                                            <GroupOptionsControllerLayout validFor={['ADMIN', 'DEVELOPER']}>
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Edit Setting"
                                                        onClick={this.goToAccountingSeatSettingEditor.bind(this, accountingSeat)}
                                                >
                                                    <i className="fa fa-pen"></i>
                                                </button>
                                            </GroupOptionsControllerLayout>
                                            <button className="btn btn-xs btn-default"
                                                    type="button"
                                                    data-toggle="tooltip"
                                                    title="Select Setting"
                                                    onClick={this.goToAccountingSeatSettingDetails.bind(this, accountingSeat)}
                                            >
                                                <i className="fa fa-eye"></i>
                                            </button>
                                            <GroupOptionsControllerLayout validFor={['ADMIN']}>
                                                <button className="btn btn-xs btn-default"
                                                        type="button"
                                                        data-toggle="tooltip"
                                                        title="Remove Setting"
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </GroupOptionsControllerLayout>
                                        </td>
                                    </tr>
                              )
                            })
                        }
                        <EmptyTableLayout results={this.props.accountingSeats} columns={4}/>
                        </tbody>
                    </table>
                    {this.props.children}
                </div>
            </>
    )
  }
}

// Set defaultProps
AccountingSeatSettingsTable.defaultProps = {
  accountingSeatSettings: [],
  onSortBy: () => {}
}

// Export Class
export { AccountingSeatSettingsTable }
