import React, { Component } from 'react'
import { Session } from '../../../../../state/utils/session'
import { Button } from 'react-bootstrap'
import * as PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { clientSettingsOperations } from '../../../../../state/ducks/client-settings'

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? 'modal display-block' : 'modal display-none'
  return (

        <div className={showHideClassName}>
            <section className="modal-main">
                <button className={'modal-close-button'} onClick={handleClose}>X</button>
                {children}
            </section>
        </div>
  )
}

class AddBankSettingModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      country: 'AR',
      bank: 'Citibank',
      account: ''
    }
  }

  render () {
    return (
            <div className={'modal-content'} style={{ padding: '8px 16px', minWidth: '300px', borderRadius: '5px' }}>
                <div className="modal-title" style={{ fontSize: '1.7rem', textAlign: 'center' }}>
                    Agregar una cuenta
                </div>
                <div className="modal-body" style={{ marginBottom: '32px' }}>
                    <form>
                        <div className="form-group">
                            <label htmlFor="country">Elige un país</label>
                            <select className="form-control" id="country" value={this.state.country}
                                    onChange={(e) => this.setState({ country: e.target.value })}>
                                <option value="AR">Argentina</option>
                                <option value="BR">Brasil</option>
                                <option value="CL">Chile</option>
                                <option value="CO">Colombia</option>
                                <option value="MX">México</option>
                                <option value="PE">Perú</option>
                                <option value="UY">Uruguay</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="bank">Banco</label>
                            <select className="form-control" id="bank" value={this.state.bank}
                                    onChange={(e) => this.setState({ bank: e.target.value })}>
                                <option value="citibank">Citibank</option>
                                <option value="santander">Santander</option>
                                <option value="spb">SPB</option>
                                <option value="banamex">Banamex</option>
                                <option value="davivienda">Davivienda</option>
                                <option value="bancolombia">Bancolombia</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="account">Cuenta bancaria</label>
                            <input className="form-control" type="text" id="account" value={this.state.account}
                                   onChange={(e) => this.setState({ account: e.target.value })}/>
                        </div>
                    </form>
                    <div className="form-actions">
                        <div className="btn" onClick={() => this.props.handleSave(this.state)} style={{
                          background: '#212b5c',
                          color: 'white',
                          float: 'right',
                          marginTop: '16px',
                          marginBottom: '16px'
                        }}>Agregar</div>
                        <div className="btn" onClick={() => this.props.handleClose()} style={{
                          background: '#f0f0f0',
                          float: 'right',
                          marginTop: '16px',
                          marginBottom: '16px',
                          marginRight: '8px'
                        }}>Salir</div>
                    </div>
                </div>
            </div>
    )
  }
}

class BankSettingsPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      session: new Session(),
      settings: [],
      showModal: false,
      activeModal: null
    }
  }

  componentDidMount () {
    this.props.getClientSetting('bank_settings')
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    this.setState({
      settings: nextProps.settings
    })
  }

  handleOnSettingChange (event, indexConfig, indexExtraction) {
    const newState = Object.assign({}, this.state)
    newState.settings[indexConfig].extraction[indexExtraction].method = event.target.value
    this.setState(newState)
  }

  removeConfig (indexConfig) {
    const newState = Object.assign({}, this.state)
    if (indexConfig > -1) {
      newState.settings.splice(indexConfig, 1)
    }
    this.setState(newState)
  }

  addNewConfig () {
    this.showModal()
  }

  updateSetting () {
    this.props.updateClientSetting(this.props.settingId, { name: 'bank_settings', parameters: this.state.settings, client: this.state.session.getClientSession() })
  }

  renderSettingTableRow (config, indexConfig) {
    const isBankAdmin = this.state.session.allowedActionFor(['BANK_ADMIN', 'ADMIN'])
    return (
            <tr>
                <td>
                    <span className={config.flag}></span>
                </td>
                <td>{config.financial_entity}</td>
                <td>{config.account_number}</td>
                {
                    config.extraction.map((day, indexExtraction) => <td key={`extraction-${indexExtraction}`} >
                        <select className="form-control" name={day.day} value={day.method}
                                onChange={(e) => this.handleOnSettingChange(e, indexConfig, indexExtraction)}>
                            <option value="SWIFT">Swift</option>
                            <option value="MANUAL">Manual</option>
                        </select>
                    </td>)
                }
                <td>
                    {
                        isBankAdmin
                          ? <Button
                                variant="danger"
                                className="float-right"
                                onClick={this.removeConfig.bind(this, indexConfig)}
                            >
                                Delete
                            </Button> : null
                    }

                </td>
            </tr>
    )
  }

  renderSettingsTable () {
    return (
            <table className="table table-responsive text-centered config-table" style={{ background: 'white' }}>
                <thead>
                <tr>
                    <th>País</th>
                    <th>Banco</th>
                    <th>Cuenta</th>
                    <th>Lunes</th>
                    <th>Martes</th>
                    <th>Miércoles</th>
                    <th>Jueves</th>
                    <th>Viérnes</th>
                    <th>Sábado</th>
                    <th>Domingo</th>
                </tr>
                </thead>
                <tbody>
                {
                    this.state.settings.map((config, index) => this.renderSettingTableRow(config, index))

                }
                </tbody>
            </table>
    )
  }

  renderActions () {
    const isBankAdmin = this.state.session.allowedActionFor(['ADMIN', 'BANK_ADMIN'])

    if (this.props.isLoading) {
      return <div className="loader" style={{ height: this.props.viewPort, marginTop: '64px' }}>
                <svg className="spinner" width={32} height={32} viewBox="0 0 66 66"
                     xmlns="http://www.w3.org/2000/svg">
                    <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round"
                            cx="33" cy="33" r="30"></circle>
                </svg>
            </div>
    } else {
      if (isBankAdmin) {
        return (
                    <div className={'col-sm-12'} style={{ margin: '16px 0px' }}>
                        <div className="btn" onClick={() => this.updateSetting()} style={{
                          background: '#212b5c',
                          color: 'white',
                          float: 'right',
                          marginRight: '16px'
                        }}>
                            Guardar cambios
                        </div>
                        <div className="btn" onClick={() => this.addNewConfig()} style={{
                          background: '#f0f0f0',
                          float: 'right',
                          marginRight: '16px'
                        }}>Agregar
                        </div>
                    </div>
        )
      }
    }
  }

  createSetting (state) {
    const config = {
      country: state.country,
      flag: `flag-icon flag-icon-${state.country.toLowerCase()}`,
      financial_entity: state.bank.charAt(0).toUpperCase() + state.bank.substring(1),
      account_number: state.account,
      extraction: [
        { day: 'Monday', method: 'SWIFT' },
        { day: 'Tuesday', method: 'SWIFT' },
        { day: 'Wednesday', method: 'SWIFT' },
        { day: 'Thursday', method: 'SWIFT' },
        { day: 'Friday', method: 'SWIFT' },
        { day: 'Saturday', method: 'SWIFT' },
        { day: 'Sunday', method: 'SWIFT' }
      ]
    }
    const newState = Object.assign({}, this.state)
    newState.settings.push(config)
    this.setState(newState)
    this.hideModal()
  }

  showModal (user) {
    this.setState({
      activeModal: <AddBankSettingModal handleClose={this.hideModal.bind(this)} handleSave={this.createSetting.bind(this)}/>,
      showModal: true
    })
  }

  hideModal () {
    this.setState({
      activeModal: null,
      showModal: false
    })
  }

  render () {
    return (
            <div>
                <div className="flex-container">
                    <div className="page-title">
                        <h1 className="config-title">Carga de extractos</h1>
                        <h5>En esta sección podrás configurar la carga de extractos bancarios.</h5>
                    </div>
                    {
                        this.props.isLoading ? (<div className="loader" style={{ height: this.props.viewPort, marginTop: '64px' }}>
                        <svg className="spinner" width={32} height={32} viewBox="0 0 66 66"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle className="path" fill="none" strokeWidth="6" strokeLinecap="round"
                        cx="33" cy="33" r="30"></circle>
                        </svg>
                        </div>)
                          : <div className="body">
                                {this.renderSettingsTable()}
                                {this.renderActions()}
                            </div>
                    }

                </div>
                <Modal show={this.state.showModal}
                       handleClose={this.hideModal.bind(this)}>
                    {this.state.activeModal}
                </Modal>
            </div>)
  }
}

// Set propTypes
BankSettingsPage.propTypes = {
  settings: PropTypes.array,
  getAccountingSeatErrorResponse: PropTypes.object
}

// Set defaultProps
BankSettingsPage.defaultProps = {
  settings: [],
  getAccountingSeatErrorResponse: {}
}

// mapStateToProps
const mapStateToProps = (state) => {
  const parameters =
      state.clientSettings.fetchClientSettingsReducer.data.parameters || []
  const settings = Array.isArray(parameters)
    ? parameters
    : Object.keys(parameters).length
      ? [parameters]
      : []

  return {
    settings,
    settingId: state.clientSettings.fetchClientSettingsReducer.data.id,
    isLoading: state.clientSettings.fetchClientSettingsReducer.loading,
    getAccountingSeatErrorResponse: state.clientSettings.fetchClientSettingsReducer.error_data
  }
}

// mapStateToProps
const mapDispatchToProps = {
  getClientSetting: clientSettingsOperations.get,
  updateClientSetting: clientSettingsOperations.update
}

// Export Class
const _BankSettingsPage = connect(mapStateToProps, mapDispatchToProps)(BankSettingsPage)
export { _BankSettingsPage as BankSettingsPage }
