import React, { Component } from 'react'
import {
  GroupOptionsControllerLayout,
  InputSearchLayout,
  PageContainerLayout,
  PageHeaderLayout,
  PaginationLayout
} from '../../../layouts'
import { ClientsHeaderTiles, ClientsTable } from '../../containers'
import { clientOperations } from '../../../../state/ducks/clients'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { ClientShape, PaginationShape } from '../../../../prop-types'
import { Session } from '../../../../state/utils/session'

class ClientsPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session(),
      params: {}
    }
    this.fetchClients = this.fetchClients.bind(this)
    this.onPaginationChange = this.onPaginationChange.bind(this)
    this.onSearchChange = this.onSearchChange.bind(this)
    this.updateParams = this.updateParams.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.fetchClients()
  }

  fetchClients () {
    this.props.fetchClients(this.state.params)
  }

  onPaginationChange (page) {
    this.updateParams('page', page)
  }

  onSearchChange (keywork) {
    this.updateParams('search', keywork)
  }

  updateParams (key, value) {
    const { params } = this.state
    params[key] = value
    if (key === 'search') {
      params.page = 1
    }
    this.setState({
      params
    }, () => this.fetchClients())
  }

  renderHeaderPaths () {
    return (
            <>
                <li>Clients</li>
            </>
    )
  }

  render () {
    return (
            <>
                <PageContainerLayout>
                    <PageHeaderLayout title={'Clients'} subTitle={'All clients'}
                                      paths={this.renderHeaderPaths}/>
                    {/* Page Content */}
                    <div className="content content-boxed">
                        {/* Header Tiles */}
                        <GroupOptionsControllerLayout validFor={['ADMIN', 'DEVELOPER']}>
                            <ClientsHeaderTiles all_clients={this.props.paginationData.totalItems}/>
                        </GroupOptionsControllerLayout>
                        {/* END Header Tiles */}
                        <div className={' block block-themed block-opt-refresh-icon7 ' + (this.props.isLoading ? ' block-opt-refresh ' : '')}>
                            <div className="block-header bg-primary-dark">
                                <ul className="block-options">
                                    <li>
                                        <button type="button"
                                                data-toggle="block-option"
                                                data-action="refresh_toggle"
                                                data-action-mode="demo"
                                                onClick={this.fetchClients}
                                        >
                                            <i className="si si-refresh"></i>
                                        </button>
                                    </li>
                                </ul>
                                <h3 className="block-title">All clients</h3>
                            </div>
                            <div className="block-content">
                                {/* Search */}
                                <InputSearchLayout onSearchChange={this.onSearchChange}/>
                                {/* End Search */}
                                {/* Table */}
                                <ClientsTable clients={this.props.clients}>
                                    {/* Pagination */}
                                    <PaginationLayout pagination={this.props.paginationData}
                                                      onPaginationChange={this.onPaginationChange}/>
                                    {/* End Pagination */}
                                </ClientsTable>
                                {/* End Table */}
                            </div>
                        </div>
                    </div>
                    {/* END Page Content */}
                </PageContainerLayout>
            </>
    )
  };
}

// Set propTypes
const { arrayOf, func } = PropTypes
ClientsPage.propTypes = {
  clients: arrayOf(ClientShape).isRequired,
  fetchClients: func.isRequired,
  paginationData: PaginationShape
}

// Set defaultProps
ClientsPage.defaultProps = {
  clients: [],
  paginationData: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  isLoading: state.clients.fetchClientsReducer.loading,
  clients: state.clients.fetchClientsReducer.data.results,
  paginationData: state.clients.fetchClientsReducer.data.pagination_data
})

// mapStateToProps
const mapDispatchToProps = {
  fetchClients: clientOperations.list
}

// Export Class
const _ClientsPage = connect(mapStateToProps, mapDispatchToProps)(ClientsPage)
export { _ClientsPage as ClientsPage }
