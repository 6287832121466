import React, { Component } from 'react'

class ClientDataForm extends Component {
  constructor (props) {
    super(props)

    // state
    this.state = { client: {} }

    this.handleChange = this.handleChange.bind(this)
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    this.setState({
      client: nextProps.client
    })
  }

  handleChange (event) {
    this.props.onChange(event.target.name, event.target.value)
  }

  render () {
    return (
            <>

                <div className="form-group">
                    <div className="col-sm-8 col-sm-offset-2">
                        <div className="form-material">
                            <input className="form-control"
                                   type="text"
                                   id="input-name"
                                   placeholder="Please enter the company name"
                                   name="name"
                                   value={this.state.client.name}
                                   onChange={this.handleChange}
                            />
                            <label htmlFor="input-name">Client Name</label>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="col-sm-8 col-sm-offset-2">
                        <div className="form-material">
                            <input className="form-control"
                                   type="text"
                                   id="input-logo"
                                   placeholder="Please enter a url"
                                   name="logo"
                                   value={this.state.client.logo}
                                   onChange={this.handleChange}
                            />
                            <label htmlFor="input-region">Logo</label>
                        </div>
                    </div>
                </div>
                {/* <div className="form-group"> */}
                {/*    <div className="col-sm-8 col-sm-offset-2"> */}
                {/*        <div className="form-material"> */}
                {/*            <select className="form-control" */}
                {/*                    type="text" */}
                {/*                    id="input-emr_region" */}
                {/*                    name="emr_region" */}
                {/*                    value={this.state.client.emr_region} */}
                {/*                    onChange={this.handleChange} */}
                {/*            > */}
                {/*                <option value=""></option> */}
                {/*                <option value="us-east-2">Ohio (us-east-2)</option> */}
                {/*                <option value="us-east-1">Norte de Virginia (us-east-1) */}
                {/*                </option> */}
                {/*                <option value="us-west-1">Norte de California (us-west-1) */}
                {/*                </option> */}
                {/*                <option value="us-west-2">Oregón (us-west-2)</option> */}
                {/*                /!*<option value="ap-east-1">Hong Kong (ap-east-1)</option>*!/ */}
                {/*                /!*<option value="ap-south-1">Mumbai (ap-south-1)</option>*!/ */}
                {/*                /!*<option value="ap-northeast-3">Osaka-local (ap-northeast-3)</option>*!/ */}
                {/*                /!*<option value="ap-northeast-2">Seúl (ap-northeast-2)</option>*!/ */}
                {/*                /!*<option value="ap-southeast-1">Singapur (ap-southeast-1)</option>*!/ */}
                {/*                /!*<option value="ap-southeast-2">Sídney (ap-southeast-2)</option>*!/ */}
                {/*                /!*<option value="ap-northeast-1">Tokio (ap-northeast-1)</option>*!/ */}
                {/*                /!*<option value="ca-central-1">Central (ca-central-1)</option>*!/ */}
                {/*                /!*<option value="cn-north-1">Beijing (cn-north-1)</option>*!/ */}
                {/*                /!*<option value="cn-northwest-1">Ningxia (cn-northwest-1)</option>*!/ */}
                {/*                /!*<option value="eu-central-1">Fráncfort (eu-central-1)</option>*!/ */}
                {/*                /!*<option value="eu-west-1">Irlanda (eu-west-1)</option>*!/ */}
                {/*                /!*<option value="eu-west-2">Londres (eu-west-2)</option>*!/ */}
                {/*                /!*<option value="eu-west-3">París (eu-west-3)</option>*!/ */}
                {/*                /!*<option value="eu-north-1">Estocolmo (eu-north-1)</option>*!/ */}
                {/*                /!*<option value="sa-east-1">São Paulo (sa-east-1)</option>*!/ */}
                {/*                /!*<option value="gov-east-1">US-East (us-gov-east-1)</option>*!/ */}
                {/*                /!*<option value="gov-west-1">EE.UU. (us-gov-west-1)</option>*!/ */}
                {/*            </select> */}
                {/*            <label htmlFor="input-emr_region" */}
                {/*                   data-toggle="tooltip" */}
                {/*                   title="Is the AWS region where all your EMR clusters will run" */}
                {/*            > */}
                {/*                Region <i className="fa fa-question-circle"></i> */}
                {/*            </label> */}
                {/*        </div> */}
                {/*    </div> */}
                {/* </div> */}
                {/* <div className="form-group"> */}
                {/*    <div className="col-sm-8 col-sm-offset-2"> */}
                {/*        <div className="form-material"> */}
                {/*            <input className="form-control" */}
                {/*                   type="text" */}
                {/*                   id="input-emr-sufix" */}
                {/*                   placeholder="Please enter a suffix" */}
                {/*                   name="emr_suffix" */}
                {/*                   value={this.state.client.emr_suffix} */}
                {/*                   onChange={this.handleChange} */}
                {/*            /> */}
                {/*            <label htmlFor="input-region" */}
                {/*                   data-toggle="tooltip" */}
                {/*                   title="Is a suffix for all your EMR clusters" */}
                {/*            > */}
                {/*                EMR Suffix Name <i className="fa fa-question-circle"></i> */}
                {/*            </label> */}
                {/*        </div> */}
                {/*    </div> */}
                {/* </div> */}

                <div className="form-group">
                    <div className="col-sm-8 col-sm-offset-2">
                        <div className="form-material">
                            <select className="form-control"
                                    type="text"
                                    id="input-quicksight_region"
                                    name="quicksight_region"
                                    value={this.state.client.quicksight_region}
                                    onChange={this.handleChange}
                            >
                                <option value=""></option>
                                <option value="us-east-2">Ohio (us-east-2)</option>
                                <option value="us-east-1">Norte de Virginia (us-east-1)</option>
                                <option value="us-west-1">Norte de California (us-west-1)</option>
                                <option value="us-west-2">Oregón (us-west-2)</option>
                                <option value="ap-east-1">Hong Kong (ap-east-1)</option>
                                <option value="ap-south-1">Mumbai (ap-south-1)</option>
                                <option value="ap-northeast-3">Osaka-local (ap-northeast-3)</option>
                                <option value="ap-northeast-2">Seúl (ap-northeast-2)</option>
                                <option value="ap-southeast-1">Singapur (ap-southeast-1)</option>
                                <option value="ap-southeast-2">Sídney (ap-southeast-2)</option>
                                <option value="ap-northeast-1">Tokio (ap-northeast-1)</option>
                                <option value="ca-central-1">Central (ca-central-1)</option>
                                <option value="cn-north-1">Beijing (cn-north-1)</option>
                                <option value="cn-northwest-1">Ningxia (cn-northwest-1)</option>
                                <option value="eu-central-1">Fráncfort (eu-central-1)</option>
                                <option value="eu-west-1">Irlanda (eu-west-1)</option>
                                <option value="eu-west-2">Londres (eu-west-2)</option>
                                <option value="eu-west-3">París (eu-west-3)</option>
                                <option value="eu-north-1">Estocolmo (eu-north-1)</option>
                                <option value="sa-east-1">São Paulo (sa-east-1)</option>
                                <option value="gov-east-1">US-East (us-gov-east-1)</option>
                                <option value="gov-west-1">EE.UU. (us-gov-west-1)</option>
                            </select>
                            <label htmlFor="input-quicksight_region">Quicksight Region</label>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <div className="col-sm-8 col-sm-offset-2">
                        <div className="form-material">
                            <input className="form-control"
                                   type="text"
                                   id="input-user_arn"
                                   placeholder="Please enter a User URN"
                                   name="user_arn"
                                   value={this.state.client.user_arn}
                                   onChange={this.handleChange}
                            />
                            <label htmlFor="input-user_arn">User ARN</label>
                        </div>
                    </div>
                </div>
            </>
    )
  }
}

// Set default props
ClientDataForm.defaultProps = {
  client: {},
  onChange: () => {
  }
}

export {
  ClientDataForm
}
