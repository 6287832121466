// EXTRACTIONS
export const FETCH_EXTRACTIONS_REQUEST = 'session/FETCH_EXTRACTIONS_REQUEST'
export const FETCH_EXTRACTIONS_REQUEST_SUCCESS = 'session/FETCH_EXTRACTIONS_REQUEST_SUCCESS'
export const FETCH_EXTRACTIONS_REQUEST_FAILURE = 'session/FETCH_EXTRACTIONS_REQUEST_FAILURE'
export const FETCH_EXTRACTIONS_REQUEST_COMPLETED = 'session/FETCH_EXTRACTIONS_REQUEST_COMPLETED'

export const GET_EXTRACTION_REQUEST = 'session/GET_EXTRACTION_REQUEST'
export const GET_EXTRACTION_REQUEST_SUCCESS = 'session/GET_EXTRACTION_REQUEST_SUCCESS'
export const GET_EXTRACTION_REQUEST_FAILURE = 'session/GET_EXTRACTION_REQUEST_FAILURE'
export const GET_EXTRACTION_REQUEST_COMPLETED = 'session/GET_EXTRACTION_REQUEST_COMPLETED'

export const SAVE_EXTRACTION_REQUEST = 'session/SAVE_EXTRACTION_REQUEST'
export const SAVE_EXTRACTION_REQUEST_SUCCESS = 'session/SAVE_EXTRACTION_REQUEST_SUCCESS'
export const SAVE_EXTRACTION_REQUEST_FAILURE = 'session/SAVE_EXTRACTION_REQUEST_FAILURE'
export const SAVE_EXTRACTION_REQUEST_COMPLETED = 'session/SAVE_EXTRACTION_REQUEST_COMPLETED'

export const UPDATE_EXTRACTION_REQUEST = 'session/UPDATE_EXTRACTION_REQUEST'
export const UPDATE_EXTRACTION_REQUEST_SUCCESS = 'session/UPDATE_EXTRACTION_REQUEST_SUCCESS'
export const UPDATE_EXTRACTION_REQUEST_FAILURE = 'session/UPDATE_EXTRACTION_REQUEST_FAILURE'
export const UPDATE_EXTRACTION_REQUEST_COMPLETED = 'session/UPDATE_EXTRACTION_REQUEST_COMPLETED'

export const REMOVE_EXTRACTION_REQUEST = 'session/REMOVE_EXTRACTION_REQUEST'
export const REMOVE_EXTRACTION_REQUEST_SUCCESS = 'session/REMOVE_EXTRACTION_REQUEST_SUCCESS'
export const REMOVE_EXTRACTION_REQUEST_FAILURE = 'session/REMOVE_EXTRACTION_REQUEST_FAILURE'
