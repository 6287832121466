import reducer from './reducers'

import * as clientSettingsOperations from './operations'
import * as clientSettingsEndpoints from './paths'

export {
  clientSettingsOperations,
  clientSettingsEndpoints
}

export default reducer
