import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { ChangePasswordForm, VerifySecurityCodeForm } from '../../containers'
import { AuthenticationPageLayout } from '../../../layouts/authentication-page'

class ActionRequiredPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      action: this.props.match.params.action
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.match.params.action !== this.state.action) {
      this.setState({
        action: nextProps.match.params.action
      }, () => {
        this.actionContainer()
      })
    }
  }

  actionContainer () {
    switch (this.state.action) {
      case 'change-password':
        return (
                    <ChangePasswordForm />
        )
      case 'verify-security-code':
        return (
                    <VerifySecurityCodeForm />
        )
      default:
        return (
                    <VerifySecurityCodeForm />
        )
    }
  }

  render () {
    return (
            <>
                <AuthenticationPageLayout>
                    {this.actionContainer()}
                </AuthenticationPageLayout>
            </>
    )
  };
}

const _ActionRequiredPage = withRouter(ActionRequiredPage)

export { _ActionRequiredPage as ActionRequiredPage }
