import React, { Component } from 'react'
import { history } from '../../../../routing/History'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { Session } from '../../../../state/utils/session'
import { DownloadButton } from '../../../shared/containers'

class ExtractionsHeaderTiles extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }
    this.goToGroupCreator = this.goToGroupCreator.bind(this)
  }

  goToGroupCreator () {
    history.push(
      ROUTING_PATHS.CLIENT_DASHBOARD_EXTRACTION_CREATOR_PATH
        .replace(':client_id', this.state.session.getClientSession())
    )
  }

  render () {
    return (
            <>
                <div className="row">
                    <div className="col-sm-6 col-md-3">
                        <a className="block block-link-hover3 text-center"
                           href="javascript:void(0)"
                           onClick={this.goToGroupCreator}
                        >
                            <div className="block-content block-content-full">
                                <div className="h1 font-w700 text-success"><i className="fa fa-plus"></i></div>
                            </div>
                            <div className="block-content block-content-full block-content-mini bg-gray-lighter text-success font-w600">
                                Add New Extraction
                            </div>
                        </a>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <a className="block block-link-hover3 text-center" href="javascript:void(0)">
                            <div className="block-content block-content-full">
                                <div className="h1 font-w700">{this.props.all_extractions}</div>
                            </div>
                            <div className="block-content block-content-full block-content-mini bg-gray-lighter text-muted font-w600">
                                All extractions
                            </div>
                        </a>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <a className="block block-link-hover3 text-center" href="javascript:void(0)">
                            <div className="block-content block-content-full">
                                <div className="h1 font-w700">
                                    <DownloadButton name='extractions' />
                                </div>
                            </div>
                            <div className="block-content block-content-full block-content-mini bg-gray-lighter text-muted font-w600">
                                Download Pipeline
                            </div>
                        </a>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <a className="block block-link-hover3 text-center" href="javascript:void(0)">
                            <div className="block-content block-content-full">
                                <div className="h1 font-w700">&nbsp;</div>
                            </div>
                            <div className="block-content block-content-full block-content-mini bg-gray-lighter text-muted font-w600">
                                &nbsp;
                            </div>
                        </a>
                    </div>
                </div>
            </>
    )
  }
}

// Set defaultProps
ExtractionsHeaderTiles.defaultProps = {
  all_extractions: 0
}

// Export Class
export { ExtractionsHeaderTiles }
