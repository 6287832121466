import React, { Component } from 'react'
import { isEmail, ReactMultiEmail } from 'react-multi-email'

class AlarmEmailForm extends Component {
  constructor (props) {
    super(props)

    // props
    this.props = {}

    this.handleChange = this.handleChange.bind(this)
    this.handleEmailsChange = this.handleEmailsChange.bind(this)
    this.returnEmailsArray = this.returnEmailsArray.bind(this)
  }

  handleChange (event) {
    this.props.onChange(event.target.name, event.target.value)
  }

  handleEmailsChange (emails) {
    this.props.onChange('emails', emails)
  }

  returnEmailsArray () {
    switch (typeof this.props.alarm.emails) {
      case 'object':
        return this.props.alarm.emails
      case 'string':
        try {
          return this.props.alarm.emails.split(',')
        } catch (error) {
          return [this.props.alarm.emails]
        }
    }
  }

  render () {
    return (
            <>
                <div className="form-group">
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <div className="form-material">
                            <ReactMultiEmail
                                name="emails"
                                placeholder="Enter Emails"
                                emails={this.props.alarm.emails ? this.returnEmailsArray() : []}
                                onChange={(emails) => this.handleEmailsChange(emails)}
                                validateEmail={email => {
                                  return isEmail(email)
                                }}
                                getLabel={(
                                  email,
                                  index,
                                  removeEmail
                                ) => {
                                  return (
                                        <div data-tag key={index}>
                                            {email}
                                            <span data-tag-handle onClick={() => removeEmail(index)}>
                                          ×
                                        </span>
                                        </div>
                                  )
                                }}
                            />
                            <label htmlFor="input-name">Emails</label>
                        </div>
                    </div>
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <div className="form-material">
                            <input className="form-control"
                                   type="text"
                                   id="input-subject"
                                   placeholder="Please enter the subject"
                                   name="subject"
                                   value={this.props.alarm.subject}
                                   onChange={this.handleChange}
                            />
                            <label htmlFor="input-subject">Subject</label>
                        </div>
                    </div>
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <div className="form-material">
                            <select
                                className="form-control"
                                id="input-template"
                                name="template"
                                value={this.props.alarm.template}
                                onChange={this.handleChange}
                            >
                                <option>-</option>
                                <option value="HelloWorld">HelloWorld</option>
                                <option value="ReporteAlarma">ReporteAlarma</option>
                                <option value="ReporteAlarmaCero">ReporteAlarmaCero</option>
                                <option value="TuReporteDiario">TuReporteDiario</option>
                            </select>
                            <label htmlFor="input-template">Template</label>
                        </div>
                    </div>
                </div>
            </>
    )
  }
}

// Set default props
AlarmEmailForm.defaultProps = {
  alarm: {},
  onChange: () => {
  }
}

export {
  AlarmEmailForm
}
