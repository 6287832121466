import React from 'react'
import './modal.styles.scss'

export const Modal = ({ show, handleClose, s3file }) => {
  const showHideClassName = show ? 'modal display-block' : 'modal display-none'
  return (
    <div className={showHideClassName}>
      <section className="modal-main">
        <p>{s3file}</p>
        <div className="btn-container">
            <button className="btn cancel" onClick={handleClose}>
              Cerrar
            </button>
          </div>
      </section>
    </div>
  )
}
