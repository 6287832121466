import { combineReducers } from 'redux'
import * as types from './types'

const fetchConnectionsInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { results: [], pagination_data: {} }
}

const getConnectionInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { groups: [] }
}

const saveConnectionInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

const updateConnectionInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

export function fetchConnectionsReducer (state = fetchConnectionsInitialState, action) {
  switch (action.type) {
    case types.FETCH_CONNECTIONS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.FETCH_CONNECTIONS_REQUEST_FAILURE:
      return {
        ...fetchConnectionsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.FETCH_CONNECTIONS_REQUEST_SUCCESS:
      return {
        ...fetchConnectionsInitialState,
        data: action.payload.data
      }
    case types.FETCH_CONNECTIONS_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function getConnectionReducer (state = getConnectionInitialState, action) {
  switch (action.type) {
    case types.GET_CONNECTION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.GET_CONNECTION_REQUEST_FAILURE:
      return {
        ...getConnectionInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.GET_CONNECTION_REQUEST_SUCCESS:
      return {
        ...getConnectionInitialState,
        data: action.payload.data
      }
    case types.GET_CONNECTION_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function saveConnectionReducer (state = saveConnectionInitialState, action) {
  switch (action.type) {
    case types.SAVE_CONNECTION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.SAVE_CONNECTION_REQUEST_FAILURE:
      return {
        ...fetchConnectionsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.SAVE_CONNECTION_REQUEST_SUCCESS:
      return {
        ...fetchConnectionsInitialState,
        data: action.payload.data
      }
    case types.SAVE_CONNECTION_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function updateConnectionReducer (state = updateConnectionInitialState, action) {
  switch (action.type) {
    case types.UPDATE_CONNECTION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.UPDATE_CONNECTION_REQUEST_FAILURE:
      return {
        ...updateConnectionInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.UPDATE_CONNECTION_REQUEST_SUCCESS:
      return {
        ...updateConnectionInitialState,
        data: action.payload.data
      }
    case types.UPDATE_CONNECTION_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export default combineReducers({
  fetchConnectionsReducer,
  saveConnectionReducer,
  getConnectionReducer,
  updateConnectionReducer
})
