// JOBS
export const FETCH_JOBS_REQUEST = 'session/FETCH_JOBS_REQUEST'
export const FETCH_JOBS_REQUEST_SUCCESS = 'session/FETCH_JOBS_REQUEST_SUCCESS'
export const FETCH_JOBS_REQUEST_FAILURE = 'session/FETCH_JOBS_REQUEST_FAILURE'
export const FETCH_JOBS_REQUEST_COMPLETED = 'session/FETCH_JOBS_REQUEST_COMPLETED'

export const GET_JOB_REQUEST = 'session/GET_JOB_REQUEST'
export const GET_JOB_REQUEST_SUCCESS = 'session/GET_JOB_REQUEST_SUCCESS'
export const GET_JOB_REQUEST_FAILURE = 'session/GET_JOB_REQUEST_FAILURE'
export const GET_JOB_REQUEST_COMPLETED = 'session/GET_JOB_REQUEST_COMPLETED'

export const FETCH_JOBS_HISTORICAL_REQUEST = 'session/FETCH_JOBS_HISTORICAL_REQUEST'
export const FETCH_JOBS_HISTORICAL_REQUEST_SUCCESS = 'session/FETCH_JOBS_HISTORICAL_REQUEST_SUCCESS'
export const FETCH_JOBS_HISTORICAL_REQUEST_FAILURE = 'session/FETCH_JOBS_HISTORICAL_REQUEST_FAILURE'
export const FETCH_JOBS_HISTORICAL_REQUEST_COMPLETED = 'session/FETCH_JOBS_HISTORICAL_REQUEST_COMPLETED'
