import React, { Component } from 'react'
import config from '../../../../../config'
import { Session } from '../../../../../state/utils/session'
import * as ROUTING_PATHS from '../../../../../routing/Paths'
import {
  GroupOptionsControllerLayout,
  InputSearchLayout,
  PageContainerLayout,
  PaginationLayout
} from '../../../../layouts'
import { releaseOperations } from '../../../../../state/ducks/deploy-tool'
import { connect } from 'react-redux'

import { ReleasesTable } from '../components/releaseTable'
import { Link } from 'react-router-dom'
import { LoadingPage } from '../components/loadingPage'
import axios from 'axios'
import { Modal } from '../components/modal.component'

class DeployListPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      session: new Session(),
      params: {},
      loading: true,
      id: null,
      is_running: false,
      show: false
    }
    this.fetchReleases = this.fetchReleases.bind(this)
    this.onPaginationChange = this.onPaginationChange.bind(this)
    this.onSearchChange = this.onSearchChange.bind(this)
    this.updateParams = this.updateParams.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.fetchReleases()
  }

  async componentDidMount () {
    try {
      const res = await axios.get(
        `${
          config.apiEndPoint
        }api/v1/clients/${this.state.session.getClientSession()}/settings?name=deploy_tool_properties`,
        {
          headers: {
            Authorization: `JWT ${this.state.session.selectedAuth}`
          }
        }
      )
      const result = res.data.results[0]
      this.setState({
        id: result.id,
        is_running: result.parameters.is_running,
        loading: false
      })
    } catch (err) {
      this.setState({ loading: false })
      console.log('Error: ', err)
    }
  }

  handleChangeState = async () => {
    this.setState({ loading: true })
    try {
      const res = await axios.patch(
        `${
          config.apiEndPoint
        }api/v1/clients/${this.state.session.getClientSession()}/settings/${
          this.state.id
        }/`,
        {
          parameters: {
            is_running: false
          }
        },
        {
          headers: {
            Authorization: `JWT ${this.state.session.selectedAuth}`
          }
        }
      )
      this.setState({
        loading: false,
        show: false,
        is_running: res.data.parameters.is_running
      })
    } catch (err) {
      console.log('Error al cambiar el estado')
      this.setState({ loading: false })
    }
  }

  showHideModal = () => {
    this.setState((st) => ({ ...st, show: !st.show }))
  }

  onPaginationChange (page) {
    this.updateParams('page', page)
  }

  onSearchChange (keywork) {
    this.updateParams('search', keywork)
  }

  fetchReleases () {
    this.props.fetchReleases(this.state.params)
  }

  updateParams (key, value) {
    const { params } = this.state
    params[key] = value
    if (key === 'search') {
      params.page = 1
    }
    this.setState(
      {
        params
      },
      () => this.fetchReleases()
    )
  }

  render () {
    const { loading, is_running: isRunning, show } = this.state
    return (
      <>
        <PageContainerLayout>
          {/* Page Content */}
          <div className="content content-boxed">
            <GroupOptionsControllerLayout
              validFor={['DEPLOY_TOOL_MANAGER']}
            >
              {loading ? (
                <LoadingPage />
              ) : (
                <div className="buttons-container">
                  {isRunning && (
                    <div
                      className={'deploy-tool creator-button'}
                      onClick={this.showHideModal}
                    >
                      <button>Desbloquear Usuario</button>
                    </div>
                  )}
                  <Link
                    to={ROUTING_PATHS.DEPLOY_TOOL_CREATOR_PATH.replace(
                      ':client_id',
                      this.state.session.getClientSession()
                    )}
                  >
                    <div className={'deploy-tool creator-button'}>
                      Crear nueva versión
                    </div>
                  </Link>
                </div>
              )}
            </GroupOptionsControllerLayout>

            <div
              className={
                ' block block-themed block-opt-refresh-icon7 ' +
                (this.props.isLoading ? ' block-opt-refresh ' : '')
              }
            >
              <div className="block-header bg-primary-dark">
                <ul className="block-options">
                  <li>
                    <button
                      type="button"
                      data-toggle="block-option"
                      data-action="refresh_toggle"
                      data-action-mode="demo"
                      onClick={this.fetchReleases}
                    >
                      <i className="si si-refresh"></i>
                    </button>
                  </li>
                </ul>
                <h3 className="block-title">Versiones de la aplicación</h3>
              </div>
              <div className="block-content">
                {/* Search */}
                <InputSearchLayout
                  searchLabel={'Busca por código de la versión'}
                  onSearchChange={this.onSearchChange}
                />
                {/* End Search */}
                {/* Table */}
                <ReleasesTable releases={this.props.releases}>
                  {/* Pagination */}
                  {console.log('PAGINATION====>', this.props.releases)}
                  <PaginationLayout
                    pagination={this.props.paginationData}
                    onPaginationChange={this.onPaginationChange}
                  />
                  {/* End Pagination */}
                </ReleasesTable>
                {/* End Table */}
              </div>
            </div>
          </div>
          {/* END Page Content */}
          <Modal
            show={show}
            handleClose={this.showHideModal}
            handleSubmit={this.handleChangeState}
            loading={loading}
          />
        </PageContainerLayout>
      </>
    )
  }
}

// Set defaultProps
DeployListPage.defaultProps = {
  releases: [],
  paginationData: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  isLoading: state.releases.fetchReleasesReducer.loading,
  releases: state.releases.fetchReleasesReducer.data.results,
  paginationData: state.releases.fetchReleasesReducer.data.pagination_data
})

// mapStateToProps
const mapDispatchToProps = {
  fetchReleases: releaseOperations.list
}

// Export Class
const _DeployListPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeployListPage)

export { _DeployListPage as DeployListPage }
