import React, { Component } from 'react'
import { Session } from '../../../../state/utils/session'

class JobsPoolDetailsCard extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }
  }

  render () {
    return (
            <>
                <div className={'block block-themed' + (this.props.isLoading ? ' block-opt-refresh ' : '')}>
                    <div className="block-header bg-primary-dark">
                        <h3 className="block-title">
                            <i className="fa fa-info-circle"></i> Details
                        </h3>
                    </div>
                    <div className="block-content">
                        <ul className="list list-activity push">
                            <li>
                                <div className="font-w600">ID:</div>
                                <div>{this.props.jobPool.id}</div>
                            </li>
                            <li>
                                <div className="font-w600">Job Pool name:</div>
                                <div>{this.props.jobPool.name}</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </>
    )
  }
}

// Set defaultProps
JobsPoolDetailsCard.defaultProps = {
  jobPool: {}
}

// Export Class
export { JobsPoolDetailsCard }
