import React from 'react'
import { NavLink } from 'react-router-dom'
import { PageContainerLayout, PageHeaderLayout } from '../../../layouts'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { Session } from '../../../../state/utils/session'
import { WorkerCallWizardForm } from '../../containers'

export function WorkerCallCreatorPage (props) {
  const session = new Session()
  const renderHeaderPaths = () => (
    <>
      <li>
        <NavLink
          className="link-effect"
          to={ROUTING_PATHS.CLIENT_DASHBOARD_WORKER_CALLS_PATH.replace(
            ':client_id',
            session.getClientSession()
          )}
        >
          Worker Calls
        </NavLink>
      </li>
      <li>New Worker Call</li>
    </>
  )
  return (
    <>
      <PageContainerLayout clientSidebar={true} showNavbarClientOptions={true}>
        <PageHeaderLayout
          title={'New Worker Call'}
          subTitle={'Create a new worker call'}
          paths={renderHeaderPaths}
        />
        <WorkerCallWizardForm />
      </PageContainerLayout>
    </>
  )
}
