// #############################
// AUTHENTICATION
// #############################
export const ROOT_PATH = '/'
export const NOT_FOUND_PATH = '/500-not-found'
export const NOT_AUTHORIZED_PATH = '/401-not-authorized'
export const LOGIN_PATH = '/login'
export const LOGOUT_PATH = '/logout'
export const LOGIN_SUCCESS_PATH = '/login/success/:token'
export const LOGIN_FAILURE_PATH = '/login/failure/:message'
export const LOGIN_ACTION_REQUIRED_PATH = '/login/action-required/:action'
export const RESET_PASSWORD_PATH = '/reset-password'
export const ACCOUNT_LOCKED_PATH = '/account-locked'
// #############################
// END AUTHENTICATION
// #############################

// #############################
// SHARED
// #############################
export const SHARED_USER_PROFILE_PATH = '/user-profile'
export const SHARED_USER_PROFILE_EDITOR_PATH = '/user-profile/editor'
// #############################
// END SHARED
// #############################

// #############################
// ADMIN DASHBOARD
// #############################
export const HOME_PATH = '/home'
export const CLIENTS_PATH = '/clients'
export const CLIENT_CREATOR_PATH = CLIENTS_PATH + '/creator'
export const CLIENT_EDITOR_PATH = CLIENTS_PATH + '/:client_id/editor'
export const CLIENT_DASHBOARD_PATH = CLIENTS_PATH + '/:client_id/dashboard'

export const PERMISSIONS_PATH = '/permissions'
export const PERMISSION_CREATOR_PATH = PERMISSIONS_PATH + '/creator'
export const PERMISSION_EDITOR_PATH =
  PERMISSIONS_PATH + '/:permission_id/editor'
export const PERMISSION_DETAILS_PATH =
  PERMISSIONS_PATH + '/:permission_id/details'

export const GROUPS_PATH = '/groups'
export const GROUP_CREATOR_PATH = GROUPS_PATH + '/creator'
export const GROUP_EDITOR_PATH = GROUPS_PATH + '/:group_id/editor'
export const GROUP_DETAILS_PATH = GROUPS_PATH + '/:group_id/details'

export const USERS_PATH = '/users'
export const USER_CREATOR_PATH = USERS_PATH + '/creator'
export const USER_EDITOR_PATH = USERS_PATH + '/:user_id/editor'
export const USER_DETAILS_PATH = USERS_PATH + '/:user_id/details'
// #############################
// END ADMIN DASHBOARD
// #############################

// #############################
// CLIENT DASHBOARD
// #############################
const BASE_CLIENT_DASHBOARD_PATH = '/clients/:client_id'
export const CLIENT_DASHBOARD_HOME_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/dashboard'

export const CLIENT_DASHBOARD_EXTRACTIONS_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/extractions'
export const CLIENT_DASHBOARD_EXTRACTION_CREATOR_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/extractions/creator'
export const CLIENT_DASHBOARD_EXTRACTION_DETAILS_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/extractions/:extraction_id/details'
export const CLIENT_DASHBOARD_EXTRACTION_EDITOR_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/extractions/:extraction_id/editor'

export const CLIENT_DASHBOARD_CONNECTIONS_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/connections'
export const CLIENT_DASHBOARD_CONNECTION_CREATOR_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/connections/creator'
export const CLIENT_DASHBOARD_CONNECTION_DETAILS_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/connections/:connection_id/details'
export const CLIENT_DASHBOARD_CONNECTION_EDITOR_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/connections/:connection_id/editor'

export const CLIENT_DASHBOARD_SOURCES_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/sources'
export const CLIENT_DASHBOARD_SOURCE_CREATOR_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/sources/creator'
export const CLIENT_DASHBOARD_SOURCE_DETAILS_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/sources/:source_id/details'
export const CLIENT_DASHBOARD_SOURCE_EDITOR_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/sources/:source_id/editor'

export const CLIENT_DASHBOARD_CONCILIATIONS_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/conciliations'
export const CLIENT_DASHBOARD_CONCILIATION_CREATOR_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/conciliations/creator'
export const CLIENT_DASHBOARD_CONCILIATION_DETAILS_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/conciliations/:conciliation_id/details'
export const CLIENT_DASHBOARD_CONCILIATION_EDITOR_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/conciliations/:conciliation_id/editor'

export const CLIENT_DASHBOARD_DASHBOARDS_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/dashboards'
export const CLIENT_DASHBOARD_DASHBOARD_CREATOR_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/dashboards/creator'
export const CLIENT_DASHBOARD_DASHBOARD_DETAILS_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/dashboards/:dashboard_id/details'
export const CLIENT_DASHBOARD_DASHBOARD_EDITOR_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/dashboards/:dashboard_id/editor'

export const CLIENT_DASHBOARD_AUTOMATIONS_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/automations'
export const CLIENT_DASHBOARD_AUTOMATION_CREATOR_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/automations/creator'
export const CLIENT_DASHBOARD_AUTOMATION_DETAILS_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/automations/:automation_id/details'
export const CLIENT_DASHBOARD_AUTOMATION_EDITOR_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/automations/:automation_id/editor'

export const CLIENT_DASHBOARD_WORKER_CALLS_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/worker-calls'
export const CLIENT_DASHBOARD_WORKER_CALLS_CREATOR_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/worker-calls/creator'
export const CLIENT_DASHBOARD_WORKER_CALLS_DETAILS_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/worker-calls/:worker_call_id/details'
export const CLIENT_DASHBOARD_WORKER_CALLS_EDITOR_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/worker-calls/:worker_call_id/editor'

export const CLIENT_DASHBOARD_ALARMS_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/alarms'
export const CLIENT_DASHBOARD_ALARM_CREATOR_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/alarms/creator'
export const CLIENT_DASHBOARD_ALARM_DETAILS_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/alarms/:alarm_id/details'
export const CLIENT_DASHBOARD_ALARM_EDITOR_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/alarms/:alarm_id/editor'

export const CLIENT_DASHBOARD_EXECUTIONS_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/executions'
export const CLIENT_DASHBOARD_EXECUTION_CREATOR_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/executions/creator'
export const CLIENT_DASHBOARD_EXECUTION_DETAILS_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/executions/:execution_id/details'
export const CLIENT_DASHBOARD_EXECUTION_EDITOR_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/executions/:execution_id/editor'

export const CLIENT_DASHBOARD_ACCOUNTING_SEATS_DASHBOARD_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/accounting-seats'
export const CLIENT_DASHBOARD_ACCOUNTING_SEAT_DETAILS_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/accounting-seats/:accounting_seat_id/details'
export const CLIENT_DASHBOARD_ACCOUNTING_SEAT_EDITOR_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/accounting-seats/:accounting_seat_id/editor'

export const CLIENT_DASHBOARD_ACCOUNTING_SEAT_SETTINGS_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/accounting-seat-settings'
export const CLIENT_DASHBOARD_ACCOUNTING_SEAT_SETTING_CREATOR_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/accounting-seat-settings/creator'
export const CLIENT_DASHBOARD_ACCOUNTING_SEAT_SETTING_DETAILS_PATH =
  BASE_CLIENT_DASHBOARD_PATH +
  '/accounting-seat-settings/:accounting_seat_setting_id/details'
export const CLIENT_DASHBOARD_ACCOUNTING_SEAT_SETTING_EDITOR_PATH =
  BASE_CLIENT_DASHBOARD_PATH +
  '/accounting-seat-settings/:accounting_seat_setting_id/editor'

export const CLIENT_DASHBOARD_PERMISSIONS_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/permissions'
export const CLIENT_DASHBOARD_PERMISSION_CREATOR_PATH =
  CLIENT_DASHBOARD_PERMISSIONS_PATH + '/creator'
export const CLIENT_DASHBOARD_PERMISSION_EDITOR_PATH =
  CLIENT_DASHBOARD_PERMISSIONS_PATH + '/:permission_id/editor'
export const CLIENT_DASHBOARD_PERMISSION_DETAILS_PATH =
  CLIENT_DASHBOARD_PERMISSIONS_PATH + '/:permission_id/details'

export const CLIENT_DASHBOARD_GROUPS_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/groups'
export const CLIENT_DASHBOARD_GROUP_CREATOR_PATH =
  CLIENT_DASHBOARD_GROUPS_PATH + '/creator'
export const CLIENT_DASHBOARD_GROUP_EDITOR_PATH =
  CLIENT_DASHBOARD_GROUPS_PATH + '/:group_id/editor'
export const CLIENT_DASHBOARD_GROUP_DETAILS_PATH =
  CLIENT_DASHBOARD_GROUPS_PATH + '/:group_id/details'

export const CLIENT_DASHBOARD_USERS_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/users'
export const CLIENT_DASHBOARD_USER_CREATOR_PATH =
  CLIENT_DASHBOARD_USERS_PATH + '/creator'
export const CLIENT_DASHBOARD_USER_EDITOR_PATH =
  CLIENT_DASHBOARD_USERS_PATH + '/:user_id/editor'
export const CLIENT_DASHBOARD_USER_DETAILS_PATH =
  CLIENT_DASHBOARD_USERS_PATH + '/:user_id/details'

export const CLIENT_DASHBOARD_JOB_POOLS_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/jobs-pools'
export const CLIENT_DASHBOARD_JOB_POOLS_CREATOR_PATH =
  CLIENT_DASHBOARD_JOB_POOLS_PATH + '/creator'
export const CLIENT_DASHBOARD_JOB_POOLS_DETAILS_PATH =
  CLIENT_DASHBOARD_JOB_POOLS_PATH + '/:job_pool_id/details'

export const CLIENT_DASHBOARD_JOB_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/jobs'
export const CLIENT_DASHBOARD_JOB_DETAILS_PATH =
  CLIENT_DASHBOARD_JOB_PATH + '/:job_id/details'

export const CLIENT_DASHBOARD_NON_RECONCILED_ITEMS_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/non-reconciled-items'

export const CLIENT_LOGIN_PATH = '/client/login'

export const CLIENT_DASHBOARD_INCONSISTENCIES_PATH =
  BASE_CLIENT_DASHBOARD_PATH + '/inconsistencies'
// #############################
// END CLIENT DASHBOARD
// #############################

export const ACCOUNTING_HOME_PATH = '/accounting'

export const AUDIT_USERS_PATH = '/audit/users'
export const ACCESS_CONTROL_PATH = '/security/users'
export const CLIENT_CUSTOM_SETTINGS_PATH = '/settings'

export const DEPLOY_TOOL_HOME_INIT_PATH = '/deploy-tool'
export const DEPLOY_TOOL_BASE_PATH = BASE_CLIENT_DASHBOARD_PATH + '/releases'
export const DEPLOY_TOOL_CREATOR_PATH = DEPLOY_TOOL_BASE_PATH + '/creator'
export const DEPLOY_TOOL_EDITOR_PATH =
  DEPLOY_TOOL_BASE_PATH + '/:release_id/editor'
export const DEPLOY_TOOL_DETAILS_PATH =
  DEPLOY_TOOL_BASE_PATH + '/:release_id/details'
