import React, { useEffect, useState } from 'react'
import {
  InputSearchLayout,
  PageContainerLayout,
  PageHeaderLayout,
  PaginationLayout
} from '../../../layouts'
import { WorkerCallsHeaderTiles } from '../../containers/worker-calls-header-tiles'
import { connect } from 'react-redux'
import { WorkerCallsTable } from '../../containers/worker-calls-table'
import PropTypes from 'prop-types'
import { WorkerCallShape } from '../../../../prop-types/worker-calls'
import { PaginationShape } from '../../../../prop-types'
import { workerCallsOperations } from '../../../../state/ducks/worker-calls'

function WorkerCallsPage (props) {
  const [params, setParams] = useState({})

  const onSearchChange = (keyword) => {
    updateParams('search', keyword)
  }

  const onPaginationChange = (page) => {
    updateParams('page', page)
  }

  const fetchWorkerCalls = () => {
    props.fetchWorkerCalls(params)
  }

  const updateParams = (key, value) => {
    const requestParams = { ...params }
    requestParams[key] = value
    if (key === 'search') {
      params.page = 1
    }
    setParams(requestParams)
  }

  const renderHeaderPaths = () => {
    return (
      <>
        <li>Worker calls</li>
      </>
    )
  }

  useEffect(() => {
    fetchWorkerCalls()
  }, [params])

  return (
    <>
      <PageContainerLayout clientSidebar={true} showNavbarClientOptions={true}>
        <PageHeaderLayout
          title={'Worker calls'}
          subtitle={'All worker calls'}
          paths={renderHeaderPaths}
        ></PageHeaderLayout>
        {/* Page Content */}
        <div className="content content-boxed">
          {/* Header Tiles */}
          <WorkerCallsHeaderTiles
            all_worker_calls={props.paginationData.totalItems}
          />
          {/* END Header Tiles */}
          <div
            className={
              'block block-themed block-opt-refresh-icon7 ' +
              (props.isLoading ? ' block-opt-refresh ' : '')
            }
          >
            <div className="block-header bg-primary-dark">
              <ul className="block-options">
                {/* <li>
                  <button
                    type="button"
                    data-toggle="block-option"
                    data-action="fullscreen_toggle"
                  >
                    <i className="si si-size-fullscreen" />
                  </button>
                </li> */}
                <li>
                  <button
                    type="button"
                    data-toggle="block-option"
                    data-action="refresh_toggle"
                    data-action-mode="demo"
                    onClick={fetchWorkerCalls}
                  >
                    <i className="si si-refresh"></i>
                  </button>
                </li>
              </ul>
              <h3 className="block-title">All worker calls</h3>
            </div>
            <div className="block-content">
              {/* Search */}
              <InputSearchLayout onSearchChange={onSearchChange} />
              {/* End Search */}
              {/* Table */}
              <WorkerCallsTable workerCalls={props.workerCalls} params={params}>
                {/* Pagination */}
                <PaginationLayout
                  pagination={props.paginationData}
                  onPaginationChange={onPaginationChange}
                />
                {/* End Pagination */}
              </WorkerCallsTable>
              {/* End Table */}
            </div>
          </div>
        </div>
        {/* END Page Content */}
      </PageContainerLayout>
    </>
  )
}

const { arrayOf, func } = PropTypes
WorkerCallsPage.propTypes = {
  workerCalls: arrayOf(WorkerCallShape).isRequired,
  fetchWorkerCalls: func.isRequired,
  paginationData: PaginationShape
}

WorkerCallsPage.defaultProps = {
  workerCalls: [],
  paginationData: {}
}

const mapStateToProps = (state) => ({
  isLoading: state.workerCalls.fetchWorkerCallsReducer.loading,
  workerCalls: state.workerCalls.fetchWorkerCallsReducer.data.results,
  paginationData:
    state.workerCalls.fetchWorkerCallsReducer.data.pagination_data
})

const mapDispatchToProps = {
  fetchWorkerCalls: workerCallsOperations.list
}

const _WorkerCallsPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkerCallsPage)
export { _WorkerCallsPage as WorkerCallsPage }
