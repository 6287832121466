import React, { Component } from 'react'
import { PageContainerLayout, PageHeaderLayout } from '../../../layouts'
import { PermissionWizardForm } from '../../../shared/containers'
import { NavLink } from 'react-router-dom'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { PermissionShape } from '../../../../prop-types'
import * as PropTypes from 'prop-types'
import { permissionOperations } from '../../../../state/ducks/permissions'
import { connect } from 'react-redux'

class PermissionEditorPage extends Component {
  constructor (props) {
    super(props)
  }

  UNSAFE_componentWillMount () {
    this.props.getPermission(this.props.match.params.permission_id)
  }

  renderHeaderPaths () {
    return (
            <>
                <li>
                    <NavLink className="link-effect" to={ROUTING_PATHS.PERMISSIONS_PATH}>
                        Permissions
                    </NavLink>
                </li>
                <li>Edit Permission</li>
            </>
    )
  }

  render () {
    return (
            <>

                <PageContainerLayout>
                    <PageHeaderLayout
                        title={this.props.permission.codename}
                        subTitle={'Edit Permission'}
                        paths={this.renderHeaderPaths}
                    />
                    <PermissionWizardForm
                        permission={this.props.permission}
                        isEditor={true}
                    />
                </PageContainerLayout>

            </>
    )
  }
}

// Set propTypes
PermissionEditorPage.propTypes = {
  permission: PermissionShape,
  getPermissionErrorResponse: PropTypes.object
}

// Set defaultProps
PermissionEditorPage.defaultProps = {
  permission: {},
  getPermissionErrorResponse: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  permission: state.permissions.getPermissionReducer.data,
  isLoading: state.permissions.getPermissionReducer.loading,
  getPermissionErrorResponse: state.permissions.getPermissionReducer.error_data
})

// mapStateToProps
const mapDispatchToProps = {
  getPermission: permissionOperations.get
}

// Export Class
const _PermissionEditorPage = connect(mapStateToProps, mapDispatchToProps)(PermissionEditorPage)
export { _PermissionEditorPage as PermissionEditorPage }
