import React, { Component } from 'react'
import { PageContainerLayout, PageHeaderLayout } from '../../../layouts'
import { NavLink } from 'react-router-dom'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { AccountingSeatSettingsWizardForm } from '../../containers'
import { Session } from '../../../../state/utils/session'

class AccountingSeatSettingCreatorPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }

    this.renderHeaderPaths = this.renderHeaderPaths.bind(this)
  }

  renderHeaderPaths () {
    return (
            <>
                <li>
                    <NavLink className="link-effect"
                             to={ROUTING_PATHS.CLIENT_DASHBOARD_ACCOUNTING_SEAT_SETTINGS_PATH.replace(':client_id', this.state.session.getClientSession())}>
                        Accounting Seat Settings
                    </NavLink>
                </li>
                <li>New Setting</li>
            </>
    )
  }

  render () {
    return (
            <>

                <PageContainerLayout clientSidebar={true} showNavbarClientOptions={true}>
                    <PageHeaderLayout
                        title={'New Accounting Seat Setting'}
                        subTitle={'Create a new accounting seat setting'}
                        paths={this.renderHeaderPaths}
                    />
                    <AccountingSeatSettingsWizardForm/>
                </PageContainerLayout>
            </>
    )
  }
}

export { AccountingSeatSettingCreatorPage }
