import React, { Component } from 'react'

class TableTheadLayout extends Component {
  constructor (props) {
    super(props)

    // State
    this.state = {
      checkedAllRows: false
    }

    // Functions
    this.handleCheckAll = this.handleCheckAll.bind(this)
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.allTransactionsSelected !== this.props.allTransactionsSelected) {
      this.setState({
        checkedAllRows: nextProps.allTransactionsSelected
      }, () => {
        this.props.onAllTransactionsSelected(this.state.checkedAllRows)
      })
    }
  }

  handleCheckAll () {
    this.setState({
      checkedAllRows: !this.state.checkedAllRows
    }, () => {
      this.props.onAllTransactionsSelected(this.state.checkedAllRows)
    })
  }

  render () {
    return (
            <>
                <thead>
                <tr>
                    <th className="text-center checkbox-row">
                        <label className="css-input css-checkbox css-checkbox-default">
                            <input type="checkbox"
                                   id="check-all"
                                   name="check_all"
                                   onChange={this.handleCheckAll}
                                   checked={this.state.checkedAllRows}
                            />
                            <span/>
                        </label>
                    </th>
                    {
                        this.props.headers.forEach((key, index) => {
                          if (key !== 'checked') {
                            return (
                                    <th className="text-center" key={index}>
                                        {key}
                                    </th>
                            )
                          }
                        })
                    }
                </tr>
                </thead>
            </>
    )
  }
}

// Set defaultProps
TableTheadLayout.defaultProps = {
  headers: [],
  allTransactionsSelected: false,
  onAllTransactionsSelected: () => {

  }
}

export { TableTheadLayout }
