import React, { Component } from 'react'
import { PageContainerLayout, PageHeaderLayout } from '../../../layouts'
import { ConciliationWizardForm } from '../../containers/conciliation-wizard-form'
import { NavLink } from 'react-router-dom'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import { ConciliationShape } from '../../../../prop-types'
import * as PropTypes from 'prop-types'
import { conciliationOperations } from '../../../../state/ducks/conciliations'
import { connect } from 'react-redux'
import { Session } from '../../../../state/utils/session'

class ConciliationEditorPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }

    this.renderHeaderPaths = this.renderHeaderPaths.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.props.getConciliation(this.props.match.params.conciliation_id)
  }

  renderHeaderPaths () {
    return (
            <>
                <li>
                    <NavLink className="link-effect"
                             to={ROUTING_PATHS.CLIENT_DASHBOARD_CONCILIATIONS_PATH.replace(':client_id', this.state.session.getClientSession())}
                    >
                        Conciliations
                    </NavLink>
                </li>
                <li>Edit Conciliation</li>
            </>
    )
  }

  render () {
    return (
            <>
                <PageContainerLayout clientSidebar={true} showNavbarClientOptions={true}>
                    <PageHeaderLayout
                        title={this.props.conciliation.name}
                        subTitle={'Edit Conciliation'}
                        paths={this.renderHeaderPaths}
                    />
                    <ConciliationWizardForm
                        conciliation={this.props.conciliation}
                        isEditor={true}
                    />
                </PageContainerLayout>
            </>
    )
  }
}

// Set propTypes
ConciliationEditorPage.propTypes = {
  conciliation: ConciliationShape,
  getConciliationErrorResponse: PropTypes.object
}

// Set defaultProps
ConciliationEditorPage.defaultProps = {
  conciliation: {},
  getConciliationErrorResponse: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  conciliation: state.conciliations.getConciliationReducer.data,
  isLoading: state.conciliations.getConciliationReducer.loading,
  getConciliationErrorResponse: state.conciliations.getConciliationReducer.error_data
})

// mapStateToProps
const mapDispatchToProps = {
  getConciliation: conciliationOperations.get
}

// Export Class
const _ConciliationEditorPage = connect(mapStateToProps, mapDispatchToProps)(ConciliationEditorPage)
export { _ConciliationEditorPage as ConciliationEditorPage }
