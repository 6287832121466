import React from 'react'
import * as PropTypes from 'prop-types'
import { columnOperations } from '../../../../state/ducks/columns'
import { connect } from 'react-redux'
import SelectSearchLayout from '../../../layouts/select-search'
import { Table } from 'react-bootstrap'
import Select from 'react-select'

const initialState = {
  name: '',
  partition_keys: [],
  order_keys: [],
  where_clause: null,
  where_key: null
}

class SourceUniquenessModalForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      uniqueness: initialState,
      show: true
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleChangeWhere = this.handleChangeWhere.bind(this)
    this.returnColumnOptions = this.returnColumnOptions.bind(this)
    this.onColumnSearchChange = this.onColumnSearchChange.bind(this)
    this.onSelectPartitionKey = this.onSelectPartitionKey.bind(this)
    this.onSelectOriginKey = this.onSelectOriginKey.bind(this)
    this.removePartitionKey = this.removePartitionKey.bind(this)
    this.removeOrderKey = this.removeOrderKey.bind(this)
    this.sortHandler = this.sortHandler.bind(this)
    this.dataOptionsWhere = this.dataOptionsWhere.bind(this)
  }

  componentDidMount () {
    this.dataOptionsWhere()
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    console.log('SourceUniquenessModalForm nextProps:', nextProps)
    this.setState(
      {
        uniqueness: nextProps.uniqueness
      },
      () => {
        // console.log("SourceUniquenessModalForm state:", this.state)
      }
    )
  }

  handleChange (event) {
    const uniqueness = this.state.uniqueness
    uniqueness.name = event.target.value
    this.setState(
      {
        uniqueness
      },
      () => {
        this.props.onChange(uniqueness)
      }
    )
  }

  handleChangeWhere = async (event) => {
    const uniqueness = this.state.uniqueness
    uniqueness.where_clause = event.target.value
    this.setState(
      {
        uniqueness
      },
      () => {
        this.props.onChange(uniqueness)
      }
    )
  }

  onChangeSelectWhere (selectData) {
    const uniqueness = this.state.uniqueness
    uniqueness.where_key = selectData.value
    this.setState(
      {
        uniqueness,
        show: false
      },
      () => {
        this.props.onChange(uniqueness)
      }
    )
  }

  returnColumnOptions () {
    const options = []
    this.props.columns_list.forEach((element) => {
      options.push({
        value: element.id,
        label: element.column_name
      })
    })
    return options
  }

  onColumnSearchChange (keyword) {
    this.returnColumnOptions()
    this.props.fetchColumns({ search: keyword }, this.props.source_id)
  }

  onSelectPartitionKey (item) {
    const uniqueness = this.state.uniqueness
    uniqueness.partition_keys = uniqueness.partition_keys
      ? uniqueness.partition_keys
      : []
    uniqueness.partition_keys.push({
      column: {
        id: item.value,
        column_name: item.label
      }
    })
    this.setState(
      {
        uniqueness
      },
      () => {
        this.props.onChange(uniqueness)
      }
    )
  }

  dataOptionsWhere () {
    if (this.state.uniqueness.partition_keys) {
      const temp = this.state.uniqueness.partition_keys.map((value) => ({
        value: value.column.column_name,
        label: value.column.column_name,
        id: value.id,
        source: value.column.source
      }))
      return temp
    }
  }

  onSelectOriginKey (item) {
    const uniqueness = this.state.uniqueness
    uniqueness.order_keys = uniqueness.order_keys ? uniqueness.order_keys : []
    uniqueness.order_keys.push({
      column: {
        id: item.value,
        column_name: item.label
      },
      position: uniqueness.order_keys.length + 1,
      sort: 1
    })
    this.setState(
      {
        uniqueness
      },
      () => {
        this.props.onChange(uniqueness)
      }
    )
  }

  removePartitionKey (index) {
    const uniqueness = this.state.uniqueness
    uniqueness.partition_keys.splice(index, 1)
    this.setState(
      {
        uniqueness
      },
      () => {
        this.props.onChange(uniqueness)
      }
    )
  }

  removeOrderKey (index) {
    const uniqueness = this.state.uniqueness
    uniqueness.order_keys.splice(index, 1)
    this.setState(
      {
        uniqueness
      },
      () => {
        this.props.onChange(uniqueness)
      }
    )
  }

  sortHandler (index, event) {
    const uniqueness = this.state.uniqueness
    uniqueness.order_keys[index].sort = event.target.value
    this.setState(
      {
        uniqueness
      },
      () => {
        this.props.onChange(uniqueness)
      }
    )
  }

  render () {
    return (
      <>
        <div className="form-group">
          <div
            className="col-sm-10 col-sm-offset-1"
            style={{ minHeight: '70px' }}
          >
            <div className="form-material">
              <input
                className="form-control"
                type="text"
                id="input-name"
                placeholder="Please enter a name"
                name="name"
                value={this.state.uniqueness.name || ''}
                onChange={this.handleChange}
              />
              <label htmlFor="input-name">Name</label>
            </div>
          </div>
          <div className="col-sm-10 col-sm-offset-1">
            <div className="form-material">
              <SelectSearchLayout
                isInModal
                placeholder={'Search'}
                getOptions={this.returnColumnOptions}
                onSearchChange={this.onColumnSearchChange}
                onSelectItem={this.onSelectPartitionKey}
              />
              <label htmlFor="input-name">Partition Keys</label>
            </div>
          </div>
          <div
            className="col-sm-10 col-sm-offset-1"
            style={{ minHeight: '70px' }}
          >
            <Table
              responsive
              striped
              bordered
              hover
              size="sm"
              className="text-center mb-3"
            >
              <thead>
                <tr>
                  <th className="text-center">Column Name</th>
                  <th className="text-center">Options</th>
                </tr>
              </thead>
              <tbody>
                {this.state.uniqueness.partition_keys
                  ? this.state.uniqueness.partition_keys.map((pk, index) => {
                    return (
                        <tr key={index}>
                          <td>{pk.column.column_name}</td>
                          <td className="text-center text-danger">
                            <i
                              className="fa fa-trash cursor-pointer"
                              onClick={this.removePartitionKey.bind(
                                this,
                                index
                              )}
                            />
                          </td>
                        </tr>
                    )
                  })
                  : null}
              </tbody>
            </Table>
          </div>
          <div className="col-sm-10 col-sm-offset-1">
            <div className="form-material">
              <SelectSearchLayout
                isInModal
                placeholder={'Search'}
                getOptions={this.returnColumnOptions}
                onSearchChange={this.onColumnSearchChange}
                onSelectItem={this.onSelectOriginKey}
              />
              <label htmlFor="input-name">Order Keys</label>
            </div>
          </div>
          <div
            className="col-sm-10 col-sm-offset-1"
            style={{ minHeight: '70px' }}
          >
            <Table
              responsive
              striped
              bordered
              hover
              size="sm"
              className="text-center mb-3"
            >
              <thead>
                <tr>
                  <th className="text-center">Column Name</th>
                  <th className="text-center">Sort</th>
                  <th className="text-center">Options</th>
                </tr>
              </thead>
              <tbody>
                {this.state.uniqueness.order_keys
                  ? this.state.uniqueness.order_keys.map((pk, index) => {
                    return (
                        <tr key={index}>
                          <td>{pk.column.column_name}</td>
                          <td>
                            <select
                              className="bg-white text-dark"
                              name="sort"
                              value={pk.sort}
                              onChange={this.sortHandler.bind(this, index)}
                            >
                              <option value={'1'}>ASC</option>
                              <option value={'-1'}>DES</option>
                            </select>
                          </td>
                          <td className="text-center text-danger">
                            <i
                              className="fa fa-trash cursor-pointer"
                              onClick={this.removeOrderKey.bind(this, index)}
                            />
                          </td>
                        </tr>
                    )
                  })
                  : null}
              </tbody>
            </Table>

            <div className="form-material" style={{ marginBottom: '50px' }}>
              <Select
                className="input-space"
                options={this.dataOptionsWhere()}
                onChange={(data) => this.onChangeSelectWhere(data)}
                placeholder="Please select a column"
              />
              <label htmlFor="input-name">Where key </label>
            </div>

            {this.state.show ? (
              <div className="form-material">
                <input
                  className="form-control"
                  type="text"
                  id="input-name"
                  placeholder="Please enter the name of where clause"
                  name="name"
                  value={this.state.uniqueness.where_clause || ''}
                  onChange={this.handleChangeWhere}
                />
                <label htmlFor="input-name">Where clause</label>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        {/* <pre className="pre-sh push"> */}
        {/*    MODAL FORM */}
        {/*    <code className="json"> */}
        {/*    {jsonPrettify(this.state.uniqueness)} */}
        {/*    </code> */}
        {/* </pre> */}
      </>
    )
  }
}

// Set propTypes
SourceUniquenessModalForm.propTypes = {
  source_id: PropTypes.number,
  uniqueness: PropTypes.object,
  onChange: PropTypes.func,
  fetchColumns: PropTypes.func.isRequired
}

// Set defaultProps
SourceUniquenessModalForm.defaultProps = {
  source_id: null,
  uniqueness: {},
  onChange: () => {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  columns_list: state.columns.fetchColumnsReducer.data.results
})

// mapStateToProps
const mapDispatchToProps = {
  fetchColumns: columnOperations.list
}

// Export Class
const _SourceUniquenessModalForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(SourceUniquenessModalForm)
export { _SourceUniquenessModalForm as SourceUniquenessModalForm }
