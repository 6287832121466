import { combineReducers } from 'redux'
import * as types from './types'

const fetchConciliationExecutionLogsInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { results: [], pagination_data: {} }
}

const getConciliationExecutionLogInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { columns: [] }
}

const saveConciliationExecutionLogInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

const updateConciliationExecutionLogInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

export function fetchConciliationExecutionLogsReducer (state = fetchConciliationExecutionLogsInitialState, action) {
  switch (action.type) {
    case types.FETCH_CONCILIATION_EXECUTION_LOGS_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.FETCH_CONCILIATION_EXECUTION_LOGS_REQUEST_FAILURE:
      return {
        ...fetchConciliationExecutionLogsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.FETCH_CONCILIATION_EXECUTION_LOGS_REQUEST_SUCCESS:
      return {
        ...fetchConciliationExecutionLogsInitialState,
        data: action.payload.data
      }
    case types.FETCH_CONCILIATION_EXECUTION_LOGS_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function getConciliationExecutionLogReducer (state = getConciliationExecutionLogInitialState, action) {
  switch (action.type) {
    case types.GET_CONCILIATION_EXECUTION_LOG_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.GET_CONCILIATION_EXECUTION_LOG_REQUEST_FAILURE:
      return {
        ...getConciliationExecutionLogInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.GET_CONCILIATION_EXECUTION_LOG_REQUEST_SUCCESS:
      return {
        ...getConciliationExecutionLogInitialState,
        data: action.payload.data
      }
    case types.GET_CONCILIATION_EXECUTION_LOG_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function saveConciliationExecutionLogReducer (state = saveConciliationExecutionLogInitialState, action) {
  switch (action.type) {
    case types.SAVE_CONCILIATION_EXECUTION_LOG_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.SAVE_CONCILIATION_EXECUTION_LOG_REQUEST_FAILURE:
      return {
        ...fetchConciliationExecutionLogsInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.SAVE_CONCILIATION_EXECUTION_LOG_REQUEST_SUCCESS:
      return {
        ...fetchConciliationExecutionLogsInitialState,
        data: action.payload.data
      }
    case types.SAVE_CONCILIATION_EXECUTION_LOG_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function updateConciliationExecutionLogReducer (state = updateConciliationExecutionLogInitialState, action) {
  switch (action.type) {
    case types.UPDATE_CONCILIATION_EXECUTION_LOG_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.UPDATE_CONCILIATION_EXECUTION_LOG_REQUEST_FAILURE:
      return {
        ...updateConciliationExecutionLogInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.UPDATE_CONCILIATION_EXECUTION_LOG_REQUEST_SUCCESS:
      return {
        ...updateConciliationExecutionLogInitialState,
        data: action.payload.data
      }
    case types.UPDATE_CONCILIATION_EXECUTION_LOG_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export default combineReducers({
  fetchConciliationExecutionLogsReducer,
  saveConciliationExecutionLogReducer,
  getConciliationExecutionLogReducer,
  updateConciliationExecutionLogReducer
})
