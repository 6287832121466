import React from 'react'
import SelectSearchLayout from '../../../layouts/select-search'
import { Button } from 'react-bootstrap'
import * as PropTypes from 'prop-types'
import { workerCallsOperations } from '../../../../state/ducks/worker-calls'
import { connect } from 'react-redux'

function AutomationWorkerCallsForm (props) {
  const returnOptions = () => {
    const options = []
    props.worker_calls_list.forEach((element) => {
      const groupAdded = props.automation.worker_calls.find(
        (workerCall) => workerCall.toString() === element.id.toString()
      )
      if (!groupAdded) {
        options.push({
          value: `${element.id}|${element.name}`,
          label: `${element.name}(${element.id})`
        })
      }
    })
    return options
  }

  const onWorkerCallSearchKeywordChange = (keyword) => {
    returnOptions()
    props.fetchWorkerCalls({ search: keyword })
  }

  const onSelectWorkerCall = (item) => {
    const addedWorkerCalls = props.automation.worker_calls
    const split_ = item.value.split('|')
    addedWorkerCalls.push({
      id: split_[0],
      name: split_[1]
    })
    props.onChange('worker_calls', addedWorkerCalls)
  }

  const removeWorkerCall = (index) => {
    const addedWorkerCalls = props.automation.worker_calls
    addedWorkerCalls.splice(index, 1)
    props.onChange('worker_calls', addedWorkerCalls)
  }

  const renderWorkerCalls = () => (
    <>
      <table className="table">
        <tbody>
          {props.automation.worker_calls.map((workerCall, index) => (
            <tr key={index} className="border">
              <td style={{ width: '20px' }}>
                <i className="fa fa-th fa-2x"></i>
              </td>
              <td>
                <small className="font-w400">
                  {workerCall.name} ({workerCall.id})
                </small>
                <div className="font-w400 text-muted">
                  <small>---</small>
                </div>
              </td>
              <td style={{ width: '20px' }}>
                <Button
                  variant="danger"
                  className="float-right"
                  onClick={() => removeWorkerCall(index)}
                >
                  <i className="fa fa-trash" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )

  return (
    <>
      <div className="form-group">
        <div className="col-sm-6 col-sm-offset-3">
          <div className="form-material">
            <SelectSearchLayout
              getOptions={returnOptions}
              onSearchChange={onWorkerCallSearchKeywordChange}
              onSelectItem={onSelectWorkerCall}
            />
            <label htmlFor="input-groups">Search worker calls</label>
          </div>
          {renderWorkerCalls()}
        </div>
      </div>
    </>
  )
}

AutomationWorkerCallsForm.propTypes = {
  worker_calls_list: PropTypes.array.isRequired,
  fetchWorkerCalls: PropTypes.func.isRequired
}

AutomationWorkerCallsForm.defaultProps = {
  worker_calls_list: [],
  automation: [],
  onChange: () => {}
}

const mapStateToProps = (state) => ({
  worker_calls_list: state.workerCalls.fetchWorkerCallsReducer.data.results
})

const mapDispatchToProps = {
  fetchWorkerCalls: workerCallsOperations.list
}

const _AutomationWorkerCallsForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(AutomationWorkerCallsForm)

export { _AutomationWorkerCallsForm as AutomationWorkerCallsForm }
