import React, { Component } from 'react'
import { PageContainerLayout } from '../../../layouts'
import { connect } from 'react-redux'
import * as userOperations from '../../../../state/ducks/users/actions'
import { AuditUsersPage } from '../../../client-dashboard/pages/audit-logs/users'

class UserManagerPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      params: {}
    }
  }

  fetchUsers () {
    this.props.fetchUsers(this.state.params)
  }

  componentDidMount () {
    this.fetchUsers()
  }

  render () {
    return (
            <>
                <PageContainerLayout
                    clientSidebar={true}
                    showNavbarClientOptions={true}
                >
                   <AuditUsersPage users={this.props.users} paginationData={this.props.paginationData}/>
                </PageContainerLayout>
            </>
    )
  };
}

// Set propTypes
UserManagerPage.propTypes = {}

// Set defaultProps
UserManagerPage.defaultProps = {}

const mapStateToProps = (state) => ({
  isLoading: state.users.fetchUsersReducer.loading,
  users: state.users.fetchUsersReducer.data.results,
  paginationData: state.users.fetchUsersReducer.data.pagination_data
})

// mapStateToProps
const mapDispatchToProps = {
  fetchUsers: userOperations.list
}

// Export Class
const AuditLogsPage_ = connect(mapStateToProps, mapDispatchToProps)(UserManagerPage)
export { AuditLogsPage_ as UserManagerPage }
