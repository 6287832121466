import React, { Component } from 'react'
import { PageContainerLayout, PageHeaderLayout } from '../../../layouts'
import { NavLink } from 'react-router-dom'
import * as ROUTING_PATHS from '../../../../routing/Paths'
import * as PropTypes from 'prop-types'
// import {accountingSeatOperations} from "../../../../state/ducks/accounting-seat-settings";
import { connect } from 'react-redux'
import { Session } from '../../../../state/utils/session'
import { AccountingSeatDetailsCard, AccountingSeatTimelineCard } from '../../containers'

class AccountingSeatDetailsPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }

    this.renderHeaderPaths = this.renderHeaderPaths.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.props.getAccountingSeat(this.props.match.params.accountingSeat_id)
  }

  renderHeaderPaths () {
    return (
            <>
                <li>
                    <NavLink className="link-effect"
                             to={ROUTING_PATHS.CLIENT_DASHBOARD_ACCOUNTING_SEATS_DASHBOARD_PATH.replace(':client_id', this.state.session.getClientSession())}
                    >
                        Accounting Seat Settings
                    </NavLink>
                </li>
                <li>Setting Details</li>
            </>
    )
  }

  render () {
    return (
            <>
                <PageContainerLayout clientSidebar={true} showNavbarClientOptions={true}>
                    <PageHeaderLayout
                        title={this.props.accountingSeat.name}
                        subTitle={'Accounting Seat Settings'}
                        paths={this.renderHeaderPaths}
                    />
                    <div className="content content-boxed">
                        <div className="row">
                            <div className="col-sm-12 col-lg-5">
                                {/* Details */}
                                <AccountingSeatDetailsCard accountingSeat={this.props.accountingSeat}/>
                                {/* END Details */}
                            </div>
                            <div className="col-sm-12 col-lg-7">
                                {/* Timeline */}
                                <AccountingSeatTimelineCard accountingSeat={this.props.accountingSeat}/>
                                {/* END Timeline */}
                            </div>
                        </div>
                    </div>
                </PageContainerLayout>
            </>
    )
  }
}

// Set propTypes
AccountingSeatDetailsPage.propTypes = {
  accountingSeat: PropTypes.object,
  getAccountingSeatErrorResponse: PropTypes.object
}

// Set defaultProps
AccountingSeatDetailsPage.defaultProps = {
  accountingSeat: {},
  getAccountingSeatErrorResponse: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  accountingSeat: state.accountingSeats.getAccountingSeatReducer.data,
  isLoading: state.accountingSeats.getAccountingSeatReducer.loading,
  getAccountingSeatErrorResponse: state.accountingSeats.getAccountingSeatReducer.error_data
})

// mapStateToProps
const mapDispatchToProps = {
  // getAccountingSeat: accountingSeatOperations.get
}

// Export Class
const _AccountingSeatDetailsPage = connect(mapStateToProps, mapDispatchToProps)(AccountingSeatDetailsPage)
export { _AccountingSeatDetailsPage as AccountingSeatDetailsPage }
