import React, { Component } from 'react'
import TimeAgo from 'react-timeago'
import frenchStrings from 'react-timeago/lib/language-strings/en'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import { Session } from '../../../state/utils/session'
import * as PATHS from '../../../routing/Paths'
import { history } from '../../../routing/History'

const formatter = buildFormatter(frenchStrings)

class LastExecutionLogsListLayout extends Component {
  constructor (props) {
    super(props)

    this.state = {
      session: new Session()
    }

    this.returnLink = this.returnLink.bind(this)
    this.handleFetch = this.handleFetch.bind(this)
    this.goToObject = this.goToObject.bind(this)
  }

  handleFetch () {
    this.props.fetch()
  }

  returnCircleStatus (status) {
    switch (status) {
      case 'COMPLETED':
        return (
                    <i className="fa fa-circle text-success"></i>
        )
      case 'PENDING':
      case 'RUNNING':
        return (
                    <i className="fa fa-circle text-warning"></i>
        )
      case 'FAILED':
      case 'CANCELLED':
        return (
                    <i className="fa fa-circle text-danger"></i>
        )
      default:
        return (
                    <i className="fa fa-circle text-default">{status}</i>
        )
    }
  }

  getDate (date) {
    const finalDate = new Date(date)
    finalDate.setHours(finalDate.getHours() - 5)
    return finalDate
  }

  goToObject (client, path) {
    const session = new Session()
    session.setClientSession(client)
    history.push(path)
  }

  returnLink (object) {
    if (object.client) {
      switch (this.props.model) {
        case 'source':
          return (
                        <span className="cursor-pointer" onClick={this.goToObject.bind(
                          this,
                          object.client,
                          PATHS.CLIENT_DASHBOARD_SOURCE_DETAILS_PATH
                            .replace(':client_id', object.client.id)
                            .replace(':source_id', object.source)
                        )}>
                        {object.name}
                    </span>
          )
        case 'conciliation':
          return (
                        <span className="cursor-pointer" onClick={this.goToObject.bind(
                          this,
                          object.client,
                          PATHS.CLIENT_DASHBOARD_CONCILIATION_DETAILS_PATH
                            .replace(':client_id', object.client.id)
                            .replace(':conciliation_id', object.conciliation)
                        )}>
                        {object.name}
                    </span>
          )
        case 'alarm':
          return (
                        <span className="cursor-pointer" onClick={this.goToObject.bind(
                          this,
                          object.client,
                          PATHS.CLIENT_DASHBOARD_ALARM_DETAILS_PATH
                            .replace(':client_id', object.client.id)
                            .replace(':alarm_id', object.alarm)
                        )}>
                        {object.name}
                    </span>
          )
        default:
          return <></>
      }
    }
  }

  returnLastSourceExecutionLogs () {
    return (
      this.props.execution_logs
        ? this.props.execution_logs.map((e, index) => {
          return (
                        <li key={index}>
                            {this.returnCircleStatus(e.status)}
                            <div className="font-w600">
                                {this.returnLink(e)}
                                <small className="text-muted pull-right" style={{ marginRight: '10px' }}>
                                    <TimeAgo date={this.getDate(e.created_at)} formatter={formatter}/>
                                </small>
                            </div>
                            <div>
                                {
                                    e.client
                                      ? <div className="text-muted">
                                            <small>Client: {e.client.name}</small>
                                        </div>
                                      : null
                                }
                                {
                                    e.user
                                      ? <div className="text-muted">
                                            <small>Client: {e.user.full_name}</small>
                                        </div>
                                      : null
                                }
                            </div>
                        </li>
          )
        })
        : null
    )
  }

  render () {
    return (
            <>
                {/* Activity List */}
                <div className={'block block-themed' + (this.props.isLoading ? ' block-opt-refresh ' : '')}>
                    <div className="block-header bg-primary-dark">
                        <ul className="block-options">
                            <li>
                                <button
                                    type="button"
                                    data-toggle="block-option"
                                    data-action-mode="demo"
                                    onClick={this.handleFetch}
                                >
                                    <i className="si si-refresh"/>
                                </button>
                            </li>
                        </ul>
                        <h3 className="block-title">Last {this.props.title} Executions</h3>
                    </div>
                    <div className="block-content" style={{ height: '300px', overflowY: 'scroll' }}>
                        <ul className="list list-activity pull-r-l">
                            {this.returnLastSourceExecutionLogs()}
                        </ul>
                    </div>
                </div>
                {/* END Activity List */}
            </>
    )
  }
}

// Set defaultProps
LastExecutionLogsListLayout.defaultProps = {
  title: '',
  model: '',
  fetch: () => {
  },
  isLoading: false,
  execution_logs: []
}

// Export Class
export { LastExecutionLogsListLayout }
