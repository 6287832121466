import React, { Component } from 'react'
import { connect } from 'react-redux'
import { authenticationEndpoints, authenticationOperations } from '../../../../state/ducks/authentication'
import { Session } from '../../../../state/utils/session'

// Class
class VerifySecurityCodeForm extends Component {
  constructor (props) {
    super(props)

    // state
    this.state = {
      security_code: '',
      session: new Session()
    }

    // handlers
    this.handleChange = this.handleChange.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.resendSecurityCode = this.resendSecurityCode.bind(this)
    this.logout = this.logout.bind(this)
  }

  handleChange (event) {
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.handleSubmit()
    }
  }

  handleSubmit = () => {
    this.props.verifySecurityCode(
      authenticationEndpoints.VERIFY_SECURITY_CODE,
      { security_code: this.state.security_code }
    )
  }

  resendSecurityCode = () => {
    this.props.resendSecurityCode(
      authenticationEndpoints.RESEND_SECURITY_CODE
    )
  }

  logout () {
    this.state.session.removeSession()
  }

  render () {
    return (
            <>
                <div className=" block block-themed block-opt-refresh-icon7 animated fadeIn">
                    <div className="block-header bg-primary">
                        <ul className="block-options">
                            <li>
                                <span className="cursor-pointer" onClick={this.logout}>
                                    Logout
                                </span>
                            </li>
                        </ul>
                        <h3 className="block-title">Security Action Required</h3>
                    </div>
                    <div className="block-content block-content-full block-content-narrow">
                        {/* Title */}
                        <h1 className="h2 font-w600 push-30-t push-5">Simetrik</h1>
                        <p>We have sent to your email a security code to confirm your authentication.</p>
                        {/* END Title */}

                        <form className="js-validation-login form-horizontal push-30-t push-50"
                              action="javascript:void(0)" method="post">
                            <div className="form-group">
                                <div className="col-xs-12">
                                    <div className="form-material form-material-primary floating">
                                        <input
                                            className="form-control"
                                            id="login-security-code"
                                            type="text"
                                            name="security_code"
                                            value={this.state.security_code}
                                            onKeyPress={this.handleKeyPress}
                                            onChange={this.handleChange}
                                        />
                                        <label htmlFor="login-security-code">Security Code</label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="col-xs-12 col-sm-6 col-md-5">
                                    <button
                                        className="btn btn-block btn-primary"
                                        type="button"
                                        disabled={this.props.authenticationReducers.verifySecurityCodeReducer.loading}
                                        onClick={this.handleSubmit}
                                    >
                                        {
                                            this.props.authenticationReducers.verifySecurityCodeReducer.loading
                                              ? <i className="fa fa-spinner fa-spin"></i>
                                              : <>
                                                    <i className="ml-1 si si-login pull-right"></i>
                                                    <span>Continue</span>
                                                </>
                                        }
                                    </button>
                                </div>
                                <div className="col-xs-12 col-sm-6 col-md-7">
                                    {
                                        this.props.authenticationReducers.verifySecurityCodeReducer.error_data.error
                                          ? <small
                                                className="mt-2">{this.props.authenticationReducers.verifySecurityCodeReducer.error_data.error}</small>
                                          : null
                                    }
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12">
                                    <br />
                                    <small className="cursor-pointer" onClick={this.resendSecurityCode}>
                                        {
                                            this.props.authenticationReducers.resendSecurityCodeReducer.loading
                                              ? <i className="fa fa-spinner fa-spin"></i>
                                              : <span>Resend Security Code</span>
                                        }
                                    </small>
                                </div>
                            </div>
                        </form>
                        {/* END Form */}
                    </div>
                </div>
            </>
    )
  };
}

// Set propTypes
VerifySecurityCodeForm.propTypes = {}

// Set defaultProps
VerifySecurityCodeForm.defaultProps = {}

// Set prefetch
VerifySecurityCodeForm.prefetch = null

// mapStateToProps
const mapStateToProps = (state) => ({
  authenticationReducers: state.authentication
})

// mapStateToProps
const mapDispatchToProps = {
  verifySecurityCode: authenticationOperations.verifySecurityCode,
  resendSecurityCode: authenticationOperations.resendSecurityCode
}

// Export Class
const _VerifySecurityCodeForm = connect(mapStateToProps, mapDispatchToProps)(VerifySecurityCodeForm)
export { _VerifySecurityCodeForm as VerifySecurityCodeForm }
