import React, { Component } from 'react'
import { PageContainerLayout, PageHeaderLayout, PaginationLayout } from '../../../layouts'
import { JobsTable } from '../../containers'
import { JobFilter } from './components/JobFilter'
import { jobsOperations } from '../../../../state/ducks/jobs'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { JobsShape, PaginationShape } from '../../../../prop-types'
import { types, statuses } from './components/Models'
import moment from 'moment'
import 'react-dates/initialize'
import { DateRangePicker } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'

class JobsPage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      params: {},
      filters: [
        { name: 'name', query: 'name__contains', values: '' },
        { name: 'type', query: 'type', values: null, selectedOption: null },
        { name: 'state', query: 'state', values: null, selectedOption: null },
        { name: 'created_min', query: 'created__gt', values: null, selectedOption: null },
        { name: 'created_max', query: 'created__lt', values: null, selectedOption: null },
        { name: 'updated_min', query: 'updated__gt', values: null, selectedOption: null },
        { name: 'updated_max', query: 'updated__lt', values: null, selectedOption: null }
      ],
      name_input: '',
      startDate: null,
      endDate: null,
      updatedDateMin: null,
      updatedDateMax: null,
      minDate: null,
      maxDate: null,
      focusedInput: null,
      updatedFocusedInput: null
    }
    this.fetchJobs = this.fetchJobs.bind(this)
    this.onPaginationChange = this.onPaginationChange.bind(this)
  }

  UNSAFE_componentWillMount () {
    this.fetchJobs()
  }

  fetchJobs (params) {
    this.props.fetchJobs(params)
  }

  onPaginationChange (page) {
    const { params } = this.state
    params.page = page
    this.setState({
      params
    }, () => this.fetchJobs(params))
  }

  applyFilters (e) {
    e.preventDefault()
    const toApply = []
    this.state.filters.forEach(filter => {
      if (filter.values != null && filter.values !== '') {
        toApply.push(filter)
      }
    })
    const params = {}
    toApply.forEach(filter => {
      const query = filter.query
      const values = filter.values
      params[query] = values
    })
    this.setState({
      params
    }, () => this.fetchJobs(params))
  }

  clearFilters () {
    this.setState({
      filters: [
        { name: 'name', query: 'name__contains', values: '' },
        { name: 'type', query: 'type', values: null, selectedOption: null },
        { name: 'state', query: 'state', values: null, selectedOption: null },
        { name: 'created_min', query: 'created__gte', values: null, selectedOption: null },
        { name: 'created_max', query: 'created__lte', values: null, selectedOption: null },
        { name: 'updated_min', query: 'updated__gte', values: null, selectedOption: null },
        { name: 'updated_max', query: 'updated__lte', values: null, selectedOption: null }
      ],
      name_input: '',
      startDate: null,
      endDate: null,
      updatedDateMin: null,
      updatedDateMax: null,
      minDate: moment('2019-11-01', 'YYYY-MM-DD'),
      updatedDateMinDate: moment('2019-11-01', 'YYYY-MM-DD'),
      focusedInput: null,
      updatedFocusedInput: null,
      selectedEntries: [],
      activeOperation: null
    })

    this.fetchJobs()
  }

  handleInputChange (event) {
    event.preventDefault()
    if (event.target.value !== null) {
      const filters = this.state.filters
      const a = event.target.value.trim().split(',')
      const v = a
        .map(function (elem) {
          return elem
        })
        .join(',')
      filters[0].values = v
      this.setState({ filters, name_input: v })
    }
  }

  renderHeaderPaths () {
    return (
            <>
                <li>Jobs</li>
            </>
    )
  }

  render () {
    return (
            <>
                <PageContainerLayout clientSidebar={true} showNavbarClientOptions={true}>
                    <PageHeaderLayout title={'Jobs'} subTitle={'All Jobs'}
                                      paths={this.renderHeaderPaths}/>
                    {/* Page Content */}
                    <div className="content content-boxed jobs">
                        <div className={' block block-themed block-opt-refresh-icon7 ' + (this.props.isLoading ? ' block-opt-refresh ' : '')}>
                            <div className="block-content">
                                {/* Search */}
                                <div className="accounting-filters">
                                    <div className="flex-row">
                                        <div className="filter form-material">
                                            <h2 className="filter-label">Name</h2>
                                            <input
                                            value={this.state.name_input}
                                            className="simetrik_input"
                                            type="text"
                                            name="name"
                                            placeholder={'Search by name...'}
                                            onChange={this.handleInputChange.bind(this)}
                                            />
                                        </div>
                                        <div className="filter form-material">
                                            <h2 className="filter-label">Type</h2>
                                            <JobFilter
                                                selected={this.state.filters[1].selectedOption}
                                                options={types}
                                                onChange={selectedOption => {
                                                  if (selectedOption !== null) {
                                                    const filters = this.state.filters
                                                    filters[1].values = selectedOption.value
                                                    filters[1].selectedOption = selectedOption
                                                    this.setState({ filters })
                                                  }
                                                }}
                                            />
                                        </div>
                                        <div className="filter form-material">
                                            <h2 className="filter-label">Status</h2>
                                            <JobFilter
                                                selected={this.state.filters[2].selectedOption}
                                                options={statuses}
                                                onChange={selectedOption => {
                                                  if (selectedOption !== null) {
                                                    const filters = this.state.filters
                                                    filters[2].values = selectedOption.value
                                                    filters[2].selectedOption = selectedOption
                                                    this.setState({ filters })
                                                  }
                                                }}
                                            />
                                        </div>
                                        <div className="filter form-material">
                                            <h2 className="filter-label">Created</h2>
                                            <DateRangePicker
                                            startDatePlaceholderText="From"
                                            endDatePlaceholderText="To"
                                            noBorder={true}
                                            isOutsideRange={() => false}
                                            startDate={this.state.startDate}
                                            startDateId="your_unique_start_date_id_1"
                                            endDate={this.state.endDate}
                                            endDateId="your_unique_end_date_id_2"
                                            minDate={this.state.minDate}
                                            maxDate={this.state.maxDate}
                                            onDatesChange={({ startDate, endDate }) => {
                                              const filters = this.state.filters
                                              filters[3].values = moment(startDate).format('YYYY-MM-DD 00:00:00')
                                              filters[4].values = moment(endDate).format('YYYY-MM-DD 23:59:59')
                                              this.setState({ startDate, endDate, filters })
                                            }}
                                            focusedInput={this.state.focusedInput}
                                            onFocusChange={focusedInput => {
                                              if (focusedInput !== this.state.focusedInput) {
                                                this.setState({ focusedInput })
                                              }
                                            }}
                                            />
                                        </div>
                                        <div className="filter form-material">
                                            <h2 className="filter-label">Updated</h2>
                                            <DateRangePicker
                                            startDatePlaceholderText="From"
                                            endDatePlaceholderText="To"
                                            isOutsideRange={() => false}
                                            noBorder={true}
                                            startDate={this.state.updatedDateMin}
                                            startDateId="your_unique_start_date_id_3"
                                            endDate={this.state.updatedDateMax}
                                            endDateId="your_unique_end_date_id_4"
                                            minDate={this.state.minDate}
                                            maxDate={this.state.maxDate}
                                            onDatesChange={({ startDate, endDate }) => {
                                              const filters = this.state.filters
                                              filters[5].values = moment(startDate).format('YYYY-MM-DD 00:00:00')
                                              filters[6].values = moment(endDate).format('YYYY-MM-DD 23:59:59')
                                              this.setState({
                                                updatedDateMin: startDate,
                                                updatedDateMax: endDate,
                                                filters
                                              })
                                            }}
                                            focusedInput={this.state.updatedFocusedInput}
                                            onFocusChange={updatedFocusedInput => {
                                              if (updatedFocusedInput !== this.state.updatedFocusedInput) {
                                                this.setState({ updatedFocusedInput })
                                              }
                                            }}
                                            />
                                        </div>
                                    </div>

                                    <div className="flex-item">
                                        <div className="flex-row flex-row-filter">
                                            <div className="shortcut btn" onClick={this.applyFilters.bind(this)}>
                                                Apply Filters
                                            </div>
                                            <div className="shortcut btn" onClick={this.clearFilters.bind(this)}>
                                                Clean filters
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* End Search */}
                                {/* Table */}
                                <JobsTable jobs={this.props.jobs}>
                                    {/* Pagination */}
                                    <PaginationLayout pagination={this.props.paginationData}
                                                      onPaginationChange={this.onPaginationChange}/>
                                    {/* End Pagination */}
                                </JobsTable>
                                {/* End Table */}
                            </div>
                        </div>
                    </div>
                    {/* END Page Content */}
                </PageContainerLayout>
            </>
    )
  };
}

// Set propTypes
const { arrayOf, func } = PropTypes
JobsPage.propTypes = {
  jobs: arrayOf(JobsShape).isRequired,
  fetchJobs: func.isRequired,
  paginationData: PaginationShape
}

// Set defaultProps
JobsPage.defaultProps = {
  jobs: [],
  paginationData: {}
}

// mapStateToProps
const mapStateToProps = (state) => ({
  isLoading: state.jobs.fetchJobsReducer.loading,
  jobs: state.jobs.fetchJobsReducer.data.results,
  paginationData: state.jobs.fetchJobsReducer.data.pagination_data
})

// mapStateToProps
const mapDispatchToProps = {
  fetchJobs: jobsOperations.list
}

// Export Class
const _JobsPage = connect(mapStateToProps, mapDispatchToProps)(JobsPage)
export { _JobsPage as JobsPage }
