// DASHBOARDS
export const FETCH_DASHBOARDS_REQUEST = 'session/FETCH_DASHBOARDS_REQUEST'
export const FETCH_DASHBOARDS_REQUEST_SUCCESS = 'session/FETCH_DASHBOARDS_REQUEST_SUCCESS'
export const FETCH_DASHBOARDS_REQUEST_FAILURE = 'session/FETCH_DASHBOARDS_REQUEST_FAILURE'
export const FETCH_DASHBOARDS_REQUEST_COMPLETED = 'session/FETCH_DASHBOARDS_REQUEST_COMPLETED'

export const GET_DASHBOARD_REQUEST = 'session/GET_DASHBOARD_REQUEST'
export const GET_DASHBOARD_REQUEST_SUCCESS = 'session/GET_DASHBOARD_REQUEST_SUCCESS'
export const GET_DASHBOARD_REQUEST_FAILURE = 'session/GET_DASHBOARD_REQUEST_FAILURE'
export const GET_DASHBOARD_REQUEST_COMPLETED = 'session/GET_DASHBOARD_REQUEST_COMPLETED'

export const SAVE_DASHBOARD_REQUEST = 'session/SAVE_DASHBOARD_REQUEST'
export const SAVE_DASHBOARD_REQUEST_SUCCESS = 'session/SAVE_DASHBOARD_REQUEST_SUCCESS'
export const SAVE_DASHBOARD_REQUEST_FAILURE = 'session/SAVE_DASHBOARD_REQUEST_FAILURE'
export const SAVE_DASHBOARD_REQUEST_COMPLETED = 'session/SAVE_DASHBOARD_REQUEST_COMPLETED'

export const UPDATE_DASHBOARD_REQUEST = 'session/UPDATE_DASHBOARD_REQUEST'
export const UPDATE_DASHBOARD_REQUEST_SUCCESS = 'session/UPDATE_DASHBOARD_REQUEST_SUCCESS'
export const UPDATE_DASHBOARD_REQUEST_FAILURE = 'session/UPDATE_DASHBOARD_REQUEST_FAILURE'
export const UPDATE_DASHBOARD_REQUEST_COMPLETED = 'session/UPDATE_DASHBOARD_REQUEST_COMPLETED'
