import { toast } from 'react-toastify'
import { combineReducers } from 'redux'
import * as types from './types'

const fetchReleasesInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: { results: [], pagination_data: {} }
}

const getReleaseInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {
    configuration: {
      database: [],
      s3: []
    }
  }
}

const saveReleaseInitialState = {
  loadingButton: false,
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

const updateReleaseInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {}
}

const fetchDiffcheckInitialState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {
    changes: {
      data: {
        database: [],
        s3: []
      },
      status: 'running'
    }
  }
}

const s3filesState = {
  loading: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {
    body: ''
  }
}

const deployReleaseInitialState = {
  loading: false,
  loadingButton: false,
  failed: false,
  completed: false,
  error_data: { error: '' },
  data: {
    client_id: 0,
    release_id: 0,
    success: false
  }
}

export function fetchReleasesReducer (
  state = fetchReleasesInitialState,
  action
) {
  switch (action.type) {
    case types.FETCH_RELEASES_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.FETCH_RELEASES_REQUEST_FAILURE:
      return {
        ...fetchReleasesInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.FETCH_RELEASES_REQUEST_SUCCESS:
      return {
        ...fetchReleasesInitialState,
        data: action.payload.data
      }
    case types.FETCH_RELEASES_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function fetchReleaseReducer (state = getReleaseInitialState, action) {
  switch (action.type) {
    case types.GET_RELEASE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.GET_RELEASE_REQUEST_FAILURE:
      return {
        ...getReleaseInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.GET_RELEASE_REQUEST_SUCCESS:
      return {
        ...getReleaseInitialState,
        data: action.payload.data,
        loading: false
      }
    case types.GET_RELEASE_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function saveReleaseReducer (state = saveReleaseInitialState, action) {
  switch (action.type) {
    case types.SAVE_RELEASE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.SAVE_RELEASE_REQUEST_FAILURE:
      return {
        ...fetchReleasesInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.SAVE_RELEASE_REQUEST_SUCCESS:
      return {
        ...fetchReleasesInitialState,
        data: action.payload.data
      }
    case types.SAVE_RELEASE_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    case types.SAVE_RELEASE_REQUEST_BUTTON_LOADING:
      toast.success('La versión se creó correctamente', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
      return {
        ...state,
        loadingButton: true
      }
    case types.SAVE_RELEASE_REQUEST_BUTTON_STOP_LOADING:
      return {
        ...state,
        loadingButton: false
      }
    default:
      return state
  }
}

export function updateReleaseReducer (
  state = updateReleaseInitialState,
  action
) {
  switch (action.type) {
    case types.UPDATE_RELEASE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.UPDATE_RELEASE_REQUEST_FAILURE:
      return {
        ...updateReleaseInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.UPDATE_RELEASE_REQUEST_SUCCESS:
      return {
        ...updateReleaseInitialState,
        data: action.payload.data
      }
    case types.UPDATE_RELEASE_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function fetchDiffcheckReducer (
  state = fetchDiffcheckInitialState,
  action
) {
  switch (action.type) {
    case types.FETCH_DIFFCHECK_REQUEST:
      return {
        ...state,
        loading: true
      }
    case types.FETCH_DIFFCHECK_REQUEST_FAILURE:
      return {
        ...fetchDiffcheckInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.FETCH_DIFFCHECK_REQUEST_SUCCESS:
      return {
        ...fetchDiffcheckInitialState,
        data: action.payload.data
      }
    case types.FETCH_DIFFCHECK_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export function s3filesReducer (
  state = s3filesState,
  action
) {
  switch (action.type) {
    case types.S3_FILES_RELEASES_REQUEST:
      return {
        ...s3filesState,
        loading: true
      }
    case types.S3_FILES_RELEASES_REQUEST_FAILURE:
      return {
        ...s3filesState,
        error_data: action.payload.data,
        failed: true,
        loading: false
      }
    case types.S3_FILES_RELEASES_REQUEST_SUCCESS:
      return {
        ...s3filesState,
        data: action.payload.data,
        loading: false
      }
    case types.S3_FILES_RELEASES_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true,
        loading: false
      }
    default:
      return state
  }
}

export function deployReleaseReducer (
  state = deployReleaseInitialState,
  action
) {
  switch (action.type) {
    case types.SEND_DEPLOY_RELEASE_REQUEST:
      toast.info('La versión se ha enviado', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      })
      return {
        ...state,
        loading: true,
        loadingButton: true
      }
    case types.SEND_DEPLOY_RELEASE_REQUEST_FAILURE:
      return {
        ...fetchDiffcheckInitialState,
        error_data: action.payload.data,
        failed: true
      }
    case types.SEND_DEPLOY_RELEASE_REQUEST_SUCCESS:
      return {
        ...fetchDiffcheckInitialState,
        data: action.payload.data
      }
    case types.SEND_DEPLOY_RELEASE_REQUEST_CANCEL_LOADING:
      return {
        ...state,
        loadingButton: false
      }
    case types.SEND_DEPLOY_RELEASE_REQUEST_COMPLETED:
      return {
        ...state,
        data: action.payload.data,
        completed: true
      }
    default:
      return state
  }
}

export default combineReducers({
  fetchReleasesReducer,
  fetchReleaseReducer,
  saveReleaseReducer,
  updateReleaseReducer,
  fetchDiffcheckReducer,
  s3filesReducer,
  deployReleaseReducer
})
