import React, { Component } from 'react'
import { ParametersCreatorForm } from '../../containers'
import { rdsParams, s3Params, snowflakeParams } from '../../../../utils/parameters'

class ConnectionDataForm extends Component {
  constructor (props) {
    super(props)

    // state
    this.state = {
      driver: ''
    }

    this.handleChange = this.handleChange.bind(this)
    this.onParametersChange = this.onParametersChange.bind(this)
  }

  handleChange (event) {
    this.props.onChange(event.target.name, event.target.value)
  }

  onParametersChange (parametersList) {
    this.props.onChange('parameters', parametersList)
  }

  originHandler (event) {
    let parameters = {}
    switch (event.target.value.toString()) {
      case '1':
        parameters = rdsParams()
        break
      case '2':
        parameters = s3Params()
        break
      default:
        break
    }
    this.props.onChange(event.target.name, event.target.value)
    setTimeout(() => {
      this.props.onChange('parameters', parameters)
    }, 100)
  }

  driverHandler (event) {
    let parameters = {}
    switch (event.target.value.toString()) {
      case '1':
        parameters = rdsParams()
        break
      case '2':
        parameters = snowflakeParams()
        break
      default:
        break
    }
    this.setState({
      driver: event.target.value
    }, () => {
      this.props.onChange('parameters', parameters)
    })
  }

  render () {
    return (
            <>
                <div className="form-group">
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <div className="form-material">
                            <input className="form-control"
                                   type="text"
                                   id="input-name"
                                   placeholder="Please enter a name"
                                   name="name"
                                   value={this.props.connection.name}
                                   onChange={this.handleChange}
                            />
                            <label htmlFor="input-name">Name</label>
                        </div>
                    </div>
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <div className="form-material">
                            <select
                                className="form-control"
                                id="input-origin"
                                name="origin"
                                value={this.props.connection.origin}
                                onChange={this.originHandler.bind(this)}
                            >
                                <option />
                                <option value="">--</option>
                                <option value="1">Database</option>
                                <option value="2">S3</option>
                                <option value="3">Secure Database</option>
                                <option value="4">SFTP</option>
                            </select>
                            <label htmlFor="input-origin">Origin</label>
                        </div>
                    </div>
                    {
                        this.props.connection.origin === '1'
                          ? <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                                <div className="form-material">
                                    <select
                                        className="form-control"
                                        id="input-driver"
                                        name="driver"
                                        value={this.state.driver}
                                        onChange={this.driverHandler.bind(this)}
                                    >
                                        <option value="1">RDS</option>
                                        <option value="2">Snowflake</option>
                                    </select>
                                    <label htmlFor="input-driver">Driver</label>
                                </div>
                            </div>
                          : null
                    }
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <label>Parameters</label>
                        <ParametersCreatorForm
                            parameters={this.props.connection.parameters}
                            onParametersChange={this.onParametersChange.bind(this)}
                        />
                    </div>
                </div>
            </>
    )
  }
}

// Set default props
ConnectionDataForm.defaultProps = {
  connection: { parameters: {} },
  onChange: () => {
  }
}

export {
  ConnectionDataForm
}
