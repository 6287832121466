import React, { Component } from 'react'
import SelectSearchLayout from '../../../layouts/select-search'
import * as PropTypes from 'prop-types'
import { connectionOperations } from '../../../../state/ducks/connections'
import { connect } from 'react-redux'
import { ParametersCreatorForm } from '../parameters-creator-form'

class ExtractionDataForm extends Component {
  constructor (props) {
    super(props)

    // state
    this.state = {
      driver: '',
      connection_name: ''
    }

    this.handleChange = this.handleChange.bind(this)
    this.returnConnectionOptions = this.returnConnectionOptions.bind(this)
    this.onOriginConnectionSearchKeywordChange = this.onOriginConnectionSearchKeywordChange.bind(this)
    this.onSelectOriginConnection = this.onSelectOriginConnection.bind(this)
    this.handleBatchOrderChange = this.handleBatchOrderChange.bind(this)
    this.handleCheckbox = this.handleCheckbox.bind(this)
    this.onParametersChange = this.onParametersChange.bind(this)
  }

  componentDidMount () {
    if (this.props.connections_list.length) return

    this.props.fetchConnections({})
  }

  componentDidUpdate (prevProps) {
    if (this.props?.extraction?.connection != null && this.props?.connections_list?.length > 0) {
      const connection = this.props.connections_list.find((item) => this.props.extraction.connection === item.id)
      if (!connection) return
      const connectionOption = {
        value: connection.id + '|' + connection.name,
        label: connection.name
      }
      this.onSelectOriginConnection(connectionOption)
      this.setState({ connection_name: connectionOption.label })
    }
  }

  handleChange (event) {
    this.props.onChange(event.target.name, event.target.value)
  }

  handleBatchOrderChange (event) {
    this.props.onChange(event.target.name, parseFloat(event.target.value))
  }

  handleCheckbox (event) {
    this.props.onChange('active', !this.props.extraction.active)
  }

  onParametersChange (parametersList) {
    this.props.onChange('parameters', parametersList)
  }

  returnConnectionOptions () {
    const options = []
    this.props.connections_list.forEach(element => {
      options.push({
        value: element.id + '|' + element.name,
        label: element.name
      })
    })
    return options
  }

  onOriginConnectionSearchKeywordChange (keyword) {
    this.returnConnectionOptions()
    this.props.fetchConnections({ search: keyword })
  }

  onSelectOriginConnection (item) {
    const split_ = item.value.split('|')
    // id: split_[0,
    // connection_name: split_[1]
    this.props.onChange('connection', split_[0])
  }

  render () {
    return (
            <>
                <div className="form-group">
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <div className="form-material">
                            <input className="form-control"
                                   type="text"
                                   id="input-name"
                                   placeholder="Please enter a name"
                                   name="name"
                                   value={this.props.extraction.name}
                                   onChange={this.handleChange}
                            />
                            <label htmlFor="input-name">Name</label>
                        </div>
                    </div>
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <div className="form-material">
                            <input className="form-control"
                                   type="text"
                                   id="input-table"
                                   placeholder="Please enter a table"
                                   name="table"
                                   value={this.props.extraction.table}
                                   onChange={this.handleChange}
                            />
                            <label htmlFor="input-table">Table Name</label>
                        </div>
                    </div>
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <div className="form-material">
                            <label htmlFor="input-connections">Connection</label>
                            <SelectSearchLayout
                                placeholder={this.props.extraction.connection ? this.state.connection_name : 'Type to search an item...'}
                                getOptions={this.returnConnectionOptions}
                                onSearchChange={this.onOriginConnectionSearchKeywordChange}
                                onSelectItem={this.onSelectOriginConnection}
                            />
                        </div>
                    </div>
                    <div className="col-sm-8 col-sm-offset-2">
                        <div className="form-material">
                            <select
                                className="form-control"
                                id="input-origin"
                                name="origin"
                                value={this.props.extraction.origin}
                                onChange={this.handleChange}
                            >
                                <option>-</option>
                                <option value={1}>SFTP</option>
                                <option value={2}>API - GET</option>
                                <option value={3}>Webhook</option>
                                <option value={98}>CustomScript - Queue S3</option>
                                <option value={99}>CustomScript - Worker</option>
                            </select>
                            <label htmlFor="input-origin">Type</label>
                        </div>
                    </div>
                    <div className="col-sm-8 col-sm-offset-2" style={{ minHeight: '70px' }}>
                        <div className="form-material">
                            <div className="block" id="my-block">
                                <div className="block-header pl-0 pr-0">
                                    <ul className="block-options">
                                        <li>
                                            <button type="button" data-toggle="block-option"
                                                    data-action="fullscreen_toggle"
                                                    onClick={window.jQuery('body').addClass('block-fullscreen')}>
                                                <i className="si si-size-fullscreen"/>
                                            </button>
                                        </li>
                                    </ul>
                                    <h3 className="block-title">Parameters</h3>
                                    <br/>
                                </div>
                                <div className="block-content p-0">
                                    <ParametersCreatorForm
                                        editorType={2}
                                        parameters={this.props.extraction.parameters}
                                        onParametersChange={this.onParametersChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    )
  }
}

// Set propTypes
ExtractionDataForm.propTypes = {
  fetchConnections: PropTypes.func.isRequired
}

// Set defaultProps
ExtractionDataForm.defaultProps = {
  extraction: {},
  onChange: () => {
  }
}

// mapStateToProps
const mapStateToProps = (state) => ({
  connections_list: state.connections.fetchConnectionsReducer.data.results
})

// mapStateToProps
const mapDispatchToProps = {
  fetchConnections: connectionOperations.list
}

// Export Class
const _ExtractionDataForm = connect(mapStateToProps,
  mapDispatchToProps)(ExtractionDataForm)
export { _ExtractionDataForm as ExtractionDataForm }
